import React from 'react';
import { CompanyGroupPatchMutation } from '../graphql/mutations/CompanyGroupPatchMutation';
import { CompanyGroupEditQuery } from '../graphql/query/CompanyGroupEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/CompanyGroup.json';
import inputTypeIntro from '../static/introspection/CompanyGroupPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const CompanyGroupEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(CompanyGroupEditQuery, { id })}
		mutation={CompanyGroupPatchMutation}
		entityName={ENTITY.COMPANY_GROUP}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={[
			'name',
			'logoMedia',
			'description',
			'descriptionIta',
			'descriptionLong',
			'descriptionLongIta',
			'pecEmail',
			'vatNumber',
			'website',
			'Country',
			'StateProvince',
			'postalCode',
			'city',
			'address1',
			'address2',
			'lat',
			'lng',
		]}
	/>
);
