import React, { useContext } from 'react';
import {
	FormGroup,
	Label,
	Col,
	FormFeedback,
	FormText,
	Badge,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';
import { t } from '../../utils/labels';
import { BooleanFieldType, FieldComponent } from '../../types/form';
import { getLocalError } from '../../utils/formErrors';
import { FormContext } from '../../utils/context/form';
import { IChoice } from '../../utils/misc';

const valToStr = (val: boolean | null | undefined) => {
	if (val === true) return 'Yes';
	if (val === false) return 'No';
	return 'Undefined';
};

const choices = (required: boolean | undefined) => {
	let options: IChoice[] = [
		{ label: t`Yes`, value: true },
		{ label: t`No`, value: false },
	];
	if (!required) {
		options = [{ label: t`Undefined`, value: null } as IChoice].concat(
			options,
		);
	}
	return options;
};

export const BooleanField: React.FC<FieldComponent<BooleanFieldType>> = ({
	field: { hidden, label, required, value, description, helpText },
	path,
	changeHandler,
}) => {
	const [open, toggle] = React.useState(false);
	const formContext = useContext(FormContext);
	const error = getLocalError(path, formContext?.errors || {});
	const color =
		value === true ? 'success' : value === false ? 'danger' : 'secondary';

	return (
		<FormGroup row className={hidden ? 'hidden' : ''}>
			<Label sm={3}>{label}: </Label>
			<Col sm={9} className="checkbox-container">
				<Dropdown isOpen={open} toggle={() => toggle(!open)}>
					<DropdownToggle caret color={color}>
						{t(valToStr(value))}
					</DropdownToggle>
					<DropdownMenu>
						{choices(required).map((choice, index) => (
							<DropdownItem
								key={index}
								onClick={() => changeHandler(choice.value, path)}
							>
								{choice.label}
							</DropdownItem>
						))}
					</DropdownMenu>
				</Dropdown>
			</Col>
			<Col sm={{ size: 9, offset: 3 }}>
				{error && <FormFeedback invalid>{t(error.message)}</FormFeedback>}
				<FormText color="muted">
					{description.readonly && (
						<div>
							<Badge color="warning">{t`warning`}</Badge>{' '}
							{t`readonly field description`}
						</div>
					)}
					{helpText}
				</FormText>
			</Col>
		</FormGroup>
	);
};
