import gql from 'graphql-tag';
import { t } from '../utils/labels';
import { ReportType } from '../components/Report';
import { nodes } from '../utils/misc';
import { always, filter, pipe, prop } from 'ramda';
import { ENTITY } from '../utils/entities';
import { InternalCapability } from '../server-types';
import moment from 'moment';

const bool = (val: any) => (!!val ? 'Si' : 'No');

export const companiesForPlatformReport: ReportType = {
	filename: always('Aziende e stato rispetto alla piattaforma'),
	title: 'Aziende e stato rispetto alla piattaforma',
	requirements: [InternalCapability.MarketingExtra],
	query: gql`
		query CompaniesForPlatformQuery(
			$after: String
			$filter: CompaniesFilterType
			$first: Int
		) {
			connector: companiesDir(
				after: $after
				filter: $filter
				first: $first
			) {
				edges {
					node {
						... on Company {
							id
							localId
							sfId
							name
							description
							descriptionIta
							descriptionLong
							descriptionLongIta
							plProducer
							currentCompanyLevels {
								id
								level
								 startDate
								 expirationDate
							}
							goods {
								id
							}
							highlightedGoods {
								id
							}
							brands {
								id
							}
							companyCertifications {
								id
								name
							}
							billingBranch {
								id
								#								Country {
								#									id
								#									code
								#								}
							}
							managedByUsers {
								id
							}
							editorialSections {
								id
							}
						}
					}
				}
				pageInfo {
					hasNextPage
				}
			}
		}
	`,
	fields: [],
	header: () => [
		t`Company name`,
		t`Company ID`,
		t`Salesforce ID`,
		t`Company Level`,
		t`Start date`,
		t`Expiration date`,
		t`User number`,
		t`Goods number`,
		t`Highlighted goods number`,
		t`Brands number`,
		t`Certifications number`,
		t`has description?`,
		t`has description ita?`,
		t`has description long?`,
		t`has description long ita?`,
		t`has Billing branch?`,
		t`is Billing branch in Italy?`,
		t`Private Label?`,
		t`has editorial section?`,
		t`Company certifications`,
	],
	variablesGenerator: always({ filter: { targetSite: 'IFN' } }),
	extractor: pipe(
		// @ts-ignore
		prop('connector'),
		nodes,
		filter((e: any) => e.__typename === ENTITY.COMPANY),
	),
	transformer: (e: any) => {
		const level = e.currentCompanyLevels && e.currentCompanyLevels[0]
		
		return [
			e.name,
			e.localId,
			e.sfId,
			level
				? level.level
				: 'free',
			level ? moment(level.startDate).format('YYYY-MM-DD') : '',
			level ? moment(level.expirationDate).format('YYYY-MM-DD'): '',
			
			e.managedByUsers && e.managedByUsers.length,
			e.goods && e.goods.length,
			e.highlightedGoods && e.highlightedGoods.length,
			e.brands && e.brands.length,
			e.companyCertifications && e.companyCertifications.length,
			bool(e.description),
			bool(e.descriptionIta),
			bool(e.descriptionLong),
			bool(e.descriptionLongIta),
			bool(e.billingBranch),
			bool(
				e.billingBranch &&
					e.billingBranch.Country &&
					e.billingBranch.Country.code === 'IT',
			),
			bool(e.plProducer),
			bool(e.editorialSections && e.editorialSections.length > 0),
			e.companyCertifications &&
				e.companyCertifications.map((c) => c.name).join(', '),
		];
	},
	cursor: (i) => (i + 1).toString(),
};
