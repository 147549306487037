import gql from 'graphql-tag';
import { AwardInvitedUserFullFragment } from '../fragments/AwardInvitedUserFullFragment';

export const AwardInvitedUserDetailQuery = gql`
	query AwardInvitedUserDetailQuery($id: ID!) {
		entity: node(id: $id) {
			...AwardInvitedUserFullFragment
		}
	}
	${AwardInvitedUserFullFragment}
`;


