import gql from 'graphql-tag';

export const ManagedByUserFullFragment = gql`
	fragment ManagedByUserFullFragment on ManagedByUser {
		id
		localId
		capabilities
		User {
			id
			name
		}
		Company {
			id
			name
		}
		Service {
			id
			name
		}
		CompanyGroup {
			id
			name
		}
		Retailer {
			id
			name
		}
		FoodService {
			id
			name
		}
	}
`;
