import gql from 'graphql-tag';

export const EditorialSectionSelectQuery = gql`
	query EditorialSectionSelectQuery(
		$first: Int
		$cursor: String
		$orderBy: EditorialSectionOrderBy
		$orderDesc: Boolean
		$filter: EditorialSectionFilterInput
	) {
		choices: editorialSections(
			first: $first
			after: $cursor
			orderBy: $orderBy
			orderDesc: $orderDesc
			filter: $filter
		) {
			edges {
				node {
					id
					localId
					name
					Parent {
						id
						name
					}
					editorialBrand
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;


