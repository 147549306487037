import gql from 'graphql-tag';

export const genericChoiceQuery = gql`
	query GenericChoiceQuery($id: ID!) {
		node(id: $id) {
			id
			... on Company {
				name
			}
			... on Good {
				name
			}
			... on Retailer {
				name
			}
			... on Candidacy {
				name
			}
		}
	}
`;
