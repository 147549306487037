import gql from 'graphql-tag';
import {fairParticipationFullFragment} from "../fragments/FairParticipationFullFragment";

export const fairParticipationPatchMutation = gql`
	mutation fairParticipationPatchMutation($id: ID!, $changes: FairParticipationPatchInput!) {
		results: fairParticipationPatch(id: $id, changes: $changes) {
			...fairParticipationFullFragment
		}
	}
	${fairParticipationFullFragment}
`;


