import React from 'react';
import { Col, Row, Label } from 'reactstrap';
import { MediaPreview } from '../MediaPreview';
import { FieldComponentRO, MediaFieldType } from '../../types/form';

export const MediaFieldRO: React.FC<
	FieldComponentRO<MediaFieldType>
> = ({ field: { big, label, value } }) => (
	<Row>
		<Col sm={big ? 12 : 4}>
			<Label>{label}:</Label>
		</Col>
		<Col sm={big ? 12 : 8} style={{ position: 'relative' }}>
			{value && <MediaPreview item={value.media ? value.media : value} />}
		</Col>
	</Row>
);
