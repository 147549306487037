import React from 'react';
import { BranchDetailQuery } from '../graphql/query/BranchDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/Branch.json';
import { ENTITY } from '../utils/entities';
import { EntityDetailModal } from '../types/entities';
import { Company } from '../server-types';

export const BranchDetail: React.FC<EntityDetailModal<Company>> = ({
	id,
	modal,
	onDelete,
	entityLabel,
}) => (
	<EntityDetailFetcher
		id={id}
		onDelete={onDelete}
		entityLabel={entityLabel}
		query={BranchDetailQuery}
		entityName={ENTITY.BRANCH}
		typeIntro={typeIntro}
		clonable={false}
		modal={modal}
		fields={[
			'name',
			'type',
			'vatNumber',
			'website',
			'Country',
			'StateProvince',
			'postalCode',
			'city',
			'address1',
			'address2',
		]}
	/>
);
