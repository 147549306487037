import moment from 'moment';

const HISTORY_TOKEN_NAME = 'PREVIOUS_ROUTES';

function pushRoute(route) {
	const story = getRoutes();
	story.push(route);
	sessionStorage.setItem(HISTORY_TOKEN_NAME, story.join(';'));
}

function getParameters(basepath) {
	const story = getRoutes();
	let i = story.length - 1;
	while (i > 0) {
		const route = story[i];
		const route_pieces = route.split('?');
		if (basepath === route_pieces[0]) {
			return route_pieces[1];
		} else {
			i -= 1;
		}
	}
	return '';
}

function getRoutes() {
	const history = sessionStorage.getItem(HISTORY_TOKEN_NAME);
	return history ? history.split(';') : [];
}

function searchToObject(searchStr) {
	return searchStr
		.replace('?', '')
		.split('&')
		.reduce((obj, str) => {
			const t = str.split('=');
			obj[t[0]] = decodeURIComponent(t[1]);
			return obj;
		}, {});
}

/*const getOrderFromSearchString = (
	searchStr: string,
): { field: string | null; direction: string } => {
	let orderField: string | null = null;
	let orderDirection = 'ASC';

	const tmp = searchStr.replace('?', '').split('&');

	const fieldElement = tmp.filter((s) => s.startsWith('orderby='));
	const directionElement = tmp.filter((s) => s.startsWith('orderdirection='));

	if (fieldElement.length === 1) orderField = fieldElement[0].split('=')[1];

	if (directionElement.length === 1)
		orderDirection = directionElement[0].split('=')[1];

	return {
		field: orderField,
		direction: orderDirection,
	};
};*/

function changeOrderInSearch(searchStr, orderField, orderDirection = 'ASC') {
	const tmp = searchStr.replace('?', '').split('&');

	return (
		'?' +
		tmp
			.filter(
				(s) =>
					!s.startsWith('orderby=') && !s.startsWith('orderdirection='),
			)
			.concat(['orderby=' + orderField, 'orderdirection=' + orderDirection])
			.join('&')
	);
}

function changeFiltersInSearch(searchStr: string, filterValues) {
	const urlFilterValues = Object.keys(filterValues).reduce((obj, key) => {
		const filterValue = filterValues[key];
		
		if (
			filterValue === null ||
			filterValue === '' ||
			(Array.isArray(filterValue) && filterValue.length === 0)
		)
			return obj;
		
		if (Array.isArray(filterValue)) {
			// sono nel caso di un filtro con entita' multiple

			obj[key] = filterValue
				.map((choice) => {
					if (typeof choice === 'object') {
						// sono nel caso di liste di oggetti, quindi con id
						return encodeURIComponent(choice.id);
					} else {
						// sono nel caso di enumerazioni e valori costanti
						return encodeURIComponent(choice);
					}
				})
				.join(',');
		} else if (filterValue instanceof Date) {
			obj[key] = moment(filterValue).format('YYYY-MM-DD');
		} else if (typeof filterValue === 'object') {
			// sono nel caso di un filtro con entita' singola oppure un booleano...
			obj[key] =
				typeof filterValue.value === 'string'
					? filterValue.value
					: encodeURIComponent(filterValue.id);
		} else {
			// tutti gli altri filtri: enumerazioni, stringhe, date
			obj[key] = encodeURIComponent(filterValue);
		}
		return obj;
	}, {});

	const elementsToKeep = searchStr
		.replace('?', '')
		.split('&')
		.filter(
			(s) =>
				s.startsWith('orderby=') ||
				s.startsWith('orderdirection=') ||
				s.startsWith('page='),
		);

	return (
		'?' +
		Object.keys(urlFilterValues)
			.map((key) => key + '=' + urlFilterValues[key])
			.concat(elementsToKeep)
			.join('&')
	);
}

function setPageNumberInSearch(searchStr = '', pageNum) {
	return (
		'?' +
		searchStr
			.replace('?', '')
			.split('&')
			.filter((s) => !s.startsWith('page='))
			.concat(['page=' + pageNum])
			.join('&')
	);
}

export {
	getRoutes,
	getParameters,
	pushRoute,
	searchToObject,
	// getOrderFromSearchString,
	changeOrderInSearch,
	changeFiltersInSearch,
	setPageNumberInSearch,
};
