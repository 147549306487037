import React from 'react';
import { CandidacyRatingDetailQuery } from '../graphql/query/CandidacyRatingDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/CandidacyRating.json';
import { ENTITY } from '../utils/entities';
import { CandidacyRating } from '../server-types';
import { EntityDetailModal } from '../types/entities';

export const CandidacyRatingDetail: React.FC<EntityDetailModal<
	CandidacyRating
>> = ({ id, entityLabel, modal, onDelete }) => (
	<EntityDetailFetcher
		id={id}
		entityLabel={entityLabel}
		modal={modal}
		onDelete={onDelete}
		query={CandidacyRatingDetailQuery}
		typeIntro={typeIntro}
		entityName={ENTITY.CANDIDACY_RATING}
		clonable={false}
		fields={[
			'User',
			'overall',
			'rating1',
			'rating2',
			'rating3',
			'rating4',
			'rating5',
			'comment1',
			'comment2',
			'comment3',
			'askSample',
		]}
	/>
);
