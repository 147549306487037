import gql from 'graphql-tag';

export const GoodSelectQuery = gql`
	query GoodSelectQuery($filter: GoodFilterInput) {
		choices: goods(filter: $filter, orderBy: name, orderDesc: false) {
			edges {
				node {
					id
					name
					formats {
						id
						ean
					}
				}
			}
			total
		}
	}
`;
