import gql from 'graphql-tag';
import {CompanyCertificationFullFragment} from "../fragments/CompanyCertificationFullFragment";

export const CompanyCertificationDetailQuery = gql`
	query CompanyCertificationDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...CompanyCertificationFullFragment
		}
	}
	${CompanyCertificationFullFragment}
`;


