import React from 'react';
import { AwardInvitedUserCreateMutation } from '../graphql/mutations/AwardInvitedUserCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/AwardInvitedUser.json';
import inputTypeIntro from '../static/introspection/AwardInvitedUserCreateInput.json';
import { toRefInput } from '../utils/dataTrasform';
import { ENTITY } from '../utils/entities';
import { fastTransformer, toHidden } from '../utils/form';
import { transformerHandler } from '../utils/misc';
import { Award, AwardInvitedUser } from '../server-types';
import { EntityCreateModal } from '../types/entities';

export const AwardInvitedUserCreate: React.FC<EntityCreateModal<
	AwardInvitedUser,
	Award
>> = ({ originalEntity, onSubmit }) => (
	<EntityEditFetcher
		onSubmit={onSubmit}
		create={true}
		modal={true}
		mutation={AwardInvitedUserCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.AWARD_INVITED_USER}
		fields={['Award', 'User', 'roles']}
		transformer={transformerHandler(
			fastTransformer({
				Award: toHidden(toRefInput(originalEntity)),
			}),
		)}
	/>
);
