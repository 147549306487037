import React from 'react';
import { EditorialTagPatchMutation } from '../graphql/mutations/EditorialTagPatchMutation';
import { EditorialTagEditQuery } from '../graphql/query/EditorialTagEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/EditorialTag.json';
import inputTypeIntro from '../static/introspection/EditorialTagPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const EditorialTagEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(EditorialTagEditQuery, { id })}
		mutation={EditorialTagPatchMutation}
		entityName={ENTITY.EDITORIAL_TAG}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={['name']}
	/>
);
