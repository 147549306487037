import gql from 'graphql-tag';
import { MediaBaseFragment } from './MediaBaseFragment';

export const CandidacyFullFragment = gql`
	fragment CandidacyFullFragment on Candidacy {
		id
		localId
		Award {
			id
			name
			year
			edition
		}
		Company {
			id
			name
		}
		featuredImageMedia {
			...MediaBaseFragment
		}
		AwardCategory {
			id
			name
		}
		name
		Brand {
			id
			name
			Company {
				id
				name
			}
		}
		range
		description1
		description2
		description3
		description4
		description5
		description6
		description7
		description8
		description9
		description10
		extraMedia {
			...MediaBaseFragment
		}
		goods {
			id
			name
			nameIta
			formats {
				id
				ean
			}
		}
		candidacyRatings {
			id
			overall
			User {
				id
				name
			}
		}
		launchDate
		additionalImagesMedia {
			...MediaBaseFragment
		}
	}
	${MediaBaseFragment}
`;
