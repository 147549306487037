import React from 'react';
import { t } from '../utils/labels';
import {
	Badge,
	Button,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from 'reactstrap';
import { assoc } from 'ramda';
import {
	modalHandler,
	parseGraphqlError,
	sentryHandler,
} from '../utils/errors';
import { Tooltip } from 'reactstrap';
import { client } from '../utils/client';
import { UserData } from '@food/auth';
import { ENTITY } from '../utils/entities';

interface IStatusSetterProps {
	entity: any;
	entityName: ENTITY;
	entityIntrospection: any;
	statusFieldName: string;
	statuses: string[];
	mutation: any;
	onChange?: (args: any) => void;
}

export const StatusSetter: React.FunctionComponent<IStatusSetterProps> = ({
	entity,
	statusFieldName,
	statuses,
	entityIntrospection,
	entityName,
	mutation,
	onChange,
}) => {
	const [modalOpen, toggleModal] = React.useState(false);
	const [selected, setSelected] = React.useState(
		typeof entity[statusFieldName] === 'boolean'
			? entity[statusFieldName]
				? 'TRUE'
				: 'FALSE'
			: entity[statusFieldName],
	);
	const [tooltips, setTooltips] = React.useState(
		statuses.reduce((res, s) => {
			res[s] = false;
			return res;
		}, {}),
	);
	const changeStatus = React.useCallback(
		async () => {
			try {
				const mutationResult = await client.mutate({
					mutation,
					variables: {
						id: entity.id,
						status: selected,
					},
				});
				if (onChange) {
					onChange(mutationResult.data);
				}
				toggleModal(false);
			} catch (e) {
				const errs = parseGraphqlError(e);
				sentryHandler(errs);
				modalHandler(errs);
			}
		},
		[selected, entity.id],
	);

	const description = JSON.parse(entityIntrospection.description);
	const toggle = () => toggleModal(!modalOpen);

	return (
		<UserData>
			{({ userData, capabilities }) => {
				const status = entity[statusFieldName];
				const permissions = description.canSetStatusRoles[statusFieldName];
				const { role } = userData;
				const abilitedStatuses = statuses.filter(
					(s) =>
						permissions[s][role] === true ||
						capabilities.indexOf(permissions[s][role]) !== -1,
				);

				// condizione che verifica se si puo' cambiare lo stato all'entita'
				const actualValue = entity[statusFieldName];
				const actualParsedValue =
					typeof actualValue === 'boolean'
						? actualValue
							? 'TRUE'
							: 'FALSE'
						: actualValue;

				const canEdit = abilitedStatuses.indexOf(actualParsedValue) !== -1;
				
				if (!canEdit) {
					return null;
				}
				
				

				return (
					<section data-relative="status">
						<Button onClick={toggle} className="margin-right">
							<i className="mdi mdi-directions-fork" />{' '}
							{t([entityName, statusFieldName, 'set'].join('/'))}
						</Button>
						<Modal autoFocus={false} isOpen={modalOpen} toggle={toggle}>
							<ModalHeader toggle={toggle}>
								{t([entityName, statusFieldName, 'set'].join('/'))}
							</ModalHeader>
							<ModalBody>
								{statuses &&
									statuses.map((s) => (
										<FormGroup check key={s}>
											<Label check>
												<Input
													type="radio"
													name="radio"
													disabled={
														abilitedStatuses.indexOf(s) === -1
													}
													checked={s === selected}
													onChange={() => setSelected(s)}
												/>{' '}
												<a
													href="#"
													id={'tooltip-' + s}
													className="question-icon"
												>
													<i className="fa fa-question-circle" />
												</a>{' '}
												{t(s)}{' '}
												{s === status && (
													<Badge color="info">{t`Current`}</Badge>
												)}
											</Label>

											<Tooltip
												placement="bottom-end"
												isOpen={tooltips[s]}
												target={'tooltip-' + s}
												toggle={() =>
													setTooltips(
														assoc(s, !tooltips[s], tooltips),
													)
												}
											>
												{t(
													[
														entityName,
														statusFieldName,
														s,
														'helpText',
													].join('/'),
												)}
											</Tooltip>
										</FormGroup>
									))}
							</ModalBody>
							<ModalFooter>
								<Button onClick={toggle}>
									<i className="fa fa-times" /> {t`Undo`}
								</Button>
								<Button
									color={'success'}
									disabled={!selected}
									onClick={changeStatus}
								>
									<i className={'fa fa-save'} /> {t`save`}
								</Button>
							</ModalFooter>
						</Modal>
					</section>
				);
			}}
		</UserData>
	);
};
