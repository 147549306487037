/**
 * Componente per cancellare entita' tramite modale. Di solito inserito nei componenti di dettaglio di tutti i livelli
 * gerarchici, contiene anche la logica di chiamata GraphQL necessaria alla cancellazione.
 */

import React, { useCallback, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { t } from '../utils/labels';
import { deleteMutation } from '../utils/misc';
import {
	modalHandler,
	parseGraphqlError,
	sentryHandler,
} from '../utils/errors';

interface DeleteEntityModalProps {
	// l'id dell'entita' che si desidera cancellare
	entityId: string;
	
	// abbiamo deciso di non legare il pulsante di cancellazione a una grafica fissa ma di passare le primitive di
	// apertura modale e lasciare possibilita' di personalizzazione. Per passare la funzione di apertura abbiamo deciso
	// di utilizzare il children pattern, forzando il figlio ad essere una funzione con la callback come unico argomento.
	children: (openDeleteModal: () => void) => JSX.Element;
	
	// il nome della tradotto dell'entita' che si vuole cancellare
	entityName: string;
	
	// callback da richiamare una volta che il server ha confermato la cancellazione. Teoricamente potrebbe essere
	// opzionale, ma siccome e' sempre specificata tanto vale metterla come obbligatoria
	onDelete: (id: string) => void;
	
	// callback chiamata in caso il server non permettesse la cancellazione (o avesse un bug)
	onFailedDelete: (e: Error) => void;
}

export const DeleteEntityModal: React.FC<DeleteEntityModalProps> = ({
	entityId,
	children,
	entityName,
	onDelete,
	onFailedDelete,
}) => {
	const [modalIsOpen, setModalOpen] = useState(false);

	const del = useCallback(async () => {
		try {
			await deleteMutation(entityId);
			setModalOpen(false);
			if (onDelete) {
				onDelete(entityId);
			}
		} catch (e) {
			const errs = parseGraphqlError(e);
			sentryHandler(errs);
			modalHandler(errs);
			setModalOpen(false);
			if (onFailedDelete) {
				onFailedDelete(e);
			}
		}
	}, [entityId]);

	return (
		<>
			<Modal
				isOpen={modalIsOpen}
				toggle={() => setModalOpen(false)}
				backdrop="static"
				wrapClassName="renew-modal-wrapper"
				autoFocus={false}
			>
				<ModalBody>
					<h3 className="text-center">{t`Confirm delete text?`}</h3>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={() => setModalOpen(false)}>
						<i className="fa fa-times" /> {t`Undo`}
					</Button>{' '}
					<Button color="danger" onClick={del}>
						<i className="fa fa-trash" /> {t`Delete`} {t(entityName)}
					</Button>
				</ModalFooter>
			</Modal>
			{children(() => setModalOpen(true))}
		</>
	);
};
