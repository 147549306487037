import gql from 'graphql-tag';

export const UserFullFragment = gql`
    fragment UserFullFragment on User {
        id
        localId
        sfId
        firstName
        lastName
        name
        email
        role
        status
        workEmail
        langCode
        internalCapabilities
	     socialType
		  approvedToS {
				id
				name
				description
				descriptionIta
		  }
		  workEmail
        Team {
            name
            id
        }
    }
`;
