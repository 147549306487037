import React from 'react';
import { getUserData, logout, StepContainer, UserData } from '@food/auth';
import { Button, ButtonLayouts } from '@food/ui';
import { Redirect } from 'react-router';
import { t } from '../../utils/labels';
import { registerClass, currySC, classNames } from '@food/css-manager';
import CONFIG from '../../static/config';
import { Spinner } from '../GlobalSpinner';
import { UserRole } from '../../server-types';

const styleClass = registerClass(
	(t, sc) => `
	
	.${sc('main-text')} {
		margin-bottom: 1rem;
	}
	`,
);

const loginContainerStyle = registerClass(
	(t) => `
	> div {
		width: 90%;
		max-width: 700px;
		
		p {
			margin-bottom: ${t.ratios.xl}rem;
			&:last-child {
				margin-bottom: 0;
			}
			
		}
	}
	
	.${sc('link')} {
		color: #999;
		text-decoration: underline;
		font-weight: 300;
	}
`,
);

export const LoginContainer: React.FC = ({ children }) => (
	<div className={classNames('login-container', loginContainerStyle)}>
		{children}
	</div>
);

const sc = currySC(styleClass);

export const FoodServiceWelcome = () => {
	const user = getUserData();

	if (
		!user ||
		(user.role !== UserRole.Buyer && user.role !== UserRole.BuyerPlItalia)
	) {
		return <Redirect to={'/'} />;
	}

	return (
		<UserData>
			{({ userData, loading }) => {
				if (loading || !userData) {
					return <Spinner show={true} />;
				}

				return (
					<LoginContainer>
						<StepContainer>
							<h2>{t`buyer welcome title`}</h2>
							<section className={styleClass}>
								<p>
									<div
										className={sc('main-text')}
										dangerouslySetInnerHTML={{
											__html: t`buyer welcome html 1`,
										}}
									/>
								</p>
								<p>
									<div
										className={sc('main-text')}
										dangerouslySetInnerHTML={{
											__html: t`buyer welcome html 2`,
										}}
									/>
								</p>
								<p>
									{t`if you're not`} {userData.name}{' '}
									<Button
										label={t`change account`}
										layout={ButtonLayouts.LINK}
										className={sc('link')}
										onClick={() => logout()}
									/>
									.
								</p>
								<p>
									{t`if you think there's a problem with your account`}{' '}
									<a
										href={'mailto:' + CONFIG.SUPPORT_EMAIL}
									>{t`contact us`}</a>
									.
								</p>
							</section>
						</StepContainer>
					</LoginContainer>
				);
			}}
		</UserData>
	);
};
