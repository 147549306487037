import gql from 'graphql-tag';
import { MediaBaseFragment } from '../fragments/MediaBaseFragment';

export const goodForCandidacySelectQuery = gql`
	query GoodFodCandidacySelectQuery($filter: GoodFilterInput) {
		choices: goods(filter: $filter, orderBy: name, orderDesc: false) {
			edges {
				node {
					id
					name
					nameIta
					formats {
						id
						ean
					}
					descriptionLong
					descriptionLongIta
					featuredImageItaMedia {
						...MediaBaseFragment
					}
					featuredImageMedia {
						...MediaBaseFragment
					}
				}
			}
			total
		}
	}
	${MediaBaseFragment}
`;
