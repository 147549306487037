import gql from 'graphql-tag';
import {CompanyFullFragment} from "../fragments/CompanyFullFragment";

export const CompanyCreateMutation = gql`
    mutation CompanyCreateMutation($values: CompanyCreateInput!) {
        results: companyCreate(values: $values) {
            ...CompanyFullFragment
        }
    }
   ${CompanyFullFragment}
`;


