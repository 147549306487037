import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { GoodFeatureCertificationListQuery } from '../graphql/query/GoodFeatureCertificationListQuery';
import typeIntro from '../static/introspection/GoodFeatureCertification.json';
import SearchByNameFilter from '../static/introspection/SearchByNameInput.json';
import { ENTITY } from '../utils/entities';

export const GoodFeatureCertificationList = () => (
	<EntityListFetcher
		query={GoodFeatureCertificationListQuery}
		entityName={ENTITY.GOOD_FEATURE_CERTIFICATION}
		headers={['name', 'akas', 'website']}
		typeIntro={typeIntro}
		filtersIntro={SearchByNameFilter}
		filterFields={['name']}
	/>
);
