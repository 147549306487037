import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import typeIntro from '../static/introspection/DataViz.json';
import { ENTITY } from '../utils/entities';
import { DataVizListQuery } from 'graphql/query/DataVizListQuery';

const DataVizList = () => (
	<EntityListFetcher
		query={DataVizListQuery}
		entityName={ENTITY.DATA_VIZ}
		headers={[
			'targetSite',
			'editorial_section',
			'name',
		]}
		typeIntro={typeIntro}
	/>
);

export { DataVizList };
