import React from 'react';
import { BrandPatchMutation } from '../graphql/mutations/BrandPatchMutation';
import { BrandEditQuery } from '../graphql/query/BrandEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Brand.json';
import inputTypeIntro from '../static/introspection/BrandPatchInput.json';
import { editorialSectionsToTree, getExpanded } from '../utils/tree';
import { toRefInput } from '../utils/dataTrasform';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';
import { graphqlQuery } from '../utils/graphql';
import { fastTransformer, refactorInfo, toTree } from '../utils/form';
import { EntityEditModal } from '../types/entities';
import { Brand, Company } from '../server-types';

export const BrandEdit: React.FC<EntityEditModal<Brand, Company>> = ({
	id,
	onSubmit
}) => (
	<UserData>
		{({ isInternal }) => (
			<EntityEditFetcher
				id={id}
				onSubmit={onSubmit}
				modal={true}
				entityName={ENTITY.BRAND}
				fetcher={graphqlQuery(BrandEditQuery, { id })}
				mutation={BrandPatchMutation}
				typeIntro={typeIntro}
				inputTypeIntro={inputTypeIntro}
				entitiesToFields={(props) => ({
					EditorialSections: props.editorialSectionTree,
				})}
				fields={[
					'name',
					'logoMedia',
					'pricePositioning',
					isInternal ? 'goodClasses' : null,
					'retailChainDistribution',
					'foodServiceChainDistribution',
					'gourmetShopChainDistribution',
					isInternal ? 'order' : null,
					'website',
					'socialInstagramPage',
					'socialFacebookPage',
				]}
				transformer={(data, entity, listsByType) => {
					data.fields = fastTransformer({
						goodClasses: (f) => {
							if (f.type !== 'AsyncChoices') {
								refactorInfo(f, 'AsyncChoices');
								return f;
							}
							const newField = toTree(
								f,
								false,
								editorialSectionsToTree(listsByType.EditorialSections),
							);
							if (f.value) {
								newField.value = f.value.map((choice) =>
									toRefInput(choice.value),
								);
								newField.expanded = getExpanded(
									listsByType.EditorialSections,
									f.value,
								);
							}

							return newField;
						},
					})(data.fields);

					return data;
				}}
			/>
		)}
	</UserData>
);
