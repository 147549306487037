import gql from 'graphql-tag';

export const GoodCreateQuery = gql`
    query GoodCreateQuery {
        editorialSectionTree(EditorialBrand: GOODS4HUB) {
            id
            name
            Parent {
                id
            }
            goodClasses {
                id
                shortname
                shortnameIta
                localId
                name
                nameIta
            }
        }
        brands {
            edges {
                node {
                    name
                    id
                    localId
                }
            }
        }
    }
`;


