import React from 'react';
import { FrequentSearchDetailQuery } from '../graphql/query/FrequentSearchDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/FrequentSearch.json';
import { ENTITY } from '../utils/entities';
import { setFrequentSearchStatusMutation } from '../graphql/mutations/setFrequentSearchStatusMutation';
import { StatusSetter } from '../components/StatusSetter';
import FrequentSearchStatusIntrospection from '../static/introspection/FrequentSearchStatus.json';
const statuses = FrequentSearchStatusIntrospection.enumValues.map(
	(o) => o.name,
);

export const FrequentSearchDetail = ({ id }) => (
	<EntityDetailFetcher
		query={FrequentSearchDetailQuery}
		entityName={ENTITY.FREQUENT_SEARCH}
		id={id}
		typeIntro={typeIntro}
		customActionComponents={(entity) => [
			<StatusSetter
				key={1}
				statuses={statuses}
				entityName={ENTITY.FREQUENT_SEARCH}
				entity={entity}
				statusFieldName={'status'}
				mutation={setFrequentSearchStatusMutation}
				entityIntrospection={typeIntro}
			/>,
		]}
		fields={[
			'collection',
			'targetSites',
			'name',
			'nameIta',
			'akas',
			'User',
			'Team',
			'highlightReasons',
			'goodClasses',
			'goodFeatureCertifications',
			'geoIndications',
			'companyCertifications',
			'plProducer',
			'fairs',
			'awards',
			'awardFinalPositions',
			'textSearch',
		]}
		transformer={(data) => {
			data.fields.forEach((f) => {
				switch (f.name) {
					case 'goodFeatureCertifications':
					case 'companyCertifications':
					case 'goodClasses':
					case 'stateProvinces':
					case 'geoIndications':
					case 'fairs':
					case 'awards':
						f.type = 'AsyncChoices';
						break;

					default:
						break;
				}
			});
			return data;
		}}
	/>
);
