import gql from 'graphql-tag';
import {EuCategoryFullFragment} from "../fragments/EuCategoryFullFragment";

export const EuCategoryEditQuery = gql`
	query EuCategoryEditQuery($id: ID!) {
		entity: node (id: $id) {
				...EuCategoryFullFragment
		  }
	}
	${EuCategoryFullFragment}
`;


