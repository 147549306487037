import React, { Fragment } from 'react';
import { FieldSetter, IField } from '../../types/form';
import { renderField } from '../../utils/form';

interface IFieldListProps {
	fields: ReadonlyArray<IField>;
	setter: FieldSetter;
	path?: string;
	isModal?: boolean;
}

export const FieldList: React.FC<IFieldListProps> = ({
	fields,
	setter,
	isModal = false,
	path,
}) => {
	return (
		<>
			{fields.map((field) => (
				<Fragment key={field.name}>
					{renderField(
						field,
						(path ? path + '/' : '') + field.name,
						setter(field),
						isModal,
					)}
				</Fragment>
			))}
		</>
	);
};
