import gql from 'graphql-tag';
import {AwardFullFragment} from "../fragments/AwardFullFragment";

export const AwardPatchMutation = gql`
	mutation AwardPatchMutation($id: ID!, $changes: AwardPatchInput!) {
		results: awardPatch(id: $id, changes: $changes) {
			...AwardFullFragment
		}
	}
	${AwardFullFragment}
`;


