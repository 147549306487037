import gql from 'graphql-tag';
import {UserFullFragment} from "../fragments/UserFullFragment";

export const UserPatchMutation = gql`
	mutation UserPatchMutation($id: ID!, $changes: UserPatchInput!) {
		results: userPatch(id: $id, changes: $changes) {
			...UserFullFragment
		}
	}
	${UserFullFragment}
`;


