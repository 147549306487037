import { FieldsTransformer, IField } from '../../types/form';
import {
	fastTransformer,
	refactorInfo,
	transform,
	generateHiddenField,
	toHidden,
} from '../form';
import { eLabel, generateFakeAsyncQuery, toRequired } from '../misc';
import { toRefInput } from '../dataTrasform';
import { ENTITY } from '../entities';
import { t } from '../labels';
import { generateAsyncQuery, generatePaginatedSelectQuery } from '../graphql';
import { identity } from 'ramda';

const managedByUserT = (originalEntity: any): FieldsTransformer => {
	const originalEntityType = originalEntity.__typename;
	
	return transform(
		fastTransformer({
			User:
				originalEntityType === ENTITY.USER
					? toHidden(toRefInput(originalEntity))
					: identity,
		}),
		originalEntityType !== ENTITY.USER &&
			((fs: ReadonlyArray<IField>) =>
				fs.concat([
					generateHiddenField(
						originalEntityType,
						toRefInput(originalEntity),
					),
				])),
		originalEntityType === ENTITY.USER &&
			((fs: ReadonlyArray<IField>) =>
				fs.concat([
					toRequired({
						type: 'Choices',
						name: 'Type',
						label: eLabel(ENTITY.MANAGED_BY_USER, 'entityType'),
						isEnum: true,
						single: true,
						description: {},
						validators: [],
						choices: [
							ENTITY.COMPANY,
							ENTITY.COMPANY_GROUP,
							ENTITY.SERVICE,
							ENTITY.RETAILER,
							ENTITY.FOOD_SERVICE,
						].map((c) => ({ label: t(c), value: c })),
						afterChange: (field, { value }, fields) =>
							fields.map((f) => {
								if (f.name === 'Entity') {
									if (f.type !== 'AsyncChoices') {
										refactorInfo(f, 'AsyncChoices');
										return f;
									}
									f.asyncQuery = generateAsyncQuery(
										generatePaginatedSelectQuery(value),
										true,
									);
									f.disabled = false;
								}
								return f;
							}),
					}),
					toRequired({
						type: 'AsyncChoices',
						description: {},
						validators: [],
						asyncQuery: generateFakeAsyncQuery([]),
						entityType: ENTITY.MANAGED_BY_USER,
						name: 'Entity',
						label: eLabel(ENTITY.MANAGED_BY_USER, 'entity'),
						single: true,
						disabled: true,
						beforeSaveTransformer: (option) => toRefInput(option.value),
					}),
				])),
	);
};

export { managedByUserT };
