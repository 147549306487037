import gql from 'graphql-tag';
import {GoodFullFragment} from "../fragments/GoodFullFragment";

export const GoodCreateMutation = gql`
	mutation GoodCreateMutation($values: GoodCreateInput!) {
		results: goodCreate(values: $values) {
			...GoodFullFragment
		}
	}
	${GoodFullFragment}
`;


