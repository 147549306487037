import gql from 'graphql-tag';

export const CompanyLevelFullFragment = gql`
	fragment CompanyLevelFullFragment on CompanyLevel {
		id
		localId
		Company {
			id
			name
		}
		targetSite
		startDate
		expirationDate
		level
		description
		descriptionLong
		videoUrl
		layout
		website
		newsPath
		companyBadges {
			id
			name
		}
	}
`;
