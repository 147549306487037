import React from 'react';
import {
	Modal,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Button,
	Alert,
} from 'reactstrap';
import { t } from '../utils/labels';

export interface ValidationErrorsModalProps {
	open: boolean;
	onToggle: (open: boolean) => void;
}

export const ValidationErrorsModal: React.FunctionComponent<
	ValidationErrorsModalProps
> = ({ open, onToggle }) => {
	return (
		<Modal autoFocus={false} isOpen={open} toggle={() => onToggle(false)}>
			<ModalHeader
				toggle={() => onToggle(false)}
			>{t`validation errors`}</ModalHeader>
			<ModalBody>
				<Alert color="danger"> {t`validation errors introduction`}</Alert>
				Sono presenti alcuni errori di compilazione nel Form. Per cortesia ricontrolla i campi di input e verifica
				che ognuno sia compilato correttamente.
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={() => onToggle(false)}>
					<i className="fa fa-times" /> {t`close`}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
