import gql from 'graphql-tag';
import {DistributionChannelFullFragment} from "../fragments/DistributionChannelFullFragment";

export const DistributionChannelPatchMutation = gql`
	mutation DistributionChannelPatchMutation($id: ID!, $changes: DistributionChannelPatchInput!) {
		results: distributionChannelPatch(id: $id, changes: $changes) {
			...DistributionChannelFullFragment
		}
	}
	${DistributionChannelFullFragment}
`;


