import 'utils/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppLayout } from './components/AppLayout';
import { Router } from 'react-router-dom';
import CONFIG from './static/config';
import { ApolloProvider } from 'react-apollo';
import * as ReactGA from 'react-ga';
import * as git from './static/rev.json';
import { client } from './utils/client';
import { injectCSS, defaultTheme, IFNTheme } from '@food/css-manager';
import { init } from '@food/tracking';
import { history } from './utils/history';
import * as Sentry from '@sentry/browser';
import { isLogged, getUserData } from '@food/auth';
import { getSession, getTab } from '@food/tracking';
import dotenv from 'dotenv';

// CSS
// import './template/css/style.css';
import './css/bootstrap-reboot.css';
import './css/bootstrap_mod.css';
import './template/css/style-unminify.css';
import './template/css/colors/red.css';
import './css/spinner.css';
import '../node_modules/react-virtualized/styles.css';
import '../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css';
import '../node_modules/react-datepicker/dist/react-datepicker.min.css';
import './index.css';

if (!window.fetch) {
	(window as any).location = '/ie11_welcome_message.html';
} else {
	dotenv.config()
	
	init({
		baseUrl: CONFIG.API_ENDPOINT.TRACKING,
		bufferLength: 1,
		targetSite: CONFIG.TARGET_SITE,
	});

	if (process.env.NODE_ENV !== 'production') {
		// const whyDidYouRender = require('@welldone-software/why-did-you-render');
		// whyDidYouRender(React);
	}

	// inizializzo Google Analytics
	if (CONFIG.GA_ID) {
		ReactGA.initialize(CONFIG.GA_ID);
		if (isLogged()) {
			const user = getUserData();
			ReactGA.set({ userId: user.id });
		}
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	// inizializzo il sistema di autenticazione, caricando eventualmente i dati da localstorage
	history.listen(() => {
		if (CONFIG.GA_ID) {
			ReactGA.set({
				page: window.location.pathname + window.location.search,
			});
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	});

	(window as any).myHistory = history;

	Sentry.init({
		dsn: CONFIG.SENTRY_SERVER,
		release: (git as any).rev,
		environment: process.env.NODE_ENV,
		normalizeDepth: 20,
	});

	Sentry.configureScope((scope) => {
		scope.setTag('backend', CONFIG.HOST);
		scope.setTag('session', getSession());
		scope.setTag('tab', getTab());
		scope.setTag('SSR', 'false');

		if (isLogged()) {
			const user = getUserData();
			scope.setUser({
				id: user.id,
				username: user.name,
				email: user.email,
			});
		}
	});

	const theme: IFNTheme = {
		...defaultTheme,
		zIndexes: {
			...defaultTheme.zIndexes,
			P3_ContextualPopup: 2000,
		},
		colors: {
			...defaultTheme.colors,
			danger: {
				...defaultTheme.colors.danger,
				plain: '#f62d51',
			},
		},
	};

	injectCSS({ theme });

	if (window.location.href.indexOf('hub2') !== -1) {
		// alert(
		// 	'Attenzione: sei su una versione di sviluppo di HUB. Per accedere alla versione corrente vai su https://hub.italianfood.net',
		// );
	}

	ReactDOM.render(
		<ApolloProvider client={client}>
			<Router history={history}>
				<AppLayout />
			</Router>
		</ApolloProvider>,
		document.getElementById('root'),
	);
}
