import gql from 'graphql-tag';
import { FoodServiceFullFragment } from '../fragments/FoodServiceFullFragment';

export const FoodServicePatchMutation = gql`
	mutation FoodServicePatchMutation(
		$id: ID!
		$changes: FoodServicePatchInput!
	) {
		results: foodServicePatch(id: $id, changes: $changes) {
			...FoodServiceFullFragment
		}
	}
	${FoodServiceFullFragment}
`;
