import React from 'react';
import { GoodClassCreateMutation } from '../graphql/mutations/GoodClassCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/GoodClass.json';
import inputTypeIntro from '../static/introspection/GoodClassCreateInput.json';
import { GoodClassEditQuery } from '../graphql/query/GoodClassEditQuery';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const GoodClassCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(GoodClassEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={GoodClassCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.GOOD_CLASS}
		fields={[
			'name',
			'nameIta',
			'akas',
			'level1Code',
			'level1Desc',
			'level1DescIta',
			'level2Code',
			'level2Desc',
			'level2DescIta',
			'level3Code',
			'level3Desc',
			'level3DescIta',
			'level4Code',
			'level4Desc',
			'level4DescIta',
			'shortname',
			'shortnameIta',
			'preservation',
			'trasformationLevel',
		]}
	/>
);
