import gql from 'graphql-tag';
import { AnswerSetFullFragment } from '../fragments/AnswerSetFullFragment';

export const answerSetPatchMutation = gql`
	mutation AnswerSetPatchMutation($id: ID!, $changes: AnswerSetPatchInput!) {
		results: answerSetPatch(id: $id, changes: $changes) {
			...AnswerSetFullFragment
		}
	}
	${AnswerSetFullFragment}
`;
