import gql from 'graphql-tag';
import {GoodFormatFullFragment} from "../fragments/GoodFormatFullFragment";

export const GoodFormatEditQuery = gql`
	query GoodFormatEditQuery($id: ID!) {
		entity: node (id: $id) {
				...GoodFormatFullFragment
		  }
	}
	${GoodFormatFullFragment}
`;


