import { EditorialSection } from '../server-types';

export function editorialSectionsToTree(editorial_sections) {
	const getChildren = (id) =>
		editorial_sections
			.filter((s) => s.Parent === id || (s.Parent && s.Parent.id === id))
			.map((s) => {
				const gcChildren = s.goodClasses
					? s.goodClasses.map((gc) => ({
							value: 'leaf' + gc.id,
							label: gc.shortnameIta,
							name: gc.shortnameIta,
					  }))
					: [];
				return {
					value: 'branch' + s.id, // s.id is base64 -> can start by 'leaf' -> prefix needed
					label: s.name,
					name: s.name,
					children: getChildren(s.id).concat(gcChildren),
				};
			});

	return getChildren(null);
}

export function countriesToTree(countries) {
	const areas = countries.reduce((list, country) => {
		if (list.indexOf(country.area) === -1) list.push(country.area);
		return list;
	}, []);
	return areas.map((area) => ({
		value: area,
		label: area,
		name: area,
		children: countries
			.filter((c) => c.area === area)
			.map((c) => ({
				value: 'leaf' + c.id,
				label: c.name,
				name: c.name,
			})),
	}));
}

export function getExpanded(
	editorial_sections: ReadonlyArray<EditorialSection>,
	selecteds: ReadonlyArray<{ id: string }>,
): ReadonlyArray<EditorialSection> {
	if (!editorial_sections || !selecteds) {
		return [];
	}
	const bottomUp = (section) => {
		if (!section.Parent) return [section];
		const parent = editorial_sections.filter(
			(es) => es.id === section.Parent.id,
		)[0];
		return bottomUp(parent).concat([section]);
	};

	const strict_parents = selecteds.reduce(
		(array, selected) =>
			array.concat(
				editorial_sections.filter(
					(section) =>
						section.goodClasses &&
						section.goodClasses.some((gc) => gc.id === selected.id),
				),
			),
		[],
	);

	return strict_parents.reduce((array, es) => array.concat(bottomUp(es)), []);
}
