import { IField } from '../../types/form';
import {
	fastTransformer,
	generateBaseField,
	generateTextField,
	refactorInfo,
	toColor,
	toMediaField,
	toUrl,
} from '../form';
import { assign, Dict, fieldLabel, googleFontInputClean, toRequired } from '../misc';
import { ENTITY } from '../entities';
import { t } from '../labels';
import { EditorialBrand, InputTemplate, MediaType, NewsletterBrand } from '../../server-types';
import { generateAsyncQuery, graphqlQuery, mediaQuery } from '../graphql';
import { NewsletterDetailQuery } from '../../graphql/query/NewsletterDetailQuery';
import { modalHandler, parseGraphqlError, sentryHandler } from '../errors';
import { ERROR_CODES } from '../validation';
import { EditorialSectionSelectQuery } from 'graphql/query/EditorialSectionSelectQuery';

/**
 * Dati gli IField di una Newsletter e il template scelto, aggiunge i campi dinamici legati alla personalizzazione del
 * template
 * @param fields: ReadonlyArray<IField>
 * @param template: string
 * @param values?: Dict
 * @return ReadonlyArray<IField>
 */
const changeFields = (
	fields: ReadonlyArray<IField>,
	changedValue: {
		template?: string,
		target?: NewsletterBrand,
	},
	values?: Dict,
	manualChange?: boolean
): ReadonlyArray<IField> => {
	// i campi originali sono 4, quindi se cambio valore devo lavorare solo sui successivi
	const originalFields = fields.slice(0, 5);

	// creo una funzione comoda per centralizzare il comportamento dei label
	const label = (s: string) => t(fieldLabel(ENTITY.NEWSLETTER, s));
	const helpText = (s: string) => t(fieldLabel(ENTITY.NEWSLETTER, s, true));

	const templateValue = (changedValue.template) ? changedValue.template : originalFields && originalFields.filter((f) => f.name === 'inputTemplate')[0]?.value?.value;

	if (templateValue === InputTemplate.NewsletterGeneric) {
		fields = [
			toRequired(
				toMediaField(
					generateBaseField(
						'headerMedia',
						label('headerMedia'),
						helpText('headerMedia'),
					),
					MediaType.MailImage,
				),
			),
			toRequired(
				toColor(
					generateBaseField(
						'primaryColor',
						label('primaryColor'),
						helpText('primaryColor'),
					),
				),
			),
			toUrl(generateTextField('link', label('link'))),
			generateTextField('fontTitles', label('fontTitles')),
			generateTextField('fontBody', label('fontBody')),
			toColor(
				generateBaseField(
					'backgroundColor',
					label('backgroundColor'),
					helpText('backgroundColor'),
				),
			),
			toColor(generateBaseField('gradientColor1', label('gradientColor1'))),
			toColor(generateBaseField('gradientColor2', label('gradientColor2'))),
			toMediaField(
				generateBaseField('logo', label('logo'), helpText('logo')),
				MediaType.Logo,
			),
			{
				...generateTextField('prefooter', label('prefooter')),
				multiline: true
			},
			{
				...generateTextField('prefooterHtml', label('prefooterHtml')),
				multiline: true
			},
			{
				...generateBaseField('gradientDegree', label('gradientDegree')),
				type: 'Int',
				validators: [
					(v: number) => {
						if (v < 0) {
							return new Error(ERROR_CODES.MIN);
						} else if (v > 359) {
							return new Error(ERROR_CODES.MAX);
						}
						return false;
					},
				],
			},
			{
				...generateTextField('footerHtml', label('footerHtml')),
				multiline: true
			}
		];

		
		const targetValue = (changedValue.target) ? changedValue.target : originalFields && originalFields.filter((f) => f.name === 'target')[0]?.value?.value;
		const fieldsWithValues = fields.map((f) => {

			// se è cambiato il targetValue
			if (manualChange && (changedValue.target || !values[f.name])) {
				// inserisco i font di default se non sono valorizzati
				if (values && ['fontTitles','fontBody'].indexOf(f.name) > -1) {
					switch(targetValue) {
						case NewsletterBrand.Dolcesalato:
							values[f.name] = 'Roboto';
						break;
						case NewsletterBrand.Food:
						case NewsletterBrand.Foodservice:
						case NewsletterBrand.Ifn:
							values[f.name] = 'Montserrat';
						break;
						default:
							values[f.name] = '';
					}
				}
			}
			
			return Object.assign(f, {
					value: values && values[f.name],
					changed: true,
				})
			}
		);

		return originalFields.concat(fieldsWithValues);
	} else if(templateValue === InputTemplate.DemGeneric) {
		fields = [
			generateTextField('fontTitles', label('fontTitles')),
			generateTextField('fontBody', label('fontBody'))
		];

		const targetValue = (changedValue.target) ? changedValue.target : originalFields && originalFields.filter((f) => f.name === 'target')[0]?.value?.value;

		const fieldsWithValues = fields.map((f) => {
			// se è cambiato il targetValue
			if (manualChange && (changedValue.target || !values[f.name])) {
				// inserisco i font di default se non sono valorizzati
				if (values && ['fontTitles','fontBody'].indexOf(f.name) > -1) {
					switch(targetValue) {
						case NewsletterBrand.Dolcesalato:
							values[f.name] = 'Roboto';
						break;
						case NewsletterBrand.Food:
						case NewsletterBrand.Foodservice:
						case NewsletterBrand.Ifn:
							values[f.name] = 'Montserrat';
						break;
						default:
							values[f.name] = '';
					}
				}
			}
			

			return Object.assign(f, {
				value: values && values[f.name],
				changed: true,
			});
		});

		return originalFields.concat(fieldsWithValues);
	}

	return originalFields;
};

/**
 * transformer per creazione/edit di entita' Newsletter
 * @param fields: ReadonlyArray<IField>
 * @param values?: any
 * @returns ReadonlyArray<IField>
 */

const newsletterTransformer = (
	fields: ReadonlyArray<IField>,
	values: any = {}
): ReadonlyArray<IField> => {
	let step1 = fastTransformer({
		inputTemplate: assign({
			afterChange: (field, { value }, fields) => {
				return changeFields(fields, { template: value }, values, true)
			}
		}),
		target: assign({
			afterChange: (field, { value }, fields) => {
				return changeFields(fields, { target: value }, values, true);
			}
		}),
	})(fields);

	step1 = ((fields:any):readonly IField[] => fields.forEach((f:IField) => {
		switch (f.name) {
			case 'target':
				f.afterChange = (ff, value, fields) => {
					return updateEditorialSectionSelectableValuesForNewsletter(fields);
				}
				break;
			default:
				break;
		}
	}))(fields);

	step1 = updateEditorialSectionSelectableValuesForNewsletter(fields);


	const templateValue = values.inputTemplate;

	if (!templateValue) {
		return step1;
	}

	const targetValue = values.target;
	if (!targetValue) {
		return step1;
	}

	// se templateValue risulta valorizzato significa che sono nella edit, quindi al primo giro
	// devo estendere i campi presenti nella form con changeFields e valorizzarli
	return changeFields(step1, { template: templateValue, target: targetValue }, values);
};

const newsletterBeforeSaveTransformer = (values: Dict): Dict => {
	const {
		headerMedia,
		link,
		fontTitles,
		fontBody,
		primaryColor,
		gradientColor1,
		gradientColor2,
		gradientDegree,
		backgroundColor,
		logo,
		prefooter,
		footerHtml,
		...newValues
	} = values;

	newValues.values = JSON.stringify({
		headerMedia,
		link,
		fontTitles: googleFontInputClean(fontTitles),
		fontBody: googleFontInputClean(fontBody),
		primaryColor,
		gradientColor1,
		gradientColor2,
		gradientDegree,
		backgroundColor,
		logo,
		prefooter,
		footerHtml,
	});

	return newValues;
};

const newsletterFetcher = (id: string) => async (): Promise<any> => {
	try {
		const data = await graphqlQuery(NewsletterDetailQuery, { id })();
		const { entity } = data;
		const additionalData = JSON.parse(entity.values);

		if (entity.inputTemplate === InputTemplate.NewsletterGeneric) {
			if (additionalData && additionalData.headerMedia) {
				const media = await graphqlQuery(
					mediaQuery,
					additionalData.headerMedia,
				)();
				additionalData.headerMedia.media = media.node;
			}
			if (additionalData && additionalData.logo) {
				const media = await graphqlQuery(mediaQuery, additionalData.logo)();
				additionalData.logo.media = media.node;
			}
		}

		return {
			...data,
			entity: {
				...data.entity,
				...additionalData,
				values: undefined,
			},
		};
	} catch (e) {
		const errs = parseGraphqlError(e);
		modalHandler(errs);
		sentryHandler(errs);
		return null;
	}
};

const TargetSite2EditorialBrand: any = (targetSite: string) => {
	// controllo se il valore passato è un valore valido all'interno dell'enum EditorialBrand
	let keys = Object.keys(EditorialBrand).filter(x => EditorialBrand[x] == targetSite);
    return keys.length > 0 ? targetSite : null;
}

const updateEditorialSectionSelectableValuesForNewsletter: any = (fields: any, updateValues: boolean = true) => {
	const targetField = fields.filter((f) => f.name == 'target')[0];
	const editorialSectionField = fields.filter((f) => f.name == 'EditorialSection')[0];

	if (targetField.value && targetField.value.value) {
		if (TargetSite2EditorialBrand(targetField.value.value) !== null) {
			if (editorialSectionField.type !== 'AsyncChoices') {
				refactorInfo(editorialSectionField, 'AsyncChoices');
			} else {
				editorialSectionField.asyncQuery = generateAsyncQuery(
					EditorialSectionSelectQuery,
					true,
					(value) => {
						return {
							filter: {
								name: value,
								EditorialBrand: TargetSite2EditorialBrand(targetField.value.value)
							},
						};
					},
				);
			}
			editorialSectionField.disabled = false;
		} else {
			editorialSectionField.disabled = true;
			editorialSectionField.value = null;
		}		
	} else {
		editorialSectionField.disabled = true;
	}
	
	return fields;
}



export {
	newsletterTransformer,
	newsletterBeforeSaveTransformer,
	newsletterFetcher,
};
