import gql from 'graphql-tag';
import { EditorialSectionFullFragment } from './EditorialSectionFullFragment';

export const NewsletterFullFragment = gql`
	fragment NewsletterFullFragment on Newsletter {
		id
		localId
		name
		target
		delivery
		inputTemplate
		EditorialSection {
			...EditorialSectionFullFragment
		}
		values
	}
	${EditorialSectionFullFragment}
`;
