import gql from 'graphql-tag';

export const UserListQuery = gql`
	query UserListQuery(
		$first: Int
		$cursor: String
		$orderBy: UserOrderBy
		$orderDesc: Boolean
		$filter: UserFilterInput
	) {
		entities: users(
			first: $first
			after: $cursor
			orderBy: $orderBy
			orderDesc: $orderDesc
			filter: $filter
		) {
			edges {
				node {
					id
					localId
					firstName
					lastName
					name
					email
					role
					status
					workEmail
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;


