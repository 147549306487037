import gql from 'graphql-tag';
import {CandidacyRatingFullFragment} from "../fragments/CandidacyRatingFullFragment";

export const CandidacyRatingEditQuery = gql`
	query CandidacyRatingEditQuery($id: ID!) {
		entity: node (id: $id) {
				...CandidacyRatingFullFragment
		  }
	}
	${CandidacyRatingFullFragment}
`;


