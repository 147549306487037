import React from 'react';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/StoreChain.json';
import inputTypeIntro from '../static/introspection/StoreChainPatchInput.json';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';
import { StoreChainPatchMutation } from 'graphql/mutations/StoreChainPatchMutation';
import { StoreChainDetailQuery } from 'graphql/query/StoreChainDetailQuery';
import { storeChainTransformer } from 'utils/entities/storeChain';

const StoreChainEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(StoreChainDetailQuery, { id })}
		mutation={StoreChainPatchMutation}
		entityName={ENTITY.STORECHAIN}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={[
			'Retailer',
			'Foodservice',
			'Service',
			'Company',
			'name',
			'logo_media',
			'price_positioning',
			'website',
			'social_instagram_page',
			'social_facebook_page',
			'description_ita',
			'description_long_ita',
			'stores_ownership',
			'type',
			'goodClasses',
			'countries'
		]}
		entitiesToFields={(props) => ({
			EditorialSections: props.editorialSectionTree
		})}
		transformer={(...args) => storeChainTransformer(null, ...args)}
	/>
);

export { StoreChainEdit };
