import gql from 'graphql-tag';
import {GoodClassFullFragment} from "../fragments/GoodClassFullFragment";

export const GoodClassDetailQuery = gql`
	query GoodClassDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...GoodClassFullFragment
		}
	}
	${GoodClassFullFragment}
`;


