import gql from 'graphql-tag';
import {CompanyFullFragment} from "../fragments/CompanyFullFragment";


export const CompanyPatchMutation = gql`
    mutation CompanyPatchMutation($id: ID!, $changes: CompanyPatchInput!) {
        results: companyPatch(id: $id, changes: $changes) {
            ...CompanyFullFragment
        }
    }
	${CompanyFullFragment}
`;



