import gql from 'graphql-tag';
import {GoodFeatureCertificationFullFragment} from "../fragments/GoodFeatureCertificationFullFragment";

export const GoodFeatureCertificationEditQuery = gql`
	query GoodFeatureCertificationEditQuery($id: ID!) {
		entity: node (id: $id) {
				...GoodFeatureCertificationFullFragment
		  }
	}
	${GoodFeatureCertificationFullFragment}
`;


