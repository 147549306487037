import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { FoodServiceListQuery } from '../graphql/query/FoodServiceListQuery';
import typeIntro from '../static/introspection/FoodService.json';
import FoodServiceFilters from '../static/introspection/FoodServiceFilterInput.json';
import { ENTITY } from '../utils/entities';

export const FoodServiceList = () => (
	<EntityListFetcher
		query={FoodServiceListQuery}
		entityName={ENTITY.FOOD_SERVICE}
		headers={['name', 'dimension', 'type']}
		sortables={['name', 'dimension', 'type']}
		typeIntro={typeIntro}
		filtersIntro={FoodServiceFilters}
		filterFields={[
			'name',
			'producerChangedAfter',
			'dimensions',
			'types',
			'Country',
		]}
	/>
);
