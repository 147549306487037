import gql from 'graphql-tag';

export const EuCategoryListQuery = gql`
	query EuCategoryListQuery(
		$first: Int,
		$cursor: String,
		$filter: SearchByNameInput
	) {
		entities: euCategories(first: $first, after: $cursor, filter: $filter) {
			edges {
				node {
					id
					localId
					name
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}

	}
`;


