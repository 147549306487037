import gql from 'graphql-tag';


export const CompanyWithBrandsSelectQuery = gql`
	query CompanyWithBrandsSelectQuery($filter: CompanyFilterInput) {
       choices: companies(filter: $filter, orderBy: name, orderDesc:false) {
           edges {
               node {
                   id
                   localId
                   name
                   brands {
                       id
                       localId
                       name
                   }
						 
               }
           }
           total
			  pageInfo {
					hasNextPage
			  }
       }
   }
`;