import gql from 'graphql-tag';

export const answerSetListQuery = gql`
    query AnswerSetListQuery(
    $first: Int,
    $cursor: String,
    $filter: AnswerSetFilterInput
    ) {
        entities: answerSets(first: $first, after: $cursor, filter: $filter) {
            edges {
                node {
                    id
                    localId
                    sentDate
	                 
                    QuestionSet {
                        id
                        sentDate
								User {
									 id
									 name
									 role
								}
                    }
						  
						  Company {
								id
								name
						  }
						  
                }
            }
            total
            pageInfo {
                hasNextPage
                hasPreviousPage
            }
        }

    }
`;
