import React from 'react';
import { ServicePatchMutation } from '../graphql/mutations/ServicePatchMutation';
import { ServiceEditQuery } from '../graphql/query/ServiceEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Service.json';
import inputTypeIntro from '../static/introspection/ServicePatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { EntityEditPage } from '../types/entities';
import { Service } from '../server-types';
import { UserData } from '@food/auth';

export const ServiceEdit: React.FC<EntityEditPage<Service>> = ({ id }) => (
	<UserData>
		{({ isInternal }) => (
			<EntityEditFetcher
				fetcher={graphqlQuery(ServiceEditQuery, { id })}
				mutation={ServicePatchMutation}
				entityName={ENTITY.SERVICE}
				typeIntro={typeIntro}
				inputTypeIntro={inputTypeIntro}
				id={id}
				fields={[
					isInternal ? 'sfId' : null,
					'name',
					'logoMedia',
					'description',
					'descriptionIta',
					'descriptionLong',
					'descriptionLongIta',
					'pecEmail',
					'vatNumber',
					'website',
					'Country',
					'StateProvince',
					'postalCode',
					'city',
					'address1',
					'address2',
					'lat',
					'lng',
				]}
			/>
		)}
	</UserData>
);
