import gql from 'graphql-tag';
import {MagazineContentFullFragment} from "../fragments/MagazineContentFullFragment";

export const MagazineContentDetailQuery = gql`
	query MagazineContentDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...MagazineContentFullFragment
		}
	}
	${MagazineContentFullFragment}
`;


