import gql from 'graphql-tag';

export const AwardResultsQuery = gql`
	query AwardResultsQuery($id: ID!) {
		node(id: $id) {
			id
			... on Award {
				categories {
					id
					awardResults {
						id
						AwardCategory {
							id
							name
						}
						Candidacy {
							id
							name
							Company {
								id
								name
							}
						}
						finalPosition
					}
				}
			}
		}
	}
`;


