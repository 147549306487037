import gql from 'graphql-tag';
import { generateAsyncQuery } from '../utils/graphql';
import { ReportType } from '../components/Report';
import { AwardSelectQuery } from '../graphql/query/AwardSelectQuery';
import { html2text, nodes } from '../utils/misc';
import moment from 'moment';
import { InternalCapability } from '../server-types';

export const showcaseCSV: ReportType = {
	title: 'CSV per showcase',
	filename: (data, pre, form) => {
		const award = form.Award.value;
		const today = moment().format('DD-MM-YYYY');
		return `showcase ${award.name} ${award.year} ${today}`.replace(/ /g, '_');
	},
	requirements: [InternalCapability.MarketingExtra],
	query: gql`
		query QueryRep2(
			$after: String
			$filter: CandidacyFilterInput
			$first: Int
		) {
			connector: candidacies(after: $after, filter: $filter, first: $first) {
				edges {
					node {
						id
						name
						description3
						featuredImageMedia {
							id
							origUrl
							mediumThumbUrl
						}
						goods {
							id
							name
							featuredImageMedia {
								id
								mediumThumbUrl
							}
							Brand {
								id
								name
								Company {
									id
									name
								}
							}
							formats {
								id
								cuMesureUnit
								cuMesureValue
								cuPerSellingPack
							}
						}
						launchDate
						additionalImagesMedia {
							id
							mediumThumbUrl
						}
					}
				}
				pageInfo {
					hasNextPage
				}
			}
		}
	`,
	fields: [
		{
			type: 'AsyncChoices',
			name: 'Award',
			label: 'Award',
			enum: false,
			description: {},
			single: true,
			asyncQuery: generateAsyncQuery(AwardSelectQuery, true),
		},
	],

	header: () => [
		'id',
		'Product_Name',
		'Company_Name',
		'Category_Name',
		'Product_Image',
		'Format',
		'Items Per Selling Unit',
		'Seller Price_Per Item',
		'SellerPrice_per_Kg',
		'PriceStandards',
		'MarkUp_%',
		'Media',
		'Typeform_Url',
		'Video',
		// 'Faq',
	],
	preVariablesGenerator: (values: any) => ({ id: values.Award.value.id }),
	variablesGenerator: (pre: any, values: any) => ({
		filter: {
			Award: { id: values.Award.value.id },
		},
	}),
	extractor: (data: any) =>
		nodes(data.connector)
			.reduce((list, candidacy) => {
				candidacy.goods.forEach((good) => {
					list.push({
						candidacy,
						good,
					});
				});
				return list;
			}, [])
			.reverse(),
	transformer: (e: any) => [
		'',
		e.candidacy.name,
		e.good.Brand.Company.name,
		'',
		e.candidacy.featuredImageMedia
			? e.candidacy.featuredImageMedia.mediumThumbUrl
			: 'ALERT',
		e.good.formats
			.map((f) => `${f.cuMesureValue} ${f.cuMesureUnit}`)
			.join(', '),
		e.good.formats.map((f) => f.cuPerSellingPack).join(', '),
		'',
		'',
		html2text(e.candidacy.description3),
		'',
		e.candidacy.additionalImagesMedia.map((m) => m.mediumThumbUrl).join(', '),
		'',
		'',
		// '',
	],
};
