import React from 'react';
import { MagazineCreateMutation } from '../graphql/mutations/MagazineCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Magazine.json';
import inputTypeIntro from '../static/introspection/MagazineCreateInput.json';
import { MagazineEditQuery } from '../graphql/query/MagazineEditQuery';
import { generateAsyncQuery, graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';
import { refactorInfo } from 'utils/form';
import { EditorialSectionSelectQuery } from 'graphql/query/EditorialSectionSelectQuery';

const MagazineCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(MagazineEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={MagazineCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.MAGAZINE}
		fields={[
			'MagazineCollection',
			'EditorialSection',
			'category',
			'publicationDate',
			'name',
			'title',
			'customHighlightDays',
			'issueNumber',
			'numOfTheYear',
			'numPages',
			'prefixPages',
			'customVisibilityType',
			'customVisibilityArgs',
			'fullUrl',
			'previewUrl',
			'dropboxPath',
			'Media',
			'previewMedia',
			'featuredImageMedia',
		]}
		transformer={(data) => {
			data.fields.forEach((f) => {
				switch (f.name) {
					case 'MagazineCollection':
						f.afterChange = (ff, value, fields) => {
							return updateEditorialSectionSelectableValues(fields);
						}
						break;
					default:
						break;
				}
			});

			data.fields = updateEditorialSectionSelectableValues(data.fields);
			return data;
		}}
	/>
);

export { MagazineCreate };

export const updateEditorialSectionSelectableValues: any = (fields: any, updateValues: boolean = true) => {
	const magazineCollectionField = fields.filter((f) => f.name == 'MagazineCollection')[0];
	const editorialSectionField = fields.filter((f) => f.name == 'EditorialSection')[0];

	if (magazineCollectionField.value && magazineCollectionField.value.value && magazineCollectionField.value.value.BrandSf) {
		if (editorialSectionField.type !== 'AsyncChoices') {
			refactorInfo(editorialSectionField, 'AsyncChoices');
		} else {
			editorialSectionField.asyncQuery = generateAsyncQuery(
				EditorialSectionSelectQuery,
				true,
				(value) => {
					return {
						filter: {
							name: value,
							EditorialBrand: MagazineCollectionBrand2EditorialBrand(magazineCollectionField.value.value.BrandSf)
						},
					};
				},
			);
		}

		editorialSectionField.disabled = false;
	} else {
		editorialSectionField.disabled = true;
	}
	return fields;
}

export const MagazineCollectionBrand2EditorialBrand: any = (magazineCollectionBrand: string) => {
	switch (magazineCollectionBrand) {
		case 'ITALIANFOOD_NET':
			return 'IFN';
		case 'FOOD_SERVICE':
			return 'FOODSERVICE';
		default:
			return magazineCollectionBrand;
	}
}