import React, { useCallback, useRef, useState } from 'react';
import { Dropdown } from '@food/ui';

interface EntityAdditionalDataProps {
	children: (loading: boolean) => JSX.Element;
	card: () => JSX.Element;
}

export const EntityAdditionalData: React.FC<EntityAdditionalDataProps> = ({
	children,
	card,
}) => {
	const timeout = useRef<NodeJS.Timeout | null>(null);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	const onMouseEnter = useCallback(() => {
		setLoading(true);
		timeout.current = setTimeout(() => {
			setOpen(true);
			setLoading(false);
		}, 700);
	}, []);

	const onMouseLeave = useCallback(() => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setOpen(false);
		setLoading(false);
	}, []);

	return (
		<>
			<div ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
				{children(loading)}
			</div>
			<Dropdown element={ref} open={open}>
				<div
					onMouseLeave={() => {
						setOpen(false);
						setLoading(false);
					}}
					onMouseEnter={() => setOpen(true)}
				>
					{open && card()}
				</div>
			</Dropdown>
		</>
	);
};
