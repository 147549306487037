import gql from 'graphql-tag';
import { FrequentSearchFullFragment } from '../fragments/FrequentSearchFullFragment';

export const FrequentSearchEditQuery = gql`
    query FrequentSearchEditQuery($id: ID!) {
        entity: node (id: $id) {
            ...FrequentSearchFullFragment
        }
        editorialSectionTree(EditorialBrand: GOODS4HUB) {
            id
            name
            Parent {
                id
            }
            goodClasses {
                id
                shortname
                shortnameIta
                localId
                name
                nameIta
            }
        }
    }
    ${FrequentSearchFullFragment}
`;


