import React from 'react';
import { Col, FormFeedback, FormGroup, FormText, Label } from 'reactstrap';
import { t } from '../../utils/labels';
import { UnControlled as CodeMirror } from 'react-codemirror2';

export const CodeField = ({ field, changeHandler }) => {
	const errorText = field.error ? field.error.type || field.error.message : '';
	const code_mirror_config = {
		mode: 'htmlmixed',
		theme: 'hopscotch',
		lineNumbers: true,
	};

	return (
		<FormGroup row color={field.error ? 'danger' : ''}>
			<Label sm={12}>{field.label}: </Label>
			<Col sm={12}>
				<CodeMirror
					value={field.value}
					options={code_mirror_config}
					onChange={changeHandler}
				/>
				{field.error && (
					<section>
						<FormFeedback>
							{t(errorText + '/code', errorText)}
						</FormFeedback>
					</section>
				)}
				<FormText color="muted">
					{field.description.readonly && (
						<div className="usability-note">{t`readonly field description`}</div>
					)}
					{field.helpText}
				</FormText>
			</Col>
		</FormGroup>
	);
};
