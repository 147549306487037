import React from 'react';
import { EuCategoryCreateMutation } from '../graphql/mutations/EuCategoryCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/EuCategory.json';
import inputTypeIntro from '../static/introspection/EuCategoryCreateInput.json';
import { EuCategoryEditQuery } from '../graphql/query/EuCategoryEditQuery';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';

export const EuCategoryCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(EuCategoryEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={EuCategoryCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.EU_CATEGORY}
		fields={['name', 'HtmlTemplate']}
	/>
);
