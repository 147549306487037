import { hasPath, nth, memoizeWith, identity } from 'ramda';
import { parse } from 'url';
import { ErrorChecker, IField } from '../types/form';

const ERROR_CODES = {
	MAX: 'MAX',
	MIN: 'MIN',
	REQUIRED: 'EMPTY_NOT_ALLOWED',
	WRONG_FORMAT: 'WRONG_PATTERN',
	WRONG_BLOCK_NUMBER: 'WRONG_BLOCK_NUMBER',
};

// TODO cambiare l'input "field" con la tipologia del field
const max = (n: Number, field: any) => (value) => {
	let error = false;
	switch (field.type) {
		case 'Text':
			if (value && value.length > n) error = true;
			break;
		case 'RichText':
			if (value && htmlLength(value) > n) error = true;
			break;
		case 'Int':
			if (value !== null && value > n) error = true;
			break;
		default:
			break;
	}

	if (error) return new Error(ERROR_CODES.MAX);
	return false;
};

/**
 * Validatore per valori minimi assegnabili ad un IField. Funziona per numeri e per testi
 * @param n
 * @param field
 */
const min = (n: number, field: IField) => {
	return (value: any): Error | false => {
		let error = false;
		switch (field.type) {
			case 'Text':
				if (value && value.length < n) error = true;
				break;
			case 'RichText':
				if (value && htmlLength(value) < n) error = true;
				break;
			case 'Int':
				if (value !== null && value < n) error = true;
				break;
			default:
				break;
		}

		if (error) return new Error(ERROR_CODES.MIN);
		return false;
	};
};

function required(value) {
	if (value === '' || value === null || value === undefined)
		return new Error(ERROR_CODES.REQUIRED);
	return false;
}

const formatStr = (regexStr: string) => (str: string) => {
	if (!str) return false;
	const regEx = new RegExp(regexStr, 'ig');
	return str.match(regEx) === null
		? new Error(ERROR_CODES.WRONG_FORMAT)
		: false;
};

const urlValidator = formatStr(
	'^(?:(?:https?|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?|mailto:(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])$',
);

const allowedUrlsValidators = [
	'https://admin.typeform.com/form/[A-Z0-9]+(/)?(create)?',
	'https://gruppofood.typeform.com/to/[A-Z0-9]+',
].map(formatStr);
const typeformUrlValidator = (s: string) => {
	let result: any;
	for (let i = 0; i < allowedUrlsValidators.length; i += 1) {
		result = allowedUrlsValidators[i](s);
		if (result === false) {
			return false;
		}
	}
	return result;
};

function htmlLength(html) {
	const htmlMap = {
		amp: '&',
		lt: '<',
		gt: '>',
		quot: '"',
		apos: "'",
		'#x27': "'",
		'#x2F': '/',
		'#39': "'",
		'#47': '/',
		nbsp: ' ',
	};
	const convertHtmlEntities = (m0, m1) => {
		if (htmlMap[m1]) {
			return htmlMap[m1];
		}
		try {
			if (m1[0] === '#') {
				return String.fromCharCode(m1.substr(1));
			}
			if (String.fromCharCode(m1)) {
				return String.fromCharCode(m1);
			}
		} catch (err) {
			console.error('Char conversion error', m1, err);
		}
		return '&';
	};

	return html
		.normalize()
		.replace(/<[^>]+>/g, '')
		.replace(/&([^;]+);/g, convertHtmlEntities)
		.trim().length;
}

function mustBeDigits(num) {
	const onlyDigits = !num || /^-?[0-9]*$/.test(num.toString());
	if (!onlyDigits) return new Error(ERROR_CODES.WRONG_FORMAT);
	else return false;
}

const paths = [
	['0'],
	['0', '_embedded'],
	['0', '_embedded', 'wp:featuredmedia'],
];

const fetchWordpressData = memoizeWith(
	identity,
	async (url: string): Promise<Error | any> => {
		try {
			if (url === '') {
				return null;
			}

			const parsedUrl = parse(url);
			const pieces = parsedUrl.pathname.split('/');
			const origin = parsedUrl.protocol + '//' + parsedUrl.host;
			const slug = nth(-1, pieces) || nth(-2, pieces);
			const typePart =
				nth(-2, pieces) === slug ? nth(-3, pieces) : nth(-2, pieces);
			const endpoint = isNaN(parseFloat(typePart)) ? typePart : 'posts';
			const apiUrl =
				origin + '/wp-json/wp/v2/' + endpoint + '?_embed&slug=' + slug;
			const res = await fetch(apiUrl, { method: 'GET' });
			const data = await res.json();
			return data;
		} catch (e) {
			return e;
		}
	},
);

const fetchLaunchData = memoizeWith(
	identity,
	async (url: string): Promise<Error | any> => {
		try {
			if (url === '') {
				return null;
			}

			const parsedUrl = parse(url);
			const pieces = parsedUrl.pathname.split('/');
			const origin = parsedUrl.protocol + '//' + parsedUrl.host;
			const slug = nth(-1, pieces) || nth(-2, pieces);
			const apiUrl = origin + '/wp-json/wp/v2/launch?_embed&slug=' + slug;
			const res = await fetch(apiUrl, { method: 'GET' });
			return await res.json();
		} catch (e) {
			return e;
		}
	},
);

const checkApiResponse: ErrorChecker = async (url: string) => {
	const data = await fetchWordpressData(url);
	if (data === null || data instanceof Error) {
		return new Error('Not valid news url');
	}

	let count = 0;
	do {
		if (!hasPath(paths[count], data)) {
			return new Error('Not wordpress data');
		} else {
			count += 1;
		}
	} while (count < paths.length);
	return false;
};

export {
	ERROR_CODES,
	min,
	max,
	required,
	formatStr,
	urlValidator,
	mustBeDigits,
	checkApiResponse,
	fetchWordpressData,
	fetchLaunchData,
	typeformUrlValidator,
};
