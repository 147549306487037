import React from 'react';
import { FormGroup } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { t } from '../../utils/labels';
import { dateFieldMutator } from '../../utils/fieldMutators';
import { ListFilterType } from '../Filters';
import { DateFieldType } from '../../types/form';

export const DateFilter: React.FC<ListFilterType<Date, DateFieldType>> = ({
	field,
	mutator,
	size = 3,
}) => (
	<FormGroup className={`filter col-sm-${size}`}>
		<DatePicker
			placeholderText={t(field.label)}
			onChange={dateFieldMutator(mutator, field.name)}
			selected={field.value}
			dateFormat="dd/MM/yyyy"
			className="form-control col-sm-12"
		/>
	</FormGroup>
);
