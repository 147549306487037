import React from 'react';
import { FrequentSearchDetailQuery } from '../graphql/query/FrequentSearchDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/FrequentSearch.json';
import { ENTITY } from '../utils/entities';
import { setFrequentSearchStatusMutation } from '../graphql/mutations/setFrequentSearchStatusMutation';
import { StatusSetter } from '../components/StatusSetter';
import FrequentSearchStatusIntrospection from '../static/introspection/FrequentSearchStatus.json';
import {fastTransformer, toHidden} from '../utils/form';
import { identity, pipe } from 'ramda';
import { assign } from '../utils/misc';
import { ACTIONS, generateUrl } from '../utils/urls';

const statuses = FrequentSearchStatusIntrospection.enumValues.map(
	(o) => o.name,
);

const StaticFrequentSearchDetail = ({ id }) => (
	<EntityDetailFetcher
		query={FrequentSearchDetailQuery}
		entityName={ENTITY.FREQUENT_SEARCH}
		id={id}
		typeIntro={typeIntro}
		customActionComponents={(entity) => [
			<StatusSetter
				key={1}
				statuses={statuses}
				entityName={ENTITY.FREQUENT_SEARCH}
				entity={entity}
				statusFieldName={'status'}
				mutation={setFrequentSearchStatusMutation}
				entityIntrospection={typeIntro}
			/>,
		]}
		fields={[
			'name',
			'collection',
			'targetSites',
			'relatedFair',
			'nameIta',
			'goods',
			'companies',
			'logoMedia',
			'linkUrl',
			'linkRoles',
			'description',
			'User',
		]}
		customEditUrl={(id) =>
			generateUrl(ENTITY.FREQUENT_SEARCH, ACTIONS.LIST) +
			'/static/' +
			id +
			'/edit'
		}
		transformer={(data, entity) => {
			const mode = entity.collection;
			const removeCanAdd = assign({
				additionalProps: {
					forcedPermissions: {
						canEdit: false,
						canCreate: false,
						canDelete: false,
					},
				},
			});
			data.fields = fastTransformer({
				goods: pipe(
					removeCanAdd,
					mode !== 'GOOD' ? toHidden() : identity,
				),
				companies: pipe(
					removeCanAdd,
					mode !== 'COMPANY' ? toHidden() : identity,
				),
			})(data.fields);
			return data;
		}}
	/>
);

export { StaticFrequentSearchDetail };
