import gql from 'graphql-tag';
import { t } from '../utils/labels';
import { generateAsyncQuery } from '../utils/graphql';
import { ReportType } from '../components/Report';
import { AwardSelectQuery } from '../graphql/query/AwardSelectQuery';
import { always, identity } from 'ramda';
import { InternalCapability } from '../server-types';
import { nodes } from 'utils/misc';

export const candidaciesAllVotesReport: ReportType = {
	filename: always('Candidature di un premio - tutti i voti'),
	title: 'Candidature di un premio - tutti i voti',
	requirements: [InternalCapability.MarketingExtra],
	fields: [
		{
			type: 'AsyncChoices',
			name: 'Award',
			label: 'Award',
			enum: false,
			description: {},
			single: true,
			asyncQuery: generateAsyncQuery(AwardSelectQuery, true),
		},
	],
	preVariablesGenerator: (values: any) => ({
		id: values.Award.value.id,
	}),
	variablesGenerator: (pre: any) => ({
		filter: {
			Award: { id: pre.award.id },
		},
	}),
	preQuery: gql`
		query PreQueryRep1($id: ID!) {
			award: node(id: $id) {
				id
				... on Award {
					ratingOverallLabel
					rating1Label
					rating2Label
					rating3Label
					rating4Label
					rating5Label
					managerUser {
						id
						email
					}
				}
			}
		}
	`,
	customRequirementsCheck: (pre, user) => {
		const manager = pre.award.managerUser;

		if (!manager) {
			return new Error('Nessun utente manager impostato per il premio');
		}

		if (manager.id !== user.id) {
			return new Error(
				'Permessi insufficienti per accedere alle informazioni. Utente responsabile: ' +
					manager.email,
			);
		}

		return null;
	},
	query: gql`
		query QueryRep1(
			$after: String
			$filter: CandidacyFilterInput
			$first: Int
		) {
			connector: candidacies(after: $after, filter: $filter, first: $first) {
				edges {
					node {
						id
						name
						AwardCategory {
							id
							name
						}
						Company {
							id
							name
						}
						candidacyRatings {
							id
							User {
								id
								firstName
								lastName
								role
								managedByUsers {
									id
									Company {
										id
										name
									}
									Retailer {
										id
										name
									}
								}
							}
							overall
							rating1
							rating2
							rating3
							rating4
							rating5
						}
					}
				}
				pageInfo {
					hasNextPage
				}
			}
		}
	`,
	header: (pre: any) => {
		const { award } = pre;
		return [
			t`First Name`,
			t`Last Name`,
			t`Role`,
			t`Voter Company`,
			t`Voter Retailer`,
			t`Candidacy Name`,
			t`Candidacy Company`,
			t`Categoria`,
			award.ratingOverallLabel || t`Overall`,
		].concat(
			Object.keys(award)
				.filter((n) => /rating\dLabel/.test(n))
				.filter((n) => award[n])
				.map((n) => award[n]),
		);
	},
	extractor: (data: any) => nodes(data.connector),
	globalTransformer: (records, pre) => {
		const { award } = pre;
		const ratingFields = [
			'overall',
			...Object.keys(award)
				.filter((n) => /rating\dLabel/.test(n))
				.filter((n) => award[n])
				.map((n) => n.replace('Label', '')),
		];
		return records
			.map((e) => {
				return e.candidacyRatings.map((vote) => {
					const mbu = vote.User.managedByUsers[0];
					return [
						vote.User.firstName,
						vote.User.lastName,
						vote.User.role,
						mbu && mbu.Company ? mbu.Company.name : '',
						mbu && mbu.Retailer ? mbu.Retailer.name : '',
						e.name,
						e.Company.name,
						e.AwardCategory.name,
						...ratingFields.map((f) => vote[f]),
					];
				});
			})
			.flat();
	},
	transformer: identity,
};
