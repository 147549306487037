import React from 'react';
import { answerSetPatchMutation } from '../graphql/mutations/AnswerSetPatchMutation';
import { answerSetEditQuery } from '../graphql/query/AnswerSetEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/AnswerSet.json';
import inputTypeIntro from '../static/introspection/AnswerSetPatchInput.json';
import { ENTITY } from '../utils/entities';
import { notNull } from '../utils/misc';
import { graphqlQuery } from '../utils/graphql';

export const AnswerSetEdit = ({ id, originalEntity }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(answerSetEditQuery, { id })}
		mutation={answerSetPatchMutation}
		entityName={ENTITY.ANSWER_SET}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		modal={!!originalEntity}
		fields={['answer1']}
		transformer={(data, entity) => {
			data.fields = data.fields
				.map((f) => {
					if (f.name.startsWith('answer')) {
						const number = Number(f.name.replace('answer', ''));
						const question = entity.QuestionSet['question' + number];
						if (!question) {
							return null;
						}

						f.label = question;
						f.helpText = '';
					}
					return f;
				})
				.filter(notNull);
			return data;
		}}
	/>
);
