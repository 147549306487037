import React from 'react';
import { identity } from 'ramda';
import { awardInvitedCompanyPatchMutation } from '../graphql/mutations/AwardInvitedCompanyPatchMutation';
import { AwardInvitedCompanyEditQuery } from '../graphql/query/AwardInvitedCompanyEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/AwardInvitedCompany.json';
import inputTypeIntro from '../static/introspection/AwardInvitedCompanyPatchInput.json';
import { toRefInput } from '../utils/dataTrasform';
import { ENTITY } from '../utils/entities';
import { fastTransformer, toHidden } from '../utils/form';
import { graphqlQuery } from '../utils/graphql';

export const AwardInvitedCompanyEdit = ({
	id,
	originalEntity,
	onSubmit
}) => (
	<EntityEditFetcher
		id={id}
		modal={true}
		onSubmit={onSubmit}
		fetcher={graphqlQuery(AwardInvitedCompanyEditQuery, { id })}
		mutation={awardInvitedCompanyPatchMutation}
		entityName={ENTITY.AWARD_INVITED_COMPANY}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		fields={[
			'Award',
			'Company',
			'signedContract',
			'maxGoodsPerCandidacy',
			'maxPerCompanyCandidacies',
			'formValues',
		]}
		transformer={(data) => {
			const parentEntityIsAward = originalEntity.__typename === ENTITY.AWARD;
			const hider = toHidden(toRefInput(originalEntity));

			data.fields = fastTransformer({
				Award: parentEntityIsAward ? hider : identity,
				Company: parentEntityIsAward ? identity : hider,
			})(data.fields);

			return data;
		}}
	/>
);
