import React from 'react';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/PLManufacturer.json';
import inputTypeIntro from '../static/introspection/PLManufacturerCreateInput.json';
import { toRefInput } from '../utils/dataTrasform';
import { ENTITY } from '../utils/entities';
import { fastTransformer, toHidden } from '../utils/form';
import { transformerHandler } from '../utils/misc';
import { EntityCreateModal } from '../types/entities';
import { Good, PlManufacturer } from '../server-types';
import { PLManufacturerCreateMutation } from 'graphql/mutations/PLManufacturerCreateMutation';

export const PLManufacturerCreate: React.FC<EntityCreateModal<
	PlManufacturer,
	Good
>> = ({ id, onSubmit, originalEntity }) => (
	<EntityEditFetcher
		id={id}
		onSubmit={onSubmit}
		create={true}
		modal={true}
		mutation={PLManufacturerCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.PLMANUFACTURER}
		fields={[
			'company',
			'good'
		]}
		transformer={transformerHandler(
			fastTransformer({
				good: toHidden(toRefInput(originalEntity)),
			}),
		)}
	/>
);
