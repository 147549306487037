import gql from 'graphql-tag';
import { MediaBaseFragment } from './MediaBaseFragment';

export const BrandFullFragment = gql`
	fragment BrandFullFragment on Brand {
		id
		localId
		name
		pricePositioning
		website
		logoMedia {
			...MediaBaseFragment
		}
		description
		descriptionIta
		descriptionLong
		descriptionLongIta
		sfId
		socialInstagramPage
		socialFacebookPage
		retailChainDistribution
		gourmetShopChainDistribution
		foodServiceChainDistribution
		goodClasses {
			id
			name
			nameIta
			shortname
			shortnameIta
		}
		Company {
			id
			name
		}
	}
	${MediaBaseFragment}
`;
