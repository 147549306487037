import gql from 'graphql-tag';

export const EditorialTagListQuery = gql`
	query EditorialTagListQuery(
		$first: Int,
		$cursor: String,
		$filter: EditorialTagFilterInput
	) {
		entities: editorialTags(first: $first, after: $cursor, filter: $filter) {
			edges {
				node {
					id
					localId
					name
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}

	}
`;


