import React from 'react';
import { BranchCreateMutation } from '../graphql/mutations/BranchCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Branch.json';
import inputTypeIntro from '../static/introspection/BranchCreateInput.json';
import { BranchEditQuery } from '../graphql/query/BranchEditQuery';
import { toRefInput } from '../utils/dataTrasform';
import { ENTITY } from '../utils/entities';
import { fastTransformer, toHidden } from '../utils/form';
import { transformerHandler } from '../utils/misc';
import { Branch, Company } from '../server-types';
import { graphqlQuery } from '../utils/graphql';
import { EntityCreateModal } from '../types/entities';

export const BranchCreate: React.FC<EntityCreateModal<Branch, Company>> = ({
	id,
	originalEntity,
	onSubmit,
}) => (
	<EntityEditFetcher
		id={id}
		fetcher={id ? graphqlQuery(BranchEditQuery, { id }) : null}
		create={true}
		clone={false}
		modal={true}
		onSubmit={onSubmit}
		mutation={BranchCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.BRANCH}
		fields={[
			'name',
			'type',
			'vatNumber',
			'website',
			'Country',
			'StateProvince',
			'postalCode',
			'city',
			'address1',
			'address2',
			'Company',
		]}
		transformer={transformerHandler(
			fastTransformer({
				Company: toHidden(toRefInput(originalEntity)),
			}),
		)}
	/>
);
