import React from 'react';
import { fairParticipationDetailQuery } from '../graphql/query/FairParticipationDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/FairParticipation.json';
import { ENTITY } from '../utils/entities';
import { EntityDetailModal } from '../types/entities';
import { Company, Fair } from '../server-types';

export const FairParticipationDetail: React.FC<EntityDetailModal<
	Company | Fair
>> = ({ id, onDelete }) => (
	<EntityDetailFetcher
		id={id}
		onDelete={onDelete}
		query={fairParticipationDetailQuery}
		entityName={ENTITY.FAIR_PARTICIPATION}
		typeIntro={typeIntro}
		clonable={false}
		modal={true}
		fields={['Fair', 'pavilion', 'stand', 'note']}
	/>
);
