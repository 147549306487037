import gql from 'graphql-tag';
import {CompanyBadgeFullFragment} from "../fragments/CompanyBadgeFullFragment";

export const CompanyBadgePatchMutation = gql`
	mutation CompanyBadgePatchMutation($id: ID!, $changes: CompanyBadgePatchInput!) {
		results: companyBadgePatch(id: $id, changes: $changes) {
			...CompanyBadgeFullFragment
		}
	}
	${CompanyBadgeFullFragment}
`;


