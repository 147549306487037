import React from 'react';
import { t } from '../utils/labels';
import { TeamDetailQuery } from '../graphql/query/TeamDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/Team.json';
import { ACTIONS, generateUrl } from '../utils/urls';
import { ENTITY } from '../utils/entities';
import { assign, transformerHandler } from '../utils/misc';
import { fastTransformer } from '../utils/form';

export const TeamDetail = ({ id }) => (
	<EntityDetailFetcher
		query={TeamDetailQuery}
		entityName={ENTITY.TEAM}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		fields={['name', 'Retailer', 'users']}
		transformer={transformerHandler(
			fastTransformer({
				users: assign({
					tab: 1,
					props: {
						denyAdd: true,
						detailUrl: (entity) =>
							generateUrl(ENTITY.USER, ACTIONS.DETAIL, entity.id),
					},
				}),
			}),
		)}
		tabs={[
			t('generic data'),
			t([ENTITY.TEAM, ENTITY.USER, 'label'].join('/')),
		]}
		cols={[['col-sm-7', 'col-sm-5'], ['col-sm-12']]}
	/>
);
