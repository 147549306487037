import gql from 'graphql-tag';
import { StoreChainFullFragment } from 'graphql/fragments/StoreChainFullFragment';

export const StoreChainPatchMutation = gql`
	mutation StoreChainPatchMutation($id: ID!, $changes: StoreChainPatchInput!) {
		results: storeChainPatch(id: $id, changes: $changes) {
			...StoreChainFullFragment
		}
	}
	${StoreChainFullFragment}
`;


