import gql from 'graphql-tag';
import {EmailDomainFullFragment} from "../fragments/EmailDomainFullFragment";

export const EmailDomainDetailQuery = gql`
	query EmailDomainDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...EmailDomainFullFragment
		}
	}
	${EmailDomainFullFragment}
`;


