import gql from 'graphql-tag';

export const PLManufacturerFullFragment = gql`
	fragment PLManufacturerFullFragment on PLManufacturer {
		company {
			id
			name
		}
		good {
			id
			name
		}
	}
`;
