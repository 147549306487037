import gql from 'graphql-tag';

export const StoreChainListQuery = gql`
	query StoreChainListQuery(
		$first: Int
		$cursor: String
		$filter: StoreChainFilterInput
	) {
		entities: StoreChain(first: $first, after: $cursor, filter: $filter) {
			edges {
				node {
					id
					localId
					name
					Retailer {
						id
						name
					}
					Foodservice {
						id
						name
					}
					Company {
						id
						name
					}
					Service {
						id
						name
					}
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;
