import gql from 'graphql-tag';
import {fairParticipationFullFragment} from "../fragments/FairParticipationFullFragment";

export const fairParticipationCreateMutation = gql`
	mutation fairParticipationCreateMutation($values: FairParticipationCreateInput!) {
		results: fairParticipationCreate(values: $values) {
			...fairParticipationFullFragment
		}
	}
	${fairParticipationFullFragment}
`;


