/**
 * Il componente standard di Tabs utilizzato in hub. Rispetto a quello standard ha il vantaggio di renderizzare solo
 * la tab attiva e di contenere lo stato di quella attualmente visualizzata (prima era necessario duplicare questo
 * stato per ogni componente che usava le tabs).
 *
 * Se non ci sono tabs non restituisce niente, ma segnala l'errore in console.
 * Se esiste una sola tab possibile non mostra il pannello di scelta.
 *
 * Nota: le tabs vengono passate come un array di stringe ma gia' nella loro forma tradotta. Questo approccio ha qualche
 * effetto collaterale; lo gestisco poco piu' avanti nel file, con commento adeguato, in "handleInitialTabMatch"
 */

import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	Col,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
} from 'reactstrap';
import { IField } from 'types/form';

export type TabsProps = {
	tabs: ReadonlyArray<string>; // la lista delle tabs possibili. Nota: sono gia' tradotte
	initialTab?: string;
	fields?: readonly IField[];
	children: (
		activeTab: string,
		tabIndex: number,
	) => JSX.Element | string | null;
};

/**
 * "handleInitialTabMatch" permette il match fra una tab iniziale presa dall'url e le tabs passate come input al
 * componente, oltre a gestire alcuni casi con input problematici che non vengono intercettati da typescript (esempio:
 * array vuoto di tabs).
 *
 * Le tabs vengono passate al componente gia' tradotte, e risulta impratico cambiare questa cosa considerando che e' una
 * pratica molto diffusa nella base di codice. Per permettere di specificare una tab iniziale nell'url della pagina
 * senza basarsi solo su "urlEncode" che, a mio parere, renderebbe l'url esteticamente poco gradevole, ho dovuto usare
 * questa logica per permettere il matching fra il testo specificato nell'url e le tabs passate nel componente.
 *
 * Semplicemente sostituisco gli spazi con degli underscore e rendo lowercase la stringa. Esempio:
 * Canali di Export -> canali_di_export
 *
 * Questo ci permette di avere url con scritto goods#canali_di_export, invece della versione "urlEncodata" che sarebbe
 * piu' sgradevole e meno umanamente riproducibile.
 */
const handleInitialTabMatch = (
	tabs: ReadonlyArray<string>,
	initialTab: string | null | undefined,
): string | null => {
	if (tabs.length === 0) {
		return null;
	}
	if (!initialTab) {
		return tabs[0];
	}
	const urlBeautifulTabs = tabs.map((s) =>
		s.toLocaleLowerCase().replace(/ /g, '_'),
	);
	let i = 0;
	while (i < urlBeautifulTabs.length) {
		if (initialTab === urlBeautifulTabs[i]) {
			return tabs[i];
		}
		i += 1;
	}

	return tabs[0];
};

export const Tabs: React.FC<TabsProps> = ({ tabs, initialTab, children, fields }) => {
	const [activeTab, setActiveTab] = useState(
		handleInitialTabMatch(tabs, initialTab),
	);

	// qui sotto gestisco un caso in cui sono stati passati input non legittimi (ma con tipizzazione corretta)
	useEffect(() => {
		if (tabs.length === 0) {
			console.error('Nessuna tab presente in un componente Tabs');
		}
	}, [tabs]);

	if (tabs.length === 0) return null;

	// grazie ad "handleInitialTabMatch" sono sicuro che tabIndex avra' valore valido
	const tabIndex = tabs.indexOf(activeTab);

	return (
		<Row>
			<Col sm={12}>
				<Card>
					{tabs.length > 1 && (
						<Nav tabs>
							{tabs.map(function (tab, i) {
								const inTabFields = fields?.filter((f) => f.tab === i);
								if (inTabFields && inTabFields.length === 0) return <></>;
								return (
									<NavItem key={tab}>
										<NavLink
											data-tab-nav={tab}
											className={activeTab === tab ? 'active' : ''}
											onClick={() => setActiveTab(tab)}
										>
											{tab}
										</NavLink>
									</NavItem>
								);
							})}
						</Nav>
					)}
					<CardBody>
						<TabContent activeTab={activeTab}>
							<TabPane tabId={activeTab} data-tab-pane={activeTab}>
								{children(activeTab, tabIndex)}
							</TabPane>
						</TabContent>
					</CardBody>
				</Card>
			</Col>
		</Row>
	);
};
