import gql from 'graphql-tag';
import { questionSetFullFragment } from '../fragments/QuestionSetFullFragment';

export const questionSetDetailQuery = gql`
	query QuestionSetDetailQuery($id: ID!) {
		entity: node(id: $id) {
			...QuestionSetFullFragment
		}
	}
	${questionSetFullFragment}
`;
