import React from 'react';
import { Redirect } from 'react-router';
import { map } from 'ramda';
import { UserData } from '@food/auth';
import EntityListFetcher from '../components/EntityListFetcher';
import { ObjectHistoryListQuery } from '../graphql/query/ObjectHistoryListQuery';
import typeIntro from '../static/introspection/ObjectHistory.json';
import ObjectHistoryFilters from '../static/introspection/ObjectHistoryFilterInput.json';
import { valToRefInput } from '../utils/dataTrasform';
import { ENTITY } from '../utils/entities';
import { IListField } from '../utils/list';
import { ACTIONS, generateUrl } from '../utils/urls';

const customObjectField = (entity: any): IListField => {
	if (!entity.Object) {
		return {
			name: 'custom',
			type: 'Custom',
			value: <span />,
		};
	}
	const type = entity.Object.__typename;

	if (type === ENTITY.MEDIA) {
		return {
			name: 'url',
			type: 'Url',
			value: entity.Object.name,
			to: entity.Object.origUrl,
		};
	} else {
		return {
			name: type,
			type: 'Entity',
			mapTo: type,
			value: entity.Object,
			url:
				type === ENTITY.HIGHLIGHTED_GOOD &&
				entity.Object &&
				entity.Object.Good
					? generateUrl(ENTITY.GOOD, ACTIONS.DETAIL, entity.Object.Good.id)
					: undefined,
		};
	}
};

export const ObjectHistoryList: React.FC = () => (
	<UserData>
		{({ isInternal }) => {
			if (!isInternal) {
				return <Redirect to={'/404'} />;
			}

			return (
				<EntityListFetcher
					query={ObjectHistoryListQuery}
					entityName={ENTITY.OBJECT_HISTORY}
					headers={['collection', 'action', 'timestamp', 'User']}
					forcedPermissions={{
						canCreate: false,
					}}
					errorPolicy={'all'}
					customFields={[
						{
							header: 'Company',
							field: (entity) => ({
								type: 'Entity',
								mapTo: ENTITY.COMPANY,
								value:
									entity.User.managedByUsers[0] &&
									entity.User.managedByUsers[0].Company,
							}),
						},
						{
							header: 'Object',
							field: customObjectField,
						},
					]}
					typeIntro={typeIntro}
					filtersIntro={ObjectHistoryFilters}
					filterFields={[
						'actions',
						'collections',
						'userRoles',
						'User',
						'Object',
						'Award',
						'timestampMin',
						'timestampMax',
					]}
					filterTransform={map((f: any) => {
						if (f.name === 'Object') {
							f.type = 'Text';
							f.beforeSaveTransformer = (id) => valToRefInput(id);
						}

						return f;
					})}
				/>
			);
		}}
	</UserData>
);
