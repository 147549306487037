import gql from 'graphql-tag';
import { t } from '../utils/labels';
import { generateAsyncQuery } from '../utils/graphql';
import { ReportType } from '../components/Report';
import { AwardSelectQuery } from '../graphql/query/AwardSelectQuery';
import { html2text, nodes } from '../utils/misc';
import { always } from 'ramda';
import { InternalCapability } from '../server-types';

export const showcaseCandidatedCompanies: ReportType = {
	filename: always('Export per grafici - Aziende presenti ad uno showcase'),
	title: 'Export per grafici - Aziende presenti ad uno showcase',
	requirements: [InternalCapability.MarketingExtra],
	preQuery: gql`
		query PreQueryRep1($id: ID!) {
			award: node(id: $id) {
				id
				... on Award {
					description1Label
					description2Label
					description3Label
					description4Label
					description5Label
					description6Label
					description7Label
					description8Label
					description9Label
					description10Label
				}
			}
		}
	`,
	query: gql`
		query QueryRep2(
			$after: String
			$filter: CandidacyFilterInput
			$first: Int
		) {
			connector: candidacies(after: $after, filter: $filter, first: $first) {
				edges {
					node {
						id
						name
						Company {
							name
							id
							logoPrintMedia {
								id
								origUrl
							}
							companyCertifications {
								id
								name
							}
							description
							yearEstablished
							ownership
							plProducer
							billingBranch {
								id
								StateProvince {
									id
									regionIta
									region
								}
							}
							awardInvitedCompanies {
								id
								formValues
								Award {
									id
								}
							}
							companyYears {
								id
								revenue
								year
							}
						}
						description1
						description2
						description3
						description4
						description5
						description6
						description7
						description8
						description9
						description10
					}
				}
				pageInfo {
					hasNextPage
				}
			}
		}
	`,
	fields: [
		{
			type: 'AsyncChoices',
			name: 'Award',
			label: 'Award',
			enum: false,
			description: {},
			single: true,
			asyncQuery: generateAsyncQuery(AwardSelectQuery, true),
		},
	],

	header: ({ award }) => [
		t`Company name`,
		t`Company Logo High Res`,
		t`Year of foundation`,
		t`Company certifications`,
		t`Company description`,
		t`Ownership`,
		t`Private label`,
		t`Region`,
		"Azienda già presente sul mercato dell'evento",
		'Tipo di presenza sul mercato',
		'Principali partner commerciali',
		'Contatti',
		'Fatturato ultimo anno',
		'FAQS',
		...Object.keys(award)
			.filter((n) => n.startsWith('description'))
			.filter((n) => award[n])
			.map((n) => award[n].split('|')[0]),
	],
	variablesGenerator: (pre: any, values: any) => ({
		filter: {
			Award: { id: values.Award.value.id },
		},
	}),
	preVariablesGenerator: (values: any) => ({ id: values.Award.value.id }),
	extractor: (data: any) => nodes(data.connector),
	transformer: (e: any, { award }: any) => {
		const invite = e.Company.awardInvitedCompanies.find(
			(invite) => invite.Award.id === award.id,
		);

		const values = JSON.parse(
			invite && invite.formValues ? invite.formValues : '{}',
		);
		const faqs = values.faqs
			?.map(
				({ question, answer }: any, index) => `${index + 1}.
domanda: ${question}
risposta: ${answer}
`,
			)
			.join('');

		const lastYear = e.Company.companyYears.sort(
			(a, b) => b.year - a.year,
		)[0];

		return [
			e.Company.name,
			e.Company.logoPrintMedia && e.Company.logoPrintMedia.origUrl,
			e.Company.yearEstablished,
			e.Company.companyCertifications.map((obj) => obj.name).join(', '),
			html2text(e.Company.description),
			e.Company.ownership,
			e.Company.plProducer ? 'Yes' : 'No',
			e.Company &&
				e.Company.billingBranch &&
				e.Company.billingBranch.StateProvince &&
				(e.Company.billingBranch.StateProvince.regionIta ||
					e.Company.billingBranch.StateProvince.region),
			values.alreadyPresent ? 'Yes' : 'No',
			values.internationalChannels,
			values.internationalPartners,
			values.contacts,
			lastYear?.revenue + ' €',
			faqs,
			...Object.keys(award)
				.filter((n) => n.startsWith('description'))
				.filter((n) => award[n])
				.map((n) => e[n.replace('Label', '')])
				.map(html2text),
		];
	},
};
