import gql from 'graphql-tag';
import {CandidacyFullFragment} from "../fragments/CandidacyFullFragment";

export const CandidacyPatchMutation = gql`
	mutation CandidacyPatchMutation($id: ID!, $changes: CandidacyPatchInput!) {
		results: candidacyPatch(id: $id, changes: $changes) {
			...CandidacyFullFragment
		}
	}
	${CandidacyFullFragment}
`;


