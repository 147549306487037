import React from 'react';
import { Col, Label, Row } from 'reactstrap';
import { renderEntityRO } from '../../utils/misc';
import { UserData } from '@food/auth';
import { t } from '../../utils/labels';
import { FieldComponentRO, SyncChoiceFieldType } from '../../types/form';

export const ChoicesFieldRO: React.FC<FieldComponentRO<
	SyncChoiceFieldType
>> = ({ field }) => (
	<UserData>
		{(user) => {
			let parsedValue: any = null;
			if (!field.value) return parsedValue;

			if (field.isEnum) {
				if (field.single) {
					parsedValue = t(field.value.label);
				} else {
					parsedValue = field.value ? (
						<ul>
							{field.value &&
								field.value.map((c) => (
									<li key={c.value}>{t(c.label)}</li>
								))}
						</ul>
					) : null;
				}
			} else {
				if (field.single) {
					if (field.value) {
						parsedValue = renderEntityRO(field, user, field.value.value);
					}
				} else {
					if (field.value) {
						parsedValue = (
							<ul>
								{field.value.map((c) => (
									<li key={c.value.id}>
										{renderEntityRO(field, user, c.value)}
									</li>
								))}
							</ul>
						);
					}
				}
			}

			return (
				<Row>
					<Col sm={4}>
						<Label>{field.label}:</Label>
					</Col>
					<Col sm={8}>{parsedValue}</Col>
				</Row>
			);
		}}
	</UserData>
);
