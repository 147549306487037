import gql from 'graphql-tag';
import { AnswerSetFullFragment } from '../fragments/AnswerSetFullFragment';

export const answerSetEditQuery = gql`
	query AnswerSetEditQuery($id: ID!) {
		entity: node(id: $id) {
			...AnswerSetFullFragment
		}
	}
	${AnswerSetFullFragment}
`;
