import { SimpleReportType } from 'components/ReportSimple';
import { InternalCapability } from 'server-types';
import { dateField } from 'utils/reports';

export const numeroVisite: SimpleReportType = {
	filename: 'numero_visite',
	title: 'Numero Visite',
	endpoint: '/api/reports/numero-visite',
	requirements: [InternalCapability.MarketingExtra],
	fields: [dateField],
};