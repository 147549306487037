import gql from 'graphql-tag';

export const ProducerHomeQuery = gql`
	query ProducerHomeQuery {
		currentUser {
			id
			managedByUsers {
				id
				Company {
					id
					awardInvitedCompanies {
						id
						signedContract
						maxPerCompanyCandidacies
						Award {
							id
							name
							year
							edition
							candidaciesCloseAt
							status
							options
							visibility
							logoMedia {
								id
								mediumThumbUrl
							}
							candidacies {
								id
							}
							maxPerCompanyCandidacies
						}
					}
				}
			}
		}
	}
`;
