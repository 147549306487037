import gql from 'graphql-tag';
import {CompanyGroupFullFragment} from "../fragments/CompanyGroupFullFragment";

export const CompanyGroupPatchMutation = gql`
	mutation CompanyGroupPatchMutation($id: ID!, $changes: CompanyGroupPatchInput!) {
		results: companyGroupPatch(id: $id, changes: $changes) {
			...CompanyGroupFullFragment
		}
	}
	${CompanyGroupFullFragment}
`;


