import gql from 'graphql-tag';

export const GoodRecallFullFragment = gql`
	fragment GoodRecallFullFragment on GoodRecall {
		id
		localId
		urlMinistero
		urlPdf
		recallReason
		publicationDate
		warnings
		goodBranding
		goodCategory
		RelatedOSA
		productionLot
		skuWeight
		manufacturerName
		manufacturingPlantCode
		manufacturingPlantAddress
		GoodClass {
			id
			name
		}
		Company {
			id
			name
		}
		storechains {
			id
			name
		}
	}
`;
