import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { NewsletterIssueListQuery } from '../graphql/query/NewsletterIssueListQuery';
import typeIntro from '../static/introspection/NewsletterIssue.json';
import NewsletterIssueFilters from '../static/introspection/NewsletterIssueFilterInput.json';
import { ENTITY } from '../utils/entities';

export const NewsletterIssueList: React.FC = () => (
	<EntityListFetcher
		query={NewsletterIssueListQuery}
		entityName={ENTITY.NEWSLETTER_ISSUE}
		headers={['Newsletter', 'publicationDate', 'name', 'status']}
		typeIntro={typeIntro}
		filtersIntro={NewsletterIssueFilters}
		filterFields={['Newsletter', 'name', 'statuses']}
	/>
);
