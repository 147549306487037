import React from 'react';
import * as git from '../static/rev.json';
import { UserData } from '@food/auth';

export const Footer: React.FC = () => (
	<UserData>
		{({ isInternal }) => (
			<footer className="footer puppeteer-hidden">
				Copyright © 2017-{new Date().getFullYear()} Food S.r.l.
				{isInternal && (
					<div className="pull-right">Commit {(git as any).rev}</div>
				)}
			</footer>
		)}
	</UserData>
);
