/**
 * Questo componente viene utilizzato all'interno del sistema dei form. I configuratori di dettaglio entita' hanno
 * il compito di chiamare EntityDetailFetcher con i parametri adeguati alla richiesta.
 *
 * A differenza di `EntityEditFetcher` la funzione per ottenere dati esterni e' sostituita da una query GraphQL: per ora
 * sono risultati validi gli assunti di dover sempre fare una query e che una query bastasse. Nel caso le cose
 * cambiassero in futuro sara' necessario passare anche qui a una props piu' simile a `fetcher`.
 *
 * Per più informazioni rivolgiti alla pagina di documentazione `architettura_form.md`
 */

import React from 'react';
import { IEntityDetailAction } from '../containers/EntityDetailView';
import {
	generateObjectFieldList,
	setFieldsValues,
} from '../utils/objectFields';
import { t } from '../utils/labels';
import { Spinner } from './MainContentSpinner';
import { Query } from 'react-apollo';
import {
	calculateUserPermissions,
	parsePermissions,
	Permission,
} from '../utils/permissions';
import {
	ERRORS,
	modalHandler,
	parseGraphqlError,
	sentryHandler,
} from '../utils/errors';
import { generateUrl, ACTIONS } from '../utils/urls';
import { NotFoundComponent } from './404';
import { pluralEntityLabel } from '../utils/misc';
import { entity2String } from '../utils/toString';
import { UserData, UserDataInterface } from '@food/auth';
import { IField } from '../types/form';
import { ENTITY } from '../utils/entities';
import { BreadcrumbItem } from '../types/ui';
import {
	EntityDetailViewProps,
	EntityDetailViewSwitch,
} from './EntityDetailViewSwitch';

// TODO commentare tipizzazione props EntityDetailFetcher
interface IEntityDetailFetcherProps<EntityAttributes = string> {
	id: string;
	query: any;
	fields: EntityAttributes[];
	typeIntro: any;
	transformer?: (
		detailData: { id: string; fields: ReadonlyArray<IField> },
		entity: any,
		queryResult: any,
	) => any;
	entityLabel?: string;
	forcedPermissions?: ((data: any, entity: any) => Permission) | Permission;
	onDelete?: () => void;
	myToString?: (entity: any) => string;
	disableCache?: boolean;
	customEditUrl?: (id: string) => string;
	modal?: boolean;
	entityName: ENTITY;
	clonable?: boolean;
	tabs?: string[];
	cols?: string[][];
	customActionComponents?: (entity: any) => JSX.Element[]; // TODO trasformare in componenti React
	showActions?: boolean;
	pre?: (entity: any) => JSX.Element | null; // TODO trasformare in componenti React
	post?: (entity: any) => JSX.Element | null; // TODO trasformare in componenti React
	hideHistory?: boolean | undefined;
	customActions?: IEntityDetailAction[]; // TODO passare tutto sotto customActionComponents
	footer?: JSX.Element; // TODO attivare strict null check
	footerHTML?: string; // TODO attivare strict null check
	breadcrumbs?: ReadonlyArray<BreadcrumbItem>;
	title?: string;
	children?: (args: EntityDetailViewProps) => JSX.Element;
}

const toDetailData = (
	user: UserDataInterface,
	entity: any,
	fieldsName: string[],
	typeIntrospection: any,
	entityLabel: string,
) => {
	const fs = user.isInternal ? ['localId'].concat(fieldsName) : fieldsName;
	const fields = generateObjectFieldList(
		user,
		fs,
		typeIntrospection,
		true,
		null,
		{},
		entityLabel,
	);

	fields.forEach((field) => {
		field.tab = 0;
		field.col = 0;
		field.label = field.label.replace(/\*/g, '');
	});

	return {
		id: entity.id,
		fields: setFieldsValues(fields, entity),
	};
};

export const EntityDetailFetcher: React.FC<IEntityDetailFetcherProps> = ({
	query,
	id,
	entityName,
	transformer,
	fields,
	typeIntro,
	onDelete,
	disableCache = false,
	entityLabel,
	myToString,
	forcedPermissions,
	customEditUrl,
	modal,
	children = EntityDetailViewSwitch,
	footer,
	footerHTML,
	hideHistory,
	tabs,
	cols,
	customActions,
	customActionComponents,
	clonable,
}) => {
	const errContext = { entityName };
	const label = entityLabel || pluralEntityLabel(entityName);
	const { description } = typeIntro;

	return (
		<Query<any, any>
			query={query}
			variables={{ id }}
			fetchPolicy={disableCache ? 'network-only' : undefined}
		>
			{({ error, loading, data }) => {
				if (error) {
					const errs = parseGraphqlError(error, errContext);
					if (errs.length === 1 && errs[0].message === ERRORS.NOT_FOUND) {
						// siamo in caso di not found
						return <NotFoundComponent />;
					}
					sentryHandler(errs);
					modalHandler(errs);
					return <section />;
				}

				if (loading) {
					return <Spinner show={true} />;
				}

				return (
					<UserData>
						{(user: UserDataInterface) => {
							const { entity } = data;
							const { userData, capabilities } = user;
							let detailData = toDetailData(
								user,
								entity,
								fields,
								typeIntro,
								entityLabel || entityName,
							);

							if (transformer) {
								detailData = transformer(detailData, entity, data);
							}

							// gestione permessi di manipolazione entita'
							const entityRolesPermissions = parsePermissions(
								description,
							);
							const permissions = calculateUserPermissions(
								capabilities,
								userData.role,
								entityRolesPermissions,
								typeof forcedPermissions === 'function'
									? forcedPermissions(data, entity)
									: forcedPermissions,
							);

							return (
								children({
									tabs,
									cols,
									modal,
									customEditUrl,
									data: detailData,
									entity,
									entityName,
									title: myToString
										? myToString(entity)
										: entity2String(entityName, entity),
									permissions,
									onDelete,
									breadcrumbs: [
										{ label: t`home`, path: '/' },
										{
											label: t(label),
											path: generateUrl(entityName, ACTIONS.LIST),
										},
										{
											label: myToString
												? myToString(entity)
												: entity2String(entityName, entity),
										},
									],
									footer,
									footerHTML,
									id,
									hideHistory,
									customActionComponents,
									customActions,
									clonable,
								}) || <section />
							);
						}}
					</UserData>
				);
			}}
		</Query>
	);
};
