import React, { useContext, useEffect, useRef, useState, memo, useMemo } from 'react';
import {
	FormGroup,
	FormFeedback,
	Col,
	Label,
	FormText,
	Button,
} from 'reactstrap';
import { t } from '../../utils/labels';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FieldComponent, RichTextFieldType } from '../../types/form';
import { html2text, identifyColors } from '../../utils/misc';
import { cns } from '@food/css-manager';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import { getLocalError } from '../../utils/formErrors';
import { FormContext } from '../../utils/context/form';
import { HTMLLevelToQuillConfig } from '../../utils/form';
import { PhotoshopPicker } from 'react-color';

var icons = Quill.import('ui/icons');
icons['textColorAdvanced'] = '<svg viewBox="0 0 18 18"> <line class="ql-color-label ql-stroke ql-transparent" x1="3" x2="15" y1="15" y2="15"></line> <polyline class="ql-stroke" points="5.5 11 9 3 12.5 11"></polyline> <line class="ql-stroke" x1="11.63" x2="6.38" y1="9" y2="9"></line> </svg>';

export const RichTextField: React.FC<FieldComponent<RichTextFieldType>> = memo(
	({ field, changeHandler, path }) => {
		const { values, errors, touched } = useContext(FormContext);
		const error = getLocalError(path, errors);
		const errorText = error ? error.message : '';
		const [isCode, setCode] = useState(false);
		const [localText, setLocalText] = useState(field.value);
		const [colors, setColors] = useState([]);
		const [currentTextColor, setCurrentTextColor] = useState(null);
		const [quillRef, setQuillRef] = useState(null);
		const [colorPanelVisible, setColorPanelVisible] = useState(false);
		const [colorPickerVisible, setColorPickerVisible] = useState(false);
		const [colorPickerValue, setColorPickerValue] = useState(null);
		const submittedText = useRef<string>();

		useEffect(() => {
			if (localText !== field.value || touched.includes(path)) {
				const t = setTimeout(() => changeHandler(localText, path), 500);
				submittedText.current = localText;
				return () => clearTimeout(t);
			}
			return () => { };
		}, [localText, field.changed]);

		useEffect(() => {
			if (
				field.value !== submittedText.current &&
				field.value !== localText
			) {
				setLocalText(field.value);
			}
		}, [field.value]);

		useEffect(() => {
			// imposto i colori di preset
			const presetColors = ['#000000', '#ffffff', '#e20613', '#009540', '#89a5c0', '#ffcb47', '#46bdad', '#e62261'];
			// cerco i colori nei valori degli altri campi/blocch i...
			let identifiedColors = identifyColors(values);
			// ... ed elimino eventuali doppioni rispetto agli elementi del preset
			identifiedColors = identifiedColors.filter((filterValue, filterIndex) => presetColors.indexOf(filterValue) === -1 && identifiedColors.indexOf(filterValue) === filterIndex);
			// predispongo l'array di colori: tools, preset e colori identificati
			const colors = [
				{ value: 'default', source: 'tool' },
				...presetColors.map((value) => { return { value: value, source: 'preset' } }), 		// genero gli oggetti dall'array di colori
				...identifiedColors.map((value) => { return { value: value, source: 'page' } }), 	// genero gli oggetti dall'array di colori
				{ value: 'custom', source: 'tool' }
			];
			setColors(colors);
		}, [values]);

		// gestisco il cambio di selezione all'interno dell'editor; questo mi consente di modificare il colore dell'icona dei colori del testo e di segnalare il colore corrente nel pannello dei colori
		const quillOnChangeSelection = (range, source, editor) => {
			if (range) {
				let index = range.index;
				let length = range.length;
				// se non è selezionato un range, ma il cursore si trova in un singolo punto, seleziono il carattere precedente per identificare il colore corrente
				if (range.length === 0 && index > 0) {
					index--;
					length++;
				}
				// recupero i contenuti della selezione
				const contents = editor.getContents(index, length);
				// se ha attributi
				if (contents.ops && contents.ops.length === 1 && contents.ops[0].attributes) {
					const attributes = contents.ops[0].attributes;
					if (attributes.color) {
						updateTextColorIcon(attributes.color);
					} else {
						updateTextColorIcon(null);
					}
				} else {
					updateTextColorIcon(null);
				}
			}
			setColorPanelVisible(false);
		}

		// al click di un colore nel panel dei colori
		const setTextColor = (color) => {
			if (quillRef === null) return;
			const Quill = quillRef.editor;
			const range = Quill.selection.savedRange;

			if (color === 'custom') {
				setColorPickerVisible(true);
				return;
			}
			if (color === 'default') color = false;

			if (range.length === 0) {
				// se non è selezionata una porzione di contenuto, imposto il colore per un inserimento futuro
				Quill.format('color', color);
			} else {
				// se è selezionata una porzione di contenuto, imposto il colore per quella porzione
				Quill.formatText(Quill.selection.savedRange, {
					'color': color
				});
			}

			// aggiorno il valore del campo per il salvataggio
			setLocalText(Quill.root.innerHTML);

			// aggiorno il colore dell'icona
			updateTextColorIcon(color);
			Quill.focus();
			setColorPanelVisible(false);
			setColorPickerVisible(false);
		};

		const updateTextColorIcon = (color) => {
			if (quillRef === null) return;
			const Quill = quillRef.editor;

			// imposto il current text color
			setCurrentTextColor(color);

			// verifico che ci sia l'icona 'textColorAdvanced' nella toolbar
			const filteredControls = Quill.theme.modules.toolbar.controls.filter((control) => control[0] === 'textColorAdvanced');
			if (filteredControls.length === 1) {
				// recupero l'elemento DOM
				const textColorAdvanced = Object.values(filteredControls)[0][1];
				// recupero la line figlia di svg all'interno del button
				const line = textColorAdvanced.children[0].children[0];
				// imposto lo stile
				if (color === null) {
					color = '#54667a';
					line.style.opacity = 0.4;
				} else {
					line.style.opacity = 1;
				}
				line.style.stroke = color;
			}
		}

		const config = HTMLLevelToQuillConfig(field);
		// se è presente l'icona 'customBtextColorAdvancedtn', imposto la funzione al click
		if (config.modules && config.modules.toolbar && config.modules.toolbar.handlers && config.modules.toolbar.handlers.textColorAdvanced) {
			config.modules.toolbar.handlers.textColorAdvanced = () => setColorPanelVisible(true);
		}

		// predispongo gli stili per gli elementi all'interno del color panel (diversi per 'source')
		const colorPanelItemStyles = {
			preset: (color) => ({
				backgroundColor: color,
				width: '15px',
				height: '15px',
				textIndent: '-100px',
				overflow: 'hidden',
				margin: '5px 5px 0 0',
				cursor: 'pointer',
				border: '1px solid #000',
				transform: (currentTextColor === color) ? 'scale(0.7)' : 'none'
			}),
			page: (color) => ({
				backgroundColor: color,
				width: '15px',
				height: '15px',
				textIndent: '-100px',
				overflow: 'hidden',
				margin: '5px 5px 0 0',
				cursor: 'pointer',
				border: '1px solid #000',
				borderRadius: '100%',
				transform: (currentTextColor === color) ? 'scale(0.7)' : 'none'
			}),
			tool: (type) => ({
				width: '15px',
				height: '15px',
				overflow: 'hidden',
				margin: '5px 5px 0 0',
				cursor: 'pointer'
			})
		}


		return (
			<FormGroup
				row
				color={error ? 'danger' : ''}
				className={field.hidden ? 'hidden' : ''}
			>
				<Col sm={12}>
					<Label>{field.label}:</Label>
					{field.htmlFull && (
						<div className="pull-right">
							{!isCode && (
								<Button onClick={() => setCode(true)}>
									<i className="fa fa-code" /> {t`Code`}
								</Button>
							)}
							{isCode && (
								<Button onClick={() => setCode(false)}>
									<i className="fa fa-eye" /> {t`Formatted`}
								</Button>
							)}
						</div>
					)}
				</Col>
				<Col sm={12}>
					{!isCode && (
						<>
							{colorPickerVisible &&
								<>
									<div className='colorPickerUIBlocker' onClick={() => setColorPickerVisible(false)} style={{
										position: 'fixed',
										top: 0,
										left: 0,
										right: 0,
										bottom: 0,
										background: 'rgba(0,0,0,0)',
										zIndex: 60,
										display: colorPickerVisible ? 'block' : 'none'
									}}></div>
									<div className='colorPicker' style={{
										position: 'fixed',
										zIndex: 70,
										top: '50%',
										left: '50%',
										transform: 'translate(-50%,-50%)'
									}}>
										<PhotoshopPicker
											color={colorPickerValue ? colorPickerValue : currentTextColor ? currentTextColor : '#54667a'}
											onChange={(color) => { setColorPickerValue(color.hex); }}
											onAccept={() => { setTextColor(colorPickerValue); setColorPickerValue(null); }}
											onCancel={() => setColorPickerVisible(false)}
										/>
									</div>
								</>
							}
							<div className='colorPanelUIBlocker' onClick={() => setColorPanelVisible(false)} style={{
								position: 'fixed',
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								background: 'rgba(0,0,0,0)',
								zIndex: 60,
								display: colorPanelVisible ? 'block' : 'none'
							}}></div>
							<div className='colorPanel' style={{
								position: 'absolute',
								zIndex: 70,
								marginTop: '40px',
								marginLeft: '15px',
								background: '#FFF',
								borderRadius: '2px',
								maxWidth: '195px',
								flexWrap: 'wrap',
								padding: '5px 5px 10px 10px',
								display: colorPanelVisible ? 'flex' : 'none',
								boxShadow: 'rgb(0 0 0 / 30%) 0px 0px 2px, rgb(0 0 0 / 30%) 0px 4px 8px'
							}}>
								{colors.map((color) => {
									if (color.value === 'default') {
										return (
											<div
												key={color.value}
												style={colorPanelItemStyles[color.source](color.value)}
												onClick={() => setTextColor(color.value)}
											>
												<svg viewBox="0 0 18 18" style={{ display: 'block' }}><line style={{ stroke: '#000' }} x1="5" x2="13" y1="3" y2="3"></line><line style={{ stroke: '#000' }} x1="6" x2="9.35" y1="12" y2="3"></line><line style={{ stroke: '#000' }} x1="11" x2="15" y1="11" y2="15"></line><line style={{ stroke: '#000' }} x1="15" x2="11" y1="11" y2="15"></line><rect style={{ fill: '#000' }} height="1" rx="0.5" ry="0.5" width="7" x="2" y="14"></rect></svg>
											</div>
										);
									} else if (color.value === 'custom') {
										return (
											<div
												key={color.value}
												style={colorPanelItemStyles[color.source](color.value)}
												onClick={() => setTextColor(color.value)}
											>
												<svg viewBox="0 0 100 100" width="15" height="15" style={{ display: 'block', borderRadius: '100%', border: '1px solid #000' }}>
													<g>
														<rect x="-10" width="110" height="110" fill="blue" />
														<rect x="50" width="60" height="110" fill="yellow" />
														<polygon points="50,50, 60,110, 40,110" fill="#0f8" />
														<polygon points="0,0, 100,0, 100,20, 50,50, 0,20" fill="red" />
														<polygon points="0,10, 50,50, 0,30" fill="#f0f" />
														<polygon points="100,10, 100,30, 50,50" fill="#f80" />
													</g>

												</svg>
											</div>
										);
									} else {
										return (
											<div
												key={color.value}
												style={colorPanelItemStyles[color.source](color.value)}
												onClick={() => setTextColor(color.value)}
											>
												{color.value}
											</div>
										);
									}
									return '';
								})}
							</div>
							<div key={field.name + (field.forceUpdateKey)}>
								{useMemo(() => <ReactQuill
									value={localText || ''}
									onChange={(text, delta, source) => {
										if (source == 'user') {
											setLocalText(text);
										}
									}}
									onChangeSelection={quillOnChangeSelection}
									ref={(el) => { setQuillRef(el) }}
									readOnly={field.disabled}

									modules={config.modules}
									formats={config.formats}
								/>, [quillRef])} {/*causa un focus al load del componente*/}
							</div>
						</>
					)}
					{isCode && (
						<CodeMirror
							value={localText}
							options={{
								mode: 'htmlmixed',
								theme: 'material',
								lineNumbers: true,
								readOnly: field.disabled,
							}}
							onBeforeChange={(editor, data, value) => {
								setLocalText(value);
							}}
							onChange={(editor, data, value) => {
								setLocalText(value);
							}}
						/>
					)}
					{error && (
						<section>
							<FormFeedback>{t(errorText + '/richtext')}</FormFeedback>
						</section>
					)}
					<FormText color="muted">
						<small style={{ display: 'block' }}>Vuoi inserire un'emoji? Copiala da <a href="https://www.getemoji.com" target="_blank">getemoji.com</a> ed incollala all'interno del campo di testo.</small>
						{field.helpText}
						<div
							className={cns(
								'usability-note',
								field.description.max &&
								field.description.max <
								html2text(localText).length &&
								'error',
							)}
						>
							{t`Text length`}: {html2text(localText).length}
							{field.description.max && '/' + field.description.max}{' '}
							{t`chars`}
						</div>
						{field.description.min !== undefined && (
							<div className="usability-note">
								{t`min length string`}: {field.description.min}
							</div>
						)}
						{field.description.max !== undefined && (
							<div className="usability-note">
								{t`max length string`}: {field.description.max}
							</div>
						)}
					</FormText>
				</Col>
			</FormGroup>
		);
	},
);
