import React from 'react';
import { questionSetDetailQuery } from '../graphql/query/QuestionSetDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/QuestionSet.json';
import { ENTITY } from '../utils/entities';
import gql from 'graphql-tag';
import { AnswerSetEdit } from './AnswerSetEdit';
import { AnswerSetDetail } from './AnswerSetDetail';
import { refactorInfo } from '../utils/form';

export const QuestionSetDetail = ({ id }) => (
	<EntityDetailFetcher
		query={questionSetDetailQuery}
		entityName={ENTITY.QUESTION_SET}
		id={id}
		typeIntro={typeIntro}
		fields={[
			'targetSite',
			'User',
			'question1',
			'requests',
			'sentDate',
			'answerSets',
		]}
		transformer={(data) => {
			data.fields.forEach((f) => {
				if (f.name === 'answerSets') {
					if (f.type !== 'EntityList') {
						refactorInfo(f, 'EntityList');
						return;
					}

					f.editComponent = AnswerSetEdit;
					f.detailComponent = AnswerSetDetail;
					f.props.denyAdd = true;
					f.props.headers = ['Company', 'sentDate'];
					f.props.fragment = gql`
						fragment Fragment on AnswerSet {
							Company {
								id
								name
							}
							sentDate
						}
					`;
				}
			});
			return data;
		}}
	/>
);
