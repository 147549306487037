import React from 'react';
import GraphiQL from 'graphiql';
import fetch from 'isomorphic-fetch';
import 'graphiql/graphiql.css';
import CONFIG from '../static/config';
import { getValidToken } from '@food/auth';

function graphQLFetcher(graphQLParams) {
	const additionalHeaders: any = {
		'Content-Type': 'application/json',
		'X-Target-Site': CONFIG.TARGET_SITE,
	};
	const token = getValidToken();
	if (token) {
		additionalHeaders.authorization = token;
	}

	return fetch(CONFIG.GRAPHQL_API, {
		method: 'post',
		headers: additionalHeaders,
		body: JSON.stringify(graphQLParams),
	}).then((response) => response.json());
}

export const GraphiqlComponent: React.FC<{}> = () => {
	return <GraphiQL fetcher={graphQLFetcher} />;
};
