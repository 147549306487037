import React from 'react';
import { CompanyYearCreateMutation } from '../graphql/mutations/CompanyYearCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/CompanyYear.json';
import inputTypeIntro from '../static/introspection/CompanyYearCreateInput.json';
import { CompanyYearEditQuery } from '../graphql/query/CompanyYearEditQuery';
import { toRefInput } from '../utils/dataTrasform';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { fastTransformer, toHidden } from '../utils/form';
import { transformerHandler } from '../utils/misc';
import { Company, CompanyYear } from '../server-types';
import { EntityCreateModal } from '../types/entities';

export const CompanyYearCreate: React.FC<EntityCreateModal<
	CompanyYear,
	Company
>> = ({ id, originalEntity, onSubmit }) => (
	<EntityEditFetcher
		id={id}
		onSubmit={onSubmit}
		fetcher={id ? graphqlQuery(CompanyYearEditQuery, { id }) : null}
		create={true}
		modal={true}
		mutation={CompanyYearCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.COMPANY_YEAR}
		fields={['Company', 'year', 'revenue', 'exportRevenue', 'gain', 'mol', 'numEmployees']}
		transformer={transformerHandler(
			fastTransformer({
				Company: toHidden(toRefInput(originalEntity)),
			}),
		)}
	/>
);
