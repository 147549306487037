import gql from 'graphql-tag';
import {BrandFullFragment} from "../fragments/BrandFullFragment";


export const BrandEditQuery = gql`
    query BrandEditQuery($id: ID!) {
        entity: node (id: $id) {
            ...BrandFullFragment
        }
        editorialSectionTree(EditorialBrand: GOODS4HUB) {
            id
            name
            Parent {
                id
            }
            goodClasses {
                id
                shortname
                shortnameIta
                localId
                name
                nameIta
            }
        }
    }
    ${BrandFullFragment}
`;



