import gql from 'graphql-tag';
import {EmailDomainFullFragment} from "../fragments/EmailDomainFullFragment";

export const EmailDomainCreateMutation = gql`
	mutation EmailDomainCreateMutation($values: EmailDomainCreateInput!) {
		results: emailDomainCreate(values: $values) {
			...EmailDomainFullFragment
		}
	}
	${EmailDomainFullFragment}
`;


