import React from 'react';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/StoreChain.json';
import inputTypeIntro from '../static/introspection/StoreChainCreateInput.json';
import { ENTITY } from '../utils/entities';
import { EntityCreateModal } from '../types/entities';
import { Company, FoodService, Retailer, Service, StoreChain } from '../server-types';
import { StoreChainCreateMutation } from 'graphql/mutations/StoreChainCreateMutation';
import { graphqlQuery } from 'utils/graphql';
import { storeChainTransformer } from 'utils/entities/storeChain';
import { StoreChainEditQuery } from 'graphql/query/StoreChainEditQuery';
import { StoreChainCreateQuery } from 'graphql/query/StoreChainCreateQuery';

export const StoreChainCreateModal: React.FC<EntityCreateModal<
	StoreChain,
	FoodService | Service | Retailer | Company
>> = ({ id, onSubmit, originalEntity }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(StoreChainEditQuery, { id }) : graphqlQuery(StoreChainCreateQuery)}
		id={id}
		onSubmit={onSubmit}
		create={true}
		modal={true}
		mutation={StoreChainCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.STORECHAIN}
		fields={[
			'Retailer',
			'Foodservice',
			'Service',
			'Company',
			'name',
			'logo_media',
			'price_positioning',
			'website',
			'social_instagram_page',
			'social_facebook_page',
			'description_ita',
			'description_long_ita',
			'stores_ownership',
			'type',
			'goodClasses',
			'countries',
		]}
		entitiesToFields={(props) => ({
			EditorialSections: props.editorialSectionTree
		})}
		//transformer={storeChainTransformer(originalEntity)}
		transformer={(...args) => storeChainTransformer(originalEntity, ...args)}
	/>
);

const StoreChainCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(StoreChainEditQuery, { id }) : graphqlQuery(StoreChainCreateQuery)}
		create={true}
		clone={!!id}
		id={id}
		mutation={StoreChainCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.STORECHAIN}
		fields={[
			'Retailer',
			'Foodservice',
			'Service',
			'Company',
			'name',
			'logo_media',
			'price_positioning',
			'website',
			'social_instagram_page',
			'social_facebook_page',
			'description_ita',
			'description_long_ita',
			'stores_ownership',
			'type',
			'goodClasses',
			'countries',
		]}
		entitiesToFields={(props) => ({
			EditorialSections: props.editorialSectionTree
		})}
		transformer={(...args) => storeChainTransformer(null, ...args)}
	/>
);

export { StoreChainCreate };