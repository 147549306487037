import gql from 'graphql-tag';

export const invalidateDirectoryMutation = gql`
	mutation InvalidateDirectoryMutation(
		$directory: DirectoryTypeEnum!
		$preClear: Boolean!
	) {
		invalidateDirectory(directory: $directory, preClear: $preClear) {
			code
			message
		}
	}
`;
