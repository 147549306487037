import React from 'react';
import { GeoIndicationCreateMutation } from '../graphql/mutations/GeoIndicationCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/GeoIndication.json';
import inputTypeIntro from '../static/introspection/GeoIndicationCreateInput.json';
import { GeoIndicationEditQuery } from '../graphql/query/GeoIndicationEditQuery';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';

export const GeoIndicationCreate = ({ id }) => (
	<UserData>
		{({ isInternal }) => (
			<EntityEditFetcher
				fetcher={id ? graphqlQuery(GeoIndicationEditQuery, { id }) : null}
				create={true}
				clone={!!id}
				id={id}
				mutation={GeoIndicationCreateMutation}
				typeIntro={typeIntro}
				inputTypeIntro={inputTypeIntro}
				entityName={ENTITY.GEO_INDICATION}
				fields={[
					isInternal ? 'sfId' : null,
					'name',
					'logoMedia',
					'akas',
					'euIndication',
					'italianIndication',
					'descriptionValues',
					'vatNumber',
					'website',
					'socialInstagramPage',
					'socialFacebookPage',
					'EuCategory',
					'Country',
					'StateProvince',
					'postalCode',
					'city',
					'address1',
					'address2',
					'lat',
					'lng',
					'companies',
				]}
			/>
		)}
	</UserData>
);
