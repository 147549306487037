import gql from 'graphql-tag';


export const MediaFullFragment = gql`
	fragment MediaFullFragment on Media {
		id
		localId
		type
		Company {
			id
			localId
			name
		}
		name
		caption
		origFileName
		timestamp
		origUrl
		largeThumbUrl
		mediumThumbUrl
		smallThumbUrl
	}

`;
