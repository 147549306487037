import { editorialSectionsToTree, getExpanded } from 'utils/tree';
import { toRefInput } from '../dataTrasform';
import { fastTransformer, refactorInfo, toHidden, toTree } from '../form';
import { ENTITY } from 'utils/entities';

export const storeChainTransformer = (originalEntity:any, data:any, entity: any, listsByType:any) => {
	data.fields = fastTransformer({
		goodClasses: (f:any) => {
			if (f.type !== 'AsyncChoices') {
				refactorInfo(f, 'AsyncChoices');
				return f;
			}
			const newField = toTree(
				f,
				false,
				editorialSectionsToTree(listsByType.EditorialSections),
			);
			if (f.value) {
				newField.value = f.value.map((choice) =>
					toRefInput(choice.value),
				);
				newField.expanded = getExpanded(
					listsByType.EditorialSections,
					f.value,
				);
			}

			return newField;

		},
		Retailer: (f) => {
			f.afterChange = (ff, value, fields) => {
				if (value !== null) {
					fields.filter((f) => ['Service', 'Company', 'Foodservice'].indexOf(f.name) > -1).map((f) => { 
						f.disabled = true;
						f.required = false;
						f.changed = true;
						f.label = f.label.replace('*', '');
					});
				} else {
					fields.filter((f) => ['Service', 'Company', 'Foodservice'].indexOf(f.name) > -1).map((f) => { 
						f.disabled = false;
						f.required = true;
						f.changed = true;
						f.label = f.label + '*';
					});
				}
				return fields;
			}
			if (originalEntity) {
				if (originalEntity.__typename === ENTITY.RETAILER) {
					return toHidden(toRefInput(originalEntity))(f);
				}
				return toHidden(f.value)(f);
			}
			return f;
		},
		Foodservice: (f) => {
			f.afterChange = (ff, value, fields) => {
				if (value !== null) {
					fields.filter((f) => ['Service', 'Company', 'Retailer'].indexOf(f.name) > -1).map((f) => { 
						f.disabled = true;
						f.required = false;
						f.changed = true;
						f.label = f.label.replace('*', '');
					});
				} else {
					fields.filter((f) => ['Service', 'Company', 'Retailer'].indexOf(f.name) > -1).map((f) => { 
						f.disabled = false;
						f.required = true;
						f.changed = true;
						f.label = f.label + '*';
					});
				}
				return fields;
			}
			if (originalEntity) {
				if (originalEntity.__typename === ENTITY.FOOD_SERVICE) {
					return toHidden(toRefInput(originalEntity))(f);
				}
				return toHidden(f.value)(f);
			}
			return f;
		},
		Company: (f) => {
			f.afterChange = (ff, value, fields) => {
				if (value !== null) {
					fields.filter((f) => ['Service', 'Retailer', 'Foodservice'].indexOf(f.name) > -1).map((f) => {
						f.disabled = true;
						f.required = false;
						f.changed = true;
						f.label = f.label.replace('*', '');
					});
				} else {
					fields.filter((f) => ['Service', 'Retailer', 'Foodservice'].indexOf(f.name) > -1).map((f) => { 
						f.disabled = false;
						f.required = true;
						f.changed = true;
						f.label = f.label + '*';
					});
				}
				return fields;
			}
			if (originalEntity) {
				if (originalEntity.__typename === ENTITY.COMPANY) {
					return toHidden(toRefInput(originalEntity))(f);
				}
				return toHidden(f.value)(f);
			}
			return f;
		},
		Service: (f) => {
			f.afterChange = (ff, value, fields) => {
				if (value !== null) {
					fields.filter((f) => ['Company', 'Retailer', 'Foodservice'].indexOf(f.name) > -1).map((f) => { 
						f.disabled = true;
						f.required = false;
						f.changed = true;
						f.label = f.label.replace('*', '');
					});
				} else {
					fields.filter((f) => ['Company', 'Retailer', 'Foodservice'].indexOf(f.name) > -1).map((f) => { 
						f.disabled = false;
						f.required = true;
						f.changed = true;
						f.label = f.label + '*';
					});
				}
				return fields;
			}
			if (originalEntity) {
				if (originalEntity.__typename === ENTITY.SERVICE) {
					return toHidden(toRefInput(originalEntity))(f);
				}
				return toHidden(f.value)(f);
			}
			return f;
		},
	})(data.fields);
	return data;
};
