import gql from 'graphql-tag';
import { CouponFullFragment } from '../fragments/CouponFullFragment';

export const CouponPatchMutation = gql`
	mutation CouponPatchMutation($id: ID!, $changes: CouponPatchInput!) {
		results: couponPatch(id: $id, changes: $changes) {
			...CouponFullFragment
		}
	}
	${CouponFullFragment}
`;
