import React from 'react';
import { CompanyCertificationDetailQuery } from '../graphql/query/CompanyCertificationDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/CompanyCertification.json';
import { ENTITY } from '../utils/entities';

export const CompanyCertificationDetail = ({ id }) => (
	<EntityDetailFetcher
		query={CompanyCertificationDetailQuery}
		entityName={ENTITY.COMPANY_CERTIFICATION}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		fields={['name', 'akas']}
	/>
);
