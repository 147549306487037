import React from 'react';
import { t } from '../utils/labels';
import { CompanyDetailQuery } from '../graphql/query/CompanyDetailQuery';
import { BrandDetail } from './BrandDetail';
import { BrandEdit } from './BrandEdit';
import { BrandCreate } from './BrandCreate';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/Company.json';
import { Alert, Col, Row } from 'reactstrap';
import { ACTIONS, generateUrl } from '../utils/urls';
import { Link } from 'react-router-dom';
import { CompanyYearDetail } from './CompanyYearDetail';
import { BranchCreate } from './BranchCreate';
import { BranchDetail } from './BranchDetail';
import { BranchEdit } from './BranchEdit';
import { FairParticipationCreate } from './FairParticipationCreate';
import { FairParticipationDetail } from './FairParticipationDetail';
import { FairParticipationEdit } from './FairParticipationEdit';
import { CompanyLevelCreate } from './CompanyLevelCreate';
import { CompanyLevelDetail } from './CompanyLevelDetail';
import { CompanyLevelEdit } from './CompanyLevelEdit';
import { CompanyYearCreate } from './CompanyYearCreate';
import { CompanyYearEdit } from './CompanyYearEdit';
import { HighlightedGoodEdit } from './HighlightedGoodEdit';
import { HighlightedGoodDetail } from './HighlightedGoodDetail';
import { HighlightedGoodCreate } from './HighlightedGoodCreate';
import { ENTITY } from '../utils/entities';
import {
	generateDefaultEntityListField,
	pluralEntityLabel,
} from '../utils/misc';
import gql from 'graphql-tag';
import { AwardInvitedCompanyDetail } from './AwardInvitedCompanyDetail';
import { AwardInvitedCompanyCreate } from './AwardInvitedCompanyCreate';
import { AwardInvitedCompanyEdit } from './AwardInvitedCompanyEdit';
import { UserData } from '@food/auth';
import { DistributionChannelEdit } from './DistributionChannelEdit';
import { DistributionChannelDetail } from './DistributionChannelDetail';
import { DistributionChannelCreate } from './DistributionChannelCreate';
import { Company, UserRole } from '../server-types';
import { refactorInfo, toAsyncRO } from '../utils/form';
import { ManagedByUserDetail } from './ManagedByUserDetail';
import { ManagedByUserEdit } from './ManagedByUserEdit';
import { ManagedByUserCreate } from './ManagedByUserCreate';
import { EntityDetailPage } from '../types/entities';
import { StoreChainCreateModal } from './StoreChainCreate';

export interface ICompanyDetailProps {
	id: string;
	modal?: boolean;
}

export const CompanyDetail: React.FC<EntityDetailPage<Company>> = ({ id }) => (
	<UserData>
		{(user) => {
			const { isInternal, userData } = user;
			return (
				<EntityDetailFetcher
					query={CompanyDetailQuery}
					entityName={ENTITY.COMPANY}
					id={id}
					typeIntro={typeIntro}
					fields={[
						isInternal ? 'sfId' : null,
						'industry',
						'logoMedia',
						'logoPrintMedia',
						'billingName',
						'name',
						'vatNumber',
						'ownership',
						'website',
						'companyEmail',
						'yearEstablished',
						'plProducer',
						'companyCertifications',
						'descriptionIta',
						'description',
						'descriptionLongIta',
						'descriptionLong',
						'geoIndications',
						'additionalImagesMedia',
						isInternal ? 'highlightedGoods' : null,
						'branches',
						'fairParticipations',
						isInternal ? 'companyLevels' : null,
						isInternal ? 'awardInvitedCompanies' : null,
						isInternal ? 'managedByUsers' : null,
						'distributionChannels',
						'brands',
						'companyYears',
						'storeChains'
					]} /*
					customActionComponents={(entity: Company) =>
						isInternal && [
							<a
								key={entity.id}
								href={
									'https://newsletter.gruppofood.com/render2?id=' +
									entity.id
								}
								target={'_blank'}
							>
								<Button onClick={() => false}>
									<i className={'fa fa-eye'} /> {t`view card preview`}
								</Button>
							</a>,
						]
					}*/
					tabs={
						isInternal
							? [
								t(ENTITY.COMPANY),
								t([ENTITY.COMPANY, 'brands', 'label'].join('/')),
								t(pluralEntityLabel(ENTITY.BRANCH)),
								t(pluralEntityLabel(ENTITY.DISTRIBUTION_CHANNEL)),
								t(pluralEntityLabel(ENTITY.FAIR_PARTICIPATION)),
								t(pluralEntityLabel(ENTITY.COMPANY_YEAR)),
								t([ENTITY.STORECHAIN, 'label'].join('/')),
								t(pluralEntityLabel(ENTITY.COMPANY_LEVEL)),
								t(pluralEntityLabel(ENTITY.HIGHLIGHTED_GOOD)),
								t(pluralEntityLabel(ENTITY.AWARD)),
								t(pluralEntityLabel(ENTITY.USER)),
								t(pluralEntityLabel(ENTITY.GOOD))
							]
							: [
								t(ENTITY.COMPANY),
								t([ENTITY.COMPANY, 'brands', 'label'].join('/')),
								t(pluralEntityLabel(ENTITY.BRANCH)),
								t(pluralEntityLabel(ENTITY.DISTRIBUTION_CHANNEL)),
								t(pluralEntityLabel(ENTITY.FAIR_PARTICIPATION)),
								t(pluralEntityLabel(ENTITY.COMPANY_YEAR)),
								t([ENTITY.STORECHAIN, 'label'].join('/')),
							]
					}
					cols={[
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
						['col-sm-12'],
					]}
					pre={(entity) => {
						const isProducer = userData.role === UserRole.Producer;

						if (
							isProducer &&
							entity &&
							entity.logoMedia &&
							entity.brands &&
							entity.brands.length > 0 &&
							entity.companyCertifications &&
							entity.companyCertifications.length > 0 &&
							entity.description &&
							entity.descriptionIta &&
							entity.descriptionLong &&
							entity.descriptionLongIta &&
							entity.logoPrintMedia
						) {
							return (
								<Row>
									<Col sm="10">
										<Alert color="success">
											{t`company complete alert`}{' '}
											<Link
												to={generateUrl(ENTITY.GOOD, ACTIONS.LIST)}
											>
												{t(pluralEntityLabel(ENTITY.GOOD))}
											</Link>
											.
										</Alert>
									</Col>
								</Row>
							);
						}
						if (!entity.brands || entity.brands.length === 0) {
							return (
								<Row>
									<Col sm="10">
										<Alert color="danger">{t`no brands alert`}</Alert>
									</Col>
								</Row>
							);
						}
						if (
							!entity.companyCertifications ||
							entity.companyCertifications.length === 0
						) {
							return (
								<Row>
									<Col sm="10">
										<Alert color="danger">
											{t`no companyCertifications alert`}
										</Alert>
									</Col>
								</Row>
							);
						}
						if (
							!entity.description ||
							!entity.descriptionIta ||
							!entity.descriptionLong ||
							!entity.descriptionLongIta
						) {
							return (
								<Row>
									<Col sm="10">
										<Alert color="danger">{t`no descriptions alert`}</Alert>
									</Col>
								</Row>
							);
						}

						if (!entity.logoPrintMedia) {
							return (
								<Row>
									<Col sm="10">
										<Alert color="danger">
											<span
												dangerouslySetInnerHTML={{
													__html: t`no logoPrintMedia alert`,
												}}
											/>
										</Alert>
									</Col>
								</Row>
							);
						}
						return null;
					}}
					transformer={(data, entity) => {
						data.fields = data.fields.map((f) => {
							switch (f.name) {
								case 'logoPrintMedia':
								case 'logoMedia':
									break;

								case 'geoIndications':
									f.type = 'AsyncChoices';
									break;

								case 'brands':
									if (f.type !== 'EntityList') {
										refactorInfo(f, 'EntityList');
										break;
									}
									f.tab = 1;
									f.editComponent = BrandEdit;
									f.detailComponent = BrandDetail;
									f.addComponent = BrandCreate;
									f.props.headers = [
										'name',
										'pricePositioning',
										'website',
									];
									f.props.fragment = gql`
										fragment Fragment on Brand {
											name
											pricePositioning
											website
										}
									`;

									break;

								case 'branches':
									if (f.type !== 'EntityList') {
										refactorInfo(f, 'EntityList');
										break;
									}
									f.tab = 2;
									f.editComponent = BranchEdit;
									f.detailComponent = BranchDetail;
									f.addComponent = BranchCreate;
									f.props.headers = ['name', 'type', 'Country'];
									f.props.fragment = gql`
										fragment Fragment on Branch {
											name
											type
											Country {
												name
												id
											}
										}
									`;
									break;

								case 'distributionChannels':
									if (f.type !== 'EntityList') {
										refactorInfo(f, 'EntityList');
										break;
									}
									f.tab = 3;
									f.editComponent = DistributionChannelEdit;
									f.detailComponent = DistributionChannelDetail;
									f.addComponent = DistributionChannelCreate;
									f.props.headers = [
										'countries',
										'mode',
										'contactEmail',
									];
									f.props.fragment = gql`
										fragment Fragment on DistributionChannel {
											id
											mode
											contactEmail
											countries {
												id
												name
											}
										}
									`;

									f.props.transformer = (fields) => {
										return fields.map((field) => {
											if (field.name === 'countries') {
												field.type = 'Text';
												field.value = field.value
													.map((c) => c.value.name)
													.join(', ');
											}
											return field;
										});
									};
									break;

								case 'fairParticipations':
									if (f.type !== 'EntityList') {
										refactorInfo(f, 'EntityList');
										break;
									}
									f.tab = 4;
									f.editComponent = FairParticipationEdit;
									f.detailComponent = FairParticipationDetail;
									f.addComponent = FairParticipationCreate;
									f.props.headers = ['Fair', 'pavilion', 'stand'];
									f.props.fragment = gql`
										fragment Fragment on FairParticipation {
											Fair {
												id
												name
												edition
												year
											}
											pavilion
											stand
										}
									`;
									break;

								case 'companyYears':
									if (f.type !== 'EntityList') {
										refactorInfo(f, 'EntityList');
										break;
									}
									f.tab = 5;
									f.editComponent = CompanyYearEdit;
									f.detailComponent = CompanyYearDetail;
									f.addComponent = CompanyYearCreate;
									f.props.headers = [
										'year',
										'revenue',
										'exportRevenue',
										'ebitda',
										'numEmployees',
									];
									f.props.fragment = gql`
										fragment Fragment on CompanyYear {
											year
											revenue
											ebitda
											numEmployees
											exportRevenue
										}
									`;
									break;

								case 'companyLevels':
									if (f.type !== 'EntityList') {
										refactorInfo(f, 'EntityList');
										break;
									}
									f.tab = 7;
									f.editComponent = CompanyLevelEdit;
									f.detailComponent = CompanyLevelDetail;
									f.addComponent = CompanyLevelCreate;
									f.props.headers = ['level', 'description'];
									f.props.fragment = gql`
										fragment Fragment on CompanyLevel {
											level
											description
										}
									`;
									f.props.transformer = (fields) => {
										fields.forEach((field) => {
											if (field.name === 'level') {
												field.type = 'Text';
												field.value = t(
													ENTITY.COMPANY_LEVEL +
													'/' +
													field.value.value,
												);
											}
										});
										return fields;
									};
									break;

								case 'highlightedGoods':
									if (f.type !== 'EntityList') {
										refactorInfo(f, 'EntityList');
										break;
									}
									f.tab = 8;
									f.editComponent = HighlightedGoodEdit;
									f.detailComponent = HighlightedGoodDetail;
									f.addComponent = HighlightedGoodCreate;
									f.props.headers = [
										'Good',
										'targetSite',
										'highlightReasons',
									];
									f.props.fragment = gql`
										fragment Fragment on HighlightedGood {
											Good {
												id
												name
											}
											targetSite
											highlightReasons
										}
									`;
									break;

								case 'awardInvitedCompanies':
									if (f.type !== 'EntityList') {
										refactorInfo(f, 'EntityList');
										break;
									}
									f.tab = 9;
									f.props.headers = ['Award', 'signedContract'];
									f.props.fragment = gql`
										fragment Fragment on AwardInvitedCompany {
											Award {
												id
												name
												edition
												year
											}
											signedContract
										}
									`;
									f.detailComponent = AwardInvitedCompanyDetail;
									f.editComponent = AwardInvitedCompanyEdit;
									f.addComponent = AwardInvitedCompanyCreate;
									break;

								case 'managedByUsers':
									if (f.type !== 'EntityList') {
										refactorInfo(f, 'EntityList');
										break;
									}
									f.tab = 10;
									f.props.headers = ['User', 'capabilities'];
									f.props.fragment = gql`
										fragment Fragment on ManagedByUser {
											User {
												id
												name
											}
											capabilities
										}
									`;
									f.props.detailUrl = (mbu) =>
										generateUrl(
											ENTITY.USER,
											ACTIONS.DETAIL,
											mbu.User.id,
										);
									f.detailComponent = ManagedByUserDetail;
									f.editComponent = ManagedByUserEdit;
									f.addComponent = ManagedByUserCreate;
									break;

								case 'companyCertifications':
									if (f.type !== 'EntityList') {
										refactorInfo(f, 'EntityList');
										break;
									}
									return toAsyncRO(f, ENTITY.COMPANY_CERTIFICATION);

								case 'storeChains':
									if (f.type !== 'EntityList') {
										refactorInfo(f, 'EntityList');
										return f;
									}
									f.props.headers = [
										'name',
									];
									f.addComponent = StoreChainCreateModal;
									f.tab = 6;
									f.props.fragment = gql`
										fragment Fragment on StoreChain {
											name
										}
									`;
									break;
								default:
									break;
							}
							return f;
						});

						if (isInternal) {
							const productsField = generateDefaultEntityListField(
								'goods',
								user,
								ENTITY.GOOD,
								entity,
							);
							productsField.tab = 11;
							productsField.props.paginated = true;
							productsField.props.headers = ['name', 'nameIta'];
							productsField.props.query = gql`
					query CompanyGoodQuery (
						$first: Int,
						$cursor: String
					) {
						goods (first: $first, after: $cursor, filter: { Company: { id: "${entity.id}"}  }) {
							edges {
								node {
									id
									name
									nameIta
								}
							}
							total
							pageInfo {
								hasNextPage
								hasPreviousPage
							}
						}
					}
				`;
							productsField.props.extractor = (data) => data.goods;

							data.fields = data.fields.concat([productsField]);
						}

						return data;
					}}
				/>
			);
		}}
	</UserData>
);
