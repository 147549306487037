
const encodeEmoji = (
	text: string,
): string => {
	// cerco emoji nel testo
    let emojis: any;
    // rimuovo dal test i valori rgb che vengono erroneamente presi dalla regex \p{Emoji}
    const testText = text.replace(/rgb\([\d]{1,3},[\s]*[\d]{1,3},[\s]*[\d]{1,3}[\s]*\)/,'');
    // costruisco l'array di caratteri identificati come emoji, da rimuovere dall'encoding:
    const excludedChars = ['©'];
    if (emojis = testText.match(/\p{Emoji}+/gu)) { // *
        emojis.map((emoji) => {
            if (emoji.indexOf("#") > -1 || !isNaN(emoji) || excludedChars.indexOf(emoji) > -1) return; // l'espressione regolare (*) identifica anche le emoji già encodate (es: #128518); nel caso, salto la codifica
            const hex = '&#'+emoji.codePointAt(0); // ricavo l'HTML entity hex
            text = text.replace(emoji,hex); // sostituisco l'emoji con l'hex
        });
    }
    return text;
};

export {
	encodeEmoji
};
