import gql from 'graphql-tag';

export const NewsletterIssueListQuery = gql`
	query NewsletterIssueListQuery(
		$first: Int
		$cursor: String
		$filter: NewsletterIssueFilterInput
	) {
		entities: newsletterIssues(
			first: $first
			after: $cursor
			filter: $filter
		) {
			edges {
				node {
					id
					localId
					publicationDate
					Newsletter {
						id
						name
						inputTemplate
					}
					name
					status
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;


