import gql from 'graphql-tag';
import {MagazineFullFragment} from "../fragments/MagazineFullFragment";

export const MagazineEditQuery = gql`
	query MagazineEditQuery($id: ID!) {
		entity: node (id: $id) {
				...MagazineFullFragment
		  }
	}
	${MagazineFullFragment}
`;


