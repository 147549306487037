import React from 'react';
import { ManagedByUserDetailQuery } from '../graphql/query/ManagedByUserDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/ManagedByUser.json';
import { ENTITY } from '../utils/entities';
import { ACTIONS, generateUrl } from '../utils/urls';
import { t } from '../utils/labels';
import { EntityDetailModal } from '../types/entities';
import {
	Company,
	CompanyGroup,
	Retailer,
	Service,
	User,
} from '../server-types';

export const ManagedByUserDetail: React.FC<EntityDetailModal<
	Company | Service | CompanyGroup | Retailer | User
>> = ({ id, onDelete }) => (
	<EntityDetailFetcher
		id={id}
		onDelete={onDelete}
		query={ManagedByUserDetailQuery}
		entityName={ENTITY.MANAGED_BY_USER}
		typeIntro={typeIntro}
		clonable={false}
		modal={true}
		fields={['User', 'capabilities']}
		transformer={(data, entity) => {
			const entityType =
				(entity[ENTITY.COMPANY] && ENTITY.COMPANY) ||
				(entity[ENTITY.SERVICE] && ENTITY.SERVICE) ||
				(entity[ENTITY.COMPANY_GROUP] && ENTITY.COMPANY_GROUP) ||
				(entity[ENTITY.RETAILER] && ENTITY.RETAILER) ||
				(entity[ENTITY.FOOD_SERVICE] && ENTITY.FOOD_SERVICE) ||
				'UNKNOWN';

			if (entityType !== 'UNKNOWN') {
				data.fields = data.fields.concat([
					{
						validators: [],
						col: 0,
						tab: 0,
						type: 'AsyncChoices',
						description: {},
						name: 'Entity',
						label: t(entityType),
						single: true,
						entityType,
						asyncQuery: () => null,
						value: {
							label: entity[entityType].name,
							value: entity[entityType],
						},
						mapToEntity: () =>
							generateUrl(
								entityType,
								ACTIONS.DETAIL,
								entity[entityType].id,
							),
					},
				]);
			}
			data.fields = data.fields.filter((f) => f.name !== ENTITY.USER);
			return data;
		}}
	/>
);
