import gql from 'graphql-tag';
import {RetailerFullFragment} from "../fragments/RetailerFullFragment";

export const RetailerPatchMutation = gql`
	mutation RetailerPatchMutation($id: ID!, $changes: RetailerPatchInput!) {
		results: retailerPatch(id: $id, changes: $changes) {
			...RetailerFullFragment
		}
	}
	${RetailerFullFragment}
`;


