import gql from 'graphql-tag';
import {EditorialSectionFullFragment} from "../fragments/EditorialSectionFullFragment";

export const EditorialSectionDetailQuery = gql`
	query EditorialSectionDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...EditorialSectionFullFragment
		}
	}
	${EditorialSectionFullFragment}
`;


