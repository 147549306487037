import React from 'react';
import { FairCreateMutation } from '../graphql/mutations/FairCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Fair.json';
import inputTypeIntro from '../static/introspection/FairCreateInput.json';
import { editorialSectionsToTree, getExpanded } from '../utils/tree';
import { FairCreateQuery } from '../graphql/query/FairCreateQuery';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { fastTransformer, refactorInfo, toTree } from '../utils/form';
import { TreeFieldType } from '../types/form';

export const FairCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(FairCreateQuery, { id })}
		create={true}
		clone={false}
		id={id}
		mutation={FairCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.FAIR}
		entitiesToFields={(props) => ({
			EditorialSections: props.editorialSectionTree,
		})}
		fields={[
			'name',
			'edition',
			'year',
			'logoMedia',
			'begin',
			'end',
			'targetSites',
			'goodClasses',
			'description',
			'descriptionIta',
			'website',
			'Country',
			'StateProvince',
			'postalCode',
			'city',
			'address1',
			'address2',
			'lat',
			'lng',
			'newsPath',
			'ourPavilion',
			'ourStand',
			'ourNote',
		]}
		transformer={(data, entity, listsByType) => {
			data.fields = fastTransformer({
				goodClasses: (f) => {
					if (f.type !== 'AsyncChoices') {
						refactorInfo(f, 'AsyncChoices');
						return f;
					}
					const newField: TreeFieldType = toTree(
						f,
						false,
						editorialSectionsToTree(listsByType.EditorialSections),
					);
					newField.expanded = getExpanded(
						listsByType.EditorialSections,
						f.value,
					);
					return newField;
				},
			})(data.fields);
			return data;
		}}
	/>
);
