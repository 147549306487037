import { UserData } from '@food/auth';
import { updateIfnPlatformStatsMutation } from 'graphql/mutations/updateIfnPlatformStatsMutation';
import React, { useCallback, useMemo, useState } from 'react';
import {
	Button,
	Card,
	CardBody,
	Form as BootstrapForm,
	Modal,
	ModalBody,
	ModalFooter,
} from 'reactstrap';
import { InternalCapability } from 'server-types';
import { client } from 'utils/client';
import { modalHandler, parseGraphqlError, sentryHandler } from 'utils/errors';
import { generateDateField } from 'utils/form';
import { toRequired } from 'utils/misc';
import { t } from '../utils/labels';
import { Form } from './Form';
import { FieldList } from './form/FieldsList';
import { PageTitle } from './PageTitle';
import { ReportNotAllowed } from './ReportNotAllowed';
import { DTPrecision } from 'types/form';

export const UpdateIfnStatsComponent: React.FunctionComponent<{}> = () => {
	const title = 'Update IFN Platform stats';

	const [updateStatsState, setUpdateStatsState] = useState({
		date: undefined,
		updating: false,
		showReloadConfirm: false,
		result: undefined,
	});
	const { date, updating, showReloadConfirm, result } = updateStatsState;
	const closeConfirm = useCallback(
		() =>
			setUpdateStatsState((state) => ({
				...state,
				date: undefined,
				updating: false,
				showReloadConfirm: false,
			})),
		[updateStatsState],
	);

	const closeResult = useCallback(() => {
		setUpdateStatsState((state) => ({
			...state,
			result: undefined,
		}));
	}, [updateStatsState]);

	const updateStats = useCallback(async (values: { date: Date }) => {
		try {
			// I need start of the month only and I need to fix timezone problems
			const cleanedDate = new Date(
				Date.UTC(values.date.getFullYear(), values.date.getMonth(), 1),
			);
			setUpdateStatsState((state) => ({
				...state,
				updating: true,
			}));
			const res = await client.mutate({
				mutation: updateIfnPlatformStatsMutation,
				variables: {
					date: cleanedDate,
				},
			});
			setUpdateStatsState((state) => ({
				...state,
				date: undefined,
				updating: false,
				showReloadConfirm: false,
				result: res?.data?.updateIfnPlatformStats,
			}));
		} catch (e) {
			const errs = parseGraphqlError(e);
			sentryHandler(errs);
			modalHandler(errs);
			console.error(e);
			setUpdateStatsState((state) => ({
				...state,
				updating: false,
				showReloadConfirm: false,
			}));
		}
	}, []);

	const fields = useMemo(
		() => [
			toRequired(
				generateDateField(
					'date',
					t`date-reload/date/label`,
					DTPrecision.MonthBegin,
				),
			),
		],
		[],
	);

	return (
		<UserData>
			{({ userData }) => {
				if (
					!userData.internalCapabilities.includes(
						InternalCapability.AdminExtra,
					)
				) {
					return <ReportNotAllowed />;
				}

				return (
					<>
						<PageTitle
							title={title}
							breadcrumbs={[
								{ label: t`home`, path: '/' },
								{
									label: t`Maintenance`,
								},
								{
									label: title,
								},
							]}
						/>
						<Modal
							isOpen={showReloadConfirm}
							toggle={updating ? undefined : closeConfirm}
							backdrop="static"
							wrapClassName="renew-modal-wrapper"
							autoFocus={false}
						>
							<ModalBody>
								<h3 className="text-center">{t`Confirm IFN stats text?`}</h3>
							</ModalBody>
							<ModalFooter>
								<Button
									disabled={updating}
									color="secondary"
									onClick={closeConfirm}
								>
									<i className="fa fa-times" /> {t`Undo`}
								</Button>{' '}
								<Button
									disabled={updating}
									color="danger"
									onClick={() => updateStats({ date })}
								>
									<i className="fa fa-trash" /> {t`Update IFN stats`}
								</Button>
							</ModalFooter>
						</Modal>
						<Modal
							isOpen={!!result}
							toggle={closeResult}
							backdrop="static"
							wrapClassName="renew-modal-wrapper"
							autoFocus={false}
						>
							<ModalBody>
								<h3 className="text-center">{result?.code}</h3>
								<p>{result?.message}</p>
							</ModalBody>
							<ModalFooter>
								<Button color="success" onClick={closeResult}>
									<i className="fa fa-close" /> {t`Close`}
								</Button>
							</ModalFooter>
						</Modal>
						<Card>
							<CardBody>
								<Form
									fields={fields}
									onSubmit={(fields, finalValues, injectErrors) => {
										setUpdateStatsState((state) => ({
											date: finalValues.date,
											updating: false,
											showReloadConfirm: true,
											result: undefined,
										}));
									}}
								>
									{({ fields, mutatorFactory, values, submit }) => (
										<BootstrapForm className="Form">
											<FieldList
												fields={fields}
												setter={mutatorFactory}
											/>
											<Button
												onClick={() => submit()}
												size="lg"
												color="success"
											>
												<i className="mdi mdi-download" />{' '}
												{t`Update IFN stats`}
											</Button>
										</BootstrapForm>
									)}
								</Form>
							</CardBody>
						</Card>
					</>
				);
			}}
		</UserData>
	);
};
