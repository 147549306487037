import React from 'react';
import { GoodClassPatchMutation } from '../graphql/mutations/GoodClassPatchMutation';
import { GoodClassEditQuery } from '../graphql/query/GoodClassEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/GoodClass.json';
import inputTypeIntro from '../static/introspection/GoodClassPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const GoodClassEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(GoodClassEditQuery, { id })}
		mutation={GoodClassPatchMutation}
		entityName={ENTITY.GOOD_CLASS}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={[
			'name',
			'nameIta',
			'akas',
			'level1Code',
			'level1Desc',
			'level1DescIta',
			'level2Code',
			'level2Desc',
			'level2DescIta',
			'level3Code',
			'level3Desc',
			'level3DescIta',
			'level4Code',
			'level4Desc',
			'level4DescIta',
			'shortname',
			'shortnameIta',
			'preservation',
			'trasformationLevel',
		]}
	/>
);
