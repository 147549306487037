/**
 * Questo componente ha il compito di fornire un'interfaccia di input per gli IField di tipo SubForm.
 *
 * La sua genesi è contorta e vale la pena di spendere qualche parola, così da giustificarne la strutturazione non molto razionale. Inizialmente i SubForm erano pensati per mostrare due elementi: una select, dove sarebbe stato possibile una tipologia di form; infine una renderizzazione dei campi corrispondenti alla tipologia di form selezionata. Era però stato inserito un automatismo per cui, in caso fosse stato possibile specificare una sola tipologia di form, la select sarebbe scomparsa (non essendo ovviamente possibile scegliere altre configurazioni).
 *
 * Nella pratica quest'ultima casistica è l'unica utilizzata: SubForm è diventato un sistema per renderizzare tipologie di sotto-form, la select non viene mai visualizzata. Verrà usato un altro sistema, ovvero liste di SubForm eterogenei e spostabili fra di loro (per esempio quelli usati nella composizione di form). Questo crea una struttura dati un po' contorta ma, tutto sommato funzionante. Altri commenti riguardo a questo argomento sono nel componente SubFormListField.
 */

import React, { useContext } from 'react';
import { Col, FormFeedback, FormGroup, Label } from 'reactstrap';
import { Select } from '@food/ui';
import {
	FieldComponent,
	FieldMuter,
	IField,
	SubFormFieldType,
	SubForms,
} from '../../types/form';
import { subFormsConstructors } from '../../utils/form';
import { t } from '../../utils/labels';
import { FieldList } from '../form/FieldsList';
import { getLocalError, hasErrors } from '../../utils/formErrors';
import { FormContext } from '../../utils/context/form';

export interface SubFormFieldProps extends FieldComponent<SubFormFieldType> {
	index?: number;
}

export const SubFormField: React.FC<SubFormFieldProps> = ({
	field: { choices, value },
	changeHandler,
	path,
	index,
}) => {
	let selected = value ? value.selected : null;
	let fields = value ? value.fields : [];

	// se ho una sola scelta possibile la seleziono automaticamente
	if (selected === null && choices.length === 1) {
		// devo fare un cast, typescript non capisce che uso subforms come liste
		selected = choices[0].value as SubForms;
		fields = subFormsConstructors[selected];
	}
	const { errors } = useContext(FormContext);
	const error = getLocalError(path, errors);

	// la funzione che, cambiando select, imposta i campi sottostanti
	const fieldChooser = React.useCallback(
		(selected: string) =>
			changeHandler(
				{
					selected,
					fields: subFormsConstructors[selected],
				},
				path,
			),
		[changeHandler],
	);

	// il setter da passare alla lista di campi, ancora da coniugare con il singolo field (notare che si tratta di
	// una funzione di ordine superiore)
	const fieldSetter = React.useCallback(
		(f: IField): FieldMuter => (val: any, path: string) =>
			changeHandler(
				{
					selected,
					fields: fields.map((fi) =>
						fi.name === f.name ? { ...f, value: val } : fi,
					),
				},
				path,
			),
		[changeHandler],
	);

	return (
		<Col sm={12}>
			{choices.length > 1 && (
				<FormGroup row color={hasErrors(path, errors) ? 'danger' : ''}>
					<Label sm={3}>{t`block type`}: </Label>
					<Col sm={9} className="checkbox-container">
						<Select
							options={[{ label: t`Undefined`, value: null }].concat(
								choices,
							)}
							selected={selected}
							onChange={fieldChooser}
							type={'alt'}
						/>
					</Col>
				</FormGroup>
			)}
			{choices.length === 1 && (
				<h3>
					{index !== undefined && index + 1 + '. '}
					{choices[0].label}
				</h3>
			)}
			{error && (
				<FormFeedback invalid>
					{t(error.message + '/number', error.message)}
				</FormFeedback>
			)}
			{selected && (
				<FieldList path={path} fields={fields} setter={fieldSetter} />
			)}
		</Col>
	);
};
