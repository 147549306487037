import gql from 'graphql-tag';
import {ManagedByUserFullFragment} from "../fragments/ManagedByUserFullFragment";

export const ManagedByUserCreateMutation = gql`
	mutation ManagedByUserCreateMutation($values: ManagedByUserCreateInput!) {
		results: managedByUserCreate(values: $values) {
			...ManagedByUserFullFragment
		}
	}
	${ManagedByUserFullFragment}
`;


