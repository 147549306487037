import React from 'react';
import { openRenewModal, UserData } from '@food/auth';
import { ProducerHome } from '../components/ProducerHome';
import { Button } from '@food/ui';
import { UserRole } from '../server-types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { getLastUsed } from '../utils/lastUsed';
import { Link } from 'react-router-dom';
import { currySC, IFNTheme, registerClass } from '@food/css-manager';
import { ENTITY } from '../utils/entities';
import { t } from '../utils/labels';
import { pluralEntityLabel } from '../utils/misc';
import { ACTIONS, generateUrl } from '../utils/urls';

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	.${sc('big')} {
		font-size: ${t.ratios.xl}em;
	}
	
	.${sc('fast-action-block')} {
		text-align: center;
		animation-duration: 3s;
		
		&:hover {
			box-shadow: ${t.boxShadow(1)};
		}
		
		i.mdi:before {
			font-size: ${t.ratios.xl ** 4}em;
			color: ${t.colors.grey.dark};
			margin-bottom: ${t.ratios.s}rem;
		}
		
		* > div {
			font-size: ${t.ratios.l ** 2}em;
			color: ${t.colors.primary.plain};
		}
	}
`,
);

const sc = currySC(styleClass);

export const Dashboard = () => (
	<UserData>
		{({ userData }) => {
			if (userData.role === UserRole.Producer) {
				return <ProducerHome />;
			}
			if (userData.role === UserRole.Consumer) {
				return (
					<div>
						<Button
							onClick={() =>
								openRenewModal({ upgradeRole: true })
							}
							label={'Upgrade to Producer'}
						/>
					</div>
				);
			}
			if (userData.role === UserRole.Internal) {
				return (
					<Row className={styleClass}>
						<Col sm={8}>
							<Row>
								<Col lg={3} sm={6}>
									<Link to={generateUrl(ENTITY.GOOD, ACTIONS.LIST)}>
										<Card className={sc('fast-action-block')}>
											<CardBody>
												<i className={'mdi mdi-barley'} />
												<div>
													{t(pluralEntityLabel(ENTITY.GOOD))}
												</div>
											</CardBody>
										</Card>
									</Link>
								</Col>
								<Col lg={3} sm={6}>
									<Link to={generateUrl(ENTITY.COMPANY, ACTIONS.LIST)}>
										<Card className={sc('fast-action-block')}>
											<CardBody>
												<i className={'mdi mdi-factory'} />
												<div>
													{t(pluralEntityLabel(ENTITY.COMPANY))}
												</div>
											</CardBody>
										</Card>
									</Link>
								</Col>
								<Col lg={3} sm={6}>
									<Link
										to={generateUrl(ENTITY.RETAILER, ACTIONS.LIST)}
									>
										<Card className={sc('fast-action-block')}>
											<CardBody>
												<i className={'mdi mdi-cart'} />
												<div>
													{t(pluralEntityLabel(ENTITY.RETAILER))}
												</div>
											</CardBody>
										</Card>
									</Link>
								</Col>
								<Col lg={3} sm={6}>
									<Link to={generateUrl(ENTITY.USER, ACTIONS.LIST)}>
										<Card className={sc('fast-action-block')}>
											<CardBody>
												<i className={'mdi mdi-account'} />
												<div>
													{t(pluralEntityLabel(ENTITY.USER))}
												</div>
											</CardBody>
										</Card>
									</Link>
								</Col>
								<Col lg={3} sm={6}>
									<Link to={generateUrl(ENTITY.AWARD, ACTIONS.LIST)}>
										<Card className={sc('fast-action-block')}>
											<CardBody>
												<i className={'mdi mdi-trophy-variant'} />
												<div>
													{t(pluralEntityLabel(ENTITY.AWARD))}
												</div>
											</CardBody>
										</Card>
									</Link>
								</Col>
								<Col lg={3} sm={6}>
									<Link
										to={generateUrl(ENTITY.CANDIDACY, ACTIONS.LIST)}
									>
										<Card className={sc('fast-action-block')}>
											<CardBody>
												<i className={'mdi mdi-trophy-award'} />
												<div>
													{t(pluralEntityLabel(ENTITY.CANDIDACY))}
												</div>
											</CardBody>
										</Card>
									</Link>
								</Col>
								<Col lg={3} sm={6}>
									<Link to={'/reports'}>
										<Card className={sc('fast-action-block')}>
											<CardBody>
												<i className={'mdi mdi-chart-areaspline'} />
												<div>{t`Reports`}</div>
											</CardBody>
										</Card>
									</Link>
								</Col>
								<Col lg={3} sm={6}>
									<Link
										to={generateUrl(
											ENTITY.OBJECT_HISTORY,
											ACTIONS.LIST,
										)}
									>
										<Card className={sc('fast-action-block')}>
											<CardBody>
												<i className={'mdi mdi-calendar-clock'} />
												<div>
													{t(
														pluralEntityLabel(
															ENTITY.OBJECT_HISTORY,
														),
													)}
												</div>
											</CardBody>
										</Card>
									</Link>
								</Col>
							</Row>
						</Col>
						<Col sm={3}>
							<Card>
								<CardBody>
									<h2>Usati di recente:</h2>
									<ul>
										{getLastUsed()
											.filter((v) => v.path !== '/')
											.sort((a, b) =>
												('' + a.label).localeCompare(b.label),
											)
											.map((voice) => (
												<li key={voice.label} className={sc('big')}>
													<Link to={voice.path}>
														{voice.label}
													</Link>
												</li>
											))}
									</ul>
								</CardBody>
							</Card>
						</Col>
					</Row>
				);
			}
			return <section />;
		}}
	</UserData>
);
