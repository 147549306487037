import gql from 'graphql-tag';
import {CompanyBadgeFullFragment} from "../fragments/CompanyBadgeFullFragment";

export const CompanyBadgeEditQuery = gql`
	query CompanyBadgeEditQuery($id: ID!) {
		entity: node (id: $id) {
				...CompanyBadgeFullFragment
		  }
	}
	${CompanyBadgeFullFragment}
`;


