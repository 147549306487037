import gql from 'graphql-tag';
import {CompanyCertificationFullFragment} from "../fragments/CompanyCertificationFullFragment";

export const CompanyCertificationEditQuery = gql`
	query CompanyCertificationEditQuery($id: ID!) {
		entity: node (id: $id) {
				...CompanyCertificationFullFragment
		  }
	}
	${CompanyCertificationFullFragment}
`;


