import gql from 'graphql-tag';
import { nodeNameFragment } from '../../utils/toString';

export const ObjectHistoryListQuery = gql`
	query ObjectHistoryListQuery(
		$first: Int
		$cursor: String
		$filter: ObjectHistoryFilterInput
	) {
		entities: objectHistories(
			first: $first
			after: $cursor
			filter: $filter
		) {
			edges {
				node {
					id
					localId
					User {
						id
						name
						managedByUsers(currentOnly: true) {
							id
							Company {
								id
								name
								billingName
							}
						}
					}
					timestamp
					collection
					action
					params
					Object {
						...NodeNameFragment
					}
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}
	}
	${nodeNameFragment}
`;


