import React, { useContext } from 'react';
import {
	Badge,
	Col,
	FormFeedback,
	FormGroup,
	FormText,
	Label,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import { t } from '../../utils/labels';
import { dateFieldMutator } from '../../utils/fieldMutators';
import { DateFieldType, DTPrecision, FieldComponent } from '../../types/form';
import { getLocalError } from '../../utils/formErrors';
import { FormContext } from '../../utils/context/form';

export const DateField: React.FC<FieldComponent<DateFieldType>> = ({
	field,
	changeHandler,
	path,
}) => {
	const formContext = useContext(FormContext);
	const error = getLocalError(path, formContext?.errors || {});
	const hasTime = field.precision === DTPrecision.Full;

	return (
		<FormGroup row color={error ? 'danger' : ''} className={field.hidden ? 'hidden' : ''}>
			<Label sm={3}>{field.label}: </Label>
			<Col sm={9}>
				<DatePicker
					onChange={dateFieldMutator(changeHandler, path)}
					selected={field.value}
					dateFormat={hasTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
					isClearable={true}
					className="form-control col-sm-12"
					showTimeSelect={hasTime}
					timeFormat={'HH:mm'}

				/>
				{error && (
					<section>
						<FormFeedback>{t(error.message)}</FormFeedback>
					</section>
				)}
				<FormText color="muted">
					{field.helpText}
					{field.additionalHelpComponent}
					{field.description.readonly && (
						<div>
							<Badge color="warning">{t`warning`}</Badge>{' '}
							{t`readonly field description`}
						</div>
					)}
				</FormText>
			</Col>
		</FormGroup>
	);
};
