import { SimpleReportType } from 'components/ReportSimple';
import { InternalCapability } from 'server-types';
import { dateField } from 'utils/reports';

export const numeroUtenti: SimpleReportType = {
	filename: 'numero_utenti',
	title: 'Numero Utenti',
	endpoint: '/api/reports/numero-utenti',
	requirements: [InternalCapability.MarketingExtra],
	fields: [dateField],
};