import gql from 'graphql-tag';
import {BranchFullFragment} from "../fragments/BranchFullFragment";

export const BranchDetailQuery = gql`
	query BranchDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...BranchFullFragment
		}
	}
	${BranchFullFragment}
`;


