import gql from 'graphql-tag';
import {fairParticipationFullFragment} from "../fragments/FairParticipationFullFragment";

export const fairParticipationDetailQuery = gql`
	query fairParticipationDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...fairParticipationFullFragment
		}
	}
	${fairParticipationFullFragment}
`;


