import gql from 'graphql-tag';
import { Award_producer_FullFragment } from '../fragments/Award_producer_FullFragment';

export const AwardProducerDetailQuery = gql`
	query AwardDetailQuery($id: ID!) {
		entity: node(id: $id) {
			...Award_producer_FullFragment
		}
		goods {
			total
		}
		currentUser {
			id
			managedByUsers {
				id
				Company {
					id
					logoMedia {
						id
					}
					logoPrintMedia {
						id
					}
					brands {
						id
					}
					goods {
						id
					}
					description
					descriptionIta
					descriptionLong
					descriptionLongIta
					companyCertifications {
						id
					}
					companyYears {
						id
						year
						revenue
						numEmployees
					}
				}
			}
		}
	}
	${Award_producer_FullFragment}
`;
