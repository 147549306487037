import React from 'react';
import { ManagedByUserCreateMutation } from '../graphql/mutations/ManagedByUserCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/ManagedByUser.json';
import inputTypeIntro from '../static/introspection/ManagedByUserCreateInput.json';
import { ManagedByUserEditQuery } from '../graphql/query/ManagedByUserEditQuery';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';
import { managedByUserT } from '../utils/entities/managedByUser';
import { transformerHandler } from '../utils/misc';
import { EntityCreateModal } from '../types/entities';
import {
	Company,
	CompanyGroup, FoodService,
	ManagedByUser,
	Retailer,
	Service,
	User,
} from '../server-types';

export const ManagedByUserCreate: React.FC<EntityCreateModal<
	ManagedByUser,
	Company | Service | CompanyGroup | Retailer | FoodService | User
>> = ({ id, onSubmit, originalEntity }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(ManagedByUserEditQuery, { id }) : null}
		onSubmit={onSubmit}
		create={true}
		modal={true}
		mutation={ManagedByUserCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.MANAGED_BY_USER}
		beforeSaveTransformer={(data) => {
			if (originalEntity.__typename === ENTITY.USER) {
				data[data.Type.value] = data.Entity;
				delete data.Entity;
				delete data.Type;
			}
			return data;
		}}
		fields={['User', 'capabilities']}
		transformer={transformerHandler(managedByUserT(originalEntity))}
	/>
);
