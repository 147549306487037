import gql from 'graphql-tag';
import { DistributionChannelFullFragment } from '../fragments/DistributionChannelFullFragment';

export const DistributionChannelEditQuery = gql`
	query DistributionChannelEditQuery($id: ID!) {
		entity: node(id: $id) {
			...DistributionChannelFullFragment
		}
		countries: countries {
			edges {
				node {
					id
					name
					area
				}
			}
		}
	}
	${DistributionChannelFullFragment}
`;

