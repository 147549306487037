import React from 'react';
import { CompanyPatchMutation } from '../graphql/mutations/CompanyPatchMutation';
import { CompanyEditQuery } from '../graphql/query/CompanyEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Company.json';
import inputTypeIntro from '../static/introspection/CompanyPatchInput.json';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';
import { generateFakeAsyncQuery } from '../utils/misc';
import { fastTransformer } from '../utils/form';
import { assignAsyncQuery } from '../utils/transformers';

export interface ICompanyEditProps {
	id: string;
	modal?: boolean;
}

export const CompanyEdit: React.FC<ICompanyEditProps> = ({ id, modal }) => (
	<UserData>
		{({ isInternal }) => (
			<EntityEditFetcher
				modal={modal}
				fetcher={graphqlQuery(CompanyEditQuery, { id })}
				mutation={CompanyPatchMutation}
				entityName={ENTITY.COMPANY}
				typeIntro={typeIntro}
				inputTypeIntro={inputTypeIntro}
				id={id}
				fields={[
					isInternal ? 'sfId' : null,
					'industry',
					'logoMedia',
					'logoPrintMedia',
					'billingName',
					'name',
					'vatNumber',
					'ownership',
					'website',
					'companyEmail',
					'yearEstablished',
					'plProducer',
					'companyCertifications',
					'descriptionIta',
					'description',
					'descriptionLongIta',
					'descriptionLong',
					'geoIndications',
					'additionalImagesMedia',
					'billingBranch',
					'headquarterBranch',
				]}
				transformer={(data, entity) => {
					const t = assignAsyncQuery(
						generateFakeAsyncQuery(entity.branches),
					);
					data.fields = fastTransformer({
						billingBranch: t,
						headquarterBranch: t,
					})(data.fields);
					return data;
				}}
			/>
		)}
	</UserData>
);
