import React from 'react';
import { EditorialSectionPatchMutation } from '../graphql/mutations/EditorialSectionPatchMutation';
import { EditorialSectionEditQuery } from '../graphql/query/EditorialSectionEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/EditorialSection.json';
import inputTypeIntro from '../static/introspection/EditorialSectionPatchInput.json';
import { ENTITY } from '../utils/entities';
import { editorialSectionsToTree, getExpanded } from '../utils/tree';
import { toRefInput } from '../utils/dataTrasform';
import { generateAsyncQuery, graphqlQuery } from '../utils/graphql';
import { fastTransformer, refactorInfo, toTree } from '../utils/form';
import { EditorialSectionSelectQuery } from '../graphql/query/EditorialSectionSelectQuery';

export const EditorialSectionEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(EditorialSectionEditQuery, { id })}
		mutation={EditorialSectionPatchMutation}
		entityName={ENTITY.EDITORIAL_SECTION}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entitiesToFields={(props) => ({
			EditorialSections: props.editorialSectionTree,
		})}
		id={id}
		fields={[
			'name',
			'Parent',
			'color',
			'akas',
			'level',
			'styleClass',
			'iconMedia',
			'goodClasses',
		]}
		transformer={(data, entity, listsByType) => {
			data.fields = fastTransformer({
				goodClasses: (f) => {
					if (f.type !== 'AsyncChoices') {
						refactorInfo(f, 'AsyncChoices');
						return f;
					}
					const newField = toTree(
						f,
						false,
						editorialSectionsToTree(listsByType.EditorialSections),
					);

					if (f.value) {
						newField.value = f.value.map((choice) =>
							toRefInput(choice.value),
						);
						newField.expanded = getExpanded(
							listsByType.EditorialSections,
							f.value,
						);
					}
					return newField;
				},
				Parent: (f) => {
					if (f.type !== 'AsyncChoices') {
						refactorInfo(f, 'AsyncChoices');
						return f;
					}
					f.asyncQuery = generateAsyncQuery(
						EditorialSectionSelectQuery,
						true,
						(name) => ({
							filter: { name, EditorialBrand: entity.editorialBrand },
						}),
					);
					return f;
				},
			})(data.fields);

			return data;
		}}
	/>
);
