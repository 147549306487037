import React from 'react';
import { CandidacyCreateMutation } from '../graphql/mutations/CandidacyCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Candidacy.json';
import inputTypeIntro from '../static/introspection/CandidacyCreateInput.json';
import { CandidacyEditQuery } from '../graphql/query/CandidacyEditQuery';
import { CompanyWithBrandsSelectQuery } from '../graphql/query/CompanyWithBrandsSelectQuery';
import { GoodsInBrandsWithEANSelectQuery } from '../graphql/query/GoodsInBrandsWithEANSelectQuery';
import { max, required } from '../utils/validation';
import { generateAsyncQuery, graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';
import { t } from '../utils/labels';
import { AwardWithDescriptionSelectQuery } from '../graphql/query/AwardWithDescriptionSelectQuery';
import { generateFakeAsyncQuery, toRequired } from '../utils/misc';
import { fastTransformer, refactorInfo } from '../utils/form';
import { AwardOptionFlag, Candidacy } from '../server-types';
import { EntityCreatePage } from '../types/entities';

export const CandidacyCreate: React.FC<EntityCreatePage<Candidacy>> = ({
	id,
}) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(CandidacyEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={CandidacyCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.CANDIDACY}
		tabs={[
			t('generic data'),
			t([ENTITY.CANDIDACY, 'medias', 'label'].join('/')),
		]}
		fields={[
			'Award',
			'Company',
			'Brand',
			'goods',
			'featuredImageMedia',
			'extraMedia',
			'name',
			'range',
			'description1',
			'description2',
			'description3',
			'description4',
			'description5',
			'description6',
			'description7',
			'description8',
			'description9',
			'description10',
			'launchDate',
			'additionalImagesMedia',
		]}
		transformer={(data) => {
			let award = null;
			let brand = null;
			let company = null;

			const resetGoodFields = (field) => {
				if (award) {
					if (
						Object.keys(deducibleFields[award.langCode]).indexOf(
							field.name,
						) !== -1
					) {
						field.value = null;
					}
				}
				console.log(field);
				return field;
			};

			const fieldsThatNeedAward = ['Company', 'Brand', 'goods'];
			const deducibleFields = {
				it_IT: {
					featuredImageMedia: 'featuredImageItaMedia',
					name: 'nameIta',
					range: 'range',
				},
				en_US: {
					featuredImageMedia: 'featuredImageMedia',
					name: 'name',
					range: 'range',
				},
			};

			data.fields.map((f) => {
				switch (f.name) {
					case 'launchDate':
					case 'formats':
					case 'AwardCategory':
						break;

					case 'additionalImagesMedia':
						f.tab = 1;
						break;

					case 'description1':
					case 'description2':
					case 'description3':
					case 'description4':
					case 'description5':
					case 'description6':
					case 'description7':
					case 'description8':
					case 'description9':
					case 'description10':
						f.hidden = true;
						break;

					case 'goods':
						if (f.type !== 'AsyncChoices') {
							refactorInfo(f, 'AsyncChoices');
							break;
						}
						f.disabled = true;
						f.myToString = (e) =>
							award && award.langCode === 'it_IT' ? e.nameIta : e.name;
						f.asyncQuery = generateAsyncQuery(
							GoodsInBrandsWithEANSelectQuery,
							true,
							(value) => {
								return {
									filter: {
										Brand: brand,
										eanPresent:
											award &&
												award.options.indexOf('EAN_REQUIRED') !== -1
												? true
												: undefined,
										name: value,
									},
								};
							},
						);
						f.afterChange = (field, value, fields) => {
							const goodsNumber = value ? value.length : 0;
							if (goodsNumber === 1) {
								const good = value[0].value;
								return fields.map((f) => {
									if (
										Object.keys(
											deducibleFields[award.langCode],
										).indexOf(f.name) !== -1
									) {
										f.value =
											good[deducibleFields[award.langCode][f.name]];
									}
									return f;
								});
							} else if (goodsNumber > 1) {
								return fields.map((f) => {
									if (
										Object.keys(
											deducibleFields[award.langCode],
										).indexOf(f.name) !== -1
									) {
										f.disabled = false;
										f.value = null;
									}
									return f;
								});
							} else {
								// condizione implicita: goodsNumber === 0
								return fields.map((f) => {
									if (
										Object.keys(
											deducibleFields[award.langCode],
										).indexOf(f.name) !== -1
									) {
										f.disabled = true;
										f.value = null;
									}
									return f;
								});
							}
						};
						break;

					case 'Brand':
						if (f.type !== 'AsyncChoices') {
							refactorInfo(f, 'AsyncChoices');
							break;
						}
						f.disabled = true;
						f.asyncQuery = generateFakeAsyncQuery([]);
						f.afterChange = (ff, value, fields) => {
							if (value) {
								const brandRef = { id: value.value.id };
								return resetGoodFields(
									fastTransformer({
										goods: (field) => {
											if (field.type !== 'AsyncChoices') {
												refactorInfo(field, 'AsyncChoices');
												return field;
											}
											field.disabled = false;
											field.asyncQuery = generateAsyncQuery(
												GoodsInBrandsWithEANSelectQuery,
												true,
												(name) => {
													return {
														filter: {
															name,
															Brand: brandRef,
															eanPresent:
																award &&
																	award.options.indexOf(
																		'EAN_REQUIRED',
																	) !== -1
																	? true
																	: undefined,
														},
													};
												},
											);
											return field;
										},
									})(fields),
								);
							} else {
								return resetGoodFields(
									fastTransformer({
										goods: (field) => {
											field.disabled = true;
											return field;
										},
									})(fields),
								);
							}
						};
						break;

					case 'Company':
						if (f.type !== 'AsyncChoices') {
							refactorInfo(f, 'AsyncChoices');
							break;
						}
						f.disabled = true;
						f.asyncQuery = generateAsyncQuery(
							CompanyWithBrandsSelectQuery,
							true,
						);
						f.afterChange = (field, value, fields) => {
							const newFields = fields.map((field) => {
								if (!value || company !== value) {
									if (field.name === 'Brand') {
										if (field.type !== 'AsyncChoices') {
											refactorInfo(field, 'AsyncChoices');
											return field;
										}
										field.value = null;
										const choices = value ? value.value.brands : [];

										field.disabled = !value;
										field.asyncQuery = generateFakeAsyncQuery(
											choices,
										);
									}

									if (field.name === 'goods') {
										field.disabled = true;
										field.value = [];
									}
									return resetGoodFields(field);
								}

								return field;
							});

							company = value;

							return newFields;
						};
						break;

					case 'Award':
						if (f.type !== 'AsyncChoices') {
							refactorInfo(f, 'AsyncChoices');
							break;
						}
						f.asyncQuery = generateAsyncQuery(
							AwardWithDescriptionSelectQuery,
							true,
						);
						f.myToString = (award) =>
							`${award.name} ${award.year} - ${award.edition}`;
						f.afterChange = (field, value, fields) => {
							if (value && award !== value.value) {
								award = value.value;
								return fields.map((field) => {
									if (field.name === 'Company') {
										field.disabled = false;
										field.value = null;
										// companySelect._resetChoices();
									} else if (
										fieldsThatNeedAward.indexOf(field.name) !== -1
									) {
										field.disabled = true;
										field.value = null;
									} else if (
										field.name.indexOf('description') !== -1
									) {
										if (!!award[field.name + 'Label']) {
											if (field.type !== 'RichText') {
												refactorInfo(field, 'RichText');
												return field;
											}
											const splitted = award[
												field.name + 'Label'
											].split('|');
											field.hidden = false;
											field.label = splitted[0].replace('$', '');
											field.helpText = splitted[1];

											const hasMax = award[field.name + 'Max'];
											if (hasMax) {
												field.description.max = hasMax; // mettendo la voce in description mimo la forma in cui il server definisce il massimo per i campi di input graphql
												field.validators = field.validators.concat([
													max(hasMax, field),
												]);
											}

											if (f.label.indexOf('*') !== -1) {
												field.required = true;
												field.validators = field.validators.concat([
													required,
												]);
											}
										} else {
											field.hidden = true;
											if (field.value) {
												field.value = null;
											}
											field.label = field.name;
											field.helpText = '';
										}
									} else if (field.name === 'extraMedia') {
										if (field.type !== 'Media') {
											refactorInfo(field, 'Media');
											return field;
										}
										field.disabled = false;
										const awardHasExtraMediaType =
											award.extraMediaType;

										const extraMediaRequired =
											award.options.indexOf(
												AwardOptionFlag.ExtramediaRequired,
											) !== -1;
										const labels =
											award.extraMediaLabel &&
											award.extraMediaLabel.split('|');
										if (labels) {
											field.label = labels[0];
											field.helpText = labels[1];
										}
										field.mediaType = awardHasExtraMediaType;
										if (extraMediaRequired) {
											toRequired(field, true);
										}
									}

									return resetGoodFields(field);
								});
							} else {
								return fields;
							}
						};
						break;

					default:
						f.disabled = true;
						break;
				}

				return f;
			});

			return data;
		}}
	/>
);
