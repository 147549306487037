import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import typeIntro from '../static/introspection/StoreChain.json';
import { ENTITY } from '../utils/entities';
import { StoreChainListQuery } from 'graphql/query/StoreChainListQuery';
import StoreChainFilters from '../static/introspection/StoreChainFilterInput.json';


const StoreChainList = () => (
	<EntityListFetcher
		query={StoreChainListQuery}
		entityName={ENTITY.STORECHAIN}
		headers={[
			'name',
		]}
		customFields={[
			{
				header: 'Parent',
				field: function (entity) {
					if (entity.Company) {
						return ({
							name: 'Parent',
							label: 'Parent',
							type: 'Entity',
							mapTo: ENTITY.COMPANY,
							value: entity.Company,
						});
					} else if (entity.Foodservice) {
						return ({
							name: 'Parent',
							label: 'Parent',
							type: 'Entity',
							mapTo: ENTITY.FOOD_SERVICE,
							value: entity.Foodservice,
						});
					} else if (entity.Retailer) {
						return ({
							name: 'Parent',
							label: 'Parent',
							type: 'Entity',
							mapTo: ENTITY.RETAILER,
							value: entity.Retailer,
						});
					} else if (entity.Service) {
						return ({
							name: 'Parent',
							label: 'Parent',
							type: 'Entity',
							mapTo: ENTITY.SERVICE,
							value: entity.Service,
						});
					} else {
						return ({
							name: 'Parent',
							label: 'Parent',
							type: 'String',
							value: ''
						});
					}
				}
			},
		]}
		typeIntro={typeIntro}
		filtersIntro={StoreChainFilters}
	/>
);

export { StoreChainList };
