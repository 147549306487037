import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { Award_producer_ListQuery } from '../graphql/query/Award_producer_ListQuery';
import typeIntro from '../static/introspection/Award.json';
import { ENTITY } from '../utils/entities';

export const Award_producer_List = ({ visibility }) => (
	<EntityListFetcher
		query={Award_producer_ListQuery}
		entityName={ENTITY.AWARD}
		headers={['name', 'edition', 'year', 'status']}
		typeIntro={typeIntro}
		additionalVariables={{
			filter: {
				visibility,
			},
		}}
	/>
);
