import React from 'react';
import { Col, Row, Label } from 'reactstrap';
import { FieldComponentRO, TextFieldType } from '../../types/form';

export const TextFieldRO: React.FC<FieldComponentRO<TextFieldType>> = ({
	field,
}) => {
	let val: any = field.value || '';
	if (field.formatText) val = <pre>{val}</pre>;
	else {
		console.log(field.value);
		if (typeof (field.value) !== 'undefined' && field.value.toString().indexOf("http") === 0) {
			val = <a href={field.value} target="_blank" rel="noreferrer">{val}</a>;
		}
	}

	return (
		<Row className={field.hidden ? 'hidden' : ''}>
			<Col sm={4}>
				<Label>{field.label}:</Label>
			</Col>
			<Col sm={8}>
				{val}
			</Col>
		</Row>
	);
};
