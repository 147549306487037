import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { AwardListQuery } from '../graphql/query/AwardListQuery';
import typeIntro from '../static/introspection/Award.json';
import AwardFilters from '../static/introspection/AwardFilterInput.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';
import { AwardVisibility, UserRole } from 'server-types';

export const AwardList = () => (
	<UserData>
		{({ isInternal, userData }) => (
			<EntityListFetcher
				query={AwardListQuery}
				entityName={ENTITY.AWARD}
				headers={['name', 'edition', 'year', 'status']}
				sortables={['name', 'edition', 'year', 'status']}
				typeIntro={typeIntro}
				filtersIntro={AwardFilters}
				filterFields={
					isInternal ? ['name', 'edition', 'year', 'statuses'] : null
				}
				/*
				queryFiltersTransformer={userData.role === UserRole.Producer ? function (filters) {
					filters['notVisibility'] = { AwardVisibility.Sourcing };
					return filters;
				} : function (filters) { return filters; }}
				*/
				extractor={userData.role === UserRole.Producer ? function (data) {
					if (data) {
						const filteredEdges = data.entities.edges.filter((edge) => edge.node.visibility !== AwardVisibility.Sourcing);
						data.entities.edges = filteredEdges;
						return data.entities;
					}
					return null;
				} : function (data) {
					if (data) {
						return data.entities;
					}
					return null;
				}}
			/>
		)}
	</UserData>
);
