import gql from 'graphql-tag';
import {GoodFullFragment} from "../fragments/GoodFullFragment";

export const GoodPatchMutation = gql`
	mutation GoodPatchMutation($id: ID!, $changes: GoodPatchInput!) {
		results: goodPatch(id: $id, changes: $changes) {
			...GoodFullFragment
		}
	}
	${GoodFullFragment}
`;


