/**
 * Questo e' il componente standard per la visualizzazione di dettagli in modali, ovvero dal secondo livello di
 * gerarchia in giu'.
 */

import React from 'react';
import {
	Button,
	Col,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from 'reactstrap';
import { Tabs } from '../components/Tabs';
import { renderFieldRO } from '../utils/form';
import { DeleteEntityModal } from '../components/DeleteEntityModal';
import { EntityDetailViewProps } from '../components/EntityDetailViewSwitch';
import { ViewContext } from '../utils/EntityViewContext';
import { t } from '../utils/labels';
import { IFNTheme, registerClass } from '@food/css-manager';
import { EntityModalStateEnum } from '../components/EntityModalState';
import { UserData } from '@food/auth';
import { Link } from 'react-router-dom';
import { canView } from '../utils/viewRoles';
import { ENTITY } from '../utils/entities';
import { ACTIONS, generateUrl } from '../utils/urls';

const footerClass = registerClass(
	() => `
	display: flex;
	justify-content: space-between;
	align-content: flex-end;
	flex-direction: row-reverse;
`,
);

const seeObjectHistoryClass = registerClass(
	(t: IFNTheme) => `
	color: ${t.colors.alt.plain};
	font-size: ${t.ratios.s}em;
	margin-left: ${t.ratios.s}rem;
	vertical-align: bottom;
`,
);

export const EntityDetailModalView: React.FC<EntityDetailViewProps> = ({
	id,
	entityName,
	permissions,
	title,
	entity,
	pre,
	post,
	data: { fields },
	tabs = ['generic data'],
	cols = tabs.map(() => ['col-sm-12']),
	onDelete,
	hideHistory,
}) => (
	<UserData>
		{(user) => (
			<ViewContext.Consumer>
				{(viewData) => {
					// casi strani, tecnicamente impossibili (a meno di bug), ma basati sull'architettura del codice e quindi
					// non intercettabili da typescript.. mi tocca controllarli manualmente.
					if (viewData === null) {
						throw new Error('ViewData not present');
					}
					if (viewData.type !== 'modal') {
						throw new Error('wrong view type');
					}

					return (
						<DeleteEntityModal
							entityId={entity.id}
							entityName={entityName}
							onDelete={onDelete}
							onFailedDelete={(e) => {
								// TODO Implementare la gestione degli errori nella cancellazione entita' nelle modali
								console.log(e)
							}}
						>
							{(openDeleteModal) => (
								<Modal
									autoFocus={false}
									isOpen={
										viewData.mode === EntityModalStateEnum.Detail
									}
									size={'xl'}
									fade={false}
								>
									<ModalHeader
										toggle={() =>
											viewData.changeState(
												EntityModalStateEnum.Closed,
											)
										}
									>
										{title ||
											t`update` +
												(entityName
													? ' ' + t(entityName).toLowerCase()
													: '')}
										{!hideHistory &&
										canView(user, ENTITY.OBJECT_HISTORY) ? (
											<Link
												className={seeObjectHistoryClass}
												to={
													generateUrl(
														ENTITY.OBJECT_HISTORY,
														ACTIONS.LIST,
													) +
													'?Object=' +
													id
												}
												target={'_blank'}
											>
												{t`View updates`}{' '}
												<i
													className={'fa fa-external-link-square'}
												/>
											</Link>
										) : null}
									</ModalHeader>
									<ModalBody>
										{pre && pre(entity)}

										<Tabs tabs={tabs}>
											{(activeTab, tabIndex) => (
												<Row>
													{cols[tabIndex].map((cClass, i) => (
														<Col
															key={i}
															className={cClass}
															sm={12}
														>
															{fields
																.filter(
																	(f) => f.tab === tabIndex,
																)
																.filter((f) => f.col === i)
																.map((f) => renderFieldRO(f))}
														</Col>
													))}
												</Row>
											)}
										</Tabs>

										{post && post}
									</ModalBody>
									<ModalFooter className={footerClass}>
										<div>
											<Button
												color="secondary"
												onClick={() =>
													viewData.changeState(
														EntityModalStateEnum.Closed,
													)
												}
											>
												<i className="fa fa-times" /> {t`close`}
											</Button>

											{permissions.canEdit && (
												<Button
													color="success"
													className={'margin-left'}
													onClick={() =>
														viewData.changeState(
															EntityModalStateEnum.Edit,
														)
													}
												>
													<i className={'fa fa-edit'} /> {t`edit`}
												</Button>
											)}
										</div>

										{permissions.canDelete && (
											<div>
												<Button
													color="danger"
													onClick={openDeleteModal}
												>
													<i className="fa fa-trash" /> {t`Delete`}
												</Button>
											</div>
										)}
									</ModalFooter>
								</Modal>
							)}
						</DeleteEntityModal>
					);
				}}
			</ViewContext.Consumer>
		)}
	</UserData>
);
