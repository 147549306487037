import gql from 'graphql-tag';
import { NewsletterIssueFullFragment } from '../fragments/NewsletterIssueFullFragment';

export const NewsletterIssueEditQuery = gql`
	query NewsletterIssueEditQuery($id: ID!) {
		entity: node(id: $id) {
			...NewsletterIssueFullFragment
		}
	}
	${NewsletterIssueFullFragment}
`;


