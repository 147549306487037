import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { answerSetListQuery } from '../graphql/query/AnswerSetListQuery';
import typeIntro from '../static/introspection/AnswerSet.json';
import AnswerSetFilters from '../static/introspection/AnswerSetFilterInput.json';
import { ENTITY } from '../utils/entities';
import { t } from '../utils/labels';
import { formatDate } from '../utils/misc';
import { DT_PRECISION } from '../utils/list';
import { UserData } from '@food/auth';

export const AnswerSetList: React.FC = () => (
	<UserData>
		{({ isInternal }) => (
			<EntityListFetcher
				query={answerSetListQuery}
				forcedPermissions={{
					canCreate: false,
					canEdit: false,
					canDelete: false,
				}}
				entityName={ENTITY.ANSWER_SET}
				headers={isInternal ? ['QuestionSet', 'Company', 'sentDate'] : []}
				customFields={
					isInternal
						? []
						: [
								{
									header: 'name',
									field: (entity) => ({
										type: 'Text',
										value:
											t`Questions of user` +
											` ${entity.QuestionSet.User.role}-${
												entity.QuestionSet.User.localId
											}${
												entity.QuestionSet.Retailer
													? '-' +
													  entity.QuestionSet.Retailer.Country
															.code
													: ''
											}`,
									}),
								},
								{
									header: 'answered',
									field: (entity) => ({
										type: 'Boolean',
										value: !!entity.sentDate,
									}),
								},
								{
									header: 'sentDate',
									field: (entity) => ({
										type: 'Text',
										value: formatDate(
											entity.sentDate,
											DT_PRECISION.FULL,
										),
									}),
								},
						  ]
				}
				typeIntro={typeIntro}
				filtersIntro={AnswerSetFilters}
				filterFields={isInternal ? ['isClosed', 'isSent'] : null}
			/>
		)}
	</UserData>
);
