import gql from 'graphql-tag';
import {MagazineFullFragment} from "../fragments/MagazineFullFragment";

export const MagazineCreateMutation = gql`
	mutation MagazineCreateMutation($values: MagazineCreateInput!) {
		results: magazineCreate(values: $values) {
			...MagazineFullFragment
		}
	}
	${MagazineFullFragment}
`;


