import gql from 'graphql-tag';
import { entity2StringFragment } from '../../utils/toString';
import { ENTITY } from '../../utils/entities';
import { MediaBaseFragment } from './MediaBaseFragment';

export const FrequentSearchFullFragment = gql`
	fragment FrequentSearchFullFragment on FrequentSearch {
		id
		localId
		collection
		targetSites
		name
		nameIta
		akas
		status
		highlightReasons
		User {
			id
			name
		}
		Team {
			id
			name
		}
		goodClasses {
			id
			name
		}
		goodFeatureCertifications {
			id
			name
		}
		geoIndications {
			id
			name
		}
		companyCertifications {
			id
			name
		}
		plProducer
		fairs {
			id
			name
		}
		awards {
			id
			name
			year
			edition
		}
		awardFinalPositions
		stateProvinces {
			id
			name
		}
		textSearch
		reveneueRanges
		pricePositionings
		 goods {
			  id
			  name
		 }
		 companies {
			  id
			  name
		 }
		 linkRoles
		 linkUrl
		 logoMedia {
			  ...MediaBaseFragment
		 }
		 relatedFair {
			  ${entity2StringFragment(ENTITY.FAIR)}
		 }
		 description
	}
	
	${MediaBaseFragment}
`;
