import gql from 'graphql-tag';
import {UserFullFragment} from "../fragments/UserFullFragment";

export const UserDetailQuery = gql`
	query UserDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...UserFullFragment
		}
	}
	${UserFullFragment}
`;


