import gql from 'graphql-tag';
import { GoodRecallFullFragment } from 'graphql/fragments/GoodRecallFullFragment';

export const GoodRecallDetailQuery = gql`
	query GoodRecallDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...GoodRecallFullFragment
		}
	}
	${GoodRecallFullFragment}
`;


