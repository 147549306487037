import gql from 'graphql-tag';


export const GoodClassFullFragment = gql`
	fragment GoodClassFullFragment on GoodClass {
		id
		localId
		name
		nameIta
		akas
		level1Code
		level1Desc
		level1DescIta
		level2Code
		level2Desc
		level2DescIta
		level3Code
		level3Desc
		level3DescIta
		level4Code
		level4Desc
		level4DescIta
		shortname
		shortnameIta
		preservation
		trasformationLevel
		editorialSections {
			id
			localId
			name
		}
	}

`;
