import React from 'react';
import { Candidacy_producer_CreateMutation } from '../graphql/mutations/Candidacy_producer_CreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Candidacy.json';
import inputTypeIntro from '../static/introspection/CandidacyCreateInput.json';
import { Candidacy_producer_CreateQuery } from '../graphql/query/Candidacy_producer_CreateQuery';
import { t } from '../utils/labels';
import { Badge } from 'reactstrap';
import { max, required } from '../utils/validation';
import { toRefInput, valToRefInput } from '../utils/dataTrasform';
import {
	generateFakeAsyncQuery,
	goodFormatsWithEAN,
	pluralEntityLabel,
} from '../utils/misc';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';
import {
	generateAsyncQuery,
	generatePaginatedSelectQuery,
	graphqlQuery,
} from '../utils/graphql';
import { AsyncChoiceFieldType, IField } from '../types/form';
import { goodForCandidacySelectQuery } from '../graphql/query/GoodForCandidacySelectQuery';
import { refactorInfo, toHidden } from '../utils/form';
import { AwardOptionFlag, AwardVisibility } from '../server-types';
import { producerAwardCandidacyEditQuery } from '../graphql/query/ProducerAwardCandidacyEditQuery';

export const Candidacy_producer_Create = (props) => {
	const candidacyId = props.id;
	const award = props.originalEntity;
	const { options, extraMediaType, id } = award;
	const awardRequiredEan = options.indexOf(AwardOptionFlag.EanRequired) !== -1;
	const awardHasExtraMediaType = extraMediaType;
	const extraMediaRequired =
		options.indexOf(AwardOptionFlag.ExtramediaRequired) !== -1;
	const hasGallery = options.indexOf(AwardOptionFlag.GalleryEnabled) !== -1;
	const awardRequiresIngredientsMedia =
		options.indexOf(AwardOptionFlag.IngredientsImageRequired) !== -1;
	const awardIsIt = award.langCode === 'it_IT';

	const goodFilter: { eanPresent?: boolean } = {};

	if (awardRequiredEan) {
		goodFilter.eanPresent = true;
	}
	const isAwardCategoryRequired =
		award.visibility === AwardVisibility.Sourcing &&
		award.categories &&
		Array.isArray(award.categories) &&
		award.categories.length > 0;

	return (
		<UserData>
			{({ managedCompany }) => (
				<EntityEditFetcher
					{...props}
					fetcher={
						candidacyId
							? graphqlQuery(producerAwardCandidacyEditQuery, {
								id: candidacyId,
							})
							: graphqlQuery(Candidacy_producer_CreateQuery, { id })
					}
					create={true}
					clone={!!candidacyId}
					modal={true}
					disableCache={true}
					mutation={Candidacy_producer_CreateMutation}
					typeIntro={typeIntro}
					inputTypeIntro={inputTypeIntro}
					entityLabel={props.entityLabel}
					entitiesToFields={(props) => ({
						award: props.award,
						certifications: props.goodFeatureCertifications,
					})}
					additionalQueryVariables={{
						id: props.originalEntity.id,
						goodFilter,
					}}
					entityName={ENTITY.CANDIDACY}
					fields={[
						'Award',
						isAwardCategoryRequired ? 'AwardCategory' : null,
						'Company',
						'Brand',
						'goods',
						'name',
						'featuredImageMedia',
						awardHasExtraMediaType ? 'extraMedia' : null,
						'description1',
						'description2',
						'description3',
						'description4',
						'description5',
						'description6',
						'description7',
						'description8',
						'description9',
						'description10',
						'launchDate',
						hasGallery ? 'additionalImagesMedia' : null,
					]}
					transformer={(data, entity, listsByType) => {
						// le descrizioni possono essere nascoste a seconda del valore dell'Award a cui
						// si riferisce la Candidacy. Anche la loro lunghezza e' definita a livello
						// di campi dell'Award

						// i campi deducibili dipendono dalla lingua del premio
						const deducibleFields = {
							featuredImageMedia:
								award.langCode === 'it_IT'
									? 'featuredImageItaMedia'
									: 'featuredImageMedia',
							name: award.langCode === 'it_IT' ? 'nameIta' : 'name',
							description1:
								award.langCode === 'it_IT'
									? 'descriptionLongIta'
									: 'descriptionLong',
						};

						const maxGoodsPerCandidacy =
							(award.invitedCompanies &&
								award.invitedCompanies.edges[0] &&
								award.invitedCompanies.edges[0].node
									.maxGoodsPerCandidacy) ||
							award.maxGoodsPerCandidacy;

						const isSingle = maxGoodsPerCandidacy === 1;

						data.fields = data.fields
							.filter(
								(f) =>
									f.name.indexOf('description') === -1 ||
									!!award[f.name + 'Label'],
							)
							.map((f) => {
								switch (f.name) {
									case 'Company':
										// non e' possibile inserire una candidacy per una compagnia diversa da
										// quella attualmente attiva per l'utente
										return toHidden(
											valToRefInput(managedCompany.Company.id),
										)(f);

									case 'Award':
										// il premio viene settato di default in quanto Candidacy e' una entita'
										// "secondaria" rispetto ad Award
										return toHidden(toRefInput(award))(f);

									case 'Brand':
										// f = AsyncToSyncField(f, brands);
										(f as AsyncChoiceFieldType).asyncQuery = generateAsyncQuery(
											generatePaginatedSelectQuery(
												ENTITY.BRAND,
												false,
											),
											true,
											(value) => ({
												filter: {
													name: value,
												},
											}),
											(e) => e.choices,
											{ fetchPolicy: 'network-only' },
										);
										f.afterChange = function (field, value, fields) {
											if (!value) {
												// resetto il campo dei prodotti visto che non ho scelto un brand
												fields.forEach((f: IField) => {
													if (f.name === 'goods') {
														f.value = null;
														f.disabled = true;
													} else if (
														Object.keys(deducibleFields).indexOf(
															f.name,
														) !== -1
													) {
														if (f.name === 'name') {
															f.disabled = true;
														}
														f.value = null;
													}
												});
											} else {
												fields.forEach((f: IField) => {
													if (f.name === 'goods') {
														const brand = value.value;
														// se ho selezionato il brand carico i prodotti all'interno della select apposita
														(f as AsyncChoiceFieldType).asyncQuery = generateAsyncQuery(
															goodForCandidacySelectQuery,
															true,
															(value) => ({
																filter: {
																	name: value,
																	Brand: toRefInput(brand),
																	ingredientsMediaPresent:
																		awardRequiresIngredientsMedia &&
																		!awardIsIt,
																	ingredientsItaMediaPresent:
																		awardRequiresIngredientsMedia &&
																		awardIsIt,
																	eanPresent: awardRequiredEan
																		? true
																		: undefined,
																},
															}),
															(e) => e.choices,
															{ fetchPolicy: 'network-only' },
														);
														f.value = [];
														f.disabled = false;
													} else if (
														Object.keys(deducibleFields).indexOf(
															f.name,
														) !== -1
													) {
														if (f.name === 'name') {
															f.disabled = true;
														}
														f.value = null;
													}
												});
											}

											return fields;
										};
										break;

									case 'description1':
									case 'description2':
									case 'description3':
									case 'description4':
									case 'description5':
									case 'description6':
									case 'description7':
									case 'description8':
									case 'description9':
									case 'description10':
										if (f.type !== 'RichText') {
											refactorInfo(f, 'RichText');
											break;
										}

										const labelsPart = award[f.name + 'Label'].split(
											'|',
										);
										f.label = labelsPart[0].replace('$', '');
										f.helpText = labelsPart[1] || '';

										const hasMax = award[f.name + 'Max'];
										if (hasMax) {
											f.description.max = hasMax; // mettendo la voce in description mimo la forma in cui il server definisce il massimo per i campi di input graphql
											f.validators = f.validators.concat([
												max(hasMax, f),
											]);
										}

										if (f.label.indexOf('*') !== -1) {
											f.required = true;
											f.description.required = true;
											f.validators = f.validators.concat([required]);
										}

										if (f.name === 'description1' && !isSingle) {
											f.disabled = true;
										}
										/*
										if (f.name === 'description1' && isSingle) {
											f.hidden = true;
										}
										*/
										break;

									case 'goods':
										if (f.type !== 'AsyncChoices') {
											// TODO
											break;
										}

										f.asyncQuery = generateFakeAsyncQuery([]);
										if (isSingle) {
											f.label = t(ENTITY.GOOD) + '*';
											f.helpText = t(
												'select single product for ' +
												award.visibility,
											);

											/*
											nel caso della clonazione devo manualmente trasformare il valore preso dal server
											normalmente non sarebbe necessario, ma a volte tratto il campo come se fosse singolo
											partendo dalle impostazioni del premio, quindi c'e' un "falso singolo" che il parsing
											dell'introspezione della mutazione non riesce a capire - il quale porta a bug in futuro
											*/
											if (f.value) {
												f.value = f.value[0];
											}
										}

										f.additionalHelpComponent = (
											<>
												{awardRequiredEan && (
													<div>
														<Badge color="warning">{t`warning`}</Badge>{' '}
														{t`search only ean`}
													</div>
												)}
												<div className="usability-note">
													{t`max length sync choices`}:{' '}
													{maxGoodsPerCandidacy}
												</div>
											</>
										);

										f.afterChange = function (field, value, fields) {
											let goodsNumber: number = 0;
											if (isSingle && value) {
												goodsNumber = 1;
											} else if (!isSingle && Array.isArray(value)) {
												goodsNumber = value.length;
											}

											if (goodsNumber === 1) {
												const good = (isSingle ? value : value[0])
													.value;
												return fields.map((f) => {
													if (
														Object.keys(deducibleFields).indexOf(
															f.name,
														) !== -1
													) {
														f.disabled = f.name === 'name';
														f.value =
															good[deducibleFields[f.name]];

														if (f.type === 'RichText') {
															f.forceUpdateKey = new Date().getTime().toString();
														}
													}
													return f;
												});

											} else if (goodsNumber > 1) {
												return fields.map((f) => {
													if (
														Object.keys(deducibleFields).indexOf(
															f.name,
														) !== -1
													) {
														f.disabled = false;
														f.value = null;
													}
													return f;
												});
											} else {
												// condizione implicita: goodsNumber === 0
												return fields.map((f) => {
													if (
														Object.keys(deducibleFields).indexOf(
															f.name,
														) !== -1
													) {
														f.disabled = true;
														f.value = null;
													}
													return f;
												});
											}
										};

										if (isSingle) {
											f.single = true;
											f.beforeSaveTransformer = (a) => {
												return [valToRefInput(a.value.id)];
											};
										}

										if (!f.single) {
											f.label = t(pluralEntityLabel(ENTITY.GOOD));
										}

										const lens =
											award.langCode === 'it_IT'
												? 'nameIta'
												: 'name';

										f.myToString = (e) =>
											`${e[lens]}${awardRequiredEan
												? ' (' +
												goodFormatsWithEAN(e).length +
												' EAN)'
												: ''
											}`;

										f.disabled = true;
										break;

									case 'featuredImageMedia':
										f.disabled = true;
										break;

									case 'AwardCategory':
										f.required = isAwardCategoryRequired;
										f.description.required = isAwardCategoryRequired;
										if (isAwardCategoryRequired) {
											if (!f.label.endsWith('*'))
												f.label = f.label + '*';
											f.validators = f.validators.concat([required]);
										}
										if (f.type !== 'AsyncChoices') {
											refactorInfo(f, 'AsyncChoices');
											break;
										}
										f.asyncQuery = generateFakeAsyncQuery(
											award.categories.filter((c) => !c.special),
										);
										break;

									case 'name':
										// di default non ho selezionato nessun prodotto, quindi questi campi sono disabilitati
										f.disabled = true;
										if (isSingle) {
											f.helpText = t(
												'name will be derived from good name ' +
												award.visibility,
											);
										}
										break;

									case 'goodFeatureCertifications':
										if (f.type !== 'AsyncChoices') break;
										f.asyncQuery = generateFakeAsyncQuery(
											listsByType.certifications.map((c) => ({
												label: c.name,
												value: c.id,
											})),
										);
										break;

									case 'launchDate':
										if (
											props.originalEntity.options.indexOf(
												AwardOptionFlag.LaunchdateRequired,
											) !== -1
										) {
											f.validators = f.validators.concat([required]);
											f.required = true;
											f.label += '*';
										}
										break;

									case 'extraMedia':
										if (f.type !== 'Media') break;
										const labels =
											award.extraMediaLabel &&
											award.extraMediaLabel.split('|');
										if (labels) {
											f.label = labels[0];
											f.helpText = labels[1];
										}
										f.mediaType = awardHasExtraMediaType;
										if (extraMediaRequired) {
											f.validators = f.validators.concat([required]);
											f.required = true;
											f.label += '*';
										}
										break;

									default:
										break;
								}
								return f;
							});
						return data;
					}}
				/>
			)}
		</UserData>
	);
};
