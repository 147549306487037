import gql from 'graphql-tag';
import {TeamFullFragment} from "../fragments/TeamFullFragment";

export const TeamEditQuery = gql`
	query TeamEditQuery($id: ID!) {
		entity: node (id: $id) {
				...TeamFullFragment
		  }
	}
	${TeamFullFragment}
`;


