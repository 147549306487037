import gql from 'graphql-tag';
import { CouponFullFragment } from '../fragments/CouponFullFragment';

export const CouponCreateMutation = gql`
	mutation CouponCreateMutation($values: CouponCreateInput!) {
		results: couponCreate(values: $values) {
			...CouponFullFragment
		}
	}
	${CouponFullFragment}
`;
