import gql from 'graphql-tag';

export const EmailDomainListQuery = gql`
	query EmailDomainListQuery(
		$first: Int,
		$cursor: String,
		$orderBy: EmailDomainOrderBy,
		$orderDesc: Boolean,
		$filter: EmailDomainFilterInput
	) {
		entities: emailDomains(first: $first, after: $cursor, orderBy: $orderBy, orderDesc: $orderDesc, filter: $filter) {
			edges {
				node {
					id
					localId
					emailDomain
					accountType
					action
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}

	}
`;


