import gql from 'graphql-tag';
import { ENTITY } from './entities';
import { pluralName } from './urls';
import { Dict, nodes } from './misc';
import { client } from './client';
import { MediaFullFragment } from '../graphql/fragments/MediaFullFragment';
import { entity2StringFragment } from './toString';
import { QueryOptions } from 'apollo-client';

const CURSOR_PREFIX = 'arrayconnection:';

const pageToEntities = (data: { edges: Array<{ node: any }> } | null): any[] =>
	data && 'edges' in data ? nodes(data) : [];

const offsetToCursor = (offset: number): string =>
	btoa(CURSOR_PREFIX + offset.toString());

function generateDefaultFragment(entityName) {
	return gql`fragment Fragment on ${entityName} {
       name
   }
	`;
}

function generateDefaultListQuery(
	entityName: string,
	fieldName: string,
	paginated: boolean = false,
) {
	if (!paginated) {
		return (fragment) => gql`
          query ${entityName}${fieldName}DefaultListQuery (
			 $id: ID!
			 ) {
          node(id: $id) {
              id
              ... on ${entityName} {
                  ${fieldName}  {
                  id
                  ...Fragment
              }
          }
          }
          }
          ${fragment}
		`;
	} else {
		return (fragment) => gql`
          query ${entityName}${fieldName}DefaultListQuery (
			 $id: ID!,
          $first: Int,
          $cursor: String,
			 ) {
          node(id: $id) {
              id
              ... on ${entityName} {
                  ${fieldName} (first: $first, after: $cursor) {
                  edges {
                      node {
                          id
                          ...Fragment
                      }
                  }
                  total
                  pageInfo {
                      hasNextPage
                      hasPreviousPage
                  }
              }
          }
          }
          }
          ${fragment}
		`;
	}
}

const generateCumulativeFilterQuery = (
	filters: ReadonlyArray<{ id: string; name: string; entityType: ENTITY }>,
) => {
	const str =
		'query cumulativeFilterQuery {\n' +
		filters
			.map(
				(f, i) =>
					`${f.entityType}${i}: node(id: "${f.id}") {
				id
				... on ${f.entityType} {
					localId
					${entity2StringFragment(f.entityType)}
				}
			}`,
			)
			.join('\n') +
		'\n}';

	return gql(str);
};

export type AsyncQuery = (
	value: string,
) => Promise<{ entities: any[]; remaining: number }>;

const generateAsyncQuery = (
	query: any,
	paginated = false,
	customVariables: Function = (value) => ({ filter: { name: value } }),
	dataExtractor: Function = (e) => e.choices,
	options?: Partial<Omit<QueryOptions, 'query' | 'variables'>>,
): AsyncQuery => async (value: string) => {
	const res = await client.query({
		query,
		variables: customVariables(value),
		...options,
	});

	const choices = dataExtractor(res.data);
	return {
		entities: paginated ? nodes(choices) : choices,
		remaining: paginated ? Number(choices.total) - choices.edges.length : 0,
	};
};

const generatePaginatedSelectQuery = (
	entityName: ENTITY,
	order: boolean = true,
	filterName = (entityName: ENTITY) => `${entityName}FilterInput`,
) => gql`
    query (
    $filter: ${filterName(entityName)}) {
        choices: ${pluralName(entityName)} (filter: $filter${order ? ', orderBy: name, orderDesc:false' : ''
	}) {
        edges {
            node {
                id
                localId
					... on ${entityName} {
	                  ${entity2StringFragment(entityName)}
					}
            }
        }
        total
    }
 }
`;

/**
 *
 * @param query
 * @param variables
 * @param options
 */
const graphqlQuery = (
	query: any,
	variables: Dict = {},
	options?: QueryOptions,
) => async () =>
		((await client.query({ query, variables, ...options })) as any).data;

/**
 *
 */
const mediaQuery = gql`
	query MediaQuery($id: ID!) {
		node(id: $id) {
			id
			... on Media {
				...MediaFullFragment
			}
		}
	}
	${MediaFullFragment}
`;

const getDefaultNodeData = (entityType: ENTITY, id: string) => {
	const query = gql`query Default${entityType}Query($id: ID!) {
		node(id: $id) {
			id
			... on ${entityType} {
				${entity2StringFragment(entityType)}
   		}
		}
	}`;
	return client.query({ query, variables: { id } });
};

export {
	// graphQLFetcher,
	pageToEntities,
	offsetToCursor,
	generateCumulativeFilterQuery,
	generateAsyncQuery,
	generateDefaultListQuery,
	generateDefaultFragment,
	generatePaginatedSelectQuery,
	graphqlQuery,
	mediaQuery,
	getDefaultNodeData,
};
