import gql from 'graphql-tag';
import { GoodRecallFullFragment } from 'graphql/fragments/GoodRecallFullFragment';

export const GoodRecallEditQuery = gql`
	query GoodRecallEditQuery($id: ID!) {
		editorialSectionTree(EditorialBrand: GOODS4HUB) {
            id
            name
            Parent {
                id
            }
            goodClasses {
                id
                shortname
                shortnameIta
                localId
                name
                nameIta
            }
        }
		entity: node (id: $id) {
				...GoodRecallFullFragment
		  }
	}
	${GoodRecallFullFragment}
`;


