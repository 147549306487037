import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { RetailerListQuery } from '../graphql/query/RetailerListQuery';
import typeIntro from '../static/introspection/Retailer.json';
import RetailerFilters from '../static/introspection/RetailerFilterInput.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';

export const RetailerList = () => (
	<UserData>
		{({ isInternal }) => (
			<EntityListFetcher
				query={RetailerListQuery}
				entityName={ENTITY.RETAILER}
				headers={['name', 'type']}
				sortables={['name', 'type']}
				typeIntro={typeIntro}
				filtersIntro={RetailerFilters}
				filterFields={
					isInternal
						? ['name', 'producerChangedAfter', 'types', 'Country']
						: null
				}
			/>
		)}
	</UserData>
);
