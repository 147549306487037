import React from 'react';
import { ToSPatchMutation } from '../graphql/mutations/ToSPatchMutation';
import { ToSEditQuery } from '../graphql/query/ToSEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/ToS.json';
import inputTypeIntro from '../static/introspection/ToSPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const ToSEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(ToSEditQuery, { id })}
		mutation={ToSPatchMutation}
		entityName={ENTITY.TERMS_OF_SERVICE}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={[
			'name',
			'startDate',
			'description',
			'descriptionIta',
			'descriptionExtra',
			'descriptionExtraIta',
			'descriptionChanges',
			'descriptionChangesIta',
		]}
	/>
);
