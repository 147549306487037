import gql from 'graphql-tag';

export const CompanyYearFullFragment = gql`
	fragment CompanyYearFullFragment on CompanyYear {
		id
		localId
		Company {
			id
			name
		}
		year
		revenue
		ebitda
		gain
		numEmployees
		mol
		exportRevenue
	}
`;
