import React from 'react';
import { CompanyCertificationPatchMutation } from '../graphql/mutations/CompanyCertificationPatchMutation';
import { CompanyCertificationEditQuery } from '../graphql/query/CompanyCertificationEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/CompanyCertification.json';
import inputTypeIntro from '../static/introspection/CompanyCertificationPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const CompanyCertificationEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(CompanyCertificationEditQuery, { id })}
		mutation={CompanyCertificationPatchMutation}
		entityName={ENTITY.COMPANY_CERTIFICATION}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={['name', 'akas']}
	/>
);
