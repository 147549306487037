import gql from 'graphql-tag';

export const AnswerSetFullFragment = gql`
	fragment AnswerSetFullFragment on AnswerSet {
		id
		localId
		sentDate
		QuestionSet {
			id
			deadlineDate
			sentDate
			question1
			User {
				id
				name
			}
		}
		Company {
			id
			name
		}
		sentDate
		answer1
		goods {
			id
			name
		}
	}
`;
