import gql from 'graphql-tag';

export const ChecklistPlatformQuery = gql`
	query ChecklistPlatformQuery($companyId: ID!) {
		company: node(id: $companyId) {
			... on Company {
				id
				logoMedia {
					id
				}
				brands {
					id
				}
				description
				descriptionIta
				descriptionLong
				descriptionLongIta
				logoPrintMedia {
					id
				}
				companyCertifications {
					id
				}
				goods {
					id
				}
				highlightedGoods {
					id
					targetSite
				}
			}
		}
	}
`;
