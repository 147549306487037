import React from 'react';
import { AwardInvitedUserPatchMutation } from '../graphql/mutations/AwardInvitedUserPatchMutation';
import { AwardInvitedUserEditQuery } from '../graphql/query/AwardInvitedUserEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/AwardInvitedUser.json';
import inputTypeIntro from '../static/introspection/AwardInvitedUserPatchInput.json';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';
import { EntityEditModal } from '../types/entities';
import { Award, AwardInvitedUser } from '../server-types';

export const AwardInvitedUserEdit: React.FC<EntityEditModal<
	AwardInvitedUser,
	Award
>> = ({ id, onSubmit }) => (
	<EntityEditFetcher
		onSubmit={onSubmit}
		modal={true}
		fetcher={graphqlQuery(AwardInvitedUserEditQuery, { id })}
		mutation={AwardInvitedUserPatchMutation}
		entityName={ENTITY.AWARD_INVITED_USER}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={['User', 'roles']}
	/>
);
