import React from 'react';
import { t } from '../utils/labels';
import { UserDetailQuery } from '../graphql/query/UserDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/User.json';
import ChangePasswordModal from './ChangePasswordModal';
import UserStatusIntrospection from '../static/introspection/UserStatus.json';
import { ManagedByUserDetail } from './ManagedByUserDetail';
import { ManagedByUserEdit } from './ManagedByUserEdit';
import { ManagedByUserCreate } from './ManagedByUserCreate';
import { StatusSetter } from '../components/StatusSetter';
import { setUserStatusMutation } from '../graphql/mutations/setUserStatusMutation';
import { ENTITY } from '../utils/entities';
import gql from 'graphql-tag';
import { transformerHandler } from '../utils/misc';
import { fastTransformer, refactorInfo } from '../utils/form';
import { UserData } from '@food/auth';

const statuses = UserStatusIntrospection.enumValues.map((o) => o.name);

export class UserDetail extends React.Component<any, {}> {
	private changePasswordModal: any;

	render() {
		const { id } = this.props;
		return (
			<div>
				<ChangePasswordModal
					userId={id}
					customRef={(modal) => (this.changePasswordModal = modal)}
				/>
				<UserData>
					{({ isInternal }) => (
						<EntityDetailFetcher
							query={UserDetailQuery}
							customActionComponents={(entity) => [
								<StatusSetter
									key={1}
									statuses={statuses}
									entity={entity}
									entityName={ENTITY.USER}
									statusFieldName="status"
									mutation={setUserStatusMutation}
									entityIntrospection={typeIntro}
								/>,
							]}
							entityName={ENTITY.USER}
							id={id}
							typeIntro={typeIntro}
							clonable={false}
							fields={[
								isInternal ? 'sfId' : null,
								'firstName',
								'lastName',
								'email',
								'socialType',
								'name',
								'role',
								'status',
								'Team',
								'workEmail',
								'langCode',
								'internalCapabilities',
								'managedByUsers',
								'approvedToS',
							]}
							transformer={transformerHandler(
								fastTransformer({
									managedByUsers: (f) => {
										if (f.type !== 'EntityList') {
											refactorInfo(f, 'EntityList');
											return f;
										}
										f.props.headers = ['capabilities'];
										f.props.customFields = [
											{
												header: 'Entity Type',
												field: (entity) => {
													const entityType =
														(entity[ENTITY.COMPANY] &&
															ENTITY.COMPANY) ||
														(entity[ENTITY.SERVICE] &&
															ENTITY.SERVICE) ||
														(entity[ENTITY.COMPANY_GROUP] &&
															ENTITY.COMPANY_GROUP) ||
														(entity[ENTITY.RETAILER] &&
															ENTITY.RETAILER) ||
														(entity[ENTITY.FOOD_SERVICE] &&
															ENTITY.FOOD_SERVICE) ||
														'UNKOWN';
													return {
														type: 'Text',
														value: t(entityType),
													};
												},
											},
											{
												header: 'Entity',
												field: (entity) => {
													const entityType =
														(entity[ENTITY.COMPANY] &&
															ENTITY.COMPANY) ||
														(entity[ENTITY.SERVICE] &&
															ENTITY.SERVICE) ||
														(entity[ENTITY.COMPANY_GROUP] &&
															ENTITY.COMPANY_GROUP) ||
														(entity[ENTITY.RETAILER] &&
															ENTITY.RETAILER) ||
														(entity[ENTITY.FOOD_SERVICE] &&
															ENTITY.FOOD_SERVICE) ||
														'UNKOWN';
													return {
														type: 'Entity',
														mapTo: entityType,
														value: entity[entityType],
													};
												},
											},
										];
										f.props.fragment = gql`
											fragment Fragment on ManagedByUser {
												Company {
													id
													name
												}
												Service {
													id
													name
												}
												CompanyGroup {
													id
													name
												}
												Retailer {
													id
													name
												}
												FoodService {
													id
													name
												}
												capabilities
											}
										`;
										f.editComponent = ManagedByUserEdit;
										f.detailComponent = ManagedByUserDetail;
										f.addComponent = ManagedByUserCreate;
										f.tab = 1;
										return f;
									},
								}),
							)}
							tabs={[
								t('generic data'),
								t(
									[ENTITY.USER, ENTITY.MANAGED_BY_USER, 'label'].join(
										'/',
									),
								),
							]}
							cols={[['col-sm-7', 'col-sm-5'], ['col-sm-12']]}
							customActions={[
								{
									icon: 'fa-user',
									label: t`change password`,
									color: 'info',
									handler: () => this.changePasswordModal._toggle(),
								},
							]}
						/>
					)}
				</UserData>
			</div>
		);
	}
}
