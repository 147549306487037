import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { CouponListQuery } from '../graphql/query/CouponListQuery';
import typeIntro from '../static/introspection/Coupon.json';
import CouponFilters from '../static/introspection/CouponFilterInput.json';
import { ENTITY } from '../utils/entities';

export const CouponList = () => (
	<EntityListFetcher
		query={CouponListQuery}
		entityName={ENTITY.COUPON}
		headers={['name', 'startDate', 'expirationDate']}
		typeIntro={typeIntro}
		filtersIntro={CouponFilters}
		filterFields={['name', 'code', 'isActive']}
	/>
);
