import gql from 'graphql-tag';
import { MediaBaseFragment } from '../fragments/MediaBaseFragment';

export const AwardCategoryPatchMutation = gql`
	mutation AwardCategoryPatchMutation(
		$id: ID!
		$changes: AwardCategoryPatchInput!
	) {
		results: awardCategoryPatch(id: $id, changes: $changes) {
			id
			localId
			name
			slug
			description
			Media {
				...MediaBaseFragment
			}
			color
			giftUrl
		}
	}
	${MediaBaseFragment}
`;


