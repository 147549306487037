import gql from 'graphql-tag';
import {ManagedByUserFullFragment} from "../fragments/ManagedByUserFullFragment";

export const ManagedByUserPatchMutation = gql`
	mutation ManagedByUserPatchMutation($id: ID!, $changes: ManagedByUserPatchInput!) {
		results: managedByUserPatch(id: $id, changes: $changes) {
			...ManagedByUserFullFragment
		}
	}
	${ManagedByUserFullFragment}
`;


