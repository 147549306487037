import CONFIG from '../static/config';
import { retryFetch } from './retryFetch';
import { getValidToken, setAuth } from '@food/auth';

const switchManaged = async (companyId: string): Promise<void> => {
	const switchResult = await retryFetch(CONFIG.API_ENDPOINT.SWITCH, {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			authorization: getValidToken(),
		},
		method: 'POST',
		body: JSON.stringify({
			companyGlobalId: companyId,
		}),
	});
	const res = await switchResult.json();

	if (switchResult.ok) {
		setAuth(res, 'TRADITIONAL', true);
	} else {
		console.error(res);
	}
};

export { switchManaged };
