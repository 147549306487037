import gql from 'graphql-tag';
import {CompanyYearFullFragment} from "../fragments/CompanyYearFullFragment";

export const CompanyYearDetailQuery = gql`
	query CompanyYearDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...CompanyYearFullFragment
		}
	}
	${CompanyYearFullFragment}
`;


