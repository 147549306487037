import gql from 'graphql-tag';
import {AwardFullFragment} from "../fragments/AwardFullFragment";

export const AwardCreateMutation = gql`
	mutation AwardCreateMutation($values: AwardCreateInput!) {
		results: awardCreate(values: $values) {
			...AwardFullFragment
		}
	}
	${AwardFullFragment}
`;


