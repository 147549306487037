import React from 'react';
import { MagazineCollectionDetailQuery } from '../graphql/query/MagazineCollectionDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/MagazineCollection.json';
import { ENTITY } from '../utils/entities';

const MagazineCollectionDetail = ({ id }) => (
	<EntityDetailFetcher
		query={MagazineCollectionDetailQuery}
		entityName={ENTITY.MAGAZINE_COLLECTION}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		fields={[
			'BrandSf',
			'name',
			'featuredImageMedia',
			'description',
			'startYear',
			'highlightDays',
			'visibilityType',
			'visibilityArgs',
			'priority',
			'notInLatestIssues',
			'hasCategory',
		]}
	/>
);

export { MagazineCollectionDetail };
