import gql from 'graphql-tag';
import { MediaBaseFragment } from './MediaBaseFragment';

export const MagazineCollectionFullFragment = gql`
	fragment MagazineCollectionFullFragment on MagazineCollection {
		id
		localId
		BrandSf
		name
		featuredImageMedia {
			...MediaBaseFragment
		}
		description
		startYear
		highlightDays
		visibilityType
		visibilityArgs
		priority
		notInLatestIssues
		hasCategory
	}
	${MediaBaseFragment}
`;
