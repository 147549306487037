import gql from 'graphql-tag';
import { MediaBaseFragment } from './MediaBaseFragment';

export const Award_producer_FullFragment = gql`
    fragment Award_producer_FullFragment on Award {
        id
        localId
        name
        edition
        year
        targetSites
        langCode
        logoMedia {
            ...MediaBaseFragment
        }
        status
        maxPerCompanyCandidacies
        maxGoodsPerCandidacy
        invitedCompanies {
				edges {
					 node {
						  id
                    maxPerCompanyCandidacies
						  maxGoodsPerCandidacy
                    formValues
                }
				}
		  }
        candidaciesCloseAt
        votesCloseAt
        ratingOverallLabel
        rating1Label
        rating2Label
        rating3Label
        rating4Label
        rating5Label
        comment1Label
        comment1Max
        comment2Label
        comment2Max
        comment3Label
        comment3Max
        website
        description1Label
        description1Max
        description2Label
        description2Max
        description3Label
        description3Max
        description4Label
        description4Max
        description5Label
        description5Max
        description6Label
        description6Max
        description7Label
        description7Max
        description8Label
        description8Max
        description9Label
        description9Max
        description10Label
        description10Max
        descriptionForProducer
        descriptionProducerHighlights
        visibility
        options
        extraMediaType
        categories {
            id
            name
        }
        candidacies {
            id
            name
            goods {
                id
                name
                nameIta
                formats {
                    id
                    ean
                }
            }
        }
        vademecumCompany
        vademecumGoods
        vademecumCandidacies
        vademecumInvited
        supportEmail
        regulationsMedia {
            ...MediaBaseFragment
        }
        contractMedia {
            ...MediaBaseFragment
        }
    }
    ${MediaBaseFragment}
`;
