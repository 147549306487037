import React from 'react';
import { ColorFieldType, FieldComponent } from '../../types/form';
import { useContext } from 'react';
import { FormContext } from '../../utils/context/form';
import { getLocalError } from '../../utils/formErrors';
import { CirclePicker } from 'react-color';
import {
	Badge,
	Col,
	FormFeedback,
	FormGroup,
	FormText,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
} from 'reactstrap';
import { t } from '../../utils/labels';
import { IFNTheme, registerClass } from '@food/css-manager';

// il pallino con in colore bianco non si vede su hub. Per fortuna il campo "title" del pallino viene
// impostato con il colore, quindi posso creare una classe css ad-hoc per risolvere il problema
const fixWhiteCirlce = registerClass(
	(t: IFNTheme) => `
	div[title="#ffffff"] {
		border: 1px solid ${t.colors.ink};
	}
`,
);

export const ColorField: React.FC<FieldComponent<ColorFieldType>> = ({
	changeHandler,
	field,
	path,
}) => {
	const { hidden, label, description, helpText, value } = field;
	const formContext = useContext(FormContext);
	const error = getLocalError(path, formContext?.errors || {});

	return (
		<FormGroup
			row
			color={error ? 'danger' : ''}
			className={hidden ? 'hidden' : ''}
		>
			<Label sm={3}>{label}: </Label>

			<Col sm={{ size: 9 }} className={fixWhiteCirlce}>
				<InputGroup className={'mb-3'}>
					<InputGroupAddon addonType="prepend">
						<InputGroupText
							style={{ backgroundColor: value || '', minWidth: '4rem' }}
						/>
					</InputGroupAddon>
					<Input
						value={value || ''}
						onChange={(e) => changeHandler(e.target.value, path)}
						placeholder={t`color field placeholder`}
					/>
				</InputGroup>
				<CirclePicker
					width={'auto'}
					onChange={({ hex }) => changeHandler(hex, path)}
					color={value || ''}
					colors={[
						'#000000',
						'#ffffff',
						'#e20613',
						'#009540',
						'#89a5c0',
						'#ffcb47',
						'#46bdad',
						'#e62261',
					]}
				/>
				{error && (
					<section>
						<FormFeedback>{t(error.message)}</FormFeedback>
					</section>
				)}
				<FormText color="muted">
					{description.readonly && (
						<div>
							<Badge color="warning">{t`warning`}</Badge>{' '}
							{t`readonly field description`}
						</div>
					)}
					{helpText}
				</FormText>
			</Col>
		</FormGroup>
	);
};
