import gql from 'graphql-tag';


export const BranchFullFragment = gql`
	fragment BranchFullFragment on Branch {
		id
		localId
		Company {
			id
			name
		}
		name
		type
		vatNumber
		website
		Country {
			id
			name
		}
		StateProvince {
			id
			name
		}
		postalCode
		city
		address1
		address2
		lat
		lng
	}

`;
