import gql from 'graphql-tag';
import { BranchFullFragment } from '../fragments/BranchFullFragment';

export const BranchEditQuery = gql`
	query BranchEditQuery($id: ID!) {
		entity: node(id: $id) {
			...BranchFullFragment
		}
	}
	${BranchFullFragment}
`;


