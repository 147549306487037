import gql from 'graphql-tag';
import {ServiceFullFragment} from "../fragments/ServiceFullFragment";

export const ServiceCreateMutation = gql`
	mutation ServiceCreateMutation($values: ServiceCreateInput!) {
		results: serviceCreate(values: $values) {
			...ServiceFullFragment
		}
	}
	${ServiceFullFragment}
`;


