import gql from 'graphql-tag';
import {CompanyGroupFullFragment} from "../fragments/CompanyGroupFullFragment";

export const CompanyGroupDetailQuery = gql`
	query CompanyGroupDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...CompanyGroupFullFragment
		}
	}
	${CompanyGroupFullFragment}
`;


