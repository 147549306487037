import gql from 'graphql-tag';
import {FrequentSearchFullFragment} from "../fragments/FrequentSearchFullFragment";

export const FrequentSearchDetailQuery = gql`
	query FrequentSearchDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...FrequentSearchFullFragment
		}
	}
	${FrequentSearchFullFragment}
`;


