import gql from 'graphql-tag';
import {ToSFullFragment} from "../fragments/ToSFullFragment";

export const ToSDetailQuery = gql`
	query ToSDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...ToSFullFragment
		}
	}
	${ToSFullFragment}
`;


