import gql from 'graphql-tag';
import { MediaBaseFragment } from './MediaBaseFragment';

export const CompanyFullFragment = gql`
	fragment CompanyFullFragment on Company {
		id
		sfId
		localId
		name
		billingName
		website
		companyEmail
		description
		descriptionIta
		descriptionLong
		descriptionLongIta
		yearEstablished
		industry
		ownership
		logoPrintMedia {
			...MediaBaseFragment
		}
		AtecoCode {
			id
			code
			description
		}
		pecEmail
		plProducer
		vatNumber
		vatOpeningDate
		brands {
			id
			name
			website
			pricePositioning
		}
		goodFeatureCertifications {
			id
			name
		}
		companyCertifications {
			id
			name
			akas
		}
		logoMedia {
			...MediaBaseFragment
		}
		companyYears {
			id
			ebitda
			gain
			numEmployees
			revenue
			year
			exportRevenue
		}
		fairParticipations {
			id
			Fair {
				id
				name
				edition
			}
			note
			pavilion
			stand
		}
		companyLevels {
			id
			expirationDate
			level
		}
		highlightedGoods {
			id
			Good {
				id
				name
				nameIta
			}
		}
		branches {
			id
			name
			address1
			StateProvince {
				id
				name
			}
		}
		billingBranch {
			id
			name
			address1
			StateProvince {
				id
				name
			}
		}
		headquarterBranch {
			id
			name
			address1
			StateProvince {
				id
				name
			}
		}
		distributionChannels {
			id
		}
		additionalImagesMedia {
			...MediaBaseFragment
		}
	}
	${MediaBaseFragment}
`;
