import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { EuCategoryListQuery } from '../graphql/query/EuCategoryListQuery';
import typeIntro from '../static/introspection/EuCategory.json';
import EuCategoryFilters from '../static/introspection/SearchByNameInput.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';

export const EuCategoryList = () => (
	<UserData>
		{({ isInternal }) => (
			<EntityListFetcher
				query={EuCategoryListQuery}
				entityName={ENTITY.EU_CATEGORY}
				headers={['name']}
				typeIntro={typeIntro}
				filtersIntro={EuCategoryFilters}
				filterFields={isInternal ? ['name'] : null}
			/>
		)}
	</UserData>
);
