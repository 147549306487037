import React from 'react';
import { identity } from 'ramda';
import { AwardInvitedCompanyCreateMutation } from '../graphql/mutations/AwardInvitedCompanyCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/AwardInvitedCompany.json';
import inputTypeIntro from '../static/introspection/AwardInvitedCompanyCreateInput.json';
import { AwardInvitedCompanyEditQuery } from '../graphql/query/AwardInvitedCompanyEditQuery';
import { ENTITY } from '../utils/entities';
import { toRefInput } from '../utils/dataTrasform';
import { fastTransformer, toHidden } from '../utils/form';
import { graphqlQuery } from '../utils/graphql';
import { Award, AwardInvitedCompany, Company } from '../server-types';
import { EntityCreateModal } from '../types/entities';

export const AwardInvitedCompanyCreate: React.FC<EntityCreateModal<
	AwardInvitedCompany,
	Award | Company
>> = ({ id, onSubmit, originalEntity }) => (
	<EntityEditFetcher
		onSubmit={onSubmit}
		fetcher={id ? graphqlQuery(AwardInvitedCompanyEditQuery, { id }) : null}
		create={true}
		clone={false}
		modal={true}
		mutation={AwardInvitedCompanyCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.AWARD_INVITED_COMPANY}
		fields={[
			'Award',
			'Company',
			'signedContract',
			'maxGoodsPerCandidacy',
			'maxPerCompanyCandidacies',
			'formValues',
		]}
		transformer={(data) => {
			const parentEntityIsAward = originalEntity.__typename === ENTITY.AWARD;
			const hider = toHidden(toRefInput(originalEntity));

			data.fields = fastTransformer({
				Award: parentEntityIsAward ? hider : identity,
				Company: parentEntityIsAward ? identity : hider,
			})(data.fields);

			return data;
		}}
	/>
);
