import gql from 'graphql-tag';

export const UserSelectQuery = gql`
	query UserSelectQuery($filter: UserFilterInput) {
       choices: users (filter: $filter) {
			  edges {
					node {
						 id
						 ... on User {
							  localId
							  name
							  email
						 }
					}
			  }
		 }
	}
`;