import gql from 'graphql-tag';
import {MagazineContentFullFragment} from "../fragments/MagazineContentFullFragment";

export const MagazineContentPatchMutation = gql`
	mutation MagazineContentPatchMutation($id: ID!, $changes: MagazineContentPatchInput!) {
		results: magazineContentPatch(id: $id, changes: $changes) {
			...MagazineContentFullFragment
		}
	}
	${MagazineContentFullFragment}
`;


