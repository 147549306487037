import React from 'react';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/StoreChain.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';
import { EntityDetailPage } from '../types/entities';
import { StoreChain } from '../server-types';
import { StoreChainDetailQuery } from 'graphql/query/StoreChainDetailQuery';

export const StoreChainDetail: React.FC<EntityDetailPage<StoreChain>> = ({ id }) => (
	<UserData>
		{({ isInternal }) => (
			<EntityDetailFetcher
				id={id}
				query={StoreChainDetailQuery}
				entityName={ENTITY.STORECHAIN}
				typeIntro={typeIntro}
				fields={[
					'Retailer',
					'Foodservice',
					'Service',
					'Company',
					'name',
					'logo_media',
					'price_positioning',
					'website',
					'social_instagram_page',
					'social_facebook_page',
					'description_ita',
					'description_long_ita',
					'stores_ownership',
					'type',
					'goodClasses',
					'countries'
				]}
			/>
		)}
	</UserData>
);
