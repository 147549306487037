import React from 'react';
import { editorialSectionsToTree, getExpanded } from './tree';
import { toRefInput } from './dataTrasform';
import { t } from './labels';
import { Badge } from 'reactstrap';
import { UserDataInterface } from '@food/auth';
import { generateFakeAsyncQuery } from './misc';
import { BrandCreate } from '../containers/BrandCreate';
import { GoodType } from 'server-types';

function goodTransformer(user: UserDataInterface, data, entity, listsByType) {
	const { brands } = listsByType;
	const { isInternal, managedCompany } = user;
	const company: UserDataInterface['managedCompany']['Company'] | null =
		managedCompany && managedCompany.Company ? managedCompany.Company : null;

	data.fields.forEach((f) => {
		switch (f.name) {
			case 'name':
				f.description.readonly = !isInternal;
				if (entity) f.hidden = !isInternal;
				break;
			case 'shelfLifeLength':
				f.additionalHelpComponent = (
					<div>
						<Badge color="warning">{t`warning`}</Badge>{' '}
						{t`shelf life is in days`}
					</div>
				);
				break;
			case 'GoodClass':
				delete f.asyncQuery;
				f.type = 'Tree';
				f.single = true;
				f.beforeSaveTransformer = (e) => e[0];
				f.nodes = editorialSectionsToTree(listsByType.EditorialSections);
				if (entity) {
					f.value =
						f.value && f.value.value ? [toRefInput(f.value.value)] : [];
					f.expanded = getExpanded(listsByType.EditorialSections, f.value);
				}
				break;
			case 'Brand':
				if (isInternal) {
					f.myToString = (e) => `${e.name} (${e.Company.name})`;
				} else {
					f.asyncQuery = generateFakeAsyncQuery(brands);
				}
				f.addComponent = (props: any) => (
					<BrandCreate originalEntity={company} {...props} />
				);
				break;

			case 'goodType':
				if (!isInternal) {
					f.hidden = true;
					f.value = f.choices.filter((v) => v.value == GoodType.Regular)[0];
				}
				break;
			default:
				break;
		}
	});
	return data;
}

export { goodTransformer };
