import gql from 'graphql-tag';
import { FoodServiceFullFragment } from '../fragments/FoodServiceFullFragment';

export const FoodServiceCreateMutation = gql`
	mutation FoodServiceCreateMutation($values: FoodServiceCreateInput!) {
		results: foodServiceCreate(values: $values) {
			...FoodServiceFullFragment
		}
	}
	${FoodServiceFullFragment}
`;
