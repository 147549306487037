import gql from 'graphql-tag';
import {AwardInvitedCompanyFullFragment} from "../fragments/AwardInvitedCompanyFullFragment";

export const AwardInvitedCompanyCreateMutation = gql`
	mutation AwardInvitedCompanyCreateMutation($values: AwardInvitedCompanyCreateInput!) {
		results: awardInvitedCompanyCreate(values: $values) {
			...AwardInvitedCompanyFullFragment
		}
	}
	${AwardInvitedCompanyFullFragment}
`;


