import gql from 'graphql-tag';
import { CandidacyFullFragment } from '../fragments/CandidacyFullFragment';

export const producerAwardCandidacyEditQuery = gql`
	query ProducerAwardCandidacyEditQuery($id: ID!) {
		entity: node(id: $id) {
			...CandidacyFullFragment
		}
	}
	${CandidacyFullFragment}
`;
