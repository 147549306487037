import React from 'react';
import { assoc } from 'ramda';
import { CompanyLevelPatchMutation } from '../graphql/mutations/CompanyLevelPatchMutation';
import { CompanyLevelEditQuery } from '../graphql/query/CompanyLevelEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/CompanyLevel.json';
import inputTypeIntro from '../static/introspection/CompanyLevelPatchInput.json';
import { ENTITY } from '../utils/entities';
import { generateAsyncQuery, graphqlQuery } from '../utils/graphql';
import { CompanyBadgeSelectQuery } from '../graphql/query/CompanyBadgeSelectQuery';
import { fastTransformer } from '../utils/form';
import { EntityEditModal } from '../types/entities';
import { Company, CompanyLevel } from '../server-types';
import { formatStr } from '../utils/validation';

export const CompanyLevelEdit: React.FC<EntityEditModal<
	CompanyLevel,
	Company
>> = ({ id, onSubmit }) => (
	<EntityEditFetcher
		id={id}
		modal={true}
		onSubmit={onSubmit}
		fetcher={graphqlQuery(CompanyLevelEditQuery, { id })}
		mutation={CompanyLevelPatchMutation}
		entityName={ENTITY.COMPANY_LEVEL}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		fields={[
			'companyBadges',
			'expirationDate',
			'description',
			'descriptionLong',
			'videoUrl',
			'layout',
			'website',
			'newsPath',
		]}
		transformer={(data, entity) => {
			data.fields = fastTransformer({
				companyBadges: assoc(
					'asyncQuery',
					generateAsyncQuery(
						CompanyBadgeSelectQuery,
						true,
						(value: string) => ({
							filter: { name: value, targetSite: entity.targetSite },
						}),
					),
				),
				newsPath: (f) => {
					// /company-brand-product/SLUG/
					const regex = '/company-brand-product/[a-zA-Z-_.]+/';
					return {
						...f,
						validators: [...f.validators, formatStr(regex)],
					};
				},
			})(data.fields);

			return data;
		}}
	/>
);
