import gql from 'graphql-tag';

export const ToSFullFragment = gql`
	fragment ToSFullFragment on ToS {
		id
		localId
		name
		startDate
		description
		descriptionIta
		descriptionExtra
		descriptionExtraIta
		descriptionChanges
		descriptionChangesIta
	}
`;
