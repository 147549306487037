import React from 'react';
import { ToSDetailQuery } from '../graphql/query/ToSDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/ToS.json';
import { ENTITY } from '../utils/entities';

export const ToSDetail = (props) => (
	<EntityDetailFetcher
		{...props}
		query={ToSDetailQuery}
		entityName={ENTITY.TERMS_OF_SERVICE}
		typeIntro={typeIntro}
		clonable={false}
		fields={[
			'name',
			'startDate',
			'description',
			'descriptionIta',
			'descriptionExtra',
			'descriptionExtraIta',
			'descriptionChanges',
			'descriptionChangesIta',
		]}
	/>
);
