import gql from 'graphql-tag';
import { CouponFullFragment } from '../fragments/CouponFullFragment';

export const CouponDetailQuery = gql`
	query CouponDetailQuery($id: ID!) {
		entity: node(id: $id) {
			...CouponFullFragment
		}
	}
	${CouponFullFragment}
`;
