import gql from 'graphql-tag';
import {MagazineContentFullFragment} from "../fragments/MagazineContentFullFragment";

export const MagazineContentCreateMutation = gql`
	mutation MagazineContentCreateMutation($values: MagazineContentCreateInput!) {
		results: magazineContentCreate(values: $values) {
			...MagazineContentFullFragment
		}
	}
	${MagazineContentFullFragment}
`;


