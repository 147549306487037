import React from 'react';
import { AwardInvitedCompanyDetailQuery } from '../graphql/query/AwardInvitedCompanyDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/AwardInvitedCompany.json';
import { ENTITY } from '../utils/entities';
import { EntityDetailModal } from '../types/entities';
import { AwardInvitedCompany } from '../server-types';

export const AwardInvitedCompanyDetail: React.FC<EntityDetailModal<
	AwardInvitedCompany
>> = ({ id, onDelete, entityLabel, modal }) => (
	<EntityDetailFetcher
		id={id}
		onDelete={onDelete}
		query={AwardInvitedCompanyDetailQuery}
		entityName={ENTITY.AWARD_INVITED_COMPANY}
		typeIntro={typeIntro}
		clonable={false}
		modal={modal}
		entityLabel={entityLabel}
		fields={[
			'Award',
			'Company',
			'signedContract',
			'maxGoodsPerCandidacy',
			'maxPerCompanyCandidacies',
			'formValues',
		]}
	/>
);
