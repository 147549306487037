import en_US from '../static/i18n/it_IT';

const labels = {
	...en_US,
};

const notTranslated = [];
function getNotTranslated() {
	return notTranslated.map((id) => `'${id}': ''`).join(',\n');
}
if (typeof window !== 'undefined') {
	// the variable is defined
	(window as any).getNotTranslated = getNotTranslated;
}

export const t = (...stringIds: ReadonlyArray<string | TemplateStringsArray>): string => {
	let i = 0;
	for (i; i < stringIds.length; i++) {
		const stringId = stringIds[i];

		if (!stringId) continue;

		const id = typeof stringId === 'string' ? stringId : stringId[0];

		if (id in labels && labels[id] !== undefined) return labels[id];

		if (notTranslated.indexOf(id) === -1) {
			notTranslated.push(id);
		}
		if (i === stringIds.length - 1) {
			return id;
		}
	}
	return '';
};
