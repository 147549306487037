import gql from 'graphql-tag';
import {AwardInvitedUserFullFragment} from "../fragments/AwardInvitedUserFullFragment";

export const AwardInvitedUserCreateMutation = gql`
	mutation AwardInvitedUserCreateMutation($values: AwardInvitedUserCreateInput!) {
		results: awardInvitedUserCreate(values: $values) {
			...AwardInvitedUserFullFragment
		}
	}
	${AwardInvitedUserFullFragment}
`;


