import React from 'react';
import { t } from '../utils/labels';
import { CompanyGroupDetailQuery } from '../graphql/query/CompanyGroupDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/CompanyGroup.json';
import { ManagedByUserDetail } from './ManagedByUserDetail';
import { ManagedByUserEdit } from './ManagedByUserEdit';
import { ManagedByUserCreate } from './ManagedByUserCreate';
import gql from 'graphql-tag';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';
import { refactorInfo } from '../utils/form';
import { CompanyGroup } from '../server-types';
import { EntityDetailPage } from '../types/entities';

export const CompanyGroupDetail: React.FC<EntityDetailPage<CompanyGroup>> = ({
	id,
}) => (
	<UserData>
		{({ isInternal }) => (
			<EntityDetailFetcher
				query={CompanyGroupDetailQuery}
				entityName={ENTITY.COMPANY_GROUP}
				id={id}
				typeIntro={typeIntro}
				clonable={false}
				fields={[
					isInternal ? 'sfId' : null,
					'name',
					'logoMedia',
					'type',
					'billingName',
					'description',
					'descriptionIta',
					'descriptionLong',
					'descriptionLongIta',
					'pecEmail',
					'vatNumber',
					'website',
					'Country',
					'StateProvince',
					'postalCode',
					'city',
					'address1',
					'address2',
					'lat',
					'lng',
					'managedByUsers',
				]}
				transformer={(data) => {
					data.fields.forEach((f) => {
						switch (f.name) {
							case 'managedByUsers':
								if (f.type !== 'EntityList') {
									refactorInfo(f, 'EntityList');
									break;
								}
								f.props.headers = ['User', 'capabilities'];
								f.editComponent = ManagedByUserEdit;
								f.detailComponent = ManagedByUserDetail;
								f.addComponent = ManagedByUserCreate;
								f.tab = 1;
								f.props.fragment = gql`
									fragment Fragment on ManagedByUser {
										User {
											id
											name
										}
										capabilities
									}
								`;
								break;

							case 'newsCitations':
								if (f.type !== 'EntityList') {
									refactorInfo(f, 'EntityList');
									break;
								}
								f.props.denyAdd = true;
								f.props.headers = ['News', 'count'];
								f.tab = 2;
								f.props.fragment = gql`
									fragment Fragment on NewsCitation {
										News {
											id
											name: title
										}
										count
									}
								`;
								break;

							default:
								break;
						}
					});
					return data;
				}}
				tabs={[
					t('generic data'),
					t(
						[ENTITY.COMPANY_GROUP, ENTITY.MANAGED_BY_USER, 'label'].join(
							'/',
						),
					),
					t(
						[ENTITY.COMPANY_GROUP, ENTITY.NEWS_CITATION, 'label'].join(
							'/',
						),
					),
				]}
				cols={[['col-sm-12'], ['col-sm-12'], ['col-sm-12']]}
			/>
		)}
	</UserData>
);
