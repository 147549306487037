import { pipe } from 'ramda';
import { UserData } from '@food/auth';
import { invalidateDirectoryMutation } from 'graphql/mutations/InvalidateDirectoryMutation';
import React, { useCallback, useMemo, useState } from 'react';
import {
	Button,
	Card,
	CardBody,
	Form as BootstrapForm,
	Modal,
	ModalBody,
	ModalFooter,
} from 'reactstrap';
import { InternalCapability } from 'server-types';
import { client } from 'utils/client';
import { modalHandler, parseGraphqlError, sentryHandler } from 'utils/errors';
import { generateBaseField, generateBooleanField, toChoices } from 'utils/form';
import { toRequired } from 'utils/misc';
import { t } from '../utils/labels';
import { Form } from './Form';
import { FieldList } from './form/FieldsList';
import { PageTitle } from './PageTitle';
import { ReportNotAllowed } from './ReportNotAllowed';

export const DirectoryReloadComponent: React.FunctionComponent<{}> = () => {
	const title = 'Directory reload';

	const [reloadState, setReloadState] = useState({
		directory: undefined,
		preClear: false,
		reloading: false,
		showReloadConfirm: false,
		result: undefined,
	});
	const {
		directory,
		preClear,
		reloading,
		showReloadConfirm,
		result,
	} = reloadState;
	const closeConfirm = useCallback(
		() =>
			setReloadState((state) => ({
				...state,
				directory: undefined,
				preClear: false,
				reloading: false,
				showReloadConfirm: false,
			})),
		[reloadState],
	);

	const closeResult = useCallback(() => {
		setReloadState((state) => ({
			...state,
			result: undefined,
		}));
	}, [reloadState]);

	const reloadDirectory = useCallback(
		async (values: {
			directory: string; // FIXME
			preClear: boolean;
		}) => {
			try {
				setReloadState((state) => ({
					...state,
					reloading: true,
				}));
				const res = await client.mutate({
					mutation: invalidateDirectoryMutation,
					variables: {
						directory: values.directory,
						preClear: values.preClear,
					},
				});
				setReloadState((state) => ({
					...state,
					directory: undefined,
					preClear: false,
					reloading: false,
					showReloadConfirm: false,
					result: res?.data?.invalidateDirectory,
				}));
			} catch (e) {
				const errs = parseGraphqlError(e);
				sentryHandler(errs);
				modalHandler(errs);
				console.error(e);
				setReloadState((state) => ({
					...state,
					reloading: false,
					showReloadConfirm: false,
				}));
			}
		},
		[],
	);

	const fields = useMemo(
		() => [
			pipe(
				toChoices(
					[
						{
							label: 'Companies',
							value: 'COMPANY',
						},
						{
							label: 'Goods',
							value: 'GOOD',
						},
					],
					true,
				),
				toRequired,
			)(
				generateBaseField(
					'directory',
					t`directory-reload/directory/label`,
					t`directory-reload/directory/helpText`,
				),
			),
			generateBooleanField(
				'preClear',
				t`directory-reload/preClear/label`,
				true,
			),
		],
		[],
	);

	return (
		<UserData>
			{({ userData }) => {
				if (
					!userData.internalCapabilities.includes(
						InternalCapability.AdminExtra,
					)
				) {
					return <ReportNotAllowed />;
				}

				return (
					<>
						<PageTitle
							title={title}
							breadcrumbs={[
								{ label: t`home`, path: '/' },
								{
									label: t`Maintenance`,
								},
								{
									label: title,
								},
							]}
						/>
						<Modal
							isOpen={showReloadConfirm}
							toggle={reloading ? undefined : closeConfirm}
							backdrop="static"
							wrapClassName="renew-modal-wrapper"
							autoFocus={false}
						>
							<ModalBody>
								<h3 className="text-center">{t`Confirm reload text?`}</h3>
							</ModalBody>
							<ModalFooter>
								<Button
									disabled={reloading}
									color="secondary"
									onClick={closeConfirm}
								>
									<i className="fa fa-times" /> {t`Undo`}
								</Button>{' '}
								<Button
									disabled={reloading}
									color="danger"
									onClick={() =>
										reloadDirectory({ directory, preClear })
									}
								>
									<i className="fa fa-trash" /> {t`Reload`}
								</Button>
							</ModalFooter>
						</Modal>
						<Modal
							isOpen={!!result}
							toggle={closeResult}
							backdrop="static"
							wrapClassName="renew-modal-wrapper"
							autoFocus={false}
						>
							<ModalBody>
								<h3 className="text-center">{result?.code}</h3>
								<p>{result?.message}</p>
							</ModalBody>
							<ModalFooter>
								<Button color="success" onClick={closeResult}>
									<i className="fa fa-close" /> {t`Close`}
								</Button>
							</ModalFooter>
						</Modal>
						<Card>
							<CardBody>
								<Form
									fields={fields}
									onSubmit={(fields, finalValues, injectErrors) => {
										setReloadState((state) => ({
											directory: finalValues.directory,
											preClear: finalValues.preClear,
											reloading: false,
											showReloadConfirm: true,
											result: undefined,
										}));
									}}
								>
									{({ fields, mutatorFactory, values, submit }) => (
										<BootstrapForm className="Form">
											<FieldList
												fields={fields}
												setter={mutatorFactory}
											/>
											<Button
												onClick={() => submit()}
												size="lg"
												color="success"
											>
												<i className="mdi mdi-download" />{' '}
												{t`Reload`}
											</Button>
										</BootstrapForm>
									)}
								</Form>
							</CardBody>
						</Card>
					</>
				);
			}}
		</UserData>
	);
};
