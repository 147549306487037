import gql from 'graphql-tag';
import {NewsletterFullFragment} from "../fragments/NewsletterFullFragment";

export const NewsletterCreateMutation = gql`
	mutation NewsletterCreateMutation($values: NewsletterCreateInput!) {
		results: newsletterCreate(values: $values) {
			...NewsletterFullFragment
		}
	}
	${NewsletterFullFragment}
`;


