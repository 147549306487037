import { Dict, IChoice /*IChoice*/ } from '../utils/misc';
import { Permission } from '../utils/permissions';
import React from 'react';
import { ENTITY } from '../utils/entities';
import { AsyncQuery } from '../utils/graphql';
import { Media, MediaType } from '../server-types';
import { EntityListFetcherProps } from '../components/EntityListFetcher';
import { Node } from 'react-checkbox-tree';
import {
	EntityCreateModal,
	EntityDetailModal,
	EntityEditModal,
} from './entities';

export enum DTPrecision {
	DayBegin = 'DAY_BEGIN',
	DayEnd = 'DAY_END',
	MonthBegin = 'MONTH_BEGIN',
	MonthEnd = 'MONTH_END',
	Full = 'FULL',
}

export enum SubForms {
	COMPANY = 'COMPANY',
	GOOD = 'GOOD',
	NEWS = 'NEWS',
	NEWS_WITH_LAYOUT = 'NEWS_WITH_LAYOUT',
	BANNER = 'BANNER',
	MAIN_IMAGE = 'MAIN_IMAGE',
	RICH_TEXT = 'RICH_TEXT',
	TITLE = 'TITLE',
	TITLE_NO_IMAGE = 'TITLE_NO_IMAGE',
	IMAGE_TEXT = 'IMAGE_TEXT',
	INSTANT_ACTION = 'INSTANT_ACTION',
	DOUBLE_INSTANT_ACTION = 'DOUBLE_INSTANT_ACTION',
	LINK = 'LINK',
	IFN_SEARCH_BAR = 'IFN_SEARCH_BAR',
	ENTITIES = 'ENTITIES',
	TYPEFORM_PREVIEW = 'TYPEFORM_PREVIEW',
	CANDIDACY = 'CANDIDACY',
	IFN_CARD = 'IFN_CARD',
	NEWS_LIST = 'NEWS_LIST',
	EDITORIAL_SECTIONS = 'EDITORIAL_SECTIONS',
	LINK_LIST = 'LINK_LIST',
	SPONSOR = 'SPONSOR',
	AUTHOR = 'AUTHOR',
	SECTION_TAGS = 'SECTION_TAGS',
	LAUNCH = 'LAUNCH',
	LAUNCH_LIST = 'LAUNCH_LIST',
	NEWSLETTER_FAQ = 'NEWSLETTER_FAQ',
	NEWSLETTER_FAQ_LIST = 'NEWSLETTER_FAQ_LIST',
	NEWSLETTER_CLAIM = 'NEWSLETTER_CLAIM',
	NEWSLETTER_CLAIM_LIST = 'NEWSLETTER_CLAIM_LIST',
	IMAGE_TEXT_BASE = 'IMAGE_TEXT_BASE',
	IMAGE_TEXT_COLUMNS = 'IMAGE_TEXT_COLUMNS',
	SOCIAL_BUTTONS = 'SOCIAL_BUTTONS',
	ADD_TO_CALENDAR = 'ADD_TO_CALENDAR',
}

export type ErrorChecker = (val: any) => Error | false | Promise<Error | false>;

export interface FormValidationErrorT {
	key: string;
	type: string;
}

export type Entity = any; // TODO renderla miglore usando i tipi generati dall'introspezione graphql

export interface BaseField<T> {
	name: string;
	type: string;
	label: string;
	validators: ReadonlyArray<ErrorChecker>;
	description: Dict;
	helpText?: string;
	tab?: number;
	col?: number;
	pre?: JSX.Element;
	post?: JSX.Element;
	additionalHelpComponent?: JSX.Element;
	changed?: boolean; // Forse si puo' togliere ed usare al suo posto la lista di touched?
	forceUpdateKey?: string;
	hidden?: boolean;
	required?: boolean;
	disabled?: boolean;
	compact?: boolean;
	// parti relative al valure
	value?: T | null;
	beforeSaveTransformer?: (val: T) => any;
	afterChange?: (
		field: IField,
		value: T,
		fields: ReadonlyArray<IField>,
	) => ReadonlyArray<IField>;
}

export interface IntegerFieldType extends BaseField<number> {
	type: 'Int';
}

export interface SyncChoiceFieldType
	extends BaseField<string | Entity | ReadonlyArray<string | Entity>> {
	type: 'Choices';
	choices: ReadonlyArray<string | Entity>;
	isEnum?: boolean;
	single?: boolean;
	mapToEntity?: ENTITY;
}

export interface TextFieldType extends BaseField<string> {
	type: 'Text';
	formatText?: boolean; // Credo serva per la visualizzazione di testi
	htmlFull?: boolean; // Permette di utilizzare tutti i tag html all'interno di un testo
	multiline?: boolean; // Distingue i campi con tag input con quelli con tag textarea
	max?: number; // Massima lunghezza di un testo
}

// definisco la lista dei campi necessari da inserire all'interno delle props basandomi sulla loro definizione originale
// alcuni campi sono definiti in EntityListField, quindi il componente che li prende in input deve averli, ma non devo
// definirli nelle props di configurazione
type PossibleProps = Omit<
	EntityListFetcherProps,
	| 'secondary'
	| 'addModalOpener'
	| 'detailModalOpener'
	| 'createModalMode'
	| 'detailModalMode'
	| 'history'
	| 'location'
	| 'match'
>;

export interface EntityListFieldType extends BaseField<ReadonlyArray<Entity>> {
	type: 'EntityList';
	//entityStructure: any; // introspezione graphql dell'entita' listata
	permissions: Permission;
	props: PossibleProps;
	originalEntity?: Entity;
	addComponent?:
		| React.ComponentClass<EntityCreateModal<any, any>, any>
		| React.FC<EntityCreateModal<any, any>>;
	entityLabel?: ENTITY;
	detailComponent?: React.FC<EntityDetailModal<any>>;
	editComponent?: React.FC<EntityEditModal<any, any>>;
	detailHandler?: () => void;
}


export interface MediaExtended {
	media: Media,
	selectedSize: null | string;

	id?: any;
	localId?: any;
	type?: MediaType;
	Company?: string;
	name?: string;
	caption?: string;
	origFileName?: string;
	timestamp?: string;
	origUrl?: string;
	largeThumbUrl?: string;
	mediumThumbUrl?: string;
	smallThumbUrl?: string;
	vecThumbUrl?: string;
}
export interface MediaFieldType extends BaseField<MediaExtended> {
	type: 'Media';
	big?: boolean;
	permissions: Permission;
	// mediaType e' opzionale perche' alcune volte puo' essere temporaneamente non definita
	// in quanto dipendente da fattori esterni
	mediaType?: MediaType;
	chooseSize?: boolean | ReadonlyArray<string>;
}

export interface BooleanFieldType extends BaseField<boolean> {
	type: 'Boolean';
}

export interface MediaListFieldType extends BaseField<ReadonlyArray<Media>> {
	type: 'MediaList';
	permissions?: Permission;
	ordered?: boolean;
	// mediaType e' opzionale perche' alcune volte puo' essere temporaneamente non definita
	// in quanto dipendente da fattori esterni
	mediaType?: MediaType;
}

export interface SubFormFieldType
	extends BaseField<{
		selected: SubForms;
		fields: ReadonlyArray<IField>;
	}> {
	type: 'SubForm';
	choices: ReadonlyArray<IChoice>;
	types: ReadonlyArray<SubForms>;
	presets?: { [key: string]: Partial<IField> };
}

export interface SubFormListFieldType extends BaseField<any> {
	type: 'SubFormList';
	choices: ReadonlyArray<IChoice>;
	types: ReadonlyArray<SubForms>;
	ordered?: boolean;
	presets?: { [key: string]: Partial<IField> };
	modalMode?: boolean;
	addButtonLabel?: string;
	max?: number;
}

export interface WordpressPostFieldType extends BaseField<string> {
	type: 'WordpressPost';
}

export interface LaunchFieldType extends BaseField<string> {
	type: 'Launch';
}

export interface ColorFieldType extends BaseField<string> {
	type: 'Color';
}

export interface RichTextFieldType extends BaseField<string> {
	type: 'RichText';
	htmlFull?: boolean; // se true puo' contenere qualsiasi tag html
	//max?: number; // Massima lunghezza di un testo
}

export interface DateFieldType extends BaseField<Date> {
	type: 'Date';
	precision: DTPrecision;
}

export interface AsyncChoiceFieldType
	extends BaseField<Entity | ReadonlyArray<Entity>> {
	type: 'AsyncChoices';
	asyncQuery: AsyncQuery;
	entityType: ENTITY;
	single: boolean | undefined;
	addComponent?: React.ComponentClass<any, any> | React.FC<any>;
	entityToLabel?: (e: Entity) => string;
	myToString?: (e: any) => string;
	mapToEntity?: (id: string) => string; // la funzione che dato un id genera l'ult per visualizzare l'entita'
}

export interface TreeFieldType extends BaseField<any> {
	type: 'Tree';
	nodes: ReadonlyArray<Node>;
	single?: boolean;
	expanded?: ReadonlyArray<any>;
}

export interface FileFieldType extends BaseField<File> {
	type: 'File';
}

export interface HiddenFieldType extends BaseField<any> {
	type: 'Hidden';
}

export type IField =
	| HiddenFieldType
	| BooleanFieldType
	| SubFormFieldType
	| SubFormListFieldType
	| MediaListFieldType
	| MediaFieldType
	| SyncChoiceFieldType
	| EntityListFieldType
	| TextFieldType
	| IntegerFieldType
	| RichTextFieldType
	| ColorFieldType
	| DateFieldType
	| AsyncChoiceFieldType
	| TreeFieldType
	| FileFieldType
	| WordpressPostFieldType
	| LaunchFieldType;

/*export type afterChangeType = (
	field: IField,
	value: any,
	fields: IField[],
) => IField[];*/

export enum HTML_LEVELS {
	BASE = 'BASE', // Oneliner, super-simple formatting: for title
	BASE_LINK = 'BASE_LINK', // Oneliner, super-simple formatting + link (for captions)
	BASE_MULTILINE = 'BASE_MULTILINE', // Multiline (<br> not <p>), super-simple formatting: for excerpt
	FORMATTING = 'FORMATTING', // Multiline, no links or embed: for producer
	NEWSLETTER = 'NEWSLETTER', // Multiline, links, heading, no ember: for newsletter texts
	LINK_EMBED = 'LINK_EMBED', // As FORMATTING with links and embed: for news
	FULL = 'FULL', // No limits or validation: for admin
}

export type FieldsTransformer = (
	fields: ReadonlyArray<IField>,
) => ReadonlyArray<IField>;
export type FieldTransformer<From, To> = (f: From) => To;
export type FieldMuter = (val: any, path: string) => Promise<void> | void;

export interface FieldComponent<T extends BaseField<any>> {
	field: T;
	path: string;
	changeHandler: FieldMuter;
	readOnly?: boolean;
	modal?: boolean;
}

export interface FieldComponentRO<Field extends BaseField<any>> {
	field: Field;
	modal?: boolean;
}

export type FieldSetter = (field: IField) => FieldMuter;

export type FormContainer = {
	errors: { [key: string]: ReadonlyArray<Error> };
	fields: ReadonlyArray<IField>;
	usedMedias: ReadonlyArray<Media>;
	touched: ReadonlyArray<string>;
	mutatorFactory?: FieldSetter;
	values: { [key: string]: any };
	submit: () => void;
};

export type FormDefinition = {
	fields: ReadonlyArray<
		| {
				type: 'Text';
				name: string;
				required: boolean;
				defaultValue?: string;
				min?: number;
				max?: number;
				multiline?: boolean;
		  }
		| {
				type: 'RichText';
				name: string;
				required: boolean;
				defaultValue?: string;
				min?: number;
				max?: number;
		  }
		| {
				type: 'Boolean';
				defaultValue: boolean | null | undefined;
				required: boolean;
				name: string;
		  }
		| {
				type: 'SubForm';
				name: string;
				form: SubForms;
				defaultValue: any;
				required: boolean;
				single?: boolean;
		  }
	>;
};

export interface FormParsingOptions {
	customLabelFn?: (s: string) => string;
	customHelpTextFn?: (s: string) => string;
}
