import React from 'react';
import { fairParticipationCreateMutation } from '../graphql/mutations/FairParticipationCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/FairParticipation.json';
import inputTypeIntro from '../static/introspection/FairParticipationCreateInput.json';
import { fairParticipationEditQuery } from '../graphql/query/FairParticipationEditQuery';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { Company, Fair, FairParticipation } from '../server-types';
import { fairParticipationTransformer } from '../utils/entities/fairParticipation';
import { EntityCreateModal } from '../types/entities';

export const FairParticipationCreate: React.FC<EntityCreateModal<
	FairParticipation,
	Fair | Company
>> = ({ id, originalEntity, onSubmit }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(fairParticipationEditQuery, { id }) : null}
		onSubmit={onSubmit}
		create={true}
		clone={false}
		modal={true}
		mutation={fairParticipationCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.FAIR_PARTICIPATION}
		fields={['Fair', 'Company', 'pavilion', 'stand', 'note']}
		transformer={fairParticipationTransformer(originalEntity)}
	/>
);
