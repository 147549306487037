import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { EmailDomainListQuery } from '../graphql/query/EmailDomainListQuery';
import typeIntro from '../static/introspection/EmailDomain.json';
import EmailDomainFilters from '../static/introspection/EmailDomainFilterInput.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';

export const EmailDomainList = () => (
	<UserData>
		{({ isInternal }) => (
			<EntityListFetcher
				query={EmailDomainListQuery}
				entityName={ENTITY.EMAIL_DOMAIN}
				headers={['emailDomain', 'accountType', 'action']}
				sortables={['emailDomain', 'accountType', 'action']}
				typeIntro={typeIntro}
				filtersIntro={EmailDomainFilters}
				filterFields={
					isInternal ? ['emailDomain', 'accountTypes', 'actions'] : null
				}
			/>
		)}
	</UserData>
);
