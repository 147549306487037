import {
	formatStr,
	max,
	min,
	mustBeDigits,
	required,
	urlValidator,
} from './validation';
import { t } from './labels';
import {
	choicesBeforeSaveTransformer,
	toRefInput,
	toRefInputs,
} from './dataTrasform';
import { ACTIONS, generateUrl } from './urls';
import { STRUCTURE } from '../static/structures';
import { calculateUserPermissions } from './permissions';
import {
	generateAsyncQuery,
	generateDefaultListQuery,
	generatePaginatedSelectQuery,
} from './graphql';
import { UserDataInterface } from '@food/auth';
import { IField } from '../types/form';
import { globalSelectTransformQuery } from './transformers';
import { entity2String } from './toString';

const STOP_KINDS = ['SCALAR', 'ENUM', 'INTERFACE', 'OBJECT'];

/**
 * Genera l'elenco dei campi da mostrare in interfaccia con tutti i paramentri
 * basandosi sull'introspezione di GraphQL
 * @param user
 * @param fieldNamesList - [string] elenco dei campi da mettere in interfaccia già nell'ordine corretto
 * @param typeIntrospection - risultato di
 typeIntro: __type(name: "Company") {
        	name
        	fieldValues {
        		name
        		description
        		type {
					kind
					name
					enumValues {
						name
					}
					ofType {
						kind
						name
						enumValues {
							name
						}
						ofType {
							kind
							name
							enumValues {
								name
							}
						}
					}
        		}
        	}
        }
 * @param detailMode
 * @param mutationInputIntrospection - su sola lettura null, su create/patch mutation è il risultato di
 inputTypeIntro: __type(name: "CompanyPatchInput") {
		    inputFields {
		      name
		    }
		}
 * @param listsByType - Object che ha come key il nome degli ObjectType usati in elenco nei campi e come value { localId, name }
 * @param entityLabel
 * @returns [Object] - elenco campi nell'ordine giusto di e già filtrato del tipo { name, label, helpText, type, value, validators, ... }
 */
function generateObjectFieldList(
	user: UserDataInterface,
	fieldNamesList: ReadonlyArray<string>,
	typeIntrospection: any,
	detailMode: boolean, // definisce se la tipizzazione viene fatta per un dettaglio o una edit (cambiano i tipi in alcuni casi)
	mutationInputIntrospection?: any,
	listsByType?: any,
	entityLabel?: string,
): ReadonlyArray<IField> {
	const fields = [];
	const mutationFieldNames =
		mutationInputIntrospection &&
		mutationInputIntrospection.inputFields.map((field) => {
			return field.name;
		});

	// salvo le definizioni dei campi
	const typeFieldByName = {};
	typeIntrospection.fields.forEach((origField) => {
		typeFieldByName[origField.name] = origField;
	});

	const inputFieldsDescriptionByName = {};

	// sovrascrivo le definizioni dei campi con quelle fornite nella mutazione, se presente
	if (mutationInputIntrospection) {
		mutationInputIntrospection.inputFields.forEach((field) => {
			inputFieldsDescriptionByName[field.name] = field;
		});
	}

	fieldNamesList
		.filter((fieldName) => !!fieldName)
		.forEach((fieldName) => {
			const origField = typeFieldByName[fieldName];
			let inputField = inputFieldsDescriptionByName[fieldName];

			if (!origField) {
				throw new Error(
					`Unknown field "${fieldName}" in ${typeIntrospection.name} object type`,
				);
			}
			if (mutationFieldNames && mutationFieldNames.indexOf(fieldName) < 0) {
				return;
			}

			const fieldParams = origField.description
				? JSON.parse(origField.description)
				: {};
			const field: any = {
				name: origField.name,
				description: fieldParams,
				label: t(
					[
						entityLabel || typeIntrospection.name,
						origField.name,
						'label',
					].join('/'),
				),
				helpText: t(
					[
						entityLabel || typeIntrospection.name,
						origField.name,
						'helpText',
					].join('/'),
				),
				defaultValue: fieldParams.defaultValue,
				validators: [],
			};
			// Initial settings
			if (fieldParams.forceCase) {
				switch (fieldParams.forceCase) {
					case 'UPPER':
						field.beforeSaveTransformer = (str) =>
							str && str.toUpperCase();
						break;
					case 'LOWER':
						field.beforeSaveTransformer = (str) =>
							str && str.toLowerCase();
						break;
					default:
						console.error(
							`fieldParams.forceCase unsupported "${fieldParams.forceCase}" value`,
						);
						break;
				}
			}

			if (fieldParams.mediaType) {
				field.mediaType = fieldParams.mediaType;
			}
			// Map type
			let curType = origField.type;
			let multiSelect = false;

			// se il nome del tipo contiene "connection" allora si tratta di una connessione, quindi il campo e' multiplo
			if (curType.name && curType.name.indexOf('Connection') !== -1) {
				multiSelect = true;
			}

			while (STOP_KINDS.indexOf(curType.kind) < 0 && curType.ofType) {
				if (curType.kind === 'LIST') {
					multiSelect = true;
				}

				if (inputField && inputField.type.kind === 'NON_NULL') {
					fieldParams.required = true;
				}
				curType = curType.ofType;
			}

			switch (curType.kind) {
				case 'SCALAR':
					switch (curType.name) {
						case 'Int':
						case 'Float':
						case 'Int53':
							field.type = 'Int';
							field.validators.push(mustBeDigits);
							field.beforeSaveTransformer = (e: string) => Number(e);
							break;
						// case 'Float':
						// 	field.type = 'Float';
						// 	break;
						case 'String':
							if (fieldParams.html) {
								field.type = 'RichText';
								if (fieldParams.htmlLevel === 'FULL') {
									field.htmlFull = true;
								}
							} else {
								field.type = 'Text';
							}
							if (fieldParams.html || fieldParams.multiline) {
								field.multiline = true;
							}
							break;
						case 'Email':
							field.type = 'Text';
							//TODO - validator o formatRegexStr ?
							break;
						case 'DateTime':
							field.type = 'Date';
							break;
						case 'URL':
							field.type = 'Url';
							if (!fieldParams.formatRegexStr) {
								field.validators.push(urlValidator);
							}
							break;
						case 'Boolean':
							field.type = 'Boolean';
							field.defaultValue = false;
							if (fieldParams.required) {
								field.value = false;
							} else {
								field.value = null;
							}
							break;
						default:
							console.warn(
								'Unsupported scalar type',
								curType.name,
								origField,
							);
							break;
					}
					break;
				case 'ENUM':
					field.type = 'Choices';
					field.isEnum = true;
					field.single = !multiSelect;
					field.choices = curType.enumValues.map((c) => ({
						label: t(
							[entityLabel || curType.name, c.name, 'label'].join('/'),
						),
						value: c.name,
					}));
					field.beforeSaveTransformer = choicesBeforeSaveTransformer;
					break;
				case 'OBJECT':
					// prendo la sua introspezione
					// tolgo la stringa "connection" per evitare di prendere le introspezioni delle Connection
					// TODO: trovare un modo piu' sicuro
					const strucutre =
						STRUCTURE[curType.name.replace('Connection', '')];

					if (strucutre === undefined) {
						throw new Error(
							'Entity introspection not found: ' +
								curType.name.replace('Connection', ''),
						);
					}

					field.permissions = calculateUserPermissions(
						user.capabilities,
						user.userData.role,
						strucutre.description,
					);

					if (curType.name === 'Media') {
						// gestisco i media come caso speciale, hanno alcune esigenze particolari

						field.enum = false;
						field.entityType = 'Media';
						field.single = !multiSelect;

						if (field.single) {
							// campo media singolo
							field.type = 'Media';
							field.beforeSaveTransformer = toRefInput;
						} else {
							// lista di media, ordinati o meno
							field.type = 'MediaList';
							field.ordered = !!fieldParams.ordered;
							field.beforeSaveTransformer = toRefInputs;
						}
					} else {
						field.enum = false;
						field.single = !multiSelect;
						field.entityType = curType.name;
						field.mapToEntity = (id) =>
							generateUrl(curType.name, ACTIONS.DETAIL, id);
						field.asyncQuery = generateAsyncQuery(
							generatePaginatedSelectQuery(curType.name),
							true,
						);

						if (field.single || !detailMode) {
							field.type = 'AsyncChoices';
							if (field.single) {
								field.beforeSaveTransformer = (option) =>
									option ? toRefInput(option.value) : null;
							} else {
								field.beforeSaveTransformer = (options) =>
									options
										? options.map((o) => toRefInput(o.value))
										: [];
							}
						} else {
							// in altre parole entro in questa in questa condizione solo se sono in detailMode e la lista
							// e' multipla
							field.type = 'EntityList';
							field.beforeSaveTransformer = (
								options: ReadonlyArray<any> | null | undefined,
							) =>
								options ? options.map((o) => toRefInput(o.value)) : [];
						}

						// se il campo riguarda gli oggetti ed e' multiplo con buona probabilita'
						// dovra' creare una propria "tab" nel dettaglio; aggiungo quindi al campo alcune
						// proprieta' deducibili gia' in questa fase del flusso
						if (!field.single) {
							const paginated = curType.name.indexOf('Connection') > 0;

							field.props = {
								paginated: false, // non detto, ma e' il default piu' probabile
								extractor: (data) => data.node[field.name],
								headers: ['name'],
								typeIntro: strucutre,
								entityName: curType.name,
								query: generateDefaultListQuery(
									typeIntrospection.name,
									field.name,
									paginated,
								),
								identifier: typeIntrospection.name + '/' + field.name,
							};
						}
					}

					break;
				case 'INTERFACE':
					// TODO gestire il caso delle interface
					return;
				default:
					throw new Error(`Unmanaged stop kind ${curType.kind}`);
			}
			// Map validators
			if (fieldParams.required) {
				field.validators.push(required);
				field.required = true;
				field.label += '*';
			}
			if (fieldParams.max) {
				field.validators.push(max(fieldParams.max, field));
			}
			if (fieldParams.min) {
				field.validators.push(min(fieldParams.min, field));
			}
			if (fieldParams.formatRegexStr) {
				field.validators.push(formatStr(fieldParams.formatRegexStr));
			}
			fields.push(field);
		});

	return globalSelectTransformQuery(fields);
}

/**
 * Funzione per caricare in IFields dei valori a partire da un risultato GraphQL, sia per filtri che per interfacce di modifica
 *
 * Nota bene: non funziona per tutti i casi, come per esempio con i SubForm e SubFormList
 *
 * @param fields: ReadonlyArray<IField>
 * @param entity: any
 * @param isFilter: boolean?
 */
const setFieldsValues = (
	fields: ReadonlyArray<IField>,
	entity: any,
	isFilter: boolean = false,
): IField[] =>
	fields.map((f) => {
		// sempre per rispettare l'immutabilita' clono l'IField originale
		const newObj = { ...f };

		// nel caso si tratti di una lista di entita' secondarie devo comunque arricchire il campo con l'entita' originale
		if (f.type === 'EntityList') {
			f.originalEntity = entity;
			f.props.originalEntity = entity;
			f.props.additionalVariables = {
				...f.props.additionalVariables,
				id: entity.id,
			};
		}

		let value = entity[f.name];
		if (value) {
			if (f.type === 'AsyncChoices') {
				if (f.single) {
					newObj.value = {
						label: entity2String(f.entityType, value),
						value,
					};
				} else {
					// faccio questo controllo perche' i filtri non hanno un formato coerente con quello dei field
					// quando vengono presi da un refresh pagina
					if (isFilter) {
						newObj.value = value;
					} else {
						newObj.value = value.map((v) => ({
							label: entity2String(f.entityType, v),
							value: v,
						}));
					}
				}
			} else if (f.type === 'EntityList') {
				newObj.value = value.map((v) => ({
					label: entity2String(f.entityLabel, v),
					value: v,
				}));
			} else if (f.type === 'Choices') {
				// caso dei filtri singoli su entita'
				// probabilmente non dovrebbe stare qui
				// i filtri hanno un valore del tipo stringa, uniti da una virgola

				if (isFilter && !f.single) {
					value = value.split(',');
				}

				if ((f.isEnum || isFilter) && value) {
					if (f.single) {
						value = { label: t(value), value: value };
					} else {
						value = value.map((a) => ({ label: t(a), value: a }));
					}
				}
				newObj.value = value;
			} else if (f.type === 'Date') {
				newObj.value = new Date(value);
			} else if (f.type === 'Boolean' && isFilter) {
				newObj.value = {
					value,
					label: t(value === 'true' ? 'Yes' : 'No'),
				};
			} else if (f.type === 'Int') {
				newObj.value = parseInt(entity[f.name], 10);
			} else {
				newObj.value = entity[f.name];
			}
		}

		return newObj;
	});

export { generateObjectFieldList, setFieldsValues };
