/**
 * Componente view di default dei sistemi di dettaglio entita'. In realta' si occupa solo di
 * smistare fra due altre view, una adeguata a form a tutta pagina (primo livello) e una adeguata ai form mostrati in
 * modali (secondo livello o piu').
 *
 * Per più informazioni rivolgiti alla pagina di documentazione `architettura_form.md`.
 */

import React from 'react';
import { IField } from '../types/form';
import { ENTITY } from '../utils/entities';
import { ViewContext } from '../utils/EntityViewContext';
import { Permission } from '../utils/permissions';
import { BreadcrumbItem } from '../types/ui';
import {
	EntityDetailView,
	IEntityDetailAction,
} from '../containers/EntityDetailView';
import { EntityDetailModalView } from '../containers/EntityDetailModalView';

// TODO Commentare tipizzazione props EntityDetailView
export interface EntityDetailViewProps {
	id: string; // l'id dell'entita' visualizzata
	entityName: ENTITY; // la tipologia di entita' visualizzata
	clonable?: boolean; // se e' possibile clonare l'entita. E' opzionale, ma credo venga settata a un valore di default a monte
	tabs?: string[];
	cols?: string[][];
	customActionComponents?: (entity: any) => JSX.Element[]; // TODO trasformare in componenti React
	showActions?: boolean;
	pre?: (entity: any) => JSX.Element | null; // TODO trasformare in componenti React
	post?: (entity: any) => JSX.Element | null; // TODO trasformare in componenti React
	modal?: boolean;
	hideHistory?: boolean | undefined;
	customActions?: IEntityDetailAction[]; // TODO passare tutto sotto customActionComponents
	customEditUrl?: (id: string) => string;
	permissions: Permission;
	data: { id: string; fields: ReadonlyArray<IField> };
	onDelete?: () => void;
	footer?: JSX.Element; // TODO attivare strict null check
	footerHTML?: string; // TODO attivare strict null check
	entity: any;
	breadcrumbs: ReadonlyArray<BreadcrumbItem>;
	title: string;
}

// ma domanda secca secca: cosi' sto creato tutte le volte da zero il componente? Questo potrebbe essere un grosso
// problema prestazionale e di stato..
// TODO Controllare se la definizione di componenti in EntityDetailViewSwitch non comporta gravi problemi prestazionali
export const EntityDetailViewSwitch: React.FC<EntityDetailViewProps> = (
	props,
) => (
	<ViewContext.Consumer>
		{(viewData) => {
			const Component =
				viewData?.type === 'modal'
					? EntityDetailModalView
					: EntityDetailView;

			return <Component {...props} />;
		}}
	</ViewContext.Consumer>
);
