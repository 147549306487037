import React, { useCallback, useMemo } from 'react';
import {
	Button,
	Form as BootstrapForm,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from 'reactstrap';
import gql from 'graphql-tag';
import { Form } from '../components/Form';
import { FieldList } from '../components/form/FieldsList';
import { SyncChoiceFieldType } from '../types/form';
import { generateBaseField, toChoices } from '../utils/form';
import { useQuery } from 'react-apollo';
import { nodes } from '../utils/misc';
import { /*AwardStatus,*/ AwardVisibility, Candidacy } from '../server-types';
import { t } from '../utils/labels';

interface CandidacyCloneListModalProps {
	open: boolean;
	toggle: () => void;
	onChose: (id: string) => void;
	currentAwardId: string;
	currentAwardVisibility: AwardVisibility;
}

const openShowcaseQuery = gql`
	query OpenShowcaseQuery {
		candidacies {
			edges {
				node {
					id
					name
					Award {
						id
						status
						visibility
						name
						edition
						year
						candidaciesCloseAt
					}
				}
			}
		}
	}
`;

export const CandidacyCloneListModal: React.FC<CandidacyCloneListModalProps> = ({
	open,
	onChose,
	currentAwardId,
	currentAwardVisibility,
	toggle,
}) => {
	const { error, loading, data } = useQuery(openShowcaseQuery, {
		fetchPolicy: 'network-only',
	});
	const selectedCandidacies =
		error || loading
			? []
			: nodes(data.candidacies).filter((c: Candidacy) => {
					if (
						c.Award.id !== currentAwardId &&
						c.Award.visibility === currentAwardVisibility
					) {
						console.log(
							new Date(c.Award.candidaciesCloseAt).getFullYear() -
								new Date().getFullYear(),
						);
					}
					return (
						c.Award.id !== currentAwardId &&
						c.Award.visibility === currentAwardVisibility &&
						new Date().getFullYear() -
							new Date(c.Award.candidaciesCloseAt).getFullYear() <=
							1
					);
			  });

	const fields = useMemo<ReadonlyArray<SyncChoiceFieldType>>(
		() => [
			toChoices(
				selectedCandidacies.map((c) => ({
					label: c.name + ' - ' + c.Award.edition + ' ' + c.Award.year,
					value: c.id,
				})),
				true,
			)(generateBaseField('candidacy', t`Prodotti`)),
		],
		[selectedCandidacies],
	);
	const submit = useCallback(
		(fields: any, values: any) => {
			onChose(values.candidacy);
		},
		[onChose],
	);

	return (
		<Modal isOpen={open} size={'lg'}>
			<ModalHeader>{t`Candidacy clone title ${currentAwardVisibility}`}</ModalHeader>
			<Form fields={fields} onSubmit={submit}>
				{({ fields, mutatorFactory, submit, values }) => (
					<>
						<ModalBody>
							{error && <>Error</>}
							{loading && <>Loading..</>}
							{!error && !loading && (
								<BootstrapForm className="Form">
									<FieldList fields={fields} setter={mutatorFactory} />
								</BootstrapForm>
							)}
						</ModalBody>
						<ModalFooter>
							<Button onClick={toggle}>Annulla</Button>
							<Button
								disabled={!values.candidacy}
								onClick={submit}
								color={'success'}
							>
								Clona
							</Button>
						</ModalFooter>
					</>
				)}
			</Form>
		</Modal>
	);
};
