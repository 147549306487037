function userKey(userId: string, key: string): string {
	return userId + '/' + key;
}

function set(userId: string, key: string, data): void {
	localStorage.setItem(userKey(userId, key), JSON.stringify(data));
}

const get = (userId: string, key: string): unknown => {
	const keyValue = localStorage.getItem(userKey(userId, key));
	if (keyValue) {
		return JSON.parse(keyValue);
	}
	return null;
};

export { set, get };
