import React from 'react';
import { AsyncChoicesField } from './AsyncChoices';
import { ListFilterType } from '../Filters';
import { AsyncChoiceFieldType, Entity } from '../../types/form';

export const AsyncChoicesFilter: React.FC<
	ListFilterType<Entity | ReadonlyArray<Entity>, AsyncChoiceFieldType>
> = ({ field, mutator }) => (
	<AsyncChoicesField
		path={field.name}
		field={{
			...field,
			compact: true,
		}}
		changeHandler={mutator}
	/>
);
