import gql from 'graphql-tag';


export const MagazineContentFullFragment = gql`
	fragment MagazineContentFullFragment on MagazineContent {
		id
		localId
		Magazine {
			id
			localId
			name
		}
		name
		pageNumber
	}

`;
