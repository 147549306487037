import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { EditorialTagListQuery } from '../graphql/query/EditorialTagListQuery';
import typeIntro from '../static/introspection/EditorialTag.json';
import EditorialTagFilters from '../static/introspection/EditorialTagFilterInput.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';

export const EditorialTagList = () => (
	<UserData>
		{({ isInternal }) => (
			<EntityListFetcher
				query={EditorialTagListQuery}
				entityName={ENTITY.EDITORIAL_TAG}
				headers={['name']}
				typeIntro={typeIntro}
				filtersIntro={EditorialTagFilters}
				filterFields={isInternal ? ['name'] : null}
			/>
		)}
	</UserData>
);
