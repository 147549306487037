import gql from 'graphql-tag';
import {GoodFeatureCertificationFullFragment} from "../fragments/GoodFeatureCertificationFullFragment";

export const GoodFeatureCertificationPatchMutation = gql`
	mutation GoodFeatureCertificationPatchMutation($id: ID!, $changes: GoodFeatureCertificationPatchInput!) {
		results: goodFeatureCertificationPatch(id: $id, changes: $changes) {
			...GoodFeatureCertificationFullFragment
		}
	}
	${GoodFeatureCertificationFullFragment}
`;


