import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { GeoIndicationListQuery } from '../graphql/query/GeoIndicationListQuery';
import typeIntro from '../static/introspection/GeoIndication.json';
import GeoIndicationFilters from '../static/introspection/GeoIndicationFilterInput.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';

export const GeoIndicationList = () => (
	<UserData>
		{({ isInternal }) => (
			<EntityListFetcher
				query={GeoIndicationListQuery}
				entityName={ENTITY.GEO_INDICATION}
				headers={[
					'name',
					'StateProvince',
					'euIndication',
					'italianIndication',
					'status',
				]}
				sortables={[
					'name',
					'StateProvince',
					'euIndication',
					'italianIndication',
					'status',
				]}
				typeIntro={typeIntro}
				filtersIntro={GeoIndicationFilters}
				filterFields={
					isInternal
						? [
								'name',
								'euIndications',
								'italianIndications',
								'Company',
								'Country',
								'StateProvince',
								'statuses',
						  ]
						: null
				}
			/>
		)}
	</UserData>
);
