import gql from 'graphql-tag';

export const questionSetListQuery = gql`
    query QuestionSetListQuery(
    $first: Int,
    $cursor: String,
    $filter: QuestionSetFilterInput
    ) {
        entities: questionSets(first: $first, after: $cursor, filter: $filter) {
            edges {
                node {
                    id
                    localId
                    sentDate
                    requests
                    
                    User {
                        id
                        name
                    }
                }
            }
            total
            pageInfo {
                hasNextPage
                hasPreviousPage
            }
        }

    }
`;
