import gql from 'graphql-tag';
import {MagazineCollectionFullFragment} from "../fragments/MagazineCollectionFullFragment";

export const MagazineCollectionCreateMutation = gql`
	mutation MagazineCollectionCreateMutation($values: MagazineCollectionCreateInput!) {
		results: magazineCollectionCreate(values: $values) {
			...MagazineCollectionFullFragment
		}
	}
	${MagazineCollectionFullFragment}
`;


