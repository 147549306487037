import gql from 'graphql-tag';
import {CompanyBadgeFullFragment} from "../fragments/CompanyBadgeFullFragment";

export const CompanyBadgeDetailQuery = gql`
	query CompanyBadgeDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...CompanyBadgeFullFragment
		}
	}
	${CompanyBadgeFullFragment}
`;


