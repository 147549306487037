import React, { useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { t } from '../../utils/labels';
import { ListFilterType } from '../Filters';
import { IntegerFieldType } from '../../types/form';

export const IntFilter: React.FC<ListFilterType<string, IntegerFieldType>> = ({
	field,
	mutator,
	size = 3,
	search,
	path,
}) => {
	const [localText, setLocalText] = useState<number>(field.value);

	return (
		<FormGroup className={`filter col-sm-${size}`}>
			<Input
				type={'number'}
				value={localText || null}
				onChange={(event) => {
					setLocalText(Number(event.target.value));
					mutator(event.target.value, path);
				}}
				placeholder={t(field.label)}
				className="col-sm-12"
				onKeyPress={(event) => event.key === 'Enter' && search()}
			/>
		</FormGroup>
	);
};
