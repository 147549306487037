import gql from 'graphql-tag';
import { PLManufacturerFullFragment } from 'graphql/fragments/PLManufacturerFullFragment';

export const PLManufacturerCreateMutation = gql`
	mutation PLManufacturerCreate($values: PLManufacturerCreateInput!) {
		results: PLManufacturerCreate(values: $values) {
			...PLManufacturerFullFragment
		}
	}
	${PLManufacturerFullFragment}
`;


