import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { GoodClassListQuery } from '../graphql/query/GoodClassListQuery';
import typeIntro from '../static/introspection/GoodClass.json';
import GoodClassFilters from '../static/introspection/SearchGoodClassInput.json';
import { ENTITY } from '../utils/entities';

export const GoodClassList = () => (
	<EntityListFetcher
		query={GoodClassListQuery}
		entityName={ENTITY.GOOD_CLASS}
		headers={['name']}
		typeIntro={typeIntro}
		filtersIntro={GoodClassFilters}
		filterFields={['name', 'Brand', 'Company']}
	/>
);
