import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { questionSetListQuery } from '../graphql/query/QuestionSetListQuery';
import typeIntro from '../static/introspection/QuestionSet.json';
import QuestionSetFilters from '../static/introspection/QuestionSetFilterInput.json';
import { ENTITY } from '../utils/entities';

export const QuestionSetList: React.FC<{}> = () => (
	<EntityListFetcher
		query={questionSetListQuery}
		entityName={ENTITY.QUESTION_SET}
		headers={['User', 'sentDate', 'requests']}
		filterFields={['User', 'Company']}
		typeIntro={typeIntro}
		filtersIntro={QuestionSetFilters}
	/>
);
