import gql from 'graphql-tag';

export const CouponFullFragment = gql`
	fragment CouponFullFragment on Coupon {
		id
		localId
		name
		code
		type
		amount
		startDate
		expirationDate
		maxUsages
		maxUsagesPerQuote
		usagesCount
		brandSf
		product2Sf
		brandAreaSf
	}
`;
