import gql from 'graphql-tag';
import { DataVizFullFragment } from 'graphql/fragments/DataVizFullFragment';

export const DataVizPatchMutation = gql`
	mutation DataVizPatchMutation($id: ID!, $changes: DataVizPatchInput!) {
		results: dataVizPatch(id: $id, changes: $changes) {
			...DataVizFullFragment
		}
	}
	${DataVizFullFragment}
`;


