import gql from 'graphql-tag';

export const GoodRecallListQuery = gql`
	query GoodRecallListQuery(
		$first: Int
		$cursor: String
	) {
		entities: GoodRecall(first: $first, after: $cursor, orderBy: publicationDate, orderDesc: true) {
			edges {
				node {
					id
					goodBranding
					Company {
						id
						name
					}
					GoodClass {
						id
						name
					}
					publicationDate
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;
