import React from 'react';
import { EditorialTagCreateMutation } from '../graphql/mutations/EditorialTagCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/EditorialTag.json';
import inputTypeIntro from '../static/introspection/EditorialTagCreateInput.json';
import { EditorialTagEditQuery } from '../graphql/query/EditorialTagEditQuery';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const EditorialTagCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(EditorialTagEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={EditorialTagCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.EDITORIAL_TAG}
		fields={['name']}
	/>
);
