import React, { useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { t } from '../../utils/labels';
import { ListFilterType } from '../Filters';
import { TextFieldType } from '../../types/form';

export const TextFilter: React.FC<ListFilterType<string, TextFieldType>> = ({
	field,
	mutator,
	size = 3,
	search,
	path,
}) => {
	const [localText, setLocalText] = useState(field.value);

	return (
		<FormGroup className={`filter col-sm-${size}`}>
			<Input
				value={localText || ''}
				onChange={(event) => {
					setLocalText(event.target.value);
					mutator(event.target.value, path);
				}}
				placeholder={t(field.label)}
				className="col-sm-12"
				onKeyPress={(event) => event.key === 'Enter' && search()}
			/>
		</FormGroup>
	);
};
