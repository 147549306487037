import React from 'react';
import { CompanyYearPatchMutation } from '../graphql/mutations/CompanyYearPatchMutation';
import { CompanyYearEditQuery } from '../graphql/query/CompanyYearEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/CompanyYear.json';
import inputTypeIntro from '../static/introspection/CompanyYearPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { EntityEditModal } from '../types/entities';
import { Company, CompanyYear } from '../server-types';

export const CompanyYearEdit: React.FC<EntityEditModal<
	CompanyYear,
	Company
>> = ({ id, onSubmit }) => (
	<EntityEditFetcher
		id={id}
		modal={true}
		onSubmit={onSubmit}
		fetcher={graphqlQuery(CompanyYearEditQuery, { id })}
		mutation={CompanyYearPatchMutation}
		entityName={ENTITY.COMPANY_YEAR}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		fields={['year', 'revenue', 'exportRevenue', 'ebitda', 'gain', 'mol', 'numEmployees']}
	/>
);
