import React from 'react';
import { ACTIONS, generateUrl } from '../../utils/urls';
import { ENTITY } from '../../utils/entities';
import { Redirect } from 'react-router';

interface ChecklistProps {
	awardId: string;
}

export const ChecklistAward: React.FunctionComponent<ChecklistProps> = ({
	awardId,
}) => <Redirect to={generateUrl(ENTITY.AWARD, ACTIONS.DETAIL, awardId)} />;

/*
import React from 'react';
import PageTitle from '../../components/PageTitle';
import { t } from '../../utils/labels';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Query } from 'react-apollo';
import { ACTIONS, generateUrl } from '../../utils/urls';
import { ENTITY } from '../../utils/entities';
import { ROLES, UserData } from '@food/auth';
import { Redirect } from 'react-router';
import { sentryHandler } from '../../utils/errors';
import { ChecklistAwardQuery } from '../../graphql/query/ChecklistAwardQuery';
import { Link } from 'react-router-dom';
import { entity2String } from '../../utils/toString';
import CONFIG from '../../static/config';

interface ChecklistProps {
	awardId: string;
}

export const ChecklistAward: React.FunctionComponent<ChecklistProps> = ({
	awardId,
}) => {
	return (
		<UserData>
			{({ isLogged, userData, loading }) => {
				if (loading) {
					return 'Loading...';
				}

				// non permetto la visualizzazione agli internal... sarebbe un casino visto che non ho una company prescelta
				if (userData.role === ROLES.INTERNAL) {
					return (
						<Card>
							<CardBody>
								<h1>{t`Error`}</h1>
								<h3>{t`Internal not allowed message`}</h3>
							</CardBody>
						</Card>
					);
				}

				const currentCompany =
					isLogged &&
					userData.managedByUsers &&
					userData.managedByUsers[0] &&
					userData.managedByUsers[0].Company;

				return (
					<Query
						query={ChecklistAwardQuery}
						variables={{ id: awardId, companyId: currentCompany.id }}
					>
						{({ error, loading, data }) => {
							if (error) {
								// potrebbe trattarsi di un premio con uno stato non disponibile, in tal caso mando a 404
								// strano il cast ad any che devo fare... forse la tipizzazione di apollo e' vecchia
								if (
									error.graphQLErrors &&
									error.graphQLErrors[0] &&
									(error.graphQLErrors[0] as any).status === 404
								) {
									return <Redirect to={'/404'} />;
								} else {
									// errore normale, lo mostro a console
									console.error(error);
									sentryHandler(error);
									return <>Error: {error.toString()}</>;
								}
							}
							if (loading) {
								return <>Loading...</>;
							}

							const award = data.award;
							const company = data.company;
							const goods = company.goods;
							const isShowcase = award.visibility === 'SHOWCASE';
							const eanRequired =
								award.options.indexOf('EAN_REQUIRED') !== -1;
							const printMediaRequired =
								award.options.indexOf('COMPANY_LOGOPRINT_REQUIRED') !==
								-1;
							const contractRequired =
								award.options.indexOf('SIGNED_CONTRACT_REQUIRED') !==
								-1;
							const hasSignedContract =
								award &&
								award.invitedCompanies &&
								award.invitedCompanies &&
								award.invitedCompanies.edges &&
								award.invitedCompanies.edges[0] &&
								award.invitedCompanies.edges[0].node.signedContract;

							const hasGoodsWithEAN = goods.some(
								(g) =>
									g.formats &&
									g.formats.length > 0 &&
									g.formats.some((f) => !!f.ean),
							);

							const hasGoods = goods.length > 0;

							const hasBrand =
								company && company.brands && company.brands.length > 0;

							const hasCandidacies =
								award.candidacies && award.candidacies.length > 0;

							const steps = [
								contractRequired && {
									name: 'has signed contract',
									conditions: [
										{
											value: !hasSignedContract,
											text: 'contract not signed',
										},
									],
									content: award.vademecumInvited,
									actionText: t`download contract`,
									actionUrl: award.contractMedia.origUrl,
								},
								{
									name: 'company completed',
									conditions: [
										{ value: !hasBrand, text: 'no brand present' },
										{
											value: !company.description,
											text: 'company description empty',
										},
										{
											value: !company.descriptionIta,
											text: 'company description ita empty',
										},
										{
											value: !company.descriptionLong,
											text: 'company description long empty',
										},
										{
											value: !company.descriptionLongIta,
											text: 'company description ita long empty',
										},
										{
											value: !company.logoMedia,
											text: 'no logoMedia on company',
										},
										{
											value:
												!company.companyCertifications ||
												company.companyCertifications.length === 0,
											text: 'no companyCertifications on company',
										},
										{
											value:
												printMediaRequired &&
												!company.logoPrintMedia,
											text: 'no logoPrintMedia on company',
										},
									],
									content: award.vademecumCompany,
									actionText: t`go to company profile`,
									actionUrl: generateUrl(
										ENTITY.COMPANY,
										ACTIONS.DETAIL,
										currentCompany.id,
									),
								},
								{
									name: 'has goods' + (eanRequired ? ' with ean' : ''),
									conditions: [
										{ value: !hasGoods, text: 'no goods in company' },
										{
											value: eanRequired && !hasGoodsWithEAN,
											text: 'no goods with EAN',
										},
									],
									content: award.vademecumGoods,
									actionText: t`go to goods list`,
									actionUrl: generateUrl(ENTITY.GOOD, ACTIONS.LIST),
								},
								{
									name: 'has candidacies',
									conditions: [
										{
											value: !hasCandidacies,
											text: 'no candidacies',
										},
									],
									content: award.vademecumCandidacies,
									actionText: t`insert candidacies`,
									actionUrl: generateUrl(
										ENTITY.AWARD,
										ACTIONS.DETAIL,
										award.id,
									),
								},
							].filter((e) => !!e);

							// questa variabile identifica il primo step non portato a termine e mi serve per colorare
							// di rosso il bottone della prima azione fallita e di grigio i successivi
							const firstFailedStep = steps.reduce(
								(res, { conditions }, i) =>
									res < 0 && !conditions.every((c) => !c.value)
										? i
										: res,
								-1,
							);
							const actionsColor = steps.map((s, i) => {
								if (i === firstFailedStep) {
									return 'danger';
								}
								if (i > firstFailedStep && firstFailedStep !== -1) {
									return 'secondary';
								}
								return 'success';
							});

							return (
								<>
									<PageTitle
										title={
											t`Checklist for` +
											' ' +
											entity2String(ENTITY.AWARD, award)
										}
										breadcrumbs={[
											{ label: t`home`, path: '/' },
											{
												label: isShowcase
													? t`AwardProducerPrivate/labelPlural`
													: t`AwardProducerPublic/labelPlural`,
												path: isShowcase
													? '/awards-producer-private'
													: '/awards-producer-public',
											},
											{
												label: entity2String(ENTITY.AWARD, award),
												path: generateUrl(
													ENTITY.AWARD,
													ACTIONS.DETAIL,
													award.id,
												),
											},
											{ label: t`Checklist` },
										]}
									/>
									<Row>
										<Col sm={8}>
											<Card>
												<CardBody>
													<h1>
														{entity2String(ENTITY.AWARD, award)} -{' '}
														{t`checklist`}
													</h1>

													{steps.map(
														(
															{ name, conditions, content },
															i,
														) => (
															<div key={name}>
																<hr />

																<h3>
																	<span className="capolettera">
																		{i + 1}.
																	</span>
																	{t(
																		`checklist step ${name} title`,
																	)}
																</h3>
																<p
																	dangerouslySetInnerHTML={{
																		__html:
																			content ||
																			t(
																				`checklist step ${name} html`,
																			),
																	}}
																/>

																{conditions.every(
																	(c) => !c.value,
																) && (
																	<h3
																		className={
																			'text-success checklist-info'
																		}
																	>
																		<i className="fa fa-check" />{' '}
																		{t(
																			`checklist step ${name} ok`,
																		)}
																	</h3>
																)}
																{conditions.some(
																	(c) => c.value,
																) &&
																	conditions
																		.filter((c) => c.value)
																		.map((c, i) => (
																			<h3
																				className={
																					'text-danger checklist-info'
																				}
																				key={i}
																			>
																				<i className="fa fa-times" />{' '}
																				{t(
																					'check failure ' +
																						c.text,
																					c.text,
																				)}
																			</h3>
																		))}
															</div>
														),
													)}

													<hr />

													<div className={'text-center'}>
														{/!* qui metto i pulsanti con le varie azioni per step *!/}
														{steps.map(
															({ actionText, actionUrl }, i) => (
																<Link
																	key={actionUrl}
																	to={actionUrl}
																	className={
																		'checklist-action btn btn btn-' +
																		actionsColor[i]
																	}
																>
																	{i + 1}. {actionText}
																</Link>
															),
														)}
													</div>
												</CardBody>
											</Card>
										</Col>
										<Col sm={4}>
											<Card>
												<CardBody>
													<h2 className={'float-right'} />
													<div
														style={{ marginBottom: '1.2rem' }}
													>{t`For any info write us at`}</div>
													<h2 className={'text-center'}>
														<a
															href={`mailto:${award.supportEmail ||
																CONFIG.SUPPORT_EMAIL}`}
														>
															<i className={'mdi mdi-email'} />{' '}
															{award.supportEmail ||
																CONFIG.SUPPORT_EMAIL}
														</a>
													</h2>
												</CardBody>
											</Card>
										</Col>
									</Row>
								</>
							);
						}}
					</Query>
				);
			}}
		</UserData>
	);
};
*/
