import gql from 'graphql-tag';
import { DataVizFullFragment } from 'graphql/fragments/DataVizFullFragment';

export const DataVizCreateMutation = gql`
	mutation dataVizCreate($values: DataVizCreateInput!) {
		results: dataVizCreate(values: $values) {
			...DataVizFullFragment
		}
	}
	${DataVizFullFragment}
`;


