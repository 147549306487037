import gql from 'graphql-tag';
import {NewsletterIssueFullFragment} from "../fragments/NewsletterIssueFullFragment";

export const NewsletterIssuePatchMutation = gql`
	mutation NewsletterIssuePatchMutation($id: ID!, $changes: NewsletterIssuePatchInput!) {
		results: newsletterIssuePatch(id: $id, changes: $changes) {
			...NewsletterIssueFullFragment
		}
	}
	${NewsletterIssueFullFragment}
`;


