import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import typeIntro from '../static/introspection/Media.json';
import MediaFilters from '../static/introspection/MediaFilterInput.json';
import { ENTITY } from '../utils/entities';
import { MediaListQuery } from '../graphql/query/MediaListQuery';
import { DefaultEntityListProps } from '../components/DefaultEntityList';
import { PageTitle } from '../components/PageTitle';
import { Filters } from '../components/Filters';
import { Card, CardBody, CardText } from 'reactstrap';
import { cns, IFNTheme, registerClass, currySC } from '@food/css-manager';

export const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	.${sc('caption')},
	.${sc('orig-file-name')} {
		display: block;
		text-align: center;
		line-height: 1.25;
		margin-bottom: 5px;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.${sc('orig-file-name')} {
		font-style: italic;
		color: ${t.colors.grey.plain};
		display: block;
		text-align: center;
	}
	
	.${sc('image-container')} {
		background-size: contain;
		padding-top: 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}
	
	.${sc('delete-action-container')} {
		text-align: right;
	}
	
	.${sc('card-body')} {
		cursor: pointer;
	}
`,
);

const sc = currySC(styleClass);

const MediaListView: React.FC<DefaultEntityListProps> = ({
	filters,
	setFilters,
	entityName,
	title,
	breadcrumbs,
	actions,
	records,
}) => {
	return (
		<div className={'list-entity'}>
			<PageTitle
				title={title}
				breadcrumbs={breadcrumbs}
				actions={actions}
				secondary={false}
			/>
			<Filters
				fields={filters}
				onSearch={setFilters}
				entityName={entityName}
			/>
			<div className={cns('media-container', styleClass)}>
				{records.map((m) => (
					<Card key={m.id}>
						<CardBody>
							<div
								className={cns(
									sc('image-container'),
									'image-hover-container',
								)}
								style={{
									backgroundImage: `url('${m.smallThumbUrl ||
										m.mediumThumbUrl ||
										m.largeThumbUrl ||
										m.origUrl}')`,
								}}
							/>
							<CardText>
								{m.name && (
									<span className={sc('caption')}>{m.name}</span>
								)}
								<span className={sc('orig-file-name')}>
									{m.origFileName}
								</span>
							</CardText>
						</CardBody>
					</Card>
				))}
			</div>
		</div>
	);
};

export const MediaList: React.FC = () => (
	<EntityListFetcher
		query={MediaListQuery}
		entityName={ENTITY.MEDIA}
		headers={['name', 'caption', 'timestamp']}
		sortables={[]}
		typeIntro={typeIntro}
		filtersIntro={MediaFilters}
		filterFields={['type', 'Company']}
		children={MediaListView}
	/>
);
