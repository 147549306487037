import React from 'react';
import { CompanyYearDetailQuery } from '../graphql/query/CompanyYearDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/CompanyYear.json';
import { ENTITY } from '../utils/entities';
import { EntityDetailModal } from '../types/entities';
import { Company } from '../server-types';

export const CompanyYearDetail: React.FC<EntityDetailModal<Company>> = ({
	id,
	onDelete,
}) => (
	<EntityDetailFetcher
		id={id}
		onDelete={onDelete}
		modal={true}
		query={CompanyYearDetailQuery}
		entityName={ENTITY.COMPANY_YEAR}
		typeIntro={typeIntro}
		clonable={false}
		fields={['year', 'revenue', 'exportRevenue', 'ebitda', 'gain', 'mol', 'numEmployees']}
	/>
);
