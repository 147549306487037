import gql from 'graphql-tag';

export const NewsletterIssueFullFragment = gql`
	fragment NewsletterIssueFullFragment on NewsletterIssue {
		id
		localId
		publicationDate
		status
		name
		hiddenIntro
		Newsletter {
			id
			localId
			name
			inputTemplate
		}
		values
	}
`;
