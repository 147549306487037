import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AuthContext } from '@food/auth';
import { LoggedLayout } from '../containers/SidebarLayout';
import { NotFoundComponent } from './404';
import { AuthRoutes } from '@food/auth';
import { authConfig, authManager, onRedirect } from '../utils/auth';
import { t } from '../utils/labels';
import { LoginContainer } from './LoginContainer';
import { ConsumerWelcome } from './pages/ConsumerWelcome';
import { BuyerWelcome } from './pages/BuyerWelcome';
import {ShopWorkerWelcome} from "./pages/ShopWorkerWelcome";
import {FoodServiceWelcome} from "./pages/FoodServiceWelcome";

export const AppLayout: React.FC = () => (
	<AuthContext>
		<Switch>
			<Route component={ConsumerWelcome} path={'/consumer-welcome'} />
			<Route component={BuyerWelcome} path={'/buyer-welcome'} />
			<Route component={ShopWorkerWelcome} path={'/shopworker-welcome'} />
			<Route component={FoodServiceWelcome} path={'/foodservice-welcome'} />
			<AuthRoutes
				config={authConfig}
				authManager={authManager}
				t={t}
				container={LoginContainer}
				onRedirect={onRedirect}
			>
				<Switch>
					<Route path="/" component={LoggedLayout} />
					<Route component={NotFoundComponent} />
				</Switch>
			</AuthRoutes>
		</Switch>
	</AuthContext>
);
