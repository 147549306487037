import React, { useCallback } from 'react';
import { PageTitle } from './PageTitle';
import { t } from '../utils/labels';
import { Form } from './Form';
import { Button, Card, CardBody, Form as BootstrapForm } from 'reactstrap';
import {
	CONTEXTS,
	ERRORS,
	generateError,
	modalHandler,
	sentryHandler,
} from '../utils/errors';
import { UserData } from '@food/auth';
import { ReportNotAllowed } from './ReportNotAllowed';
import { FieldList } from './form/FieldsList';
import { InternalCapability } from '../server-types';
import CONFIG from 'static/config';

export interface SimpleReportType {
	title: string;
	filename: string;
	endpoint: string;
	fields: any[];
	requirements: ReadonlyArray<InternalCapability>;
}

export const SimpleReport: React.FC<SimpleReportType> = ({
	title,
	filename,
	endpoint,
	requirements,
	fields
}) => {
	const submit = useCallback(async (values: any, user: any) => {
		try {
			console.log(values)
			const userToken = localStorage.getItem('auth_token');

			const query = '?' + Object.keys(values).map((key) => {
				return values[key] ? `${key}=${values[key]['value']}` : ''
			}).join('&')

			const response = await fetch(CONFIG.BASE_URL + endpoint + query, {
				method: 'GET',
				headers: {
				  'Authorization': userToken,
				  'X-Target-Site': 'HUB',
				  'referer': 'https://hub.italianfood.net',
				  'Content-Type': 'text/csv'
				}
			});

			if(!response.ok) throw new Error(`Request failed: ${await response.text()}`)

			const csvText = await response.text()

			const blob = new Blob([csvText], { type: 'text/csv' });
			const link = document.createElement('a');
			const url = URL.createObjectURL(blob);
			link.href = url;
			link.download = `${filename}.csv`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);
		} catch (e) {
			const error = generateError(ERRORS.GENERATION, CONTEXTS.REPORT, {
				originalError: e,
			});
			sentryHandler([error]);
			modalHandler([error]);
			console.error(e);
		}
	}, []);

	
	return (
		<UserData>
			{({ userData }) => {
				if (
					requirements.some(
						(c) => userData.internalCapabilities.indexOf(c) === -1,
					)
				) {
					return <ReportNotAllowed />;
				}

				return (
					<>
						<PageTitle
							title={title}
							breadcrumbs={[
								{ label: t`home`, path: '/' },
								{
									label: t`Reports`,
									path: '/reports',
								},
								{
									label: title,
								},
							]}
						/>
						<Card>
							<CardBody>
								<Form fields={fields}>
									{({ fields, mutatorFactory, values }) => (
										<BootstrapForm className="Form">
											<FieldList
												fields={fields}
												setter={mutatorFactory}
											/>
											<Button
												onClick={() => submit(values, userData)}
												size="lg"
												color="success"
											>
												<i className="mdi mdi-download" />{' '}
												{t`Download CSV`}
											</Button>
										</BootstrapForm>
									)}
								</Form>
							</CardBody>
						</Card>
					</>
				);
			}}
		</UserData>
	);
};
