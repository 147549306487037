import React from 'react';
import { GoodClassDetailQuery } from '../graphql/query/GoodClassDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/GoodClass.json';
import { ENTITY } from '../utils/entities';
import { transformerHandler } from '../utils/misc';
import { fastTransformer, refactorInfo, toAsyncRO } from '../utils/form';

export const GoodClassDetail = ({ id }) => (
	<EntityDetailFetcher
		query={GoodClassDetailQuery}
		entityName={ENTITY.GOOD_CLASS}
		id={id}
		typeIntro={typeIntro}
		clonable={true}
		fields={[
			'name',
			'nameIta',
			'akas',
			'level1Code',
			'level1Desc',
			'level1DescIta',
			'level2Code',
			'level2Desc',
			'level2DescIta',
			'level3Code',
			'level3Desc',
			'level3DescIta',
			'level4Code',
			'level4Desc',
			'level4DescIta',
			'shortname',
			'shortnameIta',
			'preservation',
			'trasformationLevel',
			'editorialSections',
		]}
		transformer={transformerHandler(
			fastTransformer({
				editorialSections: (f) => {
					if (f.type !== 'EntityList') {
						refactorInfo(f, 'EntityList');
						return f;
					}
					return {
						...toAsyncRO(f, ENTITY.EDITORIAL_SECTION),
					};
				},
			}),
		)}
	/>
);
