import React from 'react';
import { FrequentSearchCreateMutation } from '../graphql/mutations/FrequentSearchCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/FrequentSearch.json';
import inputTypeIntro from '../static/introspection/FrequentSearchCreateInput.json';
import { ENTITY } from '../utils/entities';
import { assign, transformerHandler } from '../utils/misc';
import { fastTransformer } from '../utils/form';
import { ACTIONS, generateUrl } from '../utils/urls';

const StaticFrequentSearchCreate = ({ id }) => (
	<EntityEditFetcher
		create={true}
		clone={false}
		id={id}
		mutation={FrequentSearchCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.FREQUENT_SEARCH}
		customDetailUrl={(id) =>
			generateUrl(ENTITY.FREQUENT_SEARCH, ACTIONS.LIST) + '/static/' + id
		}
		fields={[
			'name',
			'collection',
			'targetSites',
			'relatedFair',
			'nameIta',
			'goods',
			'companies',
			'logoMedia',
			'linkUrl',
			'linkRoles',
			'description',
		]}
		transformer={transformerHandler(
			fastTransformer({
				collection: assign({
					afterChange: (field, value, fields) => {
						return fastTransformer({
							goods: assign({
								disabled: !(value && value.value === 'GOOD'),
								value: [],
							}),
							companies: assign({
								disabled: !(value && value.value === 'COMPANY'),
								value: [],
							}),
						})(fields);
					},
				}),
				goods: assign({ disabled: true }),
				companies: assign({ disabled: true }),
			}),
		)}
	/>
);

export { StaticFrequentSearchCreate };
