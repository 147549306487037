import React from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { UserData } from '@food/auth';
import { ProducerHomeQuery } from '../graphql/query/ProducerHomeQuery';
import {
	CONTEXTS,
	modalHandler,
	parseGraphqlError,
	sentryHandler,
} from '../utils/errors';
import { PlatformHomeCard } from './PlatformHomeCard';
import { t } from '../utils/labels';
import { ACTIONS, generateUrl } from '../utils/urls';
import { ENTITY } from '../utils/entities';
import moment from 'moment';
import { AwardStatus, AwardVisibility } from '../server-types';
import { handleCandidaciesColor } from '../containers/AwardProducerDetail';

export const ProducerHome: React.FC<{}> = () => (
	<UserData>
		{({ managedCompanyIsItalian, loading }) => {
			if (loading) {
				return null;
			}
			return (
				<Query query={ProducerHomeQuery}>
					{(queryResProp) => {
						if (queryResProp.error) {
							const errors = parseGraphqlError(
								queryResProp.error,
								CONTEXTS.GRAPHQL,
							);
							sentryHandler(errors);
							modalHandler(errors);
							console.error(queryResProp.error);
							return null;
						}
						const { loading, data } = queryResProp;
						if (loading) {
							return null;
						}

						const currentCompany =
							data.currentUser &&
							data.currentUser.managedByUsers &&
							data.currentUser.managedByUsers[0] &&
							data.currentUser.managedByUsers[0].Company;

						if (!currentCompany) {
							return <>Compagnia assente - errore</>; // TODO
						}

						const openInvites = currentCompany.awardInvitedCompanies
							.filter(
								(a) => a.Award.status === AwardStatus.CandidancyOpen && a.Award.visibility !== AwardVisibility.Sourcing,
							)
							.sort(
								(a, b) =>
									new Date(a.Award.candidaciesCloseAt).getTime() -
									new Date(b.Award.candidaciesCloseAt).getTime(),
							);

						return (
							<Row>
								<Col sm={12}>
									<Card>
										<CardBody>
											<p>
												Benvenuto!
												<br />
												Se sei qui per partecipare a un Premio
												clicca Partecipa in uno dei box qua sotto.
											</p>
											<p>
												Per aggiornare azienda e prodotti usa il
												menu a sinistra.
											</p>
											<p>
												Se &egrave; la prima volta che arrivi qui
												la <Link to={'/faq'}>pagina di FAQ</Link>{' '}
												può aiutarti molto!
											</p>
										</CardBody>
									</Card>
									<Row>
										{openInvites.map((invite) => {
											let award = invite.Award;

											const currentMaxCandidacies = award
												? invite.maxPerCompanyCandidacies ||
												award.maxPerCompanyCandidacies
												: 0;

											const currentCandidacies = award
												? award.candidacies.length
												: 0;

											const dayTilClose = Math.max(
												moment(
													award ? award.candidaciesCloseAt : null,
												).diff(moment(), 'days'),
												0,
											);

											return (
												<Col key={award.id} sm={6}>
													<Card>
														<CardBody>
															<div className={'award-card-home'}>
																{award.logoMedia && (
																	<img
																		src={
																			award.logoMedia
																				.mediumThumbUrl
																		}
																		alt={'award logo'}
																	/>
																)}
																<div>
																	<h2>
																		{award.name} {award.year}
																	</h2>
																	{award.edition && (
																		<h4>{award.edition}</h4>
																	)}
																</div>
															</div>

															<div
																className={'award-card-home-2'}
															>
																<div
																	className={
																		'award-card-daytilclose'
																	}
																>
																	{t`days left to candidate`}:{' '}
																	<span>{dayTilClose}</span>
																</div>
																<div
																	className={
																		'award-card-daytilclose'
																	}
																>
																	{t(
																		award.visibility !==
																			AwardVisibility.Vote
																			? 'goods number'
																			: 'candidacies number',
																	)}
																	:{' '}
																	<span
																		className={handleCandidaciesColor(
																			currentCandidacies,
																			currentMaxCandidacies,
																		)}
																	>
																		{currentCandidacies}/
																		{currentMaxCandidacies}
																	</span>
																</div>
																<div>
																	<Link
																		className={
																			'btn btn-success btn-lg'
																		}
																		to={generateUrl(
																			ENTITY.AWARD,
																			ACTIONS.DETAIL,
																			award.id,
																		)}
																	>
																		{t`participate`}{' '}
																		<i className="fa fa-angle-double-right" />
																	</Link>
																</div>
															</div>
															{/*{signedContractRequired &&
																currentCandidacies > 0 && (
																	<div
																		className={
																			'text-center margin-top award-card-home-3'
																		}
																	>
																		{currentOpenInvite.signedContract && (
																			<h3
																				className={
																					'text-success'
																				}
																			>{t`signed contract message`}</h3>
																		)}
																		{!currentOpenInvite.signedContract && (
																			<h3
																				style={{
																					color: 'orange',
																				}}
																			>{t`not signed contract message`}</h3>
																		)}
																	</div>
																)}*/}
														</CardBody>
													</Card>
												</Col>
											);
										})}
									</Row>
								</Col>
								{(0) ? <>
									<Col sm={4}>
										{managedCompanyIsItalian && (
											<PlatformHomeCard
												platform={'IFN'}
												company={currentCompany}
											/>
										)}
									</Col></> : ''}
							</Row>
						);
					}}
				</Query>
			);
		}}
	</UserData>
);
