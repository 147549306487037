import gql from 'graphql-tag';
import {CompanyGroupFullFragment} from "../fragments/CompanyGroupFullFragment";

export const CompanyGroupEditQuery = gql`
	query CompanyGroupEditQuery($id: ID!) {
		entity: node (id: $id) {
				...CompanyGroupFullFragment
		  }
	}
	${CompanyGroupFullFragment}
`;


