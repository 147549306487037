import gql from 'graphql-tag';
import { CandidacyRatingFullFragment } from '../fragments/CandidacyRatingFullFragment';

export const candidacyRatingCreateMutation = gql`
	mutation CandidacyRatingCreateMutation(
		$values: CandidacyRatingCreateInput!
	) {
		results: candidacyRatingCreate(values: $values) {
			...CandidacyRatingFullFragment
		}
	}
	${CandidacyRatingFullFragment}
`;
