import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { ToSListQuery } from '../graphql/query/ToSListQuery';
import typeIntro from '../static/introspection/ToS.json';
import { ENTITY } from '../utils/entities';

export const ToSList = () => (
	<EntityListFetcher
		query={ToSListQuery}
		entityName={ENTITY.TERMS_OF_SERVICE}
		headers={['name']}
		typeIntro={typeIntro}
	/>
);
