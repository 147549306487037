import { t } from './labels';
import { toRefInput } from './dataTrasform';
import { generateAsyncQuery, generatePaginatedSelectQuery } from './graphql';
import { globalSelectTransformQuery } from './transformers';

const STOP_KINDS = ['SCALAR', 'ENUM', 'OBJECT', 'INPUT_OBJECT'];

export const generateFilterFieldList = (
	fieldNamesList,
	filterInputIntrospection,
	entityName,
) =>
	globalSelectTransformQuery(
		filterInputIntrospection
			.filter((f) => fieldNamesList.indexOf(f.name) !== -1)
			.map((f) => {
				// Map type
				let curType = f.type;
				let multiSelect = false;
				while (STOP_KINDS.indexOf(curType.kind) < 0 && curType.ofType) {
					if (curType.kind === 'LIST') {
						multiSelect = true;
					}
					if (curType.kind === 'NON_NULL') {
						// fieldParams.required = true;
					}
					curType = curType.ofType;
				}

				const { kind, ofType, name } = curType;
				const field: any = {
					name: f.name,
					label: t([entityName, f.name, 'filter', 'label'].join('/')),
				};

				switch (kind) {
					case 'SCALAR':
						switch (name) {
							case 'Int':
								field.type = 'Int';
								break;
							case 'String':
							case 'URL':
							case 'Email':
								field.type = 'Text';
								break;
							case 'DateTime':
								field.type = 'Date';
								break;
							case 'Boolean':
								field.type = 'Boolean';
								break;
							default:
								console.error(
									'Unsupported scalar type',
									name,
									kind,
									ofType,
								);
								break;
						}
						break;
					case 'INPUT_OBJECT':
						field.type = 'AsyncChoices';
						field.enum = false;
						field.single = !multiSelect;
						field.asyncQuery = generateAsyncQuery(
							generatePaginatedSelectQuery(field.name),
							true,
						);
						field.entityType = field.name;
						if (field.single) {
							field.beforeSaveTransformer = (option) =>
								option ? toRefInput(option.value) : null;
						} else {
							field.beforeSaveTransformer = (options) =>
								options ? options.map((o) => toRefInput(o.value)) : [];
						}
						break;
					case 'ENUM':
						field.type = 'Choices';
						field.enum = true;
						field.single = !multiSelect;
						field.entityType = curType.name;
						field.choices = curType.enumValues.map((c) => ({
							label: t([name, c.name, 'label'].join('/')),
							value: c.name,
						}));
						if (field.single) {
							field.beforeSaveTransformer = (option) =>
								option?.value || null;
						} else {
							field.beforeSaveTransformer = (options) =>
								options ? options.map((o) => o.value) : [];
						}
						break;
					default:
						throw new Error(`Unmanaged stop kind ${kind}`);
				}

				return field;
			}),
	);
