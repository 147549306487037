import { MenuItem } from '../static/menu';

const key = 'lastUsed';
const maxLength = 10;

const addToLastUsed = (voice: MenuItem): void => {
	const oldData = localStorage.getItem(key);
	let newArray = (oldData ? JSON.parse(oldData) : []) as MenuItem[];
	newArray = [voice, ...newArray.filter((v) => v.label !== voice.label)];
	const sliced = newArray.slice(0, maxLength);
	localStorage.setItem(key, JSON.stringify(sliced));
	return;
};

const getLastUsed = (): ReadonlyArray<MenuItem> => {
	const data = localStorage.getItem(key);
	const parsedData = data ? JSON.parse(data) : [];
	return parsedData as ReadonlyArray<MenuItem>;
};

export { addToLastUsed, getLastUsed };
