import gql from 'graphql-tag';

export const GoodFormatFullFragment = gql`
	fragment GoodFormatFullFragment on GoodFormat {
		id
		localId
		Good {
			id
			name
		}
		cuMesureUnit
		cuMesureValue
		cuPerSellingPack
		packaging
		ean
		unitsPerBox
		status
		flavor
		flavorIta
	}
`;
