import React from 'react';
import { Button, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { t } from '../utils/labels';
import { ACTIONS, generateUrl } from '../utils/urls';
import { ENTITY } from '../utils/entities';
import { useLocation } from '../utils/hooks';
import { parse } from 'querystring';

type EntityEditActionsProps = {
	entityName: ENTITY;
	create: boolean;
	id?: string;
	save: () => void;
};

export const EntityEditActions: React.FunctionComponent<EntityEditActionsProps> = ({
	entityName,
	create,
	id,
	save,
}) => {
	const { location } = useLocation();
	const params = parse(location.search.substr(1));
	let url: string = generateUrl(
		entityName,
		create ? ACTIONS.LIST : ACTIONS.DETAIL,
		id,
	);

	if (params.redirect) {
		if (Array.isArray(params.redirect)) {
			url = params.redirect[0];
		} else {
			url = params.redirect;
		}
	}

	return (
		<Col sm={12} className="text-right" data-section="entity-edit-actions">
			<Link to={url}>
				<Button
					color="secondary"
					className="margin-top margin-bottom margin-right"
					data-action="entity-edit-undo"
				>
					<i className="fa fa-times" /> {t`Undo`}
				</Button>
			</Link>
			<Button
				color="success"
				className="margin-top margin-bottom"
				onClick={save}
				data-action="entity-edit-save"
			>
				<i className="fa fa-save" /> {t`save`}
			</Button>
		</Col>
	);
};
