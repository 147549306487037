import gql from 'graphql-tag';
import {CompanyLevelFullFragment} from "../fragments/CompanyLevelFullFragment";

export const CompanyLevelDetailQuery = gql`
	query CompanyLevelDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...CompanyLevelFullFragment
		}
	}
	${CompanyLevelFullFragment}
`;


