import gql from 'graphql-tag';
import {CompanyYearFullFragment} from "../fragments/CompanyYearFullFragment";

export const CompanyYearEditQuery = gql`
	query CompanyYearEditQuery($id: ID!) {
		entity: node (id: $id) {
				...CompanyYearFullFragment
		  }
	}
	${CompanyYearFullFragment}
`;


