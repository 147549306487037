import React from 'react';
import { DistributionChannelDetailQuery } from '../graphql/query/DistributionChannelDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/DistributionChannel.json';
import { ENTITY } from '../utils/entities';
import { EntityDetailModal } from '../types/entities';
import { DistributionChannel } from '../server-types';
import { transformerHandler } from '../utils/misc';
import { fastTransformer } from '../utils/form';
import { TextFieldType } from '../types/form';

export const DistributionChannelDetail: React.FC<EntityDetailModal<
	DistributionChannel
>> = ({ id, onDelete, entityLabel, modal }) => (
	<EntityDetailFetcher
		query={DistributionChannelDetailQuery}
		entityName={ENTITY.DISTRIBUTION_CHANNEL}
		id={id}
		entityLabel={entityLabel}
		typeIntro={typeIntro}
		clonable={false}
		modal={modal}
		onDelete={onDelete}
		fields={[
			'countries',
			'mode',
			'channels',
			'catalogMedia',
			'contactEmail',
			'note',
		]}
		transformer={transformerHandler(
			fastTransformer({
				countries: (f) =>
					({
						...f,
						type: 'Text',
						value: f.value.map((o) => o.label).join(', '),
					} as TextFieldType),
			}),
		)}
	/>
);
