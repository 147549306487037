import React from 'react';
import { TeamPatchMutation } from '../graphql/mutations/TeamPatchMutation';
import { TeamEditQuery } from '../graphql/query/TeamEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Team.json';
import inputTypeIntro from '../static/introspection/TeamPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const TeamEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(TeamEditQuery, { id })}
		mutation={TeamPatchMutation}
		entityName={ENTITY.TEAM}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={['name', 'Retailer']}
	/>
);
