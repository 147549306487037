import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { CompanyListQuery } from '../graphql/query/CompanyListQuery';
import typeIntro from '../static/introspection/Company.json';
import CompanyFilters from '../static/introspection/CompanyFilterInput.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';

export const CompanyList = () => (
	<UserData>
		{({ isInternal }) => (
			<EntityListFetcher
				query={CompanyListQuery}
				entityName={ENTITY.COMPANY}
				headers={['name', 'website']}
				sortables={['name']}
				typeIntro={typeIntro}
				filtersIntro={CompanyFilters}
				filterFields={
					isInternal
						? [
								'name',
								'vatNumber',
								'producerChangedAfter',
								'Award',
								'hasLogo',
								'hasGoodsWithEan',
								'hasCandidacies',
								'profileCompleted',
						  ]
						: null
				}
			/>
		)}
	</UserData>
);
