import React from 'react';
import { UserData } from '@food/auth';
import EntityListFetcher from '../components/EntityListFetcher';
import { UserListQuery } from '../graphql/query/UserListQuery';
import typeIntro from '../static/introspection/User.json';
import UserFilters from '../static/introspection/UserFilterInput.json';
import { ENTITY } from '../utils/entities';

export const UserList = () => (
	<UserData>
		{({ isInternal }) => (
			<EntityListFetcher
				query={UserListQuery}
				entityName={ENTITY.USER}
				headers={['name', 'status', 'role']}
				sortables={['name', 'status', 'role']}
				typeIntro={typeIntro}
				filtersIntro={UserFilters}
				filterFields={
					isInternal ? ['name', 'email', 'roles', 'statuses'] : null
				}
				pageSize={20}
				customFields={[
					{
						header: 'email',
						field: (user) => ({
							name: 'email',
							type: 'Text',
							value:
								user.role === 'PRODUCER' ||
								user.role === 'BUYER' ||
								user.role === 'RETAILER'
									? user.workEmail || user.email
									: user.email,
						}),
					},
				]}
			/>
		)}
	</UserData>
);
