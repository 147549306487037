import gql from 'graphql-tag';
import {GoodFormatFullFragment} from "../fragments/GoodFormatFullFragment";

export const GoodFormatPatchMutation = gql`
	mutation GoodFormatPatchMutation($id: ID!, $changes: GoodFormatPatchInput!) {
		results: goodFormatPatch(id: $id, changes: $changes) {
			...GoodFormatFullFragment
		}
	}
	${GoodFormatFullFragment}
`;


