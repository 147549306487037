import gql from 'graphql-tag';
import {GeoIndicationFullFragment} from "../fragments/GeoIndicationFullFragment";

export const GeoIndicationPatchMutation = gql`
	mutation GeoIndicationPatchMutation($id: ID!, $changes: GeoIndicationPatchInput!) {
		results: geoIndicationPatch(id: $id, changes: $changes) {
			...GeoIndicationFullFragment
		}
	}
	${GeoIndicationFullFragment}
`;


