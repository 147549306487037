import gql from 'graphql-tag';

export const questionSetFullFragment = gql`
	fragment QuestionSetFullFragment on QuestionSet {
		id
		localId
		User {
			id
			name
		}
		sentDate
		question1
		requests
	}
`;
