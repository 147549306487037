import React from 'react';
import { assoc } from 'ramda';
import { CandidacyPatchMutation } from '../graphql/mutations/CandidacyPatchMutation';
import { CandidacyEditQuery } from '../graphql/query/CandidacyEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Candidacy.json';
import inputTypeIntro from '../static/introspection/CandidacyPatchInput.json';
import { BrandSelectQuery } from '../graphql/query/BrandSelectQuery';
import { GoodsInBrandsWithEANSelectQuery } from '../graphql/query/GoodsInBrandsWithEANSelectQuery';
import { required } from '../utils/validation';
import { toRefInput } from '../utils/dataTrasform';
import { ENTITY } from '../utils/entities';
import { t } from '../utils/labels';
import { generateFakeAsyncQuery, toRequired } from '../utils/misc';
import { generateAsyncQuery, graphqlQuery } from '../utils/graphql';
import { fastTransformer, refactorInfo } from '../utils/form';

export const CandidacyEdit = ({ id }) => (
	<EntityEditFetcher
		id={id}
		fetcher={graphqlQuery(CandidacyEditQuery, { id })}
		mutation={CandidacyPatchMutation}
		entityName={ENTITY.CANDIDACY}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entitiesToFields={(props) => ({
			certifications: props.goodFeatureCertifications,
		})}
		tabs={[
			t('generic data'),
			t([ENTITY.CANDIDACY, 'medias', 'label'].join('/')),
		]}
		fields={[
			'AwardCategory',
			'Brand',
			'goods',
			'featuredImageMedia',
			'extraMedia',
			'name',
			'range',
			'description1',
			'description2',
			'description3',
			'description4',
			'description5',
			'description6',
			'description7',
			'description8',
			'description9',
			'description10',
			'launchDate',
			'additionalImagesMedia',
		]}
		transformer={(data, entity) => {
			const award = entity.Award;
			const company = entity.Company;
			let brand = toRefInput(entity.Brand);
			let goods = entity.goods;

			const awardHasExtraMediaType = award.extraMediaType;
			const extraMediaRequired =
				award.options.indexOf('EXTRAMEDIA_REQUIRED') !== -1;

			const deducibleFields = {
				it_IT: {
					featuredImageMedia: 'featuredImageItaMedia',
					name: 'nameIta',
					range: 'range',
				},
				en_US: {
					featuredImageMedia: 'featuredImageMedia',
					name: 'name',
					range: 'range',
				},
			};

			const resetGoodFields = (field) => {
				if (
					Object.keys(deducibleFields[award.langCode]).indexOf(
						field.name,
					) !== -1
				) {
					field.value = null;
				}

				return field;
			};

			// le descrizioni possono essere nascoste a seconda del valore dell'Award a cui
			// si riferisce la Candidacy. Anche la loro lunghezza e' definita a livello
			// di campi dell'Award
			data.fields = data.fields.filter(
				(f) =>
					f.name.indexOf('description') === -1 ||
					!!award[f.name + 'Label'],
			);

			data.fields.forEach((f) => {
				switch (f.name) {
					case 'additionalImagesMedia':
						f.tab = 1;
						break;

					case 'AwardCategory':
						if (f.type !== 'AsyncChoices') {
							refactorInfo(f, 'AsyncChoices');
							break;
						}
						f.asyncQuery = generateFakeAsyncQuery(
							award.categories.filter((c) => !c.special),
						);
						break;

					case 'description1':
					case 'description2':
					case 'description3':
					case 'description4':
					case 'description5':
					case 'description6':
					case 'description7':
					case 'description8':
					case 'description9':
					case 'description10':
						const labelsPart = award[f.name + 'Label'].split('|');
						f.label = labelsPart[0].replace('$', '');
						f.helpText = labelsPart[1] || '';
						if (f.label.indexOf('*') !== -1) {
							f.required = true;
							f.validators = f.validators.concat([required]);
						}
						break;

					case 'Brand':
						if (f.type !== 'AsyncChoices') {
							refactorInfo(f, 'AsyncChoices');
							break;
						}
						f.asyncQuery = generateAsyncQuery(
							BrandSelectQuery,
							true,
							(name) => ({
								filter: {
									name,
									Company: toRefInput(company),
								},
							}),
						);
						f.afterChange = (ff, value, fields) => {
							const brandRef = toRefInput(value.value);
							return resetGoodFields(
								fastTransformer({
									goods: assoc(
										'asyncQuery',
										generateAsyncQuery(
											GoodsInBrandsWithEANSelectQuery,
											true,
											(name) => ({
												filter: {
													name,
													Brand: brandRef,
													eanPresent:
														award &&
														award.options.indexOf(
															'EAN_REQUIRED',
														) !== -1
															? true
															: undefined,
												},
											}),
										),
									),
								})(fields),
							);
						};
						break;

					case 'goods':
						if (f.type !== 'AsyncChoices') {
							refactorInfo(f, 'AsyncChoices');
							break;
						}
						if (award.langCode === 'it_IT') {
							f.myToString = (e) => e.nameIta;
							f.value = f.value.map((option) => ({
								label: f.myToString(option.value),
								value: option.value,
							}));
						}

						f.asyncQuery = generateAsyncQuery(
							GoodsInBrandsWithEANSelectQuery,
							true,
							(name) => ({
								filter: {
									name,
									Brand: toRefInput(brand),
									eanPresent:
										award &&
										award.options.indexOf('EAN_REQUIRED') !== -1
											? true
											: undefined,
								},
							}),
						);
						f.afterChange = (ff, value, fields) => {
							if (value.length !== goods.length) {
								goods = value;
								if (goods.length === 0) {
									return fields.map(resetGoodFields);
								} else if (goods.length === 1) {
									const good = value[0].value;
									return fields.map((f) => {
										if (
											Object.keys(
												deducibleFields[award.langCode],
											).indexOf(f.name) !== -1
										) {
											f.value =
												good[
													deducibleFields[award.langCode][f.name]
												];
										}
										return f;
									});
								}
								return fields;
							}
							return fields;
						};
						break;

					case 'extraMedia':
						if (f.type !== 'Media') {
							refactorInfo(f, 'Media');
							break;
						}
						const labels =
							award.extraMediaLabel && award.extraMediaLabel.split('|');
						if (labels) {
							f.label = labels[0];
							f.helpText = labels[1];
						}
						f.mediaType = awardHasExtraMediaType;
						if (extraMediaRequired) {
							toRequired(f, true);
						}
						break;

					default:
						break;
				}
			});

			return data;
		}}
	/>
);
