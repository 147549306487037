import gql from 'graphql-tag';
import {CompanyFullFragment} from "../fragments/CompanyFullFragment";


export const CompanyEditQuery = gql`
    query CompanyEditQuery($id: ID!) {
        entity: node (id: $id) {
				...CompanyFullFragment
		  }
    }
	${CompanyFullFragment}
`;



