import gql from 'graphql-tag';
import { FrequentSearchFullFragment } from '../fragments/FrequentSearchFullFragment';

export const StaticFrequentSearchEditQuery = gql`
	query FrequentSearchEditQuery($id: ID!) {
		entity: node(id: $id) {
			...FrequentSearchFullFragment
		}
	}
	${FrequentSearchFullFragment}
`;
