import React from 'react';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/DataViz.json';
import { ENTITY } from '../utils/entities';
import { DataVizDetailQuery } from 'graphql/query/DataVizDetailQuery';

const DataVizDetail = ({ id }) => (
	<EntityDetailFetcher
		query={DataVizDetailQuery}
		entityName={ENTITY.DATA_VIZ}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		fields={[
			'name',
			'title',
			'url',
			'source',
			'editorial_section',
			'targetSite'
		]}
		transformer={(data, entity) => {
			/*data.fields = fastTransformer({
				magazineContents: (f) =>
					Object.assign(f, {
						type: 'EntityList',
						headers: ['name', 'pageNumber'],
						editComponent: MagazineContentEdit,
						detailComponent: MagazineContentDetail,
						addComponent: MagazineContentCreate,
						tab: 1,
						originalEntity: entity,
						entityStructure: MagazineContentIntrospection,
					}),
			})(data.fields);
			*/
			return data;
		}}
		cols={[['col-sm-7', 'col-sm-5'], ['col-sm-12'], ['col-sm-12']]}
	/>
);

export { DataVizDetail };
