import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { CandidacyListQuery } from '../graphql/query/CandidacyListQuery';
import typeIntro from '../static/introspection/Candidacy.json';
import CandidacyFilters from '../static/introspection/CandidacyFilterInput.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';

export const CandidacyList: React.FC = () => (
	<UserData>
		{({ isInternal }) => (
			<EntityListFetcher
				query={CandidacyListQuery}
				entityName={ENTITY.CANDIDACY}
				customFields={[
					{
						header: 'Award',
						field: (entity) => ({
							name: 'Award',
							label: 'Award',
							type: 'Entity',
							mapTo: ENTITY.AWARD,
							value: entity.Award,
						}),
					},
					{
						header: 'Company',
						field: (entity) => ({
							name: 'Company',
							label: 'Company',
							type: 'Entity',
							mapTo: ENTITY.COMPANY,
							value: entity.Company,
						}),
					},
					{
						header: 'Category',
						field: (entity) => ({
							name: ENTITY.AWARD_CATEGORY,
							label: ENTITY.AWARD_CATEGORY,
							type: 'Entity',
							mapTo: ENTITY.AWARD_CATEGORY,
							value: entity.AwardCategory,
						}),
					},
				]}
				headers={['name']}
				sortables={['name']}
				typeIntro={typeIntro}
				filtersIntro={CandidacyFilters}
				filterFields={
					isInternal
						? [
								'name',
								'producerChangedAfter',
								'Brand',
								'Company',
								'Award',
						  ]
						: null
				}
			/>
		)}
	</UserData>
);
