import gql from 'graphql-tag';
import {UserFullFragment} from "../fragments/UserFullFragment";

export const UserCreateMutation = gql`
	mutation UserCreateMutation($values: UserCreateInput!) {
		results: userCreate(values: $values) {
			...UserFullFragment
		}
	}
	${UserFullFragment}
`;


