import gql from 'graphql-tag';

export const StoreChainFullFragment = gql`
	fragment StoreChainFullFragment on StoreChain {
		id
		localId
		name
		Retailer {
			id
			name
		}
		Foodservice {
			id
			name
		}
		Company {
			id
			name
		}
		Service {
			id
			name
		}
		goodClasses {
			id
			name
			nameIta
			shortname
			shortnameIta
		}
		countries {
			id
			name
		}
	}
`;
