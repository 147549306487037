import gql from 'graphql-tag';
import {AwardResultFullFragment} from "../fragments/AwardResultFullFragment";

export const AwardResultPatchMutation = gql`
	mutation AwardResultPatchMutation($id: ID!, $changes: AwardResultPatchInput!) {
		results: awardResultPatch(id: $id, changes: $changes) {
			...AwardResultFullFragment
		}
	}
	${AwardResultFullFragment}
`;


