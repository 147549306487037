import React from 'react';
import { t } from '../utils/labels';
import { MagazineDetailQuery } from '../graphql/query/MagazineDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/Magazine.json';
// import { BannerDetail } from '../containers/BannerDetail';
// import { BannerEdit } from '../containers/BannerEdit';
// import { BannerCreate } from '../containers/BannerCreate';
// import BannerIntrospection from '../static/introspection/Banner.json';
import MagazineContentIntrospection from '../static/introspection/MagazineContent.json';
import { ENTITY } from '../utils/entities';
import { fieldLabel } from '../utils/misc';
import { fastTransformer } from '../utils/form';
import { MagazineContentEdit } from './MagazineContentEdit';
import { MagazineContentDetail } from './MagazineContentDetail';
import { MagazineContentCreate } from './MagazineContentCreate';

const MagazineDetail = ({ id }) => (
	<EntityDetailFetcher
		query={MagazineDetailQuery}
		entityName={ENTITY.MAGAZINE}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		fields={[
			'MagazineCollection',
			'EditorialSection',
			'category',
			'publicationDate',
			'slug',
			'name',
			'title',
			'customHighlightDays',
			'issueNumber',
			'numOfTheYear',
			'numPages',
			'prefixPages',
			'customVisibilityType',
			'customVisibilityArgs',
			'fullUrl',
			'previewUrl',
			'dropboxPath',
			'Media',
			'previewMedia',
			'source',
			'externalSourceId',
			'featuredImageMedia',
			'featuredImageUrl',
			'featuredImageSizes',
			'magazineContents',
			'banners',
		]}
		transformer={(data, entity) => {
			data.fields = fastTransformer({
				/*banners: (f) =>
					Object.assign(f, {
						type: 'EntityList',
						headers: ['name'],
						editComponent: BannerEdit,
						detailComponent: BannerDetail,
						addComponent: BannerCreate,
						tab: 2,
						originalEntity: entity,
						entityStructure: BannerIntrospection,
					}),*/
				magazineContents: (f) =>
					Object.assign(f, {
						type: 'EntityList',
						headers: ['name', 'pageNumber'],
						editComponent: MagazineContentEdit,
						detailComponent: MagazineContentDetail,
						addComponent: MagazineContentCreate,
						tab: 1,
						originalEntity: entity,
						entityStructure: MagazineContentIntrospection,
					}),
			})(data.fields);
			return data;
		}}
		tabs={[
			'generic data',
			fieldLabel(ENTITY.MAGAZINE, 'magazineContents'),
			fieldLabel(ENTITY.MAGAZINE, 'banner'),
		].map((s) => t(s))}
		cols={[['col-sm-7', 'col-sm-5'], ['col-sm-12'], ['col-sm-12']]}
	/>
);

export { MagazineDetail };
