import gql from 'graphql-tag';
import {CompanyYearFullFragment} from "../fragments/CompanyYearFullFragment";

export const CompanyYearPatchMutation = gql`
	mutation CompanyYearPatchMutation($id: ID!, $changes: CompanyYearPatchInput!) {
		results: companyYearPatch(id: $id, changes: $changes) {
			...CompanyYearFullFragment
		}
	}
	${CompanyYearFullFragment}
`;


