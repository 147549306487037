import gql from 'graphql-tag';

export const CompanyListQuery = gql`
    query CompanyListQuery(
        $first: Int,
        $cursor: String,
        $orderBy: CompanyOrderBy,
        $orderDesc: Boolean,
        $filter: CompanyFilterInput
    ) {
        entities: companies(first: $first, after: $cursor, orderBy: $orderBy, orderDesc: $orderDesc, filter: $filter) {
            edges {
                node {
                    id
                    localId
                    name
                    website
                }
            }
            total
            pageInfo {
                hasNextPage
                hasPreviousPage
            }

        }
        entityDescription: __type(name: "Company") {
            description
        }
        statuses: __type(name:"ObjectStatus") {
            enumValues {
                name
            }
        }
    }
`;



