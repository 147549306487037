import React from 'react';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import { AwardCategoryDetailQuery } from '../graphql/query/AwardCategoryDetailQuery';
import typeIntro from '../static/introspection/AwardCategory.json';
import { ENTITY } from '../utils/entities';
import { Award } from '../server-types';
import { EntityDetailModal } from '../types/entities';

export const AwardCategoryDetail: React.FC<EntityDetailModal<Award>> = ({
	id,
	onDelete,
}) => (
	<EntityDetailFetcher
		id={id}
		onDelete={onDelete}
		query={AwardCategoryDetailQuery}
		entityName={ENTITY.AWARD_CATEGORY}
		modal={true}
		typeIntro={typeIntro}
		fields={[
			'name',
			'slug',
			'description',
			'special',
			'giftUrl',
			'Media',
			'color',
		]}
	/>
);
