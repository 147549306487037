/**
 * Componente per la visualizzazione a tutto schermo di liste di entita'.
 *
 * La catena di realizzazione liste e' sempre stata trascurata rispetto alle sue sorelle per dettaglio e manipolazione
 * entita'; a causa di questo fenomeno questa porzione di codice risulta datata e poco coerente col resto.
 * Qui non vengono usati IField ma un altro tipo vecchio di tipizzazione (molto simile), il componente e' basato su una
 * classe, le tipizzazioni sono vecchie... e cosi' via.
 *
 * TODO Reimplementare DefaultEntityList come componente funzionale
 *
 */

import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageTitle } from './PageTitle';
import { Paginator } from './Paginator';
import { Filters } from './Filters';
import { t } from '../utils/labels';
import { Button, Card, CardBody, Table } from 'reactstrap';
import { CellRenderer } from '../utils/list';
import { ACTIONS, generateUrl } from '../utils/urls';
import { hasAutoDetail } from '../utils/viewRoles';
// import { ColumnManager } from './columnOpener';
import { get, /*set*/ } from '../utils/localStorage';
import { ENTITY } from '../utils/entities';
import { ICustomField } from './EntityListFetcher';
import { UserDataInterface } from '@food/auth';
import { FieldsTransformer, IField } from '../types/form';
import { Permission } from '../utils/permissions';
import { Action, BreadcrumbItem } from '../types/ui';
import { cns } from '@food/css-manager';
import { setFieldsValues } from '../utils/objectFields';
import { identity } from 'ramda';
import { DeleteEntityModal } from './DeleteEntityModal';

export interface IPageInfo {
	slice: number;
	page: number;
	total: number;
	sortBy?: string | null;
	sortDirection?: string | null;
}

// TODO commentare tipizzazione props DefaultEntityList
export type DefaultEntityListProps = {
	headers: ReadonlyArray<string>;
	entityName: ENTITY;
	records: any[];
	customFields?: ReadonlyArray<ICustomField>;
	entityLabel?: string;
	identifier?: string;
	user?: UserDataInterface;
	title: string;
	breadcrumbs: BreadcrumbItem[];
	pre?: (
		entities: ReadonlyArray<any>,
		addModalOpener: (id?: string | null) => void,
	) => JSX.Element;
	post?: (
		entities: ReadonlyArray<any>,
		addModalOpener: (id?: string | null) => void,
	) => JSX.Element;
	sortables?: ReadonlyArray<string>;
	entities: any[];
	filters: IField[];
	pageInfo: IPageInfo;
	secondary?: boolean;
	permissions: Permission;
	setFilters: (val: any) => void;
	actions?: ReadonlyArray<Action>;
	detailUrl?: (entity: any) => string;
	detailModalMode?: boolean;
	hideTop?: boolean;
	hideBottom?: boolean;
	hideEmptyMessage?: boolean;
	customAddLabel?: string;
	hideTitle?: boolean;
	detailModalOpener?: (entity: any) => void;
	className?: string;
	inlineDelete?: boolean;
	hideDetailAction?: boolean;
	editOnly?: boolean;
	paginated?: boolean;
	onDelete?: (entity: any) => void;
	denyAdd?: boolean;
	canManageColumns?: boolean;
	changePage: (page: number) => void;
	transformer?: FieldsTransformer;
	/*changeOrder: (args: {
		sortBy: string;
		sortDirection: 'ASC' | 'DESC';
	}) => void;*/
	entityStructure: any;
	customEmptyComponent?: JSX.Element;
	addModalOpener?: (id?: string | null) => void;
	createModalMode?: boolean;
	customDeleteLabel?: string;
	refetch: () => void;
};

interface EntityListState {
	openColumns: string[];
	showDeleteConfirm: boolean;
	selectedEntity: any;
}

export class DefaultEntityList extends React.Component<
	DefaultEntityListProps,
	EntityListState
> {
	constructor(props) {
		super(props);

		const allHeaders = props.headers.concat(
			props.customFields ? props.customFields.map((c) => c.header) : [],
		);
		const visibleColumns =
			get(props.user.userData.id, props.identifier || props.entityName) ||
			props.openColumns ||
			allHeaders;
		console.log("visibleColumns", visibleColumns);
		this.state = {
			openColumns: visibleColumns || [],
			showDeleteConfirm: false,
			selectedEntity: undefined,
		};
	}

	_cellRenderer = (data: any, index: string) => {
		// faccio un cast a UserDataInterface nonostante possa essere null, perche' non caso pratico non si puo' entrare
		// in HUB senza essere loggati
		return (
			<td key={index}>
				{CellRenderer(data, this.props.user as UserDataInterface)}
			</td>
		);
	};

	/*toggleColumn = (header, selected) => {
		let newColumns = this.state.openColumns;
		const { identifier, entityName, user } = this.props;
		const id = identifier || entityName;

		if (selected) {
			newColumns.push(header);
		} else {
			newColumns = newColumns.filter((h) => h !== header);
		}

		// faccio un casta UserDataInterface nonostante possa essere null, perche' non caso pratico non si puo' entrare
		// in HUB senza essere loggati
		set((user as UserDataInterface).userData.id, id, newColumns);

		this.setState({
			openColumns: newColumns,
		});
	};*/

	render() {
		const {
			headers,
			records,
			breadcrumbs,
			title,
			pageInfo,
			filters,
			setFilters,
			customFields,
			entityStructure,
			transformer,
			permissions,
			entityName,
			entities,
			pre,
			post,
			changePage,
			secondary,
			paginated,
			addModalOpener,
			detailModalOpener,
			createModalMode,
			detailModalMode,
			hideDetailAction,
			denyAdd,
			detailUrl,
			// canManageColumns,
			actions,
			customEmptyComponent,
			user,
			hideTop,
			hideBottom,
			hideEmptyMessage,
			customAddLabel,
			hideTitle,
			className,
			inlineDelete,
			editOnly,
			customDeleteLabel,
			onDelete,
		} = this.props;

		const { openColumns } = this.state;
		// const columnNumber =
		// 	headers.length + (customFields ? customFields.length : 0);
		const addAction =
			createModalMode && addModalOpener
				? {
					name: customAddLabel || t`create new`,
					handler: () => addModalOpener(),
					icon: 'fa-plus',
				}
				: {
					name: customAddLabel || t`create new`,
					path: generateUrl(entityName, ACTIONS.CREATE),
					icon: 'fa-plus',
				};

		const entityLabel = this.props.entityLabel || this.props.entityName;
		let acs = actions || [];

		if (permissions.canCreate && !denyAdd) {
			acs = acs.concat(addAction);
		}

		// se ho solo un'entita' faccio un redirect diretto alla pagina di dettaglio
		if (
			pageInfo.total === 1 &&
			user !== undefined &&
			hasAutoDetail(user, entityName)
		) {
			return (
				<Redirect
					to={generateUrl(
						entityName,
						ACTIONS.DETAIL,
						records[0].original.id,
					)}
				/>
			);
		}

		// prendo gli header compresi i campi custom
		const completeHeaders = headers.concat(
			customFields ? customFields.map((c) => c.header) : [],
		);

		return (
			<div className={cns('list-entity', className)}>
				{!hideTitle && (
					<PageTitle
						title={title}
						breadcrumbs={breadcrumbs}
						actions={acs}
						secondary={secondary}
					/>
				)}
				{!hideTop && filters && filters.length > 0 && (
					<Filters
						fields={filters}
						onSearch={setFilters}
						entityName={entityName}
					/>
				)}
				{pre && addModalOpener && pre(entities, addModalOpener)}
				{(!records || records.length === 0) && !hideEmptyMessage && (
					<Card>
						<CardBody>
							{customEmptyComponent || (
								<h2>{t`empty list default message`}</h2>
							)}
						</CardBody>
					</Card>
				)}
				{records && records.length > 0 && (
					<Card>
						{!hideTop && (
							<div className="list-actions actions-top">
								{/*{canManageColumns !== false && columnNumber > 1 && (
									<ColumnManager
										headers={completeHeaders}
										visibles={openColumns}
										mutator={this.toggleColumn}
										entityLabel={entityLabel}
									/>
								)}*/}
								{paginated !== false && (
									<Paginator {...pageInfo} pageChanger={changePage} />
								)}
							</div>
						)}
						<CardBody>
							<Table>
								<thead>
									<tr>
										{completeHeaders.map((name) => (
											<th key={name}>
												{t([entityLabel, name, 'label'].join('/'))}
											</th>
										))}
										<th>{t`actions`}</th>
									</tr>
								</thead>
								<tbody>
									{records.map((entity) => {
										let baseFields = setFieldsValues(
											entityStructure,
											entity,
										);
										const fields = (transformer || identity)(
											baseFields,
										);

										const finalFields = fields.reduce(
											(obj, f) => {
												obj[f.name] = f;
												return obj;
											},
											{ original: entity },
										);

										return (
											<tr key={entity.id}>
												{headers
													.filter(
														(name) =>
															openColumns.indexOf(name) !== -1,
													)
													.map((h) =>
														this._cellRenderer(
															finalFields[h],
															entity.id + '-' + h,
														),
													)}
												{customFields &&
													customFields
														.filter(
															({ header }) =>
																openColumns.indexOf(header) !==
																-1,
														)
														.map(({ header, field }) =>
															this._cellRenderer(
																field(entity),
																header,
															),
														)}
												<td>
													<div className={'text-right'}>
														{!hideDetailAction && detailModalMode &&
															detailModalOpener && (
																<Button
																	size="sm"
																	color="success"
																	onClick={() =>
																		detailModalOpener(entity)
																	}
																>
																	<i
																		className={
																			'fa fa-' +
																			(editOnly
																				? 'pencil'
																				: 'search')
																		}
																	/>{' '}
																	{t(
																		editOnly
																			? 'edit'
																			: 'detail',
																	)}
																</Button>
															)}
														{!hideDetailAction && !detailModalMode && (
															<Link
																to={
																	detailUrl
																		? detailUrl(entity)
																		: generateUrl(
																			entityName,
																			ACTIONS.DETAIL,
																			entity.id,
																		)
																}
															>
																<Button
																	size="sm"
																	color="success"
																>
																	<i className="fa fa-search" />{' '}
																	{t`detail`}
																</Button>
															</Link>
														)}
														{inlineDelete && (
															<DeleteEntityModal
																entityId={entity.id}
																entityName={entityName}
																onDelete={() => {
																	if (onDelete) {
																		onDelete(entity);
																	}
																}}
																onFailedDelete={(e) => {
																	// TODO Implementare la gestione degli errori nella cancellazione inline in liste di entita'
																	console.error(e);
																}}
															>
																{(openDeleteModal) => (
																	<Button
																		size={'sm'}
																		color={'danger'}
																		className={'margin-left'}
																		onClick={openDeleteModal}
																	>
																		<i
																			className={
																				'fa fa-trash'
																			}
																		/>{' '}
																		{customDeleteLabel ||
																			t`delete` +
																			' ' +
																			t(entityLabel)}
																	</Button>
																)}
															</DeleteEntityModal>
														)}
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</CardBody>
						{!hideBottom && (
							<div className="list-actions actions-bottom">
								{paginated !== false && (
									<Paginator {...pageInfo} pageChanger={changePage} />
								)}
							</div>
						)}
					</Card>
				)}
				{post && addModalOpener && post(entities, addModalOpener)}
			</div>
		);
	}
}
