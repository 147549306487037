import gql from 'graphql-tag';
import {CompanyBadgeFullFragment} from "../fragments/CompanyBadgeFullFragment";

export const CompanyBadgeCreateMutation = gql`
	mutation CompanyBadgeCreateMutation($values: CompanyBadgeCreateInput!) {
		results: companyBadgeCreate(values: $values) {
			...CompanyBadgeFullFragment
		}
	}
	${CompanyBadgeFullFragment}
`;


