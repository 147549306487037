import gql from 'graphql-tag';
import {GoodClassFullFragment} from "../fragments/GoodClassFullFragment";

export const GoodClassCreateMutation = gql`
	mutation GoodClassCreateMutation($values: GoodClassCreateInput!) {
		results: goodClassCreate(values: $values) {
			...GoodClassFullFragment
		}
	}
	${GoodClassFullFragment}
`;


