import gql from 'graphql-tag';
import {EuCategoryFullFragment} from "../fragments/EuCategoryFullFragment";

export const EuCategoryPatchMutation = gql`
	mutation EuCategoryPatchMutation($id: ID!, $changes: EuCategoryPatchInput!) {
		results: euCategoryPatch(id: $id, changes: $changes) {
			...EuCategoryFullFragment
		}
	}
	${EuCategoryFullFragment}
`;


