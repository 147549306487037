import gql from 'graphql-tag';
import {CompanyYearFullFragment} from "../fragments/CompanyYearFullFragment";

export const CompanyYearCreateMutation = gql`
	mutation CompanyYearCreateMutation($values: CompanyYearCreateInput!) {
		results: companyYearCreate(values: $values) {
			...CompanyYearFullFragment
		}
	}
	${CompanyYearFullFragment}
`;


