import React from 'react';
import { Link } from 'react-router-dom';
import { getParameters } from '../utils/routeHistory';
import { BreadcrumbItem } from '../types/ui';

interface IBreadcrumbsProps {
	pages: ReadonlyArray<BreadcrumbItem>;
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ pages }) => (
	<ol className="breadcrumb">
		{pages.map((p) =>
			p.path ? (
				<li className="breadcrumb-item" key={p.label}>
					<Link
						to={{
							pathname: p.path,
							search: getParameters(p.path),
						}}
					>
						{p.label}
					</Link>
				</li>
			) : (
				<li className="breadcrumb-item active" key={p.label}>
					{p.label}
				</li>
			),
		)}
	</ol>
);
