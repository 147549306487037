import gql from 'graphql-tag';
import { MediaBaseFragment } from '../fragments/MediaBaseFragment';

export const MediaCreateMutation = gql`
    mutation MediaCreateMutation($values: MediaCreateInput!) {
        mediaCreate(values: $values) {
				...MediaBaseFragment
        }
    }
   ${MediaBaseFragment}
`;
