import gql from 'graphql-tag';

export const AwardResultFullFragment = gql`
	fragment AwardResultFullFragment on AwardResult {
		id
		localId
		AwardCategory {
			id
			name
		}
		Candidacy {
			id
			name
		}
		finalPosition
		legacyCandidacyName
		legacyCompanyName
	}
`;
