import React from 'react';
import { AwardCategoryCreateMutation } from '../graphql/mutations/AwardCategoryCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/AwardCategory.json';
import inputTypeIntro from '../static/introspection/AwardCategoryCreateInput.json';
import { toRefInput } from '../utils/dataTrasform';
import { ENTITY } from '../utils/entities';
import { fastTransformer, toHidden } from '../utils/form';
import { transformerHandler } from '../utils/misc';
import { Award, AwardCategory } from '../server-types';
import { EntityCreateModal } from '../types/entities';

export const AwardCategoryCreate: React.FC<EntityCreateModal<
	AwardCategory,
	Award
>> = ({ originalEntity, onSubmit }) => (
	<EntityEditFetcher
		onSubmit={onSubmit}
		create={true}
		modal={true}
		mutation={AwardCategoryCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.AWARD_CATEGORY}
		fields={[
			'name',
			'slug',
			'description',
			'special',
			'giftUrl',
			'Media',
			'color',
			'Award',
		]}
		transformer={transformerHandler(
			fastTransformer({
				Award: toHidden(toRefInput(originalEntity)),
			}),
		)}
	/>
);
