import gql from 'graphql-tag';

export const TeamFullFragment = gql`
	fragment TeamFullFragment on Team {
		id
		localId
		name
		Retailer {
			id
			name
		}
		users {
			id
			name
		}
	}
`;
