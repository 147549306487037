import gql from 'graphql-tag';
import {GoodClassFullFragment} from "../fragments/GoodClassFullFragment";

export const GoodClassEditQuery = gql`
	query GoodClassEditQuery($id: ID!) {
		entity: node (id: $id) {
				...GoodClassFullFragment
		  }
	}
	${GoodClassFullFragment}
`;


