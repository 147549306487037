import React from 'react';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/DataViz.json';
import inputTypeIntro from '../static/introspection/DataVizCreateInput.json';
import { generateAsyncQuery, graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';
import { DataVizEditQuery } from 'graphql/query/DataVizEditQuery';
import { DataVizCreateMutation } from 'graphql/mutations/DataVizCreateMutation';
import { refactorInfo } from 'utils/form';
import { EditorialSectionSelectQuery } from 'graphql/query/EditorialSectionSelectQuery';

const DataVizCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(DataVizEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={DataVizCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.DATA_VIZ}
		fields={[
			'targetSite',
			'name',
			'title',
			'url',
			'source',
			'editorial_section',
		]}
		transformer={(data) => {
			data.fields.forEach((f) => {
				switch (f.name) {
					case 'targetSite':
						f.afterChange = (ff, value, fields) => {
							return updateDataVizEditorialSectionSelectableValues(fields);
						}
						break;
					default:
						break;
				}
			});

			data.fields = updateDataVizEditorialSectionSelectableValues(data.fields);
			return data;
		}}
	/>
);

export { DataVizCreate };

export const updateDataVizEditorialSectionSelectableValues: any = (fields: any, updateValues: boolean = true) => {
	const TargetSiteField = fields.filter((f) => f.name == 'targetSite')[0];
	const editorialSectionField = fields.filter((f) => f.name == 'editorial_section')[0];
	if (TargetSiteField.value && TargetSiteField.value.value) {
		if (editorialSectionField.type !== 'AsyncChoices') {
			refactorInfo(editorialSectionField, 'AsyncChoices');
		} else {
			editorialSectionField.asyncQuery = generateAsyncQuery(
				EditorialSectionSelectQuery,
				true,
				(value) => {
					return {
						filter: {
							name: value,
							EditorialBrand: DataVizEditorialSectionBrand2EditorialBrand(TargetSiteField.value.value)
						},
					};
				},
			);
		}

		editorialSectionField.disabled = false;
	} else {
		editorialSectionField.disabled = true;
	}
	return fields;
}

export const DataVizEditorialSectionBrand2EditorialBrand: any = (editorialSectionBrand: string) => {
	switch (editorialSectionBrand) {
		case 'VOTE':
		case 'NEWSLETTER':
		case 'SOURCINGSUPPLIER':
		case 'SOURCINGBUYER':
			return null;
		case 'FOODWEB':
			return 'FOOD';
		case 'HUB':
			return 'GOODS4HUB';
		case 'FOODSERVICEWEB':
			return 'FOODSERVICE';
		default:
			return editorialSectionBrand;
	}
}