import gql from 'graphql-tag';
import { Candidacy_producer_FullFragment } from '../fragments/Candidacy_producer_FullFragment';

export const Candidacy_producer_CreateMutation = gql`
	mutation CandidacyCreateMutation($values: CandidacyCreateInput!) {
		results: candidacyCreate(values: $values) {
			...Candidacy_producer_FullFragment
		}
	}
	${Candidacy_producer_FullFragment}
`;
