import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { FairListQuery } from '../graphql/query/FairListQuery';
import typeIntro from '../static/introspection/Fair.json';
import FairFilters from '../static/introspection/FairFilterInput.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';

export const FairList = () => (
	<UserData>
		{({ isInternal }) => (
			<EntityListFetcher
				query={FairListQuery}
				entityName={ENTITY.FAIR}
				headers={['name', 'year', 'edition', 'Country']}
				typeIntro={typeIntro}
				filtersIntro={FairFilters}
				filterFields={
					isInternal ? ['name', 'isConcluded', 'Country'] : null
				}
			/>
		)}
	</UserData>
);
