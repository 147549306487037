import React from 'react';
import { EmailDomainDetailQuery } from '../graphql/query/EmailDomainDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/EmailDomain.json';
import { ENTITY } from '../utils/entities';

export const EmailDomainDetail = ({ id }) => (
	<EntityDetailFetcher
		query={EmailDomainDetailQuery}
		entityName={ENTITY.EMAIL_DOMAIN}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		myToString={(entity) => entity.emailDomain}
		fields={[
			'emailDomain',
			'accountType',
			'action',
			'Retailer',
			'Company',
			'CompanyGroup',
			'Service',
			'FoodService',
			'Team',
			'website',
			'accountSfid',
			'note',
		]}
	/>
);
