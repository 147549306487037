import gql from 'graphql-tag';
import {FoodServiceFullFragment} from "../fragments/FoodServiceFullFragment";

export const FoodServiceDetailQuery = gql`
	query FoodServiceDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...FoodServiceFullFragment
		}
	}
	${FoodServiceFullFragment}
`;