import React from 'react';
import { MediaPatchMutation } from '../graphql/mutations/MediaPatchMutation';
import { MediaEditQuery } from '../graphql/query/MediaEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Media.json';
import inputTypeIntro from '../static/introspection/MediaPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const MediaEdit = (props) => (
	<EntityEditFetcher
		{...props}
		fetcher={graphqlQuery(MediaEditQuery, { id: props.id })}
		mutation={MediaPatchMutation}
		entityName={ENTITY.MEDIA}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		modal={true}
		fields={['caption']}
	/>
);
