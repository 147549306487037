/**
 * Questo componente viene utilizzato all'interno del sistema dei form per creare/modificare/clonare entita'.
 * Il suo compito specifico e' quello di istanziare il componente Form e di collegarlo con Apollo per avviare una
 * mutation all'invio del form.
 *
 * Si occupa anche di gestire la risposta di questa mutation. Il caso piu' interessante e' quello di errori lato server,
 * dove il componente si occupa di trasformare gli esoterici errori restituiti dal server in un formato compatibile con
 * quello usato lato client all'interno del form (gli errori di validazione).
 *
 * All'interno vi sono porzioni di codice che sarebbe meglio mettere altrove ma che, per limiti nell'architettura
 * complessiva, conviene stiano qui. Sono corredati da commenti.
 *
 * Per più informazioni rivolgiti alla pagina di documentazione `architettura_form.md`
 */

import React, { useCallback, useState } from 'react';
import { identity, flatten } from 'ramda';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import {
	modalHandler,
	parseGraphqlError,
	sentryHandler,
} from '../utils/errors';
import { ACTIONS, generateUrl } from '../utils/urls';
import { client } from '../utils/client';
import { FormValidationErrorT, IField } from '../types/form';
import { track } from '@food/tracking';
import { parse } from 'querystring';
import { ENTITY } from '../utils/entities';
import { Form } from '../components/Form';
import { t } from '../utils/labels';

// TODO commentare tipizzazione props in FunctionalEntityEdit
interface EntityEditProps {
	// lista di IField rappresentanti gli attributi dell'entita'
	// notare la differenza con lo step precedente della catena, EntityEditFetcher, dove fields era solo una lista di stringhe
	fields: ReadonlyArray<IField>;
	mutation: any; // la mutation graphql specificata nel configuratore
	create: boolean; // determina se siamo in una creazione o modifica entita'; determina effetti su come vengono passati i dati alla mutation
	entityName: ENTITY; // il tipo di entita' che stiamo modificando/creando
	beforeSaveTransformer?: (values: any) => any;
	id?: string; // id dell'entita' da gestire, presente in clonazione e modifica ma non in creazione
	customDetailUrl?: (id: string) => string;
	modal?: boolean; // se ci si trova all'interno di una modale
	onSubmit?: (mutationResult: any) => void;
	children: (
		onSubmit: () => void,
		mutatorFactory: any,
		fields: ReadonlyArray<IField>,
		validationModalOpen: boolean,
		toggleValidationModal: () => void,
		isChanged: boolean,
	) => JSX.Element;
}


// questa e' la funzione citata prima, la quale converte il formato degli errori passato dal server in quello utilizzato internamente
// TODO Tipizzare gli errori che possono arrivare dal server
const parseServerValidationErrors = (
	errors: any,
): { [key: string]: ReadonlyArray<Error> } => {
	const serverErrors: { [key: string]: Error[] } = {};
	const errorDescriptions: ReadonlyArray<FormValidationErrorT> = flatten(
		errors.map((e) => e.state),
	);
	errorDescriptions.forEach((errDesc: FormValidationErrorT) => {
		if (!(errDesc.key in serverErrors)) {
			serverErrors[errDesc.key] = [];
		}
		serverErrors[errDesc.key].push(new Error(errDesc.type));
	});
	return serverErrors;
};

export const FunctionalEntityEdit: React.FC<EntityEditProps> = ({
	children,
	fields,
	modal,
	mutation,
	id,
	customDetailUrl,
	create,
	entityName,
	onSubmit,
	beforeSaveTransformer,
}) => {
	const [validationModalOpen, setValidationModalOpen] = useState(false);
	// isChanged viene utilizzato per capire se e' necessario bloccare il cambio pagina; una volta si chiamava
	// "isBlocking", ma questo nome mi sembra piu' parlante per le props che deve passare al proprio figlio; decideranno
	// poi le eventuali modali figlie se bloccare o meno.
	const [isChanged, setIsChanged] = useState(false);
	const history = useHistory();
	const location = useLocation();

	const graphqlMutationSubmit = useCallback(
		async (fields, values, injectError) => {
			const finalValues = (beforeSaveTransformer || identity)(values);
			try {
				const saveResult = await client.mutate({
					mutation,
					variables: create
						? {
							values: finalValues,
						}
						: {
							id,
							changes: finalValues,
						},
				});

				/**
				 * Mi vedo costretto a inserire l'effetto collaterale del submit su pagine principali direttamente nel
				 * componente che vorrebbe essere generico. E' brutto hardcodare questo effetto qui, ma attualmente abbiamo
				 * dubbi su come gestire gli "onSubmit" a livello generale; siccome metterlo manualmente su ogni componente
				 * richiederebbe uno sforzo notevole questa e' la scelta piu' pragmatica a mio avviso.
				 *
				 * Idealmente, tutto il codice passato dentro questo if andrebbe rimosso e specificato solo sul submit per
				 * entita' di primo livello gerarchico.
				 */
				setIsChanged(false);
				if (!modal) {
					const entity = (saveResult.data as any).results;
					const params = parse(location.search.substr(1));
					let url;

					if (params.redirect) {
						url = params.redirect;
					} else if (customDetailUrl) {
						url = customDetailUrl(entity.id);
					} else {
						url = generateUrl(entityName, ACTIONS.DETAIL, entity.id);
					}
					history.push(url);
				}
				// --

				const result = (saveResult.data as any).results;

				if (onSubmit) {
					onSubmit(result);
				}
				return result;
			} catch (e) {
				const errContext = { entityName };
				const errs = parseGraphqlError(e, errContext);
				sentryHandler(errs);
				modalHandler(errs);
				track({
					event: 'form validation error',
					errors: e,
					errorContext: entityName,
					entityId: id,
					isModal: modal,
					creation: Boolean(create),
					pathname: location.pathname,
					search: location.search,
				});
				if (e.graphQLErrors) {
					injectError(parseServerValidationErrors(e.graphQLErrors));
				}
				return false;
			}
		},
		[id, create, onSubmit, setIsChanged],
	);

	return (
		<>
			<Prompt
				when={isChanged}
				message={() => t`question_confirm_leaving_unsaved_changes`}
			/>
			<Form
				fields={fields}
				onChange={() => {
					// si, sarebbe meglio un cambiamento basato sugli effettivi valori del form, cosi' che un ritorno allo
					// stato iniziale non bloccasse la pagina; magari un domani..
					// TODO migliorare il controllo sui valori del form per bloccare il cambio pagina
					if (!isChanged) {
						setIsChanged(true);
					}
				}}
				onSubmit={graphqlMutationSubmit}
				create={create}
				onFailedSubmit={(fields, errors) => {
					console.log(errors);
					setValidationModalOpen(true);
				}}
			>
				{({ submit, mutatorFactory, fields }) =>
					children(
						submit,
						mutatorFactory,
						fields,
						validationModalOpen,
						() => setValidationModalOpen(false),
						isChanged,
					)
				}
			</Form>
		</>
	);
};
