import gql from 'graphql-tag';
import {CompanyCertificationFullFragment} from "../fragments/CompanyCertificationFullFragment";

export const CompanyCertificationCreateMutation = gql`
	mutation CompanyCertificationCreateMutation($values: CompanyCertificationCreateInput!) {
		results: companyCertificationCreate(values: $values) {
			...CompanyCertificationFullFragment
		}
	}
	${CompanyCertificationFullFragment}
`;


