import gql from 'graphql-tag';
import {EditorialTagFullFragment} from "../fragments/EditorialTagFullFragment";

export const EditorialTagDetailQuery = gql`
	query EditorialTagDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...EditorialTagFullFragment
		}
	}
	${EditorialTagFullFragment}
`;


