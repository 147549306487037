import React, { useCallback, useState } from 'react';

interface EntityModalStateProps {
	children: (
		modalState: EntityModalStateEnum,
		setModalState: (s: EntityModalStateEnum, cloneId?: string | null) => void,
		cloneId: string | null | undefined,
	) => JSX.Element;
}

export enum EntityModalStateEnum {
	Closed,
	Detail,
	Edit,
	Create,
}

export const EntityModalState: React.FC<EntityModalStateProps> = ({
	children,
}) => {
	const [{ modalState, cloneId }, setState] = useState<{
		modalState: EntityModalStateEnum;
		cloneId: string | null;
	}>({ modalState: EntityModalStateEnum.Closed, cloneId: null });

	const easySetState = useCallback(
		(s: EntityModalStateEnum, cloneId?: string | null) => {
			setState({
				modalState: s,
				cloneId,
			});
		},
		[setState],
	);

	return children(modalState, easySetState, cloneId);
};
