import gql from 'graphql-tag';

export const MagazineCollectionListQuery = gql`
	query MagazineCollectionListQuery(
		$first: Int
		$cursor: String
		$filter: MagazineCollectionFilterInput
	) {
		entities: magazineCollections(
			first: $first
			after: $cursor
			filter: $filter
		) {
			edges {
				node {
					id
					localId
					name
					BrandSf
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;


