import React from 'react';
import { EmailDomainCreateMutation } from '../graphql/mutations/EmailDomainCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/EmailDomain.json';
import inputTypeIntro from '../static/introspection/EmailDomainCreateInput.json';
import { EmailDomainEditQuery } from '../graphql/query/EmailDomainEditQuery';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';

export const EmailDomainCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(EmailDomainEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={EmailDomainCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.EMAIL_DOMAIN}
		fields={[
			'emailDomain',
			'accountType',
			'action',
			'Retailer',
			'Company',
			'CompanyGroup',
			'Service',
			'FoodService',
			'Team',
			'website',
			'accountSfid',
			'note',
		]}
	/>
);
