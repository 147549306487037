import gql from 'graphql-tag';
import { MediaBaseFragment } from './MediaBaseFragment';

export const MagazineFullFragment = gql`
	fragment MagazineFullFragment on Magazine {
		id
		localId
		MagazineCollection {
			id
			localId
			name
			BrandSf
		}
		category
		publicationDate
		slug
		name
		title
		customHighlightDays
		issueNumber
		numOfTheYear
		numPages
		prefixPages
		customVisibilityType
		customVisibilityArgs
		fullUrl
		previewUrl
		dropboxPath
		Media {
			...MediaBaseFragment
		}
		previewMedia {
			...MediaBaseFragment
		}
		source
		externalSourceId
		featuredImageMedia {
			...MediaBaseFragment
		}
		featuredImageUrl
		featuredImageSizes
		magazineContents {
			id
			localId
			name
			pageNumber
		}
		banners {
			id
			localId
			name
		}
	}
	${MediaBaseFragment}
`;
