import gql from 'graphql-tag';
import {ToSFullFragment} from "../fragments/ToSFullFragment";

export const ToSCreateMutation = gql`
	mutation ToSCreateMutation($values: ToSCreateInput!) {
		results: toSCreate(values: $values) {
			...ToSFullFragment
		}
	}
	${ToSFullFragment}
`;


