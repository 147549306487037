import gql from 'graphql-tag';
import {AwardResultFullFragment} from "../fragments/AwardResultFullFragment";

export const AwardResultDetailQuery = gql`
	query AwardResultDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...AwardResultFullFragment
		}
	}
	${AwardResultFullFragment}
`;


