import gql from 'graphql-tag';
import {MagazineFullFragment} from "../fragments/MagazineFullFragment";

export const MagazinePatchMutation = gql`
	mutation MagazinePatchMutation($id: ID!, $changes: MagazinePatchInput!) {
		results: magazinePatch(id: $id, changes: $changes) {
			...MagazineFullFragment
		}
	}
	${MagazineFullFragment}
`;


