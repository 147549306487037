import gql from 'graphql-tag';
import {Candidacy_producer_FullFragment} from "../fragments/Candidacy_producer_FullFragment";

export const Candidacy_producer_PatchMutation = gql`
	mutation CandidacyPatchMutation($id: ID!, $changes: CandidacyPatchInput!) {
		results: candidacyPatch(id: $id, changes: $changes) {
			...Candidacy_producer_FullFragment
		}
	}
	${Candidacy_producer_FullFragment}
`;
