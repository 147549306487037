import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { ServiceListQuery } from '../graphql/query/ServiceListQuery';
import typeIntro from '../static/introspection/Service.json';
import ServiceFilters from '../static/introspection/ServiceFilterInput.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';

export const ServiceList = () => (
	<UserData>
		{({ isInternal }) => (
			<EntityListFetcher
				query={ServiceListQuery}
				entityName={ENTITY.SERVICE}
				headers={['name', 'type']}
				sortables={['name', 'type']}
				typeIntro={typeIntro}
				filtersIntro={ServiceFilters}
				filterFields={
					isInternal
						? ['name', 'producerChangedAfter', 'types', 'Country']
						: null
				}
			/>
		)}
	</UserData>
);
