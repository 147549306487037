import React from 'react';
import { t } from '../utils/labels';
import { GeoIndicationDetailQuery } from '../graphql/query/GeoIndicationDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/GeoIndication.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';
import { fastTransformer, refactorInfo, toAsyncRO } from '../utils/form';

export const GeoIndicationDetail = ({ id }) => (
	<UserData>
		{({ isInternal }) => (
			<EntityDetailFetcher
				query={GeoIndicationDetailQuery}
				entityName={ENTITY.GEO_INDICATION}
				id={id}
				typeIntro={typeIntro}
				clonable={false}
				fields={[
					isInternal ? 'sfId' : null,
					'name',
					'logoMedia',
					'status',
					'akas',
					'euIndication',
					'italianIndication',
					'descriptionValues',
					'description',
					'descriptionIta',
					'vatNumber',
					'website',
					'socialInstagramPage',
					'socialFacebookPage',
					'EuCategory',
					'Country',
					'StateProvince',
					'postalCode',
					'city',
					'address1',
					'address2',
					'lat',
					'lng',
					'goods',
					'companies',
					'managedByUsers',
					'newsCitations',
				]}
				transformer={(data) => {
					data.fields = fastTransformer({
						goods: (f) => {
							if (f.type !== 'EntityList') {
								refactorInfo(f, 'EntityList');
								return f;
							}
							return {
								...toAsyncRO(f, ENTITY.GOOD),
								tab: 2,
							};
						},
						managedByUsers: (f) => {
							if (f.type !== 'EntityList') {
								refactorInfo(f, 'EntityList');
								return f;
							}
							return {
								...toAsyncRO(f, ENTITY.MANAGED_BY_USER),
								tab: 3,
							};
						},
						newsCitations: (f) => {
							if (f.type !== 'EntityList') {
								refactorInfo(f, 'EntityList');
								return f;
							}
							return {
								...toAsyncRO(f, ENTITY.NEWS_CITATION),
								tab: 4,
							};
						},
					})(data.fields);
					return data;
				}}
				tabs={[
					t('generic data'),
					t(['GeoIndication', 'HtmlTemplate', 'label'].join('/')),
					t(['GeoIndication', 'goods', 'label'].join('/')),
					t(['GeoIndication', 'managedByUsers', 'label'].join('/')),
					t(['GeoIndication', 'newsCitations', 'label'].join('/')),
				]}
				cols={[
					['col-sm-7', 'col-sm-5'],
					['col-sm-12'],
					['col-sm-12'],
					['col-sm-12'],
					['col-sm-12'],
				]}
			/>
		)}
	</UserData>
);
