import gql from 'graphql-tag';

export const DataVizListQuery = gql`
	query DataVizListQuery(
		$first: Int
		$cursor: String
	) {
		entities: DataViz(first: $first, after: $cursor) {
			edges {
				node {
					id
					localId
					name
					title
					source
					url
					editorial_section {
						id
						name
					}
					targetSite
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;
