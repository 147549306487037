import gql from 'graphql-tag';
import { always } from 'ramda';
import { t } from '../utils/labels';
import { generateAsyncQuery } from '../utils/graphql';
import { ReportType } from '../components/Report';
import { AwardSelectQuery } from '../graphql/query/AwardSelectQuery';
import { formatDate, html2text, nodes } from '../utils/misc';
import { DT_PRECISION } from '../utils/list';
import { generateBaseField, toAsyncChoices } from '../utils/form';
import { ENTITY } from '../utils/entities';
import { InternalCapability } from '../server-types';

export const showcaseCandidatedProducts: ReportType = {
	filename: always('Export per grafici - Prodotti presenti ad uno showcase'),
	title: 'Export per grafici - Prodotti presenti ad uno showcase',
	requirements: [InternalCapability.MarketingExtra],
	preQuery: gql`
		query PreQueryRep1($id: ID!) {
			award: node(id: $id) {
				id
				... on Award {
					description1Label
					description2Label
					description3Label
					description4Label
					description5Label
					description6Label
					description7Label
					description8Label
					description9Label
					description10Label
				}
			}
		}
	`,
	query: gql`
		query QueryRep2(
			$after: String
			$filter: CandidacyFilterInput
			$first: Int
		) {
			connector: candidacies(after: $after, filter: $filter, first: $first) {
				edges {
					node {
						id
						name
						description1
						description2
						description3
						description4
						description5
						description6
						description7
						description8
						description9
						description10
						extraMedia {
							id
							origUrl
						}
						featuredImageMedia {
							id
							origUrl
						}
						goods {
							id
							name
							extraMedia {
								id
								origUrl
							}
							featuredImageMedia {
								id
								origUrl
							}
							Brand {
								id
								name
								Company {
									id
									name
								}
							}
							description
							shelfLifeLength
							range
							formats {
								id
								cuMesureUnit
								cuMesureValue
								cuPerSellingPack
							}
						}
						launchDate
					}
				}
				pageInfo {
					hasNextPage
				}
			}
		}
	`,
	fields: [
		toAsyncChoices(
			ENTITY.AWARD,
			generateAsyncQuery(AwardSelectQuery, true),
			true,
			generateBaseField('Award', 'Award'),
		),
	],

	header: ({ award }) =>
		[
			t`Product Photo High Res`,
			t`Product name`,
			t`Brand`,
			t`Company`,
			t`Formats`,
			t`Launch date`,
			t`Shelf life`,
			t`Range name`,
		].concat(
			Object.keys(award)
				.filter((n) => n.startsWith('description'))
				.filter((n) => award[n])
				.map((n) => award[n].split('|')[0]),
		),
	preVariablesGenerator: (values: any) => ({ id: values.Award.value.id }),
	variablesGenerator: (pre: any, values: any) => ({
		filter: {
			Award: { id: values.Award.value.id },
		},
	}),
	extractor: (data: any) =>
		nodes(data.connector).reduce((list, candidacy) => {
			candidacy.goods.forEach((good) => {
				list.push({
					candidacy,
					good,
				});
			});
			return list;
		}, []),
	transformer: (e: any, { award }: any) =>
		[
			e.candidacy.extraMedia && e.candidacy.extraMedia.origUrl,
			e.good.name,
			e.good.Brand.name,
			e.good.Brand.Company.name,
			e.good.formats
				.map((f) => `${f.cuMesureValue} ${f.cuMesureUnit}`)
				.join(', '),
			formatDate(e.candidacy.launchDate, DT_PRECISION.DAY_BEGIN),
			e.good.shelfLifeLength,
			e.good.range,
		].concat(
			Object.keys(award)
				.filter((n) => n.startsWith('description'))
				.filter((n) => award[n])
				.map((n) => e.candidacy[n.replace('Label', '')])
				.map(html2text),
		),
};
