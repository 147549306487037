import gql from 'graphql-tag';
import {BranchFullFragment} from "../fragments/BranchFullFragment";

export const BranchPatchMutation = gql`
	mutation BranchPatchMutation($id: ID!, $changes: BranchPatchInput!) {
		results: branchPatch(id: $id, changes: $changes) {
			...BranchFullFragment
		}
	}
	${BranchFullFragment}
`;


