import React from 'react';
import { BrandDetailQuery } from '../graphql/query/BrandDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/Brand.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';
import { EntityDetailModal } from '../types/entities';
import { Company } from '../server-types';
import { fastTransformer, refactorInfo, toAsyncRO } from '../utils/form';
import { transformerHandler } from '../utils/misc';

export const BrandDetail: React.FC<EntityDetailModal<Company>> = ({
	id,
	entityLabel,
	onDelete,
	modal,
}) => (
	<UserData>
		{({ isInternal }) => (
			<EntityDetailFetcher
				id={id}
				entityLabel={entityLabel}
				onDelete={onDelete}
				query={BrandDetailQuery}
				modal={modal}
				entityName={ENTITY.BRAND}
				typeIntro={typeIntro}
				fields={[
					isInternal ? 'sfId' : null,
					'name',
					'logoMedia',
					'pricePositioning',
					isInternal ? 'goodClasses' : null,
					'retailChainDistribution',
					'foodServiceChainDistribution',
					'gourmetShopChainDistribution',
					isInternal ? 'order' : null,
					'website',
					'socialInstagramPage',
					'socialFacebookPage',
				]}
				transformer={transformerHandler(
					fastTransformer({
						goodClasses: (f) => {
							if (f.type !== 'EntityList') {
								refactorInfo(f, 'EntityList');
								return f;
							}
							return toAsyncRO(f, ENTITY.GOOD_CLASS);
						},
					}),
				)}
			/>
		)}
	</UserData>
);
