import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { t } from '../utils/labels';

export const ReportNotAllowed: React.FunctionComponent<{}> = () => (
	<Card>
		<CardBody>
			<h2>{t`report not allowed title`}</h2>
			<p>{t`report not allowed text`}</p>
		</CardBody>
	</Card>
);
