import React from 'react';
import { AwardCreateMutation } from '../graphql/mutations/AwardCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Award.json';
import inputTypeIntro from '../static/introspection/AwardCreateInput.json';
import { AwardEditQuery } from '../graphql/query/AwardEditQuery';
import { ENTITY } from '../utils/entities';
import { t } from '../utils/labels';
import { generateAsyncQuery, graphqlQuery } from '../utils/graphql';
import { UserSelectQuery } from '../graphql/query/UserSelectQuery';
import { UserRole } from '../server-types';

export const AwardCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(AwardEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={AwardCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.AWARD}
		fields={[
			'type',
			'name',
			'edition',
			'year',
			'managerUser',
			'langCode',
			'logoMedia',
			'visibility',
			'publicDescription',
			'eventDescription',
			'descriptionForProducer',
			'descriptionForVoter',
			'descriptionProducerHighlights',
			'maxPerCompanyCandidacies',
			'maxGoodsPerCandidacy',
			'candidaciesCloseAt',
			'votesCloseAt',
			'autovoterRoles',
			'ratingOverallLabel',
			'rating1Label',
			'rating2Label',
			'rating3Label',
			'rating4Label',
			'rating5Label',
			'comment1Label',
			'comment1Max',
			'comment2Label',
			'comment2Max',
			'comment3Label',
			'comment3Max',
			'website',
			'extraMediaType',
			'extraMediaLabel',
			'options',
			'description1Label',
			'description1Max',
			'description2Label',
			'description2Max',
			'description3Label',
			'description3Max',
			'description4Label',
			'description4Max',
			'description5Label',
			'description5Max',
			'description6Label',
			'description6Max',
			'description7Label',
			'description7Max',
			'description8Label',
			'description8Max',
			'description9Label',
			'description9Max',
			'description10Label',
			'description10Max',
			'vademecumCompany',
			'vademecumGoods',
			'vademecumCandidacies',
			'vademecumInvited',
			'supportEmail',
			'regulationsMedia',
			'contractMedia',

			'pricePosition',
			'brandType',
			'EditorialSection',
			'productType',
			'benchmarkGood',
			'purchasingType',
			'paymentCurrency',
			'estimatedVolumes',
			'measureUnit',
			'listingFee',
			'marketingInvestmentExpected'
		]}
		tabs={[
			t('generic data'),
			t([ENTITY.AWARD, 'descriptions', 'label'].join('/')),
			t([ENTITY.AWARD, 'candidacies fields', 'label'].join('/')),
			t([ENTITY.AWARD, 'rating options', 'label'].join('/')),
		]}
		transformer={(data) => {
			data.fields.forEach((f) => {
				switch (f.name) {
					case 'publicDescription':
					case 'eventDescription':
					case 'descriptionForProducer':
					case 'descriptionForVoter':
					case 'descriptionProducerHighlights':
					case 'vademecumCompany':
					case 'vademecumGoods':
					case 'vademecumCandidacies':
					case 'vademecumInvited':
						f.tab = 1;
						break;

					case 'description1Label':
					case 'description1Max':
					case 'description2Label':
					case 'description2Max':
					case 'description3Label':
					case 'description3Max':
					case 'description4Label':
					case 'description4Max':
					case 'description5Label':
					case 'description5Max':
					case 'description6Label':
					case 'description6Max':
					case 'description7Label':
					case 'description7Max':
					case 'description8Label':
					case 'description8Max':
					case 'description9Label':
					case 'description9Max':
					case 'description10Label':
					case 'description10Max':
						f.tab = 2;
						break;

					case 'ratingOverallLabel':
					case 'rating1Label':
					case 'rating2Label':
					case 'rating3Label':
					case 'rating4Label':
					case 'rating5Label':
					case 'comment1Label':
					case 'comment1Max':
					case 'comment2Label':
					case 'comment2Max':
					case 'comment3Label':
					case 'comment3Max':
						f.tab = 3;
						break;

					case 'managerUser':
						// @ts-ignore
						f.asyncQuery = generateAsyncQuery(
							UserSelectQuery,
							true,
							(value) => ({
								filter: { name: value, roles: [UserRole.Internal] },
							}),
						);
						break;

					case 'type':
						f.afterChange = (ff, value, fields) => {
							return updateAwardFieldsVisibility(fields);
						}
						break;
					default:
						break;
				}
			});

			data.fields = updateAwardFieldsVisibility(data.fields);
			return data;
		}}
	/>
);

export const updateAwardFieldsVisibility: any = (fields: any, updateValues: boolean = true) => {
	const type = fields.filter((f) => f.name == 'type')[0].value;

	if (type && type.value) {
		if (type.value == "PURCHASING_REQUEST") {
			fields.forEach((f) => {
				f.hidden = false;
				switch (f.name) {
					case 'edition':
					case 'autovoterRoles':
					case 'votesCloseAt':
					case 'website':
						f.hidden = true;
						break;
					case 'visibility':
						if (updateValues) {
							f.value = f.choices.filter((v) => v.value == 'SOURCING')[0];
							f.disabled = true;
						}
						break;
					default:
						break;
				}
			});
		} else {
			fields.forEach((f) => {
				f.hidden = false;
				switch (f.name) {
					case 'pricePosition':
					case 'brandType':
					case 'productType':
					case 'purchasingType':
					case 'paymentCurrency':
					case 'estimatedVolumes':
					case 'measureUnit':
					case 'benchmarkGood':
					case 'listingFee':
					case 'marketingInvestmentExpected':
					case 'EditorialSection':
						f.hidden = true;
						break;
					case 'visibility':
						if (updateValues) {
							f.value = null;
							f.disabled = false;
						}
						break;
					default:
						break;
				}
			});
		}
	} else {
		fields.forEach((f) => {
			if (f.name != 'type') {
				f.hidden = true;
			}
		});
	}
	return fields;
}