import gql from 'graphql-tag';


export const CompanyCertificationFullFragment = gql`
	fragment CompanyCertificationFullFragment on CompanyCertification {
		id
		localId
		name
		akas
	}

`;
