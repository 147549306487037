import React from 'react';
import { RetailerCreateMutation } from '../graphql/mutations/RetailerCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Retailer.json';
import inputTypeIntro from '../static/introspection/RetailerCreateInput.json';
import { RetailerEditQuery } from '../graphql/query/RetailerEditQuery';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { UserData } from '@food/auth';

export const RetailerCreate = ({ id }) => (
	<UserData>
		{({ isInternal }) => (
			<EntityEditFetcher
				fetcher={id ? graphqlQuery(RetailerEditQuery, { id }) : null}
				create={true}
				clone={!!id}
				id={id}
				mutation={RetailerCreateMutation}
				typeIntro={typeIntro}
				inputTypeIntro={inputTypeIntro}
				entityName={ENTITY.RETAILER}
				fields={[
					isInternal ? 'sfId' : null,
					'name',
					'logoMedia',
					'type',
					'billingName',
					'description',
					'descriptionIta',
					'descriptionLong',
					'descriptionLongIta',
					'pecEmail',
					'vatNumber',
					'website',
					'Country',
					'StateProvince',
					'postalCode',
					'city',
					'address1',
					'address2',
					'lat',
					'lng',
				]}
			/>
		)}
	</UserData>
);
