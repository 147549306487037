import gql from 'graphql-tag';

export const EmailDomainFullFragment = gql`
	fragment EmailDomainFullFragment on EmailDomain {
		id
		localId
		emailDomain
		accountType
		action
		Retailer {
			id
			name
		}
		Company {
			id
			name
		}
		CompanyGroup {
			id
			name
		}
		Service {
			id
			name
		}
		FoodService {
			id
			name
		}
		Team {
			id
			name
		}
		website
		accountSfid
		note
	}
`;
