import gql from 'graphql-tag';
import {FrequentSearchFullFragment} from "../fragments/FrequentSearchFullFragment";

export const FrequentSearchCreateMutation = gql`
	mutation FrequentSearchCreateMutation($values: FrequentSearchCreateInput!) {
		results: frequentSearchCreate(values: $values) {
			...FrequentSearchFullFragment
		}
	}
	${FrequentSearchFullFragment}
`;


