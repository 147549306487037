import React from 'react';
import { NewsletterIssueDetailQuery } from '../graphql/query/NewsletterIssueDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/NewsletterIssue.json';
import { ENTITY } from '../utils/entities';
import { fastTransformer } from '../utils/form';
import { assign, getEntityLabel, transformerHandler } from '../utils/misc';
import { t } from '../utils/labels';
import { StatusSetter } from '../components/StatusSetter';
import NewsletterIssueStatusIntrospection from '../static/introspection/NewsStatus.json';
import { setNewsletterIssueStatusMutation } from '../graphql/mutations/setNewsletterIssueStatusMutation';
import { InputTemplate, NewsletterIssue } from '../server-types';
import { EntityDetailPage } from '../types/entities';

const statuses = NewsletterIssueStatusIntrospection.enumValues.map(
	(o) => o.name,
);

export const NewsletterIssueDetail: React.FC<EntityDetailPage<
	NewsletterIssue
>> = ({ id }) => (
	<EntityDetailFetcher
		query={NewsletterIssueDetailQuery}
		entityName={ENTITY.NEWSLETTER_ISSUE}
		id={id}
		typeIntro={typeIntro}
		clonable={true}
		customActionComponents={(entity) => {
			const template =
				entity.Newsletter.inputTemplate === InputTemplate.DemGeneric
					? 'generic'
					: 'foodNewsletter';
			return [
				<a
					key={1}
					target={'_blank'}
					className={'btn btn-info margin-right'}
					href={`https://newsletter.gruppofood.com/render2?template=${template}&id=${encodeURIComponent(
						id,
					)}`}
				>
					<i className={'fa fa-eye'} /> {t`show newsletter preview`}
				</a>,
				<StatusSetter
					key={2}
					statuses={statuses}
					entityName={ENTITY.AWARD}
					entity={entity}
					statusFieldName={'status'}
					mutation={setNewsletterIssueStatusMutation}
					entityIntrospection={typeIntro}
				/>,
			];
		}}
		myToString={(data) =>
			t(getEntityLabel(ENTITY.NEWSLETTER_ISSUE)) + ' ' + data.localId
		}
		fields={[
			'name',
			'Newsletter',
			'status',
			'publicationDate',
			'hiddenIntro',
			'values',
		]}
		transformer={transformerHandler(
			fastTransformer({
				values: (f) =>
					assign({
						value: JSON.stringify(JSON.parse(f.value), null, 3),
						formatText: true,
					})(f),
			}),
		)}
	/>
);
