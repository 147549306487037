import gql from 'graphql-tag';
import {ToSFullFragment} from "../fragments/ToSFullFragment";

export const ToSEditQuery = gql`
	query ToSEditQuery($id: ID!) {
		entity: node (id: $id) {
				...ToSFullFragment
		  }
	}
	${ToSFullFragment}
`;


