import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { CompanyBadgeListQuery } from '../graphql/query/CompanyBadgeListQuery';
import typeIntro from '../static/introspection/CompanyBadge.json';
import CompanyBadgeFilters from '../static/introspection/CompanyBadgeFilterInput.json';
import { ENTITY } from '../utils/entities';

const CompanyBadgeList = () => (
	<EntityListFetcher
		query={CompanyBadgeListQuery}
		entityName={ENTITY.COMPANY_BADGE}
		headers={['name']}
		sortables={[]}
		typeIntro={typeIntro}
		filtersIntro={CompanyBadgeFilters}
		filterFields={['name', 'targetSite']}
	/>
);

export { CompanyBadgeList };
