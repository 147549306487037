import gql from 'graphql-tag';

export const GoodClassListQuery = gql`
	query GoodClassListQuery(
		$first: Int,
		$cursor: String,
		$filter: SearchGoodClassInput
	) {
		entities: goodClasses(first: $first, after: $cursor, filter: $filter) {
			edges {
				node {
					id
					localId
					name
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}

	}
`;


