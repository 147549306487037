import React from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Col,
	Input,
} from 'reactstrap';
import { t } from '../utils/labels';
import { generateRandomPassword } from '../utils/password';
import { UserChangePasswordMutation } from '../graphql/mutations/UserChangePasswordMutation';
import { graphql } from 'react-apollo';
// TODO tipizzare
// TODO cambiare customref
class ChangePasswordModal extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			newPassword: '',
			confirmPassword: '',
		};

		if (props.customRef) {
			props.customRef(this);
		}
	}

	_toggle = () =>
		this.setState({
			isOpen: !this.state.isOpen,
			newPassword: '',
			confirmPassword: '',
		});

	_changePassword = async () => {
		const mutationResult = await this.props.mutate({
			variables: {
				id: this.props.userId,
				newPassword: this.state.newPassword,
			},
		});
		if (mutationResult) {
			this._toggle();
		}
	};

	_changeFieldValue = (value, confirm = false) =>
		this.setState({
			...this.state,
			newPassword: confirm ? this.state.newPassword : value,
			confirmPassword: confirm ? value : this.state.confirmPassword,
		});

	_generateRandomPassword = () => {
		const pwd = generateRandomPassword();
		this.setState({
			...this.state,
			newPassword: pwd,
			confirmPassword: pwd,
		});
	};

	render() {
		const { isOpen, newPassword, confirmPassword } = this.state;
		return (
			<Modal autoFocus={false} isOpen={isOpen} toggle={this._toggle}>
				<ModalHeader toggle={this._toggle}>
					{t`change password title`}
				</ModalHeader>
				<ModalBody>
					<p>{t`change password modal intro`}</p>
					<Form>
						<FormGroup row>
							<Col sm="4">
								<Label>{t`password`}:</Label>
							</Col>
							<Col sm="8">
								<Input
									value={newPassword}
									onChange={(event) =>
										this._changeFieldValue(event.target.value)
									}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col sm="4">
								<Label>{t`confirm password`}:</Label>
							</Col>
							<Col sm="8">
								<Input
									value={confirmPassword}
									onChange={(event) =>
										this._changeFieldValue(event.target.value, true)
									}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Col sm={{ size: 8, offset: 4 }}>
								<Button
									color="success"
									onClick={this._generateRandomPassword}
								>
									<i className="fa fa-random" />{' '}
									{t`generate random password`}
								</Button>
							</Col>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={this._toggle}>
						<i className="fa fa-times" /> {t`cancel`}
					</Button>
					<Button
						color="success"
						onClick={this._changePassword}
						disabled={
							this.state.newPassword === '' ||
							this.state.newPassword !== this.state.confirmPassword
						}
					>
						<i className="fa fa-edit" /> {t`change`}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export default graphql(UserChangePasswordMutation)(ChangePasswordModal) as any;
