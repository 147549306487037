import React from 'react';
import { CompanyGroupCreateMutation } from '../graphql/mutations/CompanyGroupCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/CompanyGroup.json';
import inputTypeIntro from '../static/introspection/CompanyGroupCreateInput.json';
import { CompanyGroupEditQuery } from '../graphql/query/CompanyGroupEditQuery';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { CompanyGroup } from '../server-types';
import { EntityCreatePage } from '../types/entities';

export const CompanyGroupCreate: React.FC<EntityCreatePage<CompanyGroup>> = ({
	id,
}) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(CompanyGroupEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={CompanyGroupCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.COMPANY_GROUP}
		fields={[
			'name',
			'logoMedia',
			'type',
			'billingName',
			'description',
			'descriptionIta',
			'descriptionLong',
			'descriptionLongIta',
			'pecEmail',
			'vatNumber',
			'website',
			'Country',
			'StateProvince',
			'postalCode',
			'city',
			'address1',
			'address2',
			'lat',
			'lng',
		]}
	/>
);
