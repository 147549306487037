import React from 'react';
import { always } from 'ramda';
import { ObjectHistoryDetailQuery } from '../graphql/query/ObjectHistoryDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/ObjectHistory.json';
import { t } from '../utils/labels';
import { ENTITY } from '../utils/entities';
import { generateBaseField, refactorInfo, toAsyncChoices } from '../utils/form';
import { IField } from '../types/form';

export const ObjectHistoryDetail = ({ id }) => (
	<EntityDetailFetcher
		query={ObjectHistoryDetailQuery}
		entityName={ENTITY.OBJECT_HISTORY}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		myToString={(o) => o.action + ' ' + o.collection + ' ' + o.Object.id}
		forcedPermissions={{
			canEdit: false,
			canDelete: false,
			canCreate: false,
		}}
		hideHistory={true}
		fields={['User', 'timestamp', 'action', 'collection', 'params']}
		transformer={(data, entity) => {
			data.fields.forEach((f) => {
				if (f.name === 'params') {
					if (f.type !== 'Text') {
						refactorInfo(f, 'Text');
						return;
					}
					f.value = JSON.stringify(JSON.parse(f.value), null, 3);
					f.formatText = true;
				}
			});

			const objField: IField = toAsyncChoices(
				entity.Object.__typename,
				() => null,
				true,
				generateBaseField('Object', t`ObjectHistory/Object/label`),
			);
			objField.myToString = () =>
				`${t(entity.Object.__typename)} ${entity.Object.id}`;
			objField.mapToEntity = always(entity.Object.__typename);
			objField.value = entity.Object;

			data.fields = data.fields.concat([objField]);
			return data;
		}}
	/>
);
