import gql from 'graphql-tag';

export const CompanyBadgeSelectQuery = gql`
	query CompanyBadgeSelectQuery($filter: CompanyBadgeFilterInput) {
		choices: companyBadges(filter: $filter) {
			edges {
				node {
					id
					localId
					name
				}
			}
			total
		}
	}
`;
