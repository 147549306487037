import gql from 'graphql-tag';
import {EditorialTagFullFragment} from "../fragments/EditorialTagFullFragment";

export const EditorialTagCreateMutation = gql`
	mutation EditorialTagCreateMutation($values: EditorialTagCreateInput!) {
		results: editorialTagCreate(values: $values) {
			...EditorialTagFullFragment
		}
	}
	${EditorialTagFullFragment}
`;


