import gql from 'graphql-tag';
import {NewsletterFullFragment} from "../fragments/NewsletterFullFragment";

export const NewsletterPatchMutation = gql`
	mutation NewsletterPatchMutation($id: ID!, $changes: NewsletterPatchInput!) {
		results: newsletterPatch(id: $id, changes: $changes) {
			...NewsletterFullFragment
		}
	}
	${NewsletterFullFragment}
`;


