import { map, prop } from 'ramda';
import { IChoice } from './misc';

const valToRefInput = (id) => (id ? { id } : null);

const toRefInput = (obj) => {
	if (obj && obj.media && obj.media.id) {
		return valToRefInput(obj.media.id);
	}
	return (obj ? valToRefInput(obj.id) : obj);
}

const toRefMediaInput = (obj) => {
	if (obj) {
		if (obj.media) {
			return {
				id: (obj.media && obj.media.id ? obj.media.id : null), // fallback per i blocchi che non supportano la selected size
				media: { id: (obj.media && obj.media.id ? obj.media.id : null) },
				selectedSize: (obj.selectedSize ? obj.selectedSize : null)
			};
		} else {
			return {
				id: (obj.id ? obj.id : null), // fallback per i blocchi che non supportano la selected size
				media: { id: (obj.id ? obj.id : null) },
				selectedSize: null
			};
		}
	} else {
		return obj;
	}
}

const toRefInputs = map(toRefInput);
const toRefMediaInputs = map(toRefMediaInput);

const urlToRefInput = (url: string) => valToRefInput(url.split('/').pop());

const choicesBeforeSaveTransformer = (
	value: null | IChoice | IChoice[],
): null | (string | number) | Array<string | number> => {
	if (!value) {
		return null;
	}
	const extractor = prop('value');
	return Array.isArray(value) ? value.map(extractor) : extractor(value);
};

export {
	toRefInputs,
	toRefMediaInput,
	toRefInput,
	toRefMediaInputs,
	valToRefInput,
	urlToRefInput,
	choicesBeforeSaveTransformer,
};
