import gql from 'graphql-tag';

export const MagazineListQuery = gql`
	query MagazineListQuery(
		$first: Int
		$cursor: String
		$filter: MagazineFilterInput
	) {
		entities: magazines(first: $first, after: $cursor, filter: $filter) {
			edges {
				node {
					id
					localId
					name
					MagazineCollection {
						id
						name
						BrandSf
					}
					category
					issueNumber
					publicationDate
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;
