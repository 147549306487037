import React from 'react';
import { AnswerSetDetailQuery } from '../graphql/query/AnswerSetDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/AnswerSet.json';
import { ENTITY } from '../utils/entities';
import { notNull } from '../utils/misc';

export const AnswerSetDetail = ({ id }) => (
	<EntityDetailFetcher
		id={id}
		query={AnswerSetDetailQuery}
		entityName={ENTITY.ANSWER_SET}
		typeIntro={typeIntro}
		clonable={false}
		modal={false}
		fields={['answer1', 'goods']}
		transformer={(data, entity) => {
			data.fields = data.fields
				.map((f) => {
					if (f.name.startsWith('answer')) {
						const number = Number(f.name.replace('answer', ''));
						const question = entity.QuestionSet['question' + number];
						if (!question) {
							return null;
						}

						f.label = question;
					}
					return f;
				})
				.filter(notNull);
			return data;
		}}
		forcedPermissions={(data, entity) =>
			entity.sentDate
				? { canEdit: false, canCreate: false, canDelete: false }
				: undefined
		}
	/>
);
