import gql from 'graphql-tag';
import { MediaBaseFragment } from '../fragments/MediaBaseFragment';

export const AwardCategoryCreateMutation = gql`
	mutation AwardCategoryCreateMutation($values: AwardCategoryCreateInput!) {
		results: awardCategoryCreate(values: $values) {
			id
			localId
			name
			slug
			description
			Media {
				...MediaBaseFragment
			}
			color
			giftUrl
		}
	}
	${MediaBaseFragment}
`;


