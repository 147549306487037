import gql from 'graphql-tag';
import {ToSFullFragment} from "../fragments/ToSFullFragment";

export const ToSPatchMutation = gql`
	mutation ToSPatchMutation($id: ID!, $changes: ToSPatchInput!) {
		results: toSPatch(id: $id, changes: $changes) {
			...ToSFullFragment
		}
	}
	${ToSFullFragment}
`;


