import { SimpleReportType } from 'components/ReportSimple';
import { InternalCapability } from 'server-types';
import { dateField, targetSiteField } from 'utils/reports';

export const utentiLoggati: SimpleReportType = {
	filename: 'utenti_loggati',
	title: 'Utenti Loggati',
	endpoint: '/api/reports/utenti-loggati',
	requirements: [InternalCapability.MarketingExtra],
	fields: [dateField, targetSiteField],
};