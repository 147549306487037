import React from 'react';
import { CompanyBadgeDetailQuery } from '../graphql/query/CompanyBadgeDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/CompanyBadge.json';
import { ENTITY } from '../utils/entities';

export const CompanyBadgeDetail = ({ id }) => (
	<EntityDetailFetcher
		query={CompanyBadgeDetailQuery}
		entityName={ENTITY.COMPANY_BADGE}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		fields={[
			'name',
			'iconMedia',
			'styleClass',
			'color',
			'descriptionLong',
			'targetSite',
		]}
	/>
);
