// @ts-ignore
import { __RouterContext as RouterContext } from 'react-router';
import { useState, useEffect, useRef, useContext } from 'react';

const useDebounce = (value: any, delay: number) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => setDebouncedValue(value), delay);

		return () => clearTimeout(handler);
	}, [value]);

	return debouncedValue;
};

function useWhyDidYouUpdate(name, props) {
	// Get a mutable ref object where we can store props ...
	// ... for comparison next time this hook runs.
	const previousProps: any = useRef();

	useEffect(() => {
		if (previousProps.current) {
			// Get all keys from previous and current props
			const allKeys = Object.keys({ ...previousProps.current, ...props });
			// Use this object to keep track of changed props
			const changesObj = {};
			// Iterate through keys
			allKeys.forEach((key) => {
				// If previous is different from current
				if (previousProps.current[key] !== props[key]) {
					// Add to changesObj
					changesObj[key] = {
						from: previousProps.current[key],
						to: props[key],
					};
				}
			});

			// If changesObj not empty then output to console
			if (Object.keys(changesObj).length) {
				console.log('[why-did-you-update]', name, changesObj);
			}
		}

		// Finally update previousProps with current props for next hook call
		previousProps.current = props;
	});
}

// Hook
function usePrevious(value) {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef();

	// Store current value in ref
	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes

	// Return previous value (happens before update in useEffect above)
	return ref.current;
}

function useRouter() {
	return useContext(RouterContext);
}

function useParams() {
	// @ts-ignore
	const { match } = useRouter();
	return match.params;
}

function useLocation() {
	// @ts-ignore
	const { location, history } = useRouter();

	function navigate(to, { replace = false } = {}) {
		if (replace) {
			history.replace(to);
		} else {
			history.push(to);
		}
	}

	return {
		location,
		navigate,
	};
}

export {
	useDebounce,
	useWhyDidYouUpdate,
	usePrevious,
	useRouter,
	useLocation,
	useParams,
};
