import React from 'react';
import { PageTitle } from '../PageTitle';
import { t } from '../../utils/labels';
import { Card, CardBody, Col, Row } from 'reactstrap';

export const Faq: React.FC = () => (
	<>
		<PageTitle
			title={t`Faq`}
			breadcrumbs={[{ label: t`home`, path: '/' }, { label: t`Faq` }]}
		/>
		<Row>
			<Col sm={8}>
				<Card>
					<CardBody>
						<h1>{t`FAQ`}</h1>
						<ol>
							<li>
								<a href="#what-is-hub">CHE COS’È HUB?</a>
							</li>
							<li>
								<a href="#what-is-company">
									COSA CONSIDERATE UN’AZIENDA?
								</a>
							</li>
							<li>
								<a href="#what-informations">
									QUALI INFORMAZIONI DEVO METTERE NEL PROFILO
									AZIENDALE?
								</a>
							</li>
							<li>
								<a href="#how-many-brands">
									QUANTI BRAND POSSO INSERIRE?
								</a>
							</li>
							<li>
								<a href="#what-is-good">
									COSA CONSIDERATE UN PRODOTTO?
								</a>
							</li>
							<li>
								<a href="#what-is-candidacy">
									CHE COSA VUOL DIRE AGGIUNGERE UNA CANDIDATURA?
								</a>
							</li>
							<li>
								<a href="#what-is-highlighted-good">
									CHE COS’È UN PRODOTTO IN EVIDENZA?
								</a>
							</li>
						</ol>
						<h3>
							<a id="what-is-hub" />
							CHE COS’È HUB?
						</h3>
						<p>
							HUB è il sito di interazione tra il nostro gruppo
							editoriale e l’industria alimentare.
							<br />
							Vi permette di mantenere, accrescere e aggiornare una
							libreria permanente di informazioni sulla vostra azienda e
							sui vostri prodotti.
						</p>
						<p>
							Per partecipare alle nostre manifestazioni o mettervi in
							mostra sulla Piattaforma{' '}
							<a href={'https://www.italianfood.net'} target={'_blank'}>
								Italianfood.net
							</a>{' '}
							vi basterà quindi collegare i prodotti in archivio alle
							varie manifestazioni senza dover compilare ogni volta
							decine di campi.
						</p>
						<h3>
							<a id="what-is-company" />
							COSA CONSIDERATE UN’AZIENDA?
						</h3>
						<p>
							Abbiamo deciso di dare un Profilo Aziendale per ciascuna
							Ragione Sociale attiva corrispondente ad un’unica Partita
							IVA.
						</p>
						<h3>
							<a id="what-informations" />
							QUALI INFORMAZIONI DEVO METTERE NEL PROFILO AZIENDALE?
						</h3>
						<p>
							Per essere visibili sulla piattaforma e partecipare a una
							manifestazione vi basterà compilare i campi della prima Tab
							“Azienda” e creare/aggiornare le vostre marche nella Tab
							“Brands”.
							<br />
							Compilando la sezione “Partecipazione a Fiere” potrete
							essere visibili sulla piattaforma nelle ricerche collegate
							al calendario delle fiere internazionali.
						</p>
						<h3>
							<a id="how-many-brands" />
							QUANTI BRAND POSSO INSERIRE?
						</h3>
						<p>
							Potete aggiungere tanti Brands quanti sono i Marchi
							caratterizzanti un prodotto o una linea di prodotti
							commercializzati dall’azienda.
							<br />
							Sulla piattaforma italianfood.net fungono da Alias della
							vostra azienda, uno stakeholder potrebbe aver visto un
							vostro prodotto in uno scaffale ma non sapere a quale
							ragione sociale appartenga.
							<br />
							Create il brand prima dei prodotto, è un campo
							obbligatorio!
						</p>
						<h3>
							<a id="what-is-good" />
							COSA CONSIDERATE UN PRODOTTO?
						</h3>
						<p>
							Definiamo un prodotto la serie di unità di vendita
							destinate al consumatore (SKU) che condividono lo stesso:
							<ul>
								<li>Brand</li>
								<li>Nome</li>
								<li>Texture</li>
								<li>Ricettazione (100% ingredienti identici)</li>
								<li>Peculiarità o Indicazione Geografica</li>
								<li>Categoria Merceologica</li>
							</ul>
							Tra i campi compilabili troverete questi che meritano una
							spiegazione:
							<ul>
								<li>
									<strong>LINEA DI PRODOTTO</strong>: Ogni azienda, in
									base a organizzazione interna e mercati alimentari,
									considera linee di prodotti e gamme con una forbice
									che va dal brand alla gamma di gusti. Per ragioni
									strutturali e accessibilità del database abbiamo
									deciso di lasciarvi modo di descrivere La Linea di
									Prodotto come un tag che possa correlare diversi
									prodotti.
								</li>
								<li>
									<strong>CATEGORIA MERCEOLOGICA</strong>: Abbiamo
									semplificato le oltre sei mila categorie
									merceologiche dello standard internazionale GS1 in
									circa 400 diverse categorie in cui catalogare il
									vostro prodotto. Se manca una dicitura consona per il
									vostro prodotto vi preghiamo di selezionare “il meno
									peggio” e di contattarci. Modificheremo noi.
									<br />
									<strong className={'text-themecolor'}>
										ATTENZIONE!
									</strong>{' '}
									La prima importante suddivisione è sul metodo di
									conservazione (Fresco / Shelf Stable / Frozen)
								</li>

								<li>
									<strong>FORMATI DI VENDITA</strong>: Potete creare
									tanti sotto-oggetti quante sono le SKU del prodotto
									in questione. I dati richiesti sono il codice ean
									EAN, il Numero di Unità nell’imballo di vendita , la
									massa di ciascuna unità (in mL o grammi)
								</li>
							</ul>
						</p>
						<h4>Esempio 1:</h4>
						<p>
							Nutella Ferrero in vasetto 200g, Secchio da 2,5 kg e
							monoporzioni.
							<br />
							<strong>Cosa Fare</strong>: Inserite Un Prodotto con vari
							formati di vendita.
							<br />
							<strong>Perché</strong>: il contenuto di tutti i formati ha
							identico nome, brand, ricettazione, peculiarità e categoria
							merceologica. Ogni formato di vendita ha il proprio EAN.
						</p>
						<h4>Esempio 2:</h4>
						<p>
							Patatine Rustiche “LE CROCCANTERRIME” con 10 variabili di
							gusto e vendute in sacchetti da 125, 250 e 500g.
							<br />
							<strong>Cosa fare</strong>: Inserite 10 prodotti diversi
							(Patatine Rustiche al Rosmarino, Patatine Rustiche al Pepe
							Nero, Patatine Rustiche al Guacamole ecc. ecc.) ognuno con
							i tre formati di vendita inserendo il valore LE
							CROCCANTERRIME nel campo Linea di Prodotto di ciascuno.
							<br />
							<strong>Perché</strong>: Ogni varietà di gusto comporta una
							diversa ricettazione (comportando possibili diversi
							allergeni e peculiarità (vegan/bio/ecc. ecc.)). Ogni
							formato di vendita di ciascun gusto ha il proprio EAN.
						</p>
						<h4>Esempio 3:</h4>
						<p>
							Un Pastificio lancia una nuova linea di Pasta di Gragnano
							Bio. Dallo stesso impasto ottiene 10 formati di pasta che
							vende solo in formato da 500g.
							<br />
							<strong>Cosa fare</strong>: Inserite 10 prodotti diversi
							ognuno con un formato di vendita da 500g.
							<br />
							<strong>Perché</strong>: Ogni formato di pasta ha un
							diverso nome caratteristico e un diverso formato di
							estrusione/texture.
						</p>
						<h4>Esempio 4:</h4>
						<p>
							Un produttore di carne lancia in commercio delle cotolette
							di pollo porzionate in vaschette da 500g . Le vende sia per
							il banco freschi, sia surgelate.
							<br />
							<strong>Cosa fare</strong>: Inserite 2 prodotti diversi
							ognuno con un formato di vendita da 500g.
							<br />
							<strong>Perché</strong>: Queste cotolette condividono tutte
							le caratteristiche suddette tranne la categorie
							merceologica. Una sarà Carne di Pollo trasformata Pronta da
							Cuocere – Fresca, l’altra Carne di Pollo trasformata Pronta
							da Cuocere – Frozen.
						</p>
						<h4>Esempio 5:</h4>
						<p>
							Un acetificio lancia in commercio una linea di Aceto
							Balsamico. Aceto Balsamico, Aceto Balsamico di Modena IGP,
							Aceto Balsamico Tradizionale di Modena DOP. Tutti hanno un
							solo Brand e un solo formato: una bottiglietta da 125g.
							<br />
							<strong>Cosa fare</strong>: Inserite 3 prodotti diversi
							ognuno con un formato di vendita da 125g.
							<br />
							<strong>Perché</strong>: Questi aceti condividono tutte le
							caratteristiche suddette ma uno non ha identificazioni
							geografiche correlate, uno lo è ad una IGP, uno lo è alla
							DOP.
						</p>

						<h3>
							<a id="what-is-candidacy" />
							CHE COSA VUOL DIRE AGGIUNGERE UNA CANDIDATURA?
						</h3>
						<p>
							La candidatura è l’oggetto che abbiamo creato per collegare
							uno o più prodotti a una Manifestazione da noi organizzata.
							<br />
							Grazie a questo meccanismo potrete selezionare un prodotto
							creato in passato e compilare soltanto i pochi campi
							descrittivi mirati per il focus della manifestazione.{' '}
						</p>
						<p>
							Leggi con attenzione il regolamento della manifestazione:
							<ul>
								<li>
									in alcune si possono candidare insieme più prodotti
									che condividono lo stesso brand
								</li>
								<li>
									in PRODOTTO FOOD il prodotto deve avere collegati i
									formati di vendita con il campo EAN compilato.
									Quest’ultimo è fondamentale per il matching coi dati
									statistici Nielsen.
								</li>
							</ul>
						</p>
						<h3>
							<a id="what-is-highlighted-good" />
							CHE COS’È UN PRODOTTO IN EVIDENZA?
						</h3>
						<p>
							Sulla piattaforma italianfood.net ogni azienda può rendere
							visibile il proprio <strong>Profilo Aziendale</strong> e
							tanti Prodotti quanti ne prevede il piano di
							sottoscrizione.
							<br />
							Il piano gratuito, ad esempio, ne prevede due.
							<br /> Mettere
							<strong>In Evidenza</strong> un Prodotto significa riempire
							uno degli slot a disposizione nella pagina hub dedicata
							alla piattaforma con uno dei prodotti caricati nella
							propria libreria.
						</p>
					</CardBody>
				</Card>
			</Col>
		</Row>
	</>
);
