import gql from 'graphql-tag';
import { AwardInvitedUserFullFragment } from '../fragments/AwardInvitedUserFullFragment';

export const AwardInvitedUserEditQuery = gql`
	query AwardInvitedUserEditQuery($id: ID!) {
		entity: node(id: $id) {
			...AwardInvitedUserFullFragment
		}
	}
	${AwardInvitedUserFullFragment}
`;


