import React from 'react';
import { PageTitle } from '../PageTitle';
import { t } from '../../utils/labels';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Query } from 'react-apollo';
import { ACTIONS, generateUrl } from '../../utils/urls';
import { ENTITY } from '../../utils/entities';
import { UserData } from '@food/auth';
import { sentryHandler } from '../../utils/errors';
import { Link } from 'react-router-dom';
import CONFIG from '../../static/config';
import { ChecklistPlatformQuery } from '../../graphql/query/ChecklistPlatformQuery';
import { Redirect } from 'react-router';
import { UserRole } from '../../server-types';

interface ChecklistPlatformProps {
	platform: string;
}

export const ChecklistPlatform: React.FC<ChecklistPlatformProps> = ({
	platform,
}) => {
	return (
		<UserData>
			{({ isLogged, managedCompanyIsItalian, userData, loading }) => {
				if (loading) {
					return <>Loading...</>;
				}
				if (platform === 'IFN' && !managedCompanyIsItalian) {
					return <Redirect to={'404'} />;
				}

				// non permetto la visualizzazione agli internal... sarebbe un casino visto che non ho una company prescelta
				if (userData.role === UserRole.Internal) {
					return (
						<Card>
							<CardBody>
								<h1>{t`Error`}</h1>
								<h3>{t`Internal not allowed message`}</h3>
							</CardBody>
						</Card>
					);
				}

				const currentCompany =
					isLogged &&
					userData.managedByUsers &&
					userData.managedByUsers[0] &&
					userData.managedByUsers[0].Company;

				return (
					<Query<any, any>
						query={ChecklistPlatformQuery}
						variables={{ companyId: currentCompany.id }}
					>
						{({ error, loading, data }) => {
							if (error) {
								console.error(error);
								sentryHandler(error);
								return <>Error: {error.toString()}</>;
							}
							if (loading) {
								return <>Loading...</>;
							}

							const company = data.company;
							const goods = company.goods;
							const hasGoods = goods.length > 0;
							const hasBrand =
								company && company.brands && company.brands.length > 0;
							const hasHighlightedGoods =
								company.highlightedGoods &&
								company.highlightedGoods.filter(
									(h) => h.targetSite === platform,
								).length > 0;

							const steps = [
								{
									name: 'company completed',
									conditions: [
										{ value: !hasBrand, text: 'no brand present' },
										{
											value: !company.description,
											text: 'company description empty',
										},
										{
											value: !company.descriptionIta,
											text: 'company description ita empty',
										},
										{
											value: !company.descriptionLong,
											text: 'company description long empty',
										},
										{
											value: !company.descriptionLongIta,
											text: 'company description ita long empty',
										},
										{
											value: !company.logoMedia,
											text: 'no logoMedia on company',
										},
										{
											value:
												!company.companyCertifications ||
												company.companyCertifications.length === 0,
											text: 'no companyCertifications on company',
										},
									],
									actionText: t`go to company profile`,
									actionUrl: generateUrl(
										ENTITY.COMPANY,
										ACTIONS.DETAIL,
										currentCompany.id,
									),
								},
								{
									name: 'has goods',
									conditions: [
										{ value: !hasGoods, text: 'no goods in company' },
									],
									actionText: t`go to goods list`,
									actionUrl: generateUrl(
										ENTITY.COMPANY,
										ACTIONS.DETAIL,
										currentCompany.id,
									),
								},
								{
									name: 'has highlighted',
									conditions: [
										{
											value: !hasHighlightedGoods,
											text: 'no highlighted goods',
										},
									],
									actionText: t`insert highlighted goods`,
									actionUrl: '/platforms/' + platform,
								},
							].filter((e) => !!e);

							// questa variabile identifica il primo step non portato a termine e mi serve per colorare
							// di rosso il bottone della prima azione fallita e di grigio i successivi
							const firstFailedStep = steps.reduce(
								(res, { conditions }, i) =>
									res < 0 && !conditions.every((c) => !c.value)
										? i
										: res,
								-1,
							);
							const actionsColor = steps.map((s, i) => {
								if (i === firstFailedStep) {
									return 'danger';
								}
								if (i > firstFailedStep && firstFailedStep !== -1) {
									return 'secondary';
								}
								return 'success';
							});

							return (
								<>
									<PageTitle
										title={t`Checklist for ${platform} Platform`}
										breadcrumbs={[
											{ label: t`home`, path: '/' },
											{ label: t(`${platform} Platform`) },
											{ label: t`Checklist` },
										]}
									/>
									<Row>
										<Col sm={8}>
											<Card>
												<CardBody>
													<h1>
														{t(`${platform} Platform`)}
														{' - '}
														{t`checklist`}
													</h1>

													{steps.map(({ name, conditions }, i) => (
														<div key={name}>
															<hr />

															<h3>
																<span className="capolettera">
																	{i + 1}.
																</span>
																{t(
																	`platform checklist step ${name} title`,
																)}
															</h3>
															<p
																dangerouslySetInnerHTML={{
																	__html: t(
																		`platform checklist step ${name} html`,
																	),
																}}
															/>

															{conditions.every(
																(c) => !c.value,
															) && (
																<h3
																	className={
																		'text-success checklist-info'
																	}
																>
																	<i className="fa fa-check" />{' '}
																	{t(
																		`platform checklist step ${name} ok`,
																	)}
																</h3>
															)}
															{conditions.some((c) => c.value) &&
																conditions
																	.filter((c) => c.value)
																	.map((c, i) => (
																		<h3
																			className={
																				'text-danger checklist-info'
																			}
																			key={i}
																		>
																			<i className="fa fa-times" />{' '}
																			{t(
																				'platform check failure ' +
																					c.text,
																				c.text,
																			)}
																		</h3>
																	))}
														</div>
													))}

													<hr />

													<div className={'text-center'}>
														{/* qui metto i pulsanti con le varie azioni per step */}
														{steps.map(
															({ actionText, actionUrl }, i) => (
																<Link
																	key={i}
																	to={actionUrl}
																	className={
																		'checklist-action btn btn btn-' +
																		actionsColor[i]
																	}
																>
																	{i + 1}. {actionText}
																</Link>
															),
														)}
													</div>
												</CardBody>
											</Card>
										</Col>
										<Col sm={4}>
											<Card>
												<CardBody>
													<h2 className={'float-right'} />
													<div
														style={{ marginBottom: '1.2rem' }}
													>{t`platform for any info write us at`}</div>
													<h2 className={'text-center'}>
														<a
															href={`mailto:${CONFIG.SUPPORT_EMAIL}`}
														>
															<i className={'mdi mdi-email'} />{' '}
															{CONFIG.SUPPORT_EMAIL}
														</a>
													</h2>
												</CardBody>
											</Card>
										</Col>
									</Row>
								</>
							);
						}}
					</Query>
				);
			}}
		</UserData>
	);
};
