import React from 'react';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { Query } from 'react-apollo';
import { t } from '../utils/labels';
import logoIFN from '../static/assets/logo-ifn.svg';

interface PlatformHomeCardProps {
	platform: string;
	company: any;
}

const isPresentOnIFNQuery = gql`
	query IsPresentOnIFNQuery($filter: CompaniesFilterType) {
		companiesDir(filter: $filter) {
			total
		}
	}
`;

export const PlatformHomeCard: React.FunctionComponent<
	PlatformHomeCardProps
> = ({ platform, company }) => (
	<Query<any, any>
		query={isPresentOnIFNQuery}
		variables={{
			filter: { items: [{ id: company.id }], targetSite: platform },
		}}
	>
		{({ error, loading, data }) => {
			if (error) {
				console.error(error);
				return null;
			}

			if (loading) {
				return null;
			}

			const isPresent = data.companiesDir.total > 0;

			return (
				<Card>
					<CardBody className={'card-info'}>
						<div className={'platform-home-card'}>
							<div>
								<h2>{t(`${platform} Platform`)}</h2>
							</div>
							<img src={logoIFN} alt={'ifn platform logo'} />
						</div>
						<div className={'text-center'}>
							<div>
								<Link
									className={'btn btn-success btn-lg'}
									to={`/platforms/${platform}/checklist`}
								>
									{t(
										isPresent
											? 'update you company'
											: 'add your company',
									)}{' '}
									<i className="fa fa-rocket" />
								</Link>
							</div>
						</div>
					</CardBody>
				</Card>
			);
		}}
	</Query>
);
