import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { FrequentSearchListQuery } from '../graphql/query/FrequentSearchListQuery';
import typeIntro from '../static/introspection/FrequentSearch.json';
import FrequentSearchFilters from '../static/introspection/FrequentSearchFilterInput.json';
import { ENTITY } from '../utils/entities';
import { t } from '../utils/labels';
import { ACTIONS, generateUrl } from '../utils/urls';
import { fastTransformer } from '../utils/form';
import {
	generateAsyncQuery,
	generatePaginatedSelectQuery,
} from '../utils/graphql';
import { assign } from '../utils/misc';

const FrequentSearchList = () => (
	<EntityListFetcher
		query={FrequentSearchListQuery}
		entityName={ENTITY.FREQUENT_SEARCH}
		headers={['name', 'collection', 'User']}
		typeIntro={typeIntro}
		filtersIntro={FrequentSearchFilters}
		filterFields={[
			'name',
			'collection',
			'targetSite',
			'statuses',
			'noUser',
			'User',
			'EditorialSection',
			'relatedFair',
		]}
		additionalActions={[
			{
				icon: 'fa-plus',
				name: t`add static frequentSearch`,
				path:
					generateUrl(ENTITY.FREQUENT_SEARCH, ACTIONS.LIST) +
					'/static/new',
			},
		]}
		detailModalMode={false}
		detailUrl={(e: any) =>
			generateUrl(ENTITY.FREQUENT_SEARCH, ACTIONS.LIST) +
			(e.isDynamic ? '' : '/static') +
			'/' +
			e.id
		}
		filterTransform={fastTransformer({
			relatedFair: assign({
				asyncQuery: generateAsyncQuery(
					generatePaginatedSelectQuery(ENTITY.FAIR, false),
					true,
				),
			}),
		})}
	/>
);

export { FrequentSearchList };
