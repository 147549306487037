import gql from 'graphql-tag';

export const FairCreateQuery = gql`
	query FairCreateQuery {
        editorialSectionTree(EditorialBrand: GOODS4HUB) {
            id
            name
            Parent {
                id
            }
            goodClasses {
                id
                shortname
                shortnameIta
                localId
                name
                nameIta
            }
        }
	}
`;


