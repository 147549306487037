import gql from 'graphql-tag';
import {GeoIndicationFullFragment} from "../fragments/GeoIndicationFullFragment";

export const GeoIndicationDetailQuery = gql`
	query GeoIndicationDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...GeoIndicationFullFragment
		}
	}
	${GeoIndicationFullFragment}
`;


