import gql from 'graphql-tag';
import { MediaBaseFragment } from './MediaBaseFragment';

export const FairFullFragment = gql`
	fragment FairFullFragment on Fair {
		id
		localId
		name
		edition
		year
		logoMedia {
			...MediaBaseFragment
		}
		begin
		end
		targetSites
		goodClasses {
			id
			name
		}
		description
		descriptionIta
		website
		Country {
			id
			name
		}
		StateProvince {
			id
			name
		}
		postalCode
		city
		address1
		address2
		lat
		lng
		newsPath
		newsCitations {
			id
			News {
				id
				name: title
				title
			}
			count
		}
		ourPavilion
		ourStand
		ourNote
	}
	${MediaBaseFragment}
`;
