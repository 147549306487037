import React from 'react';
import { FrequentSearchPatchMutation } from '../graphql/mutations/FrequentSearchPatchMutation';
import { FrequentSearchEditQuery } from '../graphql/query/FrequentSearchEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/FrequentSearch.json';
import inputTypeIntro from '../static/introspection/FrequentSearchPatchInput.json';
import { ENTITY } from '../utils/entities';
import {
	editorialSectionsToTree,
	getExpanded,
} from '../utils/tree';
import { graphqlQuery } from '../utils/graphql';
import { toRefInput } from '../utils/dataTrasform';
import { fastTransformer, refactorInfo, toTree } from '../utils/form';
import { TreeFieldType } from '../types/form';

export const FrequentSearchEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(FrequentSearchEditQuery, { id })}
		mutation={FrequentSearchPatchMutation}
		entityName={ENTITY.FREQUENT_SEARCH}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={[
			'collection',
			'targetSites',
			'name',
			'nameIta',
			'akas',
			'goodClasses',
			'highlightReasons',
			'plProducer',
			'awardFinalPositions',
			'textSearch',
			'reveneueRanges',
			'pricePositionings',
			'awards',
			'goodFeatureCertifications',
			'geoIndications',
			'companyCertifications',
			'fairs',
			'stateProvinces',
		]}
		entitiesToFields={(props) => ({
			EditorialSections: props.editorialSectionTree,
		})}
		transformer={(data, entity, listsByType) => {
			data.fields = fastTransformer({
				goodClasses: (f) => {
					if (f.type !== 'AsyncChoices') {
						refactorInfo(f, 'AsyncChoices');
						return f;
					}

					const newField: TreeFieldType = toTree(
						f,
						false,
						editorialSectionsToTree(listsByType.EditorialSections),
					);

					if (f.value) {
						newField.value = f.value.map((choice) =>
							toRefInput(choice.value),
						);
						newField.expanded = getExpanded(
							listsByType.EditorialSections,
							f.value,
						);
					}

					return newField;
				},
			})(data.fields);
			return data;
		}}
	/>
);
