import React from 'react';
import { TeamCreateMutation } from '../graphql/mutations/TeamCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Team.json';
import inputTypeIntro from '../static/introspection/TeamCreateInput.json';
import { TeamEditQuery } from '../graphql/query/TeamEditQuery';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const TeamCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(TeamEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={TeamCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.TEAM}
		fields={['name', 'Retailer']}
	/>
);
