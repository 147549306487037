import gql from 'graphql-tag';
import { t } from '../utils/labels';
import { always, countBy, identity } from 'ramda';
import { ReportType } from '../components/Report';
import { nodes } from '../utils/misc';
import { generateAsyncQuery } from '../utils/graphql';
import { AwardSelectQuery } from '../graphql/query/AwardSelectQuery';
import { generateBaseField, toAsyncChoices } from '../utils/form';
import { ENTITY } from '../utils/entities';
import { InternalCapability } from '../server-types';

export const votantiPerCategoria: ReportType = {
	filename: always('Votanti_per_categoria'),
	title: 'Votanti per categoria',
	requirements: [InternalCapability.DirectoryExtra],
	preQuery: gql`
       query PreQueryAward($id: ID!) {
           award: node(id: $id) {
               id
               ... on Award {
                   managerUser {
	                    id
	                    email
                   }
               }
           }
       }
	`,
	customRequirementsCheck: (pre, user) => {
		const manager = pre.award.managerUser;
		
		if (!manager) {
			return new Error('Nessun utente manager impostato per il premio');
		}
		
		if (manager.id !== user.id) {
			return new Error('Permessi insufficienti per accedere alle informazioni. Utente responsabile: ' + manager.email);
		}
		
		return null;
	},
	query: gql`
		query VotantiQuery(
			$after: String
			$filter: CandidacyRatingFilterInput
			$first: Int
		) {
			connector: candidacyRatings(
				after: $after
				filter: $filter
				first: $first
			) {
				edges {
					node {
						id
						localId
						User {
							id
							name
							email
						}
						Candidacy {
							id
							AwardCategory {
								id
								name
							}
						}
					}
				}
				pageInfo {
					hasNextPage
				}
			}
		}
	`,
	fields: [
		toAsyncChoices(
			ENTITY.AWARD,
			generateAsyncQuery(AwardSelectQuery, true),
			true,
			generateBaseField('Award', 'Award'),
		),
	],
	header: () => [t`Votante`, t`Email`, t`Categoria`, t`Candidature votate`],
	variablesGenerator: (pre: any, values: any) => ({
		filter: { Award: { id: values.Award.value.id } },
	}),
	preVariablesGenerator: (values: any) => ({ id: values.Award.value.id }),
	extractor: (data: any) => nodes(data.connector),
	globalTransformer: (records) => {
		const results = [];
		const users = {};
		const votes = {};
		records.forEach((record) => {
			const user = record.User;
			const category = record.Candidacy.AwardCategory.name;
			if (!(user.email in users)) {
				users[user.email] = {
					name: user.name,
					email: user.email,
				};
				votes[user.email] = [];
			}
			votes[user.email].push(category);
		});

		Object.keys(votes).forEach((email) => {
			const counts = Object.entries(countBy(identity, votes[email]));
			counts.forEach(([category, num]) => {
				results.push([users[email].name, email, category, num]);
			});
		});

		return results;
	},
	transformer: identity,
};
