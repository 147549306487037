export enum ACTIONS {
	CLONE,
	CREATE,
	EDIT,
	DETAIL,
	LIST,
}

const capitalizeFirstLetter = (s: string | null) =>
	s !== null ? s.charAt(0).toLowerCase() + s.slice(1) : '';

function pluralName(typeName: string): string {
	typeName = capitalizeFirstLetter(typeName);

	if (typeName === 'news') return 'news';

	switch (typeName[typeName.length - 1]) {
		case 's':
		case 'h':
			return typeName + 'es';
		case 'y':
			return typeName.substr(0, typeName.length - 1) + 'ies';
		default:
			return typeName + 's';
	}
}

function generateUrl(entityName, action: ACTIONS, entityId?) {
	const pieces = entityName.split('_', 2);
	let url = '/' + pluralName(pieces[0]).toLowerCase();

	if (pieces[1]) url += '_' + pieces[1];

	if (entityId) url += '/' + encodeURIComponent(entityId);

	if (action === ACTIONS.CREATE) url += '/new';
	else if (action === ACTIONS.EDIT) url += '/edit';
	else if (action === ACTIONS.CLONE) url += '/clone';
	return url;
}

export { generateUrl, capitalizeFirstLetter, pluralName };
