import gql from 'graphql-tag';
import { MediaBaseFragment } from '../fragments/MediaBaseFragment';

export const MediaModalListQuery = gql`
	query MediaListQuery($filter: MediaFilterInput) {
		medias(filter: $filter) {
			edges {
				node {
					...MediaBaseFragment
					 caption
				}
			}
          total
          pageInfo {
              hasNextPage
              hasPreviousPage
          }
		}
	}
	${MediaBaseFragment}
`;
