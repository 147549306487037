import React from 'react';
import MenuList from '../components/MenuList';
import { Link } from 'react-router-dom';
import { MenuItem } from '../static/menu';

export const Sidebar: React.FC<{ menu: ReadonlyArray<MenuItem> }> = ({
	menu,
}) => (
	<aside className="left-sidebar">
		<div className="scroll-sidebar">
			<div className="user-profile">
				<Link to="/">
					<img
						src={process.env.PUBLIC_URL + '/logo_gruppo.svg'}
						alt="logo"
						id="logo"
					/>
				</Link>
			</div>
			<MenuList voices={menu} />
		</div>
	</aside>
);
