import gql from 'graphql-tag';
import { AwardFullFragment } from '../fragments/AwardFullFragment';

export const AwardEditQuery = gql`
	query AwardEditQuery($id: ID!) {
		entity: node(id: $id) {
			...AwardFullFragment
		}
	}
	${AwardFullFragment}
`;


