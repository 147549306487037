import React from 'react';
import { Badge } from 'reactstrap';
import { t } from './labels';
import { formatDate, IChoice, renderEntityRO } from './misc';
import { UserDataInterface } from '@food/auth';
import { ENTITY } from './entities';

export interface IBooleanListField {
	name: string;
	type: 'Boolean';
	value: boolean;
}

export interface ITextListField {
	name: string;
	type: 'Text';
	value: string;
}

export interface IUrlListField {
	name: string;
	type: 'Url';
	to?: string;
	value: string;
}

export interface IIntListField {
	name: string;
	type: 'Int';
	value: number;
}

export interface IEntityListField {
	name: string;
	type: 'Entity';
	value: any;
	mapTo?: ENTITY;
	url?: string;
	myToString?: (e: any) => string;
}

export interface IDateListField {
	name: string;
	type: 'Date';
	value: Date;
	description: {
		dtPrecision: DT_PRECISION;
	};
}

export interface IChoiceListField {
	name: string;
	type: 'Choices';
	single: boolean;
	value: IChoice | IChoice[];
}

export interface IAsyncChoiceListField {
	name: string;
	type: 'AsyncChoices';
	value: any;
	mapTo?: ENTITY;
	url?: string;
	myToString?: (e: any) => string;
}

export interface ICustomListField {
	name: string;
	type: 'Custom';
	value: JSX.Element;
}

export type IListField =
	| IBooleanListField
	| ITextListField
	| IUrlListField
	| IIntListField
	| IEntityListField
	| IDateListField
	| IChoiceListField
	| IAsyncChoiceListField
	| ICustomListField;

export enum DT_PRECISION {
	YEAR_BEGIN = 'YEAR_BEGIN',
	YEAR_END = 'YEAR_END',
	MONTH_BEGIN = 'MONTH_BEGIN',
	MONTH_END = 'MONTH_END',
	DAY_BEGIN = 'DAY_BEGIN',
	DAY_END = 'DAY_END',
	HOUR_BEGIN = 'HOUR_BEGIN',
	HOUR_END = 'HOUR_END',
	MINUTE_BEGIN = 'MINUTE_BEGIN',
	MINUTE_END = 'MINUTE_END',
	FULL = 'FULL',
}

export const CellRenderer = (
	field: IListField,
	user: UserDataInterface,
): JSX.Element | string | null => {
	if (field.value === null || field.value === undefined) return null;

	switch (field.type) {
		case 'Boolean':
			return field.value ? (
				<Badge color="success">{t`yes`}</Badge>
			) : (
				<Badge color="danger">{t`no`}</Badge>
			);

		case 'Url':
			return (
				<a
					href={field.to || field.value}
					target="_blank"
					rel="noopener noreferrer"
				>
					{field.value}
				</a>
			);

		case 'Int':
			return field.value.toString();

		case 'Entity':
			return renderEntityRO(field, user, field.value);

		case 'Choices':
			if (!Array.isArray(field.value)) return t(field.value.label);
			else return field.value.map((e) => t(e.label)).join(', ');

		case 'AsyncChoices':
			return renderEntityRO(field, user, field.value.value);

		case 'Date':
			return formatDate(field.value, field.description.dtPrecision);

		case 'Custom':
			return field.value;

		default:
			return field.value;
	}
};
