import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import { t } from '../utils/labels';
import { errorToComponent } from '../utils/errors';

export class ErrorModal extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			errors: [],
		};
	}

	_open = (errors, closeHandler = null) =>
		this.setState({
			open: true,
			errors,
			closeHandler,
		});

	_close = () => {
		const handler = this.state.closeHandler;
		this.setState({
			open: false,
			errors: [],
		});
		if (handler) {
			handler();
		}
	};

	render() {
		const { open, errors } = this.state;

		return (
			<Modal
				isOpen={open}
				toggle={this._close}
				backdrop="static"
				autoFocus={false}
			>
				<ModalHeader toggle={this._close}>{t`Error`}</ModalHeader>
				<ModalBody>
					{errors.map((error, idx) => (
						<section key={idx}>{errorToComponent(error)}</section>
					))}
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={this._close}>
						<i className="fa fa-times" /> {t`Close`}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}
