import React, { useCallback } from 'react';
import {
	arrayMove,
	SortableContainer,
	SortableElement,
	SortableHandle,
} from 'react-sortable-hoc';
import { registerClass, IFNTheme, cns, currySC } from '@food/css-manager';
import { FieldMuter, IField } from '../types/form';

export interface IItemViewProps {
	item: any;
	readonly: boolean;
	path: string;
	index?: number;
	changeHandler?: (field: IField) => FieldMuter;
	ordered: boolean;
}
type ItemType = any;
type moveHandlerType = (newList: ItemType[]) => void;
type removeHandlerType = (item: any) => void;
type addHandlerType = (index?: number) => void;
type cloneHandlerType = (item?: any, index?: number) => void;
type listItem =
	| React.ComponentClass<IItemViewProps>
	| React.FunctionComponent<IItemViewProps>;

interface SortableListProps {
	items: ReadonlyArray<ItemType>;
	itemView: listItem;
	actions?: listItem;
	readonly?: boolean;
	onMove?: moveHandlerType;
	onRemove?: removeHandlerType;
	onAddToList?: addHandlerType;
	onClone?: cloneHandlerType;
	max?: number;
	ordered?: boolean;
	voiceChangeHandler?: (field: IField) => FieldMuter;
	path: string;
}

const itemClass = registerClass(
	(theme: IFNTheme, sc) => `
	margin-top: 1rem;
	border-top: 1px solid #e0e0e0;
	border-bottom: 2px dashed #e0e0e0;
	background-color: ${theme.colors.white};
	display: flex;
	justify-content: space-between;
	position: relative;
	
	.${sc('big-icon')} {
		font-size: 2rem;
		cursor: pointer;
		margin-left: 1rem;
	}
	
	.${sc('actions')} {
		flex-shrink: 0;
	}

	.${sc('addItemBetween')} {
		position: absolute;
		top: -1.3rem;
		left: 0;
		right: 0;
		height: 1.5rem;

		div {
			position: absolute; 
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 2rem;
			height: 2rem;
			border-radius: 100%;
			background: ${theme.colors.white};
			border: 2px solid ${theme.colors.success.plain};
			color: ${theme.colors.success.plain};
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.2rem;
			font-weight: ${theme.font.weights.semibold};
			cursor: pointer;
			pointer-events: none;
			opacity: 0;
			transition: opacity 0.3s ease-out;
		}

		&:hover {
			div {
				opacity: 1;
				pointer-events: all;
			}
		}
	}
`,
);

const sc = currySC(itemClass);
const ChildrenFn: React.FunctionComponent<any> = ({ children }) => children;
const SortableItem = SortableElement(ChildrenFn);
const DragHandle = SortableHandle(() => (
	<i className={cns('mdi mdi-cursor-move', sc('big-icon'))} />
));
const listClass = registerClass(() => `padding-left: 0;`);

const InternalSortable = SortableContainer(
	// @ts-ignore
	({ items, readonly, onRemove, onAddToList, onClone, max, View, ordered, voiceChangeHandler, path }) => {
		return (
			<ul className={listClass}>
				{items !== undefined &&
					items.map((item, index) => (
						<SortableItem key={`item-${item.name}`} index={index}>
							<div className={itemClass}>
								{(!max || (items && items.length < max)) &&
									<div className={sc('addItemBetween')}>
										<div
											onClick={() => onAddToList(index)}
										>
											+
										</div>
									</div>
								}
								<View
									item={item}
									index={index}
									changeHandler={voiceChangeHandler}
									path={path}
									ordered={ordered}
								/>
								<div className={sc('actions')}>
									{!readonly && (
										<>
											{ordered && items.length > 1 && <DragHandle />}
											<i
												onClick={() => onRemove(item)}
												className={cns(
													'mdi mdi-delete',
													sc('big-icon'),
												)}
											/>
											{onClone ?
												<i
													onClick={() => onClone(item, index)}
													className={cns(
														'mdi mdi-content-copy',
														sc('big-icon'),
													)}
												/> : ''}
										</>
									)}
								</div>
							</div>
						</SortableItem>
					))}
			</ul>
		);
	},
);

export const SortableList: React.FunctionComponent<SortableListProps> = ({
	onMove,
	items,
	itemView: View,
	readonly,
	onRemove,
	onAddToList,
	onClone,
	max,
	ordered,
	voiceChangeHandler,
	path,
}) => {
	const move = useCallback(
		({ oldIndex, newIndex }) =>
			onMove(arrayMove([...items], oldIndex, newIndex)),
		[items, onMove],
	);

	return (
		<div>
			<InternalSortable
				onSortEnd={move}
				useDragHandle={true}
				ordered={ordered}
				View={View}
				readonly={readonly}
				onRemove={onRemove}
				onAddToList={onAddToList}
				onClone={onClone}
				items={items}
				voiceChangeHandler={voiceChangeHandler}
				path={path}
				max={max}
			/>
		</div>
	);
};
