export default {
	'Company/label': 'Azienda',
	'Company/name/label': 'Nome Azienda',
	'Company/name/helpText': 'Inserire la denominazione pubblica dell’azienda',
	'Company/billingName/label': 'Ragione Sociale',
	'Company/billingName/helpText':
		'Inserire la denominazione della ragione sociale specificando anche il suffisso (S.R.L., S.P.A.)',
	'Company/logoMedia/label': 'Logo azienda',
	'Company/logoMedia/helpText':
		"Lunghezza minima della base o dell'Altezza: 800 pixel - Formato Preferibile: PNG (Sfondo Trasparente) - Modalità Colore: RGB",
	'Company/industry/label': 'Settore',
	'Company/industry/helpText': "Selezionare il settore in cui l'azienda opera",
	'Company/status/label': 'Stato',
	'Company/status/helpText': "la Pagina dell'Azienda è attiva o meno",
	'Company/level/label': 'Livello',
	'Company/level/helpText': "Livello di Sottoscrizione dell'azienda",
	'Company/ownership/label': 'Proprietà',
	'Company/ownership/helpText':
		"Inserire la voce che meglio descrive la struttura societaria dell'azienda",
	'Company/yearEstablished/label': 'Anno di Fondazione',
	'Company/yearEstablished/helpText':
		"Inserire l'anno in cui l'azienda ha iniziato l'attività",
	'Company/description/label': 'Descrizione breve in Inglese',
	'Company/description/helpText':
		'Inserire una Descrizione breve in inglese. Compilare con attenzione, il testo sarà utilizzato per fini editoriali.',
	'Company/descriptionIta/label': 'Descrizione breve in italiano',
	'Company/descriptionIta/helpText':
		'Inserire una breve descrizione in italiano. Compilare con attenzione, il testo sarà utilizzato per fini editoriali.',
	'Company/descriptionLong/label': 'Descrizione completa in inglese',
	'Company/descriptionLong/helpText':
		'Inserire una descrizione dettagliata in inglese. Compilare con attenzione, il testo sarà utilizzato per fini editoriali.',
	'Company/descriptionLongIta/label': 'Descrizione completa in italiano',
	'Good/descriptionLongIta/helpText':
		"Inserire una descrizione dettagliata in italiano (es. metodo di produzione, ingredientistica, combinazioni/modi d'uso/abbinamenti, particolarità). Compilare con attenzione, il testo sarà utilizzato per fini editoriali.",
	'Company/atecoCode_id/label': 'Codice Ateco',
	'Company/atecoCode_id/helpText':
		'Inserisci il Codice Ateco con cui l’azienda è registrata',
	'Company/pecEmail/label': 'Posta Elettronica Certificata',
	'Company/pecEmail/helpText': 'Inserisci la PEC Aziendale',
	'Company/vatNumber/label': 'Partita IVA',
	'Company/vatNumber/helpText':
		"Inserire il numero di Partita IVA preceduto dall'acronimo Statale - es.ITXXXXXXXXXXX",
	'Company/website/label': 'Sito Web',
	'Company/website/helpText':
		'Inserire il sito web aziendale preceduto da https://',
	'Company/plProducer/label': 'Produttore di Private Label',
	'Company/plProducer/helpText':
		"Specificare se l'azienda rifornisce di prodotti private label aziende terze",
	'Company/companyEmail/label': 'Indirizzo e-mail generico',
	'Company/companyEmail/helpText': 'Inserire un indirizzo e-mail attivo',
	'Company/brands/label': 'Brands',
	'Company/brands/helpText': "Inserisci i Marchi registrati dall'Azienda",
	'Company/goodClasses/label': 'Tipologie di Alimenti prodotti',
	'Company/goodClasses/helpText':
		"Inserisci le tipologie di prodotti alimentari commercializzati dall'azienda. La categorizzazione è basata sugli Standard GS1. Essere accurati in questo campo velocizzerà sensibilmente l'inserimento dei singoli Prodotti",
	'Company/companyCertifications/label': 'Certificazioni',
	'Company/companyCertifications/helpText':
		"Selezionare le certificazioni ottenute dall'azienda",
	'Brand/label': 'Brand',
	'Brand/labelPlural': 'Brands',
	'Brand/name/label': 'Nome',
	'Brand/name/helpText': 'Inserire il nome del brand',
	'Brand/logoMedia/label': 'Logo del Brand',
	'Brand/logoMedia/helpText':
		"Lunghezza minima della base o dell'Altezza: 800 pixel - Formato Preferibile: PNG (Sfondo Trasparente) - Modalità Colore: RGB",
	'Brand/pricePositioning/label': 'Posizionamento di Prezzo',
	'Brand/pricePositioning/helpText':
		'Selezionare il posizionamento di prezzo più appropriato per i prodotti del brand',
	'Brand/website/label': 'Sito Web',
	'Brand/website/helpText':
		'Qualora esista, inserire il sito web del brand preceduto da http:// o https://',
	'Brand/retailChainDistribution/label': 'Distribuito nel canale Retail',
	'Brand/retailChainDistribution/helpText': '',
	'Brand/foodServiceChainDistribution/label': 'Distribuito nel canale Food Service',
	'Brand/foodServiceChainDistribution/helpText': '',
	'Brand/gourmetShopChainDistribution/label': 'Distribuito nel canale tradizionale / Negozi Gourmet',
	'Brand/gourmetShopChainDistribution/helpText': '',
	'Brand/Company/label': 'Azienda',
	'Brand/Company/helpText': '',
	'Brand/order/label': 'Ordine di visualizzazione',
	'Brand/socialInstagramPage/label': 'Pagina Instagram',
	'Brand/socialInstagramPage/helpText': 'Inserire URL pagina Instagram',
	'Brand/socialFacebookPage/label': 'Pagina Facebook',
	'Brand/socialFacebookPage/helpText': 'Inserire URL pagina Facebook',
	'Good/label': 'Prodotto',
	'Good/localId/label': 'Local Id',
	'GoodFormat/localId/label': 'Local Id',
	'CompanyGroup/NewsCitation/label': 'Notizie correlate',
	'CompanyGroup/ManagedByUser/label': 'Utenti',
	'Good/labelPlural': 'Prodotti',
	'Good/Brand/label': 'Brand',
	'Good/Brand/helpText':
		'Selezionare il brand del prodotto dall\'elenco o crearlo cliccando il bottone "+ Crea"',
	'Good/featuredImageMedia/label': 'Immagine del prodotto sul mercato internazionale',
	'Good/featuredImageMedia/helpText':
		"Lunghezza minima della base o dell'altezza: 1200 pixel - Formato: JPG/PNG - Modalità Colore: RGB - Raccomandiamo un'immagine scontornata con sfondo bianco o trasparente",
	'Good/status/label': 'Stato',
	'Good/status/helpText': 'Definisce se il prodotto è attivo o meno',
	'Good/GoodClass/label': 'Categoria Merceologica del Prodotto',
	'Good/GoodClass/helpText': '',
	'Good/range/label': 'Linea di Prodotti',
	'Good/range/helpText':
		'Se presente, inserire il nome della linea di prodotto',
	'Good/name/label': 'Nome in inglese',
	'Good/name/helpText':
		"Inserire il nome con cui il prodotto è distribuito all'estero",
	'Good/descriptionLong/label': 'Descrizione accurata in inglese',
	'Good/descriptionLong/helpText':
		"Inserire una descrizione dettagliata in inglese (es. metodo di produzione, ingredientistica, combinazioni/modi d'uso/abbinamenti, particolarità). Compilare con attenzione, il testo sarà utilizzato per fini editoriali.",
	'Good/descriptionLongIta/label': 'Descrizione accurata in italiano',
	'Company/descriptionLongIta/helpText':
		'Inserire una descrizione dettagliata in italiano. Compilare con attenzione, il testo sarà utilizzato per fini editoriali.',
	'Good/shelfLifeLength/label': 'Shelf Life',
	'Good/shelfLifeLength/helpText':
		'Inserire la shelf life in numero di giorni',
	'Good/Company/label': 'Azienda',
	'Good/companyName/filter/label': 'Nome dell’azienda',
	'Good/brandName/filter/label': 'Nome del brand',
	'Good/formats/label': 'Formati di vendita',
	'Good/featuredImageItaMedia/label': 'Immagine del prodotto sul mercato italiano',
	'Good/featuredImageItaMedia/helpText':
		"Lunghezza minima della base o dell'altezza: 1200 pixel - Formato: JPG/PNG - Modalità Colore: RGB - Raccomandiamo un'immagine scontornata con sfondo bianco o trasparente",
	'Good/description/label': 'Descrizione breve in inglese',
	'Good/description/helpText':
		'Inserire una Descrizione breve in inglese. Compilare con attenzione, il testo sarà utilizzato per fini editoriali.',
	'Good/descriptionIta/label': 'Descrizione breve in italiano',
	'Good/descriptionIta/helpText':
		'Inserire una breve descrizione in italiano. Compilare con attenzione, il testo sarà utilizzato per fini editoriali.',
	'Good/extraMedia/label': 'Altro Media INT',
	'Good/extraMedia/helpText':
		'Altro Media relativo al prodotto distribuito nel mercato internazionale',
	'Good/extraItaMedia/label': 'Altro Media ITA',
	'Good/extraItaMedia/helpText':
		'Altro Media relativo al prodotto distribuito nel mercato italiano',
	'Good/formats/helpText':
		'Insere gli SKU con i quali può essere venduto il prodotto',
	'Good/productionBranches/label': 'Stabilimenti Produttivi',
	'Good/productionBranches/helpText': '',
	'Good/Brand/filter/label': 'Brand',
	'Good/Company/filter/label': 'Azienda',
	'Good/nameIta/label': 'Nome in italiano',
	'Good/nameIta/helpText':
		'Inserire il nome con cui il prodotto è distribuito in Italia',
	'Good/GoodFormat/label': 'Formati di vendita',
	'GoodFormat/ean/label': 'EAN',
	'GoodFormat/ean/helpText': "codice EAN dell'SKU",
	'GoodFormat/cuMesureValue/label': 'Quantità',
	'GoodFormat/cuMesureValue/helpText':
		'Indicare la massa o il volume del formato di vendita',
	'GoodFormat/MesureValue/label': 'Unità di Misura',
	'GoodFormat/MesureValue/helpText': '',
	'GoodFormat/flavor/label': 'Gusto/Varietà in inglese',
	'GoodFormat/flavor/helpText': '',
	'GoodFormat/flavorIta/label': 'Gusto/Varietà in italiano',
	'GoodFormat/flavorIta/helpText': '',
	'GoodFormat/cuPerSellingPack/label': 'Numero di Unità per SKU',
	'GoodFormat/cuPerSellingPack/helpText':
		'Indicare il numero di unità per SKU',
	'GoodFormat/labelPlural': 'Formati di vendita',
	'Good/goodFeatureCertifications/label': 'Certificazioni e peculiarità',
	'Good/goodFeatureCertifications/helpText':
		'Selezionare le certificazioni e/o le caratteristiche del prodotto',
	Good: 'Prodotto',

	'Good/status/filter': 'Stato',
	'GoodFormat/cuMesureUnit/label': 'Unità di misura',
	'GoodUnit/MILLILITER/label': 'Millilitri',
	'GoodUnit/GRAMS/label': 'Grammi',
	'GoodFormat/MILLILITER/label': 'Millilitri',
	'GoodFormat/GRAMS/label': 'Grammi',

	'GoodFormat/MILLIGRAMS/label': 'Milligrammi',
	'GoodUnit/MILLIGRAMS/label': 'Milligrammi',

	'GoodFormat/cuMesureUnit/helpText': "Selezionare l'unità di misura",

	'GoodFormat/unitsPerBox/label': 'Unità per scatola',
	'GoodFormat/unitsPerBox/helpText': 'inserire il numero di sku presenti in una scatola',
	'GoodFormat/packaging/label': 'Packaging',
	'GoodFormat/packaging/helpText': 'inserire il materiale principale di imballaggio',

	'Packaging/AluminiumCan/label': 'Lattina - alluminio',
	'Packaging/AluminiumJar/label': 'Barattolo - alluminio',
	'Packaging/PlasticJar/label': 'Barattolo - plastica',
	'Packaging/GlassJar/label': 'Barattolo - vetro',
	'Packaging/PETBottle/label': 'Bottiglia - plastica PET',
	'Packaging/TetraPakBottle/label': 'Bottiglia - tetrapak',
	'Packaging/GlassBottle/label': 'Bottiglia - vetro',
	'Packaging/DarkGlassBottle/label': 'Bottiglia - vetro scuro',
	'Packaging/VacuumBag/label': 'Busta Sottovuoto',
	'Packaging/PaperPackage/label': 'Confezione - carta',
	'Packaging/CellophaneWrapper/label': 'Confezione - cellophane',
	'Packaging/PlasticPackage/label': 'Confezione - plastica',
	'Packaging/MicrowaveSafeMaterials/label': 'Materiali compositi e laminati(Tetrapak)',
	'Packaging/Tinplate/label': 'Latta',
	'Packaging/luminiumCan/label': 'Lattina - alluminio',
	'Packaging/BiodegradableMaterials/label': 'Materiali Biodegradabili',
	'Packaging/Pouch/label': 'Pouch',
	'Packaging/CardboardBox/label': 'Scatola - cartone',
	'Packaging/MultiComponentPackage/label': 'Imballaggi misti',
	 
	'GoodFormat/status/label': 'Stato',
	'GoodFormat/status/helpText': 'imposta se il prodotto è attualmente in commercio o è stato ritirato dal mercato',


	'Status/Discontinued/label': 'Ritirato',
	'Status/OnTheMarket/label': 'In commercio',

	'Good/Has EAN/label': 'Il prodotto ha EAN',
	'Candidacy/label': 'Candidatura',
	'Candidacy/labelPlural': 'Candidature',
	'Candidacy/name/label': 'Nome',
	'Candidacy/name/helpText':
		"Collegando un solo prodotto alla candidatura, questa erediterà il suo nome. Collegandone più d'uno occorre inserire una dicitura che raggruppi tutti i prodotti.",
	'Candidacy/Brand/label': 'Brand',
	'Candidacy/Brand/helpText':
		'Selezionare il brand del prodotto / dei prodotti che vuoi candidare',
	'Candidacy/Award/label': 'Manifestazione',
	'Candidacy/Award/helpText': '',
	'Candidacy/Company/label': 'Azienda',
	'Candidacy/Company/helpText': '',
	'Candidacy/goods/label': 'Prodotti',
	'Candidacy/goods/labelSingle': 'Prodotto',
	'Candidacy/goods/helpText': 'Selezionare il prodotto',
	'Candidacy/goods/helpTextSingle': 'Selezionare un prodotto',
	'Candidacy/formats/label': 'Formati',
	'Candidacy/formats/helpText':
		'Elenca i formati di vendita (es. 125 g, 500 mL, 6x1,5L etc.)',
	'Candidacy/featuredImageMedia/label': 'Immagine della candidatura',
	'Candidacy/featuredImageMedia/helpText':
		"Lunghezza minima della base o dell'altezza: 1200 pixel - Formato: JPG/PNG - Modalità Colore: RGB - Raccomandiamo un'immagine scontornata con sfondo bianco o trasparente",
	'Candidacy/range/label': 'Linea di Prodotto',
	'Candidacy/range/helpText':
		'Inserire il nome della Linea di Prodotto a cui appartiene/appartengono i prodotti candidati, Lascia il campo vuoto se non appartiene ad alcuna.',
	'Candidacy/finalPosition/label': 'Piazzamento',
	'Candidacy/finalPosition/helpText': '',
	'Candidacy/candidacyRatings/label': 'Punteggi',
	'Candidacies/labelPlural': 'Candidature',
	'Candidacy/AwardCategory/label': 'Categoria della Manifestazione',
	'Candidacy/AwardCategory/helpText': '',
	'Candidacy/goodFeatureCertifications/label': 'Certificazioni e peculiarità',
	'Candidacy/goodFeatureCertifications/helpText':
		'Selezionare le certificazioni e/o le caratteristiche del prodotto',
	'Candidacy/candidacyRatings/helpText': '',
	'Candidacy/name/filter/label': 'Nome',
	'Candidacy/producerChangedAfter/filter/label': 'Ultima Modifica',
	'Candidacy/description1/label': 'Descrizione1',
	'Candidacy/description1/helpText': '',
	'Candidacy/description2/label': 'Descrizione2',
	'Candidacy/description2/helpText': '',
	'Candidacy/description3/label': 'Descrizione3',
	'Candidacy/description3/helpText': '',
	'Candidacy/description4/label': 'Descrizione4',
	'Candidacy/description4/helpText': '',
	'Candidacy/description5/label': 'Descrizione5',
	'Candidacy/description5/helpText': '',
	'Candidacy/extraMedia/label': 'Altri Media',
	'Candidacy/extraMedia/helpText': '',
	'Candidacy/launchDate/label': 'Data di Lancio',
	'Candidacy/launchDate/helpText':
		'Data in cui il prodotto è stato messo in commercio',
	'Candidacy/Award/filter/label': 'Manifestazione',
	'Candidacy/Brand/filter/label': 'Brand',
	'Candidacy/Company/filter/label': 'Azienda',
	'Candidacy/Category/label': 'Categoria',
	'CandidacyRating/User/label': 'Utente',
	'CandidacyRating/overall/label': 'Globale',
	'CandidacyRating/rating1/label': 'Rating 1',
	'CandidacyRating/askSample/label': 'Richiedi Campione',
	'User/name/label': 'Nome Cognome',
	'User/firstName/label': 'Nome',
	'User/firstName/helpText': '',
	'User/sfId/label': 'Salesforce Id',
	'User/lastName/label': 'Cognome',
	'User/lastName/helpText': '',
	'User/email/label': 'E-mail',
	'User/email/helpText': '',
	'User/role/label': 'Ruolo',
	'User/role/helpText': '',
	'User/status/label': 'Stato',
	'User/status/helpText': '',
	'User/managedCompanies/label': 'Aziende Gestite',
	'ManagedCompany/label': 'Azienda Gestita',
	'ManagedCompany/labelPlural': 'Aziende Gestite',
	'ManagedCompany/name/label': 'Azienda Gestita',
	'ManagedCompany/name/labelPlural': 'Aziende Gestite',
	'ManagedCompany/Company/label': 'Azienda Gestita',
	'ManagedCompany/Company/helpText': "aziende sui l'utente può operare",
	'ManagedCompany/edit/label': 'Can edit',
	'ManagedCompany/edit/helpText': 'Can edit content',
	'ManagedCompany/purchase/label': 'Can purchase',
	'ManagedCompany/purchase/helpText': "l'utente può fare acquisti",
	'ManagedCompany/manage/label': 'Can manage',
	'ManagedCompany/manage/helpText': 'Can manage users and subscriptions',
	'ManagedCompany/publish/label': 'Can publish',
	'ManagedCompany/publish/helpText': 'Can publish content',
	'Award/label': 'Manifestazione',
	'Award/labelPlural': 'Manifestazioni',
	'Award/name/label': 'Nome',
	'Award/name/helpText': '',
	'Award/year/label': 'Anno',
	'Award/year/helpText': 'Anno solare in cui si svolge la manifestazione',
	'Award/edition/label': 'Edizione',
	'Award/edition/helpText':
		'numero progressivo delle edizioni delle manifestazioni',
	'Award/langCode/label': 'Language code',
	'Award/langCode/helpText': 'Ex: en_US, it_IT',
	'Award/logoMedia/label': 'Logo della Manifestazione',
	'Award/logoMedia/helpText':
		"Lunghezza minima della base o dell'Altezza: 800 pixel - Formato Preferibile: PNG (Sfondo Trasparente) - Modalità Colore: RGB",
	'Award/website/label': 'Sito Web',
	'Award/website/helpText':
		'Inserire il sito web della manifestazione con http:// o https://',
	'Award/maxPerCompanyCandidacies/label': 'Candidature massime per Azienda',
	'Award/maxPerCompanyCandidacies/helpText': '',
	'Award/maxGoodsPerCandidacy/label': 'Massimo di prodotti per Candidatura',
	'Award/maxGoodsPerCandidacy/helpText':
		'Massimo di prodotti assegnabili ad una candidatura',
	'Award/candidaciesCloseAt/label': 'Le candidature vanno presentate entro',
	'Award/candidaciesCloseAt/helpText':
		'ultimo giorno valido per candidare prodotti',
	'Award/votesCloseAt/label': 'I voti vanno presentati entro',
	'Award/votesCloseAt/helpText': 'ultimo giorno valido per votare candidature',
	'Award/description1Label/label': 'Label della prima descrizione della Candidatura',
	'Award/description1Label/helpText':
		'Format: <label>|<helpText> Leave empty to disable this description',
	'Award/description1Max/label': 'Massimo di Caratteri della prima descrizione',
	'Award/description1Max/helpText': '',
	'Award/description2Label/label': 'Label della seconda Descrizione della Candidatura',
	'Award/description2Label/helpText':
		'Format: <label>|<helpText> Leave empty to disable this description',
	'Award/description2Max/label': 'Massimo di Caratteri della seconda descrizione',
	'Award/description2Max/helpText': '',
	'Award/description3Label/label': 'Label della terza Descrizione della Candidatura',
	'Award/description3Label/helpText':
		'Format: <label>|<helpText> Leave empty to disable this description',
	'Award/description3Max/label': 'Massimo di Caratteri della terza descrizione',
	'Award/description3Max/helpText': '',
	'Award/description4Label/label': 'Label della Quarta Descrizione della Candidatura',
	'Award/description4Label/helpText':
		'Format: <label>|<helpText> Leave empty to disable this description',
	'Award/description4Max/label': 'Massimo di Caratteri della Quarta descrizione',
	'Award/description4Max/helpText': '',
	'Award/description5Label/label': 'Label della Quinta Descrizione della Candidatura',
	'Award/description5Label/helpText':
		'Format: <label>|<helpText> Leave empty to disable this description',
	'Award/description5Max/label': 'Massimo di Caratteri della Quinta descrizione',
	'Award/description5Max/helpText': '',
	'Award/rating1Label/label': 'First optional rating value label',
	'Award/rating1Label/helpText':
		'Format: <label>|<helpText> Leave empty to disable this rating',
	'Award/rating2Label/label': 'Second optional rating value label',
	'Award/rating2Label/helpText':
		'Format: <label>|<helpText> Leave empty to disable this rating',
	'Award/rating3Label/label': 'Thirth optional rating value label',
	'Award/rating3Label/helpText':
		'Format: <label>|<helpText> Leave empty to disable this rating',
	'Award/rating4Label/label': 'Fourth optional rating value label',
	'Award/rating4Label/helpText':
		'Format: <label>|<helpText> Leave empty to disable this rating',
	'Award/rating5Label/label': 'Fifth optional rating value label',
	'Award/rating5Label/helpText':
		'Format: <label>|<helpText> Leave empty to disable this rating',
	'Award/comment1Label/label': 'First optional rating comment label',
	'Award/comment1Label/helpText':
		'Format: <label>|<helpText> Leave empty to disable this rating',
	'Award/comment1Max/label': 'First optional rating comment max length',
	'Award/comment1Max/helpText': '',
	'Award/comment2Label/label': 'Second optional rating comment label',
	'Award/comment2Label/helpText':
		'Format: <label>|<helpText> Leave empty to disable this rating',
	'Award/comment2Max/label': 'Second optional rating comment max length',
	'Award/comment2Max/helpText': '',
	'Award/comment3Label/label': 'Thirth optional rating comment label',
	'Award/comment3Label/helpText':
		'Format: <label>|<helpText> Leave empty to disable this rating',
	'Award/comment3Max/label': 'Thirth optional rating comment max length',
	'Award/comment3Max/helpText': '',
	'Award/categories/label': 'Categorie',
	
	'Award/type/label':'Tipo di Manifestazione',
    'Award/type/helpText':'',
    'AwardType/PRODUCT_AWARD/label':'Award di Prodotto',
    'AwardType/COMPANY_AWARD/label':'Award di Azienda',
    'AwardType/PURCHASING_REQUEST/label':'Sourcing Purchasing Request',

	'AwardCategory/label': 'Categoria manifestazione',
	'AwardCategory/labelPlural': 'Categorie manifestazione',
	'AwardCategory/name/label': 'Nome',
	'AwardCategory/name/helpText': '',
	'AwardCategory/description/label': 'Descrizione',
	'AwardCategory/description/helpText': '',
	'AwardCategory/color/label': 'Colore',
	'AwardCategory/color/helpText': 'Colore personalizzato della categoria',
	'AwardCategory/giftUrl/label': 'URL of downloadable gift',
	'AwardCategory/giftUrl/helpText':
		"Buyers that vote all products in category are prompted with this URL. Leave empty if ther's no downloadable gift. Insert the Award Website with http:// or https://",
	'AwardCategory/Media/label': 'Icona',
	'AwardCategory/Media/helpText':
		'Preferred Format: SVG or PNG (Transparent Background) - Color Type: RGB',

	'UserRole/ADMIN/label': 'Amministratore',
	'UserRole/INTERNAL/label': 'Interno',
	'UserRole/EDITOR/label': 'Editor',
	'UserRole/PRODUCER/label': 'Produttore',
	'UserRole/BUYER/label': 'Buyer',

	'UserStatus/NOT_VERIFIED/label': 'Non verificato',
	'UserStatus/ACTIVE/label': 'Attivo',
	'UserStatus/DISABLED/label': 'Disattivato',
	'UserStatus/DELETED/label': 'Eliminato',

	'FinalPosition/WINNER/label': 'Vincitore',
	'FinalPosition/FINALIST/label': 'Finalista',

	'CompanyIndustry/FOOD_INDUSTRY/label': 'Industria Alimentare',
	'CompanyIndustry/RETAIL/label': 'Retail',
	'CompanyIndustry/FOOD_SERVICE/label': 'Food service',

	FOOD_INDUSTRY: 'Industria Alimentare',
	RETAIL: 'Retail',
	FOOD_SERVICE: 'Food service',

	'PricePositioning/DISCOUNT/label': 'Discount',
	'PricePositioning/MAINSTREAM/label': 'Mainstream',
	'PricePositioning/PREMIUM/label': 'Premium',
	'PricePositioning/LUXURY/label': 'Luxury',
	'User/labelPlural': 'Utenti',
	explorer: 'Explorer',

	// ERRORS - BEGIN
	EMPTY_NOT_ALLOWED: 'Campo Obbligatorio',
	'MAX/text': 'Massimo di Caratteri',
	'MAX/number': 'Valore troppo grande',
	// MAX: 'MAX',
	'MIN/text': 'Minimo di Caratteri',
	'MIN/number': 'Valore troppo piccolo',
	'MAX/richtext': 'Testo troppo lungo',
	UNDER_MIN: 'Cifra troppo piccola',
	MIN: 'MIN',
	WRONG_PATTERN: 'Formato Sbagliato',
	UNSUPPORTED_FORMAT: 'Formato non supportato',
	MUST_BE_UNIQUE: "Il valore è già presente e dev'essere unico",
	// 'GENERIC': '',
	// 'INTERNAL': ''

	'Login/Error/LOGIN_ERROR': 'Credenziali non valide',
	'Renew/Error/LOGIN_ERROR': 'Credenziali non valide',

	'ERRORS/GRAPHQL/EMPTY_NOT_ALLOWED/field': 'Il campo non può essere vuoto',
	'ERRORS/GRAPHQL/EMPTY_NOT_ALLOWED/global':
		'{{=it.globalKeyPrefix}}Il campo non può essere vuoto',
	'ERRORS/GRAPHQL/OVER_MAX/field':
		"{{ if (it.err.data.unitType=='char') { }}Il campo contiene troppi caratteri: sono {{=it.err.data.actual}} mentre il limite è {{=it.err.data.limit}}{{ } else if (it.err.data.unitType=='entity') { }}Il campo contiene troppi elementi: sono {{=it.err.data.actual}} mentre il limite è {{=it.err.data.limit}}{{ } else if (it.err.data.unitType=='media') { }}Media troppo grande: {{=it.err.data.unitName}} {{=JSON.stringify(it.err.data.actual)}} mentre il limite è {{=it.err.data.limit}}{{ } else { }}Il campo contiene un valore troppo grande: il limite è {{=it.err.data.limit}}{{ } }}",
	'ERRORS/GRAPHQL/OVER_MAX/global':
		"{{=it.globalKeyPrefix}}{{ if (it.err.data.unitType=='char') { }}Il campo contiene troppi caratteri: sono {{=it.err.data.actual}} mentre il limite è {{=it.err.data.limit}}{{ } else if (it.err.data.unitType=='entity') { }}Il campo contiene troppi elementi: sono {{=it.err.data.actual}} mentre il limite è {{=it.err.data.limit}}{{ } else if (it.err.data.unitType=='media') { }}Media troppo grande: {{=it.err.data.unitName}} {{=JSON.stringify(it.err.data.actual)}} mentre il limite è {{=it.err.data.limit}}{{ } else { }}Il campo contiene un valore troppo grande: il limite è {{=it.err.data.limit}}{{ } }}",
	'ERRORS/GRAPHQL/UNDER_MIN/field':
		"{{ if (it.err.data.unitType=='char') { }}Il campo contiene troppo pochi caratteri: sono {{=it.err.data.actual}} mentre il limite è {{=it.err.data.limit}}{{ } else if (it.err.data.unitType=='entity') { }}Il campo contiene troppo pochi elementi: sono {{=it.err.data.actual}} mentre il limite è {{=it.err.data.limit}}{{ } else if (it.err.data.unitType=='media') { }}Media troppo piccolo: {{=it.err.data.unitName}} {{=JSON.stringify(it.err.data.actual)}} mentre il limite è {{=it.err.data.limit}}{{ } else { }}Il campo contiene un valore troppo piccolo: il limite è {{=it.err.data.limit}}{{ } }}",
	'ERRORS/GRAPHQL/UNDER_MIN/global':
		"{{=it.globalKeyPrefix}}{{ if (it.err.data.unitType=='char') { }}Il campo contiene troppo pochi caratteri: sono {{=it.err.data.actual}} mentre il limite è {{=it.err.data.limit}}{{ } else if (it.err.data.unitType=='entity') { }}Il campo contiene troppo pochi elementi: sono {{=it.err.data.actual}} mentre il limite è {{=it.err.data.limit}}{{ } else if (it.err.data.unitType=='media') { }}Media troppo piccolo: {{=it.err.data.unitName}} {{=JSON.stringify(it.err.data.actual)}} mentre il limite è {{=it.err.data.limit}}{{ } else { }}Il campo contiene un valore troppo piccolo: il limite è {{=it.err.data.limit}}{{ } }}",
	'ERRORS/GRAPHQL/WRONG_PATTERN/field': 'Formato errato',
	'ERRORS/GRAPHQL/WRONG_PATTERN/global':
		'{{=it.globalKeyPrefix}}Formato errato',
	'ERRORS/GRAPHQL/TOO_MUCH_CAPS/field': 'Troppi caratteri maiuscoli',
	'ERRORS/GRAPHQL/TOO_MUCH_CAPS/global':
		'{{=it.globalKeyPrefix}}Troppi caratteri maiuscoli',
	'ERRORS/GRAPHQL/REF_DO_NOT_EXISTS/field': 'Il riferimento non esiste',
	'ERRORS/GRAPHQL/REF_DO_NOT_EXISTS/global':
		'{{=it.globalKeyPrefix}}Il riferimento non esiste',
	'ERRORS/GRAPHQL/INVALID_REF/field': 'Riferimento non valido',
	'ERRORS/GRAPHQL/INVALID_REF/global':
		'{{=it.globalKeyPrefix}}Riferimento non valido',
	'ERRORS/GRAPHQL/UNSUPPORTED_FORMAT/field': 'Formato non supportato',
	'ERRORS/GRAPHQL/UNSUPPORTED_FORMAT/global':
		'{{=it.globalKeyPrefix}}Formato non supportato',
	'ERRORS/GRAPHQL/INVALID_VALUE/field': 'Valore non valido',
	'ERRORS/GRAPHQL/INVALID_VALUE/global':
		'{{=it.globalKeyPrefix}}Valore non valido',
	// 'ERRORS/GRAPHQL/WRONG_MEDIA_PROP/field':
	// 	"Il file non è accettabile perché la proprietà {{=it.err.data.prop}} è {{=it.err.data.actual}} mentre i valori supportati sono {{=it.err.data.acceptables.join(', ')}}",
	'ERRORS/GRAPHQL/WRONG_MEDIA_PROP/global':
		"{{=it.globalKeyPrefix}}Il file non è accettabile perché la proprietà {{=it.err.data.prop}} è {{=it.err.data.actual}} mentre i valori supportati sono {{=it.err.data.acceptables.join(', ')}}",
	'ERRORS/GRAPHQL/MUST_BE_UNIQUE/field':
		"Il valore è già presente e dev'essere unico",
	'ERRORS/GRAPHQL/MUST_BE_UNIQUE/global':
		"{{=it.globalKeyPrefix}}Il valore è già presente e dev'essere unico",
	'ERRORS/GRAPHQL/ALREADY_PRESENT/field':
		'Prodotto già associato in questo ambito', //'Già presente',
	'ERRORS/GRAPHQL/ALREADY_PRESENT/global':
		'{{=it.globalKeyPrefix}}Già associato in questo ambito', //'{{=it.globalKeyPrefix}}Già presente',
	'ERRORS/GRAPHQL/EXPIRED/field': 'Scaduto',
	'ERRORS/GRAPHQL/EXPIRED/global': '{{=it.globalKeyPrefix}}Scaduto',
	'ERRORS/GRAPHQL/GENERIC/field': 'Errore', // TODO - spiegare cosa fare
	'ERRORS/GRAPHQL/GENERIC/global': '{{=it.globalKeyPrefix}}Errore',
	'ERRORS/GRAPHQL/INTERNAL/field': 'Si è verificato un errore interno', // TODO - spiegare cosa fare
	'ERRORS/GRAPHQL/INTERNAL/global':
		'{{=it.globalKeyPrefix}}Si è verificato un errore interno',
	'ERRORS/GRAPHQL/HAS_DEPS/field':
		"{{=it.entityLabel}} è collegato a {{=Object.keys(it.err.data.deps).map((k) => { return it.err.data.deps[k]+' '+it.getEntityLabel(k, k>1)}).join(',')}}",
	'ERRORS/GRAPHQL/HAS_DEPS/global':
		"{{=it.globalKeyPrefix}}{{=it.entityLabel}} è collegato a {{=Object.keys(it.err.data.deps).map((k) => { return it.err.data.deps[k]+' '+it.getEntityLabel(k, k>1)}).join(',')}}",

	// ERRORS - END

	'validation errors': 'Errori di validazione',
	'generic data': 'Dati generali',
	actions: '', // FIXME
	edit: 'Modifica',
	save: 'Salva',
	add: 'Aggiungi',
	'change password': 'Cambia password',
	detail: 'Dettagli',
	close: 'Chiudi',
	'create new': 'Crea nuovo',
	create: 'Crea',
	change: 'Cambia',
	cancel: 'Annulla',
	update: 'Aggiorna',
	page: 'Pagina',
	'change media': 'Cambia Media',
	filters: 'Filtri',
	filter_verb: 'Filtra', // FIXME
	home: 'Home',
	'Company profile': 'Profilo Aziendale',
	password: 'Password',
	'add candidacy': 'Aggiungi Candidatura',
	'validation errors introduction': 'Errori nella compilazione del form',
	'choose media': 'Scegli media',
	yes: 'Sì',
	no: 'No',
	'renew auth': 'Rinnova l’autenticazione',
	'renew modal intro': 'Sessione scaduta per inutilizzo prolungato',
	email: 'E-mail',
	'go to login': 'Cambia utente',
	'renew login': 'Rinnova il Login',
	'change password title': 'Cambia password',
	'change password modal intro': 'change password modal intro',
	'generate random password': 'Genera password casuale',

	regulation: 'Termini e Condizioni',
	'List of candidacies': 'Lista di candidature',
	'confirm abort save': 'Sei certo di voler lasciare la pagina?',
	'abort description text': 'Stai lasciando il modulo senza aver salvato.',
	'leave the page': 'Cambia Pagina',
	'stay on page': 'Resta nella Pagina',
	'Company/name/filter/label': 'Nome',
	'Company/producerChangedAfter/filter/label': 'Ultima Modifica',
	'Award/name/filter/label': 'Nome',
	'Award/edition/filter/label': 'Edizione',
	'User/name/filter/label': 'Nome',
	'User/email/filter/label': 'Email',
	'Good/name/filter/label': 'Nome',
	'Good/producerChangedAfter/filter/label': 'Ultima Modifica',
	'Good/Retailer/filter/label': 'Retailer',
	'Good/FoodService/filter/label': 'Food Service',
	'Good/goodType/filter': 'Tipo',
	'Good/goodType/label': 'Tipo',

	'GoodType/REGULAR/label': 'Utente',
	'GoodType/WEB/label': 'Web',
	'Good/goodType/helpText': 'Definisce se un prodotto è creato da un utente o proviene dal web.',
	
	delete: 'Elimina',

	'Banner/labelPlural': 'Banners',
	'Widget/labelPlural': 'Widgets',
	'SERVER_ERROR/NETWORK/error/explication':
		'Non riesco a connettermi col Server',
	'EditorialSection/labelPlural': 'Sezioni Editoriali',
	Error: 'Errore',
	Close: 'Chiudi',
	'User/managedByUsers/label': "Oggetti Gestiti dall'Utente",
	'confirm password': 'Conferma Password',
	'User/name/helpText': "Nome e Cognome dell'Utente",
	'UserRole/BUYER_PL_ITALIA/label': 'Retailer Italiano',
	'UserRole/CONSUMER/label': 'Consumatore',
	'UserStatus/WAITING_REVIEW/label': 'In attesa di verifica',
	'User/workEmail/label': 'Email aziendale',
	'User/Team/label': 'Team',
	'User/approvedToS/label': 'Termini di utilizzo accettati',
	'User/workEmail/helpText': "email personale con dominio dell'azienda",
	'User/langCode/label': 'Codice Lingua Preferita',
	'User/langCode/helpText': '',
	'User/internalCapabilities/label': 'Abilità interne',
	'User/internalCapabilities/helpText':
		"Abilità che l'utente è in grado di svolgere",
	'InternalCapabilities/USERS_BASE/label': 'Utente BASE',
	'InternalCapabilities/USERS_EXTRA/label': 'Utente EXTRA',
	'InternalCapabilities/MARKETING_BASE/label': 'Marketing BASE',
	'InternalCapabilities/MARKETING_EXTRA/label': 'Marketing EXTRA',
	'InternalCapabilities/EDITORIAL_BASE/label': 'Editor BASE',
	'InternalCapabilities/EDITORIAL_EXTRA/label': 'Editor EXTRA',
	'InternalCapabilities/DIRECTORY_BASE/label': 'Directory BASE',
	'InternalCapabilities/DIRECTORY_EXTRA/label': 'Directory EXTRA',
	'InternalCapabilities/ADMIN_BASE/label': 'Admin BASE',
	'InternalCapabilities/ADMIN_EXTRA/label': 'Admin EXTRA',
	'User/managedByUsers/helpText': '',
	'Confirm delete text?': "Sicuro di voler procedere con l'eliminazione?",
	Undo: 'Annulla',
	Delete: 'Elimina',
	'Generic data': 'Dati Generici',
	Back: 'Indietro',
	PRODUCER: 'PRODUTTORE',
	ACTIVE: 'ATTIVO',
	'ManagedByUser/Company/label': 'Azienda',
	'EditorialSection/name/filter/label': 'Nome',
	'EditorialBrand/BARBUSINESS/label': 'BARBUSINESS',
	'EditorialBrand/FOODWEB/label': 'FOODWEB',
	'EditorialBrand/FOODSERVICE/label': 'FOODSERVICE',
	'EditorialBrand/DOLCESALATO/label': 'DOLCESALATO',
	'EditorialBrand/IFN/label': 'ITALIANFOOD.NET',
	'EditorialBrand/CM_ATTUALI/label': 'CATEGORIE MERCEOLOGICHE 2016',
	'EditorialBrand/GOODS4HUB/label': 'GOODS4HUB',
	'EditorialBrand/FOOD/label': 'FOOD',
	'EditorialSection/name/label': 'Nome',
	'EditorialSection/name/helpText': '',
	'Award/year/filter/label': 'Anno',
	'Award/statuses/filter/label': 'Stato della manifestazione',
	'AwardStatus/DRAFT/label': 'DRAFT',
	'AwardStatus/CANDIDANCY_OPEN/label': 'Candidature aperte',
	'AwardStatus/CANDIDANCY_CLOSED/label': 'Candidature chiuse',
	'AwardStatus/VOTING_OPEN/label': 'Votazioni aperte',
	'AwardStatus/VOTING_CLOSED/label': 'Votazioni chiuse',
	'AwardStatus/CONCLUDED/label': 'Concluso',
	'Award/status/label': 'Stato della manifestazione',
	'Award/status/helpText': '',
	'Award/candidacies/label': 'Candidature',
	'Award/targetSite/label': 'TargetSite',
	'Award/targetSite/helpText':
		'Sito/Brand a cui si riferisce la manifestazione',
	'TargetSite/IFN/label': 'ITALIANFOOD.NET',
	'TargetSite/FOODWEB/label': 'FOODWEB',
	'TargetSite/HUB/label': 'HUB',
	'TargetSite/SOURCINGSUPPLIER/label': 'Sourcing Supplier',
	'TargetSite/SOURCINGBUYER/label': 'Sourcing Buyer',
	'Award/categories/helpText': '',
	'Award/candidacies/helpText': '',
	Categories: 'Categorie',
	IFN: 'ITALIANFOOD.NET',
	DRAFT: 'Bozza',
	'Banner/name/filter/label': 'Nome',
	'Banner/name/label': 'Nome Banner',
	'Banner/name/helpText': '',
	'Banner/startDate/label': 'Data di Pubblicazione',
	'Banner/startDate/helpText': 'Data dalla quale il banner sarà visibile',
	'Banner/targetSite/label': 'Sito di Pubblicazione',
	'Banner/targetSite/helpText': 'Sito su cui il banner sarà pubblicato',
	'Banner/Company/label': 'Azienda Inserzionista',
	'Banner/targetMedia/label': 'Canale',
	'Banner/targetMedia/helpText':
		'Canale digitale su cui sarà diffuso il Banner',
	'TargetMedia/WEBSITE/label': 'Sito Web',
	'TargetMedia/NEWSLETTER/label': 'Newsletter',
	'Banner/Media/label': 'Media',
	'Banner/Media/helpText': '',
	'Banner/Company/helpText': 'Azienda Inserzionista',
	'Banner/Award/label': 'Manifestazione',
	'Banner/Award/helpText': '',
	'Banner/visibilityByRole/label': 'Visibilità per Ruolo Utenti',
	'Banner/visibilityByRole/helpText':
		'determina quali tipi di Utenti potranno vedere il contenuto',
	'VisibilityRoles/ANONYMUS_ONLY/label': 'solo Anonimi',
	'VisibilityRoles/USER_ONLY/label': 'solo Utenti Registrati',
	'VisibilityRoles/PRODUCER_ONLY/label': 'Solo Utenti Produttori',
	'VisibilityRoles/BUYER_ONLY/label': 'Solo Buyer',
	'Banner/visibilityByRelation/label': 'Visibilità per connessione',
	'Banner/visibilityByRelation/helpText':
		'determina quali utenti potranno vedere il contenuto in base a relazioni con altri oggetti',
	'VisibilityRelations/LINKED_TO_FAIR/label': 'Connesso alla Fiera',
	'VisibilityRelations/LINKED_TO_AWARD/label': 'Connesso alla Manifestazione',
	'VisibilityRelations/LINKED_TO_COMPANY/label': "Connesso all'azienda",
	'VisibilityRelations/LINKED_TO_GEO_INDICATION/label': "Connesso all'Indicazione Geografica",
	'VisibilityRelations/TARGET_FOR_FAIR/label': 'Utenti Target della Fiera',
	'VisibilityRelations/TARGET_FOR_AWARD/label': 'Utenti Target della Manifestazione',
	'VisibilityRelations/TARGET_FOR_COMPANY/label': "Utenti Target per l'azienda",
	'VisibilityRelations/TARGET_FOR_GEO_INDICATION/label': "Utenti Target per l'indicazione geografica",
	'Banner/visibilityByMinLevel/label': 'Livello Minimo di Visibilità',
	'Banner/visibilityByMinLevel/helpText':
		"Livello Minimo dell'utente in grado di vedere il banner",
	'CompanyLevelEnum/FREE/label': 'Presente',
	'Banner/visibilityByMaxLevel/label': 'Livello Massimo di Visibilità',
	'Banner/visibilityByMaxLevel/helpText':
		"Livello Massimo dell'utente in grado di vedere il banner",
	'Banner/visibilityByMinCapability/label': 'Livello Minimo di Abilità',
	'Banner/visibilityByMinCapability/helpText':
		"Livello Minimo di abilità dell'utente in grado di vedere il banner",
	'Capabilities/EDIT/label': 'EDIT',
	'Capabilities/ACCOUNT/label': 'ACCOUNT',
	'Capabilities/PURCHASE/label': 'PURCHASE',
	'Capabilities/MANAGE/label': 'MANAGE',
	Clone: 'Clona',
	WEBSITE: 'Sito Web',
	ANONYMUS_ONLY: 'Solo per utenti anonimi',
	LINKED_TO_AWARD: 'Connesso alla manifestazione',
	FREE: 'FREE',
	'undefined/labelPlural': 'indefinito',
	'Widget/name/filter/label': 'Nome del Widget',
	'Widget/name/label': 'Nome',
	'Widget/name/helpText': 'Nome del Widget',
	'Widget/body/label': 'Body',
	'Widget/body/helpText': '',
	'Widget/query/label': 'Query',
	'Widget/query/helpText': '',
	'Widget/Company/label': 'Azienda',
	'Widget/Company/helpText': '',
	'Widget/Award/label': 'Manifestazione',
	'Widget/Award/helpText': '',
	'Widget/startDate/label': 'Data di Pubblicazione',
	'Widget/startDate/helpText': 'Data dalla quale il widget è visibile',
	'Widget/targetSite/label': 'Sito di Pubblicazione',
	'Widget/targetSite/helpText': '',
	'Widget/orderPriority/label': 'Priorità di Ordinamento',
	'Widget/orderPriority/helpText': 'Ordine di visualizzazione dei vari widget',
	'Widget/gridSize/label': 'Larghezza visiva',
	'Widget/gridSize/helpText': '',
	'GridSize/COL_1/label': 'Colonna 1',
	'GridSize/COL_2/label': 'Colonna 2',
	'GridSize/COL_3/label': 'Colonna 3',
	'Widget/location/label': 'Posizionamento',
	'Widget/location/helpText': '',
	'WidgetLocation/HOMEPAGE/label': 'Homepage',
	'WidgetLocation/DASHBOARD/label': 'Dashboard',
	'WidgetLocation/OPEN_QUOTE/label': 'Homepage eCommerce',
	'WidgetLocation/HOME__EDITORIAL_SECTION/label': 'Homepage News',
	'WidgetLocation/EDITORIAL_SECTION__ONLY/label': 'Solo sezioni editoriali',
	'Widget/styleType/label': 'Stile',
	'Widget/styleType/helpText': 'Stile Grafico del Widget',
	'WidgetStyleType/BORDERED/label': 'Contornato',
	'WidgetStyleType/UNBOXED/label': 'Scontornato',
	'WidgetStyleType/MAGAZINE/label': 'Magazine',
	'WidgetStyleType/BIG_4SMALL/label': 'Elemento Principale con quattro correlati al fianco',
	'WidgetStyleType/CARUSEL/label': 'Carousel',
	'Widget/styleColor/label': 'Colore del Widget',
	'Widget/styleColor/helpText': '',
	'WidgetStyleColor/MAIN/label': 'Principale',
	'WidgetStyleColor/ALT/label': 'Alternativa1',
	'WidgetStyleColor/ALT2/label': 'Alternativa2',
	'WidgetStyleColor/BY_EDITORIAL_SECTION/label': 'derivato dalla sezione editoriale',
	'WidgetStyleColor/BY_ENTITY_NAME/label': "derivato dal nome dell'entità",
	'Widget/footer/label': 'Footer',
	'Widget/footer/helpText': '',
	Ratings: 'Punteggi',
	'CandidacyRating/User/helpText': '',
	'CandidacyRating/overall/helpText': '',
	'CandidacyRating/rating1/helpText': '',
	'CandidacyRating/rating2/label': 'Rating 2',
	'CandidacyRating/rating2/helpText': '',
	'CandidacyRating/rating3/label': 'Rating 3',
	'CandidacyRating/rating3/helpText': '',
	'CandidacyRating/rating4/label': 'Rating 4',
	'CandidacyRating/rating4/helpText': '',
	'CandidacyRating/rating5/label': 'Rating 5',
	'CandidacyRating/rating5/helpText': '',
	'CandidacyRating/comment1/label': 'Descrizione del Rating 1',
	'CandidacyRating/comment1/helpText': '',
	'CandidacyRating/comment2/label': 'Descrizione del Rating 2',
	'CandidacyRating/comment2/helpText': '',
	'CandidacyRating/comment3/label': 'Descrizione del Rating 3',
	'CandidacyRating/comment3/helpText': '',
	'CandidacyRating/labelPlural': 'Punteggi della Candidatura',
	'EMPTY_NOT_ALLOWED/number': 'Campo Obbligatorio',
	Global: 'Complessivo',
	Name: 'Nome',
	Edition: 'Edizione',
	Year: 'Anno',
	'Award/invitedUsers/label': 'Invitati',
	'Award/descriptionForProducer/label': 'Descrizione della Manifestazione per il Produttore',
	'Award/descriptionForProducer/helpText': '',
	'Award/descriptionForVoter/label': 'Descrizione della Manifestazione per il Votante',
	'Award/vademecumCompany/label': 'Vademecum Step dati Aziendali',
	'Award/vademecumCompany/helptext': 'è la descrizione testuale che comparirà nell\'interfaccia per candidare circa i dati aziendali necessari - puoi copiare incollare da altre manifestazioni simili',
	'Award/vademecumGoods/label': 'Vademecum Step Prodotti',
	'Award/vademecumGoods/helptext': 'è la descrizione testuale che comparirà nell\'interfaccia per candidare circa i prodotti - puoi copiare incollare da altre manifestazioni simili',
	'Award/vademecumCandidacies/label': 'Vademecum Step Candidatura',
	'Award/vademecumCandidacies/helptext': 'è la descrizione testuale che comparirà nell\'interfaccia per le candidature - puoi copiare incollare da altre manifestazioni simili',
	'Award/vademecumInvited/label': 'Vademecum aziende invitate',
	'Award/vademecumInvited/helpText': 'è un campo descrittivo che si mostrerà nella pagina di creazione candidatura, era stata fatta per elencare altre aziende candidate per invogliare a competere con aziende concorrenti',
	'Award/descriptionForVoter/helpText': '',
	'Award/descriptionProducerHighlights/label': 'Descrizione laterale homepage app di voto',
	'Award/descriptionProducerHighlights/helpText': '',
	'Award/autovoterRoles/label': "Ruolo dell'Utente votante",
	'Award/autovoterRoles/helpText': '',
	'AwardAutovoterRoles/BUYER/label': 'Retailer Estero',
	'AwardAutovoterRoles/BUYER_PL_ITALIA/label': 'Retailer Italiano',
	'AwardAutovoterRoles/CONSUMER/label': 'Consumatore',
	'Award/ratingOverallLabel/label': 'Punteggio Complessivo',
	'Award/ratingOverallLabel/helpText': '',
	'Award/invitedUsers/helpText': '',
	HUB: 'HUB',
	CANDIDANCY_OPEN: 'Candidature Aperte',
	'AwardInvitedUser/User/label': 'Utente su Invito della Manifestazione',
	'AwardInvitedUser/localId/label': 'Local Id',
	'AwardInvitedUser/User/helpText': '',
	'AwardInvitedUser/Award/label': 'Manifestazione',
	'AwardInvitedUser/Award/helpText': '',
	'AwardInvitedUser/roles/label': 'Ruoli degli Utenti',
	'AwardInvitedUser/roles/helpText': '',
	'AwardInvitedCompany/formValues/label': 'Form Values',
	'AwardRoles/SPECIAL_JUROR/label': 'Giurato di Qualità',
	'AwardRoles/SPEAKER/label': 'Relatore',
	'AwardRoles/SPECIAL_GUEST/label': "Ospite d'Onore",
	'AwardInvitedUser/labelPlural': 'Utenti su Invito della Manifestazione',
	AwardInvitedUser: 'Utente su Invito della Manifestazione',
	'AwardCategory/Award/label': 'Manifestazione',
	'AwardCategory/Award/helpText': '',
	AwardCategory: 'Categoria della Manifestazione',
	COL_1: 'Colonna 1',
	DASHBOARD: 'Dashboard',
	UNBOXED: 'Scontornato',
	MAIN: 'Principale',
	'Last Modified': 'Ultima modifica',
	Brands: 'Brand',
	Company: 'Azienda',
	'ManagedByUser/capabilities/label': 'Permessi assegnati',
	'NumPerSellingPack/N1/label': '1',
	'NumPerSellingPack/N2/label': '2',
	'NumPerSellingPack/N3/label': '3',
	'NumPerSellingPack/N4/label': '4',
	'NumPerSellingPack/N5/label': '5',
	'NumPerSellingPack/N6/label': '6',
	'NumPerSellingPack/N7/label': '7',
	'NumPerSellingPack/N8/label': '8',
	'NumPerSellingPack/N9/label': '9',
	'NumPerSellingPack/N10/label': '10',
	'NumPerSellingPack/N11/label': '11',
	'NumPerSellingPack/N12/label': '12',
	'NumPerSellingPack/N13/label': '13',
	'NumPerSellingPack/N14/label': '14',
	'NumPerSellingPack/N15/label': '15',
	'NumPerSellingPack/N16/label': '16',
	'NumPerSellingPack/N17/label': '17',
	'NumPerSellingPack/N18/label': '18',
	'NumPerSellingPack/N19/label': '19',
	'NumPerSellingPack/N20/label': '20',
	'readonly field description':
		'Questo campo non sarà più modificabile una volta completata la creazione',
	'Start typing to search': 'Comincia a digitare per avviare la ricerca',
	'Click to show options, start typing to filter':
		'Clicca per mostrare i possibili valori, comincia a digitare per filtrarli',
	'Widget/targetDate/filter/label': 'Data target',
	'Widget/targetSite/filter/label': 'Sito ',
	'Widget/location/filter/label': 'Area del sito',
	'Widget/Company/filter/label': 'Azienda',
	'Widget/GeoIndication/filter/label': 'Indicazione geografica',
	'Widget/Fair/filter/label': 'Fiera',
	'Widget/Award/filter/label': 'Manifestazione',
	'Widget/expirationDate/label': 'Data di scadenza',
	'Widget/expirationDate/helpText': '',
	'Award_producer_/name/filter/label': 'Nome',
	'Award_producer_/edition/filter/label': 'Edizione',
	'Award_producer_/year/filter/label': 'Anno',
	'Award_producer_/statuses/filter/label': 'Stato',
	'Award_producer_/labelPlural': 'Manifestazioni',
	'Submitted candidatures': 'Candidature sottoposte',
	'remaining days': 'Giorni rimanenti',
	'Add candidacy': 'Aggiungi candidatura',
	Candidacy: 'Candidatura',
	'max length integer': 'Numero massimo',
	'min length integer': 'Numero minimo',
	'max length string': 'Numero massimo di caratteri',
	'min length string': 'Numero minimo di caratteri',
	Login: 'Accedi',
	'terms advice text 1': 'Accedendo al sito accetti i termini di regolamento.',
	'link rule book': 'Leggi le condizioni',
	'support intro login': 'Se hai problemi ad accedere per favore scrivi a ',
	'support email': 'awards@italianfood.net',
	'no brands alert':
		'Per completare il profilo aziendale e poter inserire prodotti devi avere almeno un Brand\n' +
		'assegnato alla tua azienda. Per creare un Brand clicca sulla tab "Brand" e sul pulsante "Aggiungi".',
	'only one brand alert':
		'Hai un solo brand associato alla tua azienda. Aggiungine altri nel caso volessi candidare ' +
		'prodotti di altri Brand',
	'no companyCertifications alert':
		"Per completare il profilo aziendale inserisci le certificazioni dell'azienda",
	'no descriptions alert':
		'Per completare il profilo aziendale inserisci le descrizioni (brevi e accurate) sia in italiano che in lingua inglese',
	'company complete alert':
		'Hai aggiornato con successo Azienda e Brand. Passa alla pagina dei',
	'no goods alert':
		'Non hai prodotti da candidare. Clicca su <a href="/goods/new"><strong>"Crea nuovo"</strong></a> per aggiungerne.',
	'no EAN alert':
		'Non hai nessun prodotto con EAN associato. Per aggiungere un EAN vai nel dettaglio di un prodotto e aggiungi un formato di vendita',
	'some ean missing alert':
		'Alcuni tuoi prodotti non hanno un EAN associato. Clicca su <strong>"Dettagli"</strong> per aggiungerli.',
	'some products ok alert':
		'Hai alcuni prodotti pronti per la candidatura. Vai su <a href="/awards_producer"><strong>"Candidature"</strong></a> per presentali alla giuria.',
	'all products ok alert':
		'Tutti i prodotti sono pronti per la candidatura. Vai su <a href="/awards_producer"><strong>"Candidature"</strong></a> per presentali alla giuria.',
	'no candidacies condition text':
		'Mancano dati necessari alla candidatura di prodotti. Torna alla <strong><a href="/">Home</a></strong> e segui le indicazioni',
	'search only ean':
		'I prodotti senza EAN associato sono esclusi dalla ricerca',
	warning: 'Attenzione',
	'only italian products':
		"Sono candidabili solo prodotti lanciati sul mercato italiano dopo l'01/01/2016",
	'no brands good detail alert':
		'Questo prodotto non ha un EAN associato. Per aggiungere un EAN scorri la pagina fino alla voce <strong>"Formati di vendita"</strong> e aggiusta i dati.',
	'min length sync choices': 'Numero minimo di voci selezionabili',
	'max length sync choices': 'Numero massimo di voci selezionabili',
	'change good name readonly explication':
		'NB. Non è possibile modificare il <strong>nome in inglese</strong> del prodotto. Per supporto tecnico contattaci. <a href="mailto:awards@italianfood.net">contattaci</a>.',
	'Award/labelPlural/internal': 'Manifestazioni',
	'User/roles/filter/label': 'Ruolo',
	'AccountType/INDUSTRY/label': 'Industria',
	'AccountType/INSTITUTION/label': 'Istituzione',
	'AccountType/RETAIL_NO_PL/label': 'Retailer Estero',
	'AccountType/RETAIL_PL/label': 'Retailer Italiano',
	'AccountType/SERVICE/label': 'Servizi',
	'AccountType/FOODSERVICE/label': 'Food Service',
	'Company data': 'Dati Aziendali',
	'CompanyGroup/address1/helpText': 'Strada e Numero Civico',
	'CompanyGroup/address1/label': 'Indirizzo - Prima Riga',
	'CompanyGroup/address2/helpText':
		'Se necessari, dati opzionali - Palazzo, Piano, C/O',
	'CompanyGroup/address2/label': 'Indirizzo - Seconda Riga',
	'CompanyGroup/billingName/helpText':
		'Ragione sociale con cui il Consorzio è registrato',
	'CompanyGroup/billingName/label': 'Ragione Sociale',
	'CompanyGroup/city/helpText':
		'Indica la Città della sede legale del Consorzio',
	'CompanyGroup/city/label': 'Città',
	'CompanyGroup/Countries/filter/label': 'Paesi',
	'CompanyGroup/Country/helpText':
		'Indica il Paese della sede legale del Consorzio',
	'CompanyGroup/Country/label': 'Paese',
	'CompanyGroup/description/helpText':
		'Inserire una Descrizione breve in inglese. Compilare con attenzione, il testo sarà utilizzato per fini editoriali.',
	'CompanyGroup/description/label': 'Descrizione breve in inglese',
	'CompanyGroup/descriptionIta/helpText':
		'Inserire una breve descrizione in italiano. Compilare con attenzione, il testo sarà utilizzato per fini editoriali.',
	'CompanyGroup/descriptionIta/label': 'Descrizione breve in italiano',
	'CompanyGroup/descriptionLong/helpText': 'Descrizione completa in inglese',
	'CompanyGroup/descriptionLong/label': 'Inserire una descrizione dettagliata in inglese. Compilare con attenzione, il testo sarà utilizzato per fini editoriali.',
	'CompanyGroup/descriptionLongIta/helpText':
		'Usa le maiuscole solo per le iniziali. Il testo sarà usato a fini editoriali.',
	'CompanyGroup/descriptionLongIta/label': 'Descrizione accurata in italiano',
	'CompanyGroup/labelPlural': 'Consorzi',
	'CompanyGroup/lat/helpText': '',
	'CompanyGroup/lat/label': 'Latitudine',
	'CompanyGroup/lng/helpText': '',
	'CompanyGroup/lng/label': 'Longitudine',
	'CompanyGroup/localId/helpText': 'Local Id numerico della piattaforma',
	'CompanyGroup/localId/label': 'Local Id',
	'CompanyGroup/logoMedia/helpText':
		"Lunghezza minima della base o dell'Altezza: 800 pixel - Formato Preferibile: PNG (Sfondo Trasparente) - Modalità Colore: RGB",
	'CompanyGroup/logoMedia/label': 'Logo Gruppo',
	'CompanyGroup/managedByUsers/helpText':
		'Utenti che possono gestire il Consorzio',
	'CompanyGroup/managedByUsers/label': 'Utenti Assegnati',
	'CompanyGroup/name/filter/label': 'Nome',
	'CompanyGroup/name/helpText': 'Nome del Consorzio',
	'CompanyGroup/name/label': 'Nome',
	'CompanyGroup/newsCitations/helpText': '',
	'CompanyGroup/newsCitations/label': 'Citazioni',
	'CompanyGroup/pecEmail/helpText':
		'Posta Elettronica Certificata del Consorzio',
	'CompanyGroup/pecEmail/label': 'indirizzo PEC',
	'CompanyGroup/postalCode/helpText':
		'Indica il Codice di Avviamento Postale della sede legale del Consorzio',
	'CompanyGroup/postalCode/label': 'CAP',
	'CompanyGroup/producerChangedAfter/filter/label': 'Ultima Modifica',
	'CompanyGroup/sfId/helpText': 'Id del salesforce',
	'CompanyGroup/sfId/label': 'Id Salesforce',
	'CompanyGroup/StateProvince/helpText':
		'Indica la Provincia della sede legale del Consorzio',
	'CompanyGroup/StateProvince/label': 'Provincia',
	'CompanyGroup/type/helpText': 'Indica il tipo di Consorzio',
	'CompanyGroup/type/label': 'Tipologia',
	'CompanyGroup/types/filter/label': 'Tipologie',
	'CompanyGroup/vatNumber/helpText': 'Partita IVA del Consorzio',
	'CompanyGroup/vatNumber/label': 'Partita IVA',
	'CompanyGroup/website/helpText':
		'Inserire il sito web aziendale preceduto da https://',
	'CompanyGroup/website/label': 'Sito Web',
	'CompanyGroupType/CONSORZIO/label': 'Consorzio',
	'EmailDomain/accountSfid/helpText': "Id Salesforce dell'azienda",
	'EmailDomain/accountSfid/label': 'Id Salesforce',
	'EmailDomain/accountType/helpText': "Settore in cui opera l'azienda",
	'EmailDomain/accountType/label': 'Settore',
	'EmailDomain/accountTypes/filter/label': 'Settori',
	'EmailDomain/accountTypes/filter': 'Settori',
	'EmailDomain/action/helpText':
		'Azione da effettuare in caso di registrazione',
	'EmailDomain/action/label': 'Risposta',
	'EmailDomain/actions/filter/label': 'Azioni',
	'EmailDomain/actions/filter': 'Azioni',
	'EmailDomain/Company/helpText': "il dominio appartiene a un'Industria",
	'EmailDomain/Company/label': 'Industria',
	'EmailDomain/CompanyGroup/helpText': 'il dominio appartiene a un consorzio',
	'EmailDomain/CompanyGroup/label': 'Consorzio',
	'EmailDomain/emailDomain/filter/label': 'Dominio Email',
	'EmailDomain/emailDomain/helpText': 'Dominio Email Aziendale',
	'EmailDomain/emailDomain/label': 'Dominio Email',
	'EmailDomain/labelPlural': 'Domini email',
	'EmailDomain/localId/helpText': '',
	'EmailDomain/localId/label': 'Local Id',
	'EmailDomain/note/helpText': 'Scrivi eventuali indicazioni per gli admin',
	'EmailDomain/note/label': 'Annotazioni',
	'EmailDomain/Retailer/helpText': 'il dominio appartiene a un Retailer',
	'EmailDomain/Retailer/label': 'Retailer',
	'EmailDomain/Service/helpText':
		"il dominio appartiene a un'azienda del terziario",
	'EmailDomain/Service/label': 'Servizi',
	'EmailDomain/Team/helpText': '',
	'EmailDomain/Team/label': 'Team,',
	'EmailDomain/website/helpText':
		"Inserire il sito web per aiutare l'admin ad associare il registrante",
	'EmailDomain/website/label': 'Sito Web',
	'EmailDomainAction/ACCEPT/label': 'ACCETTA',
	'EmailDomainAction/CONSUMER_ONLY/label': 'CONSUMATORE',
	'EmailDomainAction/WAIT_AND_NOTIFY/label': 'CONGELA E NOTIFICA',
	'EuCategory/HtmlTemplate/helpText': '',
	'EuCategory/HtmlTemplate/label': 'Template HTML',
	'EuCategory/labelPlural': 'Categorie Merceologiche U.E.',
	'EuCategory/localId/helpText': '',
	'EuCategory/localId/label': 'Local Id',
	'User/localId/label': 'Local Id',
	'EuCategory/name/filter/label': 'Categorie Merceologiche U.E.',
	'EuCategory/name/helpText':
		'Indica la categoria merceologica europea a cui appartiene il Prodotto Tipico',
	'EuCategory/name/label': 'Categoria Merceologica U.E.',
	'EuIndication/PDO/label': 'DOP',
	'EuIndication/PGI/label': 'IGP',
	'EuIndication/TSG/label': 'STG',
	'Fair/address1/helpText': 'Strada e Numero Civico',
	'Fair/address1/label': 'Indirizzo - Prima Riga',
	'Fair/address2/helpText':
		'Se necessari, dati opzionali - Palazzo, Piano, C/O',
	'Fair/address2/label': 'Indirizzo - Seconda Riga',
	'Fair/begin/helpText': '',
	'Fair/begin/label': 'Data di Inizio',
	'Fair/city/helpText': 'Città Ospitante',
	'Fair/city/label': 'Città',
	'Fair/Countries/filter/label': 'Paesi',
	'Fair/Country/helpText': 'Stato ospitante',
	'Fair/Country/label': 'Paese',
	'Fair/description/helpText':
		'Descrizione Inserire una descrizione dettagliata in inglese. Compilare con attenzione, il testo sarà utilizzato per fini editoriali.',
	'Fair/description/label': 'Descrizione in Inglese',
	'Fair/descriptionIta/helpText':
		'Descrizione in lingua italiana. Usa le maiuscole solo per le iniziali. Il testo sarà usato a fini editoriali.',
	'Fair/descriptionIta/label': 'Descrizione in Italiano',
	'Fair/edition/helpText': "Inserisci il nome dell'edizione della Fiera",
	'Fair/edition/label': 'Edizione',
	'Fair/end/helpText': '',
	'Fair/end/label': 'Data di Fine',
	'Fair/goodClasses/helpText':
		'Inserisci le categorie merceologiche trattate nella fiera',
	'Fair/goodClasses/label': 'Categorie Merceologiche',
	'Fair/isConcluded/filter/label': 'Passata',
	'Fair/labelPlural': 'Fiere',
	'Fair/lat/helpText': '',
	'Fair/lat/label': 'Latitudine',
	'Fair/lng/helpText': '',
	'Fair/lng/label': 'Longitudine',
	'Fair/localId/helpText': '',
	'Fair/localId/label': 'Local Id',
	'Fair/logoMedia/helpText':
		"Lunghezza minima della base o dell'Altezza: 800 pixel - Formato Preferibile: PNG (Sfondo Trasparente) - Modalità Colore: RGB",
	'Fair/logoMedia/label': 'Logo fiera',
	'Fair/name/filter/label': 'Nome',
	'Fair/name/helpText': 'Nome della Manifestazione fieristica',
	'Fair/name/label': 'Nome',
	'Fair/newsCitations/helpText': '',
	'Fair/newsCitations/label': 'Citazioni',
	'Fair/partecipations/helpText': '',
	'Fair/partecipations/label': 'Espositori',
	'Fair/postalCode/helpText':
		'Indica il Codice di Avviamento Postale della sede della Fiera',
	'Fair/postalCode/label': 'CAP',
	'Fair/StateProvince/helpText': 'Indica la Provincia della sede della Fiera',
	'Fair/StateProvince/label': 'Provincia',
	'Fair/targetSites/helpText': 'Indica i siti del gruppo in cui è visibile',
	'Fair/targetSites/label': 'Visibile su',
	'Fair/website/helpText':
		'Inserire il sito web aziendale preceduto da https://',
	'Fair/website/label': 'Sito Web',
	'Fair/year/helpText': "Inserisci l'anno dell'edizione della Fiera",
	'Fair/year/label': 'Anno',
	'Fair/ourPavilion/label': 'Padiglione in cui si trova lo stand FOOD',
	'Fair/ourStand/label': 'Numero dello stand FOOD',
	'Fair/ourNote/label': 'Extra Info sullo stand FOOD',
	'FairParticipation/Company/helpText':
		"Indicare l'azienda che parteciperà alla fiera",
	'FairParticipation/Company/label': 'Azienda',
	'FairParticipation/pavilion/helpText':
		'Indicare il padiglione in cui si troverà lo Stand',
	'FairParticipation/pavilion/label': 'Padiglione / Hall',
	'FairParticipation/stand/helpText':
		'Indicare il codice alfanumerico assegnato allo Stand',
	'FairParticipation/stand/label': 'Codice Stand',
	GeoIndication: 'Indicazione Geografica',
	'GeoIndication/address1/helpText': 'Strada e Numero Civico',
	'GeoIndication/address1/label': 'Indirizzo - Prima Riga',
	'GeoIndication/address2/helpText':
		'Se necessari, dati opzionali - Palazzo, Piano, C/O',
	'GeoIndication/address2/label': 'Indirizzo - Seconda Riga',
	'GeoIndication/akas/helpText':
		"Inserisci, per uso interno, un acronimo dell'Indicazione Geografica",
	'GeoIndication/akas/label': 'AKAS',
	'GeoIndication/city/helpText':
		'Indica la Città della sede legale del Consorzio di Tutela',
	'GeoIndication/city/label': 'Città',
	'GeoIndication/companies/helpText': 'Inserisci le aziende consorziate',
	'GeoIndication/companies/label': 'Produttori',
	'GeoIndication/Company/filter/label': 'Produttore',
	'GeoIndication/Country/filter/label': 'Paese',
	'GeoIndication/Country/helpText':
		'Indica il Paese della sede legale del Consorzio di Tutela',
	'GeoIndication/Country/label': 'Paese',
	'GeoIndication/descriptionValues/helpText': 'JSON',
	'GeoIndication/descriptionValues/label': 'Description Values',
	'GeoIndication/euIndication/helpText':
		'Inserisci il tipo di Indicazione Geografica Europea',
	'GeoIndication/euIndication/label': 'Certificazione Europea di Tipicità',
	'GeoIndication/euIndications/filter': 'Certificazione Europea',
	'GeoIndication/euIndications/filter/label': 'Certificazione Europea di Tipicità',
	'GeoIndication/EuCategory/label': 'Categoria Merceologica U.E.',
	'GeoIndication/italianIndication/helpText':
		'Inserisci il tipo di Indicazione Geografica Italiana',
	'GeoIndication/italianIndication/label': 'Certificazione Italiana di Tipicità',
	'GeoIndication/italianIndications/filter/label': 'Certificazione Italiana di Tipicità',
	'GeoIndication/italianIndications/filter': 'Certificazione Italiana di Tipicità',
	'GeoIndication/labelPlural': 'Indicazioni Geografiche',
	'GeoIndication/lat/helpText': '',
	'GeoIndication/lat/label': 'Latitudine',
	'GeoIndication/lng/helpText': '',
	'GeoIndication/lng/label': 'Longitudine',
	'GeoIndication/logoMedia/helpText':
		"Lunghezza minima della base o dell'Altezza: 800 pixel - Formato Preferibile: PNG (Sfondo Trasparente) - Modalità Colore: RGB",
	'GeoIndication/logoMedia/label': 'Immagine logo',
	'GeoIndication/name/filter/label': 'Nome',
	'GeoIndication/localId/label': 'Local Id',
	'GeoIndication/sfId/label': 'Salesforce Id',
	'GeoIndication/description/label': 'Descrizione Inglese',
	'GeoIndication/descriptionIta/label': 'Descrizione Italiana',
	'GeoIndication/name/helpText': '',
	'GeoIndication/name/label': 'Nome',
	'GeoIndication/postalCode/helpText':
		'Indica il Codice di Avviamento Postale della sede legale del Consorzio di Tutela',
	'GeoIndication/postalCode/label': 'CAP',
	'GeoIndication/socialFacebookPage/helpText':
		"Inserisci l'URL di accesso al profilo Facebook preceduto da http:// or https://",
	'GeoIndication/socialFacebookPage/label': 'Pagina Facebook',
	'GeoIndication/socialInstagramPage/helpText':
		"Inserisci l'URL di accesso al profilo Instagram preceduto da http:// or https://",
	'GeoIndication/socialInstagramPage/label': 'Pagina Instagram',
	'GeoIndication/StateProvince/filter/label': 'Stato/Provincia/Distretto',
	'GeoIndication/StateProvince/helpText':
		'Indica la Provincia della sede legale del Consorzio di Tutela',
	'GeoIndication/StateProvince/label': 'Stato/Provincia/Distretto',
	'GeoIndication/status/helpText':
		'Indica il livello di visibilità della pagina',
	'GeoIndication/status/label': 'Stato',
	'GeoIndication/status/filter': 'Stato',
	'GeoIndication/statuses/filter/label': 'Stati',
	'GeoIndication/vatNumber/helpText': 'Partita IVA del Consorzio di Tutela',
	'GeoIndication/vatNumber/label': 'Partita IVA',
	'GeoIndication/website/helpText':
		'Inserire il sito web aziendale preceduto da https://',
	'GeoIndication/website/label': 'Sito Web',
	'hidden items': 'Elementi Nascosti',
	'HtmlTemplate/defaultValues/helpText': '',
	'HtmlTemplate/defaultValues/label': 'Default Values',
	'HtmlTemplate/form/helpText': '',
	'HtmlTemplate/form/label': 'Form',
	'HtmlTemplate/id/helpText': '',
	'HtmlTemplate/id/label': 'id',
	'HtmlTemplate/labelPlural': 'Templates Html',
	'HtmlTemplate/localId/helpText': '',
	'HtmlTemplate/localId/label': 'Local Id',
	'HtmlTemplate/name/filter/label': 'Nome',
	'HtmlTemplate/name/helpText': '',
	'HtmlTemplate/name/label': 'Nome',
	'HtmlTemplate/renderTemplate/helpText': '',
	'HtmlTemplate/renderTemplate/label': 'Render Template',
	'HtmlTemplate/renderTemplateDem/helpText': '',
	'HtmlTemplate/renderTemplateDem/label': 'Render Template Dem',
	'HtmlTemplate/renderTemplateDemIta/helpText': '',
	'HtmlTemplate/renderTemplateDemIta/label': 'Render Template Dem Ita',
	'HtmlTemplate/renderTemplateIta/helpText': '',
	'HtmlTemplate/renderTemplateIta/label': 'Render Template Ita',
	'HtmlTemplate/validator/helpText': '',
	'HtmlTemplate/validator/label': 'Validator',
	'ItalianIndication/ARCA_SLOW_FOOD/label': 'Arca Slow Food',
	'ItalianIndication/BEVANDA_SPIRITOSA_IG/label': 'Bevanda Spiritosa',
	'ItalianIndication/DOC/label': 'D.O.C.',
	'ItalianIndication/DOCG/label': 'D.O.C.G,.',
	'ItalianIndication/IGT/label': 'I.G.T.',
	'ItalianIndication/PAT/label': 'P.A.T.',
	'ItalianIndication/PRESIDIO_SLOW_FOOD/label': 'Presidio Slow Food',
	ManagedByUser: 'Assegnazione Utente',
	'ManagedByUser/capabilities/helpText':
		"Indica le facoltà dell'utente sull'azienda",
	'ManagedByUser/Company/helpText':
		"Indica il nome dell'azienda su cui può operare",
	'ManagedByUser/labelPlural': 'Assegnazione Utenti',
	'ManagedByUser/localId/helpText': '',
	'ManagedByUser/localId/label': 'Local Id',
	'ManagedByUser/User/helpText': '',
	'ManagedByUser/User/label': 'Utente',
	'New/labelPlural': 'Notizie',
	'News/body/helpText': '',
	'News/body/label': 'Body',
	'News/CreatorUser/filter/label': 'Creato Da',
	'News/EditorialSection/filter/label': 'Sezione Editoriali',
	'News/EditorialTag/filter/label': 'Tag Editoriali',
	'News/isSfAbandoned/filter/label': 'Scollegati da Salesforce',
	'News/labelPlural': 'News',
	'News/NewsFormat/filter/label': 'Tipologia di Post',
	'News/statuses/filter/label': 'Stati',
	'News/target/filter/label': 'Sito di Visualizzazione',
	'News/text/filter/label': 'Testo',
	'News/title/helpText': '',
	'News/title/label': 'Titolo',
	'NewsCitation/localId/helpText': '',
	'NewsCitation/localId/label': 'Local Id',
	'NewsCitation/News/helpText': '',
	'NewsCitation/News/label': 'News Citate',
	'NewsStatus/DELETED/label': 'Eliminato',
	'NewsStatus/DRAFT/label': 'Bozza',
	'NewsStatus/HIDDEN/label': 'Nascosto',
	'NewsStatus/PUBLISHED/label': 'Pubblicato',
	'NewsStatus/STUB/label': 'Segnaposto',
	'NewsStatus/WAITING_PUBLICATION/label': 'In Attesa di Pubblicazione',
	'no results text': 'Nessun Risultato',
	Retailer: 'Retailer',
	'Retailer/address1/helpText': 'Strada e Numero Civico della Sede Legale',
	'Retailer/address1/label': 'Indirizzo - Prima Riga',
	'Retailer/address2/helpText':
		'Se necessari, dati opzionali - Palazzo, Piano, C/O',
	'Retailer/address2/label': 'Indirizzo - Seconda Riga',
	'Retailer/billingName/helpText':
		'Ragione Sociale con cui il Retailer è registrato',
	'Retailer/billingName/label': 'Ragione Sociale',
	'Retailer/localId/label': 'Local Id',
	'Retailer/sfId/label': 'Salesforce Id',
	'Retailer/ManagedByUser/label': 'Utenti',
	'Retailer/city/helpText': 'Indica la Città della sede legale del Retailer',
	'Retailer/city/label': 'Città',
	'Retailer/Country/filter/label': 'Paese',
	'Retailer/Country/helpText':
		'Indica il Paese della sede legale del Retailer',
	'Retailer/Country/label': 'Paese',
	'Retailer/description/helpText':
		'Inserire una descrizione dettagliata in inglese. Compilare con attenzione, il testo sarà utilizzato per fini editoriali.',
	'Retailer/description/label': 'Descrizione breve in inglese',
	'Retailer/descriptionIta/helpText':
		'Inserire una breve descrizione in italiano. Compilare con attenzione, il testo sarà utilizzato per fini editoriali.',
	'Retailer/descriptionIta/label': 'Descrizione breve in italiano',
	'Retailer/descriptionLong/helpText': 'Descrizione accurata in inglese',
	'Retailer/descriptionLong/label': 'Inserire una descrizione dettagliata in inglese. Compilare con attenzione, il testo sarà utilizzato per fini editoriali.',
	'Retailer/descriptionLongIta/helpText': 'In lingua italiana. Usa le maiuscole solo per le iniziali. Il testo sarà usato a fini editoriali.',
	'Retailer/descriptionLongIta/label': 'Descrizione accurata in italiano',
	'Retailer/labelPlural': 'Retailers',
	'Retailer/lat/helpText': '',
	'Retailer/lat/label': 'Latitudine',
	'Retailer/lng/helpText': '',
	'Retailer/lng/label': 'Longitudine',
	'Retailer/logoMedia/helpText':
		"Lunghezza minima della base o dell'Altezza: 800 pixel - Formato Preferibile: PNG (Sfondo Trasparente) - Modalità Colore: RGB",
	'Retailer/logoMedia/label': 'Immagine logo',
	'Retailer/name/filter/label': 'Nome',
	'Retailer/name/helpText': 'Nome con cui è noto il Retailer',
	'Retailer/name/label': 'Nome',
	'Retailer/pecEmail/helpText': 'Posta Elettronica Certificata del Consorzio',
	'Retailer/pecEmail/label': 'Indirizzo PEC',
	'Retailer/postalCode/helpText':
		'Indica il Codice di Avviamento Postale della sede legale del Retailer',
	'Retailer/postalCode/label': 'CAP',
	'Retailer/producerChangedAfter/filter/label': 'Ultima Modifica',
	'Retailer/StateProvince/helpText':
		'Indica la Provincia della sede legale del Retailer',
	'Retailer/StateProvince/label': 'Provincia',
	'Retailer/type/helpText': 'Indica il tipo di Retailer',
	'Retailer/type/label': 'Tipologia',
	'Retailer/types/filter/label': 'Tipologie',
	'Retailer/vatNumber/helpText': 'Partita IVA del Retailer',
	'Retailer/vatNumber/label': 'Partita IVA',
	'Retailer/website/helpText':
		"Inserisci il sito web per aiutare l'admin ad associare il registrante",
	'Retailer/website/label': 'Sito Web',
	'Retailer/brands/label': 'Brands',
	'RetailerType/GDO/label': 'GDO',
	'RetailerType/Grossista/label': 'Grossista',
	'RetailerType/PICCOLO/label': 'DO',
	'TargetSite/VOTE/label': 'Vota',
	'Team/labelPlural': 'Teams',
	'Team/localId/helpText': '',
	'Team/localId/label': 'Local Id',
	'Team/name/helpText': 'Nome del Team',
	'Team/name/label': 'Nome',
	'Team/Retailer/helpText': 'Retailer a cui appartengono i membri del Team',
	'Team/Retailer/label': 'Retailer',
	'Team/users/helpText': 'Indica gli Utenti appartenenti al Team',
	'Team/users/label': 'Membri',
	User: 'Utente',
	'Branch/labelPlural': 'Sedi',
	'FairParticipation/labelPlural': 'Partecipazione a fiere',
	'CompanyLevel/labelPlural': 'Livelli',
	'CompanyYear/labelPlural': 'Dati annuali',
	'HighlightedGood/labelPlural': 'In evidenza',
	// 'GoodFormat/label': 'Formato di Vendita',
	// 'HighlightedGood/label': 'Prodotti in evidenza'
	'Companies and Goods': 'Azienda e Prodotti',
	'Service/labelPlural': 'Servizi',
	Editorial: 'Editoriale',
	'NewsFormat/labelPlural': 'Format Editoriale',
	'EditorialTag/labelPlural': 'Tag Editoriale',
	'EditorialTag/name/label': 'Nome',
	Meta: 'Altro',
	'ObjectHistory/labelPlural': 'Cronologia Modifiche',
	'Award/invitedCompany/label': 'Azienda Invitate',
	'Award/awardResult/label': 'Risultato',
	'Award/localId/label': 'Local Id',
	'Award/localId/helpText': '',
	'Award/visibility/label': 'Visibilità',
	'Award/visibility/helpText':
		"Imposta la visibilità dell'evento sul sito di consultazione target",
	'AwardVisibility/PUBLIC/label': 'Pubblica',
	'AwardVisibility/INVITED_ONLY/label': 'Su Invito',
	'Award/publicDescription/label': 'Descrizione Manifestazione Per Sito di Consultazione - Anonimi',
	'Award/publicDescription/helpText':
		'Presentazione della Manifestazione per Sito di Consultazione e WebApp Voto',
	'Award/eventDescription/label': 'Descrizione Manifestazione - Pagina Premiazione',
	'Award/eventDescription/helpText':
		'Descrizione che apparirà nella Pagina riguardante la cerimonia di premiazione su WebApp Voto',
	'Award/description6Label/label': 'Label della Sesta Descrizione della Candidatura',
	'Award/description6Label/helpText': '',
	'Award/description6Max/label': 'Massimo di Caratteri della Sesta Descrizione',
	'Award/description6Max/helpText': '',
	'Award/description7Label/label': 'Label della Settima Descrizione della Candidatura',
	'Award/description7Label/helpText': '',
	'Award/description7Max/label': 'Massimo di Caratteri della Settima Descrizione',
	'Award/description7Max/helpText': '',
	'Award/description8Label/label': "Label dell'Ottava Descrizione della Candidatura",
	'Award/description8Label/helpText': '',
	'Award/description8Max/label': "Massimo di Caratteri dell'Ottava Descrizione",
	'Award/description8Max/helpText': '',
	'Award/description9Label/label': 'Label della Nona Descrizione della Candidatura',
	'Award/description9Label/helpText': '',
	'Award/description9Max/label': 'Massimo di Caratteri della Nona Descrizione',
	'Award/description9Max/helpText': '',
	'Award/description10Label/label': 'Label della Decima Descrizione della Candidatura',
	'Award/description10Label/helpText': '',
	'Award/description10Max/label': 'Massimo di Caratteri della Decima Descrizione',
	'Award/description10Max/helpText': '',
	'Award/invitedCompanies/label': 'Aziende Invitate',
	'Award/invitedCompanies/helpText': '',
	'See candidacies': 'Guarda le Candidature',
	'View updates': 'Guarda gli Aggiornamenti',
	'Award/status/set': 'Stato',
	'Award/status/DRAFT/helpText': '',
	Current: 'In Uso',
	'Award/status/CANDIDANCY_OPEN/helpText': '',
	CANDIDANCY_CLOSED: 'Candidature Chiuse',
	'Award/status/CANDIDANCY_CLOSED/helpText': '',
	VOTING_OPEN: 'Votazione Aperto',
	'Award/status/VOTING_OPEN/helpText': '',
	VOTING_CLOSED: 'Votazione Chiusa',
	'Award/status/VOTING_CLOSED/helpText': '',
	CONCLUDED: 'Concluso',
	'Award/status/CONCLUDED/helpText': '',
	'AwardInvitedCompanyConnection/labelPlural': 'Aziende invitate',
	'AwardInvitedCompany/Company/label': 'Azienda',
	'AwardInvitedCompany/Company/helpText': '',
	'AwardResult/labelPlural': 'Risultati',
	'AwardResult/AwardCategory/label': 'Categoria',
	'AwardResult/AwardCategory/helpText': '',
	'AwardResult/Candidacy/label': 'Candidatura',
	'AwardResult/Candidacy/helpText': '',
	'AwardResult/finalPosition/label': 'Piazzamento',
	'AwardResult/finalPosition/helpText': '',
	'AwardInvitedCompanyConnection/Company/label': 'Azienda',
	'User/statuses/filter/label': 'Stati',
	Nome: 'Nome',
	Email: 'Email',
	'ObjectHistory/userRoles/filter/label': 'Ruoli',
	'ObjectHistory/User/filter/label': 'Utente',
	'ObjectHistory/Object/filter/label': 'Oggetto',
	'ObjectHistory/Award/filter/label': 'Manifestazione',
	'ObjectHistory/timestampMin/filter/label': 'A partire dal:',
	'ObjectHistory/timestampMax/filter/label': 'Fino al:',
	'ObjectHistory/collection/label': 'Tipo di Oggetto',
	'ObjectHistory/collection/helpText': '',
	'ObjectHistory/User/label': 'Utente',
	'ObjectHistory/User/helpText': '',
	'ObjectHistory/timestamp/label': 'Data e Ora',
	'ObjectHistory/timestamp/helpText': '',
	'ObjectHistory/actions/filter/label': 'Azione',
	'ObjectHistory/collections/filter/label': 'Tipi di Oggetto',
	HtmlTemplate: 'Template Html',
	'Fair/Country/filter/label': 'Nazione',
	Yes: 'Sì',
	No: 'No',
	'EditorialSection/editorialBrand/label': 'Brand',
	'EditorialSection/editorialBrand/helpText': 'Brand di Gruppo Food cui fa riferimento',
	'EditorialSection/Parent/label': 'Categoria Padre',
	'EditorialSection/Parent/helpText': 'Verticale a cui appartiene la sezione editoriale',
	'EditorialSection/akas/label': 'a.k.a.',
	'EditorialSection/akas/helpText': 'Altri Nomi',
	'EditorialSection/level/label': 'Livello',
	'EditorialSection/level/helpText': 'Livello gerarchico',
	'EditorialSection/styleClass/label': 'Classe CSS',
	'EditorialSection/styleClass/helpText': '',
	'EditorialSection/iconMedia/label': 'Icona',
	'EditorialSection/iconMedia/helpText': 'Logo della sezione editoriale',
	'EditorialSection/goodClasses/helpText': 'Seleziona le categorie merceologiche raccolte nella sezione editoriale',
	EditorialSection: 'Sezione Editoriale',
	'NewsFormat/name/label': 'Nome',
	'NewsFormat/name/helpText': '',
	'NewsFormat/HtmlTemplate/label': 'Template Html',
	'NewsFormat/HtmlTemplate/helpText': '',
	'AwardProducerPublic/labelPlural': 'Manifestazioni',
	'AwardProducerPrivate/labelPlural': 'Showcase',
	'AwardProducerSourcing/labelPlural': 'Sourcing',
	'ToS/labelPlural': 'Termini e Condizioni',
	Candidacy_VOTE: 'Candidature',
	'Candidacy_VOTE/name/label': 'Nome',
	'Candidacy_VOTE/name/helpText': '',
	'Candidacy_VOTE/goods/label': 'Prodotto',
	'Candidacy_VOTE/goods/helpText':
		'Selezionare i prodotti appartenenti al brand precedentemente selezionato',
	'Candidacy_VOTE/Brand/label': 'Brand',
	'Candidacy_VOTE/Brand/helpText':
		'Selezionare il brand del prodotto / dei prodotti che vuoi candidare',
	'Candidacy_VOTE/range/label': 'Linea di Prodotto',
	'Candidacy_VOTE/range/helpText':
		'Inserire il nome della Linea di Prodotto a cui appartiene/appartengono i prodotti candidati, Lascia il campo vuoto se non appartiene ad alcuna',
	'Candidacy_VOTE/featuredImageMedia/label': 'Immagine per la candidatura',
	'Candidacy_VOTE/featuredImageMedia/helpText': '',
	'Candidacy_VOTE/launchDate/label': 'Data di Lancio',
	'Candidacy_VOTE/launchDate/helpText':
		'Inserisci la data di lancio del prodotto sul mercato a cui la manifestazione si riferisce',
	Candidacy_SHOWCASE: 'Candidature',
	'Candidacy_SHOWCASE/name/label': 'Nome',
	'Candidacy_SHOWCASE/name/helpText':
		'Inserire il nome del prodotto in inglese che verrà visualizzato nella scheda prodotto.',
	'Candidacy_SHOWCASE/goods/label': 'Prodotto',
	'Candidacy_SHOWCASE/goods/helpText':
		'Scegliere il prodotto/i fra quelli disponibili del Brand selezionato',
	'Candidacy_SHOWCASE/Brand/label': 'Brand',
	'Candidacy_SHOWCASE/Brand/helpText':
		'Selezionare il brand del prodotto da esporre',
	'Candidacy_SHOWCASE/range/label': 'Linea di Prodotto',
	'Candidacy_SHOWCASE/range/helpText':
		'Inserire il nome della Linea di Prodotto a cui appartiene/appartengono i prodotti candidati, Lascia il campo vuoto se non appartiene ad alcuna.',
	'Candidacy_SHOWCASE/extraMedia/label': 'Immagine per la stampa',
	'Candidacy_SHOWCASE/extraMedia/helpText':
		"Foto del prodotto: almeno 3500px di altezza/larghezza in alta risoluzione a 300 dpi (CMYK), jpeg - Raccomandiamo un'immagine scontornata con sfondo bianco o trasparente",
	'Candidacy_SHOWCASE/launchDate/label': 'Data di Lancio',
	'Candidacy_SHOWCASE/launchDate/helpText':
		'Inserire la data di lancio del prodotto nel mercato a cui lo Showcase si riferisce',
	'Candidacy_SHOWCASE/additionalImagesMedia/label': 'Gallery',
	'Candidacy_SHOWCASE/featuredImageMedia/label': 'Immagine per il digitale',
	'Candidacy_SHOWCASE/featuredImageMedia/helpText':
		"Lunghezza minima della base o dell'altezza: 1200 pixel - Formato: JPG/PNG - Modalità Colore: RGB - Raccomandiamo un'immagine scontornata con sfondo bianco o trasparente",

	Candidacy_SOURCING: 'Candidature',
	'Candidacy_SOURCING/name/label': 'Nome',
	'Candidacy_SOURCING/name/helpText':
		'Inserire il nome del prodotto in inglese che verrà visualizzato nella scheda prodotto.',
	'Candidacy_SOURCING/goods/label': 'Prodotto',
	'Candidacy_SOURCING/goods/helpText':
		'Scegliere il prodotto/i fra quelli disponibili del Brand selezionato',
	'Candidacy_SOURCING/Brand/label': 'Brand',
	'Candidacy_SOURCING/Brand/helpText':
		'Selezionare il brand del prodotto da esporre',
	'Candidacy_SOURCING/range/label': 'Linea di Prodotto',
	'Candidacy_SOURCING/range/helpText':
		'Inserire il nome della Linea di Prodotto a cui appartiene/appartengono i prodotti candidati, Lascia il campo vuoto se non appartiene ad alcuna.',
	'Candidacy_SOURCING/extraMedia/label': 'Immagine per la stampa',
	'Candidacy_SOURCING/extraMedia/helpText':
		"Foto del prodotto: almeno 3500px di altezza/larghezza in alta risoluzione a 300 dpi (CMYK), jpeg - Raccomandiamo un'immagine scontornata con sfondo bianco o trasparente",
	'Candidacy_SOURCING/launchDate/label': 'Data di Lancio',
	'Candidacy_SOURCING/launchDate/helpText':
		'Inserire la data di lancio del prodotto nel mercato a cui lo Showcase si riferisce',
	'Candidacy_SOURCING/additionalImagesMedia/label': 'Gallery',
	'Candidacy_SOURCING/featuredImageMedia/label': 'Immagine per il digitale',
	'Candidacy_SOURCING/featuredImageMedia/helpText':
		"Lunghezza minima della base o dell'altezza: 1200 pixel - Formato: JPG/PNG - Modalità Colore: RGB - Raccomandiamo un'immagine scontornata con sfondo bianco o trasparente",
	'Candidacy_SOURCING/AwardCategory/label': 'Categoria',
	'Candidacy_SOURCING/AwardCategory/helpText':
		'Scegli la categoria per cui proponi il prodotto',

	'AwardInvitedCompany/labelPlural': 'Inviti a manifestazioni',
	'AwardInvitedCompany/Award/label': 'Manifestazione',
	'AwardInvitedCompany/notified/label': 'Notificato',
	'AwardInvitedCompanyConnection/maxGoodsPerCandidacy/label': 'Prodotti per candidatura',
	'AwardInvitedCompanyConnection/maxPerCompanyCandidacies/label': 'Candidature massime',
	'AwardInvitedCompany/maxGoodsPerCandidacy/label': 'Prodotti per candidatura',
	'AwardInvitedCompany/maxPerCompanyCandidacies/label': 'Candidature massime',
	'AwardInvitedCompany/localId/label': 'LocalId',
	'AwardInvitedCompany/maxGoodsPerCandidacy/helpText': 'Puoi impostare un valore personalizzato rispetto al default della manifestazione',
	'AwardInvitedCompany/maxPerCompanyCandidacies/helpText': 'Puoi impostare un valore personalizzato rispetto al default della manifestazione',
	'AwardInvitedCompany/notified/helpText': "L'evento è stato notificato all'azienda?",
	'Company/hasGoodsWithEan/filter/label': 'Ha prodotti con EAN?',
	'Company/hasLogo/filter/label': 'Ha un logo?',
	'Company/hasCandidacies/filter/label': 'Ha candidature?',
	'Company/profileCompleted/filter/label': 'Ha il profilo completo?',
	'AnswerSet/labelPlural': 'Risposte',
	'QuestionSet/labelPlural': 'Domande',
	'ManagedByUser/entityType/label': 'Tipo entità',
	'ManagedByUser/entity/label': 'Entità',
	'User/ManagedByUser/label': 'Oggetti gestiti',
	'AnswerSet/Company/label': 'Azienda',
	'AnswerSet/sentDate/label': 'Data invio risposta',
	'AnswerSet/QuestionSet/label': 'Gruppo di domande',
	'AnswerSet/isSent/filter/label': 'Messaggio inviato?',
	'AnswerSet/isClosed/filter/label': 'Chiuso?',
	'AnswerSet/name/label': 'Domande',
	'AnswerSet/answered/label': 'Risposto',
	'ObjectHistory/params/label': 'Parametri',
	'ObjectHistory/Object/label': 'Oggetto',
	'ObjectHistory/action/label': 'Azione',
	'ObjectHistory/localId/label': 'Local ID',
	'Questions of user': "Domande dell'utente",
	'CompanyOwnership/FAMILY_BUSINESS/label': 'Impresa a controllo famigliare',
	'CompanyOwnership/MULTINATIONAL_CORPORATION/label': 'Impresa Multinazionale',
	'CompanyOwnership/PRIVATE_EQUITY/label': 'Private Equity',
	'CompanyOwnership/COOPERATIVE/label': 'Cooperativa',
	FAMILY_BUSINESS: 'Impresa a controllo famigliare',
	MULTINATIONAL_CORPORATION: 'Impresa Multinazionale',
	PRIVATE_EQUITY: 'Private Equity',
	COOPERATIVE: 'Cooperativa',
	'EditorialSection/slug/label': 'Slug',
	'EditorialSection/localId/label': 'Local Id',
	'AnswerSet/localId/label': 'Local Id',
	'empty list default message': 'Nessun risultato corrisponde alla ricerca',
	'FairVisit/labelPlural': 'Fair Visits',
	'FrequentSearch/labelPlural': 'Ricerche frequenti',
	'ObjectHistoryAction/CREATE/label': 'Creazione',
	'ObjectHistoryAction/PATCH/label': 'Modifica',
	'ObjectHistoryAction/SET_STATUS/label': 'Cambio stato',
	'ObjectHistoryAction/DELETE/label': 'Rimozione',
	'ObjectHistoryAction/SUBCREATE/label': 'Sub-creazione',
	'ObjectHistoryCollection/AnswerSet/label': 'Risposte Aziendali',
	'ObjectHistoryCollection/AtecoCode/label': 'Codice ATECO',
	'ObjectHistoryCollection/Award/label': 'Manifestazione',
	'ObjectHistoryCollection/AwardCategory/label': 'Categoria manifestazione',
	'ObjectHistoryCollection/AwardInvitedCompany/label': 'Aziende invitate a evento',
	'ObjectHistoryCollection/AwardInvitedUser/label': 'Utenti invitati a evento',
	'ObjectHistoryCollection/AwardResult/label': 'Risultati manifestazione',
	'ObjectHistoryCollection/Banner/label': 'Banner',
	'ObjectHistoryCollection/BannerView/label': 'Banner pubblici',
	'ObjectHistoryCollection/Branch/label': 'Rami azienda',
	'ObjectHistoryCollection/Brand/label': 'Brand',
	'ObjectHistoryCollection/Candidacy/label': 'Candidature',
	'ObjectHistoryCollection/CandidacyRating/label': 'Voti candidatura',
	'ObjectHistoryCollection/Company/label': 'Azienda',
	'ObjectHistoryCollection/CompanyCertification/label': 'Certificazioni',
	'ObjectHistoryCollection/CompanyGroup/label': 'Company Group',
	'ObjectHistoryCollection/CompanyLevel/label': 'Livelli aziendali',
	'ObjectHistoryCollection/CompanyYear/label': 'Company YEar',
	'ObjectHistoryCollection/Country/label': 'Paese',
	'ObjectHistoryCollection/EditorialSection/label': 'Sezione editoriale',
	'ObjectHistoryCollection/EditorialTag/label': 'Tag editoriale',
	'ObjectHistoryCollection/EmailDomain/label': 'Dominio Email',
	'ObjectHistoryCollection/EuCategory/label': 'Categoria merceologica EU',
	'ObjectHistoryCollection/Fair/label': 'Fiera',
	'ObjectHistoryCollection/FairParticipation/label': 'Partecipazione a fiera',
	'ObjectHistoryCollection/FairVisit/label': 'Tappa fiera',
	'ObjectHistoryCollection/FairVisitParticipation/label': 'Visita alla fiera',
	'ObjectHistoryCollection/FrequentSearch/label': 'Ricerca Frequente',
	'ObjectHistoryCollection/GeoIndication/label': 'Indicazione Geo',
	'ObjectHistoryCollection/Good/label': 'Prodotti',
	'ObjectHistoryCollection/GoodClass/label': 'Classi prodotto',
	'EditorialSection/goodClasses/label': 'Classi prodotto',
	'ObjectHistoryCollection/GoodFeatureCertification/label': 'Certificazioni prodotti',
	'ObjectHistoryCollection/GoodFormat/label': 'Formati prodotti',
	'ObjectHistoryCollection/HighlightedGood/label': 'Prodotti in evidenza',
	'ObjectHistoryCollection/HtmlTemplate/label': 'Template HTML',
	'ObjectHistoryCollection/Magazine/label': 'Riviste',
	'ObjectHistoryCollection/MagazineBrand/label': 'Brand riviste',
	'ObjectHistoryCollection/MagazineCollection/label': 'Collezione riviste',
	'ObjectHistoryCollection/MagazineCollectionVisibility/label': 'Visibilità della collezione di Magazine',
	'ObjectHistoryCollection/MagazineSubscription/label':'Abbonamenti a riviste',
	'ObjectHistoryCollection/ManagedByUser/label': 'Oggetti gestiti da utente',
	'ObjectHistoryCollection/Media/label': 'Media',
	'ObjectHistoryCollection/News/label': 'News',
	'ObjectHistoryCollection/NewsCitation/label': 'Citazioni',
	'ObjectHistoryCollection/NewsFormat/label': 'Formati news',
	'ObjectHistoryCollection/Newsletter/label': 'Newsletter',
	'ObjectHistoryCollection/NewsletterIssue/label': 'Numero newsletter',
	'ObjectHistoryCollection/NewsletterIssueHasNews/label': 'NewsletterIssueHasNews',
	'ObjectHistoryCollection/ObjectHistory/label': 'Cronologia modifiche',
	'ObjectHistoryCollection/Permalink/label': 'Permalink',
	'ObjectHistoryCollection/QuestionSet/label': "Domande all'azienda",
	'ObjectHistoryCollection/Retailer/label': 'Retailer',
	'ObjectHistoryCollection/Service/label': 'Service',
	'ObjectHistoryCollection/StateProvince/label': 'Stato/provincia',
	'ObjectHistoryCollection/Team/label': 'Team',
	'ObjectHistoryCollection/ToS/label': 'Condizioni di utilizzo',
	'ObjectHistoryCollection/User/label': 'Utenti',
	'ObjectHistoryCollection/UserNote/label': 'Note utenti',
	'ObjectHistoryCollection/UserObjectSubscription/label': 'Bookmark utenti',
	'ObjectHistoryCollection/Widget/label': 'Widget',
	'ObjectHistory/action/helpText': '',
	'Company/logoPrintMedia/label': 'Logo in alta definizione',
	'Company/logoPrintMedia/helpText':
		"Caricare il logo dell'azienda in vettoriale per la stampa - Formato Preferibile: PDF",
	'no logoPrintMedia alert':
		"<strong>ATTENZIONE:</strong> Se partecipi ad una manifestazione, ad uno showcase o ad un sourcing ricordati di caricare il logo dell'azienda in vettoriale.",
	Remove: 'Rimuovi',
	'FairParticipation/Fair/label': 'Fiera',
	'CompanyYear/year/label': 'Anno',
	'CompanyYear/year/helpText':
		"Inserire l'anno di riferimento per i dati seguenti",
	'CompanyYear/revenue/label': 'Fatturato',
	'CompanyYear/revenue/helpText':
		"Inserire il fatturato maturato nell'anno di riferimento, in Euro",
	'CompanyYear/gain/label': 'Utile/Perdita',
	'CompanyYear/gain/helpText':
		"Utile o perdita d'esercizio dell'anno di riferimento, in Euro.",
	'CompanyYear/mol/label': 'MOL',
	'CompanyYear/mol/helpText':
		"Il margine operativo lordo realizzato nell'anno di riferimento, in Euro.",
	'CompanyYear/ebitda/label': 'EBITDA',
	'CompanyYear/ebitda/helpText': 'L\'Ebitda realizzato nell\'anno di riferimento, in Euro.',
	'CompanyYear/numEmployees/label': 'Numero di dipendenti',
	'CompanyYear/numEmployees/helpText':
		"Inserire il numero dei dipendenti nell'anno di riferimento",
	'CompanyYear/exportRevenue/label': 'Fatturato da Export',
	'CompanyYear/exportRevenue/helpText': 'Inserisci il valore, anche arrotondato, in € del valore di fatturato ottenuto direttamente dall\'export',
	'FairParticipation/note/label': 'Note',
	'FairParticipation/note/helpText': 'puoi aggiungere note e appunti',
	'options.email error': 'Credenziali errate',
	'Company/companyYears/label': 'Dati annuali',
	'... or logout': '... o fai logout',
	'Renew your authentication': 'Rinnova la tua autenticazione',
	'Use the credentials we gave you':
		'Utilizza le credenziali che ti abbiamo fornito',
	'Sign In / Register': 'Sign In / Registrati',
	'Sign In with your social account': 'Accedi con i tuoi account social',
	'Sign In with your social accounts': 'Accedi con i tuoi account social',
	'Sign In with username and password': 'Accedi con username e password',
	'GOOGLE login button': 'Accedi con Google',
	'Sign in with GOOGLE': 'Accedi con Google',
	'FACEBOOK login button': 'Accedi con Facebook',
	'Sign in with FACEBOOK': 'Accedi con Facebook',
	'LINKEDIN login button': 'Accedi con Linkedin',
	'Sign in with LINKEDIN': 'Accedi con Linkedin',
	'Registration - Terms & Conditions': 'Registrazione / Termini & Condizioni',
	'To continue, you must agree to Gruppo Food Terms of Service and Privacy Policy':
		'Per continuare devi accettare la Privacy Policy e Termini & Condizioni di Gruppo Food',
	'Click here to read them in English': 'Clicca per leggerli in inglese',
	'I Agree': 'Accetto',
	'Registration - Business Profile': 'Registrazione / Profilo business',
	'We reserve specific functionalities to suppliers, buyers and food service operators so we have to be sure you work in the food business.':
		'Riserviamo alcune funzionalità specifiche a produttori, buyer e operatori del settore food service; per questo motivo dobbiamo accertare la tua appartenenza al settore',
	"We can't accept public email domains (@gmail.com, @aol.com, ...)":
		'Non verranno accettati domini email pubblici (@gmail.com, @aol.com, ...)',
	Continue: 'Continua',
	'We sent you a verification email to':
		"Ti abbiamo inviati una mail all'indirizzo",
	'Please click the link in it to complete the registration':
		'Clicca il link contenuto al suo interno per terminare la registrazione',
	'Change work email': 'Cambia indirizzo email',
	loading: 'Caricamento...',
	Hello: 'Benvenuto',
	'Thanks for validating': "Grazie per aver effettuato l'accesso",
	'You have been successfully registered as a': 'Sei stato registrato come',
	CONSUMER: 'Consumatore',
	'as we related you to': 'e sei stato associato a',
	"If you think we're wrong please": 'Se pensi si sia verificato un problema',
	'contact us': 'contattaci',
	Proceed: 'Procedi',
	'Insert your job email address':
		'Inserisci il tuo indirizzo email di lavoro',
	'Good/GeoIndication/label': 'Indicazione geografica',
	'Good/GeoIndication/helpText':
		"Indicare l'eventuale indicazione geografica del prodotto",
	'CompanyLevel/description/label': 'Descrizione',
	'CompanyBadge/name/label': 'Nome',
	'CompanyBadge/name/filter/label': 'Nome',
	'CompanyBadge/targetSite/filter': 'Brand',
	'CompanyBadge/labelPlural': 'Badges',
	'CompanyBadge/targetSite/filter/label': 'Sito di Pubblicazione',
	'CompanyBadge/targetSite/label': 'Sito di Pubblicazione',
	'CompanyBadge/localId/label': 'Local ID',
	'CompanyBadge/localId/helpText': '',
	'CompanyBadge/name/helpText': '',
	'CompanyBadge/iconMedia/label': 'Media icona',
	'CompanyBadge/iconMedia/helpText': '',
	'CompanyBadge/styleClass/label': 'Classe CSS personalizzata',
	'CompanyBadge/color/label': 'Colore',
	'CompanyBadge/styleClass/helpText': '',
	'CompanyBadge/color/helpText':
		'In formato esadecimale, preceduto dal carattere "#"',
	'CompanyBadge/descriptionLong/label': 'Descrizione lunga',
	'CompanyLevel/descriptionLong/label': 'Descrizione lunga',
	'CompanyBadge/descriptionLong/helpText': '',
	'CompanyBadge/targetSite/helpText': '',
	'CompanyLevel/targetSite/label': 'Sito di riferimento',
	'CompanyLevel/targetSite/helpText': '',
	'CompanyLevel/level/label': 'Livello',
	'CompanyLevel/localId/label': 'Local ID',
	'CompanyLevel/companyBadges/label': 'Badges',
	'CompanyLevel/descriptionLong/helpText': '',
	'CompanyLevel/startDate/helpText': '',
	'CompanyLevel/startDate/label': 'Data di inizio',
	'CompanyLevel/expirationDate/label': 'Data di scadenza',
	'CompanyLevel/expirationDate/helpText': '',
	'CompanyLevel/website/label': 'Sito web',
	'CompanyLevel/website/helpText': '',
	'CompanyLevel/companyBadges/helpText': '',
	'CompanyLevel/level/helpText': '',
	'CompanyLevel/description/helpText': '',
	'CompanyLevel/layout/label': 'Raggruppamento prodotti',
	'CompanyLevel/layout/helptext': 'Nella pagina profilo per il livello in oggetto puoi scegliere se raggruppare i prodotti in evidenza per Brand o per Linea di Prodotti',
	'CompanyLevel/videoUrl/label':'URL a Video di presentazione dell\'azienda',
	'CompanyLevel/videoUrl/helptext':'Inserisci un URL a Vimeo o Youtube contenente un video di presentazione dell\'azienda nella lingua del brand di riferimento',
	'CompanyLayout/BRAND_GROUPED/label': 'Per Brand',
	'CompanyLayout/RANGE_GROUPED/label': 'Per Linea di Prodotto',
	'FrequentSearch/collection/label': 'Directory di Riferimento',
	'FrequentSearchCollection/GOODS/label': 'Prodotti',
	'FrequentSearchCollection/GEO_INDICATIONS/label': 'Indicazioni Geografiche',
	'FrequentSearchCollection/NEWS/label': 'Notizie',
	'FrequentSearch/targetSites/label': 'Website di Riferimento',
	'FrequentSearch/name/label': 'Nome Frequent Search',
	'FrequentSearch/akas/label': 'Akas',
	'FrequentSearch/goodClasses/label': 'Categorie Merceologiche',
	'FrequentSearch/plProducer/label': 'Produttore di Private Label',
	'FrequentSearch/highlightReasons/label': 'Highlight Reasons',
	'highlightReasons/NEW_PRODUCT/label': 'Nuovo Prodotto',
	'highlightReasons/RENOVATED_RECIPE/label': 'Ricetta Rinnovata',
	'highlightReasons/RENOVATED_PACKAGING/label': 'Packaging Rinnovato',
	'highlightReasons/SPECIAL_EDITION/label': 'Edizione Speciale',
	'highlightReasons/NEW_SIZES/label': 'Nuovi Formati di Vendita',
	'FrequentSearch/awardFinalPositions/label': "Piazzamento all'Evento",
	'FrequentSearch/textSearch/label': 'Ricerca Testuale',
	'FrequentSearch/pricePositionings/label': 'Posizionamento di Prezzo',
	'FrequentSearch/awards/label': 'Manifestazione',
	'FrequentSearch/goodFeatureCertifications/label': 'Features',
	'FrequentSearch/geoIndications/label': 'Indicazione Geografica',
	'FrequentSearch/companyCertifications/label': 'Certificazione',
	'FrequentSearch/fairs/label': 'Edizione della Fiera',
	'FrequentSearch/stateProvinces/label': 'Provincia',
	'CompanyLevel/newsPath/label': 'News path',
	'CompanyLevel/newsPath/helpText':
		'Inserire in formato /company-brand-product/SLUG/',
	'Fair/newsPath/label': 'News path',
	'products with print alert':
		"Se hai partecipato all'Italian Food Awards 2018 ai prodotti attualmente in evidenza sono collegate schede fisiche e cavalierini. Ti consigliamo di cambiare prodotti in evidenza al termine della fiera",
	'Company/sfId/label': 'Salesforce Id',
	'Company/billingBranch/label': 'Sede legale',
	'Company/billingBranch/helpText': "Indicare l'indirizzo di fatturazione",
	'BRANCH/NAME/LABEL': 'Nome',
	'BRANCH/TYPE/LABEL': 'Tipologia',
	'BRANCH/COUNTRY/LABEL': 'Stato',
	'Branch/localId/label': 'Local Id',
	'Branch/vatNumber/label': 'Partita IVA',
	'Branch/name/helpText':
		'Se la sede in oggetto ha una propria natura giuridica (es. Filiale estera) inserire la Ragione Sociale',
	'Branch/vatNumber/helpText':
		'Se la sede in oggetto ha una propria natura giuridica (es. Filiale estera) inserirne la Partita IVA es. ITXXX….',
	'Branch/website/label': 'Sito web',
	'Branch/website/helpText':
		'Se la sede in oggetto ha una propria natura giuridica (es. Filiale estera) inserirne il sito internet istituzionale preceduto da https://',
	'Branch/StateProvince/label': 'Provincia',
	'Branch/postalCode/label': 'CAP',
	'Branch/city/label': 'Città',
	'Branch/address1/label': 'Indirizzo - Riga 1',
	'Branch/address2/label': 'Indirizzo - Riga 2',
	'Branch/lat/label': 'Latitudine',
	'Branch/lng/label': 'Longitudine',
	'HighlightedGood/Good/label': 'Nome del Prodotto',
	'HighlightedGood/Platform link/label': 'Link alla piattaforma',
	'HighlightedGood/Brand/label': 'Brand',
	'HighlightedGood/targetSite/label': 'Piattaforma di Pubblicazione',
	'HighlightedGood/claim1/label': 'Primo Claim',
	'HighlightedGood/claim2/label': 'Secondo Claim',
	'HighlightedGood/claim3/label': 'Terzo Claim',
	'HighlightedGood/Good/helpText':
		'Selezionare il prodotto da rendere visibile',
	'HighlightedGood/targetSite/helpText':
		'Selezionare la piattaforma di pubblicazione',
	'HighlightedGood/highlightReasons/label': 'Motivazioni',
	'HighlightedGood/highlightReasons/helpText':
		'Scegli una o più ragioni per cui metti in evidenza il Prodotto',
	'HighlightedGood/claim1/helpText':
		'Inserisci uno slogan - in inglese per Italianfood.net / in italiano per le altre Piattaforme di Pubblicazione',
	'HighlightedGood/claim2/helpText':
		'Inserisci uno slogan - in inglese per Italianfood.net / in italiano per le altre Piattaforme di Pubblicazione',
	'HighlightedGood/claim3/helpText':
		'Inserisci uno slogan - in inglese per Italianfood.net / in italiano per le altre Piattaforme di Pubblicazione',
	'TargetSite/DOLCESALATO/label': 'DOLCESALATO',
	'HighlightReasons/RENOVATED_RECIPE/label': 'Ricetta Rinnovata',
	'HighlightReasons/RENOVATED_PACKAGING/label': 'Nuovo Packaging',
	'HighlightReasons/SPECIAL_EDITION/label': 'Edizione Speciale',
	'HighlightReasons/NEW_PRODUCT/label': 'Nuovo Prodotto',
	'HighlightReasons/NEW_SIZES/label': 'Nuovi Formati',
	'AwardInvitedCompany/Award/helpText':
		"Invita l'azienda ad una manifestazione del Gruppo Food",
	'CompanyCertification/labelPlural': 'Certificazioni aziendali',
	'GoodFeatureCertification/labelPlural': 'Peculiarità dei prodotti',
	'GoodFeatureCertification/localId/label': 'Local Id',
	'GoodFeatureCertification/name/label': 'Nome in inglese',
	'GoodFeatureCertification/nameIta/label': 'Nome in Italiano',
	'GoodFeatureCertification/website/label': 'Sito Web',
	'CompanyCertification/localId/label': 'Local Id',
	'GoodFeatureCertification/akas/label': 'Alias',
	'GoodFeatureCertification/akas/helpText':
		'inserisci eventuali sinonimi. Attenzione ai Marchi Registrati delle Certificazioni',
	'GoodFeatureCertification/website/helpText':
		'se è un marchio registrato inserisci sito web',
	'FairParticipationConnection/Company/Label': 'Nome Azienda',
	'FairParticipationConnection/Stand/Label': 'Stand',
	'FairParticipationConnection/Pavilion/Label': 'Padiglione',
	'Fair/FairParticipation/label': 'Espositori',
	'Company/labelPlural': 'Industrie Alimentari',
	'FairParticipationConnection/labelPlural': 'Espositori',
	'NewsCitation/labelPlural': 'Notizie Correlate',
	'FAIRVISIT/USER/LABEL': 'Utente',
	'FAIRVISIT/FAIR/LABEL': 'Fiera',
	'FAIRVISIT/USER/FILTER/LABEL': 'Utente',
	'FAIRVISIT/FAIR/FILTER/LABEL': 'Fiera',
	'QuestionSet/isClosed/filter/label': 'Conversazione Conclusa',
	'QuestionSet/name/filter/label': 'Conversazione',
	'FrequentSearch/name/filter/label': 'Nome Frequent Search',
	'FrequentSearch/collection/filter/label': 'Directory di Riferimento',
	'FrequentSearchCollection/GOOD/label': 'Prodotti',
	'FrequentSearchCollection/COMPANY/label': 'Industrie Alimentari',
	'FrequentSearchCollection/GEO_INDICATION/label': 'Indicazioni Geografiche',
	'FrequentSearch/Statuses/filter/label': 'Stato',
	'FrequentSearch/Status/DRAFT/label': 'In Bozza',
	'FrequentSearch/Status/PUBLISHED/label': 'Pubblicata',
	'FrequentSearch/Status/HIGHLIGHTED/label': 'In Evidenza',
	'FrequentSearch/Status/DELETED/label': 'Eliminata',
	'FrequentSearch/User/filter/label': 'Utente',
	'FrequentSearch/Team/filter/label': 'Team',
	'FrequentSearch/EditorialSection/filter/label': 'Sezione Editoriale',
	'FREQUENTSEARCH/USER/LABEL': 'Utente',
	'FrequentSearch/nameIta/label': 'Nome Italiano',
	'FrequentSearch/Team/label': 'Team',
	'CompanyLevel/HIDDEN': 'Nascosto',
	'CompanyLevel/FREE': 'Presente',
	'CompanyLevel/PAID_L1': 'Export Pro',
	'CompanyLevel/PAID_L2': 'Maestro',
	'download contract': 'Scarica il contratto',
	'go to company profile': 'Vai al profilo aziendale',
	'go to goods list': ' Vai alla lista dei prodotti',
	'insert candidacies': 'Nuova candidatura',
	'Checklist for': 'Prerequisiti per candidare in',
	Checklist: 'Prerequisiti',
	checklist: 'Prerequisiti',
	'checklist step has signed contract title': 'Accetta il contratto',
	'checklist step company completed html':
		'Se ci sono informazioni mancanti “Completa anagrafica”, se hai compilato con successo il profilo prosegui al punto 2. Potrai comunque controllare e aggiornare il profilo quando vorrai dal menu a sinistra.',
	'checklist step company completed ok':
		'Hai compilato con successo il profilo aziendale',
	'checklist step has goods with ean title':
		'Crea almeno un prodotto. Aggiungi uno o più EAN nei suoi formati di vendita',
	'checklist step has goods title': 'Crea i tuoi prodotti', // CHECK
	'checklist step has goods with ean ok': ' Hai già inserito prodotti con EAN',
	'checklist step has goods ok': 'Hai già inserito prodotti',
	'checklist step has candidacies title': 'Candida i tuoi prodotti',
	'checklist step has candidacies html':
		'Clicca sul bottone di creazione. Nel popup scegli il prodotto e compila i campi specifici per la manifestazione.',
	'check failure no candidacies': 'Non hai ancora candidato nessun prodotto',
	'For any info write us at':
		'Se hai bisogno di supporto per la tua candidatura scrivici a:',
	'no brand present': 'Non hai inserito nessun Brand',
	'company description empty':
		"La descrizione breve in inglese dell'azienda non è stata inserita",
	'company description ita empty':
		"La descrizione breve in italiano dell'azienda non è stata inserita",
	'company description long empty':
		"La descrizione accurata in inglese dell'azienda non è stata inserita",
	'company description ita long empty':
		"La descrizione accurata in italiano dell'azienda non è stata inserita",
	'no logoMedia on company': 'Logo non inserito',
	'no companyCertifications on company':
		'Attenzione: non hai inserito nessuna certificazione',
	'no logoPrintMedia on company':
		'Attenzione: non hai inserito il logo ad alta risoluzione per la stampa',
	'no goods in company': 'Attenzione: non hai caricato nessun prodotto',
	'no goods with EAN':
		'Attenzione: non hai caricato nessun prodotto con codice EAN',
	'contract not signed': 'Attenzione: non hai caricato il contratto firmato',
	'checklist step has goods with ean html':
		'<p>Guarda quali prodotti sono gi&agrave; a sistema o creane di nuovi. Una volta deciso i due prodotti che vorrai candidare potrai aggiungere e modificare i loro codici EAN cliccando su modifica.</p><p><u>Nota bene</u>: i formati di vendita / EAN collegati a un prodotto devono condivedere tra loro: Nome, Ingredienti e Data di Lancio.</p>',
	'checklist step has goods html':
		'Controlla di aver inserito prodotti sulla piattaforma',
	'checklist step has signed contract html':
		'Scarica il contratto e invialo firmato dal legale rappresentante',
	'checklist step has signed contract ok':
		'Contratto firmato e inviato correttamente',
	'checklist step company completed title':
		'Controlla i dati e completa il tuo profilo aziendale',

	'days left to candidate': 'Giorni alla chiusura',
	participate: 'Partecipa',
	'insert highlighted goods': 'Metti prodotti in evidenza',
	'Checklist for IFN Platform':
		'Prerequisiti per pubblicare sulla piattaforma italianfood.net',
	'IFN Platform': 'Piattaforma italianfood.net',
	'platform checklist step company completed title':
		'Completa il tuo profilo aziendale',
	'platform checklist step company completed html':
		'Verifica i dati precompilati e aggiungi il logo aziendale. Se la tua azienda ha partecipato ad altre manifestazioni Gruppo Food troverai i campi già impostati, ma potrebbero mancare le descrizioni in italiano e inglese. Controlla che siano correttamente inseriti.',
	'platform checklist step company completed ok':
		'Hai compilato con successo il profilo aziendale',
	'platform checklist step has goods title': 'Crea i tuoi prodotti',
	'platform checklist step has goods html':
		'Controlla di avere almeno un prodotto inserito in HUB, completo di loghi e descrizioni',
	'platform checklist step has goods ok': 'Hai già inserito dei prodotti',
	'platform checklist step has highlighted title':
		'Metti in evidenza alcuni prodotti',
	'platform checklist step has highlighted html':
		'Vai nella tab "prodotti in evidenza" e scegli quali prodotti pubblicare sulla piattaforma. Ricorda che, a seconda della ' +
		'fascia di pagamento, potrai mettere più o meno prodotti in evidenza.',
	'platform check failure no highlighted goods':
		'Nessun prodotto messo in evidenza',
	'platform for any info write us at':
		'Per qualsiasi informazione contattaci a',
	'platform checklist step has highlighted ok':
		'Hai già messo in evidenza alcuni prodotti',
	'add your company': 'Aggiungi la tua azienda',
	'Check your status for the award':
		'Controlla la tua situazione per la manifestazione',
	'Go to checklist': 'Vai ai prerequisiti',
	Shortcuts: 'Collegamenti rapidi',
	'checklist step has candidacies ok': 'Hai già candidato dei prodotti',
	'sidebar award title': 'Collegamenti rapidi',
	'Download Contract in PDF': 'Scarica il contratto in PDF',
	'Download Regluation in PDF': 'Scarica il regolamento in PDF',
	'Read and sign the contract': 'Scarica, leggi e firma il contratto',
	'Check the award rules': 'Leggi il regolamento della manifestazione',
	'support email label':
		'Invia il contratto firmato o chiedi supporto tecnico a',
	'Branch/name/label': 'Nome',
	'Branch/type/label': 'Tipologia',
	'Branch/lat/helpText': '',
	'Branch/lng/helpText': '',
	'Branch/postalCode/helpText': '',
	'Branch/Country/label': 'Nazione',
	'Branch/StateProvince/helpText': '',
	'Branch/address2/helpText': '',
	'Branch/address1/helpText': '',
	'Branch/city/helpText': '',
	'Branch/type/helpText': '',
	PREMIUM: 'Premium',
	MAINSTREAM: 'Mainstream',
	LOGISTIC_DEPARTMENT: 'Magazzino',
	'BranchType/MANUFACTURING_PLANT/label': 'Sito di produzione',
	'BranchType/PO_BOX/label': 'PO_BOX',
	'BranchType/LOGISTIC_DEPARTMENT/label': 'Magazzino',
	'BranchType/SALES_DEPARTMENT/label': 'Filiale commerciale',
	'Branch/Country/helpText': '',
	'DistributionChannel/labelPlural': 'Canali di export',
	'Company/localId/label': 'Local ID',
	'Company/localId/helpText': '',
	'Company/sfId/helpText': 'Salesforce ID',
	'Company/highlightedGoods/label': 'Prodotti in evidenza',
	'Company/highlightedGoods/helpText': '',
	'Company/branches/label': 'Sedi',
	'DistributionChannel/countries/label': 'Nazioni',
	'DistributionChannel/countries/helpText':
		'Indicare le nazioni in cui è attiva la distribuzione',
	'DistributionChannel/mode/label': 'Modalità',
	'DistributionChannel/mode/helpText':
		'Selezionare le modalità di distribuzione utilizzata',
	'DistributionMode/DIRECT/label': 'Diretta',
	'DistributionMode/DISTRIBUTOR/label': 'Tramite Distributore',
	'DistributionMode/LOCAL_BRANCH/label': 'Sede locale',
	'DistributionMode/BROKER/label': 'Tramite Broker',
	DIRECT: 'Diretta',
	DISTRIBUTOR: 'Tramite Distributore',
	LOCAL_BRANCH: 'Sede locale',
	BROKER: 'Tramite Broker',
	'DistributionChannel/contactEmail/label': 'Contatto Email',
	'DistributionChannel/contactEmail/helpText':
		"Indicare un indirizzo email dell'export manager o del responsabile di distribuzione dell'area",
	'CompanyLevelEnum/HIDDEN/label': 'Nascosta',
	'CompanyLevelEnum/PAID_L1/label': 'Export Pro',
	'CompanyLevelEnum/PAID_L2/label': 'Maestro',
	'TargetSite/FOODSERVICEWEB/label': 'FOODSERVICEWEB',
	'AwardInvitedCompany/signedContract/label': 'Contratto firmato',
	'AwardInvitedCompany/signedContract/helpText':
		'Controllo di firma e ricezione del contratto',
	'Capability/EDIT/label': 'Modifica',
	'Capability/ACCOUNT/label': 'Gestione utenti',
	'DistributionChannel/Company/label': 'Azienda',
	'DistributionChannel/channels/label': 'Canali',
	'DistributionChannel/channels/helpText':
		'Seleziona almeno un canale di distribuzione',
	'Channel/RETAIL/label': 'Retail',
	'Channel/FOODSERVICE/label': 'FoodService',
	'Channel/GOURMET_SHOPS/label': 'Gourmet Shops',
	'Channel/WHOLESALER/label': 'Wholesaler',
	'DistributionChannel/catalogMedia/label': 'Catalogo',
	'DistributionChannel/catalogMedia/helpText':
		"Caricare il PDF del catalogo specifico per l'area",
	'DistributionChannel/note/label': 'Note',
	'DistributionChannel/note/helpText':
		'Specificare eventuali partner commerciali in loco, brand specifici a cui si riferisce il catalogo, altro...',
	DistributionChannel: 'Canale di export',
	'DistributionChannel/localId/label': 'Local ID',
	'Download image - "large" size': 'Scarica media - formato "large"',
	'Download image - original size': 'Scarica media - formato originale',
	'create distribution channel intro':
		'Raggruppa in ciascuno di questi oggetti tutte le nazioni in cui esporti con la stessa modalità e negli stessi canali.<br /> Se negli U.S.A. hai modalità diverse per diversi stati crea più oggetti inserendo il nome degli stati nel campo "note".',
	'Good/additionalImagesMedia/label': 'Media aggiuntivi mercato internazionale',
	'Good/additionalImagesItaMedia/label': 'Media aggiuntivi mercato italiano',
	'media caption': 'Didascalia',
	'media links': 'Formati',
	'media format small': 'piccolo',
	'media format medium': 'medio',
	'media format large': 'grande',
	'media format original': 'originale',
	'add media': 'Aggiungi media',
	'Media/name/label': 'Didascalia',
	'Media/name/helpText': '',
	'Company/additionalImagesMedia/label': 'Gallery',
	'Company/medias/label': 'Gallery',
	'Candidacy/additionalImagesMedia/label': 'Gallery',
	'Candidacy/medias/label': 'Gallery',
	'Award/options/label': 'Opzioni',
	'report not allowed title': 'Accesso Negato',
	'report not allowed text':
		'Non hai i permessi sufficienti per accedere a questo report.',
	Undefined: 'Non impostato',
	'FrequentSearch/plProducer/helpText': '',
	'Fair/NewsCitation/label': 'Notizie correlate',
	'User/status/set': 'Imposta Stato',
	'see checklist before candidate html':
		'Assicurati di avere i prerequisiti per candidare alla manifestazione prima di procedere!',
	'go to checklist': 'Vai ai prerequisiti',
	'consumerOnlyEmail error':
		"Questo dominio email è di un provider pubblico. Non possiamo quindi dedurre e garantire la tua appartenenza ad un'azienda del settore. Per favore usa un dominio di proprietà aziendale oppure contattaci",
	'Company/Award/filter/label': 'Invitata alla manifestazione',
	'Capability/PURCHASE/label': 'Acquisti',
	'Capability/MANAGE/label': 'Gestione',
	'User/Team/helpText': '',
	'CompanyGroupType/CONSORZIO_TUTELA/label': 'Consorzio tutela',
	'CompanyGroupType/CONSORZIO_PRIVATO/label': 'Consorzio privato',
	'GoodClass/labelPlural': 'Categorie merceologiche',
	'GoodClass/Brand/filter/label': 'Brand',
	'GoodClass/name/filter/label': 'Nome',
	'GoodClass/Company/filter/label': 'Azienda',
	'GoodClass/localId/label': 'Local Id',
	'GoodClass/name/label': 'Nome Inglese',
	'GoodClass/nameIta/label':'Nome Italiano',
	'GoodClass/akas/label': 'Alias/AKAs',
	'GoodClass/level1Code/label': 'GPC - Liv. 1 - Codice',
	'GoodClass/level1Desc/label': 'GPC - Liv. 1 - Nome Inglese',
	'GoodClass/level1DescIta/label': 'GPC - Liv. 1 - Nome Italiano',
	'GoodClass/level2Code/label': 'GPC - Liv. 2 - Codice',
	'GoodClass/level2Desc/label': 'GPC - Liv. 2 - Nome Inglese',
	'GoodClass/level2DescIta/label': 'GPC - Liv. 2 - Nome Italiano',
	'GoodClass/level3Code/label': 'GPC - Liv. 3 - Codice',
	'GoodClass/level3Desc/label': 'GPC - Liv. 3 - Nome Inglese',
	'GoodClass/level3DescIta/label': 'GPC - Liv. 3 - Nome Italiano',
	'GoodClass/level4Code/label': 'GPC - Liv. 4 - Codice',
	'GoodClass/level4Desc/label': 'GPC - Liv. 4 - Nome Inglese',
	'GoodClass/level4DescIta/label': 'GPC - Liv. 4 - Nome Italiano',
	'GoodClass/shortname/label': 'Nome Abbreviato inglese',
	'GoodClass/shortnameIta/label': 'Nome Abbreviato italiano',
	'GoodClass/editorialSections/label': 'Sezioni editoriali in cui è inclusa',
	'CompanyGroup/Country/filter/label': 'Nazione',
	'Award/descriptions/label': 'Descrizioni',
	'Award/candidacies fields/label': 'Campi candidature',
	'Award/rating options/label': 'Opzioni voto',
	'AwardCategory/slug/label': 'Slug',
	'AwardCategory/slug/helpText':
		'Il testo che verrà usato negli url per identificare la categoria',
	'AwardCategory/special/label': 'Speciale?',
	'AwardCategory/special/helpText':
		'Scegliere "si" nel caso si tratti di una categoria di voto speciale',
	'Candidacy/localId/label': 'Local Id',
	'AwardVisibility/VOTE/label': 'VOTE',
	'AwardAutovoterRole/BUYER_PL_ITALIA/label': 'Retailer Italiano',
	'Award/regulationsMedia/label': 'Regolamento della manifestazione',
	'Award/regulationsMedia/helpText': 'Caricare il pdf con il regolamento esteso della manifestazione',
	'Award/supportEmail/label': 'Email di supporto',
	'Award/supportEmail/helptext': 'indirizzo email che apparirà nella schermata di candidatura',
	'Award/contractMedia/label': 'Contratto per la manifestazione',
	'Award/contractMedia/helptext': 'Caricare il pdf da firmare in caso di condizioni necessarie per partecipare',
	'Award/extraMediaType/label': 'Tipologia media aggiuntivo',
	'Award/extraMediaType/helptext': 'Per certe manifestazioni può essere necessario inserire altri tipi di immagini oltre alla foto della candidatura',
	'Award/extraMediaLabel/label': 'Nome media aggiuntivo',
	'Award/extraMediaLabel/helptext': 'Dicitura che leggerà chi candida',
	
	'FinalPosition/SECOND/label': 'Secondo',
	'FinalPosition/THIRD/label': 'Terzo',
	'AwardResult/legacyCandidacyName/helpText': '',
	'AwardResult/legacyCandidacyName/label': 'Nome candidatura legacy',
	'AwardResult/legacyCompanyName/helpText': '',
	'AwardResult/legacyCompanyName/label': 'Nome azienda legacy',
	'AwardResult/localId/label': 'Local Id',
	'AwardOptionFlag/EAN_REQUIRED/label': 'Richiesto EAN per candidare',
	'AwardOptionFlag/LAUNCHDATE_REQUIRED/label': 'Data di lancio richiesta',
	'Waiting review explanation html':
		'<p>Il tuo utente è stato creato e la mail verificata.</p><p>Per garantire pieno accesso alla piattaforma è comunque necessario attendere la creazione del profilo aziendale da parte nostra. Verrai notificato di avvenuta creazione o rifiuto tramite email al termine del processo.</p>',
	'Waiting Review': 'Email verificata',
	'alreadyUsedEmail error':
		'Esiste già un account utente associato a questo indirizzo email.<br />Se sei il proprietario di questo indirizzo <a href="/login?traditional=true">accedi usando le tue credenziali</a> oppure <a href="mailto:$SUPPORT_EMAIL">contattaci</a>.',
	Registration: 'Registrazione',
	'Registration intro':
		"Questo sito è riservato agli operatori dell'Industria Food & Beverage. Nel processo di registrazione ti chiederemo di inserire la tua email aziendale, attraverso quella il nostro staff verificherà la tua appartenenza alla società e ti garantirà l'accesso.",
	'Role PRODUCER': "Sono un operatore dell'Industria Food & Beverage",
	'AwardAutovoterRole/PRODUCER/label': 'Produttore',
	'EditorialSection/color/label': 'Colore',
	'Newsletter/name/label': 'Nome',
	'Newsletter/name/helpText': '',
	'Newsletter/target/label': 'Sito di riferimento',
	'Newsletter/target/helpText': '',
	'Newsletter/subject/label': 'Subject',
	'Newsletter/subject/helpText': '',
	'Newsletter/langCode/label': 'Codice lingua',
	'Newsletter/langCode/helpText': 'en_US oppure it_IT',
	'Newsletter/logoMedia/label': 'Logo',
	'Newsletter/logoMedia/helpText': '',
	'Newsletter/slogan/label': 'Slogan',
	'Newsletter/slogan/helpText': '',
	'Newsletter/maxNews/label': 'Numero massimo di news',
	'Newsletter/maxNews/helpText': '',
	'Newsletter/styleAdditionalClasses/label': 'Classe CSS aggiuntiva',
	'Newsletter/styleAdditionalClasses/helpText': '',
	'Newsletter/inputTemplate/label': 'Template HTML',
	'Newsletter/inputTemplate/helpText': '',
	'Newsletter/Delivery/Label': 'Software di Invio',
	'Newsletter/values/label': 'Valori oggetto contenuto',
	'Newsletter/fontTitles/label': 'Font dei Titoli',
	'Newsletter/fontBody/label': 'Font del body',
	'Newsletter/backgroundColor/label': 'Colore di sfondo',
	'Newsletter/logo/label': 'Logo',
	'Newsletter/logo/helpText': 'Sovrascrivi il logo di default se necessario',
	'Newsletter/prefooter/label': 'Prefooter',
	'Newsletter/footerHtml/label':'Codice HTML del Footer',
	'InputTemplate/NEWSLETTER_PRODUCTS/label': 'Newsletter Prodotti',
	'DeliveryService/PARDOT/Label': 'Pardot',
	'DeliveryService/MAILCHIMP/Label': 'Mailchimp',
	'DeliveryService/MAILUP/Label': 'MailUp',
	//-----
	'Newsletter/labelPlural': 'Tipologia Newsletter',
	'NewsletterIssue/labelPlural': 'Issue Newsletter',
	'NewsletterIssue/name/filter/label': 'Oggetto',
	'NewsletterIssue/statuses/filter/label': 'Stato',
	'MagazineCollection/labelPlural': 'Tipologia Magazine',
	'Magazine/labelPlural': 'Issue Magazine',
	Reports: 'Report',
	'Typeform/labelPlural': 'Typeform',
	'Typeform/name/filter/label': 'Nome',
	'Typeform/isOpen/filter/label': 'È aperto?',
	'Typeform/hasOpenResults/filter/label': 'Ha risultati aperti?',
	'Typeform/targetSite/filter/label': 'Sito di riferimento',
	'TargetSite/NEWSLETTER/label': 'Newsletter',
	'Typeform/name/label': 'Nome',
	'Typeform/name/helpText': '',
	Newsletter: 'Tipologia Newsletter',
	'NewsletterIssue/Newsletter/filter/label': 'Tipologia Newsletter',
	'NewsletterIssue/subject/filter/label': 'Nome',
	'NewsletterIssue/Newsletter/label': 'Tipologia',
	'NewsletterIssue/Newsletter/helpText':
		'La tipologia di Newsletter a cui appartiene questo invio',
	'NewsletterIssue/subject/label': 'Nome interno',
	'NewsletterIssue/subject/helpText':
		'Il nome che identificherà questo invio di Newsletter',
	'NewsletterIssue/status/label': 'Stato',
	'NewsletterIssue/status/helpText': '',
	'NewsletterIssue/publicationDate/label': 'Data di pubblicazione',
	'NewsletterIssue/publicationDate/helpText': '',
	'NewsletterIssue/logoMedia/label': 'Media per logo',
	'NewsletterIssue/logoMedia/helpText': '',
	'NewsletterIssue/slogan/label': 'Testo introduttivo nascosto',
	'NewsletterIssue/slogan/helpText':
		"Testo nascosto, non mostrato nella grafica, utilizzato per la preview dell'email",
	'NewsletterIssue/styleAdditionalClasses/label': 'Classi CSS aggiuntive',
	'NewsletterIssue/styleAdditionalClasses/helpText': '',
	NewsletterIssue: 'Issue Newsletter',
	'MagazineCollection/name/label': 'Nome',
	'MagazineCollection/BrandSf/label': 'Brand Salesforce',
	'MagazineCollection/BrandSf/helpText': "L'id del brand su SalesForce",
	'Abbonamento__c_Rivista__c_Enum/Food/label': 'Food',
	'Abbonamento__c_Rivista__c_Enum/Dolcesalato/label': 'Dolcesalato',
	'Abbonamento__c_Rivista__c_Enum/Bar_Business/label': 'Bar Business',
	'Abbonamento__c_Rivista__c_Enum/ITALIANFOOD_NET/label': 'Italianfood.net',
	'Abbonamento__c_Rivista__c_Enum/Food_Service/label': 'Food Service',
	'MagazineCollection/name/helpText': '',
	'MagazineCollection/featuredImageMedia/label': 'Immagine di anteprima',
	'MagazineCollection/featuredImageMedia/helpText': '',
	'MagazineCollection/description/label': 'Descrizione',
	'MagazineCollection/description/helpText': '',
	'MagazineCollection/startYear/label': 'Anno di inizio pubblicazione',
	'MagazineCollection/startYear/helpText': '',
	'MagazineCollection/highlightDays/label': 'Giorni in evidenza',
	'MagazineCollection/highlightDays/helpText': '',
	'MagazineCollection/visibilityType/label': 'Tipo visiblità',
	'MagazineCollection/visibilityType/helpText': '',
	'VisibilityType/ANONYMUS/label': 'Accesso anonimo',
	'VisibilityType/USER/label': 'Login richiesto',
	'VisibilityType/TYPEFORM/label': 'Compilazione form richiesta',
	'VisibilityType/SF_ITEM/label': undefined,
	'VisibilityType/SF_CAMPAIGN/label': undefined,
	'VisibilityType/SF_CAMPAIGN_PRESENTE/label': undefined,
	'MagazineCollection/visibilityArgs/label': 'Parametri visibilità',
	'MagazineCollection/visibilityArgs/helpText': '',
	'MagazineCollection/priority/label': 'Priorità',
	'MagazineCollection/priority/helpText': '',
	'MagazineCollection/notInLatestIssues/label': 'Non mostrare fra le ultime uscite',
	'MagazineCollection/notInLatestIssues/helpText':
		'Se `Si` i numeri di questa tipologia non compariranno fra la `Ultime sucite` in edicola',
	'MagazineCollection/hasCategory/label': 'I numeri sono divisi per categoria?',
	'MagazineCollection/hasCategory/helpText':
		'Se `Si` i numeri di questa tipologia dovranno avere valorizzato il campo testuale `Categoria`',
	MagazineCollection: 'Tipologia di Magazine',
	'Magazine/BrandSf/filter/label': 'Brand Salesforce',
	'Magazine/MagazineCollection/filter/label': 'Tipologia di Magazine',
	'Magazine/name/filter/label': 'Nome',
	'Magazine/title/filter/label': 'Titolo',
	'Magazine/BrandSf/filter': 'Brand',
	'Magazine/category/filter/label': 'Categoria',
	'Magazine/isPublished/filter/label': 'È pubblicato?',
	'Magazine/publishedAfter/filter/label': 'Pubblicato dopo il',
	'Magazine/publishedBefore/filter/label': 'Pubblicato prima del',
	'Magazine/name/label': 'Nome',
	'Magazine/name/helpText': '',
	'Magazine/MagazineCollection/label': 'Tipologia',
	'Magazine/MagazineCollection/helpText': '',
	'Magazine/category/label': 'Categoria',
	'Magazine/category/helpText':
		'Valorizzare solo se previsto dalla Tipologia di Magazine',
	'Magazine/publicationDate/label': 'Data di pubblicazione',
	'Magazine/publicationDate/helpText': '',
	'Magazine/title/label': 'Titolo',
	'Magazine/title/helpText': '',
	'Magazine/customHighlightDays/label': 'Giorni in evidenza',
	'Magazine/customHighlightDays/helpText': '',
	'Magazine/issueNumber/label': 'Numero issue',
	'Magazine/issueNumber/helpText': '',
	'Magazine/numOfTheYear/label': "Issue nell'anno",
	'Magazine/numOfTheYear/helpText': '',
	'Magazine/numPages/label': 'Numero di pagine',
	'Magazine/numPages/helpText': '',
	'Magazine/prefixPages/label': 'Numero pagine battente',
	'Magazine/prefixPages/helpText': '',
	'Magazine/customVisibilityType/label': 'Tipo visiblità',
	'Magazine/customVisibilityType/helpText': '',
	'Magazine/customVisibilityArgs/label': 'Parametri visiblità',
	'Magazine/customVisibilityArgs/helpText': '',
	'Magazine/fullUrl/label': 'URL Completo',
	'Magazine/fullUrl/helpText': '',
	'Magazine/previewUrl/label': 'URL Anteprima',
	'Magazine/previewUrl/helpText': '',
	'Magazine/dropboxPath/label': 'Path Dropbox',
	'Magazine/dropboxPath/helpText': '',
	'Magazine/Media/label': 'PDF',
	'Magazine/Media/helpText': '',
	'Magazine/previewMedia/label': 'PDF anteprima',
	'Magazine/previewMedia/helpText': '',
	'Magazine/featuredImageMedia/label': 'Immagine in evidenza',
	'Magazine/featuredImageMedia/helpText': '',
	'Magazine/magazineContents/label': 'Contenuti',
	'Magazine/banner/label': 'Banner',
	'Magazine/localId/label': 'Local ID',
	'Magazine/localId/helpText': '',
	'Magazine/slug/label': 'Slug',
	'Magazine/slug/helpText': '',
	'Magazine/source/label': 'Sorgente',
	'Magazine/source/helpText': '',
	'NewsSource/HUB/label': 'HUB',
	'NewsSource/WP_IFN/label': 'Wordpress Italianfood.net',
	'NewsSource/WP_FOODWEB/label': 'Wordpress foodweb.it',
	'NewsSource/WP_FOODSERVICEWEB/label': 'Wordpress foodserviceweb.it',
	'NewsSource/PAPER_IFN/label': 'Cartaceo',
	'Magazine/externalSourceId/label': 'Id sorgente esterna',
	'Magazine/externalSourceId/helpText': '',
	'Magazine/featuredImageUrl/label': 'URL immagine in evidenza',
	'Magazine/featuredImageUrl/helpText': '',
	'Magazine/featuredImageSizes/label': 'Dimensioni immagine in evidenza',
	'Magazine/featuredImageSizes/helpText': '',
	'Magazine/magazineContents/helpText': 'Contenuti',
	'Magazine/banners/label': 'Banners',
	'Magazine/banners/helpText': '',
	'MagazineContent/name/label': 'Nome',
	'MagazineContent/name/helpText': '',
	'MagazineContent/pageNumber/label': 'Pagina Num',
	'MagazineContent/pageNumber/helpText': 'Numero di Pagina del Cartaceo, non del pdf',
	'MagazineContent/labelPlural': 'Contenuti',
	'MagazineCollection/localId/label': 'Local ID',
	'NewsletterIssue/values/label': 'Valori oggetto contenuto',
	"If your company doesn't have a private email domain":
		'Se la tua azienda non ha un proprio dominio email',
	'Thanks for signing in': 'Grazie per esserti autenticato',
	// 'Temporary consumer explanation': '',
	// 'Sign In': '',
	' Social Account': 'Collega un account Social',
	'switch to social explanation title':
		'Stiamo dismettendo gli accessi tramite password',
	'switch to social explanation html': `Collega un tuo account social e d'ora potrai accedere con un click senza dover tenere a mente le credenziali.`,
	'Associate a social account to your user':
		'Scegli un account social da associare',
	'Maybe later': 'Ricordamelo più tardi',
	'generic error':
		'Si è verificato un problema<br />Riprova fra qualche istante',
	'Authentication error': 'Errore di autenticazione',
	'If the problem persists': 'Se il problema persiste',
	'remember switch to social title': 'Account Social collegato',
	'remember social account html':
		'Ricorda che puoi accedere senza digitare email e password utilizzando il tuo account social.',
	'social login type associated': 'Account social collegato',
	proceed: 'Continua',
	'upgrade to Producer': 'Sono uno di loro',
	'buyer welcome title': 'Accesso non consentito',
	'buyer welcome html 1':
		"Siamo spiacenti, l'accesso ad HUB è riservato ai professionisti dell'industria alimentare.",
	'buyer welcome html 2':
		'Se ti occupi di <strong>distribuzione</strong>, <strong>food service</strong>, <strong>ho.re.ca.</strong> o <strong>servizi</strong> forse ti possono interessare:<ul>' +
		'<li><a href="https://www.foodweb.it">foodweb.it</a></li>' +
		'<li><a href="https://www.italianfood.net">italianfood.net</a></li>' +
		'<li><a href="https://www.foodserviceweb.it">foodserviceweb.it</a></li>' +
		'<li><a href="https://www.dolcesalato.com">dolcesalato.com</a></li></ul>',
	logout: 'Accedi con un altro account',
	'consumer welcome title': 'Accesso riservato',
	'consumer welcome html 1':
		"L'accesso ad HUB è riservato ai professionisti dell'industria alimentare",
	'consumer welcome html 2':
		'Se invece ti occupi di <strong>distribuzione</strong>, <strong>servizi</strong> o sei un semplice <strong>appassionato</strong> forse ti possono interessare:<ul><li><a href="https://www.foodweb.it">Foodweb.it</a></li><li><a href="https://www.italianfood.net">ItalianFOOD.net</a></li><li><a href="https://www.foodserviceweb.it">Foodserviceweb.it</a></li></ul>',
	"if you're not": 'Se non sei',
	'change account': 'cambia account',
	"if you think there's a problem with your account":
		'Se pensi ci sia un problema con il tuo account',
	'The activation link is expired':
		'Il codice di attivazione risulta scaduto o già utilizzato',
	'Choose your company': 'Seleziona la tua azienda',
	'Your email domain is associated with more than a company, please confirm which you work for:':
		'Il tuo dominio email è associato a più aziende; seleziona quella a cui appartieni fra quelle in elenco:',
	'If your branch is not one of the options, please select your headquarter and then let us know by writing to our support email:':
		'Se la tua azienda non è fra le opzioni seleziona la sede principale e successivamente scrivi al nostro supporto',
	Confirm: 'Conferma',
	'update you company': 'Mostra la tua azienda e i tuoi prodotti',
	'no raster allowed error explanation':
		"L'immagine dovrebbe essere completamente vettoriale, invece contiene {{=it.err.data.actual}} elementi raster.",
	'show newsletter preview': 'Anteprima',
	'no media message': 'Nessun media attualmente presente',
	'Company/headquarterBranch/label': 'Sede operativa',
	'Company/headquarterBranch/helpText':
		"Indicare l'indirizzo della sede operativa",
	'/NETWORK/error/explication':
		'Errore di connessione - controllare il proprio collegamento a internet e riprovare',
	'ObjectHistory/Company/label': 'Azienda',
	'Good/HighlightedGood/label': 'In evidenza',
	'Company/vatNumber/filter/label': 'Partita IVA',
	'Text length': 'Lunghezza testo',
	chars: 'caratteri',
	'User/socialType/label': 'Social utilizzato',
	'Award/managerUser/label': 'Gestore',
	'Award/managerUser/helptext': 'Creatore / Manager della manifestazione. Per le Purchasing Requests può essere un buyer, per le altre un utente interno',
	'Company/geoIndications/label': 'Indicazioni geografiche',
	'Company/geoIndications/helpText': '',
	'Media/file/label': 'File',
	mainTitle: 'Titolo introduzione Newsletter',
	mainBody: 'Testo introduzione Newsletter',
	mainMedia: 'Immagine introduzione Newsletter',
	bannerMedia: 'Immagine leaderboard',
	bannerLink: 'URL esterno leaderboard',
	bannerOnTop: 'Leaderboard ad inizio Newsletter',
	sectionTitle: 'Titolo elenco elementi',
	seeMoreType: 'Tipologia barra ricerca',
	showIFNCard: 'Visualizzare blocco IFN',
	mainLink: 'Link introduzione',
	seeMoreLink: 'Link barra ricerca',
	seeMoreText: 'Testo barra ricerca',
	BlockSubFormList: 'Blocchi elementi',
	'add block': 'Aggiungi blocco',
	'block type': 'Tipologia blocco',
	'subFormType/GOOD': 'Prodotto',
	'subFormType/COMPANY': 'Azienda',
	'see More Link helpText':
		'Link a cui si verrà mandati cliccando sulla finta barra di ricerca',
	'see More Text helpText':
		"Testo presente all'interno della finta barra di ricerca",
	'show ifn card helptext':
		'Se impostato a vero mostra un blocco, alla fine della lista di blocchi, con un rimando ad IFN',
	'main title helpText': "Il titolo dell'introduzione alla Newsletter",
	'see More Type helpText':
		'Tipologia selezionata nella finta barra di ricerca (azienda o prodotto)',
	'ObjectHistoryAction/MERGE/label': 'Unione Oggetti',
	'ObjectHistoryCollection/Abbonamento/label': 'Abbonamento',
	'ObjectHistoryCollection/AbbonamentoPeriodo/label': 'Periodo Abbonamento',
	'ObjectHistoryCollection/CampaignMember/label': 'Partecipazione a Campagna',
	'ObjectHistoryCollection/CompanyBadge/label': 'Badge Azienda',
	'ObjectHistoryCollection/Contact/label': 'Contatto',
	'ObjectHistoryCollection/DistributionChannel/label':'Canale di distribuzione',
	'ObjectHistoryCollection/Indirizzo/label': 'Indirizzo',
	'ObjectHistoryCollection/MagazineContent/label': 'Contenuto Rivista',
	'ObjectHistoryCollection/PiList/label': 'Lista di accesso',
	'ObjectHistoryCollection/PricebookEntry/label': 'Pricebook',
	'ObjectHistoryCollection/Prospect/label': 'Prospect',
	'ObjectHistoryCollection/Quote/label': 'Preventivo',
	'ObjectHistoryCollection/QuoteLineItem/label': 'Voce preventivo',
	'ObjectHistoryCollection/SampleRequest/label': 'Sample Request',
	'ObjectHistoryCollection/Typeform/label': 'Typeform',
	'ObjectHistoryCollection/UserAchievement/label': 'User Achievement',
	'UserRole/ANONYMUS/label': 'Anonimo',
	'newsletterIssue/bannerMedia/helpText':
		'Immagine JPG o PNG con 600 di larghezza; altezza libera',
	'FairVisit/localId/label': 'Local ID',
	seeMoreOnTop: 'Mostrare barra sopra i blocchi',
	'view card preview': 'Preview card IFN',
	'wordpress news url': 'URL News Wordpress',
	'subFormType/NEWS': 'News Wordpress Food',
	'wordpress news view layout': 'Layout',
	'wordpressNewsLayout/expanded': 'Espansa',
	'wordpressNewsLayout/collapsed_left': 'Collassata con immagine a sinistra',
	'wordpressNewsLayout/collapsed_right': 'Collassata con immagine a destra',
	'subFormBanner/media': 'Media',
	'subFormBanner/altText': 'Testo alternativo',
	'subFormMainImage/media': 'Media',
	'subFormMainImage/altText': 'Testo alternativo',
	'subFormRichText/text': 'Contenuto',
	'subFormTitle/title': 'Titolo',
	'subFormTitle/backgroundColor': 'Colore di sfondo',
	'subFormTitle/textColor': 'Colore del testo',
	'Candidature di un premio - dati generali': '',
	'Export per grafici - Aziende presenti ad uno showcase': '',
	'Export per grafici - Prodotti presenti ad uno showcase': '',
	'Aziende e stato rispetto alla piattaforma': '',
	'CSV per showcase': '',
	// 'Developer tools': '',
	// 'schema-voyager': '',
	'Company/additionalImagesMedia/helpText': '',
	'Non impostato': '',
	'CompanyCertification/name/filter/label': 'Nome',
	'CompanyCertification/name/label': 'Nome',
	'CompanyCertification/name/helpText': '',
	'CompanyCertification/akas/label': 'AKAS',
	'CompanyCertification/akas/helpText': '',
	'Abbonamento__c_Rivista__c_Enum/FOOD/label': 'Abbonamento a FOOD',
	'Abbonamento__c_Rivista__c_Enum/DOLCESALATO/label': 'Abbonamento a Dolcesalato',
	'Abbonamento__c_Rivista__c_Enum/FOOD_SERVICE/label': 'Abbonamento a Food Service',
	'subFormType/BANNER': 'Banner Leaderboard',
	'subFormType/MAIN_IMAGE': 'Immagine',
	'subFormType/RICH_TEXT': 'Blocco di testo',
	'subFormType/TITLE': 'Titolo grafico',
	'color field placeholder':
		'Inserisci un colore in formato esadecimale o scegline uno fra quelli sottostanti',
	'subFormTitle/media': 'Immagine',
	'Service/name/filter/label': 'Nome',
	'Service/producerChangedAfter/filter/label': 'Modificato dopo il',
	'Service/types/filter/label': 'Tipologia',
	'ServiceType/ATTREZZATURE_FOOD_SERVICE/label': 'Attrezzature food service',
	'ServiceType/ATTREZZATURE_RETAIL/label': 'Attrezzature retail',
	'ServiceType/CENTRO_MEDIA/label': 'Centro media',
	'ServiceType/CONSULENZE_FORMAZIONE_HR/label': 'Consulenze e formazione',
	'ServiceType/ENERGIA/label': 'Energia',
	'ServiceType/FIERE/label': 'Fiere',
	'ServiceType/MARKETING_PROMOZ/label': 'Marketing',
	'ServiceType/REAL_ESTATE/label': 'Real estate',
	'ServiceType/RICERCHE_DI_MERCATO/label': 'Ricerche di mercato',
	'ServiceType/SCUOLE_E_UNIVERSITA/label': 'Scuole e università',
	'ServiceType/SERVIZI_FINANZIARI/label': 'Servizi finanziari',
	'ServiceType/SERVIZI_INFORMATICI/label': 'Servizi informatici',
	'ServiceType/SERVIZI_LEGALI/label': 'Servizi legali',
	'ServiceType/SERVIZI_LOGISTICI/label': 'Servizi logistici',
	'ServiceType/TELECOMUNICAZIONI/label': 'Telecomunicazioni',
	'Service/Country/filter/label': 'Paese',
	'Service/name/label': 'Nome',
	'Service/name/helpText': '',
	'Service/type/label': 'Tipologia',
	'Service/type/helpText': '',
	ATTREZZATURE_FOOD_SERVICE: 'Attrezzature food service',
	ATTREZZATURE_RETAIL: 'Attrezzature retail',
	CENTRO_MEDIA: 'Centro media',
	CONSULENZE_FORMAZIONE_HR: 'Consulenze e formazione',
	ENERGIA: 'Energia',
	FIERE: 'Fiere',
	MARKETING_PROMOZ: 'Marketing',
	REAL_ESTATE: 'Real estate',
	RICERCHE_DI_MERCATO: 'Ricerche di mercato',
	SCUOLE_E_UNIVERSITA: 'Scuole e università',
	SERVIZI_FINANZIARI: 'Servizi finanziari',
	SERVIZI_INFORMATICI: 'Servizi informatici',
	SERVIZI_LEGALI: 'Servizi legali',
	SERVIZI_LOGISTICI: 'Servizi logistici',
	TELECOMUNICAZIONI: 'Telecomunicazioni',
	'Service/managedByUsers/label': 'Utenti',
	'Service/newsCitations/label': 'Citazioni',
	'Service/localId/label': 'Local ID',
	'Service/localId/helpText': '',
	'Service/sfId/label': 'Salesforce ID',
	'Service/sfId/helpText': '',
	'Service/logoMedia/label': 'Immagine logo',
	'Service/logoMedia/helpText': '',
	'Service/billingName/label': 'Ragione Sociale',
	'Service/billingName/helpText': '',
	'Service/description/label': 'Descrizione',
	'Service/description/helpText': '',
	'Service/descriptionIta/label': 'Descrizione in italiano',
	'Service/descriptionIta/helpText': '',
	'Service/descriptionLong/label': 'Descrizione lunga',
	'Service/descriptionLong/helpText': '',
	'Service/descriptionLongIta/label': 'Descrizione lunga in italiano',
	'Service/descriptionLongIta/helpText': '',
	'Service/pecEmail/label': 'PEC',
	'Service/pecEmail/helpText': '',
	'Service/vatNumber/label': 'Partita IVA',
	'Service/vatNumber/helpText': '',
	'Service/website/label': 'Sito internet',
	'Service/website/helpText': '',
	'Service/Country/label': 'Nazione',
	'Service/Country/helpText': '',
	'Service/StateProvince/label': 'Provincia',
	'Service/StateProvince/helpText': '',
	'Service/postalCode/label': 'Codice Postale',
	'Service/postalCode/helpText': '',
	'Service/city/label': 'Città',
	'Service/city/helpText': '',
	'Service/address1/label': 'Indirizzo 1',
	'Service/address1/helpText': '',
	'Service/address2/label': 'Indirizzo 2',
	'Service/address2/helpText': '',
	'Service/lat/label': 'Latitudine',
	'Service/lat/helpText': '',
	'Service/lng/label': 'Longitudine',
	'Service/lng/helpText': '',
	'Service/managedByUsers/helpText': '',
	'Service/newsCitations/helpText': '',
	Italy: '',
	Perugia: '',
	'NewsCitation/count/label': 'Numero citazioni',
	'NewsCitation/count/helpText': '',
	'GoodFeatureCertification/name/filter/label': 'Nome',
	'GoodFeatureCertification/name/helpText': '',
	// ---___---___---___
	'subFormBanner/linkUrl': 'URL link',
	'subFormMainImage/caption': 'Didascalia',
	'subFormMainImage/linkUrl': 'URL link',
	'subFormMainImage/widthType': 'Larghezza immagine',
	'subFormMainImage/in-grid': 'Nella griglia (600)',
	'subFormMainImage/fullwidth': 'A tutta larghezza',
	'in-grid': 'Nella griglia (600)',
	'fullwidth': 'A tutta larghezza',
	
	'subFormTitle/text': 'Testo',
	'subFormTitle/url': 'URL Link',
	'subFormTitleNoImage/text': 'Testo',
	'subFormTitleNoImage/layout': 'Layout',
	'subFormTitleNoImage/backgroundColor': 'Colore sfondo',
	'subFormTitleNoImage/textColor': 'Colore testo',
	'subFormTitleNoImage/url': 'URL link',
	'subFormImageText/media': 'Media',
	'subFormImageText/text': 'Testo',
	'subFormImageText/linkUrl': 'URL link',
	'subFormImageText/valignment': 'Allineamento verticale',
	top: 'In alto',
	middle: 'Centrato',
	bottom: 'In basso',
	left: 'Immagine a sinistra',
	right: 'Immagine a destra',
	'subFormImageText/alignment': 'Allineamento',
	'half proportion': '50 e 50',
	small_image: 'Immagine piccola laterale',
	'subFormImageText/proportion': 'Proporzioni',
	'subFormLink/linkUrl': 'URL link',
	'subFormLink/text': 'Testo',
	'subFormInstantAction/media': 'Media',
	'subFormInstantAction/text': 'Testo',
	'subFormInstantAction/backgroundColor': 'Colore sfondo',
	'subFormInstantAction/textColor': 'Colore testo',
	'subFormEntities/layout': 'Layout',
	'add card': 'Aggiunti entità',
	'subFormEntities/entities': 'Elenco Prodotti/Aziende',
	'subFormType/CANDIDACY': 'Candidatura',
	'subFormType/IFN_CARD': 'Blocco Card IFN',
	'subFormTypeformPreview/url': 'URL link',
	'subFormIfnCard/url': 'URL link',
	'add news': 'Aggiungi News',
	'subFormNewsList/news': 'News',
	subFormEditorialSections: '',
	'FrequentSearch/name/helpText': 'Il nome della ricerca/lista',
	'FrequentSearch/collection/helpText':
		'Il tipo di entità contenute da questa ricerca/lista',
	'FrequentSearch/targetSites/helpText':
		'Il sito per cui questa ricerca è stata creata',
	'FrequentSearch/relatedFair/label': 'Fiera associata',
	'FrequentSearch/relatedFair/helpText':
		'Eventuale fiera associata alla lista',
	'FrequentSearch/nameIta/helpText':
		'Nome in italiano, utile per uso interno e produttori',
	'FrequentSearch/goods/label': 'Prodotti',
	'FrequentSearch/goods/helpText': 'I prodotti inclusi nella lista',
	'FrequentSearch/companies/label': 'Aziende',
	'FrequentSearch/companies/helpText': 'Le aziende incluse nella lista',
	'FrequentSearch/logoMedia/label': 'Media',
	'FrequentSearch/logoMedia/helpText':
		'Immagine opzionale associata alla lista, utile per le card IFN',
	'FrequentSearch/linkUrl/label': 'URL link',
	'FrequentSearch/linkUrl/helpText': 'Link opzionale da associale alla lista',
	'FrequentSearch/linkRoles/label': 'Ruoli per Link',
	'FrequentSearch/linkRoles/helpText':
		"La lista dei ruoli che possono accedere all'eventuale link - default anonimo",
	'AwardAutovoterRole/ANONYMUS/label': 'Anonimi',
	'AwardAutovoterRole/INTERNAL/label': 'Interni',
	'AwardAutovoterRole/BUYER/label': 'Retailer',
	'AwardAutovoterRole/CONSUMER/label': 'Consumatori',
	'FrequentSearch/description/label': 'Descrizione',
	'FrequentSearch/description/helpText': '',
	FrequentSearch: 'Ricerca frequente',
	'subFormType/NEWS_LIST': 'Lista di News',
	'subFormType/TYPEFORM_PREVIEW': 'Anteprima Typeform',
	'subFormType/ENTITIES': 'Elenco di Prodotti/Aziende',
	'subFormType/TITLE_NO_IMAGE': 'Titolo testuale',
	'subFormType/INSTANT_ACTION': 'Instant Action',
	'subFormType/IFN_SEARCH_BAR': 'Barra di ricerca stile IFN',
	'subFormType/LINK': 'Link',
	'subFormType/IMAGE_TEXT': 'Testo con immagine',
	'subFormLink/secondLine': 'Testo seconda riga',
	'add link': 'Aggiungi Link',
	'subFormLinkList/urls': 'Link',
	'subFormType/LINK_LIST': 'Lista di Link',
	'NewsletterIssue/localId/label': 'Local Id',
	'NewsletterIssue/localId/helpText': '',
	'NewsletterIssue/values/helpText': '',
	'NewsletterIssue/label': 'Issue Newsletter',
	STUB: 'Bozza',
	WAITING_PUBLICATION: 'In attesa di pubblicazione',
	PUBLISHED: 'Pubblicata',
	HIDDEN: 'Nascosta',
	DELETED: 'Cestinata',
	'Award/status/STUB/helpText': '',
	'Award/status/WAITING_PUBLICATION/helpText': '',
	'Award/status/PUBLISHED/helpText': '',
	'Award/status/HIDDEN/helpText': '',
	'Award/status/DELETED/helpText': '',
	'WRONG_PATTERN/text': 'Formato sbagliato',
	'add static frequentSearch': 'Aggiungi lista statica',
	'FrequentSearch/targetSite/filter/label': 'Sito di riferimento',
	'FrequentSearch/statuses/filter/label': 'Stato',
	'FrequentSearchStatus/DRAFT/label': 'Bozza',
	'FrequentSearchStatus/PUBLISHED/label': 'Pubblicata',
	'FrequentSearchStatus/HIGHLIGHTED/label': 'In evidenza',
	'FrequentSearchStatus/DELETED/label': 'Cestinata',
	'FrequentSearch/noUser/filter/label': 'Di sistema',
	'FrequentSearch/relatedFair/filter/label': 'Fiera collegata',
	'FrequentSearch/User/label': 'Utente',
	'FrequentSearch/User/helpText': "L'utente associato a questa ricerca/lista",
	COMPANY: 'Azienda',
	GOOD: 'Prodotto',
	'Newsletter/localId/label': 'Local ID',
	'Newsletter/localId/helpText': '',
	'add IFN newsletter issue': 'Crea newsletter IFN',
	'subFormNewsList/layout': 'Layout',
	'subFormType/NEWS_WITH_LAYOUT': 'News singola',
	CREATE: 'Creazione',
	PATCH: 'Modifica',
	SUBCREATE: 'Creazione automatica',
	SET_STATUS: 'Impostazione stato',
	DELETE: 'Eliminazione',
	WRONG_BLOCK_NUMBER: 'Numero di blocchi non coerente con layout scelto',
	main_title: 'Titolo principale',
	new_section: 'Nuova sezione',
	internal: 'Titolo paragrafo',
	'3_col': '3 colonne',
	'4_col': '4 colonne - responsive',
	'50_50_left_image': '50 e 50 con immagine a sinistra',
	'50_50_alternate': '50 e 50 con immagine alternata',
	News: 'News',
	it_IT: 'Italiano',
	en_US: 'Inglese',
	'Not valid news url': 'URL non valido',
	'complete company data': 'Completa anagrafica',
	'missing company requirements': 'Informazioni mancanti',
	'add good action': 'Crea nuovo prodotto',
	'add brand action': 'Crea nuovo brand',
	'EMPTY_NOT_ALLOWED/richtext': 'Campo richiesto',
	'Good/hasEan/label': 'EAN inserito',
	'candidacies number': 'Candidature',
	'create/edit': 'Crea/Modifica',
	GoodFormat: 'Formato di vendita',
	'Brand/DISCOUNT/label': 'Discount',
	'Brand/MAINSTREAM/label': 'Mainstream',
	'Brand/PREMIUM/label': 'Premium',
	'Brand/LUXURY/label': 'Luxury',
	'not signed contract message':
		'L’iscrizione sarà completa quando la segreteria avrà depositato il vostro contratto firmato.',
	'signed contract message':
		'Il contratto firmato è depositato. Siete iscritti alla manifestazione!',
	'no goods message': 'Nessun prodotto presente',
	'no goods for candidacies message':
		'Prima di poter candidare devi creare o completare i prodotti al punto 2',
	'company not completed message VOTE':
		'Prima di poter candidare devi completare il tuo profilo aziendale al punto 1',
	'company not completed message SHOWCASE':
		'Per poter procedere con la selezione dei prodotti da presentare allo Showcase devi prima completare il tuo profilo aziendale al punto 1',
	'company not completed message SOURCING':
		'Per poter procedere con la selezione dei prodotti da presentare al Sourcing devi prima completare il tuo profilo aziendale al punto 1',
	'no goods for candidacies message showcase':
		'Per poter procedere con la selezione dei prodotti da presentare allo showcase devi prima create prodotti al punto 2',
	'no candidacies message': '', // Intenzionalmente vuoto
	'no more candidacies allowed VOTE':
		'Complimenti, hai candidato con successo i tuoi prodotti!\nSe cambi idea su quali prodotti candidare, elimina la candidatura e ricreala.',
	'no more candidacies allowed SHOWCASE':
		'Complimenti, hai scelto correttamente i prodotti da presentare allo Showcase. Clicca su "dettagli" per visualizzare le informazioni inserite o su "modifica" per rivederle. \n' +
		'Se cambi idea sui prodotti scelti, eliminali e scegline di nuovi.',
	'no more candidacies allowed SOURCING':
		'Complimenti, hai scelto correttamente i prodotti da presentare al Sourcing. Clicca su "dettagli" per visualizzare le informazioni inserite o su "modifica" per rivederle. \n' +
		'Se cambi idea sui prodotti scelti, eliminali e scegline di nuovi.',
	'GoodFormat/data/label': 'Formato',
	MILLILITER: 'Millilitri',
	'associated eans': 'Ean associati',
	'Brand/goodClasses/label': 'Categorie merceologiche',
	// ==========
	'wordpressNewsLayout/no_main_image': 'Espanza senza immagine',
	'wordpressNewsLayout/no_intro_text': 'Espansa senza introduzione testuale',
	'subFormTitleNoImage/subtitle': 'Sottotitolo',
	'subFormTitleNoImage/subtitleColor': 'Colore sottotitolo',
	'subFormInstantAction/url': 'Link',
	'subFormInstantAction/icon': 'Icona',
	compact: 'Compatta',
	compact_no_image: 'Compatta senza immagine',
	'subFormSponsor/title': 'Titolo',
	'subFormSponsor/name': 'Nome',
	'subFormSponsor/media': 'Media',
	'subFormSponsor/url': 'Link',
	'subFormSponsor/titles': 'Titoli sponsor',
	'subFormSectionTags/target': 'Directory di destinazione',
	INTERNAL: 'Interno',
	'NewsletterIssue/name/label': 'Oggetto',
	'NewsletterIssue/name/helpText': 'Oggetto della newsletter',
	'InputTemplate/NEWSLETTER_GENERIC/label': 'Newsletter generica',
	FOODWEB: 'Food Web',
	NEWSLETTER_GENERIC: 'Newsletter generica',
	'Newsletter/headerMedia/label': 'Immagine testata',
	'Newsletter/primaryColor/label': 'Colore primario',
	'Newsletter/link/label': 'Link',
	'Newsletter/gradientColor1/label': 'Colore iniziale gradiente',
	'Newsletter/gradientColor2/label': 'Colore finale gradiente',
	'Newsletter/gradientDegree/label': 'Grado rotazione gradiente',
	'NewsletterIssue/hiddenIntro/label': 'Testo introduzione newsletter',
	'NewsletterIssue/hiddenIntro/helpText':
		'Il testo che viene visualizzato nella preview della mail del client di posta',
	'subFormType/SPONSOR': 'Blocco Sponsor',
	'subFormType/AUTHOR': 'Blocco autore',
	'subFormType/SECTION_TAGS': 'Tag Sezioni editoriali',
	'NewsletterIssue/headerMediaOverride/label': 'Immagine testata',
	'NewsletterIssue/primaryColorOverride/label': 'Colore primario',
	'NewsletterIssue/linkOverride/label': 'Link',
	'wordpressNewsLayout/no_image_no_text': 'News senza immagine né incipit',
	'Newsletter/headerMediaOverride/helpText':
		"Se impostato, sovrascrive l'immagine di testata ereditata dalla Tipologia Newsletter",
	'Newsletter/primaryColorOverride/helpText':
		'Se impostato, sovrascrive il colore principale ereditato dalla Tipologia Newsletter',
	'Newsletter/linkOverride/helpText':
		'Se impostato, sovrascrive il link a cui manda la testata impostato nella Tipologia Newsletter',
	'NewsletterBrand/IFN/Label': 'Italianfood.net',
	'NewsletterBrand/FOOD/Label': 'Food',
	'NewsletterBrand/FOODSERVICE/Label': 'Food Service',
	'NewsletterBrand/DOLCESALATO/Label': 'Dolcesalato',
	'NewsletterBrand/CLIENT_ITA/Label': 'Cliente Italiano',
	'NewsletterBrand/CLIENT_ENG/Label': 'Cliente Inglese',
	'mediaModal/filter/name': 'Cerca',
	'mediaModal/filter/Company': 'Filtra per azienda',
	'3_col_unboxed': '3 colonne unboxed',
	'4_col_unboxed': '4 colonne responsive unboxed',
	compact_plain_image: 'Compatta semplice',
	compact_plain_no_image: 'Compatta semplice senza immagine',
	simil_news: 'Compatta',
	'subFormLaunchesList/layout': 'Layout',
	'subFormLaunchesList/launches': 'Lanci',
	'subFormType/LAUNCH': 'Lancio di prodotto',
	'add launch': 'Aggiungi lancio',
	'wordpress launch url': 'URL lancio wordpress',
	Award: '',
	'subFormType/LAUNCH_LIST': 'Lista di lanci prodotto',
	'subFormNewsWithLayout/media/label': 'Immagine principale',
	'subFormNewsWithLayout/media/helpText':
		"Se impostata, sovrascrive l'immagine di default della News",
	'subFormNews/media/label': 'Immagine principale',
	'subFormNews/media/helpText':
		"Se impostata, sovrascrive l'immagine di default della News",
	'mediaUpload/file': 'File',
	'mediaUpload/caption': 'Didascalia',
	'checklist VOTE step company completed title':
		'Controlla i dati e completa il tuo profilo aziendale',
	'checklist VOTE step has candidacies title': 'Candida i tuoi prodotti',
	'checklist VOTE step company completed html':
		'Se ci sono informazioni mancanti “Completa anagrafica”, se hai compilato con successo il profilo prosegui al punto 2. Potrai comunque controllare e aggiornare il profilo quando vorrai dal menu a sinistra.',
	'checklist SHOWCASE step company completed title':
		'Controlla i dati e completa il tuo profilo aziendale',
	'checklist SHOWCASE step has candidacies title':
		'Scegli i prodotti da presentare allo Showcase',
	'checklist SHOWCASE step company completed html':
		'Se ci sono informazioni mancanti “Completa anagrafica”, se hai compilato con successo il profilo prosegui al punto 2. Potrai comunque controllare e aggiornare il profilo quando vorrai dal menu a sinistra.',
	'checklist SOURCING step company completed title':
		'Controlla i dati e completa il tuo profilo aziendale',
	'checklist SOURCING step has candidacies title':
		'Scegli i prodotti da presentare al Sourcing',
	'checklist SOURCING step company completed html':
		'Se ci sono informazioni mancanti “Completa anagrafica”, se hai compilato con successo il profilo prosegui al punto 2. Potrai comunque controllare e aggiornare il profilo quando vorrai dal menu a sinistra.',
	'select single product for VOTE': 'Selezionare il prodotto da candidare',
	'select single product for SHOWCASE':
		'Scegliere il prodotto fra quelli disponibili del brand selezionato',
	'select single product for SOURCING': 'Selezionare il prodotto da candidare',
	'name will be derived from good name VOTE':
		'La candidatura avrà il nome del prodotto selezionato',
	'name will be derived from good name SHOWCASE':
		'Inserire il nome del prodotto in inglese che verrà visualizzato nella scheda prodotto.',
	'name will be derived from good name SOURCING':
		'Inserire il nome del prodotto in inglese che verrà visualizzato nella scheda prodotto.',
	'Candidacy clone title VOTE': "Clona candidatura da un'altra manifestazione",
	'Candidacy clone title SHOWCASE': "Clona candidatura da un'altro showcase",
	'Candidacy clone title SOURCING': "Clona da un'altro sourcing",
	'add candidacy action VOTE': 'Crea nuova candidatura',
	'add candidacy action SHOWCASE': 'Scegli un prodotto',
	'add candidacy action SOURCING': 'Scegli un prodotto',
	'review company data': 'Visualizza il profilo',
	'media original name': 'Nome file',

	// ===========
	'alreadyPresent/label': "Azienda già presente sul mercato dell'evento?",
	'alreadyPresent/helpText':
		"Specificare se l'azienda opera già sul mercato dell'evento. Se sì, attraverso quali canali",
	'contacts/label': 'Contatti',
	'contacts/helpText':
		"Indicare l'indirizzo email dell'export manager o del responsabile di distribuzione dell'area",
	'faqs/label': 'FAQS',
	'subFormNewsletterFAQ/question': 'Inserire una domanda',
	'subFormNewsletterFAQ/answer': 'Inserire una risposta',
	'subFormNewsletterFAQList/faqs':
		"Inserire almeno 5 FAQ in inglese relative ai prodotti o all'azienda. Prestare attenzione alla compilazione poiché verranno inserite all'interno dei dossier consegnati ai buyers.",
	'subFormType/NEWSLETTER_FAQ': '',
	'add faq': 'Aggiungi nuova FAQ',
	'checklist VOTE step company award invited custom form':
		'Inserisci le ultime informazioni mancanti',
	'checklist SHOWCASE step company award invited custom form':
		'Inserisci le FAQ e le ultime informazioni mancanti',
	'checklist SOURCING step company award invited custom form':
		'Inserisci le ultime informazioni mancanti',
	'not completed invite data description html':
		'Completa i campi con le informazioni richieste',
	'not completed invite data and FAQ description html':
		'Inserisci 5 FAQ (domande frequenti), scritte in INGLESE relative ai vostri prodotti o alla vostra azienda. Completa poi gli altri campi con le informazioni richieste',
	'completed invite data description html':
		'Hai inserito correttamente tutte le informazioni',
	'completed invite data but missing FAQ description html':
		'Completa la sezione inserendo 5 FAQ (domande frequenti), scritte in INGLESE relative ai vostri prodotti o alla vostra azienda. Ad esempio:<br />' +
		'1. In which format is the product available?<br />2. What other varieties are available?<br />3. Where the production process takes place?',
	'complete invite data': 'Inserisci le informazioni',
	'edit awardInvite custom data':
		'Completa il form con le informazioni mancanti',
	'internationalChannels/label': 'Tipo di presenza sul mercato',
	'internationalChannels/helpText':
		'Specificare la modalità diretta (export diretto, filiale in loco) o indiretta (tramite distributore, broker..) e specificare il canale di distribuzione (retail, food service, grossista...)',
	'internationalPartners/label': 'Principali partner commerciali',
	'internationalPartners/helpText VOTE':
		'Specificare i principali partner commerciali a livello globale',
	'internationalPartners/helpText SHOWCASE':
		"Specificare i principali partner commerciali a livello globale o relativi dell'area in cui si terrà lo Showcase",
	'internationalPartners/helpText SOURCING':
		'Specificare i principali partner commerciali a livello globale e nel paese di riferimento',
	'progress status': 'Status caricamento informazioni',
	'candidacy progress not completed text VOTE':
		'Completa le informazioni necessarie per candidare i tuoi prodotti',
	'candidacy progress not completed text SHOWCASE':
		'Completa le informazioni necessarie per partecipare allo Showcase ',
	'candidacy progress not completed text SOURCING':
		'Completa le informazioni necessarie per partecipare a Sourcing ',
	'see point': 'Inserisci informazioni al',
	'company last year empty':
		"Completa il campo relativo ai dati dell'ultimo anno",
	'company last year empty description':
		'Clicca su "Completa anagrafica" e inserisci le informazioni richieste all\'interno della sezione "Dati annuali"',
	'no logoMedia on company description':
		'Clicca su "Completa anagrafica" e inserisci il logo',
	'company description empty description':
		'Clicca su "Completa anagrafica" e inserisci una breve descrizione in inglese',
	'company description ita empty description':
		'Clicca su "Completa anagrafica" e inserisci una breve descrizione in inglese',
	'company description long empty description':
		'Clicca su "Completa anagrafica" e inserisci una descrizione accurata in inglese',
	'review invite data': 'Completa la sezione',
	'candidacy progress completed text VOTE':
		'Hai inserito correttamente tutte le informazioni',
	'candidacy progress completed text SHOWCASE':
		'Hai inserito correttamente tutte le informazioni',
	'candidacy progress completed text SOURCING':
		'Hai inserito correttamente tutte le informazioni',
	'still space for candidacies message VOTE':
		'Hai spazio per altre candidature',
	'still space for candidacies message SHOWCASE': 'Scegli un altro prodotto',
	'still space for candidacies message SOURCING': 'Scegli un altro prodotto',
	'checklist showcase no more candidacies allowed':
		'Complimenti, hai scelto correttamente i prodotti da presentare allo showcase. Clicca su "dettagli" per visualizzare le informazioni inserite, o su "modifica" per rivederle. \n' +
		'Se cambi idea sui prodotti scelti, eliminali e scegline di nuovi.',
	'goods number': 'Status',
	'faqs/EMPTY_NOT_ALLOWED/text': 'FAQ non inserite',
	check: 'punto',
	'QuestionSet/sentDate/label': 'Data di invio',
	'QuestionSet/sentDate/helpText': '',
	'QuestionSet/User/label': 'Utente',
	'QuestionSet/User/helpText': '',
	'QuestionSet/AnswerSet/label': 'Risposta',
	'QuestionSet/localId/label': 'Local ID',
	'QuestionSet/localId/helpText': '',
	'QuestionSet/targetSite/label': 'Sito di riferimento',
	'QuestionSet/targetSite/helpText': '',
	'QuestionSet/question1/label': 'Domanda',
	'QuestionSet/question1/helpText': '',
	'QuestionSet/answerSets/label': 'Risposte',
	'QuestionSet/answerSets/helpText': '',
	'QuestionSet/requests/label': 'Richieste',
	'QuestionSetRequest/CATALOG/label': 'Catalogo',
	'QuestionSetRequest/PRICE/label': 'Prezzi',
	'QuestionSetRequest/RESELLERS/label': 'Punti vendita',
	'QuestionSetRequest/CONTACT/label': 'Contatti',
	QuestionSet: 'Gruppo di domande',
	CATALOG: 'Catalogo',
	PRICE: 'Prezzi',
	RESELLERS: 'Punti vendita',
	CONTACT: 'Contatti',
	'AnswerSet/Company/helpText': '',
	'AnswerSet/sentDate/helpText': '',
	small: 'Piccolo',
	medium: 'Medio',
	big: 'Grande',
	huge: 'Enorme',
	boxed: 'Con bordi',
	unboxed: 'Senza bordi',
	'FrequentSearch/collection/filter': 'Tipologia',
	'FrequentSearch/targetSite/filter': 'Sito di riferimento',
	'FrequentSearch/statuses/filter': 'Stato',
	'FrequentSearch/status/set': 'Imposta Stato',
	'clone candidacy SHOWCASE': 'Clona da altro Showcase',
	'clone candidacy SOURCING': 'Clona da altro Sourcing',
	question_confirm_leaving_unsaved_changes:
		'Alcune modifiche non sono salvate e verranno perse. Continuare?',
	'QuestionSet/User/filter/label': 'Utente',
	'QuestionSet/Company/filter/label': 'Azienda',
	'Good/ingredientsImageMedia/label': 'Lista ingredienti - lingua inglese',
	'Good/ingredientsImageMedia/helpText':
		'Una immagine con la lista degli ingredienti in lingua italiana, presa dalla confezione del prodotto',
	'Good/ingredientsImageItaMedia/label': 'Lista ingredienti - lingua italiana',
	'Good/ingredientsImageItaMedia/helpText':
		'Una immagine con la lista degli ingredienti in lingua inglese, presa dalla confezione del prodotto',
	'AwardOptionFlag/EXTRAMEDIA_REQUIRED/label': 'Media aggiuntivo obbligatorio',
	'AwardOptionFlag/COMPANY_LOGOPRINT_REQUIRED/label': 'Logo azienda per stampa obbligatorio',
	'AwardOptionFlag/SIGNED_CONTRACT_REQUIRED/label': 'Contratto formato obbligatorio',
	'AwardOptionFlag/GALLERY_ENABLED/label': 'Gallery abilitata',
	'AwardOptionFlag/INGREDIENTS_IMAGE_REQUIRED/label': 'Immagine ingredienti obbligatoria',
	'Ingredients image': 'Ingredienti',
	'Coupon/labelPlural': 'Coupon',
	'Coupon/name/filter/label': 'Nome',
	'Coupon/name/label': 'Nome',
	'Coupon/StartDate/label': 'Data di Inizio',
	'Coupon/ExpirationDate/label': 'Data di Scadenza',
	'Coupon/code/filter/label': 'Codice',
	'Coupon/isActive/filter/label': 'Attivo',
	'Coupon/localId/label': 'Local Id',
	'Coupon/code/label': 'Codice',
	'Coupon/amount/label': '% di Sconto',
	'Coupon/maxUsages/label': 'Num Max di utilizzi',
	'Coupon/maxUsagesPerQuote/label': 'Num Max di utilizzi per Transazione',
	'Coupon/usagesCount/label': 'Num di utilizzi',
	'Coupon/brandSf/label': 'Brand',
	'Coupon/product2Sf/label': 'Prodotto SF',
	'Coupon/brandAreaSf/label': 'Brand Area',
	'FoodService/labelPlural': 'Food Service',
	'FoodService/managedByUsers/label': 'Utenti',
	'FoodService/localId/label': 'LocalId',
	'FoodService/localId/helpText': '',
	'FoodService/name/label': 'Nome',
	'FoodService/name/helpText': '',
	'FoodService/vatNumber/label': 'Partita IVA',
	'FoodService/vatNumber/helpText': '',
	'FoodService/type/label': 'Tipologia',
	'FoodService/type/helpText': '',
	'FoodService/AGRITURISMO/label': 'Agriturismo',
	'FoodService/ASPORTO/label': 'Asporto',
	'FoodService/B_B/label': 'Bed&Breakfast',
	'FoodService/BAR_TRADIZIONALE/label': 'Bar tradizionale',
	'FoodService/CAMPING/label': 'Camping',
	'FoodService/CATERING/label': 'Catering',
	'FoodService/CINEMA_MULTISALA/label': 'Cinema multisala',
	'FoodService/DISCOTECA/label': 'Discoteca',
	'FoodService/ENOTECHE/label': 'Enoteca',
	'FoodService/FARMACIA/label': 'Farmacia',
	'FoodService/FAST_FOOD/label': 'Fast food',
	'FoodService/FOOD_TRUCK/label': 'Food truck',
	'FoodService/FRANCHISER/label': 'Franchiser',
	'FoodService/GELATERIA/label': 'Gelateria',
	'FoodService/HOTEL/label': 'Hotel',
	'FoodService/IMPIANTI_SPORTIVI/label': 'Impianti sportivi',
	'FoodService/ISTITUZIONE_CULTURALE/label': 'Istituzione culturale',
	'FoodService/LATTERIA/label': 'Latteria',
	'FoodService/LIBRERIA/label': 'Libreria',
	'FoodService/LOCALE_POLIFUNZ/label': 'Locale polufunzionale',
	'FoodService/MACELLERIA/label': 'Macelleria',
	'FoodService/MENSE/label': 'Mensa',
	'FoodService/OSTELLI_HOTEL_2_STELLE/label': 'Ostello o Hotel 2 stelle',
	'FoodService/PANETTERIA/label': 'Panetteria',
	'FoodService/PARCHI_DI_DIVERTIMENTO/label': 'Parco divertimenti',
	'FoodService/PASTICCERIA/label': 'Pasticceria',
	'FoodService/PESCHERIA/label': 'Pescheria',
	'FoodService/POLLERIA/label': 'Polleria',
	'FoodService/PUB_BIRRERIA/label': 'Pub birreria',
	'FoodService/RESORT_VILLAGGIO_TURISTICO/label': 'Resort - Villaggio turistico',
	'FoodService/RIFUGI_DI_MONTAGNA/label': 'Rifugio di montagna',
	'FoodService/RIST_PIZZERIA/label': 'Ristorante pizzeria',
	'FoodService/RIST_ETNICO/label': 'Ristorante etnico',
	'FoodService/RIST_PREMIUM/label': 'Ristorante premium',
	'FoodService/RISTORAZIONE_COLLETTIVA/label': 'Ristorazione collettiva',
	'FoodService/RISTORAZIONE_COMMERCIALE/label': 'Ristorazione commerciale',
	'FoodService/RISTORAZIONE_SU_MEZZI/label': 'Ristoriazione su mezzi',
	'FoodService/SALE_SCOMMESSE/label': 'Sala scommesse',
	'FoodService/SALUMERIA/label': 'Salumeria',
	'FoodService/STABILIMENTO_BALNEARE/label': 'Stabilimento balneare',
	'FoodService/STAZIONE_DI_SERVIZIO/label': 'Stazione di servizio',
	'FoodService/TABACCHERIA/label': 'Tabaccheria',
	'FoodService/TEATRO_SALA_DA_CONCERTO/label': 'Teatro - Sala da concerto',
	'FoodService/TORREFAZIONE/label': 'Torrefazione',
	'FoodService/TRATTORIA_OSTERIA/label': 'Trattoria - Osteria',
	'FoodService/slug/label': 'Slug',
	'FoodService/slug/helpText': '',
	'FoodService/logoMedia/label': 'Logo',
	'FoodService/logoMedia/helpText': '',
	'FoodService/billingName/label': 'Ragione fiscale',
	'FoodService/billingName/helpText': '',
	'FoodService/description/label': 'Descrizione in inglese',
	'FoodService/description/helpText': '',
	'FoodService/descriptionIta/label': 'Descrizione in italiano',
	'FoodService/descriptionIta/helpText': '',
	'FoodService/descriptionLong/label': 'Descrizione accurata in inglese',
	'FoodService/descriptionLong/helpText': '',
	'FoodService/descriptionLongIta/label': 'Descrizione accurata in italiano',
	'FoodService/descriptionLongIta/helpText': '',
	'FoodService/pecEmail/label': 'PEC',
	'FoodService/pecEmail/helpText': '',
	'FoodService/website/label': 'Sito Internet',
	'FoodService/website/helpText': '',
	'FoodService/Country/label': 'Nazionalità',
	'FoodService/Country/helpText': '',
	'FoodService/StateProvince/label': 'Provincia',
	'FoodService/StateProvince/helpText': '',
	'FoodService/postalCode/label': 'CAP',
	'FoodService/postalCode/helpText': '',
	'FoodService/city/label': 'Città',
	'FoodService/city/helpText': '',
	'FoodService/address1/label': 'Indirizzo primario',
	'FoodService/address1/helpText': '',
	'FoodService/address2/label': 'Indirizzo secondario',
	'FoodService/address2/helpText': '',
	'FoodService/lat/label': 'Latitudine',
	'FoodService/lat/helpText': '',
	'FoodService/lng/label': 'Longitudine',
	'FoodService/lng/helpText': '',
	'FoodService/managedByUsers/helpText': '',
	ASPORTO: 'Asporto',
	FoodService: 'Food Service',
	'ObjectHistoryCollection/Coupon/label': 'Coupon',
	'ObjectHistoryCollection/FoodService/label': 'Food Service',
	'UserRole/FOODSERVICE/label': 'Food Service',
	'UserRole/SHOPWORKER/label': 'Shop Worker',
	'UserRole/SERVICE/label': 'Service',
	'ObjectHistory/actions/filter': 'Azione',
	'ObjectHistory/collections/filter': 'Entità',
	'ObjectHistory/userRoles/filter': 'Ruolo utente',
	'FoodService/name/filter/label': 'Nome',
	'FoodService/Dimension/Label': 'Dimensione',
	'FoodService/producerChangedAfter/filter/label': 'Ultima modifica',
	'FoodService/types/filter/label': 'Tipologia',
	'FoodServiceType/AGRITURISMO/label': 'Agriturismo',
	'FoodServiceType/ASPORTO/label': 'Asporto',
	'FoodServiceType/B_B/label': 'Bed&Breakfast',
	'FoodServiceType/BAR_TRADIZIONALE/label': 'Bar tradizionale',
	'FoodServiceType/CAMPING/label': 'Camping',
	'FoodServiceType/CATERING/label': 'Catering',
	'FoodServiceType/CINEMA_MULTISALA/label': 'Cinema multisala',
	'FoodServiceType/DISCOTECA/label': 'Discoteca',
	'FoodServiceType/ENOTECHE/label': 'Enoteca',
	'FoodServiceType/FARMACIA/label': 'Farmacia',
	'FoodServiceType/FAST_FOOD/label': 'Fast food',
	'FoodServiceType/FOOD_TRUCK/label': 'Food truck',
	'FoodServiceType/FRANCHISER/label': 'Franchiser',
	'FoodServiceType/GELATERIA/label': 'Gelateria',
	'FoodServiceType/HOTEL/label': 'Hotel',
	'FoodServiceType/IMPIANTI_SPORTIVI/label': 'Impianti sportivi',
	'FoodServiceType/ISTITUZIONE_CULTURALE/label': 'Istituzione culturale',
	'FoodServiceType/LATTERIA/label': 'Latteria',
	'FoodServiceType/LIBRERIA/label': 'Libreria',
	'FoodServiceType/LOCALE_POLIFUNZ/label': 'Locale polufunzionale',
	'FoodServiceType/MACELLERIA/label': 'Macelleria',
	'FoodServiceType/MENSE/label': 'Mensa',
	'FoodServiceType/OSTELLI_HOTEL_2_STELLE/label': 'Ostello o Hotel 2 stelle',
	'FoodServiceType/PANETTERIA/label': 'Panetteria',
	'FoodServiceType/PARCHI_DI_DIVERTIMENTO/label': 'Parco divertimenti',
	'FoodServiceType/PASTICCERIA/label': 'Pasticceria',
	'FoodServiceType/PESCHERIA/label': 'Pescheria',
	'FoodServiceType/POLLERIA/label': 'Polleria',
	'FoodServiceType/PUB_BIRRERIA/label': 'Pub birreria',
	'FoodServiceType/RESORT_VILLAGGIO_TURISTICO/label': 'Resort - Villaggio turistico',
	'FoodServiceType/RIFUGI_DI_MONTAGNA/label': 'Rifugio di montagna',
	'FoodServiceType/RIST_PIZZERIA/label': 'Ristorante pizzeria',
	'FoodServiceType/RIST_ETNICO/label': 'Ristorante etnico',
	'FoodServiceType/RIST_PREMIUM/label': 'Ristorante premium',
	'FoodServiceType/RISTORAZIONE_COLLETTIVA/label': 'Ristorazione collettiva',
	'FoodServiceType/RISTORAZIONE_COMMERCIALE/label': 'Ristorazione commerciale',
	'FoodServiceType/RISTORAZIONE_SU_MEZZI/label': 'Ristoriazione su mezzi',
	'FoodServiceType/SALE_SCOMMESSE/label': 'Sala scommesse',
	'FoodServiceType/SALUMERIA/label': 'Salumeria',
	'FoodServiceType/STABILIMENTO_BALNEARE/label': 'Stabilimento balneare',
	'FoodServiceType/STAZIONE_DI_SERVIZIO/label': 'Stazione di servizio',
	'FoodServiceType/TABACCHERIA/label': 'Tabaccheria',
	'FoodServiceType/TEATRO_SALA_DA_CONCERTO/label': 'Teatro - Sala da concerto',
	'FoodServiceType/TORREFAZIONE/label': 'Torrefazione',
	'FoodServiceType/TRATTORIA_OSTERIA/label': 'Trattoria - Osteria',
	'FoodService/Country/filter/label': 'Nazione',
	'FoodService/types/filter': 'Tipologia',
	'FoodService/sfId/label': 'Salesforce Id',

	'objects-merge/source/label': 'Source (SARÀ CANCELLATO)',
	'objects-merge/source/helpText':
		"Mettere l'url HUB dell'oggetto che sarà cancellato",
	'objects-merge/target/label': 'Target (resterà)',
	'objects-merge/target/helpText':
		"Mettere l'url HUB dell'oggetto dell'oggetto che resterà",
	'directory-reload/directory/label': 'Directory',
	'directory-reload/directory/helpText':
		'Scegliere quale delle directory IFN aggiornare',
	'directory-reload/preClear/label': 'Ricreare da zero in modo da eliminare quelli che non ci sono più?',
	'date-reload/date/label': 'Mese di riferimento',
	'PLManufacturer/labelPlural': 'Produttori di Private Label',
	'PLManufacturer/label': 'Produttore di Private Label',
	'PLManufacturer/helptext': 'Industria manufattrice dei prodotti PL dei retailer',
	'PLManufacturer/company/label': 'Industria',
	'PLManufacturer/company/helptext': '',
    'PLManufacturer/good/label': 'Prodotto',
'PLManufacturer/good/helpText': 'Seleziona il prodotto da associare',
'PLManufacturer/brand/label': 'Brand',
'PLManufacturer/brand/helpText': 'Seleziona il brand di riferimento',
'PLManufacturer/retailerName/filter/label': 'Nome Retailer',
'PLManufacturer/FoodServiceName/filter/label': 'Nome catena Food Service',
'PLManufacturer/goodClassName/filter/label': 'Categoria merceologica',
'PLManufacturer/manufacturer/filter/label': 'Industria manufattrice',

'StoreChain/labelPlural': 'Insegne di negozi',
'StoreChain/label': 'Insegna di negozi',
'StoreChain/name/label': 'Nome Insegna',
'StoreChain/name/helpText': 'Inserire il nome della Insegna',
'StoreChain/Parent/label': 'Proprietario dell Insegna',
'StoreChain/localId/label': 'Local ID',
'StoreChain/localId/helpText': '',
'StoreChain/Retailer/label': 'Retailer',
'StoreChain/Retailer/helpText': 'Selezionare il retailer associato',
'StoreChain/Foodservice/label': 'Food Service',
'StoreChain/Foodservice/helpText': 'Selezionare il food service associato',
'StoreChain/Service/label': 'Servizio',
'StoreChain/Service/helpText': 'Selezionare il servizio associato',
'StoreChain/Company/label': 'Azienda',
'StoreChain/Company/helpText': "Selezionare l'azienda associata",
'StoreChain/logo_media/label': 'Logo',
'StoreChain/logo_media/helpText': 'Caricare il logo della catena in formato PNG o SVG',
'StoreChain/website/label': 'Sito web',
'StoreChain/website/helpText': 'Inserire il sito web preceduto da https://',
'StoreChain/social_instagram_page/label': 'Pagina Instagram',
'StoreChain/social_instagram_page/helpText': "Inserire l'URL del profilo Instagram",
'StoreChain/social_facebook_page/label': 'Pagina Facebook',
'StoreChain/social_facebook_page/helpText': "Inserire l'URL del profilo Facebook",
'StoreChain/description_ita/label': 'Descrizione breve in italiano',
'StoreChain/description_ita/helpText': 'Inserire una breve descrizione in italiano della catena',
'StoreChain/description_long_ita/label': 'Descrizione completa in italiano',
'StoreChain/description_long_ita/helpText': 'Inserire una descrizione dettagliata in italiano della catena',
'StoreChain/type/label': 'Tipologia',
'StoreChain/type/helpText': 'Selezionare la tipologia di catena',
'StoreChain/stores_ownership/label': 'Proprietà punti vendita',
'StoreChain/stores_ownership/helpText': 'Indicare la tipologia di proprietà dei punti vendita',
'StoreChain/price_positioning/label': 'Posizionamento di prezzo',
'StoreChain/price_positioning/helpText': 'Indicare il posizionamento di prezzo della catena',

'Award/pricePosition/label': 'Posizionamento di prezzo',
'Award/pricePosition/helpText': 'Indicare il posizionamento di prezzo desiderato',
'Award/brandType/label': 'Tipologia brand',
'Award/brandType/helpText': 'Selezionare la tipologia di brand richiesta',
'Award/EditorialSection/label': 'Sezione editoriale',
'Award/EditorialSection/helpText': 'Selezionare la sezione editoriale di riferimento',
'Award/productType/label': 'Tipologia prodotto',
'Award/productType/helpText': 'Specificare la tipologia di prodotto richiesta',
'Award/benchmarkGood/label': 'Prodotto benchmark',
'Award/benchmarkGood/helpText': 'Selezionare il prodotto di riferimento',
'Award/purchasingType/label': 'Tipologia di fornitura',
'Award/purchasingType/helpText': "Specificare la modalità di fornitura prevista",
'Award/estimatedVolumes/label': 'Volumi stimati',
'Award/estimatedVolumes/helpText': 'Indicare i volumi stimati di acquisto',
'Award/measureUnit/label': 'Unità di misura',
'Award/measureUnit/helpText': 'Selezionare l\'unità di misura per i volumi',
'Award/listingFee/label': 'Listing fee',
'Award/listingFee/helpText': 'Indicare eventuale listing fee richiesta',
'Award/marketingInvestmentExpected/label': 'Investimento marketing previsto',
'Award/marketingInvestmentExpected/helpText': 'Indicare l\'investimento in marketing atteso',

'DataViz/labelPlural': 'Infografiche',
'DataViz/label': 'Infografica',
'DataViz/targetSite/label': 'Sito di riferimento',
'DataViz/targetSite/helpText': 'Selezionare il sito dove sarà pubblicata l\'infografica',
'DataViz/editorial_section/label': 'Sezione editoriale',
'DataViz/editorial_section/helpText': 'Selezionare la sezione editoriale di riferimento',
'DataViz/name/label': 'Nome',
'DataViz/name/helpText': 'Inserire il nome dell\'infografica',
'DataViz/localId/label': 'Local ID',
'DataViz/localId/helpText': '',
'DataViz/title/label': 'Titolo',
'DataViz/title/helpText': 'Inserire il titolo che verrà mostrato ad interfaccia',
'DataViz/url/label': 'URL',
'DataViz/url/helpText': 'Inserire l\'URL dell\'infografica',
'DataViz/source/label': 'Fonte dei dati',
'DataViz/source/helpText': 'Indicare la fonte dei dati visualizzati',

'GoodRecall/labelPlural': 'Richiami di prodotto',
'GoodRecall/label': 'Richiamo di prodotto',
'GoodRecall/goodBranding/label': 'Marchio prodotto',
'GoodRecall/goodBranding/helpText': 'Inserire il marchio del prodotto richiamato',
'GoodRecall/Company/label': 'Azienda distributrice',
'GoodRecall/Company/helpText': 'Selezionare l\'azienda produttrice',
'GoodRecall/GoodClass/label': 'Categoria merceologica',
'GoodRecall/GoodClass/helpText': 'Selezionare la categoria merceologica',
'GoodRecall/publicationDate/label': 'Data pubblicazione',
'GoodRecall/publicationDate/helpText': 'Inserire la data di pubblicazione del richiamo',
'GoodRecall/localId/label': 'Local ID',
'GoodRecall/localId/helpText': '',
'GoodRecall/urlMinistero/label': 'URL Ministero',
'GoodRecall/urlMinistero/helpText': 'Inserire l\'URL della pagina del Ministero',
'GoodRecall/urlPdf/label': 'URL PDF',
'GoodRecall/urlPdf/helpText': 'Inserire l\'URL del documento PDF',
'GoodRecall/goodCategory/label': 'Tipologia di prodotto',
'GoodRecall/goodCategory/helpText': 'Indicare la Tipologia del prodotto richiamato',
'GoodRecall/manufacturerName/label': 'Nome produttore',
'GoodRecall/manufacturerName/helpText': 'Inserire il nome del produttore',
'GoodRecall/manufacturingPlantCode/label': 'Codice stabilimento',
'GoodRecall/manufacturingPlantCode/helpText': 'Inserire il codice dello stabilimento produttivo',
'GoodRecall/manufacturingPlantAddress/label': 'Indirizzo stabilimento',
'GoodRecall/manufacturingPlantAddress/helpText': 'Inserire l\'indirizzo dello stabilimento produttivo',
'GoodRecall/recallReason/label': 'Motivazione del richiamo',
'GoodRecall/recallReason/helpText': 'Specificare la motivazione del richiamo',
'GoodRecall/warnings/label': 'Avvertenze',
'GoodRecall/warnings/helpText': 'Inserire le avvertenze per il consumatore',
'GoodRecall/RelatedOSA/label': 'OSA correlati',
'GoodRecall/RelatedOSA/helpText': 'Selezionare eventuali OSA correlati',
'GoodRecall/productionLot/label': 'Lotto produzione',
'GoodRecall/productionLot/helpText': 'Inserire il lotto di produzione interessato',
'GoodRecall/skuWeight/label': 'Peso confezione',
'GoodRecall/skuWeight/helpText': 'Inserire il peso della confezione',
'Newsletter/delivery/label': 'Software di invio',
'Newsletter/name/filter/label': 'Nome',
'Newsletter/targets/filter/label': 'Target Site',
'Newsletter/deliveries/filter/label': 'Software di invio',
'Newsletterissue/statuses/filter/label': 'Stati',
'EditorialSection/EditorialBrand/filter/label': 'Brand editoriale',
'EditorialBrand/HOMECARE360/label': 'Home Care 360',
'EditorialBrand/PERSONALCARE360/label': 'Personal Care 360',
'NewsletterBrand/HOMECARE360/label': 'Home Care 360',
'NewsletterBrand/PERSONALCARE360/label': 'Personal Care 360',
'TargetSite/HOMECARE360/label': 'Homecare 360',
'TargetSite/PERSONALCARE360/label': 'Personal Care 360',
'Geoindication/statuses/filter/label': 'Stato',
'Coupon/startDate/label': 'Data inizio',
'Coupon/startDate/helpText': 'Inserire la data di inizio validità',
'Coupon/expirationDate/label': 'Data scadenza',
'Coupon/expirationDate/helpText': 'Inserire la data di fine validità'
};
