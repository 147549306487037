import React, { useCallback, useContext, useState } from 'react';
import { FormGroup, FormText, FormFeedback, Col, Label } from 'reactstrap';
import CheckboxTree from 'react-checkbox-tree';
import { t } from '../../utils/labels';
import { FieldComponent, FieldMuter, TreeFieldType } from '../../types/form';
import { FormContext } from '../../utils/context/form';
import { getLocalError } from '../../utils/formErrors';

export const TreeField: React.FC<FieldComponent<TreeFieldType>> = ({
	field,
	path,
	changeHandler,
}) => {
	const { expanded } = field;
	const { errors } = useContext(FormContext);
	const error = getLocalError(path, errors);
	const [state, setState] = useState({
		checked: [],
		expanded: expanded ? expanded.map((obj) => 'branch' + obj.id) : [],
	});

	const expand = useCallback(
		(e: any) =>
			setState({
				expanded: e,
				checked: state.checked,
			}),
		[state],
	);

	const select = useCallback(
		(changeHandler: FieldMuter) => {
			const { single, value } = field;
			if (single) {
				return (e) => {
					const branch = e && e.filter((v) => v.indexOf('branch') !== -1);

					if (branch[0]) {
						expand(state.expanded.concat([branch[0]]));
						return;
					}

					const oldValue = value && value[0] ? value[0].id : false;
					let obj: any = false;

					if (e.length === 1) {
						obj = { id: e[0].replace('leaf', '') };
					} else if (e.length >= 2) {
						const newValue = oldValue
							? e.filter((val) => val !== 'leaf' + oldValue)
							: e;
						obj = { id: newValue[0].replace('leaf', '') };
					}

					changeHandler(obj ? [obj] : null, path);
				};
			}
			return (e) => {
				changeHandler(
					e.map((i) => ({ id: i.replace('leaf', '') })),
					path,
				);
			};
		},
		[field],
	);

	const errorText = error ? error.message : '';

	return (
		<FormGroup row color={error ? 'danger' : ''}>
			<Label sm={12}>{field.label}: </Label>
			<Col sm={12} className={field.single ? 'single-tree' : ''}>
				<CheckboxTree
					nodes={field.nodes.slice(0)} // WTF maledetto react-checkbox-tree
					expanded={state.expanded}
					checked={
						field.value ? field.value.map((obj) => 'leaf' + obj.id) : []
					}
					onExpand={expand}
					onCheck={(mah) => select(changeHandler)(mah)}
					optimisticToggle={!field.single}
					noCascade={field.single}
				/>
				{error && (
					<section>
						<FormFeedback>
							{t(errorText + '/tree', errorText)}
						</FormFeedback>
					</section>
				)}
				<FormText color="muted">
					{field.description.readonly && (
						<div className="usability-note">{t`readonly field description`}</div>
					)}
					{field.helpText}
				</FormText>
			</Col>
		</FormGroup>
	);
};
