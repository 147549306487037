import React from 'react';
import { GoodFeatureCertificationDetailQuery } from '../graphql/query/GoodFeatureCertificationDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/GoodFeatureCertification.json';
import { ENTITY } from '../utils/entities';

export const GoodFeatureCertificationDetail = ({ id }) => (
	<EntityDetailFetcher
		query={GoodFeatureCertificationDetailQuery}
		entityName={ENTITY.GOOD_FEATURE_CERTIFICATION}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		fields={['name', 'nameIta', 'akas', 'website']}
	/>
);
