import gql from 'graphql-tag';
import {EditorialTagFullFragment} from "../fragments/EditorialTagFullFragment";

export const EditorialTagPatchMutation = gql`
	mutation EditorialTagPatchMutation($id: ID!, $changes: EditorialTagPatchInput!) {
		results: editorialTagPatch(id: $id, changes: $changes) {
			...EditorialTagFullFragment
		}
	}
	${EditorialTagFullFragment}
`;


