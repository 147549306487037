import React from 'react';
import { registerClass, classNames } from '@food/css-manager';

const loginContainerStyle = registerClass(
	() => `
	> div {
		width: 90%;
		max-width: 900px;
	}
`,
);

export const LoginContainer: React.FunctionComponent<{}> = ({ children }) => (
	<div className={classNames('login-container', loginContainerStyle)}>
		{children}
	</div>
);
