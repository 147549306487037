import { identity, pipe } from 'ramda';
import { toRefInput } from '../dataTrasform';
import { fastTransformer, toHidden } from '../form';
import { Company, Fair } from '../../server-types';
import { generateAsyncQuery, generatePaginatedSelectQuery } from '../graphql';
import { ENTITY } from '../entities';
import { assignAsyncQuery } from '../transformers';

export const fairParticipationTransformer = (
	originalEntity: Company | Fair,
) => (data) => {
	// isFair necessario in quanto questa entita' secondaria puo' essere chiamata sia da Fair che da Company
	const isFair = originalEntity.__typename === 'Fair';
	const hider = toHidden(toRefInput(originalEntity));
	const fairQuery = generateAsyncQuery(
		generatePaginatedSelectQuery(ENTITY.FAIR, false),
		true,
		(value) => ({ filter: { name: value, isConcluded: false } }),
	);

	data.fields = fastTransformer({
		Company: isFair ? identity : hider,
		Fair: pipe(assignAsyncQuery(fairQuery), isFair ? hider : identity),
	})(data.fields);

	return data;
};
