import gql from 'graphql-tag';
import {FairFullFragment} from "../fragments/FairFullFragment";

export const FairCreateMutation = gql`
	mutation FairCreateMutation($values: FairCreateInput!) {
		results: fairCreate(values: $values) {
			...FairFullFragment
		}
	}
	${FairFullFragment}
`;


