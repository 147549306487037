import gql from 'graphql-tag';
import {EditorialSectionFullFragment} from "../fragments/EditorialSectionFullFragment";

export const EditorialSectionCreateMutation = gql`
	mutation EditorialSectionCreateMutation($values: EditorialSectionCreateInput!) {
		results: editorialSectionCreate(values: $values) {
			...EditorialSectionFullFragment
		}
	}
	${EditorialSectionFullFragment}
`;


