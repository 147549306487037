import gql from 'graphql-tag';

export const ObjectHistoryFullFragment = gql`
	fragment ObjectHistoryFullFragment on ObjectHistory {
		id
		localId
		User {
			id
			name
		}
		timestamp
		action
		collection
		params
		Object {
			id
		}
	}
`;
