import gql from 'graphql-tag';
import {FairFullFragment} from "../fragments/FairFullFragment";

export const FairEditQuery = gql`
	query FairEditQuery($id: ID!) {
		entity: node (id: $id) {
				...FairFullFragment
		  }
        editorialSectionTree(EditorialBrand: GOODS4HUB) {
            id
            name
            Parent {
                id
            }
            goodClasses {
                id
                shortname
                shortnameIta
                localId
                name
                nameIta
            }
        }
	}
	${FairFullFragment}
`;


