import React, { useCallback } from 'react';
import { PageTitle } from './PageTitle';
import { t } from '../utils/labels';
import { Form } from './Form';
import { Button, Card, CardBody, Form as BootstrapForm } from 'reactstrap';
import { client } from '../utils/client';
import { offsetToCursor } from '../utils/graphql';
import stringify from 'csv-stringify/lib/es5/sync';
import {
	CONTEXTS,
	ERRORS,
	generateError,
	modalHandler,
	sentryHandler,
} from '../utils/errors';
import { UserData } from '@food/auth';
import { ReportNotAllowed } from './ReportNotAllowed';
import { identity } from 'ramda';
import { FieldList } from './form/FieldsList';
import { InternalCapability } from '../server-types';

export interface ReportType {
	title: string;
	filename: (data: any, pre: any, form: any) => string;
	fields: any[];
	query: any;
	extractor: Function;
	transformer: (e: any, pre: any, index: number) => any;
	variablesGenerator?: (pre: any, formValues: any) => any;
	header: (pre: any) => string[];
	preQuery?: any;
	preQueryToFilters?: Function;
	preVariablesGenerator?: (formValues: any) => any;
	paginated?: boolean;
	requirements: ReadonlyArray<InternalCapability>;
	cursor?: (i: number) => string;
	globalTransformer?: (records: any[], pre: any) => ReadonlyArray<any>;
	customRequirementsCheck?: (pre: any, user: any) => Error | null;
}

const stopIteration = 1000;

export const Report: React.FC<ReportType> = ({
	query,
	paginated = true,
	extractor,
	transformer,
	header,
	preQuery,
	variablesGenerator,
	preVariablesGenerator,
	filename,
	cursor = offsetToCursor,
	globalTransformer = identity,
	title,
	fields,
	requirements,
	customRequirementsCheck,
}) => {
	const submit = useCallback(async (values: any, user: any) => {
		try {
			let result;
			let tmp;
			let count = 0;
			const perPage = 50;

			let pre = {};
			if (preQuery) {
				const variables = preVariablesGenerator
					? preVariablesGenerator(values)
					: {};
				pre = ((await client.query({
					query: preQuery,
					variables,
				})) as any).data;
			}

			const accessError = customRequirementsCheck
				? customRequirementsCheck(pre, user)
				: null;
			
			if (accessError !== null) {
				alert(accessError);
				return;
			}

			let variables = {};
			if (variablesGenerator) {
				variables = variablesGenerator(pre, values);
			}

			if (paginated) {
				let end = false;
				result = [];

				while (!end) {
					tmp = (await client.query({
						query,
						variables: {
							...variables,
							after: cursor(count * perPage - 1),
							first: perPage,
						},
						fetchPolicy: 'network-only',
					})) as any;

					result = result.concat(extractor(tmp.data));

					if (!tmp.data.connector.pageInfo.hasNextPage) {
						end = true;
					}

					if (count++ === stopIteration) {
						throw new Error('Il loop continua da troppo! Mi fermo');
					}
				}
			} else {
				tmp = (await client.query({
					query,
					variables,
				})) as any;
				result = tmp.data.entities;
			}

			//
			const transformedResults = globalTransformer(result, pre);

			const parsedData = transformedResults.map((e, index) =>
				transformer(e, pre, index),
			);
			parsedData.unshift(header(pre));
			const csvData = new Blob([stringify(parsedData)], {
				type: 'text/csv;charset=utf-8;',
			});
			const csvURL = window.URL.createObjectURL(csvData);
			const tempLink = document.createElement('a');
			tempLink.href = csvURL;
			tempLink.setAttribute(
				'download',
				filename(parsedData, pre, values) + '.csv',
			);
			tempLink.style.display = 'none';
			document.body.appendChild(tempLink);
			tempLink.click();
		} catch (e) {
			const error = generateError(ERRORS.GENERATION, CONTEXTS.REPORT, {
				originalError: e,
			});
			sentryHandler([error]);
			modalHandler([error]);
			console.error(e);
		}
	}, []);

	
	return (
		<UserData>
			{({ userData }) => {
				if (
					requirements.some(
						(c) => userData.internalCapabilities.indexOf(c) === -1,
					)
				) {
					return <ReportNotAllowed />;
				}

				return (
					<>
						<PageTitle
							title={title}
							breadcrumbs={[
								{ label: t`home`, path: '/' },
								{
									label: t`Reports`,
									path: '/reports',
								},
								{
									label: title,
								},
							]}
						/>
						<Card>
							<CardBody>
								<Form fields={fields}>
									{({ fields, mutatorFactory, values }) => (
										<BootstrapForm className="Form">
											<FieldList
												fields={fields}
												setter={mutatorFactory}
											/>
											<Button
												onClick={() => submit(values, userData)}
												size="lg"
												color="success"
											>
												<i className="mdi mdi-download" />{' '}
												{t`Download CSV`}
											</Button>
										</BootstrapForm>
									)}
								</Form>
							</CardBody>
						</Card>
					</>
				);
			}}
		</UserData>
	);
};
