import React from 'react';
import { FormGroup } from 'reactstrap';
import Select, { components } from 'react-select';
import { t } from '../../utils/labels';
import { ListFilterType } from '../Filters';
import { BooleanFieldType } from '../../types/form';

const options = [
	{ label: t`Yes`, value: 'true' },
	{ label: t`No`, value: 'false' },
];

export const BooleanFilter: React.FC<ListFilterType<
	{ label: string; value: string },
	BooleanFieldType
>> = ({ field, mutator, size = 3, entityName }) => {
	const label = t([entityName, field.name, 'filter', 'label'].join('/'));

	const SingleValue = (props) => (
		<components.SingleValue {...props}>
			<span className="grey">{label}</span>{' '}
			<span
				className={
					'boolean-select-value ' +
					(props.data.value === 'true' ? 'yes' : 'no')
				}
			>
				{props.children}
			</span>
		</components.SingleValue>
	);

	return (
		<FormGroup className={`filter col-sm-${size}`}>
			<Select
				// @ts-ignore
				value={field.value}
				className={'full-width'}
				isMulti={false}
				isClearable={true}
				name={field.name}
				components={{ SingleValue }}
				options={options}
				onChange={(val) => mutator(val, field.name)}
				placeholder={label}
			/>
		</FormGroup>
	);
};
