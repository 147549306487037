import gql from 'graphql-tag';
import {HighlightedGoodFullFragment} from "../fragments/HighlightedGoodFullFragment";

export const HighlightedGoodDetailQuery = gql`
	query HighlightedGoodDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...HighlightedGoodFullFragment
		}
	}
	${HighlightedGoodFullFragment}
`;


