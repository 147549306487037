import gql from 'graphql-tag';
import {MagazineCollectionFullFragment} from "../fragments/MagazineCollectionFullFragment";

export const MagazineCollectionPatchMutation = gql`
	mutation MagazineCollectionPatchMutation($id: ID!, $changes: MagazineCollectionPatchInput!) {
		results: magazineCollectionPatch(id: $id, changes: $changes) {
			...MagazineCollectionFullFragment
		}
	}
	${MagazineCollectionFullFragment}
`;


