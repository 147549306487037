import React, { useState } from 'react';
import EntityListFetcher from '../../components/EntityListFetcher';
import { IFieldROProps } from '../EntityDetailView';
import { EntityListFieldType } from '../../types/form';
import { ViewContext } from '../../utils/EntityViewContext';
import { DefaultEntityList } from '../../components/DefaultEntityList';
import {
	EntityModalState,
	EntityModalStateEnum,
} from '../../components/EntityModalState';

interface EntityListFieldProps extends IFieldROProps<EntityListFieldType> {
	onCreate?: (entity: any) => void;
	onDelete?: () => void;
	customAddTitle?: string;
}

export const EntityListField: React.FC<EntityListFieldProps> = ({
	onCreate,
	onDelete,
	customAddTitle,
	field,
}) => {
	const [selectedEntity, setSelectedEntity] = useState(null);
	const { props, addComponent } = field;
	const { originalEntity, children } = props;

	const EditC = field.editComponent;
	const CreateC = field.addComponent;
	const DetailC = field.detailComponent;
	const C = children || DefaultEntityList;

	return (
		<EntityModalState>
			{(modalState, changeModalState, cloneId) => (
				<EntityListFetcher
					{...props}
					secondary={true}
					addModalOpener={(id?: string) => {
						changeModalState(EntityModalStateEnum.Create, id);
					}
					}
					detailModalOpener={(e) => {
						setSelectedEntity(e);
						changeModalState(EntityModalStateEnum.Detail);
					}}
					createModalMode={!!addComponent}
					detailModalMode={!!field.detailComponent}
					detailHandler={field.detailHandler}
					additionalActions={props.actions}
				>
					{(args) => {
						
						const deleteHandler = () => {
							changeModalState(EntityModalStateEnum.Closed);
							args.refetch();

							if (onDelete) {
								onDelete();
							}
						};

						const closeAndRefetch = (saved?: any) => {
							changeModalState(EntityModalStateEnum.Closed);
							args.refetch();
							if (onCreate) {
								onCreate(saved);
							}
						};
						return (
							<ViewContext.Provider
								value={{
									type: 'modal',
									mode: modalState,
									changeState: (state: EntityModalStateEnum) => {
										changeModalState(state);
										if (state === EntityModalStateEnum.Closed) {
											setSelectedEntity(null);
										}
									},
									customTitle:
										modalState === EntityModalStateEnum.Create
											? customAddTitle
											: null,
								}}
							>
								{CreateC && (
									<CreateC
										onSubmit={closeAndRefetch}
										originalEntity={originalEntity}
										id={cloneId}
									/>
								)}

								{DetailC && selectedEntity && (
									<DetailC
										id={selectedEntity.id}
										originalEntity={originalEntity}
										modal={true}
										onDelete={deleteHandler}
									/>
								)}
								{EditC && selectedEntity && (
									<EditC
										id={selectedEntity.id}
										onSubmit={closeAndRefetch}
										originalEntity={originalEntity}
									/>
								)}
								<C {...args} />
							</ViewContext.Provider>
						);
					}}
				</EntityListFetcher>
			)}
		</EntityModalState>
	);
};
