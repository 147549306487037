import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import CONFIG from '../static/config';
import { RenewLink, targetSiteLink } from '@food/auth';
import { authManager } from './auth';
import { retryFetch } from './retryFetch';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from '../static/fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData,
});

const link = ApolloLink.from([
	new (targetSiteLink(CONFIG as any))(),
	new (RenewLink(authManager))(), // link per la modal di renew una volta che la sessione e' scaduta/inesistente
	createUploadLink({
		fetch: retryFetch,
		uri: CONFIG.GRAPHQL_API,
	}),
]);

const cache = new InMemoryCache({
	fragmentMatcher,
	dataIdFromObject: (object: any) => object.id,
});

export const client = new ApolloClient({
	link,
	cache,
});
