import React from 'react';
import { Col, Row, Label } from 'reactstrap';
import { t } from '../../utils/labels';
import { Tag } from '@food/ui';
import { BooleanFieldType, FieldComponentRO } from '../../types/form';

export const BooleanFieldRO: React.FunctionComponent<FieldComponentRO<
	BooleanFieldType
>> = ({ field }) => (
	<Row>
		<Col sm={4}>
			<Label>{field.label}:</Label>
		</Col>
		<Col sm={8}>
			{field.value === true && <Tag label={t`Yes`} type={'success'} />}
			{field.value === false && <Tag type={'danger'} label={t`No`} />}
			{(field.value === null || field.value === undefined) && (
				<Tag type={'alt'} label={t`Undefined`} />
			)}
		</Col>
	</Row>
);
