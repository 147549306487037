import React from 'react';
import { CompanyBadgePatchMutation } from '../graphql/mutations/CompanyBadgePatchMutation';
import { CompanyBadgeEditQuery } from '../graphql/query/CompanyBadgeEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/CompanyBadge.json';
import inputTypeIntro from '../static/introspection/CompanyBadgePatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const CompanyBadgeEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(CompanyBadgeEditQuery, { id })}
		mutation={CompanyBadgePatchMutation}
		entityName={ENTITY.COMPANY_BADGE}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={['name', 'iconMedia', 'styleClass', 'color', 'descriptionLong']}
	/>
);
