import gql from 'graphql-tag';
import {AwardResultFullFragment} from "../fragments/AwardResultFullFragment";

export const AwardResultEditQuery = gql`
	query AwardResultEditQuery($id: ID!) {
		entity: node (id: $id) {
				...AwardResultFullFragment
		  }
	}
	${AwardResultFullFragment}
`;


