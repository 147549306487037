import gql from 'graphql-tag';
import {RetailerFullFragment} from "../fragments/RetailerFullFragment";

export const RetailerCreateMutation = gql`
	mutation RetailerCreateMutation($values: RetailerCreateInput!) {
		results: retailerCreate(values: $values) {
			...RetailerFullFragment
		}
	}
	${RetailerFullFragment}
`;


