import gql from 'graphql-tag';
import { MediaBaseFragment } from './MediaBaseFragment';

export const Candidacy_producer_FullFragment = gql`
	fragment Candidacy_producer_FullFragment on Candidacy {
		id
		localId
		Award {
			id
			name
			langCode
			maxGoodsPerCandidacy
			description1Label
			description1Max
			description2Label
			description2Max
			description3Label
			description3Max
			description4Label
			description4Max
			description5Label
			description5Max
			description5Max
			description6Label
			description6Max
			description7Label
			description7Max
			description8Label
			description8Max
			description9Label
			description9Max
			description10Label
			description10Max
			extraMediaLabel
			extraMediaType
		}
		Company {
			id
			name
		}
		featuredImageMedia {
			...MediaBaseFragment
		}
		name
		Brand {
			id
			name
		}
		range
		description1
		description2
		description3
		description4
		description5
		description6
		description7
		description8
		description9
		description10
		extraMedia {
			...MediaBaseFragment
		}
		goods {
			id
			name
			nameIta
			formats {
				id
				ean
			}
		}
		launchDate
		additionalImagesMedia {
			...MediaBaseFragment
		}
	}
	${MediaBaseFragment}
`;
