import gql from 'graphql-tag';
import {NewsletterIssueFullFragment} from "../fragments/NewsletterIssueFullFragment";

export const NewsletterIssueCreateMutation = gql`
	mutation NewsletterIssueCreateMutation($values: NewsletterIssueCreateInput!) {
		results: newsletterIssueCreate(values: $values) {
			...NewsletterIssueFullFragment
		}
	}
	${NewsletterIssueFullFragment}
`;


