import gql from 'graphql-tag';
import {HighlightedGoodFullFragment} from "../fragments/HighlightedGoodFullFragment";

export const HighlightedGoodEditQuery = gql`
	query HighlightedGoodEditQuery($id: ID!) {
		entity: node (id: $id) {
				...HighlightedGoodFullFragment
		  }
	}
	${HighlightedGoodFullFragment}
`;


