import gql from 'graphql-tag';
import {MediaFullFragment} from "../fragments/MediaFullFragment";

export const MediaEditQuery = gql`
	query MediaEditQuery($id: ID!) {
		entity: node (id: $id) {
				...MediaFullFragment
		  }
	}
	${MediaFullFragment}
`;


