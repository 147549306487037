import gql from 'graphql-tag';
import { EditorialSectionFullFragment } from '../fragments/EditorialSectionFullFragment';

export const EditorialSectionEditQuery = gql`
	query EditorialSectionEditQuery($id: ID!) {
		entity: node(id: $id) {
			...EditorialSectionFullFragment
		}
		editorialSectionTree(EditorialBrand: GOODS4HUB) {
			id
			name
			Parent {
				id
			}
			goodClasses {
				id
				shortname
				shortnameIta
				localId
				name
				nameIta
			}
		}
	}
	${EditorialSectionFullFragment}
`;


