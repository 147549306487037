import React from 'react';
import { FrequentSearchPatchMutation } from '../graphql/mutations/FrequentSearchPatchMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/FrequentSearch.json';
import inputTypeIntro from '../static/introspection/FrequentSearchPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { StaticFrequentSearchEditQuery } from '../graphql/query/StaticFrequentSearchEditQuery';
import { fastTransformer, toHidden } from '../utils/form';
import { identity } from 'ramda';
import { ACTIONS, generateUrl } from '../utils/urls';

const StaticFrequentSearchEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(StaticFrequentSearchEditQuery, { id })}
		mutation={FrequentSearchPatchMutation}
		entityName={ENTITY.FREQUENT_SEARCH}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		customDetailUrl={(id) =>
			generateUrl(ENTITY.FREQUENT_SEARCH, ACTIONS.LIST) + '/static/' + id
		}
		fields={[
			'name',
			'collection',
			'targetSites',
			'relatedFair',
			'nameIta',
			'goods',
			'companies',
			'logoMedia',
			'linkUrl',
			'linkRoles',
			'description',
		]}
		transformer={(data, entity) => {
			data.fields = fastTransformer({
				goods: entity.collection === 'GOOD' ? identity : toHidden(),
				companies: entity.collection === 'COMPANY' ? identity : toHidden(),
			})(data.fields);
			return data;
		}}
	/>
);

export { StaticFrequentSearchEdit };
