import gql from 'graphql-tag';
import {EuCategoryFullFragment} from "../fragments/EuCategoryFullFragment";

export const EuCategoryCreateMutation = gql`
	mutation EuCategoryCreateMutation($values: EuCategoryCreateInput!) {
		results: euCategoryCreate(values: $values) {
			...EuCategoryFullFragment
		}
	}
	${EuCategoryFullFragment}
`;


