import React from 'react';
import { NewsletterIssuePatchMutation } from '../graphql/mutations/NewsletterIssuePatchMutation';
import { NewsletterIssueEditQuery } from '../graphql/query/NewsletterIssueEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/NewsletterIssue.json';
import inputTypeIntro from '../static/introspection/NewsletterIssuePatchInput.json';
import { ENTITY } from '../utils/entities';
import {
	addOverrideFields, injectFetchedData, jsonToPromises,
	newsletterIssueBeforeSaveTransformer,
	newsletterIssueCreateTransformer,
	newsletterTemplateAdditionalFieldsTransformer,
	valuesToForms,
} from '../utils/entities/newsletterIssue';
import { transform } from '../utils/form';
import { graphqlQuery, mediaQuery } from '../utils/graphql';


export const NewsletterIssueEdit = ({ id }) => (
	<EntityEditFetcher
		beforeSaveTransformer={newsletterIssueBeforeSaveTransformer}
		fetcher={async () => {
			
			const data = await graphqlQuery(NewsletterIssueEditQuery, {
				id,
			})();
			const values = JSON.parse(data.entity.values);
			const { blocks } = values;

			// creo un array di promise con le due immagini necessarie alla section della newsletterIssue
			// e tutte le query necessarie per ricostruire i blocchi (parte con filter e Concat)
			const results: { [key: string]: any } = {};

			// nel caso fosse presente un override per l'header del media lo devo scaricare
			if (
				values.newsletterOverrides &&
				values.newsletterOverrides.headerMedia
			) {
				values.newsletterOverrides.headerMedia = (
					await graphqlQuery(
						mediaQuery,
						values.newsletterOverrides.headerMedia,
					)()
				).node;
			}

			const blocksResults = await jsonToPromises(blocks, results);

			injectFetchedData(blocksResults, blocks);

			return {
				entity: {
					...data.entity,
					values,
				},
			};
		}}
		mutation={NewsletterIssuePatchMutation}
		entityName={ENTITY.NEWSLETTER_ISSUE}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={['name', 'publicationDate', 'Newsletter', 'hiddenIntro']}
		transformer={(data, fetcherResult) => {
			data.fields = transform(
				newsletterIssueCreateTransformer,
				newsletterTemplateAdditionalFieldsTransformer,
				valuesToForms(fetcherResult),
			)(data.fields);

			if (fetcherResult.Newsletter.inputTemplate) {
				data.fields = addOverrideFields(
					data.fields,
					fetcherResult.Newsletter.inputTemplate,
					fetcherResult.values.newsletterOverrides,
				);
			}

			return data;
		}}
	/>
);
