import gql from 'graphql-tag';
import {MagazineFullFragment} from "../fragments/MagazineFullFragment";

export const MagazineDetailQuery = gql`
	query MagazineDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...MagazineFullFragment
		}
	}
	${MagazineFullFragment}
`;


