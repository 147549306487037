import gql from 'graphql-tag';
import {GoodFeatureCertificationFullFragment} from "../fragments/GoodFeatureCertificationFullFragment";

export const GoodFeatureCertificationCreateMutation = gql`
	mutation GoodFeatureCertificationCreateMutation($values: GoodFeatureCertificationCreateInput!) {
		results: goodFeatureCertificationCreate(values: $values) {
			...GoodFeatureCertificationFullFragment
		}
	}
	${GoodFeatureCertificationFullFragment}
`;


