import gql from 'graphql-tag';

export const Candidacy_producer_CreateQuery = gql`
	query CandidacyCreateQuery($id: ID!) {
		award: node(id: $id) {
			... on Award {
				langCode
				id
				maxGoodsPerCandidacy
				invitedCompanies {
					edges {
						node {
							id
							maxGoodsPerCandidacy
						}
					}
				}
				categories {
					id
					name
				}
				description1Label
				description1Max
				description2Label
				description2Max
				description3Label
				description3Max
				description4Label
				description4Max
				description5Label
				description5Max
				description6Label
				description6Max
				description7Label
				description7Max
				description8Label
				description8Max
				description9Label
				description9Max
				description10Label
				description10Max
				extraMediaLabel
				extraMediaType
			}
		}
	}
`;
