import React from 'react';
import { HighlightedGoodPatchMutation } from '../graphql/mutations/HighlightedGoodPatchMutation';
import { HighlightedGoodEditQuery } from '../graphql/query/HighlightedGoodEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/HighlightedGood.json';
import inputTypeIntro from '../static/introspection/HighlightedGoodPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { EntityEditModal } from '../types/entities';
import { Company, Good, HighlightedGood } from '../server-types';

export const HighlightedGoodEdit: React.FC<EntityEditModal<
	HighlightedGood,
	Company | Good
>> = ({ id, onSubmit }) => (
	<EntityEditFetcher
		modal={true}
		id={id}
		onSubmit={onSubmit}
		fetcher={graphqlQuery(HighlightedGoodEditQuery, { id })}
		mutation={HighlightedGoodPatchMutation}
		entityName={ENTITY.HIGHLIGHTED_GOOD}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		fields={['highlightReasons', 'claim1', 'claim2', 'claim3']}
	/>
);
