import gql from 'graphql-tag';

export const updateIfnPlatformStatsMutation = gql`
	mutation UpdateIfnPlatformStatsMutation($date: DateTime!) {
		updateIfnPlatformStats(date: $date) {
			code
			message
		}
	}
`;
