import React from 'react';
import { MagazineContentDetailQuery } from '../graphql/query/MagazineContentDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/MagazineContent.json';
import { ENTITY } from '../utils/entities';

const MagazineContentDetail = ({ id }) => (
	<EntityDetailFetcher
		query={MagazineContentDetailQuery}
		entityName={ENTITY.MAGAZINE_CONTENT}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		fields={['name', 'pageNumber']}
		modal={true}
	/>
);

export { MagazineContentDetail };
