import gql from 'graphql-tag';
import {CompanyFullFragment} from "../fragments/CompanyFullFragment";


export const CompanyDetailQuery = gql`
	query CompanyDetailQuery($id: ID!) {
		entity: node(id: $id) {
			...CompanyFullFragment
			... on Company {
				managedByUsers {
					capabilities
					User {
						id
						localId
						name
						email
					}
				}
				goods {
					id
					localId
					name
					nameIta
				}
			}
		}
	}
	${CompanyFullFragment}
`;



