import gql from 'graphql-tag';
import {MediaBaseFragment} from "./MediaBaseFragment";

export const AwardCategoryFullFragment = gql`
    fragment AwardCategoryFullFragment on AwardCategory {
        id
        localId
        name
        slug
        description
        Media {
            ...MediaBaseFragment
        }
        color
        giftUrl
        special
    }
    ${MediaBaseFragment}
`;