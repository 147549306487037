import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import typeIntro from '../static/introspection/PLManufacturer.json';
import { ENTITY } from '../utils/entities';
import { PLManufacturerListQuery } from 'graphql/query/PLManufacturerListQuery';
import { ACTIONS, generateUrl } from 'utils/urls';
import PLManufacturerFilters from '../static/introspection/PLManufacturerFilterInput.json';
import { map } from 'ramda';
import { generateAsyncQuery, generatePaginatedSelectQuery } from 'utils/graphql';


const PLManufacturerList = () => (
	<EntityListFetcher
		query={PLManufacturerListQuery}
		entityName={ENTITY.PLMANUFACTURER}
		headers={[
			'company',
			'good',
		]}
		customFields={[
			{
				header: 'brand',
				field: (entity) => ({
					name: 'Brand',
					label: 'Brand',
					type: 'Entity',
					mapTo: ENTITY.BRAND,
					value: entity.good.Brand,
				}),
			}
		]}
		typeIntro={typeIntro}
		denyAdd={true}
		detailUrl={(entity) => generateUrl(
			ENTITY.GOOD,
			ACTIONS.DETAIL,
			entity.good.id,
		)}
		filtersIntro={PLManufacturerFilters}
		filterFields={['retailerName', 'foodServiceName', 'goodClassName', 'manufacturer']}
		filterTransform={map((f: any) => {
			if (f.name === 'manufacturer') {
				f.name = 'Company';
				f.entityType = 'Company';
				f.asyncQuery = generateAsyncQuery(
					generatePaginatedSelectQuery(ENTITY.COMPANY, false),
					true,
				);
			}

			return f;
		})}
		queryFiltersTransformer={function (filters) {
			filters['manufacturer'] = filters['Company'];
			delete filters['Company'];
			return filters;
		}}

	/>
);

export { PLManufacturerList };
