import gql from 'graphql-tag';

export const BrandSelectQuery = gql`
	query BrandSelectQuery($filter: BrandFilterInput) {
       choices: brands (filter: $filter) {
			  edges {
					node {
						 id
						 localId
						 name
						 Company {
						 	id
							localId
							  name
						 }
					}
			  }
			  total
		 }
	}
`;