import React from 'react';
import { FrequentSearchCreateMutation } from '../graphql/mutations/FrequentSearchCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/FrequentSearch.json';
import inputTypeIntro from '../static/introspection/FrequentSearchCreateInput.json';
import { FrequentSearchEditQuery } from '../graphql/query/FrequentSearchEditQuery';
import { ENTITY } from '../utils/entities';
import { editorialSectionsToTree } from '../utils/tree';
import { FrequentSearchCreateQuery } from '../graphql/query/FrequentSearchCreateQuery';
import { graphqlQuery } from '../utils/graphql';
import { fastTransformer, refactorInfo, toTree } from '../utils/form';

export const FrequentSearchCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={
			id
				? graphqlQuery(FrequentSearchEditQuery, { id })
				: graphqlQuery(FrequentSearchCreateQuery)
		}
		create={true}
		clone={!!id}
		id={id}
		mutation={FrequentSearchCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.FREQUENT_SEARCH}
		fields={[
			'collection',
			'targetSites',
			'name',
			'nameIta',
			'akas',
			'goodClasses',
			'plProducer',
			'highlightReasons',
			'awardFinalPositions',
			'textSearch',
			'reveneueRanges',
			'pricePositionings',
			'awards',
			'goodFeatureCertifications',
			'geoIndications',
			'companyCertifications',
			'fairs',
			'stateProvinces',
		]}
		entitiesToFields={(props) => ({
			EditorialSections: props.editorialSectionTree,
		})}
		transformer={(data, entity, listsByType) => {
			data.fields = fastTransformer({
				goodClasses: (f) => {
					if (f.type !== 'AsyncChoices') {
						refactorInfo(f, 'AsyncChoices');
						return f;
					}
					
					return toTree(
						f,
						false,
						editorialSectionsToTree(listsByType.EditorialSections),
					);
				},
			})(data.fields);
			return data;
		}}
	/>
);
