import gql from 'graphql-tag';
import {MagazineCollectionFullFragment} from "../fragments/MagazineCollectionFullFragment";

export const MagazineCollectionDetailQuery = gql`
	query MagazineCollectionDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...MagazineCollectionFullFragment
		}
	}
	${MagazineCollectionFullFragment}
`;


