import gql from 'graphql-tag';
import {ObjectHistoryFullFragment} from "../fragments/ObjectHistoryFullFragment";

export const ObjectHistoryDetailQuery = gql`
	query ObjectHistoryDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...ObjectHistoryFullFragment
		}
	}
	${ObjectHistoryFullFragment}
`;


