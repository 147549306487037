import React from 'react';
import { MagazinePatchMutation } from '../graphql/mutations/MagazinePatchMutation';
import { MagazineEditQuery } from '../graphql/query/MagazineEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Magazine.json';
import inputTypeIntro from '../static/introspection/MagazinePatchInput.json';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';
import { updateEditorialSectionSelectableValues } from './MagazineCreate';

const MagazineEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(MagazineEditQuery, { id })}
		mutation={MagazinePatchMutation}
		entityName={ENTITY.MAGAZINE}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={[
			'MagazineCollection',
			'EditorialSection',
			'category',
			'publicationDate',
			'name',
			'title',
			'customHighlightDays',
			'issueNumber',
			'numOfTheYear',
			'numPages',
			'prefixPages',
			'customVisibilityType',
			'customVisibilityArgs',
			'fullUrl',
			'previewUrl',
			'dropboxPath',
			'Media',
			'previewMedia',
			'featuredImageMedia',
			// 'magazineContents',
			// 'magazineContents__inline',
		]}
		transformer={(data) => {
			data.fields.forEach((f) => {
				switch (f.name) {
					case 'MagazineCollection':
						f.afterChange = (ff, value, fields) => {
							return updateEditorialSectionSelectableValues(fields);
						}
						break;
					default:
						break;
				}
			});

			data.fields = updateEditorialSectionSelectableValues(data.fields);
			return data;
		}}
	/>
);

export { MagazineEdit };
