import gql from 'graphql-tag';
import { CompanyLevelFullFragment } from '../fragments/CompanyLevelFullFragment';

export const CompanyLevelEditQuery = gql`
	query CompanyLevelEditQuery($id: ID!) {
		entity: node(id: $id) {
			...CompanyLevelFullFragment
		}
	}
	${CompanyLevelFullFragment}
`;
