import { TargetSite } from "server-types";
import { SyncChoiceFieldType } from "types/form";

function formatDate(date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-based
	const day = String(date.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
}

function subtractMonths(numMonths) {
	const date = new Date();
	date.setMonth(date.getMonth() - numMonths);
	return formatDate(date); // Format date as 'YYYY-MM-DD'
}

function subtractYears(numYears) {
	const date = new Date();
	date.setFullYear(date.getFullYear() - numYears);
	return formatDate(date); // Format date as 'YYYY-MM-DD'
}
  

export const dateField: SyncChoiceFieldType = {
	type: 'Choices',
	name: 'dateFrom',
	label: 'Data da',
	description: {},
	validators: [],
	single: true,
    required: false,
	choices: [
		{label: 'Questo Mese', value: subtractMonths(1)}, 
		{label: 'Da Mese Scorso', value: subtractMonths(2)}, 
		{label: 'L\'ultimo Trimestre', value: subtractMonths(3)}, 
		{label: 'Quest\'anno', value: subtractYears(1)}
	],
}

export const targetSiteField: SyncChoiceFieldType = {
	type: 'Choices',
	name: 'targetSite',
	label: 'Sito',
	description: {},
	validators: [],
	single: true,
    required: false,
	choices: Object.keys(TargetSite).map(key => {return {label: TargetSite[key], value: TargetSite[key]}})
}

