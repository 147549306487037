import gql from 'graphql-tag';
import { MediaFullFragment } from '../fragments/MediaFullFragment';

export const MediaPatchMutation = gql`
	mutation MediaPatchMutation($id: ID!, $changes: MediaPatchInput!) {
		results: mediaPatch(id: $id, changes: $changes) {
			...MediaFullFragment
		}
	}
	${MediaFullFragment}
`;
