import React, { useContext } from 'react';
import {
	Badge,
	Col,
	FormFeedback,
	FormGroup,
	FormText,
	Label,
} from 'reactstrap';
import Select from 'react-select';
import { t } from '../../utils/labels';
import { FieldComponent, SyncChoiceFieldType } from '../../types/form';
import { getLocalError } from '../../utils/formErrors';
import { FormContext } from '../../utils/context/form';

export const ChoicesField: React.FC<FieldComponent<SyncChoiceFieldType>> = ({
	field: {
		label,
		value,
		name,
		additionalHelpComponent,
		single,
		choices,
		helpText,
		hidden,
		description,
		disabled,
	},
	changeHandler,
	path,
}) => {
	const formContext = useContext(FormContext);
	const error = getLocalError(path, formContext?.errors || {});

	if (value && choices.filter((choice) => choice.value == value.value).length) {
		value = choices.filter((choice) => choice.value == value.value);
	}
	return (
		<FormGroup row className={hidden ? 'hidden' : ''}>
			<Label sm={3}>{label}: </Label>
			<Col sm={9}>
				<Select
					classNamePrefix={name + '-select '}
					className={
						'input-border' + (Boolean(error) ? ' is-invalid' : '')
					}
					value={value}
					name={name}
					isMulti={!single}
					isClearable={true}
					onChange={(value) =>
						changeHandler(
							value === null ? (single ? null : []) : value,
							path,
						)
					}
					isDisabled={disabled}
					options={choices}
					disabled={disabled}
					placeholder={
						disabled
							? ''
							: t`Click to show options, start typing to filter`
					}
				/>
				{error && (
					<FormFeedback invalid>
						{t(error.message + '/choices', error.message)}
					</FormFeedback>
				)}
				<FormText color="muted">
					{helpText}
					{description.readonly && (
						<div>
							<Badge color="warning">{t`warning`}</Badge>{' '}
							{t`readonly field description`}
						</div>
					)}
					{additionalHelpComponent}
					{description.min !== undefined && (
						<div className="usability-note">
							{t`min length sync choices`}: {description.min}
						</div>
					)}
					{description.max !== undefined && (
						<div className="usability-note">
							{t`max length sync choices`}: {description.max}
						</div>
					)}
				</FormText>
			</Col>
		</FormGroup>
	);
};
