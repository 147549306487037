import gql from 'graphql-tag';
import {EuCategoryFullFragment} from "../fragments/EuCategoryFullFragment";

export const EuCategoryDetailQuery = gql`
	query EuCategoryDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...EuCategoryFullFragment
		}
	}
	${EuCategoryFullFragment}
`;


