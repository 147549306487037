import gql from 'graphql-tag';
import {MediaBaseFragment} from "./MediaBaseFragment";

export const DistributionChannelFullFragment = gql`
	fragment DistributionChannelFullFragment on DistributionChannel {
		id
		localId
		Company {
			id
			name
		}
		mode
		channels
		catalogMedia {
			...MediaBaseFragment
		}
		contactEmail
		note
		countries {
			id
			name
		}
	}
	${MediaBaseFragment}
`;
