import React from 'react';
import { EuCategoryDetailQuery } from '../graphql/query/EuCategoryDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/EuCategory.json';
import { ENTITY } from '../utils/entities';

export const EuCategoryDetail = ({ id }) => (
	<EntityDetailFetcher
		query={EuCategoryDetailQuery}
		entityName={ENTITY.EU_CATEGORY}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		fields={['name', 'HtmlTemplate']}
	/>
);

