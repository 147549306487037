import gql from 'graphql-tag';


export const CompanySelectQuery = gql`
	query CompanySelectQuery($filter: CompanyFilterInput) {
       choices: companies(filter: $filter, orderBy: name, orderDesc:false) {
           edges {
               node {
                   id
                   localId
                   name
               }
           }
           total
       }
   }
`;