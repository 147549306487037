import gql from 'graphql-tag';
import { CandidacyFullFragment } from '../fragments/CandidacyFullFragment';

export const producerAwardCandidacyDetailQuery = gql`
	query ProducerAwardCandidacyDetailQuery($id: ID!) {
		entity: node(id: $id) {
			...CandidacyFullFragment
		}
	}
	${CandidacyFullFragment}
`;
