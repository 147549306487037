import gql from 'graphql-tag';
import {TeamFullFragment} from "../fragments/TeamFullFragment";

export const TeamCreateMutation = gql`
	mutation TeamCreateMutation($values: TeamCreateInput!) {
		results: teamCreate(values: $values) {
			...TeamFullFragment
		}
	}
	${TeamFullFragment}
`;


