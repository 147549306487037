import React from 'react';
import {
	Button,
	Col,
	Form,
	FormFeedback,
	FormGroup,
	FormText,
	Input,
	Label,
} from 'reactstrap';
import { track } from '@food/tracking';
import { MediaCreateMutation } from '../graphql/mutations/MediaCreateMutation';
import { t } from '../utils/labels';
import {
	modalHandler,
	parseGraphqlError,
	sentryHandler,
} from '../utils/errors';
import { client } from '../utils/client';
import { UserData, UserDataInterface } from '@food/auth';

interface IMediaUploaderProps {
	onStartLoading: () => void;
	onError: () => void;
	onLoad: (queryResult: any) => void;
	mediaType: string;
	helpText?: string;
}

export class MediaUploader extends React.Component<IMediaUploaderProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			file: false,
			caption: null,
			fileError: false,
			captionError: false,
		};
	}

	_setFile = (event) => {
		if (event.target.files[0]) {
			this.setState({
				file: event.target.files[0],
				fileError: false,
				captionError: false,
			});
		} else {
			// ha fatto annulla, non faccio niente
		}
	};

	_changeName = (event) =>
		this.setState({
			caption: event.target.value,
			captionError: false,
		});

	_dispatchErrors = (e) => {
		if (e && e.state) {
			this.setState({
				fileError: 'file' in e.state ? e.state.file[0] : false,
				captionError: 'caption' in e.state ? e.state.caption[0] : false,
			});
		}
	};

	_upload = async (user: UserDataInterface) => {
		const { onStartLoading, onError, onLoad } = this.props;
		onStartLoading();
		const { file, caption } = this.state;
		this.setState({
			fileError: false,
			captionError: false,
		});
		try {
			const mutationResult = await client.mutate({
				mutation: MediaCreateMutation,
				variables: {
					values: {
						type: this.props.mediaType,
						Company: user.managedCompany
							? { id: user.managedCompany.Company.id }
							: undefined,
						caption,
						file,
					},
				},
			});

			onLoad(mutationResult.data.mediaCreate);
		} catch (e) {
			const errContext = { entityName: 'Media' };
			const errs = parseGraphqlError(e, errContext);
			if (
				errs &&
				errs.length === 1 &&
				errs[0].originalError &&
				errs[0].originalError.state
			) {
				this._dispatchErrors(errs[0].originalError);
			} else {
				sentryHandler(errs);
				modalHandler(errs);
				track({
					event: 'media upload error',
					originalError: e.message,
					type: this.props.mediaType,
					Company: user.managedCompany
						? { id: user.managedCompany.Company.id }
						: undefined,
					caption,
				});
			}
			onError();
		}
	};

	render() {
		const {
			fileError: fileE,
			captionError: captionE,
			caption,
			file,
		} = this.state;
		const { helpText } = this.props;

		const fileError = fileE ? fileE.type || fileE.message : false;
		const captionError = captionE ? captionE.type || captionE.message : false;

		return (
			<UserData>
				{(user) => (
					<Form>
						<FormGroup row color={fileError ? 'danger' : ''}>
							<Label sm={3} className="text-right">
								<h4>{t`mediaUpload/file`}:</h4>
							</Label>
							<Col sm={9}>
								<Input
									type="file"
									onChange={this._setFile}
									state={fileError ? 'danger' : ''}
								/>
								{fileError && (
									<section>
										<FormFeedback>
											{t(fileError + '/media/file', fileError)}
										</FormFeedback>
									</section>
								)}
								<FormText color="muted">{helpText}</FormText>
							</Col>
						</FormGroup>
						{file && (
							<section>
								<FormGroup row color={captionError ? 'danger' : ''}>
									<Label sm={3} className="text-right">
										<h4>{t`mediaUpload/caption`}:</h4>
									</Label>
									<Col sm={9}>
										<Input
											type="text"
											value={caption || ''}
											onChange={this._changeName}
											state={captionError ? 'danger' : ''}
										/>
										{captionError && (
											<section>
												<FormFeedback>
													{t(
														captionError + '/media/caption',
														captionError,
													)}
												</FormFeedback>
											</section>
										)}
									</Col>
								</FormGroup>
								<FormGroup row>
									<Col sm={{ size: 9, offset: 3 }}>
										<Button
											color="success"
											onClick={() => this._upload(user)}
										>
											<i className="fa fa-upload" /> Carica
										</Button>
									</Col>
								</FormGroup>
							</section>
						)}
					</Form>
				)}
			</UserData>
		);
	}
}
