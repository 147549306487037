import gql from 'graphql-tag';
import {FairFullFragment} from "../fragments/FairFullFragment";

export const FairPatchMutation = gql`
	mutation FairPatchMutation($id: ID!, $changes: FairPatchInput!) {
		results: fairPatch(id: $id, changes: $changes) {
			...FairFullFragment
		}
	}
	${FairFullFragment}
`;


