import gql from 'graphql-tag';
import {GoodFormatFullFragment} from "../fragments/GoodFormatFullFragment";

export const GoodFormatDetailQuery = gql`
	query GoodFormatDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...GoodFormatFullFragment
		}
	}
	${GoodFormatFullFragment}
`;


