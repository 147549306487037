import gql from 'graphql-tag';
import {MagazineContentFullFragment} from "../fragments/MagazineContentFullFragment";

export const MagazineContentEditQuery = gql`
	query MagazineContentEditQuery($id: ID!) {
		entity: node (id: $id) {
				...MagazineContentFullFragment
		  }
	}
	${MagazineContentFullFragment}
`;


