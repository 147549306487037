import gql from 'graphql-tag';

export const EuCategoryFullFragment = gql`
	fragment EuCategoryFullFragment on EuCategory {
		id
		localId
		name
		HtmlTemplate {
			id
			name
		}
	}
`;
