import gql from 'graphql-tag';
import { GoodRecallFullFragment } from 'graphql/fragments/GoodRecallFullFragment';

export const GoodRecallPatchMutation = gql`
	mutation GoodRecallPatchMutation($id: ID!, $changes: GoodRecallPatchInput!) {
		results: GoodRecallPatch(id: $id, changes: $changes) {
			...GoodRecallFullFragment
		}
	}
	${GoodRecallFullFragment}
`;


