import gql from 'graphql-tag';
import {AwardCategoryFullFragment} from "../fragments/AwardCategoryFullFragment";

export const AwardCategoryEditQuery = gql`
    query AwardCategoryEditQuery($id: ID!) {
        entity: node (id: $id) {
            ...AwardCategoryFullFragment
        }
    }
   ${AwardCategoryFullFragment}
`;


