import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Report, ReportType } from '../../components/Report';
import { candidaciesDescriptionReport } from '../../reports/candidaciesDescriptions';
import { UserData } from '@food/auth';
import { NotFoundComponent } from '../../components/404';
import { showcaseCandidatedCompanies } from '../../reports/showcaseCandidatedCompanies';
import { showcaseCandidatedProducts } from '../../reports/showcaseCandidatedProducts';
import { companiesForPlatformReport } from '../../reports/companiesForPlatform';
import { candidaciesEAN } from '../../reports/candidaciesEAN';
import { showcaseCSV } from '../../reports/showcaseCSV';
import { votantiPerCategoria } from '../../reports/votantiPerCategoria';
import { candidaciesWithoutCategory } from '../../reports/candidatureSenzaCategoria';
import { candidaciesVoteResultReport } from 'reports/candidaciesVoteResult';
import { candidaciesAllVotesReport } from 'reports/candidaciesAllVotes';
import { utentiLoggati } from 'reports/loggedUsers';
import { SimpleReport, SimpleReportType } from 'components/ReportSimple';
import { numeroUtenti } from 'reports/userNumber';
import { numeroVisite } from 'reports/visitNumber';

export const reports: {
	[key: number]: ReportType;
} = {
	1: candidaciesDescriptionReport,
	2: showcaseCandidatedCompanies,
	3: showcaseCandidatedProducts,
	4: companiesForPlatformReport,
	5: candidaciesEAN,
	6: showcaseCSV,
	7: votantiPerCategoria,
	8: candidaciesWithoutCategory,
	9: candidaciesVoteResultReport,
	10: candidaciesAllVotesReport,
};

export const simpleReports: {
	[key: number]: SimpleReportType;
} = {
	11: utentiLoggati,
	12: numeroUtenti,
	13: numeroVisite,
}

type ReportConfiguratorProps = RouteComponentProps<{ id: string }>;

export const ReportConfigurator: React.FC<ReportConfiguratorProps> = ({
	match: {
		params: { id },
	},
}) => (
	<UserData>
		{({ isInternal }) =>
			isInternal ? simpleReports[id] ? <SimpleReport {...simpleReports[id]} /> : <Report {...reports[id]} /> : <NotFoundComponent />
		}
	</UserData>
);
