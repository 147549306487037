import gql from 'graphql-tag';
import {BrandFullFragment} from "../fragments/BrandFullFragment";


export const BrandDetailQuery = gql`
    query BrandDetailQuery($id: ID!) {
        entity: node (id: $id) {
            ...BrandFullFragment
        }
        entityDescription: __type(name: "Brand") {
            description
        }
    }
	${BrandFullFragment}
`;



