import React from 'react';
import { assoc } from 'ramda';
import { CompanyLevelCreateMutation } from '../graphql/mutations/CompanyLevelCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/CompanyLevel.json';
import inputTypeIntro from '../static/introspection/CompanyLevelCreateInput.json';
import { CompanyLevelEditQuery } from '../graphql/query/CompanyLevelEditQuery';
import { toRefInput } from '../utils/dataTrasform';
import { ENTITY } from '../utils/entities';
import { generateAsyncQuery, graphqlQuery } from '../utils/graphql';
import { CompanyBadgeSelectQuery } from '../graphql/query/CompanyBadgeSelectQuery';
import { fastTransformer, toHidden } from '../utils/form';
import {
	assign,
	generateFakeAsyncQuery,
	transformerHandler,
} from '../utils/misc';
import { EntityCreateModal } from '../types/entities';
import { Company, CompanyLevel } from '../server-types';
import { formatStr } from '../utils/validation';

export const CompanyLevelCreate: React.FC<EntityCreateModal<
	CompanyLevel,
	Company
>> = ({ id, onSubmit, originalEntity }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(CompanyLevelEditQuery, { id }) : null}
		onSubmit={onSubmit}
		create={true}
		clone={false}
		modal={true}
		mutation={CompanyLevelCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.COMPANY_LEVEL}
		fields={[
			'Company',
			'targetSite',
			'companyBadges',
			'startDate',
			'expirationDate',
			'level',
			'description',
			'descriptionLong',
			'videoUrl',
			'layout',
			'website',
			'newsPath',
		]}
		transformer={transformerHandler(
			fastTransformer({
				Company: toHidden(toRefInput(originalEntity)),
				targetSite: assoc('afterChange', (field, value, fields) =>
					fields.map((f) => {
						if (f.name === 'companyBadges') {
							f.disabled = !value;
							if (value) {
								f.asyncQuery = generateAsyncQuery(
									CompanyBadgeSelectQuery,
									true,
									(s: string) => ({
										filter: {
											name: s,
											targetSite: value.value,
										},
									}),
								);
								f.value = [];
							}
						}

						return f;
					}),
				),
				companyBadges: assign({
					disabled: true,
					value: [],
					asyncQuery: generateFakeAsyncQuery([]),
				}),
				newsPath: (f) => {
					// /company-brand-product/SLUG/
					const regex = '/company-brand-product/[a-zA-Z-_.]+/';
					return {
						...f,
						validators: [...f.validators, formatStr(regex)],
					};
				},
			}),
		)}
	/>
);
