import gql from 'graphql-tag';


export const GoodFeatureCertificationFullFragment = gql`
	fragment GoodFeatureCertificationFullFragment on GoodFeatureCertification {
		id
		localId
		name
		nameIta
		akas
		website
	}

`;
