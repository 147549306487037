import React from 'react';
import { ACTIONS, generateUrl } from '../utils/urls';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { t } from '../utils/labels';
import { ENTITY } from '../utils/entities';

export const HistoryLink = ({ id }) => (
	<Link
		to={generateUrl(ENTITY.OBJECT_HISTORY, ACTIONS.LIST) + '?Object=' + id}
	>
		<Button color="info" className="margin-right">
			<i className="fa fa-external-link-square" /> {t`View updates`}
		</Button>
	</Link>
);
