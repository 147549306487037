import React from 'react';
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { UserData } from '@food/auth';
import { switchManaged } from '../utils/switch';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { parseGraphqlError, sentryHandler } from '../utils/errors';

const otherManagedQuery = gql`
	query OtherManagedQuery {
		currentUser {
			id
			managedByUsers(currentOnly: false) {
				id
				capabilities
				Company {
					id
					name
					billingBranch {
						id
						Country {
							id
							code
						}
					}
				}
			}
		}
	}
`;

export class Navbar extends React.Component<any, any> {
	state = {
		userDropdownIsOpen: false,
	};

	_toggleUserDropdown = () => {
		this.setState({
			userDropdownIsOpen: !this.state.userDropdownIsOpen,
		});
	};

	render() {
		return (
			<UserData>
				{({ userData, managedCompany }) => {
					let loginName =
						userData.firstName || userData.lastName
							? userData.firstName + ' ' + userData.lastName
							: userData.email;
					if (managedCompany) {
						loginName = `${loginName} (${managedCompany.Company.name})`;
					}

					return (
						<Query<any, any> query={otherManagedQuery}>
							{({ error, loading, data }) => {

								if (error) {
									sentryHandler(parseGraphqlError(error));
									return null;
								}

								if (loading) {
									return null;
								}
								
								const otherManagedCompanies = data.currentUser.managedByUsers
									.filter((m) => m.Company)
									.filter(
										(m) =>
											managedCompany &&
											managedCompany.Company.id !==
												m.Company.id,
									);

								return (
									<header className="topbar">
										<nav className="navbar top-navbar navbar-expand-md navbar-light">
											<div className="navbar-header" />
											<div className="navbar-collapse">
												<ul className="navbar-nav mr-auto mt-md-0 " />
												<div className="pull-right">
													<Dropdown
														isOpen={this.state.userDropdownIsOpen}
														toggle={this._toggleUserDropdown}
													>
														<DropdownToggle caret={true}>
															{loginName}{' '}
															<i className="fa fa-caret-down" />
														</DropdownToggle>
														<DropdownMenu right>
															{otherManagedCompanies.length >
																0 && (
																<>
																	<DropdownItem header>
																		Altri profili aziendali:
																	</DropdownItem>
																	{otherManagedCompanies.map(
																		(managed) => (
																			<DropdownItem
																				key={managed.id}
																				onClick={() =>
																					switchManaged(
																						managed
																							.Company
																							.id,
																					)
																				}
																			>
																				{
																					managed.Company
																						.name
																				}
																			</DropdownItem>
																		),
																	)}
																	<DropdownItem divider />
																</>
															)}
															<Link to="/logout">
																<DropdownItem>
																	<i className="fa fa-user" />{' '}
																	Logout
																</DropdownItem>
															</Link>
														</DropdownMenu>
													</Dropdown>
												</div>
											</div>
										</nav>
									</header>
								);
							}}
						</Query>
					);
				}}
			</UserData>
		);
	}
}
