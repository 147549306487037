import React from 'react';
import { FormGroup, Col, Label } from 'reactstrap';
import { IFieldROProps } from '../EntityDetailView';
import { RichTextFieldType } from '../../types/form';

export const RichTextFieldRO: React.FC<IFieldROProps<RichTextFieldType>> = ({
	field: { label, value },
}) => (
	<FormGroup row>
		<Label sm={12}>{label}: </Label>
		<Col sm={12}>
			<div dangerouslySetInnerHTML={{ __html: value }} />
		</Col>
	</FormGroup>
);
