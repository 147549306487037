import gql from 'graphql-tag';
import { AwardInvitedCompanyFullFragment } from '../fragments/AwardInvitedCompanyFullFragment';

export const awardInvitedCompanyPatchMutation = gql`
	mutation AwardInvitedCompanyPatchMutation(
		$id: ID!
		$changes: AwardInvitedCompanyPatchInput!
	) {
		results: awardInvitedCompanyPatch(id: $id, changes: $changes) {
			...AwardInvitedCompanyFullFragment
		}
	}
	${AwardInvitedCompanyFullFragment}
`;


