import React from 'react';
import { fairParticipationPatchMutation } from '../graphql/mutations/FairParticipationPatchMutation';
import { fairParticipationEditQuery } from '../graphql/query/FairParticipationEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/FairParticipation.json';
import inputTypeIntro from '../static/introspection/FairParticipationPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { Company, Fair, FairParticipation } from '../server-types';
import { fairParticipationTransformer } from '../utils/entities/fairParticipation';
import { EntityEditModal } from '../types/entities';

export const FairParticipationEdit: React.FC<EntityEditModal<
	FairParticipation,
	Company | Fair
>> = ({ id, originalEntity, onSubmit }) => (
	<EntityEditFetcher
		id={id}
		modal={true}
		fetcher={graphqlQuery(fairParticipationEditQuery, { id })}
		mutation={fairParticipationPatchMutation}
		entityName={ENTITY.FAIR_PARTICIPATION}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		fields={['Fair', 'Company', 'pavilion', 'stand', 'note']}
		onSubmit={onSubmit}
		transformer={fairParticipationTransformer(originalEntity)}
	/>
);
