import React, { useContext, useEffect, useState } from 'react';
import {FieldComponent, WordpressPostFieldType} from '../../types/form';
import { useDebounce } from '../../utils/hooks';
import {
	Badge,
	Col,
	FormFeedback,
	FormGroup,
	FormText,
	Input,
	Label,
	Row,
} from 'reactstrap';
import { t } from '../../utils/labels';
import { getLocalError } from '../../utils/formErrors';
import { FormContext } from '../../utils/context/form';
import { fetchLaunchData, fetchWordpressData } from '../../utils/validation';
import { registerClass } from '@food/css-manager';
import { isNil } from 'ramda';

type IWordpressPostFieldProps = FieldComponent<WordpressPostFieldType>;

const marginTop = registerClass(() => `margin-top: 1rem;`);

// Questo componente renderizza diversi tipi di campi, i quali pero' seguono politiche differenti di estrazione dati
// Devo selezionarle a seconda del loro tipo
const getExtractor = (type: string) => {
	if (type === 'WordpressPost') {
		return fetchWordpressData;
	} else if (type === 'Launch') {
		return fetchLaunchData;
	}

	throw new Error('Unsupported type: ' + type);
};

export const WordpressPostField: React.FC<IWordpressPostFieldProps> = ({
	field,
	changeHandler,
	path,
}) => {
	const { errors, touched } = useContext(FormContext);
	const { value } = field;
	const [url, setUrl] = useState(value || '');
	const [preview, setPreviewResult] = useState(null);
	const debouncedUrl = useDebounce(url, 500);
	const error = getLocalError(path, errors);

	useEffect(() => {
		if (debouncedUrl !== '' || touched.includes(path)) {
			changeHandler(debouncedUrl, path);
		}
	}, [debouncedUrl]);

	useEffect(() => {
		if (value && !error) {
			const extractor = getExtractor(field.type);
			extractor(value).then((data) => {
				setPreviewResult(data[0])
			});
		}
	}, [value, error]);
	
	const bodyLens = field.type === 'WordpressPost' ? 'excerpt' : 'content';
	
	return (
		<FormGroup
			row
			color={error ? 'danger' : ''}
			className={field.hidden ? 'hidden' : ''}
		>
			<Label sm={3}>{field.label}: </Label>
			<Col sm={9}>
				<Input
					name={field.name}
					value={url}
					onChange={(event) => setUrl(event.target.value)}
					state={error ? 'danger' : ''}
					disabled={field.disabled}
					invalid={Boolean(error)}
				/>
				<FormText color="muted">
					{field.helpText}
					{field.description.readonly && (
						<div>
							<Badge color="warning">{t`warning`}</Badge>{' '}
							{t`readonly field description`}
						</div>
					)}
					{error && (
						<section>
							<FormFeedback>{t(error.message)}</FormFeedback>
						</section>
					)}
				</FormText>
			</Col>
			{!isNil(preview) && (
				<Col sm={{ size: 9, offset: 3 }} className={marginTop}>
					<Row>
						<Col sm={3}>
							<img
								className={'wordpress-post-image-preview'}
								src={
									preview._embedded['wp:featuredmedia'][0].source_url
								}
								alt={'post featured image'}
							/>
						</Col>
						<Col sm={9}>
							<Row>
								<Col sm={12}>
									<h3
										dangerouslySetInnerHTML={{
											__html: preview.title.rendered,
										}}
									/>
								</Col>
							</Row>
							<Row>
								<Col
									sm={12}
									dangerouslySetInnerHTML={{
										__html: preview[bodyLens].rendered,
									}}
								/>
							</Row>
						</Col>
					</Row>
				</Col>
			)}
		</FormGroup>
	);
};
