import gql from 'graphql-tag';
import {GeoIndicationFullFragment} from "../fragments/GeoIndicationFullFragment";

export const GeoIndicationEditQuery = gql`
	query GeoIndicationEditQuery($id: ID!) {
		entity: node (id: $id) {
				...GeoIndicationFullFragment
		  }
	}
	${GeoIndicationFullFragment}
`;


