import React, { useContext } from 'react';
import {
	FormGroup,
	Input,
	Label,
	Col,
	FormFeedback,
	FormText,
	Badge,
} from 'reactstrap';
import { t } from '../../utils/labels';
import { FieldComponent, IntegerFieldType } from '../../types/form';
import { getLocalError } from '../../utils/formErrors';
import { FormContext } from '../../utils/context/form';

export const IntField: React.FC<FieldComponent<IntegerFieldType>> = ({
	field: { description, helpText, label, name, value, disabled, hidden },
	changeHandler,
	path,
}) => {
	const formContext = useContext(FormContext);
	const error = getLocalError(path, formContext?.errors || {});

	return (
		<FormGroup row color={error ? 'danger' : ''} className={hidden ? 'hidden' : ''}>
			<Label sm={3}>{label}: </Label>
			<Col sm={9}>
				<Input
					name={name}
					value={
						value !== null && value !== undefined ? value.toString() : ''
					}
					onChange={(event) =>
						changeHandler(
							event.target.value !== '' ? event.target.value : null,
							path,
						)
					}
					invalid={Boolean(error)}
					disabled={disabled}
				/>
				{error && (
					<FormFeedback>
						{t(error.message + '/number', error.message)}
					</FormFeedback>
				)}
				<FormText color="muted">
					{helpText}
					{description.readonly && (
						<div>
							<Badge color="warning">{t`warning`}</Badge>{' '}
							{t`readonly field description`}
						</div>
					)}
					{description.min === undefined ? (
						''
					) : (
						<div className="usability-note">
							{t`min length integer`}: {description.min}
						</div>
					)}
					{description.max !== undefined && (
						<div className="usability-note">
							{t`max length integer`}: {description.max}
						</div>
					)}
				</FormText>
			</Col>
		</FormGroup>
	);
};
