import gql from 'graphql-tag';

export const AwardInvitedUserFullFragment = gql`
	fragment AwardInvitedUserFullFragment on AwardInvitedUser {
		id
		localId
		Award {
			id
			name
			edition
			year
		}
		User {
			id
			name
			email
		}
		roles
	}
`;
