import gql from 'graphql-tag';
import {TeamFullFragment} from "../fragments/TeamFullFragment";

export const TeamDetailQuery = gql`
	query TeamDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...TeamFullFragment
		}
	}
	${TeamFullFragment}
`;


