import React from 'react';
import { AwardInvitedUserDetailQuery } from '../graphql/query/AwardInvitedUserDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/AwardInvitedUser.json';
import { ENTITY } from '../utils/entities';
import { EntityDetailModal } from '../types/entities';
import { Award } from '../server-types';

export const AwardInvitedUserDetail: React.FC<EntityDetailModal<Award>> = ({
	id,
	onDelete,
}) => (
	<EntityDetailFetcher
		id={id}
		onDelete={onDelete}
		query={AwardInvitedUserDetailQuery}
		entityName={ENTITY.AWARD_INVITED_USER}
		modal={true}
		typeIntro={typeIntro}
		clonable={false}
		fields={['User', 'roles']}
	/>
);
