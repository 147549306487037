import gql from 'graphql-tag';
import {AnswerSetFullFragment} from "../fragments/AnswerSetFullFragment";

export const AnswerSetDetailQuery = gql`
	query AnswerSetDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...AnswerSetFullFragment
		}
	}
	${AnswerSetFullFragment}
`;


