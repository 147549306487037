import gql from 'graphql-tag';
import { t } from '../utils/labels';
import { always } from 'ramda';
import { ReportType } from '../components/Report';
import { nodes } from '../utils/misc';
import { generateAsyncQuery } from '../utils/graphql';
import { AwardSelectQuery } from '../graphql/query/AwardSelectQuery';
import { generateBaseField, toAsyncChoices } from '../utils/form';
import { ENTITY } from '../utils/entities';
import { InternalCapability } from '../server-types';
import moment from 'moment';


const clean = (s: string) => s.replace(';', '').replace(',', '');

export const candidaciesEAN: ReportType = {
	filename: always('EAN candidature'),
	title: 'EAN candidature',
	requirements: [InternalCapability.MarketingExtra],
	query: gql`
		query CandidaciesEANQuery(
			$after: String
			$filter: CandidacyFilterInput
			$first: Int
		) {
			connector: candidacies(after: $after, filter: $filter, first: $first) {
				edges {
					node {
						id
						localId
						Company {
							id
							localId
							billingName
						}
						goods {
							id
							localId
							nameIta
							formats {
								id
								localId
								ean
							}
						}
						AwardCategory {
							id
							name
						}
						launchDate
					}
				}
				pageInfo {
					hasNextPage
				}
			}
		}
	`,
	fields: [
		toAsyncChoices(
			ENTITY.AWARD,
			generateAsyncQuery(AwardSelectQuery, true),
			true,
			generateBaseField('Award', 'Award'),
		),
	],
	header: () => [
		t`Candidacy ID`,
		t`Company ID`,
		t`Good ID`,
		t`Format ID`,
		t`Company Billing Name`,
		t`Good Italian Name`,
		t`EAN`,
		t`Category Name`,
		t`Lanch date`,
	],
	variablesGenerator: (pre: any, values: any) => ({
		filter: {
			Award: { id: values.Award.value.id },
		},
	}),
	extractor: (data: any) =>
		nodes(data.connector)
			.reduce((result, candidacy) => {
				candidacy.goods.forEach((good) => {
					good.formats.forEach((format) =>
						result.push({
							format,
							good,
							candidacy,
							company: candidacy.Company,
							category: candidacy.AwardCategory,
							launchDate: candidacy.launchDate,
						}),
					);
				});
				return result;
			}, [])
			.filter((o) => o.format.ean),
	transformer: (e: any) => [
		e.candidacy.localId,
		e.company.localId,
		e.good.localId,
		e.format.localId,
		clean(e.company.billingName),
		clean(e.good.nameIta),
		e.format.ean,
		e.category.name,
		moment(e.launchDate).format('DD/MM/YYYY'),
	],
};
