import gql from 'graphql-tag';

export const GeoIndicationListQuery = gql`
	query GeoIndicationListQuery(
		$first: Int,
		$cursor: String,
		$orderBy: GeoIndicationOrderBy,
		$orderDesc: Boolean,
		$filter: GeoIndicationFilterInput
	) {
		entities: geoIndications(first: $first, after: $cursor, orderBy: $orderBy, orderDesc: $orderDesc, filter: $filter) {
			edges {
				node {
					id
					localId
					name
					status
					euIndication
					italianIndication
					StateProvince {
						id
						localId
						name
					}
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}

	}
`;


