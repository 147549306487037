import React from 'react';
import { AwardResultDetailQuery } from '../graphql/query/AwardResultDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/AwardResult.json';
import { ENTITY } from '../utils/entities';

export const AwardResultDetail = (props) => (
	<EntityDetailFetcher
		{...props}
		query={AwardResultDetailQuery}
		entityName={ENTITY.AWARD_RESULT}
		typeIntro={typeIntro}
		clonable={false}
		modal={true}
		fields={[
			'AwardCategory',
			'Candidacy',
			'legacyCandidacyName',
			'legacyCompanyName',
			'finalPosition',
		]}
		transformer={(data) => {
			data.fields.forEach((f) => {
				if (f.name === 'AwardCategory' && f.type === 'AsyncChoices') {
					delete f.mapToEntity;
				}
			});
			return data;
		}}
	/>
);
