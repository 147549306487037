import gql from 'graphql-tag';


export const EditorialTagFullFragment = gql`
	fragment EditorialTagFullFragment on EditorialTag {
		id
		localId
		name
		slug
	}

`;
