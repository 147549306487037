import gql from 'graphql-tag';
import {fairParticipationFullFragment} from "../fragments/FairParticipationFullFragment";

export const fairParticipationEditQuery = gql`
	query fairParticipationEditQuery($id: ID!) {
		entity: node (id: $id) {
				...fairParticipationFullFragment
		  }
	}
	${fairParticipationFullFragment}
`;


