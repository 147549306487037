import React from 'react';
import { Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { PageTitle } from '../PageTitle';
import { t } from '../../utils/labels';
// import pricing from '../../static/assets/pricing-02.png';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import {
	ERRORS,
	modalHandler,
	parseGraphqlError,
	sentryHandler,
} from '../../utils/errors';
import { NotFoundComponent } from '../404';
import { Spinner } from '../MainContentSpinner';
import moment from 'moment';
import { ENTITY } from '../../utils/entities';
import { EntityDetailFetcher } from '../EntityDetailFetcher';
import typeIntro from '../../static/introspection/Company.json';
import { HighlightedGoodEdit } from '../../containers/HighlightedGoodEdit';
import { HighlightedGoodDetail } from '../../containers/HighlightedGoodDetail';
import { HighlightedGoodCreate } from '../../containers/HighlightedGoodCreate';
import { EntityListField } from '../../containers/fields/EntityListField';
import { Link } from 'react-router-dom';
import { ACTIONS, generateUrl } from '../../utils/urls';
import { UserData } from '@food/auth';
import { Redirect } from 'react-router';
import { EntityDetailViewProps } from '../EntityDetailViewSwitch';

const littleCompanyQuery = gql`
	query LittleCompanyQuery($id: ID!) {
		entity: node(id: $id) {
			... on Company {
				id
				name
			}
		}
	}
`;

const subscriptionQuery = gql`
	query SubscriptionQuery {
		currentUser {
			id
			managedByUsers(currentOnly: false) {
				id
				Company {
					highlightedGoods {
						id
						targetSite
						Good {
							id
							name
							slug
						}
					}
					id
					name
					slug
					currentCompanyLevels {
						id
						targetSite
						startDate
						expirationDate
						level
					}
				}
			}
		}
	}
`;

const hgLevels = {
	HIDDEN: 0,
	FREE: 2,
	PAID_L1: 10,
	PAID_L2: 25,
};

const onlyFieldView: React.FC<EntityDetailViewProps> = ({
	data: { fields },
}) => {
	const field = fields[0];
	if (field.type === 'EntityList') {
		return <EntityListField field={field} />;
	}
	return null;
};

export const IFNPage: React.FC<{ platform: string }> = ({ platform }) => {
	return (
		<UserData>
			{({ loading, managedCompany, managedCompanyIsItalian }) => {
				if (loading) {
					return <>Loading...</>;
				}
				return (
					<Query<any, any> query={subscriptionQuery}>
						{({ error, loading, data, refetch }) => {
							if (error) {
								const errs = parseGraphqlError(error);
								if (
									errs.length === 1 &&
									errs[0].message === ERRORS.NOT_FOUND
								) {
									// siamo in caso di not found
									return <NotFoundComponent />;
								}
								sentryHandler(errs);
								modalHandler(errs);
								return <section />;
							}

							if (loading) {
								return <Spinner show={true} />;
							}

							// faccio un refetch forzato a causa di un bug di apollo: restituisce nessun dato ma non loading e non
							// error... solito problema di sotto-inclusione di fragments
							if (!data.currentUser) {
								refetch();
								return null;
							}

							const company = data.currentUser.managedByUsers.find(
								({ id }) => managedCompany.id === id,
							).Company;

							if (!company) {
								// TODO quando posso capitare qui?
							}
							if (!managedCompanyIsItalian) {
								return <Redirect to={'404'} />;
							}

							const levelObj = company.currentCompanyLevels.find(
								(l) => l.targetSite === platform,
							);
							const level = levelObj ? levelObj.level : 'FREE';
							const hGoods = company.highlightedGoods.filter(
								(hg) => hg.targetSite === platform,
							);

							return (
								<>
									<PageTitle
										title={t(`${platform} Platform`)}
										breadcrumbs={[
											{ label: t`home`, path: '/' },
											{
												label: t(`${platform} Platform`),
											},
										]}
									/>
									<Row>
										<Col sm={12} className="align-self-center">
											<Card>
												<CardBody>
													<Form>
														{/* nome company */}
														<FormGroup>
															<Row>
																<Col sm={4}>
																	<Label>
																		{t`Nome Company`}:
																	</Label>
																</Col>
																<Col sm={8}>{company.name}</Col>
															</Row>
														</FormGroup>
														{/* livello pagamento */}
														<FormGroup>
															<Row>
																<Col sm={4}>
																	<Label>{t`Livello`}:</Label>
																</Col>
																<Col sm={4}>
																	{t('CompanyLevel/' + level)}
																</Col>
																<Col
																	sm={4}
																	className={'text-right'}
																>
																	<a
																		href="mailto:support@italianfood.net?subject=Nascondete la mia azienda da Italianfood.net"
																		className={
																			'btn btn-secondary'
																		}
																	>{t`Nascondi`}</a>
																	<a
																		href="mailto:support@italianfood.net?subject=Voglio attivare una subscription"
																		className={
																			'btn btn-secondary spaced-left'
																		}
																	>{t`Upgrade`}</a>
																</Col>
															</Row>
														</FormGroup>
														{/* scadenza livello*/}
														{level.expirationDate && (
															<FormGroup>
																<Row>
																	<Col sm={4}>
																		<Label>
																			{t`Scadenza livello corrente`}
																			:
																		</Label>
																	</Col>
																	<Col sm={4}>
																		{level
																			? moment(
																					new Date(
																						level.expirationDate,
																					),
																			  ).format(
																					'DD/MM/YYYY',
																			  )
																			: t`Mai`}
																	</Col>
																	<Col
																		sm={4}
																		className={'text-right'}
																	>
																		<a
																			href="mailto:support@italianfood.net?subject=Voglio rinnovare la subscription"
																			className={
																				'btn btn-secondary'
																			}
																		>{t`Rinnova`}</a>
																	</Col>
																</Row>
															</FormGroup>
														)}
														<FormGroup>
															<Row>
																<Col
																	sm={12}
																	className={'text-center'}
																>
																	<a
																		target="_blank"
																		href={
																			'https://www.italianfood.net/suppliers/' +
																			company.slug
																		}
																		className={
																			'btn btn-danger btn-lg'
																		}
																	>
																		{t`go to company profile`}{' '}
																		<i
																			className={
																				'mdi mdi-home'
																			}
																		/>
																	</a>
																</Col>
															</Row>
														</FormGroup>

														<EntityDetailFetcher
															id={company.id}
															query={littleCompanyQuery}
															entityName={ENTITY.COMPANY}
															typeIntro={typeIntro}
															fields={['highlightedGoods']}
															disableCache={true}
															children={onlyFieldView}
															transformer={(data) => {
																data.fields.forEach((f) => {
																	if (
																		f.name ===
																			'highlightedGoods' &&
																		f.type === 'EntityList'
																	) {
																		f.editComponent = HighlightedGoodEdit;
																		f.detailComponent = HighlightedGoodDetail;
																		f.addComponent = (
																			props,
																		) => (
																			<HighlightedGoodCreate
																				{...props}
																			/>
																		);
																		f.props.headers = [
																			'Good',
																		];
																		f.props.customFields = [
																			{
																				header: 'Brand',
																				field: (
																					entity,
																				) => ({
																					name: 'Brand',
																					label: 'Brand',
																					type: 'Entity',
																					mapTo:
																						ENTITY.BRAND,
																					value:
																						entity.Good
																							.Brand,
																				}),
																			},
																			{
																				header:
																					'Platform link',
																				field: (
																					entity,
																				) => ({
																					name:
																						'Platform link',
																					label:
																						'Platform link',
																					type: 'Url',
																					to:
																						'https://www.italianfood.net/goods/' +
																						encodeURIComponent(
																							entity.Good
																								.slug,
																						),
																					value:
																						'Go to page',
																				}),
																			},
																		];
																		f.props.denyAdd =
																			hgLevels[level] -
																				hGoods.length <=
																			0;
																		f.props.fragment = gql`
																			fragment Fragment on HighlightedGood {
																				Good {
																					id
																					name
																					nameIta
																					slug
																					slugIta
																					Brand {
																						id
																						name
																						slug
																					}
																				}
																				targetSite
																				highlightReasons
																			}
																		`;
																		f.props.extractor = (
																			data,
																		) =>
																			data.node.highlightedGoods.filter(
																				(hg) =>
																					hg.targetSite ===
																					platform,
																			);
																		f.props.actions = [
																			{
																				name:
																					'number of highlighted goods',
																				component: (
																					<strong
																						className={
																							'margin-right'
																						}
																					>
																						(
																						{
																							hGoods.length
																						}
																						/
																						{
																							hgLevels[
																								level
																							]
																						}
																						)
																					</strong>
																				),
																			},
																		];
																	}
																});
																return data;
															}}
														/>

														<FormGroup>
															<Row>
																<Col
																	sm={12}
																	className={'text-center'}
																>
																	<Link
																		to={generateUrl(
																			ENTITY.COMPANY,
																			ACTIONS.DETAIL,
																			company.id,
																		)}
																		className={
																			'btn btn-danger btn-lg'
																		}
																	>
																		{t`Aggiorna Azienda, Brands e Partecipazione alle fiere`}{' '}
																		<i
																			className={
																				'mdi mdi-pencil'
																			}
																		/>
																	</Link>
																</Col>
															</Row>
														</FormGroup>
													</Form>
												</CardBody>
											</Card>
										</Col>
									</Row>
									{/*<Row>
										<Col sm={12} className="align-self-center">
											<Card>
												<CardBody>
													<a
														href={
															'mailto:support@italianfood.net?subject=Voglio attivare una subscription'
														}
													>
														<img
															src={pricing}
															id="pricing-image"
														/>
													</a>
												</CardBody>
											</Card>
										</Col>
									</Row>*/}
								</>
							);
						}}
					</Query>
				);
			}}
		</UserData>
	);
};
