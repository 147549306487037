import React from 'react';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/GoodRecall.json';
import { ENTITY } from '../utils/entities';
import { GoodRecallDetailQuery } from 'graphql/query/GoodRecallDetailQuery';

const GoodRecallDetail = ({ id }) => (
	<EntityDetailFetcher
		query={GoodRecallDetailQuery}
		entityName={ENTITY.GOOD_RECALL}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		fields={[
			'urlMinistero',
			'urlPdf',
			'goodCategory',
			'GoodClass',
			'Company',
			'goodBranding',
			'manufacturerName',
			'manufacturingPlantCode',
			'manufacturingPlantAddress',
			'recallReason',
			'publicationDate',
			'warnings',
			'RelatedOSA',
			'productionLot',
			'skuWeight'
		]}
		transformer={(data, entity) => {
			data.fields.forEach((f) => {

			});
			return data;
		}}
		cols={[['col-sm-7', 'col-sm-5'], ['col-sm-12'], ['col-sm-12']]}
	/>
);

export { GoodRecallDetail };
