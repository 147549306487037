import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Query } from 'react-apollo';
import { Company } from '../../server-types';
import gql from 'graphql-tag';
import { t } from '../../utils/labels';
import { cns, currySC, IFNTheme, registerClass } from '@food/css-manager';
import { ENTITY } from '../../utils/entities';

const dropDownClass = registerClass(
	(t: IFNTheme, sc) => `
	box-shadow: ${t.boxShadow(3)};
	
	img {
		width: 100px;
		margin-right: 2.rem
	}
	
	.${sc('content')} {
		display: flex;
		align-items: flex-start;
		flex-direction: row;
	}
	
	.${sc('title')} {
		color: ${t.colors.ink};
		font-size: ${t.ratios.xl}em;
		font-weight: ${t.font.weights.semibold};
		margin-bottom: .2em;
	}
	
	.${sc('role')} {
		font-weight: ${t.font.weights.semibold};
		color: ${t.colors.primary.plain};
	}
`,
	true,
);

const companyQuery = gql`
	query CompanyDropdown($id: ID!) {
		node(id: $id) {
			id
			... on Company {
				name
				goods {
					id
				}
				highlightedGoods {
					id
				}
				logoMedia {
					id
					smallThumbUrl
				}
				brands {
					id
				}
			}
		}
	}
`;

const sc = currySC(dropDownClass);

/**
 * Componente mostrato sull'over di CellRender visualizzanti entita' Company
 * @param id: string
 */
export const CompanyDropDown: React.FC<{ id: string }> = ({ id }) => (
	<Card className={cns(dropDownClass, 'fadeInUp', 'animated', 'faster')}>
		<CardBody>
			<Query<{ node: Company }, { id: string }>
				query={companyQuery}
				variables={{ id: id }}
			>
				{({ error, loading, data }) => {
					if (loading) {
						return <>...</>;
					}
					if (error) {
						return <>Error!</>;
					}

					const company = data?.node;

					if (!company) {
						return null;
					}

					return (
						<>
							<div className={sc('content')}>
								<img
									src={
										company.logoMedia &&
										company.logoMedia.smallThumbUrl
									}
									alt={company.name}
								/>
								<div>
									<div className={sc('role')}>{t(ENTITY.COMPANY)}</div>
									<div className={sc('title')}>{company.name}</div>
								</div>
							</div>
							<div>
								Prodotti presenti: {company.goods.length}
								<br />
								Prodotti in evidenza: {company.highlightedGoods.length}
								<br />
							</div>
						</>
					);
				}}
			</Query>
		</CardBody>
	</Card>
);
