import gql from 'graphql-tag';
import { DistributionChannelFullFragment } from '../fragments/DistributionChannelFullFragment';

export const DistributionChannelDetailQuery = gql`
	query DistributionChannelDetailQuery($id: ID!) {
		entity: node(id: $id) {
			...DistributionChannelFullFragment
		}
	}
	${DistributionChannelFullFragment}
`;
