import gql from 'graphql-tag';
import {HighlightedGoodFullFragment} from "../fragments/HighlightedGoodFullFragment";

export const HighlightedGoodPatchMutation = gql`
	mutation HighlightedGoodPatchMutation($id: ID!, $changes: HighlightedGoodPatchInput!) {
		results: highlightedGoodPatch(id: $id, changes: $changes) {
			...HighlightedGoodFullFragment
		}
	}
	${HighlightedGoodFullFragment}
`;


