import gql from 'graphql-tag';
import {NewsletterIssueFullFragment} from "../fragments/NewsletterIssueFullFragment";

export const NewsletterIssueDetailQuery = gql`
	query NewsletterIssueDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...NewsletterIssueFullFragment
		}
	}
	${NewsletterIssueFullFragment}
`;


