import gql from 'graphql-tag';
import {ServiceFullFragment} from "../fragments/ServiceFullFragment";

export const ServiceDetailQuery = gql`
	query ServiceDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...ServiceFullFragment
		}
	}
	${ServiceFullFragment}
`;


