import gql from 'graphql-tag';
import { GoodRecallFullFragment } from 'graphql/fragments/GoodRecallFullFragment';

export const GoodRecallCreateMutation = gql`
	mutation GoodRecallCreate($values: GoodRecallCreateInput!) {
		results: GoodRecallCreate(values: $values) {
			...GoodRecallFullFragment
		}
	}
	${GoodRecallFullFragment}
`;


