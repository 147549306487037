import React from 'react';
import { NavLink } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import { UserData, UserDataInterface } from '@food/auth';
import { MenuItem } from '../static/menu';
import { canView } from '../utils/viewRoles';
import { addToLastUsed } from '../utils/lastUsed';

interface MenuListState {
	openVoice: MenuItem | null;
}

interface MenuListProps extends RouteComponentProps<{}> {
	voices: [MenuItem];
}

class MenuList extends React.Component<MenuListProps, MenuListState> {
	constructor(props) {
		super(props);

		// controllo se devo aprire di default una voce di menu
		const activeVoice = props.voices.filter(
			(v) =>
				v.children &&
				v.children.some((c) => props.location.pathname.startsWith(c.path)),
		);

		this.state = {
			openVoice: activeVoice.length === 1 ? activeVoice[0] : null,
		};
	}

	openVoice = (voice: MenuItem) => {
		const { openVoice } = this.state;
		// se e' gia' aperto un suo genitore non devo fare niente
		if (
			!openVoice ||
			!openVoice.children ||
			openVoice.children
				.filter((c) => c.path !== undefined)
				.every((c) => c.path !== voice.path)
		) {
			this.setState({
				openVoice:
					openVoice !== null && voice.label === openVoice.label
						? null
						: voice,
			});
		}
	};

	renderMenuVoice(
		voice: MenuItem,
		user: UserDataInterface,
		openVoice: (menuItem) => void,
	) {
		const { openVoice: v } = this.state;
		const isActive = v !== null && voice.label === v.label;

		if (voice.children && voice.children.length > 0) {
			// se l'utente non puo' vedere alcun figlio della voce non la mostro!
			const atLeastOne = voice.children.some(
				(c) => c.forcedView || canView(user, c.entity),
			);
			if (atLeastOne) {
				return (
					<li
						key={voice.label}
						className={isActive ? 'active' : undefined}
					>
						<a
							className="has-arrow "
							href="#"
							aria-expanded="false"
							onClick={(event) => {
								event.preventDefault();
								openVoice(voice);
							}}
						>
							<i className={'mdi ' + voice.icon} />
							<span className="hide-menu">{voice.label}</span>
						</a>
						<ul className={'collapse' + (isActive ? ' in' : '')}>
							{voice.children.map((v) =>
								this.renderMenuVoice(v, user, openVoice),
							)}
						</ul>
					</li>
				);
			}
		} else if (voice.forcedView || canView(user, voice.entity)) {
			return (
				<li key={voice.label}>
					<NavLink
						to={voice.path}
						activeClassName="active"
						exact={voice.path === '/'}
						onClick={() => {
							addToLastUsed(voice);
							this.openVoice(voice);
						}}
					>
						<i className={'mdi ' + voice.icon} /> {voice.label}
					</NavLink>{' '}
				</li>
			);
		}

		return null;
	}

	render() {
		const { voices } = this.props;

		return (
			<UserData>
				{(user) => (
					<nav className="sidebar-nav">
						<ul>
							{voices.map((i) =>
								this.renderMenuVoice(i, user, this.openVoice),
							)}
						</ul>
					</nav>
				)}
			</UserData>
		);
	}
}

// TODO risolvere la tipizzazione con withRouter
export default withRouter(MenuList) as any;
