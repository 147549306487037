import gql from 'graphql-tag';
import {AwardCategoryFullFragment} from "../fragments/AwardCategoryFullFragment";

export const AwardCategoryDetailQuery = gql`
    query AwardCategoryDetailQuery($id: ID!) {
        entity: node (id: $id) {
            ...AwardCategoryFullFragment
        }
        entityDescription: __type(name: "AwardCategory") {
            description
        }
    }
    ${AwardCategoryFullFragment}
`;


