import gql from 'graphql-tag';
import { t } from '../utils/labels';
import { generateAsyncQuery } from '../utils/graphql';
import { ReportType } from '../components/Report';
import { AwardSelectQuery } from '../graphql/query/AwardSelectQuery';
import { formatDate, html2text, nodes } from '../utils/misc';
import { DT_PRECISION } from '../utils/list';
import { always } from 'ramda';
import { InternalCapability } from '../server-types';

const bool = (val: any) => (!!val ? 'Si' : 'No');

export const candidaciesDescriptionReport: ReportType = {
	filename: always('Candidature di un premio - dati generali'),
	title: 'Candidature di un premio - dati generali',
	requirements: [InternalCapability.MarketingExtra],
	preQuery: gql`
		query PreQueryRep1($id: ID!) {
			award: node(id: $id) {
				id
				... on Award {
					description1Label
					description2Label
					description3Label
					description4Label
					description5Label
					description6Label
					description7Label
					description8Label
					description9Label
					description10Label
				}
			}
		}
	`,
	query: gql`
		query QueryRep1(
			$after: String
			$filter: CandidacyFilterInput
			$first: Int
		) {
			connector: candidacies(after: $after, filter: $filter, first: $first) {
				edges {
					node {
						id
						name
						description1
						description2
						description3
						description4
						description5
						description6
						description7
						description8
						description9
						description10
						launchDate
						Company {
							id
							name
							sfId
							billingName
							plProducer
							logoMedia {
								id
								largeThumbUrl
								origUrl
							}
							billingBranch {
								StateProvince {
									regionIta
								}
							}
							logoPrintMedia {
								id
								origUrl
							}
							companyCertifications {
								id
								name
							}
						}
						extraMedia {
							id
							largeThumbUrl
							origUrl
						}
						featuredImageMedia {
							id
							largeThumbUrl
							origUrl
						}
						goods {
							id
							name
							slug
							description
							GoodClass {
								id
								name
								preservation
							}
							Brand {
								id
								name
								logoMedia {
									id
									largeThumbUrl
									origUrl
								}
							}
							goodFeatureCertifications {
								id
								name
							}
						}
						AwardCategory {
							id
							name
						}
					}
				}
				pageInfo {
					hasNextPage
				}
			}
		}
	`,
	fields: [
		{
			type: 'AsyncChoices',
			name: 'Award',
			label: 'Award',
			enum: false,
			description: {},
			single: true,
			asyncQuery: generateAsyncQuery(AwardSelectQuery, true),
		},
	],

	header: (pre: any) => {
		const base = [
			t`Candidacy Name`,
			t`Product Name`,
			t`Product Slug`,
			t`Product link on IFN`,
			t`Product description ENG short`,
			t`Company Name`,
			t`Company Billing Name`,
			t`Company Salesforce ID`,
			t`Company Region`,
			t`Company does PL?`, // test
			t`Company Certifications`, // test
			t`Brand Name`,
			t`Candidacy Category`,
			t`Launch date`,
			t`Media URL`,
			t`Media URL - square`,
			t`Good Class`,
			t`Good Features`,
			t`Preservation Mode`,
			t`Brand Media URL`,
			t`Brand Media URL - square`,
			t`Company Media URL`,
			t`Company Media URL - square`,
			t`Company Print Media URL`,
		];
		const { award } = pre;
		Object.keys(award)
			.filter((n) => n.startsWith('description'))
			.filter((n) => award[n])
			.forEach((n) => {
				const tmp = award[n].split('|');
				base.push(tmp[0]);
			});
		return base;
	},
	preVariablesGenerator: (values: any) => ({ id: values.Award.value.id }),
	variablesGenerator: (pre: any) => ({
		filter: {
			Award: { id: pre.award.id },
		},
	}),
	extractor: (data: any) =>
		nodes(data.connector).reduce(
			(result, candidacy) =>
				result.concat(
					candidacy.goods.map((good) => ({ ...candidacy, good })),
				),
			[],
		),
	transformer: (e: any, pre: any) => {
		const base = [
			e.name,
			e.good.name,
			e.good.slug,
			'https://www.italianfood.net/goods/' + e.good.slug,
			e.good.description,
			e.Company.name,
			e.Company.billingName,
			e.Company.sfId,
			e.Company &&
				e.Company.billingBranch &&
				e.Company.billingBranch.StateProvince &&
				e.Company.billingBranch.StateProvince.regionIta,
			bool(e.Company.plProducer),
			(e.Company.companyCertifications || []).map((c) => c.name).join(', '),
			e.good.Brand.name,
			e.AwardCategory ? e.AwardCategory.name : '',
			formatDate(e.launchDate, DT_PRECISION.DAY_BEGIN),
			e.featuredImageMedia && e.featuredImageMedia.origUrl,
			e.featuredImageMedia && e.featuredImageMedia.largeThumbUrl,
			e.good.GoodClass && e.good.GoodClass.name,
			e.good.goodFeatureCertifications &&
				e.good.goodFeatureCertifications.map((c) => c.name).join(', '),
			e.good.GoodClass && e.good.GoodClass.preservation,
			e.good.Brand.logoMedia && e.good.Brand.logoMedia.origUrl,
			e.good.Brand.logoMedia && e.good.Brand.logoMedia.largeThumbUrl,
			e.Company.logoMedia && e.Company.logoMedia.origUrl,
			e.Company.logoMedia && e.Company.logoMedia.largeThumbUrl,
			e.Company.logoPrintMedia && e.Company.logoPrintMedia.origUrl,
		];

		const { award } = pre;

		Object.keys(award)
			.filter((n) => n.startsWith('description'))
			.filter((n) => award[n])
			.forEach((n) => base.push(html2text(e[n.replace('Label', '')])));

		return base;
	},
};
