import gql from 'graphql-tag';

export const AwardWithDescriptionSelectQuery = gql`
	query AwardWithDescriptionSelectQuery($filter: AwardFilterInput) {
		choices: awards(filter: $filter, orderBy: name, orderDesc: false) {
			edges {
				node {
					id
					localId
					name
					edition
					year
					langCode
					options
					description1Label
					description2Label
					description3Label
					description4Label
					description5Label
					description6Label
					description7Label
					description8Label
					description9Label
					description10Label
					extraMediaType
				}
			}
			total
		}
	}
`;
