import gql from 'graphql-tag';

export const HighlightedGoodFullFragment = gql`
	fragment HighlightedGoodFullFragment on HighlightedGood {
		id
		localId
		claim1
		claim2
		claim3
		Good {
			id
			name
		}
		targetSite
		highlightReasons
	}
`;
