import gql from 'graphql-tag';
import { CandidacyFullFragment } from '../fragments/CandidacyFullFragment';

export const CandidacyEditQuery = gql`
	query CandidacyEditQuery($id: ID!) {
		entity: node(id: $id) {
			...CandidacyFullFragment
			... on Candidacy {
				Award {
					categories {
						name
						id
						localId
						special
					}
					id
					langCode
					maxGoodsPerCandidacy
					description1Label
					description1Max
					description2Label
					description2Max
					description3Label
					description3Max
					description4Label
					description4Max
					description5Label
					description5Max
					description6Label
					description6Max
					description7Label
					description7Max
					description8Label
					description8Max
					description9Label
					description9Max
					description10Label
					description10Max
					options
					extraMediaType
					extraMediaLabel
					
				}
			}
		}
	}
	${CandidacyFullFragment}
`;


