import gql from 'graphql-tag';
import { GoodFullFragment } from '../fragments/GoodFullFragment';

export const GoodEditQuery = gql`
	query GoodEditQuery($id: ID!) {
		entity: node(id: $id) {
			...GoodFullFragment
		}
		editorialSectionTree(EditorialBrand: GOODS4HUB) {
			id
			name
			Parent {
				id
			}
			goodClasses {
				id
				shortname
				shortnameIta
				localId
			}
		}
		brands {
			edges {
				node {
					name
					id
					localId
				}
			}
		}
	}
	${GoodFullFragment}
`;


