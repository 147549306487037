import React from 'react';
import { MagazineCollectionCreateMutation } from '../graphql/mutations/MagazineCollectionCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/MagazineCollection.json';
import inputTypeIntro from '../static/introspection/MagazineCollectionCreateInput.json';
import { MagazineCollectionEditQuery } from '../graphql/query/MagazineCollectionEditQuery';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';

const MagazineCollectionCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(MagazineCollectionEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={MagazineCollectionCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.MAGAZINE_COLLECTION}
		fields={[
			'BrandSf',
			'name',
			'featuredImageMedia',
			'description',
			'startYear',
			'highlightDays',
			'visibilityType',
			'visibilityArgs',
			'priority',
			'notInLatestIssues',
			'hasCategory',
		]}
	/>
);

export { MagazineCollectionCreate };
