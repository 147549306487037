import React from 'react';
import { UserPatchMutation } from '../graphql/mutations/UserPatchMutation';
import { UserEditQuery } from '../graphql/query/UserEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/User.json';
import inputTypeIntro from '../static/introspection/UserPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { UserData } from '@food/auth';
import { InternalCapability } from '@food/auth/build/dist/server-types';

export const UserEdit = ({ id }) => (
	<UserData>
		{({ userHasCapability }) => (
			<EntityEditFetcher
				fetcher={graphqlQuery(UserEditQuery, { id })}
				mutation={UserPatchMutation}
				entityName={ENTITY.USER}
				typeIntro={typeIntro}
				inputTypeIntro={inputTypeIntro}
				id={id}
				fields={[
					!id || userHasCapability(InternalCapability.AdminExtra)
						? 'sfId'
						: null,
					'firstName',
					'lastName',
					'role',
					'status',
					'Team',
					'internalCapabilities',
				]}
			/>
		)}
	</UserData>
);
