import React from 'react';
import { MagazineContentPatchMutation } from '../graphql/mutations/MagazineContentPatchMutation';
import { MagazineContentEditQuery } from '../graphql/query/MagazineContentEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/MagazineContent.json';
import inputTypeIntro from '../static/introspection/MagazineContentPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

const MagazineContentEdit = ({ id, onSubmit }) => (
	<EntityEditFetcher
		modal={true}
		fetcher={graphqlQuery(MagazineContentEditQuery, { id })}
		mutation={MagazineContentPatchMutation}
		entityName={ENTITY.MAGAZINE_CONTENT}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={['name', 'pageNumber']}
		onSubmit={onSubmit}
	/>
);

export { MagazineContentEdit };
