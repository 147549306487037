import React from 'react';
import { registerClass, IFNTheme, currySC } from '@food/css-manager';
import { Button, ButtonLayouts } from '@food/ui';
import { UserData } from '@food/auth';
import { t } from '../utils/labels';
import { Media } from '../server-types';
import { MediaExtended } from 'types/form';

type IMediaPreviewProps = {
	path?: string;
	item: Media | MediaExtended;
	onEdit?: (entity: any) => void;
	selectedSize?: null | string;
	changeSelectedSize?: (selectedSize: string | null) => void;
	chooseSize?: boolean | ReadonlyArray<string>
};

const styleClass = registerClass(
	(theme: IFNTheme, sc) => `
	display: flex;
	flex-grow: 1;
	align-items: center;
	
	> img {
		margin: ${theme.ratios.s}rem;
		padding: ${theme.ratios.s}rem;
		border: 1px solid ${theme.colors.primary.disabled.light};
	}
	
	.${sc('title')} {
		font-weight: ${theme.font.weights.bold};
	}

	.${sc('fields')} {
		> div {
			display: flex;

			> * {
				margin-right: 5px;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.${sc('sizeItem')} {
		display: flex;
		align-items: center;

		input {
			margin-right: 4px;
		}

		&:not(:last-child) {
			span, a {
				&:first-child {
					&:after {
						content: ',';
					}
				}
			}
		}
	}

	.${sc('sizeMessage')} {
		font-size: 0.9em;
		color: ${theme.colors.danger.plain};
		display: block!important;
	}
	
`,
);

const sc = currySC(styleClass);

export const MediaPreview: React.FC<IMediaPreviewProps> = ({
	path = new Date().getTime(),
	item: media,
	onEdit,
	selectedSize,
	changeSelectedSize,
	chooseSize
}) => {

	let sizeOptions = [
		{
			label: 'auto',
			value: null
		},
		{
			label: 'media format small',
			value: 'smallThumbUrl'
		},
		{
			label: 'media format medium',
			value: 'mediumThumbUrl'
		},
		{
			label: 'media format large',
			value: 'largeThumbUrl'
		},
		{
			label: 'media format original',
			value: 'origUrl',
			message: '<strong>Attenzione!</strong> Usa questa opzione con cautela:<br />le immagini inserite come \'originali\' devono essere ridimensionate manualmente prima di essere caricate.'
		}
	];

	if (typeof (chooseSize) === 'object') {
		sizeOptions = sizeOptions.filter((item) => {
			return chooseSize.indexOf(item.value) > -1 || item.value === null
		});
	}

	if (!media || media.id === null) return <></>;
	return (
		<div className={styleClass}>
			<img src={media.smallThumbUrl} alt={`${media.name} preview`} />
			<div className={sc('fields')}>
				<div>
					<span><span className={sc('title')}>{t`media caption`}</span>:</span>
					<span>{media.caption}</span>
					{onEdit && (
						<>
							<span>-</span>
							<Button
								label={t`edit`}
								type={'primary'}
								onClick={() => onEdit(media)}
								layout={ButtonLayouts.LINK}
							/>
						</>
					)}
				</div>
				<div>
					<span><span className={sc('title')}>{t`media original name`}</span>:</span>
					<span>{media.origFileName}</span>
				</div>
				<UserData>
					{({ isInternal }) => {
						return isInternal && (
							<>
								<div>
									<span><span className={sc('title')}>{t`media links`}</span>:</span>
									{
										sizeOptions.map((item) => {
											if (item.value === null) {
												if (chooseSize) {
													return (
														<>
															<span className={sc('sizeItem')}>
																<input type="radio" name={path + 'selectedSize'} value={item.value ? item.value : ''} checked={!selectedSize ? true : false} onChange={() => changeSelectedSize(null)} /><span>{item.label}</span>
															</span>
														</>
													);
												} else {
													return '';
												}
											} else {
												return (
													<>
														<span className={sc('sizeItem')}>
															{(chooseSize) && (
																<>
																	<input type="radio" name={path + 'selectedSize'} value={item.value} checked={selectedSize === item.value ? true : false} onChange={() => changeSelectedSize(item.value)} />
																</>
															)}
															<a
																href={media[item.value]}
																target={'_blank'}
															>{t(item.label)}</a>
														</span>
													</>
												);
											}
										})
									}
								</div>
								{
									sizeOptions.map((item) => (selectedSize === item.value && item.message && (
										<div className={sc('sizeMessage')} dangerouslySetInnerHTML={{ __html: item.message }}></div>
									)))
								}
							</>
						)
					}
					}
				</UserData>
			</div>
		</div >
	);
}
