import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { t } from '../utils/labels';

export const NotFoundComponent: React.FunctionComponent<{}> = () => (
	<Card>
		<CardBody>
			<h2>{t`Page not found`}</h2>
			<p>{t`Page not found text`}</p>
		</CardBody>
	</Card>
);
