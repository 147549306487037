import React from 'react';
import { EditorialSectionCreateMutation } from '../graphql/mutations/EditorialSectionCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/EditorialSection.json';
import inputTypeIntro from '../static/introspection/EditorialSectionCreateInput.json';
import { EditorialSectionEditQuery } from '../graphql/query/EditorialSectionEditQuery';
import { EditorialSectionCreateQuery } from '../graphql/query/EditorialSectionCreateQuery';
import { ENTITY } from '../utils/entities';
import { editorialSectionsToTree, getExpanded } from '../utils/tree';
import { toRefInput } from '../utils/dataTrasform';
import { assign, generateFakeAsyncQuery } from '../utils/misc';
import { generateAsyncQuery, graphqlQuery } from '../utils/graphql';
import { EditorialSectionSelectQuery } from '../graphql/query/EditorialSectionSelectQuery';
import { fastTransformer, refactorInfo, toTree } from '../utils/form';

export const EditorialSectionCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={
			id
				? graphqlQuery(EditorialSectionEditQuery, { id })
				: graphqlQuery(EditorialSectionCreateQuery)
		}
		create={true}
		clone={!!id}
		id={id}
		mutation={EditorialSectionCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entitiesToFields={(props) => ({
			EditorialSections: props.editorialSectionTree,
		})}
		entityName={ENTITY.EDITORIAL_SECTION}
		fields={[
			'name',
			'editorialBrand',
			'Parent',
			'color',
			'akas',
			'level',
			'styleClass',
			'iconMedia',
			'goodClasses',
		]}
		transformer={(data, entity, listsByType) => {
			data.fields = fastTransformer({
				goodClasses: (f) => {
					if (f.type !== 'AsyncChoices') {
						refactorInfo(f, 'AsyncChoices');
						return f;
					}
					const newField = toTree(
						f,
						false,
						editorialSectionsToTree(listsByType.EditorialSections),
					);

					if (f.value) {
						newField.value = f.value.map((choice) =>
							toRefInput(choice.value),
						);
						newField.expanded = getExpanded(
							listsByType.EditorialSections,
							f.value,
						);
					}
					return newField;
				},
				Parent: (f) => {
					if (f.type !== 'AsyncChoices') {
						refactorInfo(f, 'AsyncChoices');
						return f;
					}
					f.disabled = true;
					f.asyncQuery = generateFakeAsyncQuery([]);
					return f;
				},
				editorialBrand: assign({
					afterChange: (field, value, fields) =>
						fields.map((field) => {
							if (field.name === 'Parent') {
								field.value = null;
								if (value) {
									field.disabled = false;
									field.asyncQuery = generateAsyncQuery(
										EditorialSectionSelectQuery,
										true,
										(name) => ({
											filter: { name, EditorialBrand: value.value },
										}),
									);
								} else {
									field.disabled = true;
									field.asyncQuery = generateFakeAsyncQuery([]);
								}
							}
							return field;
						}),
				}),
			})(data.fields);

			return data;
		}}
	/>
);
