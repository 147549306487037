import React from 'react';
import { DataVizPatchMutation } from '../graphql/mutations/DataVizPatchMutation';
import { DataVizEditQuery } from '../graphql/query/DataVizEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/DataViz.json';
import inputTypeIntro from '../static/introspection/DataVizPatchInput.json';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';

const DataVizEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(DataVizEditQuery, { id })}
		mutation={DataVizPatchMutation}
		entityName={ENTITY.DATA_VIZ}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={[
			'targetSite',
			'name',
			'title',
			'url',
			'source',
			'editorial_section'
		]}
	/>
);

export { DataVizEdit };
