import gql from 'graphql-tag';
import { BrandFullFragment } from '../fragments/BrandFullFragment';

export const BrandCreateMutation = gql`
	mutation BrandCreateMutation($values: BrandCreateInput!) {
		results: brandCreate(values: $values) {
			...BrandFullFragment
		}
	}
	${BrandFullFragment}
`;


