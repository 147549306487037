import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { EditorialSectionListQuery } from '../graphql/query/EditorialSectionListQuery';
import typeIntro from '../static/introspection/EditorialSection.json';
import EditorialSectionFilters from '../static/introspection/EditorialSectionFilterInput.json';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';

export const EditorialSectionList = () => (
	<UserData>
		{({ isInternal }) => (
			<EntityListFetcher
				query={EditorialSectionListQuery}
				entityName={ENTITY.EDITORIAL_SECTION}
				headers={['editorialBrand', 'Parent', 'name']}
				typeIntro={typeIntro}
				filtersIntro={EditorialSectionFilters}
				filterFields={isInternal ? ['name', 'EditorialBrand'] : null}
			/>
		)}
	</UserData>
);
