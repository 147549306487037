import React from 'react';
import { GoodFormatDetailQuery } from '../graphql/query/GoodFormatDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/GoodFormat.json';
import { ENTITY } from '../utils/entities';
import { EntityDetailModal } from '../types/entities';
import { Good } from '../server-types';

export const GoodFormatDetail: React.FC<EntityDetailModal<Good>> = ({
	id,
	onDelete,
}) => (
	<EntityDetailFetcher
		id={id}
		onDelete={onDelete}
		query={GoodFormatDetailQuery}
		entityName={ENTITY.GOOD_FORMAT}
		typeIntro={typeIntro}
		clonable={false}
		modal={true}
		fields={['cuMesureUnit', 'cuMesureValue', 'cuPerSellingPack', 'packaging', 'ean', 'unitsPerBox', 'status']}
	/>
);
