import gql from 'graphql-tag';
import {GoodFeatureCertificationFullFragment} from "../fragments/GoodFeatureCertificationFullFragment";

export const GoodFeatureCertificationDetailQuery = gql`
	query GoodFeatureCertificationDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...GoodFeatureCertificationFullFragment
		}
	}
	${GoodFeatureCertificationFullFragment}
`;


