import React from 'react';
import { Col, Row, Label } from 'reactstrap';
import { formatDate } from '../../utils/misc';

export const DateFieldRO = ({ field }) => (
	<Row className={field.hidden ? 'hidden' : ''}>
		<Col sm={4}>
			<Label>{field.label}:</Label>
		</Col>
		<Col sm={8}>{formatDate(field.value, field.description.dtPrecision)}</Col>
	</Row>
);
