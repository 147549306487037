import gql from 'graphql-tag';

export const GoodFeatureCertificationListQuery = gql`
	query GoodFeatureCertificationListQuery(
		$first: Int
		$cursor: String
		$filter: SearchByNameInput
	) {
		entities: goodFeatureCertifications(
			first: $first
			after: $cursor
			filter: $filter
		) {
			edges {
				node {
					id
					name
					akas
					website
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;


