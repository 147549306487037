import gql from 'graphql-tag';

export const AwardSelectQuery = gql`
	query AwardSelectQuery($filter: AwardFilterInput) {
		choices: awards(filter: $filter, orderBy: name, orderDesc: false) {
			edges {
				node {
					id
					localId
					name
					edition
					year
					langCode
					options
					description1Label
				}
			}
			total
		}
	}
`;
