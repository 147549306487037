import gql from 'graphql-tag';
import { MediaBaseFragment } from '../fragments/MediaBaseFragment';

export const MediaListQuery = gql`
    query MediaListQuery($filter: MediaFilterInput) {
        entities: medias(filter: $filter) {
            edges {
                node {
                    ...MediaBaseFragment
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
    ${MediaBaseFragment}
`;
