import React from 'react';
import { EditorialTagDetailQuery } from '../graphql/query/EditorialTagDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/EditorialTag.json';
import { ENTITY } from '../utils/entities';

export const EditorialTagDetail = ({ id }) => (
	<EntityDetailFetcher
		query={EditorialTagDetailQuery}
		entityName={ENTITY.EDITORIAL_TAG}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		fields={['name', 'slug']}
	/>
);
