import gql from 'graphql-tag';

export const newsletterIssueSelectQuery = gql`
	query NewsletterIssueSelectQuery($filter: NewsletterIssueFilterInput) {
       choices: newsletterIssues (filter: $filter) {
			  edges {
					node {
						 id
						 localId
						 Newsletter {
							  id
							  name
						 }
					}
			  }
			  total
		 }
	}
`;