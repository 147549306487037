import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Query } from 'react-apollo';
import { User, UserRole } from '../../server-types';
import gql from 'graphql-tag';
import { t } from '../../utils/labels';
import { cns, currySC, IFNTheme, registerClass } from '@food/css-manager';
import { Link } from 'react-router-dom';
import { ACTIONS, generateUrl } from '../../utils/urls';
import { ENTITY } from '../../utils/entities';

const dropDownClass = registerClass(
	(t: IFNTheme, sc) => `
	box-shadow: ${t.boxShadow(3)};
	padding: ${t.ratios.s}rem;

	.${sc('title')} {
		color: ${t.colors.ink};
		font-size: ${t.ratios.xl}em;
		font-weight: ${t.font.weights.semibold};
		margin-bottom: .2em;
	}
	
	.${sc('role')} {
		font-weight: ${t.font.weights.semibold};
		color: ${t.colors.primary.plain};
	}
`,
	true,
);

const userQuery = gql`
	query UserDropdown($id: ID!) {
		node(id: $id) {
			id
			... on User {
				name
				role
				email
				managedByUsers {
					Company {
						id
						name
					}
					Retailer {
						id
						name
					}
				}
			}
		}
	}
`;

const sc = currySC(dropDownClass);

export const UserDropDown: React.FC<{ id: string }> = ({ id }) => (
	<Card className={cns(dropDownClass, 'fadeInUp', 'animated', 'faster')}>
		<CardBody>
			<Query<{ node: User }, { id: string }>
				query={userQuery}
				variables={{ id }}
			>
				{({ error, loading, data }) => {
					if (loading) {
						return <>...</>;
					}
					if (error) {
						return <>Error!</>;
					}

					const user = data?.node;

					if (!user) {
						return null;
					}

					const managed =
						user.managedByUsers[0] &&
						(user.managedByUsers[0].Company ||
							user.managedByUsers[0].Retailer);

					return (
						<>
							<div className={sc('role')}>{t(user.role)}</div>
							<div className={sc('title')}>{user.name}</div>
							{managed && (
								<div>
									Oggetto gestito:{' '}
									<Link
										to={generateUrl(
											managed.__typename,
											ACTIONS.DETAIL,
											managed.id,
										)}
									>
										{managed.name}
									</Link>
								</div>
							)}
							{user.role === UserRole.Internal && (
								<div>
									Email:{' '}
									<a href={'mailto:' + user.email}>{user.email}</a>
								</div>
							)}
							<div>
								Ultime operazioni:{' '}
								<Link
									to={
										generateUrl(ENTITY.OBJECT_HISTORY, ACTIONS.LIST) +
										'?User=' +
										user.id
									}
								>
									Cronologia modifiche
								</Link>
							</div>
						</>
					);
				}}
			</Query>
		</CardBody>
	</Card>
);
