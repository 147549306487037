import React, { useCallback } from 'react';
import {
	Col,
	Button,
	Form as BootstrapForm,
	Row,
	Card,
	CardBody,
} from 'reactstrap';
import { TextFilter } from './fields/TextFilter';
import { DateFilter } from './fields/DateFilter';
import { AsyncChoicesFilter } from './fields/AsyncChoicesFilter';
import { EnumFilter } from './fields/EnumFilter';
import { t } from '../utils/labels';
import { identity } from 'ramda';
import { BaseField, FieldMuter, FieldSetter, IField } from '../types/form';
import { ENTITY } from '../utils/entities';
import { Form } from './Form';
import { BooleanFilter } from './fields/BooleanFilter';
import {IntFilter} from "./fields/IntFilter";

export interface ListFilterType<B, T extends BaseField<any>> {
	path: string;
	field: T;
	size?: number;
	entityName: string;
	mutator: FieldMuter;
	search: () => void;
}

export interface IHubFilterBarProps {
	fields: ReadonlyArray<IField>;
	entityName: ENTITY;
	onSearch: (values: { [key: string]: any }) => void;
}

export const Filters: React.FC<IHubFilterBarProps> = ({
	fields,
	onSearch,
	entityName,
}) => {
	const renderSingleFilter = useCallback(
		(field: IField, mutatorFactory: FieldSetter, submit: () => void) => {
			let FilterComponent: React.FC<ListFilterType<any, any>>;
			let transformer: (value: any) => any = identity;
			
			switch (field.type) {
				case 'Date':
					FilterComponent = DateFilter;
					break;
				case 'AsyncChoices':
					FilterComponent = AsyncChoicesFilter;
					break;
				case 'Choices':
					FilterComponent = EnumFilter;
					break;
				case 'Boolean':
					FilterComponent = BooleanFilter;
					break;
				case 'Int':
					FilterComponent = IntFilter;
					break;
				default:
					FilterComponent = TextFilter;
					break;
			}

			return (
				<FilterComponent
					key={field.name}
					size={3}
					field={field}
					mutator={(val) =>
						mutatorFactory(field)(transformer(val), field.name)
					}
					path={field.name}
					entityName={entityName}
					search={submit}
				/>
			);
		},
		[],
	);

	if (fields.length === 0) {
		return null;
	}

	return (
		<Form
			fields={fields}
			onSubmit={(fields, values) => {
				onSearch(values);
			}}
		>
			{({ fields, submit, mutatorFactory }) => (
				<Row className="filters">
					<Col sm={12} className="align-self-center">
						<Card>
							<CardBody>
								<Row>
									<Col sm="12">
										<div className="action-top-right">
											<Button color="success" onClick={submit}>
												<i className="fa fa-filter" />{' '}
												{t`filter_verb`}
											</Button>
										</div>

										<h6 className="d-inline-block flex-first padding-top-20">
											{t`filters`}:
										</h6>

										<BootstrapForm inline className="form-material">
											{fields.map((f) =>
												renderSingleFilter(
													f,
													mutatorFactory,
													submit,
												),
											)}
										</BootstrapForm>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			)}
		</Form>
	);
};
