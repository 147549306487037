import gql from 'graphql-tag';
import { MediaBaseFragment } from './MediaBaseFragment';

export const FoodServiceFullFragment = gql`
	fragment FoodServiceFullFragment on FoodService {
		id
		localId
		sfId
		slug
		name
		logoMedia {
			...MediaBaseFragment
		}
		type
		dimension
		billingName
		description
		descriptionIta
		descriptionLong
		descriptionLongIta
		pecEmail
		vatNumber
		website
		Country {
			id
			localId
			name
		}
		StateProvince {
			id
			localId
			name
		}
		postalCode
		city
		address1
		address2
		lat
		lng
		managedByUsers {
			id
			localId
		}
		newsCitations {
			id
			localId
		}
		userObjectSubscriptions {
			id
			localId
		}
		userNotes {
			id
			localId
		}
		storeChains {
			id
			localId
		}
	}
	${MediaBaseFragment}
`;
