import React from 'react';
import { MagazineContentCreateMutation } from '../graphql/mutations/MagazineContentCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/MagazineContent.json';
import inputTypeIntro from '../static/introspection/MagazineContentCreateInput.json';
import { MagazineContentEditQuery } from '../graphql/query/MagazineContentEditQuery';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { fastTransformer, toHidden } from '../utils/form';
import { transformerHandler } from '../utils/misc';
import { toRefInput } from '../utils/dataTrasform';
import { EntityCreateModal } from '../types/entities';
import { Magazine, MagazineContent } from '../server-types';

const MagazineContentCreate: React.FC<EntityCreateModal<
	MagazineContent,
	Magazine
>> = ({ id, originalEntity, onSubmit }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(MagazineContentEditQuery, { id }) : null}
		create={true}
		modal={true}
		clone={!!id}
		id={id}
		onSubmit={onSubmit}
		mutation={MagazineContentCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.MAGAZINE_CONTENT}
		fields={['Magazine', 'name', 'pageNumber']}
		transformer={transformerHandler(
			fastTransformer({
				Magazine: toHidden(toRefInput(originalEntity)),
			}),
		)}
	/>
);

export { MagazineContentCreate };
