import React from 'react';
import { AwardCategoryPatchMutation } from '../graphql/mutations/AwardCategoryPatchMutation';
import { AwardCategoryEditQuery } from '../graphql/query/AwardCategoryEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/AwardCategory.json';
import inputTypeIntro from '../static/introspection/AwardCategoryPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { EntityEditModal } from '../types/entities';
import { Award, AwardCategory } from '../server-types';

export const AwardCategoryEdit: React.FC<EntityEditModal<
	AwardCategory,
	Award
>> = ({ id, onSubmit }) => (
	<EntityEditFetcher
		id={id}
		modal={true}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.AWARD_CATEGORY}
		fetcher={graphqlQuery(AwardCategoryEditQuery, { id })}
		mutation={AwardCategoryPatchMutation}
		onSubmit={onSubmit}
		fields={[
			'name',
			'slug',
			'description',
			'special',
			'giftUrl',
			'Media',
			'color',
		]}
	/>
);
