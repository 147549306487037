import gql from 'graphql-tag';

export const fairParticipationFullFragment = gql`
	fragment fairParticipationFullFragment on FairParticipation {
		id
		localId
		Fair {
			id
			name
			edition
			year
		}
		Company {
			id
			name
		}
		pavilion
		stand
		note
	}
`;
