import gql from 'graphql-tag';
import {GoodFormatFullFragment} from "../fragments/GoodFormatFullFragment";

export const GoodFormatCreateMutation = gql`
	mutation GoodFormatCreateMutation($values: GoodFormatCreateInput!) {
		results: goodFormatCreate(values: $values) {
			...GoodFormatFullFragment
		}
	}
	${GoodFormatFullFragment}
`;


