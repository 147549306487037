import React from 'react';
import { EditorialSectionDetailQuery } from '../graphql/query/EditorialSectionDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/EditorialSection.json';
import { ENTITY } from '../utils/entities';
import { transformerHandler } from '../utils/misc';
import { fastTransformer, refactorInfo } from '../utils/form';

export const EditorialSectionDetail = ({ id }) => (
	<EntityDetailFetcher
		query={EditorialSectionDetailQuery}
		entityName={ENTITY.EDITORIAL_SECTION}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		fields={[
			'editorialBrand',
			'name',
			'slug',
			'Parent',
			'color',
			'akas',
			'level',
			'styleClass',
			'iconMedia',
			'goodClasses',
		]}
		transformer={transformerHandler(
			fastTransformer({
				goodClasses: (f) => {
					if (f.type !== 'EntityList') {
						refactorInfo(f, 'EntityList');
						return f;
					}
					return {
						...f,
						type: 'AsyncChoices',
						mapToEntity: null,
						myToString: (entity) => entity.nameIta,
						asyncQuery: () => null,
						entityType: ENTITY.GOOD_CLASS,
						single: false,
					};
				},
			}),
		)}
	/>
);
