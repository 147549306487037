import React from 'react';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import { t } from '../../utils/labels';
import { ListFilterType } from '../Filters';
import { SyncChoiceFieldType } from '../../types/form';

export const EnumFilter: React.FC<ListFilterType<
	string,
	SyncChoiceFieldType
>> = ({ field, mutator, size = 3, entityName }) => {
	let value = field.value;
	if (value && value.value) {
		const labelledValue = field.choices.filter((v) => v.value == value.value);
		if (labelledValue.length) {
			value = labelledValue;
		}
	}
	return (
		<FormGroup className={`filter col-sm-${size}`}>
			<Select
				className={'full-width'}
				value={value}
				isMulti={!field.single}
				isClearable={true}
				delimiter=","
				name={field.name}
				options={field.choices}
				onChange={(val) => mutator(val, field.name)}
				placeholder={t([entityName, field.name, 'filter'].join('/'))}
			/>
		</FormGroup>
	)
};
