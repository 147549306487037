import gql from 'graphql-tag';
import {CompanyGroupFullFragment} from "../fragments/CompanyGroupFullFragment";

export const CompanyGroupCreateMutation = gql`
	mutation CompanyGroupCreateMutation($values: CompanyGroupCreateInput!) {
		results: companyGroupCreate(values: $values) {
			...CompanyGroupFullFragment
		}
	}
	${CompanyGroupFullFragment}
`;


