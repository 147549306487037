import gql from 'graphql-tag';
import {CompanyCertificationFullFragment} from "../fragments/CompanyCertificationFullFragment";

export const CompanyCertificationPatchMutation = gql`
	mutation CompanyCertificationPatchMutation($id: ID!, $changes: CompanyCertificationPatchInput!) {
		results: companyCertificationPatch(id: $id, changes: $changes) {
			...CompanyCertificationFullFragment
		}
	}
	${CompanyCertificationFullFragment}
`;


