import gql from 'graphql-tag';
import { DataVizFullFragment } from "../fragments/DataVizFullFragment";

export const DataVizEditQuery = gql`
	query DataVizEditQuery($id: ID!) {
		entity: node (id: $id) {
				...DataVizFullFragment
		  }
	}
	${DataVizFullFragment}
`;


