import gql from 'graphql-tag';
import { StoreChainFullFragment } from 'graphql/fragments/StoreChainFullFragment';

export const StoreChainEditQuery = gql`
	query StoreChainEditQuery($id: ID!) {
		editorialSectionTree(EditorialBrand: GOODS4HUB) {
            id
            name
            Parent {
                id
            }
            goodClasses {
                id
                shortname
                shortnameIta
                localId
                name
                nameIta
            }
        }
		entity: node (id: $id) {
				...StoreChainFullFragment
		  }
	}
	${StoreChainFullFragment}
`;


