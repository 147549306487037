import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import typeIntro from '../static/introspection/GoodRecall.json';
import { ENTITY } from '../utils/entities';
import { GoodRecallListQuery } from 'graphql/query/GoodRecallListQuery';
import { DT_PRECISION } from 'utils/list';

const GoodRecallList = () => (
	<EntityListFetcher
		query={GoodRecallListQuery}
		entityName={ENTITY.GOOD_RECALL}
		headers={[
			'goodBranding',
			'Company',
			'GoodClass',
			'publicationDate'
		]}
		typeIntro={typeIntro}
		transformer={(data) => {
			data.forEach((f) => {
				if (f.name === 'publicationDate') {
					f.description.dtPrecision = DT_PRECISION.MINUTE_BEGIN;
				}
			});
			return data;
		}}
	/>
);

export { GoodRecallList };
