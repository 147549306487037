import gql from 'graphql-tag';
import {RetailerFullFragment} from "../fragments/RetailerFullFragment";

export const RetailerDetailQuery = gql`
	query RetailerDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...RetailerFullFragment
		}
	}
	${RetailerFullFragment}
`;


