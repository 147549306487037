import gql from 'graphql-tag';
import {CandidacyFullFragment} from "../fragments/CandidacyFullFragment";

export const CandidacyCreateMutation = gql`
	mutation CandidacyCreateMutation($values: CandidacyCreateInput!) {
		results: candidacyCreate(values: $values) {
			...CandidacyFullFragment
		}
	}
	${CandidacyFullFragment}
`;


