import gql from 'graphql-tag';

export const DistributionChannelCreateQuery = gql`
	query DistributionChannelCreateQuery {
		countries: countries {
			edges {
				node {
					id
					name
					area
				}
			}
		}
	}
`;
