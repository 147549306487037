import gql from 'graphql-tag';
import {HighlightedGoodFullFragment} from "../fragments/HighlightedGoodFullFragment";

export const HighlightedGoodCreateMutation = gql`
	mutation HighlightedGoodCreateMutation($values: HighlightedGoodCreateInput!) {
		results: highlightedGoodCreate(values: $values) {
			...HighlightedGoodFullFragment
		}
	}
	${HighlightedGoodFullFragment}
`;


