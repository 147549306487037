import React from 'react';
import { MagazineCollectionPatchMutation } from '../graphql/mutations/MagazineCollectionPatchMutation';
import { MagazineCollectionEditQuery } from '../graphql/query/MagazineCollectionEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/MagazineCollection.json';
import inputTypeIntro from '../static/introspection/MagazineCollectionPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

const MagazineCollectionEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(MagazineCollectionEditQuery, { id })}
		mutation={MagazineCollectionPatchMutation}
		entityName={ENTITY.MAGAZINE_COLLECTION}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={[
			'BrandSf',
			'name',
			'featuredImageMedia',
			'description',
			'startYear',
			'highlightDays',
			'visibilityType',
			'visibilityArgs',
			'priority',
			'notInLatestIssues',
			'hasCategory',
		]}
	/>
);

export { MagazineCollectionEdit };
