import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { TeamListQuery } from '../graphql/query/TeamListQuery';
import typeIntro from '../static/introspection/Team.json';
import { ENTITY } from '../utils/entities';

export const TeamList = () => (
	<EntityListFetcher
		query={TeamListQuery}
		entityName={ENTITY.TEAM}
		headers={['name']}
		typeIntro={typeIntro}
	/>
);
