import React from 'react';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/GoodRecall.json';
import inputTypeIntro from '../static/introspection/GoodRecallCreateInput.json';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';
import { GoodRecallEditQuery } from 'graphql/query/GoodRecallEditQuery';
import { GoodRecallCreateMutation } from 'graphql/mutations/GoodRecallCreateMutation';
import { editorialSectionsToTree, getExpanded } from 'utils/tree';
import { toRefInput } from 'utils/dataTrasform';
import { GoodRecallCreateQuery } from 'graphql/query/GoodRecallCreateQuery';

const GoodRecallCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(GoodRecallEditQuery, { id }) : graphqlQuery(GoodRecallCreateQuery)}
		create={true}
		clone={!!id}
		id={id}
		mutation={GoodRecallCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.GOOD_RECALL}
		fields={[
			'urlMinistero',
			'urlPdf',
			'goodCategory',
			'GoodClass',
			'Company',
			'goodBranding',
			'manufacturerName',
			'manufacturingPlantCode',
			'manufacturingPlantAddress',
			'recallReason',
			'publicationDate',
			'warnings',
			'RelatedOSA',
			'productionLot',
			'skuWeight'
		]}
		entitiesToFields={(props) => ({
			EditorialSections: props.editorialSectionTree
		})}
		transformer={(data, entity, listsByType) => {
			data.fields.forEach((f: any) => {
				switch (f.name) {
					case 'GoodClass':
						delete f.asyncQuery;
						f.type = 'Tree';
						f.single = true;
						f.beforeSaveTransformer = (e) => e[0];
						f.nodes = editorialSectionsToTree(listsByType.EditorialSections);
						if (entity) {
							f.value =
								f.value && f.value.value ? [toRefInput(f.value.value)] : [];
							f.expanded = getExpanded(listsByType.EditorialSections, f.value);
						}
						break;
				}
			});
			return data;
		}}
	/>
);

export { GoodRecallCreate };
