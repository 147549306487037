import gql from 'graphql-tag';
import {MediaBaseFragment} from "./MediaBaseFragment";

export const CompanyBadgeFullFragment = gql`
	fragment CompanyBadgeFullFragment on CompanyBadge {
		id
		localId
		name
		iconMedia {
			...MediaBaseFragment
		}
		styleClass
		color
		descriptionLong
		targetSite
	}
	${MediaBaseFragment}
`;
