import React from 'react';
import { EmailDomainPatchMutation } from '../graphql/mutations/EmailDomainPatchMutation';
import { EmailDomainEditQuery } from '../graphql/query/EmailDomainEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/EmailDomain.json';
import inputTypeIntro from '../static/introspection/EmailDomainPatchInput.json';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';

export const EmailDomainEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(EmailDomainEditQuery, { id })}
		mutation={EmailDomainPatchMutation}
		entityName={ENTITY.EMAIL_DOMAIN}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		myToString={(entity) => entity.emailDomain}
		fields={[
			'emailDomain',
			'accountType',
			'action',
			'Retailer',
			'Company',
			'CompanyGroup',
			'Service',
			'FoodService',
			'Team',
			'website',
			'accountSfid',
			'note',
		]}
	/>
);
