import React from 'react';
import { range } from 'ramda';
import { Input } from 'reactstrap';

export interface PaginatorProps {
	total: number;
	slice: number;
	page: number;
	pageChanger: (page: number) => void;
}

export const Paginator: React.FC<PaginatorProps> = ({
	total,
	slice,
	page,
	pageChanger,
}) => (
	<div className="paginator">
		<Input
			type="select"
			onChange={(evt) => pageChanger(Number(evt.target.value))}
			value={page}
		>
			{range(1, Math.ceil(total / slice) + 1).map((n) => (
				<option key={n} value={n}>
					{' '}
					{slice * (n - 1) + 1} - {Math.min(slice * n, total)}
				</option>
			))}
		</Input>
	</div>
);
