import React from 'react';
import {
	Button,
	Col,
	FormFeedback,
	FormGroup,
	FormText,
	Label,
} from 'reactstrap';
import { t } from '../../utils/labels';
import { MediaPreview } from '../MediaPreview';
// import { ENTITY } from '../../utils/entities';
// import { EditEntityModal } from '../EditEntityModal';
import { FieldComponent, MediaFieldType } from '../../types/form';
import { getLocalError } from '../../utils/formErrors';
import { FormContext } from 'utils/context/form';
import { MediaModal } from '../MediaModal';
import { ViewContext } from '../../utils/EntityViewContext';
import { MediaEdit } from '../../containers/MediaEdit';
import { EntityModalStateEnum } from '../EntityModalState';

interface IMediaFieldState {
	editModalIsOpen: boolean;
	uploadModalIsOpen: boolean;
}

export class MediaFieldFood extends React.Component<
	FieldComponent<MediaFieldType>,
	IMediaFieldState
> {
	constructor(props) {
		super(props);

		this.state = {
			editModalIsOpen: false,
			uploadModalIsOpen: false,
		};
	}
	openEditModal = () =>
		this.setState({
			editModalIsOpen: true,
		});

	onChangeSelectedSize = (selectedSize) => {
		const {
			field: { value },
			path,
		} = this.props;

		this.props.changeHandler({
			media: value.media,
			selectedSize: selectedSize
		}, path);
	}

	closeEditModal = () => {
		this.setState({
			editModalIsOpen: false,
		});
	}

	toggleUploadModal = () => {
		this.setState({
			uploadModalIsOpen: !this.state.uploadModalIsOpen,
		});
	}

	select = (obj) => {
		const { path } = this.props;
		this.toggleUploadModal();
		this.props.changeHandler({
			media: obj,
			selectedSize: this.props.field.value && this.props.field.value.selectedSize
		}, path);
	};

	componentWillReceiveProps() {
		const {
			field: { value, mediaType },
			changeHandler,
			path,
		} = this.props;

		if (value && value.media && mediaType !== value.media.type) {
			changeHandler({
				media: null,
				selectedSize: value.selectedSize || null
			}, path);
		}
	}

	render() {
		const {
			field: { label, value, mediaType, helpText, disabled, chooseSize, hidden },
			path,
			changeHandler,
		} = this.props;

		const { editModalIsOpen, uploadModalIsOpen } = this.state;

		return (
			<FormContext.Consumer>
				{({ errors, usedMedias }) => {
					const error = getLocalError(path, errors);

					return (
						<FormGroup row invalid={'true'} className={hidden ? 'hidden' : ''}>
							{value && value.media && value.media.id && (
								<ViewContext.Provider
									value={{
										type: 'modal',
										mode: editModalIsOpen
											? EntityModalStateEnum.Edit
											: EntityModalStateEnum.Closed,
										changeState: () =>
											this.setState({ editModalIsOpen: false }),
									}}
								>
									<MediaEdit
										id={value.media.id}
										onSubmit={(newMedia: any) => {
											changeHandler({
												media: newMedia,
												selectedSize: value.selectedSize
											}, path);
											this.closeEditModal();
										}}
									/>
								</ViewContext.Provider>
							)}
							<Label sm={3}>{label}: </Label>
							<Col sm={9}>
								<FormText color="muted">{helpText}</FormText>
								<div className="image-container">
									{value && (value.media || value.id) && (
										<MediaPreview
											path={path}
											item={value.media ? value.media : value}
											onEdit={this.openEditModal}
											selectedSize={value.selectedSize}
											changeSelectedSize={this.onChangeSelectedSize}
											chooseSize={chooseSize}
										/>
									)}
								</div>
								<Button
									onClick={this.toggleUploadModal}
									color="success"
									className={value && value.media ? 'margin-left' : ''}
									disabled={!mediaType || disabled}
								>
									<i className="fa fa-image" />{' '}
									{value && (value.media || value.id) ? t`change media` : t`choose media`}
								</Button>
								{value && (value.media || value.id) && (
									<Button
										onClick={() => changeHandler(null, path)}
										className={'margin-left'}
										color="danger"
										disabled={!mediaType || disabled}
									>
										<i className="fa fa-times" /> {t`Remove`}
									</Button>
								)}
								<MediaModal
									selected={value && (value.media || value.id)}
									onSelect={this.select}
									mediaType={mediaType}
									helpText={helpText}
									usedMedias={usedMedias}
									open={uploadModalIsOpen}
									closeHandler={this.toggleUploadModal}
								/>
							</Col>

							<Col sm={{ size: 9, offset: 3 }}>
								{error && (
									<FormFeedback invalid={'true'} color={'danger'}>
										{t(error.message)}
									</FormFeedback>
								)}
							</Col>
						</FormGroup>
					);
				}}
			</FormContext.Consumer>
		);
	}
}
