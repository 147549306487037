import gql from 'graphql-tag';

export const mergeNodeMutation = gql`
	mutation MergeNodeMutation(
		$source: RefInputObject!
		$target: RefInputObject!
	) {
		objectsMerge(source: $source, target: $target) {
			code
			message
		}
	}
`;
