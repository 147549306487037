import gql from 'graphql-tag';
import { CouponFullFragment } from '../fragments/CouponFullFragment';

export const CouponEditQuery = gql`
	query CouponEditQuery($id: ID!) {
		entity: node(id: $id) {
			...CouponFullFragment
		}
	}
	${CouponFullFragment}
`;
