/**
 * Componente di interfaccia input per renderizzare IField di tipologia SubFormListField.
 *
 * Come il componente per SubFormField, la genesi di questo componente è abbastanza contorta e ciò giustifica in parte la sua struttura interna. Questo componente è composto da una lista "dragndroppabile" di SubForms e da una modale per poter aggiungere nuovi elementi alla lista di SubForm renderizzati. Siccome il suo valore è composto da una lista di IField il mutator del form viene appunto richiamato con IField come argomento, il ché genera poi qualche problema quando si tratta di ri-caricare lo stato del form (quando si apre, per esempio, una pagina di modifica newsletter issue). Nonostante ciò, per ora non abbiamo idee concrete di come separare meglio istanze di oggetti e stato del form.
 *
 * Abbiamo attualmente una grana nel modo in cui aggiungiamo IFields nei SubFormList: la funzione di "aggiunta", normalmente chiamata durante l'uso del form da parte dell'utente, si trova all'interno del componente e non è disaccoppiata da qualche parte; questo fa sì che nel momento in cui si apre un form con valori precaricati, ad esempio durante l'apertura di una modifica newsletter issue, la logica che carica il valore dentro ai SubFormListField sia duplicata. Probabilmente si può unificare e rendere molto più elegante, ma bisogna avere le idee un po' fresche per poterlo fare.
 */

import React, { useContext, useState } from 'react';
import {
	Card,
	CardTitle,
	Col,
	FormFeedback,
	FormGroup,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
} from 'reactstrap';
import { registerClass } from '@food/css-manager';
import { Button } from '@food/ui';
import {
	FieldComponent,
	IField,
	SubFormListFieldType,
	SubForms,
} from '../../types/form';
import { SortableList, IItemViewProps } from '../SortableList';
import { SubFormField } from './SubFormField';
import { generateSubFormField } from '../../utils/form';
import { t } from '../../utils/labels';
import { FormContext } from '../../utils/context/form';
import { getLocalError, hasErrors } from '../../utils/formErrors';
import { generateRandomID } from '../../utils/misc';

const smallBtnClass = registerClass(
	(t) => `
font-size: ${t.ratios.l}em;
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 2rem;
`,
);

// questo piccolo componente viene usato solo internamente a SubFormListField per motivi prestazioniali; fondamentalmente è SubForm con un qualche dettaglio grafico attorno
const ItemView: React.FC<IItemViewProps> = ({
	item: f,
	changeHandler,
	path,
	index,
}) => {
	const { errors } = useContext(FormContext);
	// notare come evolve il path degli IField mentre discende all'interno dell'albero di componenti React
	const newPath = path + '/' + f.name;
	const hasError = hasErrors(newPath, errors);
	return (
		<Col
			sm={10}
			key={f.name}
			className={'group-border-left' + (hasError ? ' danger' : '')}
		>
			<SubFormField
				field={f}
				changeHandler={changeHandler(f)}
				path={newPath}
				index={index}
			/>
		</Col>
	);
};

export const SubFormListField: React.FC<FieldComponent<
	SubFormListFieldType
>> = (props) => {
	const { field, changeHandler, path } = props;
	const {
		pre,
		post,
		value = [],
		label,
		choices,
		presets,
		modalMode,
		addButtonLabel,
		disabled,
		max,
	} = field;
	const { errors } = useContext(FormContext);
	const error = getLocalError(path, errors);
	const errorText = error ? error.message : '';
	const isDeep = path.split('/').length > 1;
	const [modalOpen, setModalOpen] = useState(false);
	const [addItemAtPosition, setAddItemAtPosition] = useState<boolean | number>(false);
	const possibleTypes: SubForms[] = choices.map((c) => c.value as SubForms);

	// funzione per aggiungere un'ulteriore istanza di SubForm alla lista presente
	const addVoice = React.useCallback(
		(type?: SubForms, addItemAtPosition?: boolean | number) => {
			// concettualmente parlando le varie istanze di sotto-form in una lista non hanno un nome proprio; che nome potrebbero avere i singoli blocchi di testo in una lista dinamica, tipo una newsletter issue? Allo stesso tempo, per come sono stati concepiti gli IField devono avere un nome. Si potrebbe pensare di usare la posizione che hanno all'interno della lista, ma questo richiederebbe strane operazioni di scambio valori-nome-errori una volta che il sotto-form venisse spostato ad un'altra posizione. Ho trovato più pratico assegnare ad ogni field un id pseudo casuale, il quale fa mantenere alla specifica istanza di un IField la stessa identità quando spostato in un'altra posizione.
			const name = generateRandomID();

			const types: SubForms[] = type ? [type] : possibleTypes;
			const newField = generateSubFormField(
				name,
				name,
				types,
				true,
				modalMode,
			);

			if (presets && type in presets) {
				Object.assign(newField, presets[type]);
			}

			if (addItemAtPosition !== false) {
				value.splice(addItemAtPosition, 0, newField);
				setAddItemAtPosition(false);
			} else {
				changeHandler(value.concat([newField]), path);
			}
		},
		[changeHandler],
	);

	const removeFromList = (entity: any) =>
		changeHandler(
			value.filter((f) => f.name !== entity.name),
			path,
		);

	const addToList = (index?: number) => {
		if (index >= 0) setAddItemAtPosition(index);
		if (modalMode && choices.length > 1) {
			setModalOpen(true);
		} else {
			addVoice(null, addItemAtPosition);
		}
	}

	const cloneToList = (toClone?: any, index?: number) => {
		const name = generateRandomID();

		let newField = Object.assign({}, toClone);
		newField.name = name;
		newField.label = name;

		value.splice(index, 0, newField);
		changeHandler(value, path);
	}

	// a causa della struttra interna di subform e subformlist non posso semplicemente passare il mutator standard ai SubForm utilizzati. Per mantenere l'immutabilità devo fare questa operazione un po' intricata e decisamente poco intuitiva. Onestamente non so neanche se abbia senso, visto che poi il componente Form assicura l'immutabilità. Forse sto duplicando il lavoro?
	// comunque, per non lasciarti in braghe di tela: il changeHandler modificato semplicemente va a sovrascrivere lo stato di questo IField creando una nuova lista di IField in cui sovrascrive solo quello con nome corrispondente; anche qui, fa una copia del vecchio IField con solo il valore cambiato
	const voiceChangeHandler = React.useCallback(
		(field: IField) => (singleValue: any, path: string) =>
			changeHandler(
				props.field.value.map((f) =>
					f.name === field.name ? { ...f, value: singleValue } : f,
				),
				path,
			),
		[value, changeHandler],
	);

	return (
		<>
			{pre && pre}
			<FormGroup>
				{!isDeep && (
					<Label>
						<h2>{label}</h2>
					</Label>
				)}
				{error && (
					<FormFeedback color={'danger'}>
						{t(
							field.name + '/' + errorText + '/text',
							errorText + '/text',
							errorText,
						)}
					</FormFeedback>
				)}
				{value && value.length > 0 && (
					<SortableList
						readonly={false}
						onMove={(newArr) => changeHandler(newArr, path)}
						onRemove={removeFromList}
						onAddToList={addToList}
						onClone={cloneToList}
						max={max}
						items={value}
						ordered={true}
						voiceChangeHandler={voiceChangeHandler}
						path={path}
						itemView={ItemView}
					/>
				)}
			</FormGroup>
			{(!max || (value && value.length < max)) && (
				<div className={smallBtnClass}>
					<Button
						disabled={disabled}
						type="success"
						onClick={() => addToList()}
						label={addButtonLabel || t`add block`}
					/>
				</div>
			)}
			<Modal
				isOpen={modalOpen}
				toggle={() => setModalOpen(false)}
				size={'lg'}
				zIndex={100000}
			>
				<ModalHeader>{t`add block`}</ModalHeader>
				<ModalBody>
					<Row>
						{possibleTypes.map((type) => (
							<Col sm={6} key={type}>
								<Card
									body
									className={'forced-border'}
									onClick={() => {
										setModalOpen(false);
										addVoice(type, addItemAtPosition);
									}}
								>
									<CardTitle>{t('subFormType/' + type)}</CardTitle>
								</Card>
							</Col>
						))}
					</Row>
				</ModalBody>
			</Modal>
			{post && post}
		</>
	);
};
