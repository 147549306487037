import { track } from '@food/tracking';
import * as Sentry from '@sentry/browser';

const maxRepeat = 10;
const sleep = (seconds: number) =>
	new Promise((resolve) => setTimeout(resolve, seconds * 1000));

export const retryFetch = async (
	url: RequestInfo,
	options: RequestInit,
): Promise<any> => {
	let repeatCount = 0;
	while (repeatCount++ < maxRepeat) {
		try {
			const response = await fetch(url, options);
			if (response.status !== 502 || repeatCount === maxRepeat) {
				if (repeatCount === maxRepeat) {
					track({
						event: 'max fetch repeat count reached - server error',
						url,
						options,
						response,
					});
					Sentry.withScope((scope) => {
						scope.setExtras({
							url,
							options,
							response,
						});
						Sentry.captureException(
							new Error('Max fetch repeat count reached - server error'),
						);
					});
				}

				return response;
			} else {
				await sleep(3);
			}
		} catch (e) {
			if (repeatCount === maxRepeat) {
				track({
					event: 'max fetch repeat count reached - network error',
					url,
					options,
					error: e,
				});
				Sentry.withScope((scope) => {
					scope.setExtras({
						url,
						options,
					});
					Sentry.captureException(
						new Error('Max fetch repeat count reached - network error'),
					);
				});
			} else {
				await sleep(3);
			}
		}
	}
};
