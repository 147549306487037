import React from 'react';
import { CandidacyRatingPatchMutation } from '../graphql/mutations/CandidacyRatingPatchMutation';
import { CandidacyRatingEditQuery } from '../graphql/query/CandidacyRatingEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/CandidacyRating.json';
import inputTypeIntro from '../static/introspection/CandidacyRatingPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { EntityEditModal } from '../types/entities';
import { Candidacy, CandidacyRating } from '../server-types';

export const CandidacyRatingEdit: React.FC<EntityEditModal<
	CandidacyRating,
	Candidacy
>> = ({ id, onSubmit }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(CandidacyRatingEditQuery, { id })}
		mutation={CandidacyRatingPatchMutation}
		entityName={ENTITY.CANDIDACY_RATING}
		typeIntro={typeIntro}
		modal={true}
		inputTypeIntro={inputTypeIntro}
		id={id}
		onSubmit={onSubmit}
		fields={[
			'User',
			'overall',
			'rating1',
			'rating2',
			'rating3',
			'rating4',
			'rating5',
			'comment1',
			'comment2',
			'comment3',
			'askSample',
		]}
	/>
);
