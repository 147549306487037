import { UserData } from '@food/auth';
import { mergeNodeMutation } from 'graphql/mutations/MergeNodeMutation';
import React, { useCallback, useMemo, useState } from 'react';
import {
	Button,
	Card,
	CardBody,
	Form as BootstrapForm,
	Modal,
	ModalBody,
	ModalFooter,
} from 'reactstrap';
import { InternalCapability } from 'server-types';
import { client } from 'utils/client';
import { urlToRefInput } from 'utils/dataTrasform';
import { modalHandler, parseGraphqlError, sentryHandler } from 'utils/errors';
import { generateTextField } from 'utils/form';
import { toRequired } from 'utils/misc';
import { t } from '../utils/labels';
import { Form } from './Form';
import { FieldList } from './form/FieldsList';
import { PageTitle } from './PageTitle';
import { ReportNotAllowed } from './ReportNotAllowed';

export const ObjectsMergeComponent: React.FunctionComponent<{}> = () => {
	const title = 'Merge objects';

	const [mergeState, setMergeState] = useState({
		source: undefined,
		target: undefined,
		merging: false,
		showMergeConfirm: false,
		result: undefined,
	});
	const { source, target, merging, showMergeConfirm, result } = mergeState;
	const closeConfirm = useCallback(
		() =>
			setMergeState((state) => ({
				...state,
				source: undefined,
				target: undefined,
				merging: false,
				showMergeConfirm: false,
			})),
		[mergeState],
	);

	const closeResult = useCallback(() => {
		setMergeState((state) => ({
			...state,
			result: undefined,
		}));
	}, [mergeState]);

	const mergeNodes = useCallback(async (values: Record<string, any>) => {
		try {
			setMergeState((state) => ({
				...state,
				merging: true,
			}));
			const res = await client.mutate({
				mutation: mergeNodeMutation,
				variables: { source: values.source, target: values.target },
			});
			setMergeState((state) => ({
				...state,
				source: undefined,
				target: undefined,
				merging: false,
				showMergeConfirm: false,
				result: res?.data?.objectsMerge,
			}));
		} catch (e) {
			const errs = parseGraphqlError(e);
			sentryHandler(errs);
			modalHandler(errs);
			console.error(e);
			setMergeState((state) => ({
				...state,
				merging: false,
				showMergeConfirm: false,
			}));
		}
	}, []);

	const fields = useMemo(
		() => [
			toRequired({
				...generateTextField(
					'source',
					t`objects-merge/source/label`,
					t`objects-merge/source/helpText`,
				),
				beforeSaveTransformer: urlToRefInput,
			}),
			toRequired({
				...generateTextField(
					'target',
					t`objects-merge/target/label`,
					t`objects-merge/target/helpText`,
				),
				beforeSaveTransformer: urlToRefInput,
			}),
		],
		[],
	);

	return (
		<UserData>
			{({ userData }) => {
				if (
					!userData.internalCapabilities.includes(
						InternalCapability.AdminExtra,
					)
				) {
					return <ReportNotAllowed />;
				}

				return (
					<>
						<PageTitle
							title={title}
							breadcrumbs={[
								{ label: t`home`, path: '/' },
								{
									label: t`Maintenance`,
								},
								{
									label: title,
								},
							]}
						/>
						<Modal
							isOpen={showMergeConfirm}
							toggle={merging ? undefined : closeConfirm}
							backdrop="static"
							wrapClassName="renew-modal-wrapper"
							autoFocus={false}
						>
							<ModalBody>
								<h3 className="text-center">{t`Confirm merge text?`}</h3>
							</ModalBody>
							<ModalFooter>
								<Button
									disabled={merging}
									color="secondary"
									onClick={closeConfirm}
								>
									<i className="fa fa-times" /> {t`Undo`}
								</Button>{' '}
								<Button
									disabled={merging}
									color="danger"
									onClick={() => mergeNodes({ source, target })}
								>
									<i className="fa fa-trash" /> {t`Merge`}
								</Button>
							</ModalFooter>
						</Modal>
						<Modal
							isOpen={!!result}
							toggle={closeResult}
							backdrop="static"
							wrapClassName="renew-modal-wrapper"
							autoFocus={false}
						>
							<ModalBody>
								<h3 className="text-center">{result?.code}</h3>
								<p>{result?.message}</p>
							</ModalBody>
							<ModalFooter>
								<Button color="success" onClick={closeResult}>
									<i className="fa fa-close" /> {t`Close`}
								</Button>
							</ModalFooter>
						</Modal>
						<Card>
							<CardBody>
								<Form
									fields={fields}
									onSubmit={(fields, finalValues, injectErrors) => {
										setMergeState((state) => ({
											source: finalValues.source,
											target: finalValues.target,
											merging: false,
											showMergeConfirm: true,
											result: undefined,
										}));
									}}
								>
									{({ fields, mutatorFactory, values, submit }) => (
										<BootstrapForm className="Form">
											<FieldList
												fields={fields}
												setter={mutatorFactory}
											/>
											<Button
												onClick={() => submit()}
												size="lg"
												color="success"
											>
												<i className="mdi mdi-download" />{' '}
												{t`Merge`}
											</Button>
										</BootstrapForm>
									)}
								</Form>
							</CardBody>
						</Card>
					</>
				);
			}}
		</UserData>
	);
};
