import gql from 'graphql-tag';
import { MediaBaseFragment } from './MediaBaseFragment';

export const EditorialSectionFullFragment = gql`
	fragment EditorialSectionFullFragment on EditorialSection {
		id
		localId
		editorialBrand
		name
		slug
		color
		Parent {
			id
			name
		}
		akas
		level
		styleClass
		iconMedia {
			...MediaBaseFragment
		}
		goodClasses {
			id
			name
		}
	}
	${MediaBaseFragment}
`;
