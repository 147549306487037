import { UserRole } from '../server-types';

const PERMISSIONS_LABELS = [
	'canCreateRoles',
	'canPatchRoles',
	'canDeleteRoles',
];

export interface Permission {
	canEdit?: boolean;
	canDelete?: boolean;
	canCreate?: boolean;
}

function parsePermissions(entityDescription) {
	if (!entityDescription) throw new Error('No internalCapabilities!');

	const d = JSON.parse(entityDescription);

	return PERMISSIONS_LABELS.reduce((obj, label) => {
		obj[label] = d[label];
		return obj;
	}, {});
}

const capabilityToPermission = (
	userCapabilities: string,
	capability: boolean | string,
): boolean =>
	typeof capability === 'boolean' ||
	userCapabilities.indexOf(capability) !== -1;

function calculateUserPermissions(
	userCapabilities,
	userRole: UserRole,
	rolesPermissions,
	forcedPermissions: {
		canEdit?: boolean;
		canDelete?: boolean;
		canCreate?: boolean;
	} = {},
): Permission {
	const editCapability = rolesPermissions.canPatchRoles
		? rolesPermissions.canPatchRoles[userRole]
		: false;
	const createCapability = rolesPermissions.canCreateRoles
		? rolesPermissions.canCreateRoles[userRole]
		: false;
	const deleteCapability = rolesPermissions.canDeleteRoles
		? rolesPermissions.canDeleteRoles[userRole]
		: false;

	return {
		canEdit: forcedPermissions.hasOwnProperty('canEdit')
			? forcedPermissions.canEdit
			: capabilityToPermission(userCapabilities, editCapability),
		canCreate: forcedPermissions.hasOwnProperty('canCreate')
			? forcedPermissions.canEdit
			: capabilityToPermission(userCapabilities, createCapability),
		canDelete: forcedPermissions.hasOwnProperty('canDelete')
			? forcedPermissions.canEdit
			: capabilityToPermission(userCapabilities, deleteCapability),
	};
}

export { parsePermissions, calculateUserPermissions };
