import gql from 'graphql-tag';
import {AwardInvitedCompanyFullFragment} from "../fragments/AwardInvitedCompanyFullFragment";

export const AwardInvitedCompanyDetailQuery = gql`
	query AwardInvitedCompanyDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...AwardInvitedCompanyFullFragment
		}
	}
	${AwardInvitedCompanyFullFragment}
`;


