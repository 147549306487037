import gql from 'graphql-tag';
import {GeoIndicationFullFragment} from "../fragments/GeoIndicationFullFragment";

export const GeoIndicationCreateMutation = gql`
	mutation GeoIndicationCreateMutation($values: GeoIndicationCreateInput!) {
		results: geoIndicationCreate(values: $values) {
			...GeoIndicationFullFragment
		}
	}
	${GeoIndicationFullFragment}
`;


