import React from 'react';
import { CouponPatchMutation } from '../graphql/mutations/CouponPatchMutation';
import { CouponEditQuery } from '../graphql/query/CouponEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Coupon.json';
import inputTypeIntro from '../static/introspection/CouponPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { Coupon } from '../server-types';
import { EntityEditPage } from '../types/entities';

export const CouponEdit: React.FC<EntityEditPage<Coupon>> = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(CouponEditQuery, { id })}
		mutation={CouponPatchMutation}
		entityName={ENTITY.COUPON}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={[
			'name',
			'type',
			'amount',
			'startDate',
			'expirationDate',
			'maxUsages',
			'maxUsagesPerQuote',
			'usagesCount',
			'brandSf',
			'product2Sf',
			'brandAreaSf',
		]}
	/>
);
