import React from 'react';
import { assoc, omit } from 'ramda';
import { CompanyCreateMutation } from '../graphql/mutations/CompanyCreateMutation';
import BranchIntrospection from '../static/introspection/Branch.json';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Company.json';
import inputTypeIntro from '../static/introspection/CompanyCreateInput.json';
import { CompanyEditQuery } from '../graphql/query/CompanyEditQuery';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';
import { t } from '../utils/labels';
import { fastTransformer, transform } from '../utils/form';
import { generateObjectFieldList } from '../utils/objectFields';
import { toRefInput } from '../utils/dataTrasform';
import { toRequired } from '../utils/misc';

export const CompanyCreate = ({ id }) => {
	const billingBranchFieldNames = [
		'name',
		'type',
		'vatNumber',
		'Country',
		'StateProvince',
		'website',
		'postalCode',
		'city',
		'address1',
		'address2',
	];

	return (
		<UserData>
			{(user) => (
				<EntityEditFetcher
					fetcher={id ? graphqlQuery(CompanyEditQuery, { id }) : null}
					create={true}
					clone={!!id}
					id={id}
					mutation={CompanyCreateMutation}
					typeIntro={typeIntro}
					inputTypeIntro={inputTypeIntro}
					entityName={ENTITY.COMPANY}
					beforeSaveTransformer={(data) => {
						const fieldsToDelete = billingBranchFieldNames.map(
							(s) => 'billingBranch_' + s,
						);

						data.billingBranch__inline = Object.entries(data)
							.filter(([key]) => key.startsWith('billingBranch_'))
							.reduce(
								(agg, [key, value]) => {
									const cleanKey = key.replace('billingBranch_', '');
									agg[cleanKey] = value;
									return agg;
								},
								{ Company: toRefInput(id) },
							);

						return omit(fieldsToDelete, data);
					}}
					fields={[
						user.isInternal ? 'sfId' : null,
						'industry',
						'logoMedia',
						'logoPrintMedia',
						'billingName',
						'name',
						'vatNumber',
						'ownership',
						'website',
						'companyEmail',
						'yearEstablished',
						'plProducer',
						'companyCertifications',
						'descriptionIta',
						'description',
						'descriptionLongIta',
						'descriptionLong',
						'geoIndications',
						'additionalImagesMedia',
					]}
					tabs={[
						t('generic data'),
						t([ENTITY.COMPANY, 'medias', 'label'].join('/')),
					]}
					transformer={(data) => {
						// Devo prendere i campi necessari per la creazione inline del billing branch e renderli IField[],
						// stando attento a rinominarli
						const branchCreationFields = generateObjectFieldList(
							user,
							billingBranchFieldNames,
							BranchIntrospection,
							false,
						).map((field) => {
							if (field.name === 'name') {
								field.pre = <h2>{t`Company/billingBranch/label`}</h2>;
							}
							field.name = 'billingBranch_' + field.name;
							return field;
						});

						const localTransformer = fastTransformer({
							additionalImagesMedia: assoc('tab', 1),
							billingBranch_name: toRequired,
							billingBranch_Country: toRequired,
							billingBranch_city: toRequired,
							billingBranch_address1: toRequired,
						});

						data.fields = transform(
							(fields) => fields.concat(branchCreationFields),
							localTransformer,
						)(data.fields);

						return data;
					}}
				/>
			)}
		</UserData>
	);
};
