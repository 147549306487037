import React from 'react';
import { GoodFeatureCertificationPatchMutation } from '../graphql/mutations/GoodFeatureCertificationPatchMutation';
import { GoodFeatureCertificationEditQuery } from '../graphql/query/GoodFeatureCertificationEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/GoodFeatureCertification.json';
import inputTypeIntro from '../static/introspection/GoodFeatureCertificationPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const GoodFeatureCertificationEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(GoodFeatureCertificationEditQuery, { id })}
		mutation={GoodFeatureCertificationPatchMutation}
		entityName={ENTITY.GOOD_FEATURE_CERTIFICATION}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={['name', 'nameIta', 'akas', 'website']}
	/>
);
