import gql from 'graphql-tag';
import {TeamFullFragment} from "../fragments/TeamFullFragment";

export const TeamPatchMutation = gql`
	mutation TeamPatchMutation($id: ID!, $changes: TeamPatchInput!) {
		results: teamPatch(id: $id, changes: $changes) {
			...TeamFullFragment
		}
	}
	${TeamFullFragment}
`;


