import gql from 'graphql-tag';
import {ServiceFullFragment} from "../fragments/ServiceFullFragment";

export const ServicePatchMutation = gql`
	mutation ServicePatchMutation($id: ID!, $changes: ServicePatchInput!) {
		results: servicePatch(id: $id, changes: $changes) {
			...ServiceFullFragment
		}
	}
	${ServiceFullFragment}
`;


