import React from 'react';
import { DistributionChannelCreateMutation } from '../graphql/mutations/DistributionChannelCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/DistributionChannel.json';
import inputTypeIntro from '../static/introspection/DistributionChannelCreateInput.json';
import { ENTITY } from '../utils/entities';
import { toRefInput } from '../utils/dataTrasform';
import { DistributionChannelCreateQuery } from '../graphql/query/DistributionChannelCreateQuery';
import { countriesToTree } from '../utils/tree';
import { nodes } from '../utils/misc';
import { t } from '../utils/labels';
import { Alert } from 'reactstrap';
import { graphqlQuery } from '../utils/graphql';
import { fastTransformer, refactorInfo, toHidden, toTree } from '../utils/form';
import { EntityCreateModal } from '../types/entities';
import { Company, DistributionChannel } from '../server-types';

export const DistributionChannelCreate: React.FC<EntityCreateModal<
	DistributionChannel,
	Company
>> = ({ originalEntity, onSubmit }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(DistributionChannelCreateQuery)}
		create={true}
		clone={false}
		modal={true}
		mutation={DistributionChannelCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		onSubmit={onSubmit}
		entityName={ENTITY.DISTRIBUTION_CHANNEL}
		pre={() => (
			<Alert color="warning">
				<h4
					dangerouslySetInnerHTML={{
						__html: t`create distribution channel intro`,
					}}
				/>
			</Alert>
		)}
		fields={[
			'Company',
			'mode',
			'channels',
			'countries',
			'note',
			'catalogMedia',
			'contactEmail',
		]}
		entitiesToFields={(props) => ({
			countries: props.countries,
		})}
		transformer={(data, entity, listsByType) => {
			data.fields = fastTransformer({
				Company: toHidden(toRefInput(originalEntity)),
				countries: (f) => {
					if (f.type !== 'AsyncChoices') {
						refactorInfo(f, 'AsyncChoices');
						return f;
					}
					return toTree(
						f,
						false,
						countriesToTree(nodes(listsByType.countries)),
					);
				},
			})(data.fields);

			return data;
		}}
	/>
);
