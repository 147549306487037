import gql from 'graphql-tag';
import { DataVizFullFragment } from 'graphql/fragments/DataVizFullFragment';

export const DataVizDetailQuery = gql`
	query DataVizDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...DataVizFullFragment
		}
	}
	${DataVizFullFragment}
`;


