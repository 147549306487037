import React from 'react';
import { CouponDetailQuery } from '../graphql/query/CouponDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/Coupon.json';
import { ENTITY } from '../utils/entities';
import { EntityDetailPage } from '../types/entities';
import { Coupon } from '../server-types';

export const CouponDetail: React.FC<EntityDetailPage<Coupon>> = ({ id }) => (
	<EntityDetailFetcher
		query={CouponDetailQuery}
		entityName={ENTITY.COUPON}
		id={id}
		typeIntro={typeIntro}
		clonable={true}
		fields={[
			'name',
			'code',
			// 'type',
			'amount',
			'startDate',
			'expirationDate',
			'maxUsages',
			'maxUsagesPerQuote',
			'usagesCount',
			'brandSf',
			'product2Sf',
			'brandAreaSf',
		]}
	/>
);
