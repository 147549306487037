import gql from 'graphql-tag';
import {EditorialSectionFullFragment} from "../fragments/EditorialSectionFullFragment";

export const EditorialSectionPatchMutation = gql`
	mutation EditorialSectionPatchMutation($id: ID!, $changes: EditorialSectionPatchInput!) {
		results: editorialSectionPatch(id: $id, changes: $changes) {
			...EditorialSectionFullFragment
		}
	}
	${EditorialSectionFullFragment}
`;


