import React from 'react';
import { CompanyBadgeCreateMutation } from '../graphql/mutations/CompanyBadgeCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/CompanyBadge.json';
import inputTypeIntro from '../static/introspection/CompanyBadgeCreateInput.json';
import { CompanyBadgeEditQuery } from '../graphql/query/CompanyBadgeEditQuery';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const CompanyBadgeCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(CompanyBadgeEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={CompanyBadgeCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.COMPANY_BADGE}
		fields={[
			'name',
			'iconMedia',
			'styleClass',
			'color',
			'descriptionLong',
			'targetSite',
		]}
	/>
);
