

import React, { useCallback } from 'react';
import { Tabs } from './Tabs';
import {
	Button,
	Form,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from 'reactstrap';
import { ValidationErrorsModal } from './ValidationErrorsModal';
import { renderField } from '../utils/form';
import { t } from '../utils/labels';
import { ViewContext } from '../utils/EntityViewContext';
import { EntityEditViewProps } from './EntityEditViewSwitch';
import { EntityModalStateEnum } from './EntityModalState';

export const EntityEditModalView: React.FC<EntityEditViewProps> = ({
	fields,
	tabs = ['generic data'],
	title,
	pre,
	onSubmit,
	entity,
	validationModalOpen,
	toggleValidationModal,
	mutatorFactory,
	entityLabel,
	create,
	clone,
	isChanged,
}) => {
	const onCloseHandler = useCallback(
		(viewData) => () => {
			if (isChanged) {
				const confirm = window.confirm(
					t`question_confirm_leaving_unsaved_changes`,
				);
				if (confirm) {
					viewData.changeState(EntityModalStateEnum.Closed);
				}
			} else {
				viewData.changeState(EntityModalStateEnum.Closed);
			}
		},
		[isChanged],
	);

	return (
		<ViewContext.Consumer>
			{(viewData) => {
				if (viewData === null) {
					throw new Error('ViewData not present');
				}
				if (viewData.type !== 'modal') {
					throw new Error('wrong view type');
				}

				return (
					<Modal
						autoFocus={false}
						isOpen={
							viewData.mode ===
							(create || clone
								? EntityModalStateEnum.Create
								: EntityModalStateEnum.Edit)
						}
						size={'xl'}
						fade={false}
					>
						<ModalHeader
							/*toggle={() => {
								viewData.changeState(EntityModalStateEnum.Closed);
							}}*/
							toggle={onCloseHandler(viewData)}
						>
							{viewData.customTitle ||
								title ||
								t`update` +
									(entityLabel
										? ' ' + t(entityLabel).toLowerCase()
										: '')}
						</ModalHeader>
						<ModalBody>
							{pre && pre(entity)}
							<Tabs tabs={tabs}>
								{(activeTab, tabIndex) => (
									<Form className="Form">
										{fields
											.filter(
												(f) =>
													f.tab === tabIndex ||
													(f.tab === undefined && tabIndex === 0),
											)
											.map((f) =>
												renderField(
													f,
													f.name,
													mutatorFactory(f),
													true,
												),
											)}
									</Form>
								)}
							</Tabs>
							<ValidationErrorsModal
								open={validationModalOpen}
								onToggle={toggleValidationModal}
							/>
						</ModalBody>
						<ModalFooter>
							<div>
								<Button
									color="secondary"
									onClick={onCloseHandler(viewData)}
								>
									<i className="fa fa-times" /> {t`close`}
								</Button>

								<Button
									color="success"
									className={'margin-left'}
									onClick={onSubmit}
								>
									<i className={'fa fa-save'} /> {t`save`}
								</Button>
							</div>
						</ModalFooter>
					</Modal>
				);
			}}
		</ViewContext.Consumer>
	);
};
