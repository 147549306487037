import React from 'react';
import { t } from '../utils/labels';
import { GoodDetailQuery } from '../graphql/query/GoodDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/Good.json';
import { GoodFormatDetail } from './GoodFormatDetail';
import { GoodFormatEdit } from './GoodFormatEdit';
import { GoodFormatCreate } from './GoodFormatCreate';
import { ACTIONS, generateUrl } from '../utils/urls';
import { pluralEntityLabel } from '../utils/misc';
import { ENTITY } from '../utils/entities';
import gql from 'graphql-tag';
import { HighlightedGoodCreate } from './HighlightedGoodCreate';
import { HighlightedGoodDetail } from './HighlightedGoodDetail';
import { HighlightedGoodEdit } from './HighlightedGoodEdit';
import { UserData } from '@food/auth';
import { entity2StringFragment } from '../utils/toString';
import { fastTransformer, refactorInfo, toAsyncRO, toHidden } from '../utils/form';
import { EntityDetailPage } from '../types/entities';
import { Good } from '../server-types';
import { PLManufacturerCreate } from './PLManufacturerCreate';

export const GoodDetail: React.FC<EntityDetailPage<Good>> = ({ id }) => (
	<UserData>
		{({ isInternal }) => (
			<EntityDetailFetcher
				id={id}
				query={GoodDetailQuery}
				entityName={ENTITY.GOOD}
				typeIntro={typeIntro}
				fields={[
					'nameIta',
					'name',
					'Brand',
					'range',
					'featuredImageItaMedia',
					'featuredImageMedia',
					'ingredientsImageMedia',
					'ingredientsImageItaMedia',
					'GoodClass',
					'goodFeatureCertifications',
					'shelfLifeLength',
					'formats',

					'descriptionIta',
					'description',
					'descriptionLongIta',
					'descriptionLong',
					'GeoIndication',
					isInternal ? 'highlightedGoods' : null,
					isInternal ? 'candidacies' : null,
					isInternal ? 'goodType' : null,
					isInternal ? 'plManufacturer' : null,
					'status'
				]}
				transformer={(data, entity) => {
					data.fields = fastTransformer({
						formats: (f) => {
							if (f.type !== 'EntityList') {
								refactorInfo(f, 'EntityList');
								return f;
							}
							f.props.headers = [
								'ean',
								'cuPerSellingPack',
								'cuMesureValue',
							];
							f.editComponent = GoodFormatEdit;
							f.detailComponent = GoodFormatDetail;
							f.addComponent = GoodFormatCreate;
							f.tab = 1;
							f.props.fragment = gql`
								fragment Fragment on GoodFormat {
									ean
									cuPerSellingPack
									cuMesureValue
									flavor
									status
								}
							`;
							if (!isInternal) {
								f.props.extractor = function (data) {
									return data.node.formats.filter((item) => item.status != "Discontinued");
								};
							}
							return f;
						},
						candidacies: (f) => {
							if (f.type !== 'EntityList') {
								refactorInfo(f, 'EntityList');
								return f;
							}

							f.tab = 2;
							f.props.denyAdd = true;
							f.props.headers = ['Award', 'name'];
							f.props.detailUrl = (e) =>
								generateUrl(ENTITY.CANDIDACY, ACTIONS.DETAIL, e.id);
							f.props.fragment = gql`
									fragment Fragment on Candidacy {
										name
										Award {
											id
											${entity2StringFragment(ENTITY.AWARD)}
										}
									}
								`;
							return f;
						},
						Brand: (f) => {
							if (f.type !== 'AsyncChoices') {
								refactorInfo(f, 'AsyncChoices');
								return f;
							}
							f.mapToEntity = () =>
								generateUrl(
									ENTITY.COMPANY,
									ACTIONS.DETAIL,
									entity.Brand.Company.id,
								);
							return f;
						},
						goodFeatureCertifications: (f) => {
							if (f.type !== 'EntityList') {
								refactorInfo(f, 'EntityList');
								return f;
							}
							return {
								...toAsyncRO(f, ENTITY.GOOD_FEATURE_CERTIFICATION),
								mapToEntity: null,
							};
						},
						GoodClass: (f) => {
							if (f.type !== 'AsyncChoices') {
								refactorInfo(f, 'AsyncChoices');
								return f;
							}
							return {
								...f,
								myToString: (e) => e.nameIta,
							};
						},
						highlightedGoods: (f) => {
							if (f.type !== 'EntityList') {
								refactorInfo(f, 'EntityList');
								return f;
							}
							f.tab = 3;
							f.editComponent = HighlightedGoodEdit;
							f.detailComponent = HighlightedGoodDetail;
							f.addComponent = HighlightedGoodCreate;
							f.props.headers = ['targetSite', 'highlightReasons'];
							f.props.fragment = gql`
								fragment Fragment on HighlightedGood {
									targetSite
									highlightReasons
								}
							`;
							return f;
						},
						plManufacturer: (f) => {
							if (f.type !== 'EntityList') {
								refactorInfo(f, 'EntityList');
								return f;
							}
							f.props.headers = [
								'company',
							];
							f.props.hideDetailAction = true;
							f.props.inlineDelete = true;
							f.addComponent = PLManufacturerCreate;
							f.tab = 4;
							f.props.fragment = gql`
								fragment Fragment on PLManufacturer {
									company {
										id
										name
									},
									good {
										id
										name
									}
								}
							`;
							return f;
						},
					})(data.fields);

					const brand = data.fields.filter((f) => f.name == 'Brand')[0].value.value;
					if (brand.Company !== null) {
						data.fields = fastTransformer({
							plManufacturer: (f) => {
								return toHidden()(f);
							}
						})(data.fields);
					}
					return data;
				}}
				tabs={
					isInternal
						? [
							t(ENTITY.GOOD),
							t([ENTITY.GOOD, ENTITY.GOOD_FORMAT, 'label'].join('/')),
							t(pluralEntityLabel(ENTITY.CANDIDACY)),
							t(
								[ENTITY.GOOD, ENTITY.HIGHLIGHTED_GOOD, 'label'].join(
									'/',
								),
							),
							t([ENTITY.GOOD, ENTITY.PLMANUFACTURER, 'label'].join('/')),
						]
						: [
							t(ENTITY.GOOD),
							t([ENTITY.GOOD, ENTITY.GOOD_FORMAT, 'label'].join('/')),
						]
				}
			/>
		)}
	</UserData>
);
