import gql from 'graphql-tag';
import {RetailerFullFragment} from "../fragments/RetailerFullFragment";

export const RetailerEditQuery = gql`
	query RetailerEditQuery($id: ID!) {
		entity: node (id: $id) {
				...RetailerFullFragment
		  }
	}
	${RetailerFullFragment}
`;


