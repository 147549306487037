import gql from 'graphql-tag';

export const AwardInvitedCompanyFullFragment = gql`
	fragment AwardInvitedCompanyFullFragment on AwardInvitedCompany {
		id
		localId
		Award {
			id
			name
			year
			edition
		}
		Company {
			id
			name
		}
		signedContract
		maxGoodsPerCandidacy
		maxPerCompanyCandidacies
		formValues
	}
`;
