import React from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import { FieldComponent, MediaListFieldType } from '../../types/form';
import { SortableList } from '../SortableList';
// import { EditEntityModal } from '../EditEntityModal';
import { MediaPreview } from '../MediaPreview';
import { t } from '../../utils/labels';
// import { ENTITY } from '../../utils/entities';
import { fieldToggler } from '../../utils/misc';
import { FormContext } from 'utils/context/form';
import { MediaModal } from '../MediaModal';
import { MediaEdit } from '../../containers/MediaEdit';
import { ViewContext } from '../../utils/EntityViewContext';
import { EntityModalStateEnum } from '../EntityModalState';

interface IOrderedMediaFieldState {
	detailModalIsOpen: boolean;
	uploadModalIsOpen: boolean;
	selectedItem: any;
}

export class MediaListField extends React.Component<
	FieldComponent<MediaListFieldType>,
	IOrderedMediaFieldState
> {
	constructor(props) {
		super(props);

		this.state = {
			detailModalIsOpen: false,
			uploadModalIsOpen: false,
			selectedItem: null,
		};
	}

	toggleUploadModal = fieldToggler(this, 'uploadModalIsOpen');

	addToSelected = (media: any) => {
		const { field, changeHandler, path } = this.props;
		const newValue = field.value ? field.value.slice(0) : [];
		newValue.push(media);
		this.toggleUploadModal();
		changeHandler(newValue, path);
	};

	removeFromList = (media: any) => {
		const { field, changeHandler, path } = this.props;
		changeHandler(
			field.value.filter((m) => m.id !== media.id),
			path,
		);
	};

	openEditModal = (item: any) =>
		this.setState({
			selectedItem: item,
			detailModalIsOpen: true,
		});

	closeEditModal = () =>
		this.setState({
			selectedItem: null,
			detailModalIsOpen: false,
		});

	render() {
		const { field, changeHandler, readOnly, path } = this.props;
		const {
			pre,
			post,
			value,
			mediaType,
			helpText,
			label,
			ordered,
			name,
		} = field;
		const { detailModalIsOpen, uploadModalIsOpen, selectedItem } = this.state;

		return (
			<FormContext.Consumer>
				{({ usedMedias }) => (
					<>
						{!readOnly && (
							<MediaModal
								open={uploadModalIsOpen}
								closeHandler={this.toggleUploadModal}
								selected={value}
								onSelect={this.addToSelected}
								mediaType={mediaType}
								helpText={helpText}
								usedMedias={usedMedias}
							/>
						)}
						{!readOnly && selectedItem && (
							<ViewContext.Provider
								value={{
									type: 'modal',
									mode: detailModalIsOpen
										? EntityModalStateEnum.Detail
										: EntityModalStateEnum.Closed,
									changeState: this.closeEditModal,
								}}
							>
								<MediaEdit
									id={selectedItem.id}
									onUpdate={(newMedia: any) => {
										const newList = value.map((media) =>
											media.id === newMedia.id ? newMedia : media,
										);
										changeHandler(newList, path);
										this.closeEditModal();
									}}
								/>
							</ViewContext.Provider>
							/*<EditEntityModal
								closeHandler={this.closeEditModal}
								editOnly={true}
								open={detailModalIsOpen}
								title={selectedItem.name}
								id={selectedItem.id}
								originalEntity={selectedItem}
								permissions={field.permissions}
								entityLabel={ENTITY.MEDIA}
								onUpdate={(newMedia: any) => {
									const newList = value.map((media) =>
										media.id === newMedia.id ? newMedia : media,
									);
									changeHandler(newList, path);
									this.closeEditModal();
								}}
							/>*/
						)}

						{pre && pre}
						<FormGroup>
							<Label>{label}</Label>
							{value && value.length > 0 && (
								<SortableList
									readonly={readOnly}
									onMove={(val) => changeHandler(val, path)}
									onRemove={this.removeFromList}
									items={value}
									ordered={ordered}
									path={name}
									itemView={({ item }) => (
										<MediaPreview
											item={item}
											onEdit={this.openEditModal}
										/>
									)}
								/>
							)}
							{(!value || value.length === 0) && (
								<div>{t`no media message`}</div>
							)}
							{!readOnly && (
								<Button
									color={'success'}
									onClick={this.toggleUploadModal}
								>
									<i className={'mdi mdi-plus'} />
									{t`add media`}
								</Button>
							)}
						</FormGroup>
						{post && post}
					</>
				)}
			</FormContext.Consumer>
		);
	}
}
