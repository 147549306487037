import gql from 'graphql-tag';
import {CompanyLevelFullFragment} from "../fragments/CompanyLevelFullFragment";

export const CompanyLevelCreateMutation = gql`
	mutation CompanyLevelCreateMutation($values: CompanyLevelCreateInput!) {
		results: companyLevelCreate(values: $values) {
			...CompanyLevelFullFragment
		}
	}
	${CompanyLevelFullFragment}
`;


