import React from 'react';
import { getUserData, StepContainer, UserData, logout } from '@food/auth';
import { Button, ButtonLayouts } from '@food/ui';
import { Redirect } from 'react-router';
import { t } from '../../utils/labels';
import { classNames, currySC, registerClass } from '@food/css-manager';
import { Spinner } from '../GlobalSpinner';
import CONFIG from '../../static/config';

const styleClass = registerClass(
	(t, sc) => `
	
	.${sc('main-text')} {
		margin-bottom: 1rem;
	}
	`,
);

const loginContainerStyle = registerClass(
	(t) => `
	> div {
		width: 90%;
		max-width: 700px;
		
		p {
			margin-bottom: ${t.ratios.xl}rem;
			&:last-child {
				margin-bottom: 0;
			}
			
			&:first-child {
				a {
					text-decoration: none;
				}
			}
		}
	}
	
	.${sc('link')} {
		color: #999;
		text-decoration: underline;
		font-weight: 300;
	}
`,
);

export const LoginContainer: React.FC = ({ children }) => (
	<div className={classNames('login-container', loginContainerStyle)}>
		{children}
	</div>
);

const sc = currySC(styleClass);

export const ConsumerWelcome = () => {
	const user = getUserData();

	if (!user) {
		return <Redirect to={'/'} />;
	}

	return (
		<UserData>
			{({ userData, loading }) => {
				if (loading || !userData) {
					return <Spinner show={true} />;
				}
				return (
					<LoginContainer>
						<StepContainer>
							<h2>{t`consumer welcome title`}</h2>
							<section className={styleClass}>
								<p>
									<div
										className={sc('main-text')}
										dangerouslySetInnerHTML={{
											__html: t`consumer welcome html 1`,
										}}
									/>
									<div className={'text-center'}>
										<Button
											type={'success'}
											label={t`upgrade to Producer`}
											layout={ButtonLayouts.BUTTON}
											linkTo={'/login?upgraderole=PRODUCER'}
										/>
									</div>
								</p>
								<p>
									<div
										className={sc('main-text')}
										dangerouslySetInnerHTML={{
											__html: t`consumer welcome html 2`,
										}}
									/>
								</p>
								<p>
									{t`if you're not`} {userData.name}{' '}
									<Button
										label={t`change account`}
										layout={ButtonLayouts.LINK}
										className={sc('link')}
										onClick={() => logout()}
									/>
									.
								</p>
								<p>
									{t`if you think there's a problem with your account`}{' '}
									<a
										href={'mailto:' + CONFIG.SUPPORT_EMAIL}
									>{t`contact us`}</a>
									.
								</p>
							</section>
						</StepContainer>
					</LoginContainer>
				);
			}}
		</UserData>
	);
};
