import React from 'react';
import { GoodFormatCreateMutation } from '../graphql/mutations/GoodFormatCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/GoodFormat.json';
import inputTypeIntro from '../static/introspection/GoodFormatCreateInput.json';
import { toRefInput } from '../utils/dataTrasform';
import { ENTITY } from '../utils/entities';
import { fastTransformer, toHidden } from '../utils/form';
import { transformerHandler } from '../utils/misc';
import { EntityCreateModal } from '../types/entities';
import { Good, GoodFormat } from '../server-types';

export const GoodFormatCreate: React.FC<EntityCreateModal<
	GoodFormat,
	Good
>> = ({ id, onSubmit, originalEntity }) => (
	<EntityEditFetcher
		id={id}
		onSubmit={onSubmit}
		create={true}
		modal={true}
		mutation={GoodFormatCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.GOOD_FORMAT}
		fields={[
			'cuMesureUnit',
			'cuMesureValue',
			'cuPerSellingPack',
			'packaging',
			'ean',
			'unitsPerBox',
			'status',
			'Good',
		]}
		transformer={transformerHandler(
			fastTransformer({
				Good: toHidden(toRefInput(originalEntity)),
			}),
		)}
	/>
);
