import gql from 'graphql-tag';
import {FrequentSearchFullFragment} from "../fragments/FrequentSearchFullFragment";

export const FrequentSearchPatchMutation = gql`
	mutation FrequentSearchPatchMutation($id: ID!, $changes: FrequentSearchPatchInput!) {
		results: frequentSearchPatch(id: $id, changes: $changes) {
			...FrequentSearchFullFragment
		}
	}
	${FrequentSearchFullFragment}
`;


