import gql from 'graphql-tag';
import {MediaBaseFragment} from "../fragments/MediaBaseFragment";

// scusate per il nome

// IMPORTANT - name(Ita), featuredImage(Ita)Media, descriptionLong(Ita) e range servono per copiarli nella Candidacy
export const GoodsInBrandsWithEANSelectQuery = gql`
	query GoodsInBrandsWithEANSelectQuery($filter: GoodFilterInput) {
       choices: goods(filter: $filter, orderBy: name, orderDesc:false) {
           edges {
               node {
                   id
                   localId
                   name
						 nameIta
                   featuredImageMedia {
                       ...MediaBaseFragment
                   }
						 featuredImageItaMedia {
							  ...MediaBaseFragment
						 }
                   descriptionLong
                   descriptionLongIta
                   range
               }
           }
           total
       }
   }
  ${MediaBaseFragment}
`;