import gql from 'graphql-tag';
import {BranchFullFragment} from "../fragments/BranchFullFragment";

export const BranchCreateMutation = gql`
	mutation BranchCreateMutation($values: BranchCreateInput!) {
		results: branchCreate(values: $values) {
			...BranchFullFragment
		}
	}
	${BranchFullFragment}
`;


