import React from 'react';
import { NewsletterIssueCreateMutation } from '../graphql/mutations/NewsletterIssueCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/NewsletterIssue.json';
import inputTypeIntro from '../static/introspection/NewsletterIssueCreateInput.json';
import { NewsletterIssueEditQuery } from '../graphql/query/NewsletterIssueEditQuery';
import { ENTITY } from '../utils/entities';
import {
	addOverrideFields,
	injectFetchedData,
	jsonToPromises,
	newsletterIssueBeforeSaveTransformer,
	newsletterIssueCreateTransformer,
	newsletterTemplateAdditionalFieldsTransformer,
	valuesToForms,
} from '../utils/entities/newsletterIssue';
import { graphqlQuery, mediaQuery } from '../utils/graphql';
import { transform } from '../utils/form';

export const NewsletterIssueCreate: React.FC<{ id: string }> = ({ id }) => (
	<EntityEditFetcher
		fetcher={
			id
				? async () => {
					const data = await graphqlQuery(NewsletterIssueEditQuery, {
						id,
					})();
					const values = JSON.parse(data.entity.values);
					const { blocks } = values;
					const results: { [key: string]: any } = {};

					// nel caso fosse presente un override per l'header del media lo devo scaricare
					if (
						values.newsletterOverrides &&
						values.newsletterOverrides.headerMedia
					) {
						values.newsletterOverrides.headerMedia = (
							await graphqlQuery(
								mediaQuery,
								values.newsletterOverrides.headerMedia,
							)()
						).node;
					}

					const blocksResults = await jsonToPromises(blocks, results);

					injectFetchedData(blocksResults, blocks);

					return {
						entity: {
							...data.entity,
							values,
						},
					};
				}
				: null
		}
		create={true}
		clone={Boolean(id)}
		id={id}
		mutation={NewsletterIssueCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.NEWSLETTER_ISSUE}
		beforeSaveTransformer={newsletterIssueBeforeSaveTransformer}
		fields={['name', 'publicationDate', 'Newsletter', 'hiddenIntro']}
		transformer={(data, fetcherResult) => {
			data.fields = transform(
				newsletterIssueCreateTransformer,
				newsletterTemplateAdditionalFieldsTransformer,
				id && valuesToForms(fetcherResult), // aggiungo il transformer solo se sono in clonazione
			)(data.fields);

			if (fetcherResult?.Newsletter?.inputTemplate) {
				data.fields = addOverrideFields(
					data.fields,
					fetcherResult.Newsletter.inputTemplate,
					fetcherResult.values.newsletterOverrides,
				);
			}

			return data;
		}}
	/>
);
