import gql from 'graphql-tag';
import {CompanyLevelFullFragment} from "../fragments/CompanyLevelFullFragment";

export const CompanyLevelPatchMutation = gql`
	mutation CompanyLevelPatchMutation($id: ID!, $changes: CompanyLevelPatchInput!) {
		results: companyLevelPatch(id: $id, changes: $changes) {
			...CompanyLevelFullFragment
		}
	}
	${CompanyLevelFullFragment}
`;


