import React from 'react';
import { HighlightedGoodDetailQuery } from '../graphql/query/HighlightedGoodDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/HighlightedGood.json';
import { ENTITY } from '../utils/entities';
import { EntityDetailModal } from '../types/entities';
import { Company, Good } from '../server-types';

export const HighlightedGoodDetail: React.FC<EntityDetailModal<
	Company | Good
>> = ({ id, onDelete }) => (
	<EntityDetailFetcher
		query={HighlightedGoodDetailQuery}
		entityName={ENTITY.HIGHLIGHTED_GOOD}
		typeIntro={typeIntro}
		clonable={false}
		modal={true}
		id={id}
		onDelete={onDelete}
		fields={[
			'Good',
			'targetSite',
			'highlightReasons',
			'claim1',
			'claim2',
			'claim3',
		]}
	/>
);
