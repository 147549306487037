import gql from 'graphql-tag';
import { StoreChainFullFragment } from 'graphql/fragments/StoreChainFullFragment';

export const StoreChainCreateMutation = gql`
	mutation storeChainCreate($values: StoreChainCreateInput!) {
		results: storeChainCreate(values: $values) {
			...StoreChainFullFragment
		}
	}
	${StoreChainFullFragment}
`;


