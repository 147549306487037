import gql from 'graphql-tag';
import {ServiceFullFragment} from "../fragments/ServiceFullFragment";

export const ServiceEditQuery = gql`
	query ServiceEditQuery($id: ID!) {
		entity: node (id: $id) {
				...ServiceFullFragment
		  }
	}
	${ServiceFullFragment}
`;


