/**
 * Componente view di default dei sistemi di creazione/modifica/clonazione entita'. In realta' si occupa solo di
 * smistare fra due altre view, una adeguata a form a tutta pagina (primo livello) e una adeguata ai form mostrati in
 * modali (secondo livello o piu').
 *
 * Per più informazioni rivolgiti alla pagina di documentazione `architettura_form.md`.
 */

import React, { ReactInstance } from 'react';
import { IField } from '../types/form';
import { ENTITY } from '../utils/entities';
import { ViewContext } from '../utils/EntityViewContext';
import { EntityEditView } from './EntityEditView';
import { EntityEditModalView } from './EntityEditModalView';


// TODO commentare tipizzazione props EntityEditView
export interface EntityEditViewProps {
	loading: boolean;
	fields: ReadonlyArray<IField>; // lista di IField rappresentanti gli attributi dell'entita'
	id?: string;
	tabs?: ReadonlyArray<string>;
	title: string;
	pre?: (entity: any) => ReactInstance;
	entityLabel: string;
	isChanged: boolean; // booleano che indica se
	onSubmit?: () => any;
	entity?: any;
	entityName: ENTITY;
	create: boolean;
	validationModalOpen: boolean;
	toggleValidationModal: () => void;
	mutatorFactory: (f: IField) => (v: any, path: string) => void;
	clone?: boolean;
	myToString?: (entity: any) => string; // eventuale funzione per tederminare il nome dell'entita'
}

// ma domanda secca secca: cosi' sto creato tutte le volte da zero il componente? Questo potrebbe essere un grosso
// problema prestazionale e di stato..
// TODO Controllare se la definizione di componenti in EntityEditViewSwitch non comporta gravi problemi prestazionali
export const EntityEditViewSwitch: React.FC<EntityEditViewProps> = (props) => (
	<ViewContext.Consumer>
		{(viewData) => {
			const Component =
				viewData?.type === 'modal' ? EntityEditModalView : EntityEditView;

			return <Component {...props} />;
		}}
	</ViewContext.Consumer>
);
