import gql from 'graphql-tag';
import {BrandFullFragment} from "../fragments/BrandFullFragment";


export const BrandPatchMutation = gql`
    mutation BrandPatchMutation($id: ID!, $changes: BrandPatchInput!) {
        results: brandPatch(id: $id, changes: $changes) {
            ...BrandFullFragment
        }
    }
   ${BrandFullFragment}
`;



