import gql from 'graphql-tag';
import {ManagedByUserFullFragment} from "../fragments/ManagedByUserFullFragment";

export const ManagedByUserEditQuery = gql`
	query ManagedByUserEditQuery($id: ID!) {
		entity: node (id: $id) {
				...ManagedByUserFullFragment
		  }
	}
	${ManagedByUserFullFragment}
`;


