import React from 'react';
import { CompanyLevelDetailQuery } from '../graphql/query/CompanyLevelDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/CompanyLevel.json';
import { ENTITY } from '../utils/entities';
import { transformerHandler } from '../utils/misc';
import { fastTransformer, refactorInfo } from '../utils/form';
import { EntityDetailModal } from '../types/entities';
import { Company } from '../server-types';

export const CompanyLevelDetail: React.FC<EntityDetailModal<Company>> = ({
	id,
	onDelete,
}) => (
	<EntityDetailFetcher
		id={id}
		onDelete={onDelete}
		query={CompanyLevelDetailQuery}
		entityName={ENTITY.COMPANY_LEVEL}
		typeIntro={typeIntro}
		clonable={false}
		modal={true}
		fields={[
			'targetSite',
			'startDate',
			'companyBadges',
			'expirationDate',
			'level',
			'description',
			'descriptionLong',
			'videoUrl',
			'layout',
			'website',
			'newsPath',
		]}
		transformer={transformerHandler(
			fastTransformer({
				companyBadges: (f) => {
					if (f.type !== 'EntityList') {
						refactorInfo(f, 'EntityList');
						return f;
					}
					return {
						...f,
						entityType: ENTITY.COMPANY_BADGE,
						type: 'AsyncChoices',
						mapToEntity: null,
						asyncQuery: () => null,
						single: false,
					};
				},
			}),
		)}
	/>
);
