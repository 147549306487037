import { ENTITY } from './entities';
import { viewRoles } from '../static/viewRoles';
import { UserDataInterface } from '@food/auth';

export function canView(user: UserDataInterface, entity: ENTITY): boolean {
	const { role } = user.userData;
	const { capabilities } = user;

	if (
		!viewRoles.has(entity) ||
		!viewRoles.get(entity).viewRoles.hasOwnProperty(role)
	)
		return false;

	const cap = viewRoles.get(entity).viewRoles[role];

	if (typeof cap === 'boolean') {
		return cap;
	} else {
		return capabilities.indexOf(cap) !== -1;
	}
}

export function hasAutoDetail(
	user: UserDataInterface,
	entity: ENTITY,
): boolean {
	const { role } = user.userData;
	const { capabilities } = user;

	if (
		!viewRoles.has(entity) ||
		!viewRoles.get(entity).autoDetailRoles.hasOwnProperty(role)
	)
		return false;

	const cap = viewRoles.get(entity).autoDetailRoles[role];

	if (typeof cap === 'boolean') {
		return cap;
	} else {
		return capabilities.indexOf(cap) !== -1;
	}
}
