import gql from 'graphql-tag';
import {DistributionChannelFullFragment} from "../fragments/DistributionChannelFullFragment";

export const DistributionChannelCreateMutation = gql`
	mutation DistributionChannelCreateMutation($values: DistributionChannelCreateInput!) {
		results: distributionChannelCreate(values: $values) {
			...DistributionChannelFullFragment
		}
	}
	${DistributionChannelFullFragment}
`;


