import gql from 'graphql-tag';
import {FairFullFragment} from "../fragments/FairFullFragment";

export const FairDetailQuery = gql`
	query FairDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...FairFullFragment
		}
	}
	${FairFullFragment}
`;


