import React from 'react';
import { FoodServiceCreateMutation } from '../graphql/mutations/FoodServiceCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/FoodService.json';
import inputTypeIntro from '../static/introspection/FoodServiceCreateInput.json';
import { FoodServiceEditQuery } from '../graphql/query/FoodServiceEditQuery';
import { ENTITY } from '../utils/entities';
import { EntityCreatePage } from '../types/entities';
import { FoodService } from '../server-types';
import { graphqlQuery } from '../utils/graphql';
import { UserData } from '@food/auth';

export const FoodServiceCreate: React.FC<EntityCreatePage<FoodService>> = ({
	id,
}) => (
	<UserData>
		{({ isInternal }) => (
			<EntityEditFetcher
				fetcher={id ? graphqlQuery(FoodServiceEditQuery, { id }) : null}
				create={true}
				clone={!!id}
				id={id}
				mutation={FoodServiceCreateMutation}
				typeIntro={typeIntro}
				inputTypeIntro={inputTypeIntro}
				entityName={ENTITY.FOOD_SERVICE}
				fields={[
					isInternal ? 'sfId' : null,
					'name',
					'logoMedia',
					'dimension',
					'type',
					'billingName',
					'description',
					'descriptionIta',
					'descriptionLong',
					'descriptionLongIta',
					'pecEmail',
					'vatNumber',
					'website',
					'Country',
					'StateProvince',
					'postalCode',
					'city',
					'address1',
					'address2',
					'lat',
					'lng',
				]}
			/>
		)}
	</UserData>
);
