import React from 'react';
import { GoodFeatureCertificationCreateMutation } from '../graphql/mutations/GoodFeatureCertificationCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/GoodFeatureCertification.json';
import inputTypeIntro from '../static/introspection/GoodFeatureCertificationCreateInput.json';
import { GoodFeatureCertificationEditQuery } from '../graphql/query/GoodFeatureCertificationEditQuery';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const GoodFeatureCertificationCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={
			id ? graphqlQuery(GoodFeatureCertificationEditQuery, { id }) : null
		}
		create={true}
		clone={!!id}
		id={id}
		mutation={GoodFeatureCertificationCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.GOOD_FEATURE_CERTIFICATION}
		fields={['name', 'nameIta', 'akas', 'website']}
	/>
);
