import React from 'react';
import { EuCategoryPatchMutation } from '../graphql/mutations/EuCategoryPatchMutation';
import { EuCategoryEditQuery } from '../graphql/query/EuCategoryEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/EuCategory.json';
import inputTypeIntro from '../static/introspection/EuCategoryPatchInput.json';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';

export const EuCategoryEdit = ({ id }) => (
	<EntityEditFetcher
		fetcher={graphqlQuery(EuCategoryEditQuery, { id })}
		mutation={EuCategoryPatchMutation}
		entityName={ENTITY.EU_CATEGORY}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		id={id}
		fields={['name', 'HtmlTemplate']}
	/>
);
