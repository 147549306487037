import gql from 'graphql-tag';
import { FoodServiceFullFragment } from '../fragments/FoodServiceFullFragment';

export const FoodServiceEditQuery = gql`
	query FoodServiceEditQuery($id: ID!) {
		entity: node(id: $id) {
			...FoodServiceFullFragment
		}
	}
	${FoodServiceFullFragment}
`;
