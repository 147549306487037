import gql from 'graphql-tag';
import {EmailDomainFullFragment} from "../fragments/EmailDomainFullFragment";

export const EmailDomainPatchMutation = gql`
	mutation EmailDomainPatchMutation($id: ID!, $changes: EmailDomainPatchInput!) {
		results: emailDomainPatch(id: $id, changes: $changes) {
			...EmailDomainFullFragment
		}
	}
	${EmailDomainFullFragment}
`;


