import gql from 'graphql-tag';
import {AwardInvitedUserFullFragment} from "../fragments/AwardInvitedUserFullFragment";

export const AwardInvitedUserPatchMutation = gql`
	mutation AwardInvitedUserPatchMutation($id: ID!, $changes: AwardInvitedUserPatchInput!) {
		results: awardInvitedUserPatch(id: $id, changes: $changes) {
			...AwardInvitedUserFullFragment
		}
	}
	${AwardInvitedUserFullFragment}
`;


