import React from 'react';
import { t } from '../utils/labels';
import { FairDetailQuery } from '../graphql/query/FairDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/Fair.json';
import { FairParticipationDetail } from './FairParticipationDetail';
import { FairParticipationEdit } from './FairParticipationEdit';
import { FairParticipationCreate } from './FairParticipationCreate';
import { ENTITY } from '../utils/entities';
import gql from 'graphql-tag';
import { fastTransformer, refactorInfo } from '../utils/form';
import { assign, transformerHandler } from '../utils/misc';
import { EntityDetailPage } from '../types/entities';
import { Fair } from '../server-types';

export const FairDetail: React.FC<EntityDetailPage<Fair>> = ({ id }) => (
	<EntityDetailFetcher
		query={FairDetailQuery}
		entityName={ENTITY.FAIR}
		id={id}
		typeIntro={typeIntro}
		clonable={false}
		fields={[
			'name',
			'edition',
			'year',
			'logoMedia',
			'begin',
			'end',
			'targetSites',
			'goodClasses',
			'description',
			'descriptionIta',
			'website',
			'Country',
			'StateProvince',
			'postalCode',
			'city',
			'address1',
			'address2',
			'lat',
			'lng',
			'participations',
			'ourPavilion',
			'ourStand',
			'ourNote',
		]}
		transformer={transformerHandler(
			fastTransformer({
				goodClasses: assign({ type: 'AsyncChoices' }),
				participations: (f) => {
					if (f.type !== 'EntityList') {
						refactorInfo(f, 'EntityList');
						return f;
					}
					f.props.headers = ['Company', 'stand', 'pavilion'];
					f.editComponent = FairParticipationEdit;
					f.detailComponent = FairParticipationDetail;
					f.addComponent = FairParticipationCreate;
					f.tab = 1;
					f.props.paginated = true;
					f.props.fragment = gql`
						fragment Fragment on FairParticipation {
							Company {
								id
								name
							}
							stand
							pavilion
						}
					`;
					return f;
				},
				/*newsCitations: (f) => {
					if (f.type !== 'EntityList') {
						refactorInfo(f, 'EntityList');
						return f;
					}
					f.props.denyAdd = true;
					f.props.headers = ['News', 'count'];
					f.tab = 2;
					f.props.fragment = gql`
						fragment Fragment on NewsCitation {
							News {
								id
								name: title
							}
							count
						}
					`;
					return f;
				},*/
			}),
		)}
		tabs={[
			t('generic data'),
			t([ENTITY.FAIR, ENTITY.FAIR_PARTICIPATION, 'label'].join('/')),
			t([ENTITY.FAIR, ENTITY.NEWS_CITATION, 'label'].join('/')),
		]}
		cols={[['col-sm-12'], ['col-sm-12'], ['col-sm-12']]}
	/>
);
