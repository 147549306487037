import React from 'react';
import { UserCreateMutation } from '../graphql/mutations/UserCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/User.json';
import inputTypeIntro from '../static/introspection/UserCreateInput.json';
import { UserEditQuery } from '../graphql/query/UserEditQuery';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const UserCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(UserEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={UserCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.USER}
		fields={[
			'firstName',
			'lastName',
			'email',
			'role',
			'status',
			'Team',
			'internalCapabilities',
		]}
	/>
);
