import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { NewsletterListQuery } from '../graphql/query/NewsletterListQuery';
import typeIntro from '../static/introspection/Newsletter.json';
import NewsletterFilters from '../static/introspection/NewsletterFilterInput.json';
import { ENTITY } from '../utils/entities';

export const NewsletterList: React.FunctionComponent<{}> = () => (
	<EntityListFetcher
		query={NewsletterListQuery}
		entityName={ENTITY.NEWSLETTER}
		headers={['name', 'target', 'delivery', 'inputTemplate']}
		typeIntro={typeIntro}
		filtersIntro={NewsletterFilters}
		filterFields={['name', 'targets', 'deliveries']}
	/>
);
