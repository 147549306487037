import React from 'react';
import { ManagedByUserPatchMutation } from '../graphql/mutations/ManagedByUserPatchMutation';
import { ManagedByUserEditQuery } from '../graphql/query/ManagedByUserEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/ManagedByUser.json';
import inputTypeIntro from '../static/introspection/ManagedByUserPatchInput.json';
import { graphqlQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';
import { EntityEditModal } from '../types/entities';
import {
	Company,
	CompanyGroup,
	ManagedByUser,
	Retailer,
	Service,
	User,
} from '../server-types';

export const ManagedByUserEdit: React.FC<EntityEditModal<
	ManagedByUser,
	Company | Service | CompanyGroup | Retailer | User
>> = ({ id, onSubmit }) => (
	<EntityEditFetcher
		id={id}
		fetcher={graphqlQuery(ManagedByUserEditQuery, { id })}
		mutation={ManagedByUserPatchMutation}
		entityName={ENTITY.MANAGED_BY_USER}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		modal={true}
		fields={['capabilities']}
		onSubmit={onSubmit}
	/>
);
