import gql from 'graphql-tag';

export const ToSListQuery = gql`
	query ToSListQuery(
		$first: Int,
		$cursor: String,
	) {
		entities: toSs(first: $first, after: $cursor) {
			edges {
				node {
					id
					localId
					name
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}

	}
`;


