import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { MagazineCollectionListQuery } from '../graphql/query/MagazineCollectionListQuery';
import typeIntro from '../static/introspection/MagazineCollection.json';
import { ENTITY } from '../utils/entities';

const MagazineCollectionList = () => (
	<EntityListFetcher
		query={MagazineCollectionListQuery}
		entityName={ENTITY.MAGAZINE_COLLECTION}
		headers={['BrandSf', 'name']}
		typeIntro={typeIntro}
	/>
);

export { MagazineCollectionList };
