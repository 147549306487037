import gql from 'graphql-tag';
import {EmailDomainFullFragment} from "../fragments/EmailDomainFullFragment";

export const EmailDomainEditQuery = gql`
	query EmailDomainEditQuery($id: ID!) {
		entity: node (id: $id) {
				...EmailDomainFullFragment
		  }
	}
	${EmailDomainFullFragment}
`;


