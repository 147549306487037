import React from 'react';
import { Breadcrumbs } from './Breadcrumbs';
import { Link } from 'react-router-dom';
import { Button, Col, Card, CardBody, Row } from 'reactstrap';
import { Action, BreadcrumbItem } from '../types/ui';

interface PageTitleProps {
	title: string;
	breadcrumbs?: ReadonlyArray<BreadcrumbItem>;
	actions?: ReadonlyArray<Action>;
	secondary?: boolean;
}

export const PageTitle: React.FC<PageTitleProps> = ({
	title,
	breadcrumbs,
	actions,
	secondary,
}) => (
	<Row className={'page-titles' + (secondary ? ' food-secondary' : '')}>
		<Col sm={12} className="align-self-center">
			<Card>
				<CardBody>
					<Row>
						<Col sm="12" className="d-inline-block">
							<h3 className="text-themecolor">{title}</h3>
							{breadcrumbs && !secondary && (
								<Breadcrumbs pages={breadcrumbs} />
							)}

							{actions && (
								<div className="action-top-right">
									{actions.map((action, i) => (
										<span key={i}>
											{action.path && (
												<Link to={action.path} key={action.name}>
													<Button
														color="success"
														className={'margin-right'}
													>
														<i className={'fa ' + action.icon} />{' '}
														{action.name}
													</Button>
												</Link>
											)}
											{action.handler && (
												<Button
													color="success"
													key={action.name}
													onClick={action.handler}
												>
													{action.icon && (
														<i className={'fa ' + action.icon} />
													)}
													{action.icon && ' '}
													{action.name}
												</Button>
											)}
											{action.component && action.component}
										</span>
									))}
								</div>
							)}
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Col>
	</Row>
);
