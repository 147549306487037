import React from 'react';
import * as Sentry from '@sentry/browser';
import { t } from './labels';
import doT from 'dot';
import { fieldLabel, getEntityLabel } from './misc';

let errorModal = null;

export enum CONTEXTS {
	NETWORK = 'NETWORK',
	GRAPHQL = 'GRAPHQL',
	SERVER = 'SERVER',
	VALIDATION = 'VALIDATION',
	REPORT = 'REPORT',
}

export enum ERRORS {
	SERVER_ERROR = 'SERVER_ERROR',
	UNKNOWN_ERROR = 'UNKNOWN_ERROR',
	NOT_FOUND = 'NOT_FOUND',
	GENERATION = 'GENERATION',
}

export type HubError = Error & {
	context?: CONTEXTS;
	type?: ERRORS;
	data?: any;
	timestamp?: number;
};

// prendo un Error javascript e aggiungo i miei campi personalizzati
const generateError = (error: ERRORS, context: CONTEXTS, data: any): HubError =>
	Object.assign(new Error(error), {
		type: error,
		context,
		data,
		timestamp: new Date().getTime(),
	});

function parseGraphqlError(e, errContext = {}) {
	if (e.graphQLErrors && e.graphQLErrors.length > 0) {
		return e.graphQLErrors.reduce((result, error) => {
			
			
			if (error.message === 'NotFound error.') {
				result.push(
					generateError(ERRORS.NOT_FOUND, CONTEXTS.GRAPHQL, {
						...errContext,
						...error,
					}),
				);
			} else if (error.state) {
				if (error.state.length) {
					return result.concat(
						error.state.map((er) =>
							generateError(ERRORS.SERVER_ERROR, CONTEXTS.VALIDATION, {
								...errContext,
								...er,
							}),
						),
					);
				} else {
					result.push(
						generateError(ERRORS.SERVER_ERROR, CONTEXTS.VALIDATION, {
							...errContext,
							...error.state,
						}),
					);
				}
			} else {
				result.push(
					generateError(ERRORS.SERVER_ERROR, CONTEXTS.GRAPHQL, {
						...errContext,
						...error,
					}),
				);
			}
			return result;
		}, []);
		
	} else if (e.networkError) {
		return [
			generateError(ERRORS.SERVER_ERROR, CONTEXTS.NETWORK, {
				originalError: e,
			}),
		];
	}

	return [
		generateError(ERRORS.UNKNOWN_ERROR, CONTEXTS.SERVER, {
			originalError: e,
		}),
	];
}

// ho bisogno di queste due funzioni per poter dare all'handler "modal" l'elemento a cui fare riferimento
// per ora vengono usate solo quando si attacca/stacca il layout principale (sidebar layout)
function setErrorModal(modal) {
	errorModal = modal;
	return errorModal;
}

// segnalo gli errori a sentry
const sentryHandler = (errs: HubError[] | HubError) => {
	if (!Array.isArray(errs)) errs = [errs];
	errs
		.filter((err) => err.context !== CONTEXTS.VALIDATION) // gli errori di validazione non vanno segnalati
		.forEach((err) => {
			const data = { extra: err.data };
			Sentry.withScope((scope) => {
				scope.setExtra('data', data);
				Sentry.captureException(err.data ? err.data.originalError : err);
			});
		});
	// setTimeout(resolve, CONFIG.SENTRY_NOTIFICATION_TIMEOUT);
};

// segnalo gli errori all'utente tramite modale
function modalHandler(errs) {
	errorModal.current._open(errs);
	console.error(errs[0]);
}

export const VALIDATION_ERROR_TYPES = {
	EMPTY_NOT_ALLOWED: 'EMPTY_NOT_ALLOWED',
	OVER_MAX: 'OVER_MAX',
	UNDER_MIN: 'UNDER_MIN',
	WRONG_PATTERN: 'WRONG_PATTERN',
	TOO_MUCH_CAPS: 'TOO_MUCH_CAPS',
	REF_DO_NOT_EXISTS: 'REF_DO_NOT_EXISTS',
	INVALID_REF: 'INVALID_REF',
	UNSUPPORTED_FORMAT: 'UNSUPPORTED_FORMAT',
	INVALID_VALUE: 'INVALID_VALUE',
	WRONG_MEDIA_PROP: 'WRONG_MEDIA_PROP',
	MUST_BE_UNIQUE: 'MUST_BE_UNIQUE',
	ALREADY_PRESENT: 'ALREADY_PRESENT',
	EXPIRED: 'EXPIRED',
	GENERIC: 'GENERIC',
	INTERNAL: 'INTERNAL',
	HAS_DEPS: 'HAS_DEPS',
};

const enum2name = function(enumName, value) {
	if (!enumName || !value) {
		return '';
	}
	return t([enumName, value, 'label'].join('/')) || value;
};

const ERROR_I18N_BASE_KEY = 'ERRORS';
const errorTemplatesCache = {};
// traduco i vari errori con del testo personalizzato, così da avere più controllo su cosa mostrare all'utente
const errorToComponent = (
	err: HubError,
	isFieldContext?: boolean,
): JSX.Element => {
	const { message, context, data } = err;

	// faccio un controllo piu' ad ampio spettro per quanto riguarda la validazione
	let customMessage = t([message, context, 'error', 'explication'].join('/'));

	if (context === CONTEXTS.GRAPHQL || context === CONTEXTS.VALIDATION) {
		if (!err.data) {
			err.data = {
				type: VALIDATION_ERROR_TYPES.GENERIC,
			};
		}

		if (!err.data.type) {
			err.data.type = VALIDATION_ERROR_TYPES.GENERIC;
		}

		let key = [
			ERROR_I18N_BASE_KEY,
			CONTEXTS.GRAPHQL,
			err.data.type,
			isFieldContext ? 'field' : 'global',
		].join('/');

		// faccio un check per alcuni tipi di errori speciali per cui mostro messaggi adeguati
		// caso in cui si sia caricato un raster dove non e' consentito
		if (
			data.type === 'WRONG_MEDIA_PROP' &&
			!isFieldContext &&
			data.prop === 'raster' &&
			data.actual === 1
		) {
			key = 'no raster allowed error explanation';
		}

		if (errorTemplatesCache[key] === undefined) {
			errorTemplatesCache[key] = t(key);
			if (errorTemplatesCache[key] === key) {
				errorTemplatesCache[key] = null;
			} else {
				if (errorTemplatesCache[key].indexOf('{{') >= 0) {
					// Compilo template
					try {
						errorTemplatesCache[key] = doT.template(
							errorTemplatesCache[key],
						);
					} catch (compErr) {
						// TODO passare alla gestione unitaria degli errori
						Sentry.withScope((scope) => {
							scope.setExtra('data', data);
							scope.setExtra('isFieldContext', isFieldContext);
							Sentry.captureException(err);
						});
					}
				}
			}
		}

		if (!errorTemplatesCache[key]) {
			customMessage = err.message || err.data.type.toString();
		} else if (typeof errorTemplatesCache[key] === 'function') {
			// CHECK - dentro err oltre a 'key' servirebbe il 'entityTypeName' per dedurne il label del campo
			// Esplicitiamo e documentiamo la struttura di 'err'

			const tmplContext: any = {
				err,
				t,
				getFieldLabel: fieldLabel,
				enum2name,
				getEntityLabel,
				keyLabel: t(fieldLabel(err.data.entityName, err.data.key)),
				entityLabel: getEntityLabel(err.data.entityName),
			};

			
			tmplContext.globalKeyPrefix = tmplContext.keyLabel
				? tmplContext.keyLabel + ': '
				: '';

			
			try {
				customMessage = errorTemplatesCache[key](tmplContext);
			} catch (tmplErr) {
				customMessage = err.message || err.type.toString();
				Sentry.withScope((scope) => {
					scope.setExtra('data', data);
					scope.setExtra('isFieldContext', isFieldContext);
					Sentry.captureException(err);
				});
			}
		} else {
			customMessage = errorTemplatesCache[key].toString();
		}
	}

	return <p>{customMessage}</p>;
};

export {
	generateError,
	parseGraphqlError,
	sentryHandler,
	setErrorModal,
	modalHandler,
	errorToComponent,
};
