import gql from 'graphql-tag';
import {MagazineCollectionFullFragment} from "../fragments/MagazineCollectionFullFragment";

export const MagazineCollectionEditQuery = gql`
	query MagazineCollectionEditQuery($id: ID!) {
		entity: node (id: $id) {
				...MagazineCollectionFullFragment
		  }
	}
	${MagazineCollectionFullFragment}
`;


