import React from 'react';
import { CompanyCertificationCreateMutation } from '../graphql/mutations/CompanyCertificationCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/CompanyCertification.json';
import inputTypeIntro from '../static/introspection/CompanyCertificationCreateInput.json';
import { CompanyCertificationEditQuery } from '../graphql/query/CompanyCertificationEditQuery';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const CompanyCertificationCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(CompanyCertificationEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={CompanyCertificationCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.COMPANY_CERTIFICATION}
		fields={['name', 'akas']}
	/>
);
