import gql from 'graphql-tag';

export const newsletterSelectQuery = gql`
	query NewsletterSelectQuery($filter: NewsletterFilterInput) {
		choices: newsletters(filter: $filter) {
			edges {
				node {
					id
					localId
					name
					inputTemplate
				}
			}
			total
		}
	}
`;
