import gql from 'graphql-tag';

export const DataVizFullFragment = gql`
	fragment DataVizFullFragment on DataViz {
		id
		localId
		name
		title
		source
		url
		editorial_section {
			id
			name
		}
		targetSite
	}
`;
