import gql from 'graphql-tag';

export const GoodRecallCreateQuery = gql`
	query GoodRecallCreateQuery {
		editorialSectionTree(EditorialBrand: GOODS4HUB) {
            id
            name
            Parent {
                id
            }
            goodClasses {
                id
                shortname
                shortnameIta
                localId
                name
                nameIta
            }
        }
	}
`;


