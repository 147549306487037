import gql from 'graphql-tag';
import {GoodFullFragment} from "../fragments/GoodFullFragment";

export const GoodDetailQuery = gql`
	query GoodDetailQuery($id: ID!) {
		entity: node (id: $id) {
			...GoodFullFragment
			  ... on Good {
					candidacies {
						 id
						 localId
						 name
						 Award {
							  id
							  localId
							  name
							  year
							  edition
						 }
					}
			  }
		}
	}
	${GoodFullFragment}
`;


