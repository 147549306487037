import gql from 'graphql-tag';
import { CandidacyRatingFullFragment } from '../fragments/CandidacyRatingFullFragment';

export const CandidacyRatingDetailQuery = gql`
	query CandidacyRatingDetailQuery($id: ID!) {
		entity: node(id: $id) {
			...CandidacyRatingFullFragment
		}
	}
	${CandidacyRatingFullFragment}
`;


