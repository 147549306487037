import { ENTITY } from '../utils/entities';
import { UserRole } from '../server-types';

interface RoleCapabilities {
	[key: string]: string | boolean;
}

interface ViewRole {
	viewRoles: RoleCapabilities;
	autoDetailRoles: RoleCapabilities;
}

export const viewRoles: Map<ENTITY, ViewRole> = new Map();
const onlyInternal = [
	ENTITY.BANNER,
	ENTITY.HIGHLIGHTED_GOOD,
	ENTITY.WIDGET,
	ENTITY.USER,
	ENTITY.EDITORIAL_SECTION,
	ENTITY.RETAILER,
	ENTITY.NEWS,
	ENTITY.EMAIL_DOMAIN,
	ENTITY.TEAM,
	ENTITY.FAIR,
	ENTITY.GEO_INDICATION,
	ENTITY.TEAM,
	ENTITY.COMPANY_GROUP,
	ENTITY.HTML_TEMPLATE,
	ENTITY.EU_CATEGORY,
	ENTITY.SERVICE,
	ENTITY.NEWS_FORMAT,
	ENTITY.EDITORIAL_TAG,
	ENTITY.OBJECT_HISTORY,
	ENTITY.TERMS_OF_SERVICE,
	ENTITY.QUESTION_SET,
	ENTITY.FREQUENT_SEARCH,
	ENTITY.COMPANY_CERTIFICATION,
	ENTITY.GOOD_CLASS,
	ENTITY.COMPANY_BADGE,
	ENTITY.FAIR_VISIT,
	ENTITY.GOOD_FEATURE_CERTIFICATION,
	ENTITY.NEWSLETTER,
	ENTITY.NEWSLETTER_ISSUE,
	ENTITY.MAGAZINE_COLLECTION,
	ENTITY.MAGAZINE,
	ENTITY.DATA_VIZ,
	ENTITY.TYPEFORM,
	ENTITY.MEDIA,
	ENTITY.COUPON,
	ENTITY.FOOD_SERVICE,
	ENTITY.GOOD_RECALL,
	ENTITY.PLMANUFACTURER,
	ENTITY.STORECHAIN
];

onlyInternal.forEach((entity) =>
	viewRoles.set(entity, {
		viewRoles: {
			[UserRole.Internal]: true,
		},
		autoDetailRoles: {},
	}),
);

viewRoles.set(ENTITY.COMPANY, {
	viewRoles: {
		[UserRole.Internal]: true,
		[UserRole.Producer]: true,
	},
	autoDetailRoles: {
		[UserRole.Producer]: true,
	},
});

viewRoles.set(ENTITY.GOOD, {
	viewRoles: {
		[UserRole.Producer]: true,
		[UserRole.Internal]: true,
	},
	autoDetailRoles: {},
});

viewRoles.set(ENTITY.DISTRIBUTION_CHANNEL, {
	viewRoles: {
		[UserRole.Producer]: true,
		[UserRole.Internal]: true,
	},
	autoDetailRoles: {},
});

viewRoles.set(ENTITY.AWARD, {
	viewRoles: {
		[UserRole.Internal]: true,
		[UserRole.Producer]: true,
	},
	autoDetailRoles: {
		[UserRole.Producer]: true,
	},
});

viewRoles.set(ENTITY.CANDIDACY, {
	viewRoles: {
		[UserRole.Internal]: true,
		[UserRole.Producer]: true,
	},
	autoDetailRoles: {},
});

viewRoles.set(ENTITY.FAIR_PARTICIPATION, {
	viewRoles: {},
	autoDetailRoles: {},
});

viewRoles.set(ENTITY.ANSWER_SET, {
	viewRoles: {
		[UserRole.Internal]: true,
	},
	autoDetailRoles: {
		[UserRole.Producer]: true,
	},
});
