import React from 'react';
import { BranchPatchMutation } from '../graphql/mutations/BranchPatchMutation';
import { BranchEditQuery } from '../graphql/query/BranchEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/Branch.json';
import inputTypeIntro from '../static/introspection/BranchPatchInput.json';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';
import { EntityEditModal } from '../types/entities';
import { Branch, Company } from '../server-types';

export const BranchEdit: React.FC<EntityEditModal<Branch, Company>> = ({
	id,
	onSubmit,
}) => (
	<EntityEditFetcher
		id={id}
		modal={true}
		fetcher={graphqlQuery(BranchEditQuery, { id })}
		mutation={BranchPatchMutation}
		entityName={ENTITY.BRANCH}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		onSubmit={onSubmit}
		fields={[
			'name',
			'type',
			'vatNumber',
			'website',
			'Country',
			'StateProvince',
			'postalCode',
			'city',
			'address1',
			'address2',
		]}
	/>
);
