import gql from 'graphql-tag';
import { t } from '../utils/labels';
import { generateAsyncQuery } from '../utils/graphql';
import { ReportType } from '../components/Report';
import { AwardSelectQuery } from '../graphql/query/AwardSelectQuery';
import { nodes } from '../utils/misc';
import { always } from 'ramda';
import { InternalCapability } from '../server-types';

export const candidaciesWithoutCategory: ReportType = {
	filename: always('Candidature di un premio senza categoria'),
	title: 'Candidature di un premio senza categoria',
	requirements: [InternalCapability.MarketingExtra],
	query: gql`
		query QueryRep1(
			$after: String
			$filter: CandidacyFilterInput
			$first: Int
		) {
			connector: candidacies(after: $after, filter: $filter, first: $first) {
				edges {
					node {
						id
						name
						Company {
							id
							name
						}
						AwardCategory {
							id
							name
						}
					}
				}
				pageInfo {
					hasNextPage
				}
			}
		}
	`,
	fields: [
		{
			type: 'AsyncChoices',
			name: 'Award',
			label: t`Award`,
			enum: false,
			description: {},
			single: true,
			asyncQuery: generateAsyncQuery(AwardSelectQuery, true),
		},
	],

	header: () => [
		t`Candidacy Name`,
		t`Company Name`,
		'URL comodo da parte di Stefano ;)',
	],
	variablesGenerator: (pre: any, values: any) => ({
		filter: {
			Award: { id: values.Award.value.id },
		},
	}),
	extractor: (data: any) =>
		nodes(data.connector).filter((e) => !e.AwardCategory),
	transformer: (e: any) => [
		e.name,
		e.Company.name,
		`https://hub.italianfood.net/candidacies/${e.id}/edit`,
	],
};
