import gql from 'graphql-tag';

export const PLManufacturerListQuery = gql`
	query PLManufacturerListQuery(
		$first: Int
		$cursor: String
		$filter: PLManufacturerFilterInput
	) {
		entities: PLManufacturer(first: $first, after: $cursor, filter: $filter) {
			edges {
				node {
					id
					localId
					company {
						id
						name
					}
					good {
						id
						name
						Brand {
							id
							name
						}
					}
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;
