import gql from 'graphql-tag';
import { AwardInvitedCompanyFullFragment } from '../fragments/AwardInvitedCompanyFullFragment';

export const AwardInvitedCompanyEditQuery = gql`
	query AwardInvitedCompanyEditQuery($id: ID!) {
		entity: node(id: $id) {
			...AwardInvitedCompanyFullFragment
		}
	}
	${AwardInvitedCompanyFullFragment}
`;


