import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { MagazineListQuery } from '../graphql/query/MagazineListQuery';
import typeIntro from '../static/introspection/Magazine.json';
import MagazineFilters from '../static/introspection/MagazineFilterInput.json';
import { ENTITY } from '../utils/entities';

const MagazineList = () => (
	<EntityListFetcher
		query={MagazineListQuery}
		entityName={ENTITY.MAGAZINE}
		headers={[
			'MagazineCollection',
			'category',
			'issueNumber',
			'name',
			'publicationDate',
		]}
		typeIntro={typeIntro}
		filtersIntro={MagazineFilters}
		filterFields={[
			'BrandSf',
			'MagazineCollection',
			'category',
			'name',
			'title',
			'isPublished',
			'publishedAfter',
			'publishedBefore',
		]}
	/>
);

export { MagazineList };
