import gql from 'graphql-tag';
import {GoodClassFullFragment} from "../fragments/GoodClassFullFragment";

export const GoodClassPatchMutation = gql`
	mutation GoodClassPatchMutation($id: ID!, $changes: GoodClassPatchInput!) {
		results: goodClassPatch(id: $id, changes: $changes) {
			...GoodClassFullFragment
		}
	}
	${GoodClassFullFragment}
`;


