import React from 'react';
import { t } from '../utils/labels';
import { PageTitle } from './PageTitle';
import { Card, CardBody } from 'reactstrap';
import { reports, simpleReports } from '../containers/fields/ReportConfigurator';
import { Link } from 'react-router-dom';

export const ReportList: React.FC = () => (
	<>
		<PageTitle
			title={t`Reports`}
			breadcrumbs={[{ label: t`home`, path: '/' }, { label: t`Reports` }]}
		/>
		<Card>
			<CardBody>
				<ul>
					{Object.keys(reports).map((key) => (
						<li key={key}>
							<Link to={`/reports/${key}`}>{reports[key].title}</Link>
						</li>
					))}
					{Object.keys(simpleReports).map((key) => (
						<li key={key}>
							<Link to={`/reports/${key}`}>{simpleReports[key].title}</Link>
						</li>
					))}
				</ul>
			</CardBody>
		</Card>
	</>
);
