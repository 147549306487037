import gql from 'graphql-tag';

export const MediaBaseFragment = gql`
	fragment MediaBaseFragment on Media {
		id
		name
		caption
		origFileName
		origUrl
		smallThumbUrl
		mediumThumbUrl
		largeThumbUrl
		type
	}
`;
