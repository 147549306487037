import gql from 'graphql-tag';

export const FairListQuery = gql`
	query FairListQuery(
		$first: Int,
		$cursor: String,
		$filter: FairFilterInput
	) {
		entities: fairs(first: $first, after: $cursor, filter: $filter) {
			edges {
				node {
					id
					localId
					name
					 year
					 edition
					 Country {
						  id
						  name
					 }
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}

	}
`;


