import React from 'react';
import { t } from '../utils/labels';
import { CandidacyDetailQuery } from '../graphql/query/CandidacyDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/Candidacy.json';
import { CandidacyRatingDetail } from './CandidacyRatingDetail';
import { CandidacyRatingEdit } from './CandidacyRatingEdit';
import { CandidacyRatingCreate } from './CandidacyRatingCreate';
import { ACTIONS, generateUrl } from '../utils/urls';
import gql from 'graphql-tag';
import { ENTITY } from '../utils/entities';
import { refactorInfo, toAsyncRO } from '../utils/form';

export const CandidacyDetail = (props) => (
	<EntityDetailFetcher
		{...props}
		query={CandidacyDetailQuery}
		entityName={ENTITY.CANDIDACY}
		typeIntro={typeIntro}
		clonable={false}
		fields={[
			'Award',
			'Company',
			'featuredImageMedia',
			'extraMedia',
			'AwardCategory',
			'name',
			'Brand',
			'goods',
			'range',
			'description1',
			'description2',
			'description3',
			'description4',
			'description5',
			'description6',
			'description7',
			'description8',
			'description9',
			'description10',
			'candidacyRatings',
			'launchDate',
			'additionalImagesMedia',
		]}
		transformer={(data, entity) => {
			const award = entity.Award;
			// le descrizioni possono essere nascoste a seconda del valore dell'Award a cui
			// si riferisce la Candidacy. Anche la loro lunghezza e' definita a livello
			// di campi dell'Award
			data.fields = data.fields.filter(
				(f) =>
					f.name.indexOf('description') === -1 ||
					!!award[f.name + 'Label'],
			);

			data.fields = data.fields.map((f) => {
				switch (f.name) {
					case 'description1':
					case 'description2':
					case 'description3':
					case 'description4':
					case 'description5':
					case 'description6':
					case 'description7':
					case 'description8':
					case 'description9':
					case 'description10':
						const labelsPart = award[f.name + 'Label'].split('|');
						f.label = labelsPart[0].replace('$', '');
						f.helpText = labelsPart[1] || '';
						break;

					case 'candidacyRatings':
						if (f.type !== 'EntityList') {
							refactorInfo(f, 'EntityList');
							break;
						}
						f.props.headers = ['User', 'overall'];
						f.props.fragment = gql`
							fragment Fragment on CandidacyRating {
								User {
									id
									name
								}
								overall
							}
						`;
						f.editComponent = CandidacyRatingEdit;
						f.detailComponent = CandidacyRatingDetail;
						f.addComponent = CandidacyRatingCreate;
						f.tab = 2;
						break;

					case 'Brand':
						if (f.type !== 'AsyncChoices') {
							refactorInfo(f, 'AsyncChoices');
							break;
						}
						f.mapToEntity = () =>
							generateUrl(
								ENTITY.COMPANY,
								ACTIONS.DETAIL,
								entity.Brand.Company.id,
							);
						break;

					case 'AwardCategory':
					case 'goodFeatureCertifications':
						if (f.type !== 'AsyncChoices') {
							refactorInfo(f, 'AsyncChoices');
							break;
						}
						f.mapToEntity = null;
						break;

					case 'additionalImagesMedia':
						f.tab = 1;
						break;

					case 'goods':
						if (f.type !== 'EntityList') {
							refactorInfo(f, 'EntityList');
							break;
						}
						const newField = toAsyncRO(f, ENTITY.BRAND);
						if (entity.Award.langCode === 'it_IT') {
							newField.myToString = (entity) => entity.nameIta;
						}
						return newField;

					default:
						break;
				}
				return f;
			});
			return data;
		}}
		tabs={[
			t('generic data'),
			t([ENTITY.CANDIDACY, 'medias', 'label'].join('/')),
			t([ENTITY.CANDIDACY, 'candidacyRatings', 'label'].join('/')),
		]}
		cols={[['col-sm-7', 'col-sm-5'], ['col-sm-12'], ['col-sm-12']]}
	/>
);
