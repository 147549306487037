import React from 'react';
import { EntityModalStateEnum } from '../components/EntityModalState';

export type ViewContextInterface =
	| {
			type: 'modal';
			mode: EntityModalStateEnum;
			changeState: (newState: EntityModalStateEnum) => void;
			customTitle?: string; // TODO parlarne con Alessandro
	  }
	| {
			type: 'page';
	  };

export const ViewContext = React.createContext<ViewContextInterface | null>(
	null,
);
