import gql from 'graphql-tag';
import {AwardResultFullFragment} from "../fragments/AwardResultFullFragment";

export const AwardResultCreateMutation = gql`
	mutation AwardResultCreateMutation($values: AwardResultCreateInput!) {
		results: awardResultCreate(values: $values) {
			...AwardResultFullFragment
		}
	}
	${AwardResultFullFragment}
`;


