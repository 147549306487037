import gql from 'graphql-tag';

export const NewsletterListQuery = gql`
	query NewsletterListQuery(
		$first: Int
		$cursor: String
		$filter: NewsletterFilterInput
	) {
		entities: newsletters(first: $first, after: $cursor, filter: $filter) {
			edges {
				node {
					id
					localId
					name
					target
					delivery
					inputTemplate
				}
			}
			total
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;


