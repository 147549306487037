import gql from 'graphql-tag';

export const EditorialSectionCreateQuery = gql`
    query EditorialSectionCreateQuery {
        editorialSectionTree(EditorialBrand: GOODS4HUB) {
            id
            name
            Parent {
                id
            }
            goodClasses {
                id
                shortname
                shortnameIta
                localId
                name
                nameIta
            }
        }
    }
`;


