import gql from 'graphql-tag';
import { NewsletterFullFragment } from '../fragments/NewsletterFullFragment';

export const NewsletterDetailQuery = gql`
	query NewsletterDetailQuery($id: ID!) {
		entity: node(id: $id) {
			...NewsletterFullFragment
		}
	}
	${NewsletterFullFragment}
`;
