import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { SortableList } from '../SortableList';
import { MediaPreview } from '../MediaPreview';
import { t } from '../../utils/labels';
import { FieldComponentRO, MediaListFieldType } from '../../types/form';

export const MediaListFieldRO: React.FC<FieldComponentRO<
	MediaListFieldType
>> = ({ field: { pre, post, value, label, name } }) => (
	<>
		{pre && pre}
		<FormGroup>
			<Label>{label}</Label>
			{value && value.length > 0 && (
				<SortableList
					readonly={true}
					onMove={null}
					onRemove={null}
					items={value}
					ordered={false}
					path={name}
					itemView={({ item }) => <MediaPreview item={item} />}
				/>
			)}
			{(!value || value.length === 0) && <div>{t`no media message`}</div>}
		</FormGroup>
		{post && post}
	</>
);
