import gql from 'graphql-tag';
import {CandidacyRatingFullFragment} from "../fragments/CandidacyRatingFullFragment";

export const CandidacyRatingPatchMutation = gql`
	mutation CandidacyRatingPatchMutation($id: ID!, $changes: CandidacyRatingPatchInput!) {
		results: candidacyRatingPatch(id: $id, changes: $changes) {
			...CandidacyRatingFullFragment
		}
	}
	${CandidacyRatingFullFragment}
`;


