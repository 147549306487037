import React, { useCallback, useMemo, useState } from 'react';
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardText,
	Col,
	Form as BootstrapForm,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
} from 'reactstrap';
import { t } from '../utils/labels';
import { Media, MediaFilterInput, MediaType } from '../server-types';
import { Form } from './Form';
import {
	generateBaseField,
	generateTextField,
	toAsyncChoices,
} from '../utils/form';
import { Query } from 'react-apollo';
import { MediaModalListQuery } from '../graphql/query/MediaModalListQuery';
import {
	modalHandler,
	parseGraphqlError,
	sentryHandler,
} from '../utils/errors';
import { nodes } from '../utils/misc';
import { deleteNodeMutation } from '../graphql/mutations/DeleteNodeMutation';
import { client } from '../utils/client';
import { generateAsyncQuery } from '../utils/graphql';
import { ENTITY } from '../utils/entities';
import { CompanySelectQuery } from '../graphql/query/CompanySelectQuery';
import { AsyncChoicesField } from './fields/AsyncChoices';
import { toRefInput } from '../utils/dataTrasform';
import { UserData } from '@food/auth/build/dist';
import { AsyncChoiceFieldType, TextFieldType } from '../types/form';
import {
	cns,
	currySC,
	IFNTheme,
	registerClass,
} from '@food/css-manager';

interface MediaListProps {
	select: (e: Media) => void;
	usedMedias: ReadonlyArray<Media>;
	mediaType: MediaType;
}

const styleClass = registerClass(
	(t: IFNTheme, sc) => `
	.${sc('caption')},
	.${sc('orig-file-name')} {
		display: block;
		text-align: center;
		line-height: 1.25;
		margin-bottom: 5px;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.${sc('orig-file-name')} {
		font-style: italic;
		color: ${t.colors.grey.plain};
		display: block;
		text-align: center;
	}
	
	.${sc('image-container')} {
		background-size: contain;
		padding-top: 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}
	
	.${sc('delete-action-container')} {
		text-align: right;
	}
	
	.${sc('card-body')} {
		cursor: pointer;
	}
`,
	true,
);
const sc = currySC(styleClass);

export const MediaModalList: React.FC<MediaListProps> = ({
	select,
	usedMedias,
	mediaType,
}) => {
	const [textFilter, setTextFilter] = useState(null);
	const [companyFilter, setCompanyFilter] = useState(null);
	const [deleteState, setDeleteState] = useState({
		toCancelId: undefined,
		deleting: false,
		showDeleteConfirm: false,
	});
	const { showDeleteConfirm, toCancelId } = deleteState;
	const closeDeleteConfirm = useCallback(
		() =>
			setDeleteState({
				toCancelId: undefined,
				deleting: false,
				showDeleteConfirm: false,
			}),
		[deleteState],
	);

	const deleteMedia = useCallback(
		async (refetch) => {
			try {
				await client.mutate({
					mutation: deleteNodeMutation,
					variables: { id: toCancelId },
				});
				setDeleteState({
					toCancelId: undefined,
					deleting: false,
					showDeleteConfirm: false,
				});
				refetch();
			} catch (e) {
				const errContext = { entityName: 'Media' };
				const errs = parseGraphqlError(e, errContext);
				sentryHandler(errs);
				modalHandler(errs);
			}
		},
		[toCancelId],
	);

	const formFields = useMemo(
		() => [
			generateTextField('name', t`mediaModal/filter/name`),
			toAsyncChoices(
				ENTITY.COMPANY,
				generateAsyncQuery(CompanySelectQuery, true),
				true,
				generateBaseField('Company', t`mediaModal/filter/Company`),
			),
		],
		[],
	);

	return (
		<UserData>
			{({ isInternal }) => (
				<Form
					fields={formFields}
					onSubmit={(fields, values) => {
						setTextFilter(values.name);
					}}
					onChange={(field, value) => {
						if (field.name === 'Company') {
							if (value) {
								setCompanyFilter(toRefInput(value.value));
							} else {
								setCompanyFilter(null);
							}
						}
					}}
				>
					{({ fields, mutatorFactory, submit }) => {
						const nameField = fields[0] as TextFieldType;
						const nameSetter = mutatorFactory(nameField);
						const companyField = fields[1] as AsyncChoiceFieldType;
						const companySetter = mutatorFactory(companyField);

						return (
							<Query<any, { filter: MediaFilterInput }>
								query={MediaModalListQuery}
								variables={{
									filter: {
										type: mediaType,
										name: textFilter,
										Company: companyFilter,
									},
								}}
								fetchPolicy={'cache-and-network'}
							>
								{({ error, loading, data, refetch }) => {
									if (error) {
										const errContext = { entityName: 'Media' };
										const errs = parseGraphqlError(error, errContext);
										sentryHandler(errs);
										return null;
									}

									const list: ReadonlyArray<Media> = loading
										? []
										: nodes(data.medias);
									return (
										<div className={styleClass}>
											<Modal
												isOpen={showDeleteConfirm}
												toggle={closeDeleteConfirm}
												backdrop="static"
												wrapClassName="renew-modal-wrapper"
												autoFocus={false}
											>
												<ModalBody>
													<h3 className="text-center">{t`Confirm delete text?`}</h3>
												</ModalBody>
												<ModalFooter>
													<Button
														color="secondary"
														onClick={closeDeleteConfirm}
													>
														<i className="fa fa-times" />{' '}
														{t`Undo`}
													</Button>{' '}
													<Button
														color="danger"
														onClick={() => deleteMedia(refetch)}
													>
														<i className="fa fa-trash" />{' '}
														{t`Delete`}
													</Button>
												</ModalFooter>
											</Modal>
											<div>
												<BootstrapForm>
													{isInternal && (
														<AsyncChoicesField
															field={companyField}
															path={'Company'}
															changeHandler={companySetter}
														/>
													)}
													<FormGroup row>
														<Label sm={3}>
															{t`mediaModal/filter/name`}:
														</Label>
														<Col sm={9}>
															<Input
																className={'col-sm-12'}
																type={'text'}
																name={nameField.name}
																value={nameField.value || ''}
																onChange={(event) =>
																	nameSetter(
																		event.target.value,
																		nameField.name,
																	)
																}
																onKeyPress={(event) => {
																	if (event.key === 'Enter') {
																		event.preventDefault();
																		event.stopPropagation();
																		submit();
																	}
																}}
															/>
														</Col>
													</FormGroup>
												</BootstrapForm>
											</div>
											<div className="media-container">
												{list.map((m) => (
													<Card key={m.id}>
														<CardBody
															className={sc('card-body')}
															onClick={() => select(m)}
														>
															<div
																className={cns(
																	sc('image-container'),
																	'image-hover-container',
																)}
																style={{
																	backgroundImage: `url('${m.smallThumbUrl ||
																		m.mediumThumbUrl ||
																		m.largeThumbUrl ||
																		m.origUrl}')`,
																}}
															/>
															<CardText>
																{m.name && (
																	<span
																		className={sc('caption')}
																	>
																		{m.name}
																	</span>
																)}
																<span
																	className={sc(
																		'orig-file-name',
																	)}
																>
																	{m.origFileName}
																</span>
															</CardText>
														</CardBody>
														<CardFooter>
															<div
																className={sc(
																	'delete-action-container',
																)}
															>
																<Button
																	color="danger"
																	size="sm"
																	disabled={usedMedias.some(
																		(u) => m.id === u.id,
																	)}
																	onClick={(event) => {
																		event.preventDefault();
																		event.stopPropagation();
																		setDeleteState({
																			deleting: false,
																			toCancelId: m.id,
																			showDeleteConfirm: true,
																		});
																	}}
																>
																	<i className="fa fa-trash" />{' '}
																	{t`Delete`}
																</Button>
															</div>
														</CardFooter>
													</Card>
												))}
											</div>
										</div>
									);
								}}
							</Query>
						);
					}}
				</Form>
			)}
		</UserData>
	);
};
