import React from 'react';
import { ToSCreateMutation } from '../graphql/mutations/ToSCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/ToS.json';
import inputTypeIntro from '../static/introspection/ToSCreateInput.json';
import { ToSEditQuery } from '../graphql/query/ToSEditQuery';
import { ENTITY } from '../utils/entities';
import { graphqlQuery } from '../utils/graphql';

export const ToSCreate = ({ id }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(ToSEditQuery, { id }) : null}
		create={true}
		clone={!!id}
		id={id}
		mutation={ToSCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.TERMS_OF_SERVICE}
		fields={[
			'name',
			'startDate',
			'description',
			'descriptionIta',
			'descriptionExtra',
			'descriptionExtraIta',
			'descriptionChanges',
			'descriptionChangesIta',
		]}
	/>
);
