import React from 'react';
import { assoc } from 'ramda';
import { HighlightedGoodCreateMutation } from '../graphql/mutations/HighlightedGoodCreateMutation';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/HighlightedGood.json';
import inputTypeIntro from '../static/introspection/HighlightedGoodCreateInput.json';
import { HighlightedGoodEditQuery } from '../graphql/query/HighlightedGoodEditQuery';
import { generateAsyncQuery, graphqlQuery } from '../utils/graphql';
import { GoodSelectQuery } from '../graphql/query/GoodSelectQuery';
import { toRefInput } from '../utils/dataTrasform';
import { ENTITY } from '../utils/entities';
import { fastTransformer, toHidden } from '../utils/form';
import { EntityCreateModal } from '../types/entities';
import { Company, Good, HighlightedGood } from '../server-types';

export const HighlightedGoodCreate: React.FC<EntityCreateModal<
	HighlightedGood,
	Company | Good
>> = ({ id, onSubmit, originalEntity }) => (
	<EntityEditFetcher
		fetcher={id ? graphqlQuery(HighlightedGoodEditQuery, { id }) : null}
		onSubmit={onSubmit}
		create={true}
		modal={true}
		mutation={HighlightedGoodCreateMutation}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		entityName={ENTITY.HIGHLIGHTED_GOOD}
		fields={[
			'Good',
			'targetSite',
			'highlightReasons',
			'claim1',
			'claim2',
			'claim3',
		]}
		transformer={(data) => {
			const isGood = originalEntity.__typename === ENTITY.GOOD;

			data.fields = fastTransformer({
				Good: isGood
					? toHidden(toRefInput(originalEntity))
					: assoc(
						'asyncQuery',
						generateAsyncQuery(
							GoodSelectQuery,
							true,
							(value) => ({
								filter: {
									Company: toRefInput(originalEntity),
									name: value,
								},
							}),
							(e) => e.choices,
						),
					),
				//targetSite: toHidden(TargetSite.Ifn),
			})(data.fields);

			return data;
		}}
	/>
);
