import React from 'react';
import EntityListFetcher from '../components/EntityListFetcher';
import { CompanyCertificationListQuery } from '../graphql/query/CompanyCertificationListQuery';
import typeIntro from '../static/introspection/CompanyCertification.json';
import { ENTITY } from '../utils/entities';
import SearchByNameFilter from '../static/introspection/SearchByNameInput.json';

export const CompanyCertificationList = () => (
	<EntityListFetcher
		query={CompanyCertificationListQuery}
		entityName={ENTITY.COMPANY_CERTIFICATION}
		headers={['name', 'akas']}
		typeIntro={typeIntro}
		filtersIntro={SearchByNameFilter}
		filterFields={['name']}
	/>
);
