import React from 'react';
import { DistributionChannelPatchMutation } from '../graphql/mutations/DistributionChannelPatchMutation';
import { DistributionChannelEditQuery } from '../graphql/query/DistributionChannelEditQuery';
import { EntityEditFetcher } from '../components/EntityEditFetcher';
import typeIntro from '../static/introspection/DistributionChannel.json';
import inputTypeIntro from '../static/introspection/DistributionChannelPatchInput.json';
import { ENTITY } from '../utils/entities';
import { toRefInput } from '../utils/dataTrasform';
import { countriesToTree } from '../utils/tree';
import { nodes } from '../utils/misc';
import { graphqlQuery } from '../utils/graphql';
import { fastTransformer, refactorInfo, toTree } from '../utils/form';
import { EntityEditModal } from '../types/entities';
import { Company, DistributionChannel } from '../server-types';

export const DistributionChannelEdit: React.FC<EntityEditModal<
	DistributionChannel,
	Company
>> = ({ id, onSubmit }) => (
	<EntityEditFetcher
		id={id}
		fetcher={graphqlQuery(DistributionChannelEditQuery, { id })}
		mutation={DistributionChannelPatchMutation}
		entityName={ENTITY.DISTRIBUTION_CHANNEL}
		typeIntro={typeIntro}
		inputTypeIntro={inputTypeIntro}
		onSubmit={onSubmit}
		modal={true}
		fields={[
			'mode',
			'channels',
			'countries',
			'note',
			'catalogMedia',
			'contactEmail',
		]}
		entitiesToFields={(props) => ({
			countries: props.countries,
		})}
		transformer={(data, entity, listsByType) => {
			data.fields = fastTransformer({
				countries: (f) => {
					if (f.type !== 'AsyncChoices') {
						refactorInfo(f, 'AsyncChoices');
						return f;
					}
					const newField = toTree(
						f,
						false,
						countriesToTree(nodes(listsByType.countries)),
					);
					if (f.value) {
						newField.value = f.value.map((choice) =>
							toRefInput(choice.value),
						);
					}
					return newField;
				},
			})(data.fields);
			return data;
		}}
	/>
);
