import React, { useCallback, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import { MediaModalList } from './MediaModalList';
import { MediaUploader } from './MediaUploader';
import { t } from '../utils/labels';
import { Spinner } from './GlobalSpinner';
import { Portal } from 'react-portal';
import { Media, MediaType } from '../server-types';

interface IMediaModalProps {
	onSelect?: (val: Media) => void;
	mediaType: MediaType;
	helpText: string;
	usedMedias: ReadonlyArray<Media>;
	selected: Media | ReadonlyArray<Media>;
	open: boolean;
	closeHandler: () => void;
}

export const MediaModal: React.FC<IMediaModalProps> = ({
	onSelect,
	mediaType,
	helpText,
	usedMedias,
	closeHandler,
	open,
}) => {
	const [uploading, setUploading] = useState(false);

	const selectUploaded = useCallback(
		(obj: Media) => {
			setUploading(false);
			if (onSelect) {
				onSelect(obj);
			}
		},
		[uploading],
	);

	return (
		<section>
			<Portal isOpened={true}>
				<Spinner show={uploading} />
			</Portal>

			<Modal autoFocus={false} isOpen={open} toggle={closeHandler} size="xl">
				<ModalHeader toggle={closeHandler}>{t`choose media`}</ModalHeader>
				<ModalBody>
					<MediaUploader
						onLoad={selectUploaded}
						onStartLoading={() => setUploading(true)}
						onError={() => setUploading(false)}
						mediaType={mediaType}
						helpText={helpText}
					/>
					<hr />
					<MediaModalList
						mediaType={mediaType}
						select={(e) => {
							closeHandler();
							onSelect(e);
						}}
						usedMedias={usedMedias}
					/>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={closeHandler}>
						{t`cancel`}
					</Button>
				</ModalFooter>
			</Modal>
		</section>
	);
};
